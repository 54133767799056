import styled from 'styled-components';

export const ButtonsGroupStyled = styled.div`
  display: flex;
  width: fit-content;
  overflow: hidden;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  box-shadow: 1px 1px 2px 0 rgba(54, 54, 54, 0.1);

  > div {
    cursor: pointer;
    display: flex;
    min-width: 108px;
    padding: 10px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    flex-direction: column;
    color: ${({ theme }) => theme.color.grey900};
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.08px;
    &:first-of-type {
      border-right: 1px solid ${({ theme }) => theme.color.grey300};
    }
    &.selected {
      color: ${({ theme }) => theme.color.white};
      background: ${({ theme }) => theme.color.error2};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      &.active {
        background: ${({ theme }) => theme.color.success1};
      }
    }
  }
`;
