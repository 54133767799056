import { useTranslation } from 'react-i18next';
import { PasswordRules } from '../constants';

type RulesId =
  | PasswordRules.validLength
  | PasswordRules.includeUppercase
  | PasswordRules.includeLowercase;

interface Rule {
  id: RulesId;
  name: string;
}

export const usePasswordRules = () => {
  const { t } = useTranslation();

  const passwordRules: Rule[] = [
    {
      id: PasswordRules.validLength,
      name: t('auth.create_password.validation.rule1'),
    },
    {
      id: PasswordRules.includeUppercase,
      name: t('auth.create_password.validation.rule2'),
    },
    {
      id: PasswordRules.includeLowercase,
      name: t('auth.create_password.validation.rule3'),
    },
  ];

  return passwordRules;
};
