import styled from 'styled-components';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 456px;
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.color.grey500};
    }
  }

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-bottom: 8px;
    font-family: 'AcidGrotesk-Medium';
    letter-spacing: 0.2px;

    > div {
      margin-top: 8px;
      color: ${({ theme }) => theme.color.grey700};
      font-family: 'Mulish';
      font-size: 18px;
      font-weight: 500;
      line-height: 25.2px;

      > a {
        text-decoration: none;
        color: ${({ theme }) => theme.color.blue800};
        font-weight: 700;
      }
    }
  }

  > section {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 32px;

    > button {
      width: 50%;
    }
  }
`;

export const RadioButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  > p {
    color: ${({ theme }) => theme.color.grey700};
    font-family: 'Mulish';
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }
`;
