import React, { FC } from 'react';

import { AuthPageWrapper } from '../AuthPageLayout';
import { ExpiredInvitationLink } from 'features/feature-finish-registration/ExpiredInvitationLink';

export const RegistrationExpiredCode: FC = () => {
  return (
    <AuthPageWrapper>
      <ExpiredInvitationLink />
    </AuthPageWrapper>
  );
};
