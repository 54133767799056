import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { styledTheme } from 'applicaiton/theme';
import { Link } from 'react-router-dom';

interface Props {
  error?: number;
}
export const LoginFormWrapper = styled(Box)`
  padding: 120px 0 0;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 464px;

  > svg:first-of-type {
    display: none;
  }

  @media screen and (max-width: 960px) {
    padding: 48px 0 0;
    > svg:first-of-type {
      display: block;
      width: 300px;
      height: auto;
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: 960px) {
    padding: 32px 0 0;
    width: 100%;
    > svg:first-of-type {
      width: 180px;
      height: auto;
      margin-bottom: 32px;
    }
  }
`;

export const StyledForm = styled('form')`
  padding: 0 22px;
  width: 100%;
`;

export const FormGroupStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 12px;
`;

export const StyledBlueLink = styled(Link)`
  color: ${styledTheme.color.blue800};
  font: 700 16px/22.4px 'Mulish', sans-serif;
  text-decoration: none;
  letter-spacing: 0.08px;
  cursor: pointer;
`;

export const StyledTypography = styled(Typography)`
  color: ${styledTheme.color.grey800};
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;

export const StyledTypographySmall = styled(StyledTypography)`
  color: ${styledTheme.color.grey900};
  line-height: 19.6px;
`;

export const StyledError = styled(StyledTypography)`
  color: ${styledTheme.color.error1};
  line-height: 19.6px;
  text-align: center;
  margin-top: 28px;
`;

export const CheckboxWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
`;

export const CheckboxStyled = styled('div')`
  > div {
    input + label:before {
      width: 18px;
      height: 18px;
    }
  }
`;

export const ForgotPasswordWrapper = styled(Box)<Props>`
  padding: ${({ error }) => (error ? '0' : '0 22px')};
  width: 100%;
`;
