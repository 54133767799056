import { AudioTrackProps } from 'features/feature-teleconsultation/types';
import React, { FC, useEffect, useRef } from 'react';

const AudioTrack: FC<AudioTrackProps> = ({ audioTrack }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (audioTrack) {
      const el = ref.current || '';
      audioTrack.attach(el);

      return () => {
        audioTrack.detach(el);
      };
    }
  }, [audioTrack]);

  return <audio ref={ref} />;
};

export default AudioTrack;
