export enum ViewBy {
  byProfessionals = 'byProfessionals',
  byAvailability = 'byAvailability',
}

export enum CalendarView {
  daily = 'daily',
  weekly = 'weekly',
}

export enum AgendaView {
  agenda = 'agenda',
  list = 'list',
}

export enum Format {
  dateFormat = 'YYYY-MM-DD',
  dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss',
}
