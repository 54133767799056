import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PersonsIcon } from 'applicaiton/assets/persons.svg';
import { ReactComponent as BadgeIcon } from 'applicaiton/assets/badge.svg';
import { ReactComponent as ClockIcon } from 'applicaiton/assets/clock.svg';
import { ReactComponent as ClinicIcon } from 'applicaiton/assets/clinic.svg';
import { ReactComponent as DollarIcon } from 'applicaiton/assets/monetization.svg';
import { ReactComponent as TeleconsultationCameraIcon } from 'applicaiton/assets/teleconsultation_camera.svg';
import { Wrapper, ItemsList, ItemStyled } from './styles';
import { PatientSubscriptionsProAppResponseDto } from '@docbay/schemas';

interface Props {
  data: PatientSubscriptionsProAppResponseDto;
  index: number;
}

const SubscriptionItem: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const professionalName = `${data.clinicProfessionalSubscription.professional.firstName} ${data.clinicProfessionalSubscription.professional.lastName}`;
  const pronunciation =
    data.clinicProfessionalSubscription.professional.gender === 'Male'
      ? t('drMale')
      : t('drFemale');

  const durationText = useMemo(() => {
    const duration =
      Number(data.clinicProfessionalSubscription.durationInWeeks) || 0;
    const durationNumber = duration > 3 ? duration / 4 : duration;

    const durationName =
      duration > 3
        ? t(
            `professional_profile.subscriptions.${
              duration / 4 === 1 ? 'month' : 'months'
            }`,
          )
        : t(
            `professional_profile.subscriptions.${
              duration === 1 ? 'week' : 'weeks'
            }`,
          );

    return `${durationNumber} ${durationName}`;
  }, [data, t]);

  return (
    <Wrapper>
      <p>{data.clinicProfessionalSubscription.title}</p>
      <div style={{ display: 'flex' }}>
        <ItemsList>
          <ItemStyled>
            <BadgeIcon />
            <span>{t('doctor')}:</span>
            <p>
              {pronunciation} {professionalName}
            </p>
          </ItemStyled>
          <ItemStyled>
            <ClockIcon />
            <span>{t('professional_profile.subscriptions.duration')}:</span>
            <p>{durationText}</p>
          </ItemStyled>
          <ItemStyled>
            <TeleconsultationCameraIcon />
            <span>{t('teleconsultationTitle')}:</span>
            <p>
              {data.teleconsultationMinutesReceived} {t('mins')}
            </p>
          </ItemStyled>
          {data.forWhom && (
            <ItemStyled>
              <PersonsIcon />
              <span>{t('relatives')}:</span>
              <p>{data.forWhom.relationship}</p>
            </ItemStyled>
          )}
        </ItemsList>
        <ItemsList>
          <ItemStyled>
            <ClinicIcon />
            <span>{t('clinic')}:</span>
            <p>{data.clinicProfessionalSubscription.clinic.name}</p>
          </ItemStyled>
          <ItemStyled>
            <DollarIcon />
            <span>{t('price')}:</span>
            <p>€{Number(data.pricePaid).toFixed(2)}</p>
          </ItemStyled>
        </ItemsList>
      </div>
    </Wrapper>
  );
};

export default SubscriptionItem;
