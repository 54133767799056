import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useBillingInfoSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');

  return yup
    .object({
      companyName: yup.string().required(requiredError),
      address: yup.string().required(requiredError),
      iban: yup.string().required(requiredError),
    })
    .required();
};
