import React, { FC, useEffect, useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import {
  StyledModal,
  TitleWrapper,
  DataSection,
  ButtonWrapper,
} from './styles';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import {
  InfinityScrollDropdown,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import { Props } from './modules';
import { useTranslation } from 'react-i18next';
import { Option } from 'common/components/Dropdown/models';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  getAllergies,
  getDiseases,
} from 'applicaiton/store/reducers/Consultation/ActionCreators';
import { Loader } from 'common/loader/Loader';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import {
  AllergyResponseDto,
  CommonEntitiesListResponse,
  DiseaseResponseDto,
} from '@docbay/schemas';
import {
  setAllergies,
  setDiseases,
} from 'applicaiton/store/reducers/Consultation/ConsultationSlice';
import { useLanguage } from 'common/hooks/useLanguage';

const AddMedicalDataModal: FC<Props> = ({
  header,
  title,
  placeholder,
  onClose,
  onAdd,
  isAllergy,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchItem, setSearchItem] = useState('');
  const [currentSearchText, setCurrentSearchText] = useState('');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();
  const { language } = useLanguage();
  const [dataId, setDataId] = useState<string>('');

  const {
    allergies,
    diseases,
    isLoading,
    allergiesTotalPages,
    diseasesTotalPages,
  } = useAppSelector((state) => state.consultationData);

  const currentData = useMemo(() => {
    return isAllergy ? allergies : diseases;
  }, [allergies, diseases]);

  const optionsArr = useMemo(() => {
    if (allergies.length || diseases.length) {
      const options = currentData.map((item) => {
        const optionName = item[localizeNameKey];

        return {
          value: item.id,
          label: optionName as string,
        };
      });
      return options;
    }

    return [];
  }, [allergies, diseases]);

  const getData = () => {
    const params = {
      page: currentPage,
      limit: 10,
      languageIso: language,
      search: searchItem,
    };

    const currentRequest = isAllergy ? getAllergies : getDiseases;
    const currentSetData = isAllergy ? setAllergies : setDiseases;
    const currentTotalPage = isAllergy
      ? allergiesTotalPages
      : diseasesTotalPages;

    if (currentPage <= currentTotalPage || currentTotalPage === 0) {
      dispatch(currentRequest(params)).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          const dataArr = isAllergy
            ? (data.payload as CommonEntitiesListResponse<AllergyResponseDto>)
            : (data.payload as CommonEntitiesListResponse<DiseaseResponseDto>);

          dispatch(
            currentSetData(
              currentPage === 1
                ? dataArr?.entities!
                : isAllergy
                ? [...allergies, ...dataArr.entities!]
                : [...diseases, ...dataArr.entities!],
            ),
          );

          if (!dataArr?.entities.length) {
            setHasNextPage(!dataArr?.entities.length);
          }
        }
      });
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, searchItem, isAllergy]);

  const handleGetNewData = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSearchData = (search: string) => {
    setSearchItem(search);
    setHasNextPage(true);
    setCurrentPage(1);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Modal open={true}>
      <StyledModal>
        <TitleWrapper>
          <h2>{header}</h2>
          <CloseIcon onClick={onClose} />
        </TitleWrapper>
        <DataSection>
          <InfinityScrollDropdown
            id={'data'}
            placeholder={placeholder}
            value={dataId}
            onChange={(value) => {
              const val = value as Option;

              setDataId(val.value as string);
              setCurrentSearchText(val.label);
            }}
            options={optionsArr}
            label={title}
            withSearch
            getNewData={handleGetNewData}
            hasNextPage={hasNextPage}
            onSearchData={handleSearchData}
            currentValue={currentSearchText}
          />
        </DataSection>
        <ButtonWrapper>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton disabled={!dataId} onClick={() => onAdd(dataId!)}>
            {t('save')}
          </PrimaryButton>
        </ButtonWrapper>
      </StyledModal>
    </Modal>
  );
};

export default AddMedicalDataModal;
