import React, { FC, useEffect } from 'react';
import { Modal } from '@mui/material';
import { DeleteModal, SuccessModal } from './components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader } from 'common/components';
import { deletePatient } from 'applicaiton/store/reducers/Patients/ActionCreators';
import { selectPatientToDelete } from 'applicaiton/store/reducers/Patients/PatientsSlice';

const DeletePatientModal: FC = () => {
  const { isLoading, patientToDelete, deletedSuccess } = useAppSelector(
    (state) => state.patients,
  );
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(selectPatientToDelete(null));
  };

  const handleSubmit = () => {
    dispatch(deletePatient(String(patientToDelete?.id)));
  };

  useEffect(() => {
    if (!deletedSuccess) return;
    const timer = setTimeout(() => {
      handleClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [deletedSuccess]);

  const patientFullName = `${patientToDelete?.firstName || ''} ${
    patientToDelete?.lastName || ''
  }`;

  return (
    <Modal open={!!patientToDelete}>
      <>
        {isLoading && <Loader />}
        <DeleteModal
          fullName={patientFullName}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />

        {deletedSuccess && !isLoading && (
          <SuccessModal fullName={patientFullName} onClose={handleClose} />
        )}
      </>
    </Modal>
  );
};

export default DeletePatientModal;
