import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;

    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const SelectSection = styled.div`
  width: 464px;

  > div {
    width: 464px;

    &:last-child {
      margin-top: 24px;
    }
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const SelectedClinicsCounts = styled.div`
  color: ${({ theme }) => theme.color.grey600};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-top: 24px;
`;

export const SelectedClinics = styled.div`
  margin-top: 12px;
`;

export const SelectedClinicsItem = styled.div`
  display: flex;
  position: relative;
  width: 464px;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  margin-bottom: 12px;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  line-height: 140%;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ClinicImage = styled.div`
  min-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.blueDark100};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ClinicInfo = styled.div`
  flex-grow: 1;
  margin-left: 8px;

  div:first-child {
    color: ${({ theme }) => theme.color.grey900};
    font-weight: 700;
  }

  div:last-child {
    color: ${({ theme }) => theme.color.grey600};
    font-weight: 400;
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -8px;
  transform: translateX(100%);

  path {
    fill: ${({ theme }) => theme.color.grey700};
  }
`;
