import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex !important;
  width: fit-content !important;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.grey900};
  margin-bottom: 8px;

  div {
    padding: 10px 16px;
    background: ${({ theme }) => theme.color.blue100};
    border: 1px solid ${({ theme }) => theme.color.grey300};
  }

  a {
    padding: 10px 16px;
    color: ${({ theme }) => theme.color.grey900};
    background: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.grey300};
    font-weight: 700;
    text-decoration: none;
  }

  > :nth-child(1) {
    border-right: unset;
    border-radius: 8px 0 0 8px;
  }

  > :nth-child(2) {
    border-radius: 0 8px 8px 0;
  }
`;
