import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { useTranslation } from 'react-i18next';

import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { setSlotsDetails } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';

import { useAppDispatch } from 'common/hooks/redux';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { dateWithoutTimezone } from 'common/helpers/dateWithoutTimezone';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import { TimeTableCellProps, WeeklyViewProps } from './modules';
import { TimeScaleLabelStyled, TimeTableCellStyled } from './styles';

dayjs.extend(isBetweenPlugin);

const TimeScaleLabel = (props: any) => {
  const minute = dayjs(props.time).minute();
  const time = dayjs(props.time).format('HH:mm');

  return (
    <TimeScaleLabelStyled isHour={minute === 0}>
      {minute === 0 ? time : ''}
    </TimeScaleLabelStyled>
  );
};

const TimeTableCell: FC<TimeTableCellProps> = ({
  startDate,
  endDate,
  groupingInfo,
  workingHours,
  absences,
  scrollEvent,
}) => {
  const { t, i18n } = useTranslation();
  const currentProfessional = groupingInfo?.length ? groupingInfo[0] : null;
  const isCanCreateAbsence = userHasPermission(UserPermissions.createAbsence);
  const isCanCreateAppointment = userHasPermission(
    UserPermissions.createAppointment,
  );
  const isCanCreateEvents = isCanCreateAbsence || isCanCreateAppointment;
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLTableCellElement | null>(null);
  const { tz } = useUserTimeZone();
  const currentStartDate = dayjs(startDate);
  const currentEndDate = dayjs(endDate);
  const minute = dayjs(endDate).minute();
  const isWorkingTime = workingHours
    .filter((item) => currentProfessional?.id === item.priorityId)
    .some((item) => {
      const isStartDate = currentStartDate.isSame(dayjs(item.startDate));
      const isEndDate = currentEndDate.isSame(dayjs(item.endDate));

      return (
        isStartDate ||
        isEndDate ||
        currentEndDate.isBetween(dayjs(item.startDate), dayjs(item.endDate))
      );
    });

  const absenceByPriorityId = absences.filter(
    (item) => groupingInfo && item.priorityId === groupingInfo[0].id,
  );

  const absence = absenceByPriorityId.find(
    (item) =>
      dayjs(item.startDate).isSame(startDate) ||
      dayjs(item.endDate).isSame(endDate) ||
      currentEndDate.isBetween(dayjs(item.startDate), dayjs(item.endDate)),
  );
  const isEarliestWorkingHour =
    scrollEvent &&
    dayjs(scrollEvent.startDate).toString() === dayjs(startDate).toString();

  useCallback(() => {
    if (ref.current) {
      const wrapper = document.querySelector('.MainLayout-container')!;
      wrapper.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [ref.current])();

  useEffect(() => {
    if (absence) {
      const absenceDiffDays = absenceByPriorityId.some(
        (item) => !dayjs(item.startDate).isSame(item.endDate, 'day'),
      );
      const elements = document.querySelectorAll(`[datatype="${absence.id}"]`);

      if (elements.length) {
        if (absenceDiffDays) {
          const absenceFirstDayElements: Element[] = [];
          elements.forEach((item) => {
            const currentAbsence = absenceByPriorityId.find(
              (absence) =>
                absence.id === item.attributes.getNamedItem('datatype')?.value,
            );
            const isSameDay = dayjs(
              dateWithoutTimezone(new Date(item.id)),
            ).isSame(dayjs(currentAbsence?.startDate), 'day');
            if (isSameDay) {
              absenceFirstDayElements.push(item);
            }
          });

          if (absenceFirstDayElements.length) {
            absenceFirstDayElements[0].innerHTML = `<span>${t(
              'appointment.absence',
            )}`;
            if (absence.reason) {
              if (absenceFirstDayElements[1]) {
                absenceFirstDayElements[1].innerHTML = `<p>${absence.reason}</p>`;
              }
              if (absenceDiffDays && !absenceFirstDayElements[1]) {
                elements[0].innerHTML = `<p>${absence.reason}</p>`;
              }
            }
          }
        } else {
          elements[0].innerHTML = `<span>${t('appointment.absence')}`;
          if (elements.length && elements[1] && absence.reason) {
            elements[1].innerHTML = `<p>${absence.reason}</p>`;
          }
        }
      }
    }
  }, [i18n.language]);

  const reserveSlot = () => {
    const isValidDate = dayjs
      .tz(dateWithoutTimezone(startDate as Date), tz)
      .isAfter(dayjs.tz(new Date(), tz));

    if (absence) {
      return dispatch(
        setSlotsDetails({
          professionalId: String(currentProfessional?.id),
          absenceId: absence.id,
          mode: ReserveSlotsMode.EditAbsence,
        }),
      );
    }

    const newTimeZoneHours = new Date().setHours(dayjs().tz().get('hour'));
    const currentTimezoneDate = new Date(newTimeZoneHours).setDate(
      dayjs().tz().get('date'),
    );

    return dispatch(
      setSlotsDetails({
        professionalId: String(currentProfessional?.id),
        mode: isCanCreateAppointment
          ? ReserveSlotsMode.CreateAppointment
          : ReserveSlotsMode.CreateAbsence,
        startDate: isValidDate
          ? dayjs.tz(dayjs(startDate).tz(), tz).toDate()
          : new Date(currentTimezoneDate),
      }),
    );
  };

  return (
    <TimeTableCellStyled
      datatype={absence?.id ? absence.id : ''}
      ref={isEarliestWorkingHour ? ref : null}
      isHour={minute === 0 || minute === 30}
      isWorkingTime={isWorkingTime}
      isAbsence={!!absence}
      id={dayjs(startDate).toString()}
      onClick={() => {
        isCanCreateEvents && reserveSlot();
      }}
    />
  );
};

const WeeklyView: FC<WeeklyViewProps> = ({
  workingHours,
  dayHour,
  absences,
  scrollEvent,
  excludedDays,
}) => {
  return (
    <WeekView
      startDayHour={dayHour.start}
      endDayHour={dayHour.end}
      cellDuration={10}
      excludedDays={excludedDays}
      timeScaleLabelComponent={TimeScaleLabel}
      timeTableCellComponent={(props) => (
        <TimeTableCell
          workingHours={workingHours}
          absences={absences}
          scrollEvent={scrollEvent}
          {...props}
        />
      )}
    />
  );
};

export default memo(WeeklyView);
