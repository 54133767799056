import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccessIcon } from 'applicaiton/assets/success-icon.svg';
import { StyledModal } from './styles';

interface SuccessModalProps {
  fullName: string;
  onClose: () => void;
}

const SuccessModal: FC<SuccessModalProps> = ({ fullName, onClose }) => {
  const { t } = useTranslation();
  return (
    <StyledModal>
      <CloseIcon onClick={onClose} />
      <div>
        <SuccessIcon />
        <p>
          {t('my_patients.delete_patient.success.titlePart1')}{' '}
          <span>{fullName}</span>{' '}
          {t('my_patients.delete_patient.success.titlePart2')}
        </p>
        <SecondaryButton onClick={onClose}>
          {t('my_patients.delete_patient.success.ok')}
        </SecondaryButton>
      </div>
    </StyledModal>
  );
};

export default SuccessModal;
