import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';

export const InsurancesWrapper = styled.div`
  margin-bottom: 32px;
  min-height: 340px;

  > label {
    display: block;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const InsurancesInput = styled.div`
  width: 464px;
`;

export const DividerStyled = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.color.grey200};
`;

export const InsurancesToAddSection = styled.div`
  margin-top: 16px;

  > label {
    display: block;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const InsurancesToAdd = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InsurancesItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  margin-right: 12px;
  margin-bottom: 12px;
  gap: 4px;
  border-radius: 100px;
  background: ${({ theme }) => theme.color.grey200};
`;

export const StyledCloseButton = styled(CloseIcon)`
  cursor: pointer;
  margin-left: 4px;
  width: 16px;
  height: 16px;
  path {
    fill: ${({ theme }) => theme.color.grey600};
  }
`;
