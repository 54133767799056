import styled from 'styled-components';

export const PaymentOrderStyled = styled.section`
  width: 50%;
  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    > h1 {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 18px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.5px;
    }

    > button {
      background: none;
      border: none;
      cursor: pointer;
      color: ${({ theme }) => theme.color.blue800};
      font-size: 16px;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.08px;
    }
  }

  > table {
    width: 100%;
    height: fit-content;
    background: ${({ theme }) => theme.color.background};

    tbody {
      border-collapse: separate;
      border-spacing: 0 16px;
      border-radius: 8px;
      box-shadow: 0 0 0 1px #6be4ff;
      padding: 0 16px;

      display: table-cell;
      tr {
        color: ${({ theme }) => theme.color.grey600};
        font-size: 16px;
        font-family: Mulish;
        font-weight: 500;
        line-height: 140%;
        td {
          vertical-align: top;
        }

        td:first-child {
          width: 100%;
          padding-right: 20px;
          p {
            color: ${({ theme }) => theme.color.grey900};
            font-size: 18px;
            font-weight: 700;
          }
        }
        td:nth-child(3) {
          padding: 0 12px;
          div {
            display: flex;
            align-items: center;
            border-left: 1px solid ${({ theme }) => theme.color.grey300};
            border-right: 1px solid ${({ theme }) => theme.color.grey300};
            padding: 0 12px;

            svg {
              margin-right: 8px;
            }
          }
        }
        td:last-child {
          text-align: right;
          font-size: 16px;
          font-family: Mulish;
          font-weight: 700;
        }

        &:last-child {
          color: ${({ theme }) => theme.color.grey900};
          text-align: right;
          font-size: 16px;
          font-weight: 700;
          line-height: 140%;
          td {
            padding: 0;
          }
        }
      }
    }
  }
`;

export const SummarySectionStyled = styled.div`
  margin-top: 16px;
  > p {
    color: ${({ theme }) => theme.color.grey900};
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 8px;
  }
  > h2 {
    color: ${({ theme }) => theme.color.grey900};
    text-align: right;
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
  }
`;
