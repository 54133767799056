import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
`;

export const DatePickersSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > div:last-child {
    margin-top: 16px;
  }
`;

export const TimePickersSection = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;

  .MuiFormControl-root {
    width: 100%;
  }

  & > div:last-child {
    margin-top: 16px;
  }
`;

export const TimePickerWrapper = styled.div`
  margin-left: 12px;

  label {
    display: block;
    margin-bottom: 6px;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;
