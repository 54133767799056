import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/unsuccess-icon.svg';

interface SuccessModalProps {
  onClose: () => void;
}

const ImportErrorModal: FC<SuccessModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <WarningIcon />
          <p>
            {t('patient.import_error_modal.title')}
            <span>{t('patient.import_error_modal.text')}</span>
          </p>
          <SecondaryButton onClick={onClose}>{t('ok')}</SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default ImportErrorModal;
