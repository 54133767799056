import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  border-radius: 12px;
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  border: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
  }
`;
