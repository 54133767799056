import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { SubscriptionClinicT } from '../components/AddSubscriptionModal/types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export const useSubscriptionsForm = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const formSubscriptionValues = {
    clinicId: '',
    title: '',
    price: '1',
    durationInWeeks: 1,
    durationInMonth: 0,
    teleconsultationMinutes: 30,
    hours: [],
  };

  const subscriptionSchema = yup
    .object({
      clinicId: yup.string().required(requiredError!),
      title: yup.string().required(requiredError!),
      price: yup.string(),
      durationInWeeks: yup.number().required(requiredError!),
      teleconsultationMinutes: yup.number(),
      durationInMonth: yup.number(),
      hours: yup.array().min(0, requiredError!).required(requiredError!),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    register,
    formState: { errors },
  } = useForm<SubscriptionClinicT>({
    resolver: yupResolver(subscriptionSchema),
    defaultValues: formSubscriptionValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    register,
  };
};
