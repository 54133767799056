import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import ButtonSection from '../ButtonSection';
import { Wrapper, DividerStyled } from './styles';
import { useEmailForm } from '../../hooks/useEmailForm';
import { resetEmailData } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';
import EmailChangedPopUp from '../EmailChangedPopUp';
import ChangeEmailModal from '../ChangeEmailModal';
import { adminVerifyOldEmail } from 'applicaiton/store/reducers/Admins/ActionCreators';
import { resetAdminError } from 'applicaiton/store/reducers/Admins/AdminsSlice';

const Email: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { currentUser, isVerifiedOldEmail, error } = useAppSelector(
    (state) => state.admins,
  );

  const [showEmailPopUp, setShowEmailPopUp] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);

  const { register, errors, handleSubmit, watch, setValue } = useEmailForm({
    requiredError: t('errors.required'),
  });

  useEffect(() => {
    return () => {
      dispatch(resetAdminError());
    };
  }, []);

  useEffect(() => {
    setValue('email', currentUser?.email || '');
  }, [currentUser]);

  const handleCloseModal = () => {
    isEmailExist && setIsEmailExist(false);
    setShowEmailPopUp(false);
  };

  const handleCloseEmailChangeModal = () => {
    setValue('email', currentUser?.email || '');
    dispatch(resetEmailData());
  };

  const handleOpenForm = () => {
    sessionStorage.setItem('newEmail', watch('email'));
    sessionStorage.setItem('oldEmail', String(currentUser?.email));
    setShowEmailPopUp(false);

    dispatch(
      adminVerifyOldEmail({
        email: String(currentUser?.email),
      }),
    );
  };

  const onSubmit = () => {
    setShowEmailPopUp(true);
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Input
        id="email"
        type={'email'}
        label={t('email') || ''}
        register={register}
        errorMessage={
          errors.email?.message || (error && t('error_attempt_limit')) || ''
        }
      />
      <div>
        <DividerStyled />
        <ButtonSection
          disabled={
            !!Object.keys(errors).length ||
            watch('email') === currentUser?.email
          }
        />
      </div>
      {showEmailPopUp && (
        <EmailChangedPopUp
          onClose={handleCloseModal}
          onSubmit={handleOpenForm}
        />
      )}
      {isVerifiedOldEmail && (
        <ChangeEmailModal
          isOpen={isVerifiedOldEmail}
          onClose={handleCloseEmailChangeModal}
        />
      )}
    </Wrapper>
  );
};

export default Email;
