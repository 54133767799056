import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledModal, ButtonWrapper } from './styles';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/unsuccess-icon.svg';
import { PrimaryButton, SecondaryButton } from 'common/components';

interface SuccessModalProps {
  onClose: () => void;
}

const WarningModal: FC<SuccessModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <StyledModal>
      <CloseIcon onClick={onClose} />
      <div>
        <WarningIcon />
        <h1>{t('absenceWarningModal.title')}</h1>
        <p>{t('absenceWarningModal.text')}</p>
        <ButtonWrapper>
          <SecondaryButton onClick={onClose}>
            {t('absenceWarningModal.continue_booking')}
          </SecondaryButton>
          <PrimaryButton onClick={() => {}}>
            {t('absenceWarningModal.show_in_calendar')}
          </PrimaryButton>
        </ButtonWrapper>
      </div>
    </StyledModal>
  );
};

export default WarningModal;
