import styled from 'styled-components';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';

interface Props {
  isError?: boolean;
}

export const Wrapper = styled.div`
  flex-grow: 1;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  > h1 {
    color: ${({ theme }) => theme.color.grey800};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.1px;
  }

  > div {
    display: flex;

    > button {
      height: 100%;
      display: flex;
      border: none;
      background: none;
      padding: 0;
      color: ${({ theme }) => theme.color.grey600};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22.4px;
      letter-spacing: 0.08px;
      cursor: pointer;

      &:first-child {
        margin-right: 16px;
      }

      > svg {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        cursor: pointer;

        g {
          path {
            fill: ${({ theme }) => theme.color.grey600};
          }
        }
      }
    }
  }
`;

export const ScheduleFieldItem = styled.div<Props>`
  margin-bottom: 24px;

  label {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error2 : theme.color.grey600};
    margin-bottom: 16px;
  }
`;

export const ScheduleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ButtonWrapper = styled.div`
  max-width: 225px;
`;

export const AddClinic = styled(AddIcon)`
  path {
    fill: ${({ theme }) => theme.color.grey700};
  }
`;

export const DividerStyled = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background: ${({ theme }) => theme.color.grey200};
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const MainButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;
