import styled from 'styled-components';

export const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
  > p {
    color: ${({ theme }) => theme.color.grey800};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }
  > span {
    color: ${({ theme }) => theme.color.green600};
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
  }
`;
