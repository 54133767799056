import React, { useState } from 'react';
import {
  ButtonWrapper,
  CodeActive,
  InputWrapper,
  NoCode,
  StyledDivider,
  VerifyText,
  Wrapper,
} from './styles';
import { Input, PrimaryButton, SecondaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import LeavePageModal from '../../LeavePageModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { AuthLocalStorage } from 'applicaiton/sessionStorage/auth';
import TimeCounter from 'common/components/TimeCounter';
import SuccessPhoneModal from '../SuccessPhoneModal';
import { VerifyPhoneProps } from './modules';
import { UserType } from '@docbay/schemas';
import {
  adminSetNewPhone,
  adminVerifyCode,
  adminVerifyPhone,
} from 'applicaiton/store/reducers/Admins/ActionCreators';
import {
  setIsResentActive,
  setIsWrongVerificationCode,
} from 'applicaiton/store/reducers/Admins/AdminsSlice';
import { DocBaySupportLink } from 'applicaiton';
import { Link } from 'react-router-dom';

const VerifyPhone = ({ onNext, isNewPhone, onCancel }: VerifyPhoneProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [codeValue, setCodeValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const oldPhone = sessionStorage.getItem('oldPhone');
  const newPhone = sessionStorage.getItem('newPhone');
  const [timeCounter, setTimeCounter] = useState(true);
  const [timerMinutes, setTimerMinutes] = useState(5);
  const [resetTimeCounter, setResetTimeCounter] = useState(true);
  const [resetTimerMinutes, setResetTimerMinutes] = useState(1);

  const { isResentActive, isWrongVerificationCode, error } = useAppSelector(
    (state) => state.admins,
  );
  const attemptLimitExceeded = error.endsWith('500');
  const handleSubmit = () => {
    onCancel();
  };

  const handleNext = () => {
    const refreshToken = JSON.parse(
      localStorage.getItem(AuthLocalStorage.refreshToken) as string,
    );

    if (isNewPhone) {
      dispatch(
        adminSetNewPhone({
          oldPhone: String(oldPhone),
          phone: String(newPhone),
          code: codeValue,
          userType: 'Admin' as UserType,
          refreshToken: refreshToken as string,
        }),
      ).then((data) => {
        if (data.payload.token) {
          setSuccessModal(true);
        }
      });
    } else {
      dispatch(
        adminVerifyCode({
          phone: String(oldPhone),
          code: codeValue,
          userType: 'Admin' as UserType,
          refreshToken: refreshToken as string,
        }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          dispatch(setIsResentActive(true));
          data.payload && onNext();
        }
      });
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
    onCancel();
  };

  const handleCounterChange = (isStarted: boolean) => {
    setTimeCounter(isStarted);
  };

  const handleResetCounterChange = (isStarted: boolean) => {
    setResetTimeCounter(isStarted);
  };

  const showResetButton = () => {
    dispatch(setIsResentActive(true));
  };

  const handleResendCode = () => {
    dispatch(
      adminVerifyPhone({
        phone: String(isNewPhone ? newPhone : oldPhone),
        userType: 'Admin' as UserType,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        handleCounterChange(true);
        handleResetCounterChange(true);
        setTimerMinutes(5);
        setResetTimerMinutes(1);
        dispatch(setIsResentActive(false));
      }
    });
  };

  const handleChangeCode = (value: string) => {
    isWrongVerificationCode && dispatch(setIsWrongVerificationCode(false));
    setCodeValue(value);
  };

  return (
    <Wrapper>
      <VerifyText>
        {isNewPhone
          ? t('changePhone.phone.enter_code_for_new_phone')
          : t('changePhone.phone.enter_code_for_old_phone')}
        <br />
        <span>{isNewPhone ? newPhone : oldPhone}</span>
      </VerifyText>
      <CodeActive>
        {t('changePhone.phone.code_be_active')}
        <TimeCounter
          key={'generalTime'}
          timeMins={timerMinutes}
          isStart={timeCounter}
          onChange={handleCounterChange}
        />
      </CodeActive>
      <InputWrapper>
        <Input
          id={'code'}
          value={codeValue}
          onChange={(e) => {
            handleChangeCode(e.target.value);
          }}
          label={t('changePhone.phone.verification_code')!}
          placeholder={t('changePhone.phone.enter_code')!}
          type={'text'}
          errorMessage={
            isWrongVerificationCode
              ? t('changePhone.phone.wrong_code')!
              : (attemptLimitExceeded && t('error_attempt_limit')) || ''
          }
        />
      </InputWrapper>

      <NoCode>
        <div>{t('changePhone.phone.still_no_code')}</div>
        <div>
          <Link to={DocBaySupportLink} target="_blank">
            {t('changePhone.phone.go_to_support')}
          </Link>
          <span>|</span>
          <div
            className={!isResentActive ? 'disabled' : ''}
            onClick={handleResendCode}
          >
            {t('changePhone.phone.resend_code')}
          </div>
          {!isResentActive && (
            <TimeCounter
              key={'resentButton'}
              timeMins={resetTimerMinutes}
              isStart={resetTimeCounter}
              onChange={handleResetCounterChange}
              showResetButton={showResetButton}
            />
          )}
        </div>
      </NoCode>
      <StyledDivider />
      <ButtonWrapper>
        <SecondaryButton onClick={() => setShowModal(true)}>
          {t('cancel')}
        </SecondaryButton>
        <PrimaryButton onClick={handleNext} disabled={!codeValue.length}>
          {isNewPhone ? t('save') : t('next')}
        </PrimaryButton>
      </ButtonWrapper>
      <LeavePageModal
        showModal={showModal}
        onClose={handleCancel}
        onSubmit={handleSubmit}
      />
      {showSuccessModal && (
        <SuccessPhoneModal onClose={handleCloseSuccessModal} />
      )}
    </Wrapper>
  );
};

export default VerifyPhone;
