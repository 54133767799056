import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
  }

  h3 {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-bottom: 8px;
  }
`;

export const SwitchStyled = styled.div`
  display: flex;

  > p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-left: 8px;
  }

  & .MuiSwitch-track {
    background-color: ${({ theme }) => theme.color.grey400};
  }
`;
