export enum UserPermissions {
  createAppointment = 'CreateAppointment',
  createAbsence = 'CreateAbsence',
  rescheduleAppointment = 'RescheduleAppointment',
  cancelAgenda = 'CancelAgenda',
  professionalOpeningHours = 'ProfessionalOpeningHours',
  accessToOtherAgendas = 'AccessToOtherAgendas',
  clinicOpeningHours = 'ClinicOpeningHours',
  clinicFAQ = 'ClinicFAQ',
  clinicTeleconsultationPayment = 'ClinicTeleconsultationPayment',
  clinicDescription = 'ClinicDescription',
  clinicProfessionalsManagement = 'ClinicProfessionalsManagement',
  clinicAppointmentTypesConfiguration = 'ClinicAppointmentTypesConfiguration',
  clinicDeletion = 'ClinicDeletion',
  clinicCommunicationWithPatients = 'ClinicCommunicationWithPatients',
  clinicInsurancesConfiguration = 'ClinicInsurancesConfiguration',
}
