import { createSlice } from '@reduxjs/toolkit';
import {
  fetchNotificationsSettings,
  updateNotificationsSettings,
} from './ActionCreators';
import { InitialState } from './models';

const initialState: InitialState = {
  isLoading: false,
  error: '',
  notificationsSettings: null,
};

const notificationsSettingsSlice = createSlice({
  name: 'notificationsSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch notifications settings
    builder.addCase(fetchNotificationsSettings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNotificationsSettings.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchNotificationsSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notificationsSettings = action.payload;
    });

    // update notifications settings
    builder.addCase(updateNotificationsSettings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateNotificationsSettings.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateNotificationsSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notificationsSettings = action.payload;
    });
  },
});

export default notificationsSettingsSlice.reducer;
