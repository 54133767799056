import React, { FC, useEffect } from 'react';
import { Modal } from '@mui/material';
import { DeleteModal, SuccessModal } from './components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader } from 'common/components';
import { deleteAbsence } from 'applicaiton/store/reducers/Absence/ActionCreators';
import {
  resetAbsence,
  setShowDeleteModal,
} from 'applicaiton/store/reducers/Absence/AbsenceSlice';
import {
  refreshAgenda,
  setIsAddNewEvent,
} from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { setSlotsDetails } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import dayjs from 'dayjs';
import { fetchConfusingAgenda } from 'applicaiton/store/reducers/ConfusingAgenda/ActionCreators';

const DeleteAbsenceModal: FC = () => {
  const {
    isLoading,
    absence,
    deletedSuccess,
    showDeleteModal,
    isConfusingAbsence,
  } = useAppSelector((state) => state.absence);
  const { confusingAgendas } = useAppSelector(
    (state) => state.confusingAgendas,
  );
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setShowDeleteModal(false));
  };

  const handleCloseSuccess = () => {
    dispatch(resetAbsence());
    dispatch(setIsAddNewEvent(true));
    dispatch(refreshAgenda());
    !isConfusingAbsence && dispatch(setSlotsDetails({ mode: null }));
  };

  const handleSubmit = async () => {
    const response = await dispatch(deleteAbsence(String(absence?.id)));
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(setIsAddNewEvent(true));
      dispatch(refreshAgenda());
      if (confusingAgendas) {
        await dispatch(
          fetchConfusingAgenda({
            data: {
              startDate: dayjs(absence?.startDate).toDate(),
              endDate: dayjs(absence?.endDate).toDate(),
              professionalId: absence?.professional?.id || '',
              clinicId: absence?.clinic?.id,
            },
            isOnSave: false,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (!deletedSuccess) return;
    const timer = setTimeout(() => {
      handleCloseSuccess();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [deletedSuccess]);

  return (
    <Modal open={!!showDeleteModal}>
      <>
        {isLoading && <Loader />}
        {!deletedSuccess ? (
          <DeleteModal
            onClose={handleClose}
            onSubmit={handleSubmit}
            startDate={absence?.startDate || ''}
            endDate={absence?.endDate || ''}
          />
        ) : (
          <SuccessModal onClose={handleCloseSuccess} />
        )}
      </>
    </Modal>
  );
};

export default DeleteAbsenceModal;
