import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar.svg';
import { ReactComponent as BellIcon } from '../assets/bell.svg';
import { ReactComponent as BadgeIcon } from 'applicaiton/assets/badge-menu.svg';
import { ReactComponent as SettingsIcon } from 'applicaiton/assets/settings.svg';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import { ReactComponent as DocBoxIcon } from 'applicaiton/assets/docbox.svg';
import { ReactComponent as SubscriptionsIcon } from 'applicaiton/assets/subscriptions.svg';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { userRoleExactMatch } from 'common/helpers/userRoleExactMatch';
import { isShowClinicsForProfessional } from 'common/helpers/permissions';

export const useMenuList = () => {
  const { t } = useTranslation();
  const userRole = getUserRole();
  const isClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const isAdmin = userRoleExactMatch(UserRoles.admin);
  const isSecretary = userRole?.includes(UserRoles.secretary);
  const doShowAgenda = userHasPermission(UserPermissions.accessToOtherAgendas);
  const { isPrivateDoctor } = useClinicOwner();
  const canUserManageProfessionals = userHasPermission(
    UserPermissions.clinicProfessionalsManagement,
  );
  const showClinicsForProfessional = isShowClinicsForProfessional();
  const isUserRoleClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const isUserRoleProfessional = userRole?.includes(UserRoles.professional);

  const usersMenu = {
    path: PathNames.professionals,
    name: t('clinicsConfiguration.aside_menu.staff'),
    image: BadgeIcon,
    availablePaths: [PathNames.professionals, PathNames.myUsers],
  };

  const settingsMenu = {
    path: PathNames.myClinics,
    name: t('clinicsConfiguration.aside_menu.settings'),
    image: SettingsIcon,
    availablePaths: [
      PathNames.myClinics,
      PathNames.typesAppointments,
      PathNames.userPermissions,
      PathNames.notificationsSettings,
      PathNames.clinicBilling,
    ],
  };

  const docBoxMenu = {
    path: PathNames.chats,
    name: t('clinicsConfiguration.aside_menu.doc_box'),
    image: DocBoxIcon,
    availablePaths: [PathNames.chats],
  };
  const subscriptionsMenu = {
    path: PathNames.mySubscribers,
    name: t('clinicsConfiguration.aside_menu.subscriptions'),
    image: SubscriptionsIcon,
    availablePaths: [
      PathNames.mySubscribers,
      PathNames.topicsOfChats,
      PathNames.subscriptionSettings,
      PathNames.subscriptionsStatistics,
      PathNames.clinicOwnerProfessionalSubscriptions,
    ],
  };
  const list = [
    {
      path: PathNames.schedule,
      name: t('clinicsConfiguration.aside_menu.clinics_agendas'),
      image: CalendarIcon,
      availablePaths: [PathNames.schedule],
    },
    docBoxMenu,
    subscriptionsMenu,
    ...(canUserManageProfessionals && !isPrivateDoctor ? [usersMenu] : []),
    {
      path: PathNames.notifications,
      name: t('clinicsConfiguration.aside_menu.notifications'),
      image: BellIcon,
      availablePaths: [PathNames.notifications],
    },
    ...(showClinicsForProfessional || isUserRoleClinicOwner
      ? [settingsMenu]
      : []),
  ];

  if (isAdmin || isSecretary) {
    return [
      ...(doShowAgenda
        ? [
            {
              path: PathNames.schedule,
              name: t('clinicsConfiguration.aside_menu.clinics_agendas'),
              image: CalendarIcon,
              availablePaths: [PathNames.schedule],
            },
          ]
        : []),
      ...(canUserManageProfessionals && !isPrivateDoctor ? [usersMenu] : []),
      ...(showClinicsForProfessional || isUserRoleClinicOwner
        ? [settingsMenu]
        : []),
    ];
  }

  if (isClinicOwner) {
    const professionalMenu = [
      {
        path: PathNames.clinicOwnerSchedule,
        name: t('clinicsConfiguration.aside_menu.clinics_agendas'),
        image: CalendarIcon,
        availablePaths: [PathNames.clinicOwnerSchedule],
      },
    ];
    const clinicsAgendaMenu = [
      {
        path: PathNames.schedule,
        name: t('clinicsConfiguration.aside_menu.clinics_agendas'),
        image: CalendarIcon,
        availablePaths: [PathNames.schedule],
      },
    ];

    return [
      ...(!isPrivateDoctor ? clinicsAgendaMenu : professionalMenu),
      {
        path: PathNames.myPatients,
        name: t('clinicsConfiguration.aside_menu.my_patients'),
        image: PersonIcon,
        availablePaths: [PathNames.myPatients, PathNames.waitingList],
      },
      ...(isUserRoleProfessional ? [docBoxMenu] : []),
      ...(isUserRoleProfessional ? [subscriptionsMenu] : []),
      ...(canUserManageProfessionals && !isPrivateDoctor ? [usersMenu] : []),
      {
        path: PathNames.notifications,
        name: t('clinicsConfiguration.aside_menu.notifications'),
        image: BellIcon,
        availablePaths: [PathNames.notifications],
      },
      ...(showClinicsForProfessional || isUserRoleClinicOwner
        ? [settingsMenu]
        : []),
    ];
  }

  return list;
};
