import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  StyledBlueLink,
  StyledTypography,
} from 'features/feature-login/styles';

import { ForgotPasswordStyled, ForgotPasswordWrapper } from './styles';
import DividerGroup from 'common/components/DividerGroup';
import { PathNames } from 'applicaiton/routes';

const ForgotPasswordFooter: FC = () => {
  const { t } = useTranslation();

  return (
    <ForgotPasswordWrapper>
      <DividerGroup />

      <ForgotPasswordStyled>
        <StyledTypography variant="body1">
          {t('auth.log_in.dont_have_an_account')}
        </StyledTypography>

        <StyledBlueLink to={PathNames.clinicRegistration}>
          {t('auth.log_in.register')}
        </StyledBlueLink>
      </ForgotPasswordStyled>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPasswordFooter;
