import React, { useState } from 'react';
import { Wrapper } from './styles';
import { ChatsSidebarHeader } from '../ChatsSidebarHeader';
import { ChatsTabSelector } from '../ChatsTabSelector';
import { ChatsSearch } from '../ChatsSearch';
import { ChatsList } from '../ChatsList';
import { NewChatModal } from '../NewChatModal';
import { useChats } from '../../hooks/useChats';
import { useAppSelector } from 'common/hooks/redux';
import { ChatsMode } from 'applicaiton/constants/chats';
import { NewMassMessagesModal } from '../NewMassMessagesModal';

export const ChatsSideBar = () => {
  const [newChatModalOpen, setNewChatModalOpen] = useState(false);
  const [newMassMessagesModalOpen, setNewMassMessagesModalOpen] =
    useState(false);
  const { createChat } = useChats();
  const { mode } = useAppSelector((state) => state.chats);

  const handleCreateChat = (patientId: string) => {
    createChat(patientId);
  };

  return (
    <Wrapper>
      <ChatsSidebarHeader
        onNewChatClick={() => setNewChatModalOpen(true)}
        onNewMassMessagesClick={() => setNewMassMessagesModalOpen(true)}
      />
      {/*todo uncomment after search will be implemented*/}
      <ChatsSearch />
      {mode === ChatsMode.Chats && <ChatsTabSelector />}

      <ChatsList />
      <NewChatModal
        isOpen={newChatModalOpen}
        onClose={() => setNewChatModalOpen(false)}
        onCreateChat={handleCreateChat}
      />
      <NewMassMessagesModal
        isOpen={newMassMessagesModalOpen}
        onClose={() => setNewMassMessagesModalOpen(false)}
      />
    </Wrapper>
  );
};
