import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const ParticipantStyled = styled.div`
  > div {
    position: absolute;
    width: 230px !important;
    height: 180px !important;
    bottom: 30px;
    right: 30px;

    video {
      border-radius: 12px;
      box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
      border: 2px solid white;
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
    @media screen and (max-width: 480px) {
      width: 113px !important;
      height: 176px !important;
    }
  }
`;

export const RemoteParticipantStyled = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  video {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
    object-fit: cover;
  }
`;
