export const nifValidate = (nif: string) => {
  if (!/^[123]|45|5/.test(nif)) return false;

  // digit check
  let tot =
    +String(nif)[0] * 9 +
    +String(nif)[1] * 8 +
    +String(nif)[2] * 7 +
    +String(nif)[3] * 6 +
    +String(nif)[4] * 5 +
    +String(nif)[5] * 4 +
    +String(nif)[6] * 3 +
    +String(nif)[7] * 2;

  let div = tot / 11;
  let mod = tot - parseInt(String(div)) * 11;
  let tst = mod === 1 || mod === 0 ? 0 : 11 - mod;

  return parseInt(String(nif)[8]) === tst;
};
