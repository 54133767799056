import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';

import { ContactsType } from 'common/types/clinics';
import { ContactsChildProps, ContactsFormValues } from '../models';

import AddAnother from '../AddAnother';
import { InputPhone } from 'common/components';

import {
  Close,
  FlexContainer,
  InputContainer,
  WrapperContact,
} from '../styles';
import { EmergencyMessage } from './styles';
import { ReactComponent as AttentionIcon } from 'applicaiton/assets/attention.svg';

const Emergency = ({
  errors,
  control,
  register,
  getValues,
  handleRemoveContact,
}: ContactsChildProps) => {
  const { t } = useTranslation();

  const prependKey = 'emergency';
  const { fields, append, remove } = useFieldArray<ContactsFormValues>({
    control,
    name: prependKey,
  });

  const handleAddAnother = () => {
    append({
      type: 'EmergencyPhone' as ContactsType,
      contactDetail: '',
    });
  };

  const removeContact = (index: number) => {
    const contacts = getValues(prependKey);
    if (contacts.length) {
      const contactWithId = contacts.find(
        (item, idx) => !!item.id && idx === index,
      );
      if (contactWithId) {
        handleRemoveContact(contactWithId.id);
      }
    }
    remove(index);
  };

  return (
    <WrapperContact>
      <label>{t('edit_contacts.emergency_label')}</label>
      {fields.map((field, index) => {
        const id = `${prependKey}.${index}.contactDetail`;
        const value = getValues(`${prependKey}.${index}.contactDetail`);
        const error = errors?.[prependKey]?.[index]?.contactDetail?.message;
        return (
          <div key={field.id}>
            <FlexContainer>
              <InputContainer>
                <InputPhone
                  id={id}
                  value={value}
                  isValid={!error}
                  register={register}
                  errorMessage={error}
                  allowChangeDialCode={true}
                />
              </InputContainer>
              {index === 0 ? (
                <EmergencyMessage>
                  <AttentionIcon />
                  <p>{t('edit_contacts.emergency_tip')}</p>
                </EmergencyMessage>
              ) : (
                <Close onClick={() => removeContact(index)} />
              )}
            </FlexContainer>
          </div>
        );
      })}

      <AddAnother title={t('add_another')} onClick={handleAddAnother} />
    </WrapperContact>
  );
};

export default Emergency;
