import styled from 'styled-components';

export const TimerStyled = styled.div`
  display: flex;
  align-items: center;
  h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 64px;
    font-weight: 700;
    line-height: 89.6px;
  }

  @media screen and (max-width: 600px) {
    h1 {
      font-size: 48px;
      line-height: 67.2px;
    }
  }
`;
