import styled from 'styled-components';
import { ReactComponent as ChevronRightIcon } from 'applicaiton/assets/chevron-right.svg';

interface PatientInfoItemProps {
  isStrokeSvg?: boolean;
}

export const SelectedPatient = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey300};

  > div:first-child {
    width: 100%;
  }

  &.isTeleconsultation {
    flex-direction: column;
    padding: 0;
    border: none;

    div:last-child {
      margin-top: 12px;
    }
  }
`;

export const PatientInfoItem = styled.div<PatientInfoItemProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.color.grey700};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.08px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  > svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme, isStrokeSvg }) =>
        !isStrokeSvg ? theme.color.grey600 : ''};
      stroke: ${({ theme, isStrokeSvg }) =>
        isStrokeSvg ? theme.color.grey600 : ''};
    }
  }

  > span {
    color: ${({ theme }) => theme.color.grey500};
    font-weight: 500;
    margin-left: 8px;
    margin-right: 6px;
  }
`;

export const SeeProfile = styled.div`
  margin-top: 12px;
  > div {
    display: flex;
    align-items: center;
    > a {
      color: ${({ theme }) => theme.color.blue800};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.08px;
      cursor: pointer;
      text-decoration: none;
    }
  }
`;

export const StyledChevronRightIcon = styled(ChevronRightIcon)`
  margin-left: 4px;
  path {
    fill: ${({ theme }) => theme.color.blue800};
  }
`;

export const MemberStyled = styled.div`
  width: 100%;
  padding: 12px 0;
  border-radius: 12px;
  background: ${({ theme }) => theme.color.grey100};
  margin-bottom: 12px;

  > h2 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.16px;
    margin-bottom: 8px;
    padding: 0 12px;
  }
`;
