import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RestartIcon } from 'applicaiton/assets/restart_alt.svg';
import { Input, SecondaryButton } from 'common/components';
import { EmailSection, SectionStyled } from './styles';
import { GeneralInfoSectionProps } from './modules';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useParams } from 'react-router-dom';
import { resendProfessionalAuthorizationData } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';

const GeneralInfoSection: FC<GeneralInfoSectionProps> = ({
  isActive,
  register,
  errors,
  disableEditing,
  isClinicOwnerProfessional,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const userRole = getUserRole();
  const isProfessional = userRole === UserRoles.professional;
  const { isClinicOwnerProfile } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );

  const handleResetLink = () => {
    dispatch(resendProfessionalAuthorizationData(String(id)));
  };

  const emailErrorMessage = !isActive
    ? t('errors.not_active_professional')
    : errors.email?.message;

  return (
    <SectionStyled>
      <Input
        id={'firstName'}
        type="text"
        label={t('personal_information.first_name') || ''}
        placeholder={t('personal_information.first_name_placeholder') || ''}
        register={register}
        errorMessage={errors.firstName?.message}
        disabled={disableEditing}
      />

      <Input
        id={'lastName'}
        type="text"
        label={t('personal_information.last_name') || ''}
        placeholder={t('personal_information.last_name_placeholder') || ''}
        register={register}
        errorMessage={errors.lastName?.message}
        disabled={disableEditing}
      />
      {!isProfessional && !isClinicOwnerProfile && (
        <EmailSection>
          <Input
            id={'email'}
            type="email"
            label={t('email') || ''}
            placeholder={t('email_placeholder') || ''}
            register={register}
            errorMessage={emailErrorMessage}
            disabled={disableEditing || isClinicOwnerProfessional}
          />
          {!isActive && (
            <SecondaryButton styleType={'color'} onClick={handleResetLink}>
              <RestartIcon />
              {t('resend_link')}
            </SecondaryButton>
          )}
        </EmailSection>
      )}
      <Input
        id={'medicalNumber'}
        type="text"
        label={t('personal_information.medical_number') || ''}
        placeholder={t('personal_information.medical_number_placeholder') || ''}
        register={register}
        errorMessage={errors.medicalNumber?.message}
        disabled={disableEditing}
      />
    </SectionStyled>
  );
};

export default GeneralInfoSection;
