import { Box, styled } from '@mui/system';

export const SearchInput = styled(Box)`
  position: relative;
  min-width: 324px;
`;

export const SearchResultsWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(100%);
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.common.grey300};
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  padding: 8px;
  z-index: 99;
  max-height: 300px;
  overflow-y: auto;
`;

export const ResultsItemStyled = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 8px;
  margin-bottom: 4px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.palette.common.grey100};
  }

  > svg {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.palette.common.grey500};
  }

  > div {
    margin-left: 8px;
  }

  .name {
    color: ${({ theme }) => theme.palette.common.grey800};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.04px;
  }

  .phone {
    color: ${({ theme }) => theme.palette.common.grey600};
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: 0.035px;
  }
`;

export const EmptyResults = styled(Box)`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
  transform: translateY(100%);
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.common.grey300};
  background: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  padding: 12px;
  z-index: 99;

  > svg {
    height: 40%;
  }

  > p {
    color: ${({ theme }) => theme.palette.common.grey900};
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    margin-left: 12px;
  }
`;
