import React, { FC, useEffect, useMemo } from 'react';
import { TimerProps } from './modules';
import { TimerStyled } from './styles';
import { useTimer } from 'common/hooks/useTimer';
import { secondsToTime } from 'common/helpers/dateTimeFormat';

const Timer: FC<TimerProps> = ({ startDate, setIsEventStarted }) => {
  const { secondsLeft, startTimerToDate, isRunning } = useTimer();
  useEffect(() => {
    startDate && startTimerToDate(startDate);
  }, [startDate]);

  useEffect(() => {
    if (isRunning && secondsLeft <= 0) {
      setIsEventStarted(true);
    }
  }, [secondsLeft, startDate]);

  const timer = useMemo(() => {
    return secondsLeft ? secondsToTime(secondsLeft) : '--:--';
  }, [secondsLeft]);

  return (
    <TimerStyled className="timer">
      <h1>{timer}</h1>
    </TimerStyled>
  );
};

export default React.memo(Timer);
