import React, { FC } from 'react';

import { AuthPageWrapper } from '../AuthPageLayout';
import { LoginPage } from 'features';

export const Login: FC = () => {
  return (
    <AuthPageWrapper>
      <LoginPage />
    </AuthPageWrapper>
  );
};
