import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputPhone } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import ButtonSection from 'features/feature-clinic-owner-profile/components/ButtonSection';
import { DividerStyled, Wrapper } from './styles';
import ChangePhoneModal from '../ChangePhoneModal';
import { clinicOwnerVerifyPhone } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { resetPhoneData } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';
import { usePhoneForm } from 'features/feature-clinic-owner-profile/hooks/usePhoneForm';
import { UserType } from '@docbay/schemas/dist/user/user.types';
import PhoneChangedPopUp from '../PhoneChangedPopUp';

const Phone: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isLoading, currentClinicOwner, isVerifiedOldPhone, error } =
    useAppSelector((state) => state.clinicOwnerSlice);
  const [showPhonePopUp, setShowPhonePopUp] = useState(false);
  const [isPhoneExist, setIsPhoneExist] = useState(false);
  const attemptLimitExceeded = error.endsWith('500');
  const { register, errors, handleSubmit, watch, setValue, getValues } =
    usePhoneForm({
      requiredError: t('errors.required'),
    });

  useEffect(() => {
    setValue('phone', currentClinicOwner?.phone || '');
  }, [currentClinicOwner]);

  const handleCloseModal = () => {
    isPhoneExist && setIsPhoneExist(false);
    setShowPhonePopUp(false);
  };

  const handleClosePhoneChangeModal = () => {
    setValue('phone', currentClinicOwner?.phone || '');
    dispatch(resetPhoneData());
  };

  const handleOpenForm = async () => {
    sessionStorage.setItem('newPhone', `+${watch('phone')}`);
    sessionStorage.setItem('oldPhone', String(currentClinicOwner?.phone));
    setShowPhonePopUp(false);

    dispatch(
      clinicOwnerVerifyPhone({
        phone: String(currentClinicOwner?.phone),
        userType: UserType.ClinicOwner,
      }),
    );
  };

  const onSubmit = () => {
    setShowPhonePopUp(true);
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <div>
        <InputPhone
          id="phone"
          label={t('phone') || ''}
          register={register}
          errorMessage={
            attemptLimitExceeded
              ? t('error_attempt_limit') || ''
              : errors.phone?.message
          }
          value={getValues('phone')}
          isValid={true}
        />
      </div>
      <div>
        <DividerStyled />
        <ButtonSection
          disabled={
            !!Object.keys(errors).length ||
            watch('phone') === currentClinicOwner?.phone ||
            isLoading
          }
        />
      </div>
      {showPhonePopUp && (
        <PhoneChangedPopUp
          onClose={handleCloseModal}
          onSubmit={handleOpenForm}
        />
      )}
      {isVerifiedOldPhone && (
        <ChangePhoneModal
          isOpen={isVerifiedOldPhone}
          onClose={handleClosePhoneChangeModal}
        />
      )}
    </Wrapper>
  );
};

export default Phone;
