import React, { FC } from 'react';
import { AuthPageWrapper } from '../AuthPageLayout';
import { CreateNewPasswordPage } from 'features';

export const CreateNewPassword: FC = () => {
  return (
    <AuthPageWrapper>
      <CreateNewPasswordPage />
    </AuthPageWrapper>
  );
};
