import { ColorsPallet } from 'applicaiton/theme';
import styled from 'styled-components';

interface StyledButtonProps {
  styleType: 'default' | 'error';
  disabled?: boolean;
  theme: { color: ColorsPallet };
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 10px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.08px;
  color: ${({ theme, styleType }) =>
    styleType === 'default' ? theme.color.grey900 : theme.color.grey50};
  background-color: ${({ theme, styleType }) =>
    styleType === 'default' ? theme.color.blue300 : theme.color.error2};
  box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
  border: none;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme, styleType }) =>
      styleType === 'default' ? theme.color.blue400 : theme.color.error2};
  }

  :disabled {
    background-color: ${({ theme, styleType }) =>
      styleType === 'default' ? theme.color.grey300 : theme.color.error2};
    color: ${({ theme, styleType }) =>
      styleType === 'default' ? theme.color.grey600 : theme.color.grey50};
    cursor: not-allowed;
  }
`;
