import styled from 'styled-components';
import { commonColors } from 'applicaiton/theme';
import { StyledProps } from './models';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  height: 100%;
  padding: 16px;
  border-right: 1px solid ${commonColors.grey200};
`;

export const CreatePatientButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  margin-top: 4px;

  color: ${commonColors.grey600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.08px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 4px;

    path {
      fill: ${commonColors.grey600};
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: ${commonColors.grey400};
    svg {
      path {
        fill: ${commonColors.grey400};
      }
    }
  }
`;

export const PatientsTabsStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const TabsStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const Tab = styled.button<StyledProps>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px 4px;

  display: flex;
  align-items: center;
  border-bottom: 2px solid
    ${({ isActive, hasError }) =>
      isActive
        ? commonColors.blue700
        : hasError
        ? commonColors.error2
        : 'transparent'};

  color: ${({ isActive, hasError }) =>
    isActive
      ? commonColors.blue700
      : hasError
      ? commonColors.error2
      : commonColors.grey600};
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 22.4px;

  &:first-of-type {
    margin-right: 20px;
  }
`;
