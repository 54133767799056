import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccessIcon } from 'applicaiton/assets/success-icon.svg';
import { StyledModal } from './styles';

interface SuccessModalProps {
  open: boolean;
  onClose: () => void;
}

const SuccessModal: FC<SuccessModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Modal open={open}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccessIcon />
          <p>{t('my_patients.create_patient.title')} </p>
          <SecondaryButton onClick={onClose}>
            {t('my_patients.delete_patient.success.ok')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessModal;
