import styled from 'styled-components';

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 16px 24px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;

    &:first-child {
      border-left: 1px solid ${({ theme }) => theme.color.grey200};
      font-weight: 700;
      width: 275px;
    }

    &:last-child {
      border-right: 1px solid ${({ theme }) => theme.color.grey200};
      width: unset;
      margin: auto;
    }
  }
`;

export const ActionButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.blue800};
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  margin-inline-start: inherit;
`;
