import styled from 'styled-components';
import { RadioButtonStyledProps } from './modules';

export const RadioButtonStyled = styled.div<RadioButtonStyledProps>`
  display: flex;
  align-items: center;

  > label {
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    color: ${({ theme, disabled }) =>
      disabled ? theme.color.grey400 : theme.color.grey700};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    margin-left: 8px;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: ${({ theme }) => theme.color.white};
    margin: 0;

    color: currentColor;
    width: 20px;
    height: 20px;
    border: 1px solid ${({ theme }) => theme.color.grey400};
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }

  input[type='radio']::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 12px 12px
      ${({ theme, disabled }) =>
        disabled ? theme.color.grey500 : theme.color.blue500};
    border-radius: 100px;
    background: ${({ theme }) => theme.color.white};
  }

  input[type='radio']:checked {
    border: 1px solid
      ${({ theme, disabled }) =>
        disabled ? theme.color.grey500 : theme.color.blue500};
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
`;
