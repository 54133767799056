import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { styledTheme } from 'applicaiton/theme';

export const ModalContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  > section {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
  }
`;

export const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  > svg {
    width: 82px;
    height: 82px;
  }
`;

export const Title = styled(Typography)`
  color: ${styledTheme.color.grey900};
  text-align: center;
  font-family: 'AcidGrotesk-Medium';
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;
`;

export const CheckboxStyled = styled(Box)`
  > div {
    input + label:before {
      width: 18px;
      height: 18px;
    }
  }
`;
