import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Format } from 'applicaiton/constants/scheduler';
import { store } from 'applicaiton/store';

dayjs.extend(utc, localizedFormat);
dayjs.extend(timezone);

export const getTimeWithRoundedMinutes = (date: Date) => {
  const dateObj = dayjs(date);
  const currentDateMinute = dateObj.get('minute');
  return dateObj
    .set('minute', Math.ceil(currentDateMinute / 30) * 30)
    .set('second', 0)
    .toDate();
};

export const addMinutes = (date: Date, minutes: number) => {
  return dayjs(date).add(minutes, 'minutes').toDate();
};

export const formatDateTimeInDefaultTimezone = (
  date: Date | string,
  format: string,
  locale?: string | ILocale,
) => {
  const DEFAULT_TIMEZONE = store.getState().timeZone.tz;
  let dateInTz = dayjs.tz(date, DEFAULT_TIMEZONE);
  dateInTz = dateInTz.isUTC() ? dateInTz : dayjs(date).tz(DEFAULT_TIMEZONE);
  if (locale) {
    dateInTz = dateInTz.locale(locale);
  }
  return dateInTz.format(format);
};

export const fromUtcToDefaultTimezone = (date: Date) => {
  const DEFAULT_TIMEZONE = store.getState().timeZone.tz;
  const dateInTz = dayjs(date).tz(DEFAULT_TIMEZONE);
  return new Date(dateInTz.format(Format.dateTimeFormat));
};

export const getDayjsFromUTCInDefaultTimezone = (date: Date | null) => {
  const DEFAULT_TIMEZONE = store.getState().timeZone.tz;
  return dayjs(date).tz(DEFAULT_TIMEZONE);
};

//this method required to solve daylight saving time issue
export const getDateInDefaultTimezone = (date: Date | null) => {
  const DEFAULT_TIMEZONE = store.getState().timeZone.tz;
  let dateInTz = dayjs.tz(date, DEFAULT_TIMEZONE);
  return dateInTz.isUTC() ? dateInTz : dayjs(date).tz(DEFAULT_TIMEZONE);
};

export const getRoundedDateInUTC = (date: Date | string) => {
  let resultDate = dayjs(date);
  const hours = resultDate.get('hour');
  const daysToAdd = hours >= 12 ? 1 : 0;
  resultDate = resultDate.add(daysToAdd, 'day');
  resultDate = resultDate.startOf('date');
  return resultDate;
};

export const updateDateAndTime = (
  initialDateTime: Date,
  newDate?: string,
  newTime?: string,
) => {
  let [initDate, initTime] = initialDateTime.toString().split('T');
  if (newDate) {
    initDate = newDate;
  }
  if (newTime) {
    initTime = newTime;
  }
  return `${initDate}T${initTime}`;
};
