import React, { FC, memo, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  GroupingState,
  IntegratedGrouping,
  ViewState,
} from '@devexpress/dx-react-scheduler';
import {
  Appointments,
  Scheduler,
  GroupingPanel,
  Resources,
} from '@devexpress/dx-react-scheduler-material-ui';

import { CalendarView, ViewBy } from 'applicaiton/constants/scheduler';

import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import { getSelectedDays } from 'features/feature-scheduler/helpers/getSelectedDays';

import DailyView from '../DailyView';
import Appointment from '../Apointment';
import GroupingPanelCell from '../GroupingPanelCell';
import { Event } from '../SchedulerByProfessional/module';

import { SchedulerByAvailabilityProps } from './module';

const SchedulerByAvailability: FC<SchedulerByAvailabilityProps> = ({
  appointments,
  absences,
  currentLanguage,
  grouping,
  workingHours,
  resources,
  dayHour,
  selectedDate,
  daysCount,
  viewBy,
  calendarView,
  isAddNewEvent,
  clinicWorkingHours,
}) => {
  const { tz } = useUserTimeZone();
  const isDayView = calendarView === CalendarView.daily;
  const isViewByAvailability = viewBy === ViewBy.byAvailability;

  const getIntervalCount = () => {
    if (isViewByAvailability) {
      return daysCount;
    }
    return isDayView ? 1 : 7;
  };

  const { startDate } = getSelectedDays(
    !isDayView,
    selectedDate || dayjs().toString(),
  );

  const earlierEvent = useMemo(() => {
    const isHighLightAppointment = appointments.some(
      (item) => !!item.highLight,
    );
    if (isAddNewEvent || isHighLightAppointment) return { type: null };
    const scheduler = clinicWorkingHours ? clinicWorkingHours : workingHours;
    const workingHoursFiltered = !isDayView
      ? scheduler.filter(
          (item) => dayjs.tz(item.startDate, tz).hour() >= dayHour.start,
        )
      : scheduler.filter((item) => {
          return (
            dayjs(item.startDate).format('DD/MM/YYY') ===
              dayjs(selectedDate).format('DD/MM/YYY') &&
            dayjs.tz(item.startDate, tz).hour() >= dayHour.start
          );
        });
    const appointmentsFiltered = !isDayView
      ? appointments.filter(
          (item) => dayjs.tz(item.startDate, tz).hour() >= dayHour.start,
        )
      : appointments.filter((item) => {
          return (
            dayjs(item.startDate).format('DD/MM/YYY') ===
              dayjs(selectedDate).format('DD/MM/YYY') &&
            dayjs.tz(item.startDate, tz).hour() >= dayHour.start
          );
        });

    const earlessAppointment = appointmentsFiltered.length
      ? appointmentsFiltered.reduce(
          (acc, curr) => {
            return (acc = dayjs(curr.startDate)
              .set('day', 1)
              .isBefore(dayjs(acc.startDate).set('day', 1))
              ? curr
              : acc);
          },
          { startDate: dayjs().set('year', 3000).toDate() },
        )
      : { startDate: dayjs().set('year', 3000).toDate() };

    const earlessWorkingHour = workingHoursFiltered.length
      ? workingHoursFiltered.reduce(
          (acc, curr) => {
            return (acc = dayjs(curr.startDate)
              .set('day', 1)
              .isBefore(dayjs(acc.startDate).set('day', 1))
              ? curr
              : acc);
          },
          { startDate: dayjs().set('year', 3000).toDate() },
        )
      : { startDate: dayjs().set('year', 3000).toDate() };

    const event: Event = [
      {
        type: 'appointment',
        ...earlessAppointment,
      },
      {
        type: 'workingHour',
        ...earlessWorkingHour,
      },
    ].reduce(
      (acc, curr) => {
        return (acc = dayjs(curr.startDate)
          .set('day', 1)
          .isBefore(dayjs(acc.startDate).set('day', 1))
          ? curr
          : acc);
      },
      { startDate: dayjs().set('year', 3000).toDate() },
    );

    return event;
  }, [
    workingHours,
    appointments,
    selectedDate,
    isDayView,
    clinicWorkingHours,
    dayHour,
  ]);

  return (
    <Scheduler data={appointments} locale={currentLanguage}>
      <ViewState
        currentDate={
          isDayView || isViewByAvailability ? selectedDate : startDate
        }
      />
      <GroupingState grouping={grouping} />
      <DailyView
        workingHours={workingHours}
        intervalCount={getIntervalCount()}
        dayHour={dayHour}
        absences={absences}
        scrollEvent={earlierEvent.type === 'workingHour' ? earlierEvent : null}
      />

      <Appointments
        appointmentComponent={(props) => (
          <Appointment
            isWeeklyView={!isDayView}
            isGroupingView={grouping.length > 1}
            scrollEvent={
              earlierEvent.type === 'appointment' ? earlierEvent : null
            }
            {...props}
          />
        )}
      />
      <Resources data={resources} />
      <IntegratedGrouping />

      <GroupingPanel cellComponent={GroupingPanelCell} />
    </Scheduler>
  );
};

export default memo(SchedulerByAvailability);
