import React, { FC, useEffect } from 'react';
import { Input } from 'common/components';
import { MetricItem } from './styles';
import { useFieldArray } from 'react-hook-form';
import { ConsultationData } from '../../hooks/useConsultationDataForm';
import { useTranslation } from 'react-i18next';
import { Props } from './models';
import { useAppSelector } from 'common/hooks/redux';
import { FieldType } from '../types';
import { useLanguage } from 'common/hooks/useLanguage';

const MetricsSection: FC<Props> = ({ register, errors, control }) => {
  const { i18n } = useTranslation();
  const { language } = useLanguage();

  const { data, healthMetrics } = useAppSelector(
    (state) => state.consultationData,
  );
  const prependKey = 'metric';
  const { fields, append, remove } = useFieldArray<ConsultationData>({
    control,
    name: prependKey,
  });

  useEffect(() => {
    remove();
    if (healthMetrics?.length) {
      const metricsFromData = data?.healthMetrics?.map((item) => {
        return item.healthMetric;
      });

      healthMetrics?.forEach((item) => {
        const currentMetric = item?.localizations?.find(
          (metric) => metric.languageIsoCode === language,
        );

        if (data?.healthMetrics?.length && metricsFromData?.length) {
          const currentData = data.healthMetrics.find(
            (metric) => metric.healthMetric?.id === item?.id,
          );

          append(
            {
              type: 'Metric' as FieldType,
              metricName: currentMetric?.translation!,
              metricId: item?.id!,
              unitName: currentMetric?.unitOfMeasurement?.name!,
              value: currentData?.value ? String(currentData?.value) : '',
            },
            { shouldFocus: false },
          );
        } else {
          append(
            {
              type: 'Metric' as FieldType,
              metricName: currentMetric?.translation!,
              metricId: item?.id!,
              unitName: currentMetric?.unitOfMeasurement?.name!,
              value: '',
            },
            { shouldFocus: false },
          );
        }
      });
    }
  }, [healthMetrics, i18n.language, data?.healthMetrics, language]);

  return (
    <>
      {fields.map((item, index) => {
        const id = `${prependKey}.${index}.value`;
        const error = errors?.[prependKey]?.[index]?.id?.message;

        return (
          <MetricItem key={id}>
            <Input
              id={id}
              register={register}
              type={'number'}
              label={item.metricName}
              errorMessage={error}
              onWheel={(e) => e.currentTarget.blur()}
            />
            <span>{item.unitName}</span>
          </MetricItem>
        );
      })}
    </>
  );
};

export default MetricsSection;
