import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/warning.svg';
import { Wrapper } from './styles';

const AppointmentTypeDeletedBanner: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <WarningIcon />
      <p>{t('appointment_types.appointment_type_was_deleted')}</p>
    </Wrapper>
  );
};

export default AppointmentTypeDeletedBanner;
