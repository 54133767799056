import styled from 'styled-components';

export const ProfessionalItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  border-radius: 8px;

  > div {
    display: flex;
    align-items: center;
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.blueDark100};
      margin-right: 8px;
      color: ${({ theme }) => theme.color.white};
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    p {
      color: ${({ theme }) => theme.color.grey900};
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 25.2px;
      text-transform: uppercase;
    }
  }
`;

export const ProfessionalInfo = styled.div`
  > h2 {
    color: ${({ theme }) => theme.color.grey900};
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
  }
  > div {
    display: flex;
    align-items: center;

    > p {
      color: ${({ theme }) => theme.color.grey600};
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      margin-left: 4px;
      text-transform: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }
`;
