import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as USAIcon } from 'applicaiton/assets/countries-flags/usa.svg';
import { ReactComponent as PortugalIcon } from 'applicaiton/assets/countries-flags/portugal.svg';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { DropdownProps } from './models';
import { DropdownStyled, OptionItem, OptionsList } from './styles';

const LanguageDropdown: FC<DropdownProps> = ({
  id,
  placeholder,
  value,
  onChange,
  disabled,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  const optionList = useMemo(() => {
    return [
      { value: 'pt', label: 'Portugal', image: PortugalIcon },
      {
        value: 'en',
        label: 'English',
        image: USAIcon,
      },
    ];
  }, []);

  const handleChange = (value: string) => {
    onChange(value);
    setIsActive(false);
  };

  const getValue = () => {
    const currentLanguage = optionList.find((item) => item.value === value);
    return currentLanguage?.label || '';
  };

  return (
    <DropdownStyled
      isActive={isActive}
      disabled={disabled}
      ref={ref}
      isError={!!errorMessage}
    >
      <label htmlFor={id}>{t('language')}</label>
      <section onMouseDown={() => !disabled && setIsActive(!isActive)}>
        {!value ? <span>{placeholder}</span> : <h2>{getValue()}</h2>}
        <ExpandMoreIcon />
      </section>
      {isActive && (
        <OptionsList className="optionList">
          {optionList.map((item) => (
            <OptionItem
              key={item.value}
              onClick={() => {
                handleChange(item.value);
              }}
              selected={value === String(item.value)}
            >
              <item.image /> {item.label}
            </OptionItem>
          ))}
        </OptionsList>
      )}
    </DropdownStyled>
  );
};

export default LanguageDropdown;
