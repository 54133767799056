import { createSlice } from '@reduxjs/toolkit';
import { fetchProfessionalsSpokenLanguages } from './ActionCreators';
import { ProfessionalsSpokenLanguagesState } from './models';

const initialState: ProfessionalsSpokenLanguagesState = {
  languages: [],
  isLoading: false,
  error: '',
};

const professionalsSpokenLanguagesSlice = createSlice({
  name: 'professionalsSpokenLanguagesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //fetchProfessionalsSpokenLanguages fetch
    builder.addCase(fetchProfessionalsSpokenLanguages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchProfessionalsSpokenLanguages.rejected,
      (state, action) => {
        state.isLoading = false;
        state.languages = [];
        state.error = action.payload as string;
      },
    );
    builder.addCase(
      fetchProfessionalsSpokenLanguages.fulfilled,
      (state, action) => {
        state.languages = action.payload.entities;
        state.isLoading = false;
      },
    );
  },
});

export default professionalsSpokenLanguagesSlice.reducer;
