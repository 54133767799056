import React, { useState } from 'react';
import {
  Wrapper,
  StyledSection,
  StepSection,
  StepItem,
  Step,
  StepText,
  StepSectionWrapper,
  BackToProfile,
  SetNewEmailTitle,
  FormWrapper,
} from './styles';

import { ReactComponent as CheckIcon } from 'applicaiton/assets/checked.svg';
import { ReactComponent as ArrowLeftIcon } from 'applicaiton/assets/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import VerifyEmail from '../VerifyEmail';
import NewEmail from '../NewEmail';
import { ChangeEmailModalProps } from './modules';

const ChangeEmailModal = ({ isOpen, onClose }: ChangeEmailModalProps) => {
  const [activeForm, setActiveForm] = useState<number>(0);
  const { t } = useTranslation();

  const setNextStep = () => {
    setActiveForm((prev) => prev + 1);
  };

  const cancelEmailChange = () => {
    setActiveForm(0);
    onClose();
  };

  const getCurrentForm = () => {
    switch (activeForm) {
      case 0:
        return (
          <VerifyEmail onNext={setNextStep} onCancel={cancelEmailChange} />
        );
      case 1:
        return <NewEmail onNext={setNextStep} onCancel={cancelEmailChange} />;
      default:
        return (
          <VerifyEmail
            isNewEmail
            onNext={setNextStep}
            onCancel={cancelEmailChange}
          />
        );
    }
  };

  const verifyExistingEmailStep = activeForm === 0;
  const newEmailStep = activeForm === 1;
  const verifyNewEmailStep = activeForm === 2;

  return (
    <Modal open={isOpen}>
      <Wrapper>
        <StyledSection>
          <BackToProfile onClick={onClose}>
            <ArrowLeftIcon />
            {t('clinic_owner.email.back_to_profile')}
          </BackToProfile>
          <SetNewEmailTitle>
            {t('clinic_owner.email.set_new_email')}
          </SetNewEmailTitle>
          <FormWrapper>
            <StepSectionWrapper>
              <StepSection>
                <StepItem>
                  {activeForm < 1 ? (
                    <Step isActive={verifyExistingEmailStep}>1</Step>
                  ) : (
                    <Step isActive={true}>
                      <CheckIcon />
                    </Step>
                  )}
                  <StepText isActive={verifyExistingEmailStep}>
                    {t('clinic_owner.email.verify_existing_email')}
                  </StepText>
                </StepItem>
                <StepItem>
                  {activeForm < 2 ? (
                    <Step isActive={newEmailStep}>2</Step>
                  ) : (
                    <Step isActive={true}>
                      <CheckIcon />
                    </Step>
                  )}
                  <StepText isActive={newEmailStep}>
                    {t('clinic_owner.email.new_email')}
                  </StepText>
                </StepItem>
                <StepItem>
                  {activeForm < 2 ? (
                    <Step isActive={verifyNewEmailStep}>2</Step>
                  ) : (
                    <Step isActive={true}>
                      <CheckIcon />
                    </Step>
                  )}
                  <StepText isActive={verifyNewEmailStep}>
                    {t('clinic_owner.email.verify_new_email')}
                  </StepText>
                </StepItem>
              </StepSection>
            </StepSectionWrapper>
            {getCurrentForm()}
          </FormWrapper>
        </StyledSection>
      </Wrapper>
    </Modal>
  );
};

export default ChangeEmailModal;
