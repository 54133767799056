import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteDocuments,
  postDocuments,
  postDocumentsToAppointment,
  editDocuments,
} from './ActionCreators';
import { InitialDocumentState } from './models';
import { AppointmentDocumentDto } from '@docbay/schemas';

const initialState: InitialDocumentState = {
  isLoading: false,
  appointmentDocuments: [],
  deletedDocument: null,
  error: '',
};

const appointmentDocumentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setAppointmentDocuments: (
      state,
      actions: PayloadAction<AppointmentDocumentDto[]>,
    ) => {
      state.appointmentDocuments = actions.payload;
    },
    setDeletedDocument: (
      state,
      actions: PayloadAction<AppointmentDocumentDto | null>,
    ) => {
      state.deletedDocument = actions.payload;
    },
    resetDocumentsErrors: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postDocuments.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(postDocuments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(postDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.appointmentDocuments = [
        ...state.appointmentDocuments,
        ...action.payload,
      ];
    });
    //upload document to existing appointment
    builder.addCase(postDocumentsToAppointment.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(postDocumentsToAppointment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(postDocumentsToAppointment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
    });
    // delete document
    builder.addCase(deleteDocuments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteDocuments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteDocuments.fulfilled, (state) => {
      state.isLoading = false;
    });
    // edit document
    builder.addCase(editDocuments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editDocuments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(editDocuments.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setAppointmentDocuments,
  setDeletedDocument,
  resetDocumentsErrors,
} = appointmentDocumentsSlice.actions;

export default appointmentDocumentsSlice.reducer;
