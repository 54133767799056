import React, { useEffect, useMemo, useState } from 'react';
import { Input, PrimaryButton, SecondaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import { Wrapper, InputWrapper, ButtonWrapper, StyledDivider } from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { NewEmailProps } from './modules';
import { secretaryVerifyOldEmail } from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import {
  resetSecretaryError,
  resetVerifiedOldEmailCode,
} from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';

const NewEmail = ({ onNext, onCancel }: NewEmailProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const newEmail = sessionStorage.getItem('newEmail');
  const [email, setEmail] = useState(newEmail);

  const { error } = useAppSelector((state) => state.secretaries);

  useEffect(() => {
    dispatch(resetVerifiedOldEmailCode());

    return () => {
      dispatch(resetSecretaryError());
    };
  }, []);

  const handleNext = () => {
    dispatch(
      secretaryVerifyOldEmail({
        email: String(email),
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        onNext();
      }
    });
  };

  const emailErrorMessage = useMemo(() => {
    return error.includes('400')
      ? t('user_exist')
      : error
      ? t('error_attempt_limit')
      : '';
  }, [error]);

  return (
    <Wrapper>
      <InputWrapper>
        <Input
          id={'newEmail'}
          placeholder={t('auth.enter_your_email')!}
          type={'email'}
          label={t('email')!}
          onChange={(e) => setEmail(e.target.value)}
          value={email!}
          errorMessage={emailErrorMessage}
        />
      </InputWrapper>
      <StyledDivider />
      <ButtonWrapper>
        <SecondaryButton onClick={onCancel}>{t('cancel')}</SecondaryButton>
        <PrimaryButton onClick={handleNext}> {t('next')}</PrimaryButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default NewEmail;
