import React, { FC } from 'react';
import { PatientDataProfile, SideMenu } from 'features';
import { Wrapper } from './styles';

const PatientDataPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <PatientDataProfile />
    </Wrapper>
  );
};

export default PatientDataPage;
