import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 20px;
`;

export const DividerStyled = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.color.grey300};
  margin: 0 20px;
`;

export const InfoSection = styled.div`
  display: grid;
  gap: 4px;
  grid-template-rows: max-content;
  width: inherit;

  > div:last-child {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      path {
        fill: ${({ theme }) => theme.color.grey600};
      }
    }
    p {
      color: ${({ theme }) => theme.color.grey800};
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
      letter-spacing: 0.08px;
    }
  }

  @media screen and (max-width: 600px) {
    > div:last-child {
      display: none;
    }
  }
`;

export const SupportButton = styled(Link)`
  text-decoration: none;
  min-width: fit-content;
  > button {
    width: fit-content;
    height: 36px;
    background-color: ${({ theme }) => theme.color.white};

    > svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }
`;

export const Title = styled.div`
  display: flex;
  overflow-x: hidden;

  h2 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:first-child {
      margin-right: 5px;
    }
  }

  @media screen and (max-width: 600px) {
    display: grid;
    gap: 4px;
    grid-template-rows: max-content;
    width: inherit;

    h2 {
      font-size: 16px;
      line-height: 22.4px;
    }
  }
`;
