import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchAppointmentById } from 'applicaiton/store/reducers/Appointments/ActionCreators';

export const useAppointment = () => {
  const dispatch = useAppDispatch();
  const { currentAppointment, isLoading } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const fetchAppointment = async (id: number | string) => {
    dispatch(fetchAppointmentById(String(id)));
  };

  return {
    currentAppointment,
    fetchAppointmentById: fetchAppointment,
    isLoading,
  };
};
