import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { PrimaryButton, RadioButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { RadioButtonSection, StyledModal } from './styles';
import { Modal } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  UserType,
  useUserTypeOptions,
} from 'features/feature-users-list/hooks/useUserTypeOptions';
import { Link, useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  user: UserType;
}

const AddUserModal: FC<AddUserModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userTypes = useUserTypeOptions();

  const userTypeSchema = yup
    .object({
      user: yup.string(),
    })
    .required();
  const { setValue, watch, handleSubmit, reset } = useForm<FormData>({
    resolver: yupResolver(userTypeSchema),
    defaultValues: { user: UserType.Admin },
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = ({ user }: FormData) => {
    user === UserType.Admin
      ? navigate(PathNames.addAdmin)
      : navigate(PathNames.addSecretary);
  };

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />

        <h1>
          {t('users.add_user_modal.title')}

          <div>
            {t('users.add_user_modal.read_more')}{' '}
            <Link to={PathNames.userPermissions}>
              {t('users.add_user_modal.docbay_permissions')}
            </Link>
          </div>
        </h1>

        <RadioButtonSection>
          {userTypes.map((item) => (
            <RadioButton
              key={item.value}
              id={item.value}
              label={item.label}
              checked={watch('user') === item.value}
              onChange={(e) => setValue('user', e.target.id as UserType)}
            />
          ))}
        </RadioButtonSection>
        <section>
          <SecondaryButton onClick={handleClose}>{t('close')}</SecondaryButton>

          <PrimaryButton onClick={handleSubmit(onSubmit)} styleType={'default'}>
            {t('continue')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default AddUserModal;
