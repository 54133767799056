import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { ReactComponent as InfoIcon } from 'applicaiton/assets/info.svg';
import { ContactsType } from 'common/types/clinics';
import { ContactsChildProps, ContactsFormValues } from '../models';

import AddAnother from '../AddAnother';
import { Input } from 'common/components';

import {
  Close,
  InputContainer,
  InputWrapper,
  LabelWrapper,
  WrapperContact,
} from '../styles';

const Emails = ({
  errors,
  control,
  register,
  getValues,
  handleRemoveContact,
}: ContactsChildProps) => {
  const { t } = useTranslation();

  const prependKey = 'emails';
  const { fields, append, remove } = useFieldArray<ContactsFormValues>({
    control,
    name: prependKey,
  });

  const handleAddAnother = () => {
    append({
      type: 'Email' as ContactsType,
      contactDetail: '',
    });
  };

  const removeContact = (index: number) => {
    const contacts = getValues(prependKey);
    if (contacts.length) {
      const contactWithId = contacts.find(
        (item, idx) => !!item.id && idx === index,
      );
      if (contactWithId) {
        handleRemoveContact(contactWithId.id);
      }
    }
    remove(index);
  };

  const showRequiredIcon =
    getValues('emails')?.filter((item) => item.contactDetail).length === 0 ||
    errors?.emails?.length;

  return (
    <WrapperContact>
      <LabelWrapper>
        <label>{t('email')}</label>
        {showRequiredIcon && <InfoIcon />}
      </LabelWrapper>
      {fields.map((field, index) => {
        const id = `${prependKey}.${index}.contactDetail`;
        const error = errors?.[prependKey]?.[index]?.contactDetail?.message;

        return (
          <InputWrapper key={field.id} last={index === fields.length - 1}>
            <InputContainer>
              <Input
                id={id}
                type="email"
                register={register}
                errorMessage={error}
                placeholder={t('email_placeholder') as string}
              />
            </InputContainer>
            {index !== 0 && <Close onClick={() => removeContact(index)} />}
          </InputWrapper>
        );
      })}

      <AddAnother title={t('add_another')} onClick={handleAddAnother} />
    </WrapperContact>
  );
};

export default Emails;
