import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import ClinicsSlice from './reducers/Clinics/ClinicsSlice';
import ConfusingAgendaSlice from './reducers/ConfusingAgenda/ConfusingAgendaSlice';
import PatientsSlice from './reducers/Patients/PatientsSlice';
import { errorSlice, loadingSlice } from 'common';
import {
  MapBoxSlice,
  PhotoSlice,
  ClinicProfileSlice,
  ProfessionalProfileSlice,
  ProfessionalsSlice,
  CreateProfessionalProfileSlice,
  SpecializationsSlice,
  ClinicRegistrationSlice,
  DetectChangesSavedSlice,
  SchedulerSlice,
  InsurancesSlice,
  AgendaSlice,
  PatientAppointmentsSlice,
  AppointmentTypesSlice,
  UsersSlice,
  AppointmentsSlice,
  ConfusingAgendasSlice,
  AppointmentDocumentsSlice,
  AdminsSlice,
  ProfessionalsSpokenLanguagesSlice,
  ClinicOwnerSlice,
  SecretariesSlice,
  AbsenceSlice,
  TeleconsultationSlice,
  TopicsSlice,
  ChatsSlice,
  OnlineHoursSlice,
  ChatTeleconsultationSlice,
  NotificationsInboxSlice,
  NotificationsSettingsSlice,
  ConsultationSlice,
  TimeZoneSlice,
  CountriesSlice,
  PrivateDoctorSlice,
  AgreementsAndPoliciesSlice,
} from './reducers';
import { ResetStateActionType } from 'applicaiton/constants/store';

const reducer = combineReducers({
  clinics: ClinicsSlice,
  clinicProfile: ClinicProfileSlice,
  photos: PhotoSlice,
  mapBox: MapBoxSlice,
  professionals: ProfessionalsSlice,
  professionalProfile: ProfessionalProfileSlice,
  createProfessionalProfile: CreateProfessionalProfileSlice,
  specializations: SpecializationsSlice,
  clinicRegistrationSlice: ClinicRegistrationSlice,
  savedChanges: DetectChangesSavedSlice,
  scheduler: SchedulerSlice,
  insurances: InsurancesSlice,
  agenda: AgendaSlice,
  confusingAgendas: ConfusingAgendaSlice,
  patientAppointments: PatientAppointmentsSlice,
  appointmentsSlice: AppointmentsSlice,
  patients: PatientsSlice,
  appointmentTypes: AppointmentTypesSlice,
  confusingAgendasSlice: ConfusingAgendasSlice,
  error: errorSlice?.reducer,
  loading: loadingSlice?.reducer,
  users: UsersSlice,
  appointmentDocumentsSlice: AppointmentDocumentsSlice,
  admins: AdminsSlice,
  professionalsSpokenLanguages: ProfessionalsSpokenLanguagesSlice,
  clinicOwnerSlice: ClinicOwnerSlice,
  secretaries: SecretariesSlice,
  absence: AbsenceSlice,
  teleconsultation: TeleconsultationSlice,
  topics: TopicsSlice,
  chats: ChatsSlice,
  onlineHours: OnlineHoursSlice,
  chatTeleconsultation: ChatTeleconsultationSlice,
  notificationsInbox: NotificationsInboxSlice,
  notificationsSettings: NotificationsSettingsSlice,
  consultationData: ConsultationSlice,
  timeZone: TimeZoneSlice,
  countries: CountriesSlice,
  privateDoctor: PrivateDoctorSlice,
  agreementsAndPolicies: AgreementsAndPoliciesSlice,
});

const rootReducer: typeof reducer = (
  state: RootState | undefined,
  action: AnyAction,
) => {
  if (action.type === ResetStateActionType) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
