import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: 40px 0;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: ${({ theme }) => theme.color.grey700};
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.04px;

    span {
      font-weight: 900;
      color: ${({ theme }) => theme.color.blue800};
    }
  }

  button {
    width: auto;

    svg {
      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }
  }
  a {
    text-decoration: none;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    cursor: pointer;
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;
