import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getChats,
  getAccessToken,
  createChat,
  updateChat,
  archiveChat,
  deleteChat,
  postMassMessagesChat,
  startChatTeleconsultation,
  addDocumentMessage,
} from './ActionCreators';
import { ChatsReadMessageInfo, ChatsType, InitialState } from './models';
import { ChatsMode } from 'applicaiton/constants/chats';

const initialState: InitialState = {
  currentClient: null,
  selectedChatsType: ChatsType.ALL,
  selectedChat: null,
  mode: ChatsMode.Chats,
  massMessagesPatientsIds: null,
  massMessages: [],
  chats: {
    all: [],
    archived: [],
    emergency: [],
    teleconsultation: [],
  },
  accessToken: null,
  isLoading: false,
  error: '',
  room: '',
  isDocumentLoading: false,
  documentError: '',
  chatsReadMessagesInfo: [],
};

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setSelectedChatsType: (state, action) => {
      state.selectedChatsType = action.payload;
    },
    setSelectedChat: (state, action) => {
      state.selectedChat = action.payload;
    },
    updateSelectedChat: (state, action) => {
      if (
        state.selectedChat?.twilioConversationSID ===
        action.payload?.twilioConversationSID
      ) {
        state.selectedChat = action.payload;
      }
    },
    setChatMode: (state, action) => {
      state.mode = action.payload;
    },
    setMassMessagesPatientsIds: (state, action) => {
      state.massMessagesPatientsIds = action.payload;
    },
    deselectChat: (state, action) => {
      if (state.selectedChat?.twilioConversationSID === action.payload) {
        state.selectedChat = null;
      }
    },
    setCurrentClient: (state, action) => {
      state.currentClient = action.payload;
    },
    setChatsReadMessagesInfo: (
      state,
      action: PayloadAction<ChatsReadMessageInfo[]>,
    ) => {
      state.chatsReadMessagesInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get all chats
    builder.addCase(getChats.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getChats.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getChats.fulfilled, (state, action) => {
      state.chats = action.payload;
      state.isLoading = false;
    });
    // get accessToken
    builder.addCase(getAccessToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAccessToken.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getAccessToken.fulfilled, (state, action) => {
      state.accessToken = action.payload;
      state.isLoading = false;
    });
    // create chat
    builder.addCase(createChat.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createChat.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createChat.fulfilled, (state) => {
      state.isLoading = false;
    });
    // update chat
    builder.addCase(updateChat.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateChat.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateChat.fulfilled, (state) => {
      state.isLoading = false;
    });
    // archive chat
    builder.addCase(archiveChat.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(archiveChat.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(archiveChat.fulfilled, (state) => {
      state.isLoading = false;
    });
    // delete chat
    builder.addCase(deleteChat.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteChat.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteChat.fulfilled, (state) => {
      state.isLoading = false;
    });
    // post mass message
    builder.addCase(postMassMessagesChat.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postMassMessagesChat.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(postMassMessagesChat.fulfilled, (state, action) => {
      state.isLoading = false;
      state.massMessages = action.payload;
    });
    // start chat teleconsultation
    builder.addCase(startChatTeleconsultation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(startChatTeleconsultation.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(startChatTeleconsultation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.room = action.payload.room;
    });
    // post mass message
    builder.addCase(addDocumentMessage.pending, (state) => {
      state.isDocumentLoading = true;
    });
    builder.addCase(addDocumentMessage.rejected, (state, action) => {
      state.isDocumentLoading = false;
      state.documentError = action.payload as string;
    });
    builder.addCase(addDocumentMessage.fulfilled, (state, action) => {
      state.isDocumentLoading = false;
    });
  },
});

export const {
  setSelectedChatsType,
  setSelectedChat,
  setChatMode,
  setMassMessagesPatientsIds,
  updateSelectedChat,
  deselectChat,
  setCurrentClient,
  setChatsReadMessagesInfo,
} = chatsSlice.actions;

export default chatsSlice.reducer;
