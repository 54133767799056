import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { getReadableDuration } from './getReadableDuration';
dayjs.extend(duration);

export const getDurationOptions = (
  startDuration: number,
  endDuration: number,
  step: number,
) => {
  const stepCount = endDuration / step;

  let x = 1;
  const res =
    startDuration === 0
      ? [
          {
            label: '0 min',
            value: String(0),
          },
        ]
      : [];
  while (x < stepCount) {
    const formattedTime = getReadableDuration(x * step);

    if (startDuration <= x * step) {
      res.push({
        label: formattedTime,
        value: String(x * step),
      });
    }

    x++;
  }

  const restHours = Array.from(Array(25).keys())
    .filter((item) => !!item)
    .map((item) => ({
      label: `${item} h`,
      value: String(60 * item),
    }));

  return [...res, ...restHours];
};
