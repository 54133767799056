import styled from 'styled-components';

export const Wrapper = styled.main`
  display: grid;
  grid-template-columns: 252px 1fr;
  overflow-y: hidden;
  height: 100vh;
  width: 100%;
`;

export const SchedulerSection = styled.section`
  display: grid;
  grid-auto-rows: max-content 1fr;
  width: 100%;
  height: 100vh;
`;
