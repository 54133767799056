import { DeleteProfessionalModal, ProfessionalEdit, SideMenu } from 'features';
import React, { FC } from 'react';
import { Wrapper } from './styles';

const ProfessionalEditPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <ProfessionalEdit />
      <DeleteProfessionalModal />
    </Wrapper>
  );
};

export default ProfessionalEditPage;
