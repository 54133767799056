import styled from 'styled-components';
import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-pencil.svg';

export const Wrapper = styled.div`
  border-radius: 4px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey100};
  background: ${({ theme }) => theme.color.grey50};
  margin-bottom: 8px;
`;

export const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.color.grey600};
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.07px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  cursor: pointer;
  width: 20px;

  path {
    fill: ${({ theme }) => theme.color.grey600};
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  margin-left: 12px;
  cursor: pointer;
  width: 20px;

  path {
    fill: ${({ theme }) => theme.color.grey600};
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  margin-left: 12px;
  cursor: pointer;
  width: 20px;

  path {
    fill: ${({ theme }) => theme.color.grey600};
  }
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.color.grey900};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 4px;
`;

export const Type = styled.div`
  color: ${({ theme }) => theme.color.grey600};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 4px;
`;

export const MemberStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    min-height: 20px;
    min-width: 20px;
    height: 20px;
    width: 20px;
    margin-right: 6px;
    path {
      stroke: ${({ theme }) => theme.color.grey500};
    }
  }
  p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 4px;
  }
`;
