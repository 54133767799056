import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DropdownClinics, PrimaryButton } from 'common/components';
import {
  Wrapper,
  ButtonsWrapper,
  StyledDivider,
  SelectSection,
  SelectedClinicsCounts,
  SelectedClinics,
  SelectedClinicsItem,
  ClinicImage,
  ClinicInfo,
  StyledCloseIcon,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useClinicsForm } from '../../hooks/useClinicsForm';
import SuccessModal from '../SuccessModal';
import { PathNames } from 'applicaiton/routes';
import { resetAdminData } from 'applicaiton/store/reducers/Admins/AdminsSlice';
import { Option } from 'common/components/DropdownClinics/models';
import { ReactComponent as HomeIcon } from 'applicaiton/assets/home.svg';
import { editAdmin } from 'applicaiton/store/reducers/Admins/ActionCreators';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';

const Assigning: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addedClinics, setAddedClinics] = useState<Option[]>([]);

  const { errors, handleSubmit, setValue } = useClinicsForm({
    requiredError: t('errors.required'),
  });

  const { currentUser, isUpdated } = useAppSelector((state) => state.admins);
  const { clinics } = useAppSelector((state) => state.clinics);

  useEffect(() => {
    if (currentUser) {
      const filteredClinics = currentUser?.clinics?.map((clinic) => {
        const otherChainClinic = clinics.filter(
          (item) => item.id === clinic.id,
        );

        return {
          label: clinic.name!,
          value: clinic.id!,
          image: clinic.mainPhoto?.thumbnailUrl,
          address: clinic.address,
          isHidden: !otherChainClinic.length,
        };
      });

      setAddedClinics(filteredClinics || []);
      setValue('clinic', filteredClinics || []);
    }
  }, [currentUser, clinics]);

  const onSubmit = () => {
    const ids: string[] = addedClinics.map((a) => a.value as string);

    dispatch(
      editAdmin({
        id: currentUser?.id!,
        data: {
          clinicIds: ids,
        },
      }),
    );
  };

  const handleRemoveClinic = (id: string) => {
    const filteredClinics = addedClinics.filter((item) => item.value !== id);

    setAddedClinics(filteredClinics);

    setValue('clinic', filteredClinics, {
      shouldValidate: true,
    });
  };

  const closeSuccessModal = () => {
    dispatch(resetAdminData());
    navigate(PathNames.myUsers);
  };

  const isStateChanged = useMemo(() => {
    const ids: string[] =
      currentUser?.clinics?.map((a) => a.id as string) || [];
    const addedIds: string[] =
      addedClinics?.map((a) => a.value as string) || [];

    const firstObj =
      addedIds.length < ids.length ? { a: ids } : { a: addedIds };
    const secondObj =
      addedIds.length > ids.length ? { a: ids } : { a: addedIds };

    const isChanged = compareObjectsChanges(firstObj, secondObj);

    if (!addedClinics.length) return false;

    return isChanged;
  }, [currentUser, addedClinics]);

  return (
    <Wrapper>
      <SelectSection>
        <DropdownClinics
          value={addedClinics}
          label={t('users.select_a_clinic')!}
          placeholder={t('professional_profile.select_clinic')!}
          onChange={(value) => {
            const clinicsArr: Option[] = value as Option[];
            setAddedClinics(clinicsArr);
            setValue('clinic', clinicsArr, {
              shouldValidate: true,
            });
          }}
          isMulti={true}
          isError={!!errors?.clinic}
          errorMessage={errors.clinic && t('errors.required')!}
        />
      </SelectSection>
      {!!addedClinics.length && (
        <>
          <SelectedClinicsCounts>
            {`${addedClinics.length} ${t('selected')}`}
          </SelectedClinicsCounts>
          <SelectedClinics>
            {addedClinics.map((item) => {
              return (
                <SelectedClinicsItem key={item.value}>
                  <ClinicImage>
                    {item.image ? (
                      <img src={item.image} alt="image" />
                    ) : (
                      <HomeIcon />
                    )}
                  </ClinicImage>
                  <ClinicInfo>
                    <div>{item.label}</div>
                    <div>
                      {item.address?.streetAddress}, {item.address?.city} ,
                      {item.address?.country}
                    </div>
                  </ClinicInfo>
                  {!item.isHidden && (
                    <StyledCloseIcon
                      onClick={() => {
                        handleRemoveClinic(String(item.value));
                      }}
                    />
                  )}
                </SelectedClinicsItem>
              );
            })}
          </SelectedClinics>
        </>
      )}

      <StyledDivider />
      <ButtonsWrapper>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={!isStateChanged}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonsWrapper>

      {isUpdated && (
        <SuccessModal
          typeName={`${currentUser?.firstName} ${currentUser?.lastName}`}
          onClose={closeSuccessModal}
        />
      )}
    </Wrapper>
  );
};

export default Assigning;
