import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { FormSubTitle, FormTitle, HeaderWrapper } from './styles';

const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <FormTitle>{t('auth.log_in.title')}</FormTitle>
      <FormSubTitle>{t('auth.log_in.sub_title')}</FormSubTitle>
    </HeaderWrapper>
  );
};

export default Header;
