import React, { FC, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { setIsClinicOwnerProfile } from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { fetchProfessionalById } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { GeneralProfileInfo, Header, LayoutComponent } from './components';
import { Wrapper } from './styles';
import { resetClinicOwnerProfile } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';
import { PathNames } from 'applicaiton/routes';

const ProfessionalEdit: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userRole = getUserRole();
  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const { editedProfessional } = useAppSelector(
    (state) => state.professionalProfile,
  );

  const isProfessionalRole = userRole === UserRoles.professional;
  const clinicOwnerProfessionalId = currentClinicOwner?.professional?.id;

  useEffect(() => {
    const isEditProfessionalPage = location.pathname.includes(
      PathNames.editProfessional,
    );

    const professionalId = editedProfessional
      ? editedProfessional.id
      : isEditProfessionalPage || !clinicOwnerProfessionalId
      ? String(id)
      : String(clinicOwnerProfessionalId);

    dispatch(fetchProfessionalById(professionalId));
    return () => {
      dispatch(setHasUnsavedChanges(false));
      dispatch(professionalProfileActions.resetProfessionalProfile());
      dispatch(setIsClinicOwnerProfile(false));
      dispatch(resetClinicOwnerProfile());
    };
  }, []);

  return (
    <Wrapper id="professional-edit">
      {!isProfessionalRole && <Header />}
      <LayoutComponent>
        <GeneralProfileInfo />
      </LayoutComponent>
    </Wrapper>
  );
};

export default ProfessionalEdit;
