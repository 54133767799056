import React, { FC, useEffect } from 'react';
import { Modal } from '@mui/material';
import dayjs from 'dayjs';
import { DeleteModal, SuccessModal } from './components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader } from 'common/components';
import { deleteAppointment } from 'applicaiton/store/reducers/Appointments/ActionCreators';
import { selectAppointmentToDeleted } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import {
  refreshAgenda,
  setIsAddNewEvent,
} from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { fetchConfusingAgenda } from 'applicaiton/store/reducers/ConfusingAgenda/ActionCreators';

const DeleteAppointmentModal: FC = () => {
  const { isLoading, appointmentToDeleted, deletedSuccess } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const { confusingAgendas } = useAppSelector(
    (state) => state.confusingAgendas,
  );
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(selectAppointmentToDeleted(null));
  };

  const handleSubmit = async () => {
    const response = await dispatch(
      deleteAppointment(String(appointmentToDeleted?.id)),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(setIsAddNewEvent(true));
      dispatch(refreshAgenda());
      if (confusingAgendas) {
        await dispatch(
          fetchConfusingAgenda({
            data: {
              startDate: dayjs(appointmentToDeleted?.startDate).toDate(),
              endDate: dayjs(appointmentToDeleted?.endDate).toDate(),
              professionalId: appointmentToDeleted?.professional?.id || '',
              clinicId: appointmentToDeleted?.clinic?.id,
            },
            isOnSave: false,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (!deletedSuccess) return;
    const timer = setTimeout(() => {
      handleClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
      dispatch(selectAppointmentToDeleted(null));
    };
  }, [deletedSuccess]);

  return (
    <Modal open={!!appointmentToDeleted}>
      <>
        {isLoading && <Loader />}
        {!deletedSuccess ? (
          <DeleteModal
            name={appointmentToDeleted?.appointmentType?.title || ''}
            onClose={handleClose}
            onSubmit={handleSubmit}
            startDate={appointmentToDeleted?.startDate || ''}
          />
        ) : (
          <SuccessModal
            name={appointmentToDeleted?.appointmentType?.title || ''}
            onClose={handleClose}
          />
        )}
      </>
    </Modal>
  );
};

export default DeleteAppointmentModal;
