import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderStyled = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.color.grey900};
    margin-bottom: 8px;
  }

  > div {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: ${({ theme }) => theme.color.grey700};
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  button {
    width: 110px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
    :disabled {
      svg {
        path {
          fill: ${({ theme }) => theme.color.blue200};
        }
      }
    }
  }
`;

export const DeviderStyled = styled.hr`
  margin: 28px 0;
  border-width: 0px;
  border-color: ${({ theme }) => theme.color.grey300};
  border-style: solid;
  border-bottom-width: thin;
`;
