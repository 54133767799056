import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { deleteChatDocumentMessage } from 'applicaiton/store/reducers/ChatTeleconsultation/ActionCreators';
import { ReactComponent as ImageIcon } from 'applicaiton/assets/document-types/img.svg';
import { ReactComponent as PdfIcon } from 'applicaiton/assets/document-types/pdf.svg';
import { ReactComponent as DocumentIcon } from 'applicaiton/assets/document-types/doc.svg';
import { ReactComponent as DownloadIcon } from 'applicaiton/assets/download.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';

import { MessageI } from 'features/feature-teleconsultation/components/Chat/types';

import { Wrapper, MessageStyled, AnchorMenu } from './styles';
import { ChatTeleconsultationAPI } from 'integration/api/chatTeleconsultation';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { useAppDispatch } from 'common/hooks/redux';

const imageExtensions = ['jpg', 'jpeg', 'png'];

const Message = ({ isOwnMessage, message, date, file, id }: MessageI) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const [showAnchor, setShowAnchor] = useState(false);

  useOnClickOutside(ref, () => setShowAnchor(false));

  const getCurrentIcon = () => {
    if (imageExtensions.includes(file?.extension!)) {
      return <ImageIcon />;
    } else if (file?.extension.includes('pdf')) {
      return <PdfIcon />;
    } else {
      return <DocumentIcon />;
    }
  };

  const downloadDocument = async () => {
    try {
      const response =
        await ChatTeleconsultationAPI.downloadChatTeleconsultationDocument(
          String(id),
        );

      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'downloaded_file';
      if (contentDisposition) {
        const matches = /filename="([^"]+)"/.exec(contentDisposition);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document?.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document?.body?.appendChild(link);
      link.click();
      link.remove();
      setShowAnchor(false);
    } catch (e) {
      setShowAnchor(false);
    }
  };

  const handleDeleteMessage = async () => {
    await dispatch(deleteChatDocumentMessage(String(id)));
  };

  return (
    <Wrapper ref={ref} isDocument={!!file?.id}>
      <MessageStyled
        isOwnMessage={isOwnMessage}
        onClick={() => setShowAnchor((prev) => !prev)}
      >
        {file?.originalName ? (
          <p className={'message-document'}>
            {getCurrentIcon()}
            {file?.originalName}.{file?.extension}
          </p>
        ) : (
          <textarea className={'message-text'} value={message} readOnly />
        )}

        <p className={'message-time'}>{dayjs(date).format('HH:mm')}</p>
      </MessageStyled>
      {showAnchor && (
        <AnchorMenu>
          <p onClick={downloadDocument}>
            <DownloadIcon />
            {t('download')}
          </p>
          <p onClick={handleDeleteMessage}>
            <DeleteIcon />
            {t('delete')}
          </p>
        </AnchorMenu>
      )}
    </Wrapper>
  );
};

export default Message;
