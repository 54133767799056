import { useMemo } from 'react';
import { useLanguage } from './useLanguage';

export enum LocalizeNameKey {
  name_EN = 'name_EN',
  name_PT = 'name_PT',
  name_FR = 'name_FR',
}
export const useLocalizeKey = () => {
  const { language } = useLanguage();
  const localizeNameKey = useMemo(() => {
    switch (language) {
      case 'pt':
        return LocalizeNameKey.name_PT;
      case 'fr':
        return LocalizeNameKey.name_FR;
      default:
        return LocalizeNameKey.name_EN;
    }
  }, [language]);
  return {
    localizeNameKey,
  };
};
