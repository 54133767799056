import React, { FC } from 'react';
import { UsersListStyled } from './styles';
import { SubscribersTable, Header } from './components';
import { useTranslation } from 'react-i18next';

const SubscribersList: FC = () => {
  const { t } = useTranslation();

  return (
    <UsersListStyled>
      <Header />
      <h1>{t('subscriptions.my_subscribers')}</h1>
      <SubscribersTable />
    </UsersListStyled>
  );
};

export default SubscribersList;
