import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DatePicker, Dropdown } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { GeneralDropdownSectionProps } from './module';
import { DropdownSection } from './styles';
import { fetchProfessionalsSpokenLanguages } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
// --- Will be use in future ---
// import { Format } from 'applicaiton/constants/scheduler';
// import { getYearsOptions } from 'common/helpers/getYearsOptions';

// const YEAR_AFTER_BIRTH = 18;
// -----------------------------

const GeneralDropdownSection: FC<GeneralDropdownSectionProps> = ({
  errors,
  handleSetChanges,
  values,
  disableEditing,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { editedProfessional, profileChanges } = useAppSelector(
    (state) => state.professionalProfile,
  );
  const { languages } = useAppSelector(
    (state) => state.professionalsSpokenLanguages,
  );

  useEffect(() => {
    dispatch(fetchProfessionalsSpokenLanguages());
  }, [dispatch]);

  const languagesOptions = useMemo(() => {
    const language = getLanguageFromLocalStorage();
    const localizeName = `name_${language.toUpperCase()}` as
      | 'name_EN'
      | 'name_PT';

    return languages
      ?.map((item) => {
        return { label: item[localizeName], value: item.id };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [languages, i18n.language]);

  const professional = useMemo(() => {
    return {
      ...editedProfessional,
      ...profileChanges,
    };
  }, [editedProfessional, profileChanges]);

  const professionalSpokenLanguages = useMemo(() => {
    if (!values.spokenLanguageIds) return [];
    return values.spokenLanguageIds;
  }, [values.spokenLanguageIds]);

  // --- Will be use in future ---

  // const professionalActivityStartDate = useMemo(() => {
  //   if (!values.professionalActivityStartDate) return '';
  //   return dayjs(values.professionalActivityStartDate).get('years');
  // }, [values.professionalActivityStartDate]);

  // const handlePickActivityStartDate = (year: number) => {
  //   handleSetChanges(
  //     dayjs()
  //       .set('years', year)
  //       .set('month', 11)
  //       .set('date', 12)
  //       .format(Format.dateFormat)
  //       .toString(),
  //     'professionalActivityStartDate',
  //   );
  // };

  // const optionsProfessionalActivityStartDate = useMemo(() => {
  //   return getYearsOptions(
  //     !values.dateOfBirth
  //       ? dayjs().year()
  //       : Number(dayjs(values.dateOfBirth).year()) + YEAR_AFTER_BIRTH,
  //   );
  // }, [values.dateOfBirth]);
  // -------------------------------
  return (
    <DropdownSection>
      <Dropdown
        id={'language'}
        value={professionalSpokenLanguages}
        label={t('personal_information.spoken_languages') || ''}
        placeholder={
          t('personal_information.spoken_languages_placeholder') || ''
        }
        searchPlaceholder={
          t('personal_information.spoken_languages_search') || ''
        }
        onChange={(value) => {
          Array.isArray(value) && handleSetChanges(value, 'spokenLanguageIds');
        }}
        options={languagesOptions}
        isMulti={true}
        errorMessage={errors.spokenLanguageIds?.message}
        disabled={disableEditing}
        withSearch={true}
      />
      {/* Will be use in future */}
      {/* <Dropdown
        id={'year'}
        value={String(professionalActivityStartDate || '')}
        label={t('personal_information.start_work_year') || ''}
        placeholder={
          t('personal_information.start_work_year_placeholder') || ''
        }
        onChange={(value) => {
          if (!Array.isArray(value)) {
            handlePickActivityStartDate(Number(value.value));
          }
        }}
        options={optionsProfessionalActivityStartDate}
        errorMessage={errors.professionalActivityStartDate?.message}
        disabled={disableEditing}
      /> */}
      <DatePicker
        id={'dateBirth'}
        label={t('personal_information.date_birth') || ''}
        value={String(professional?.dateOfBirth || '')}
        onChange={(value) =>
          handleSetChanges(dayjs(value).toISOString(), 'dateOfBirth')
        }
        maxDate={dayjs(dayjs().subtract(18, 'year')).valueOf()}
        errorMessage={errors.dateOfBirth?.message}
        disabled={disableEditing}
        views={['year', 'month', 'day']}
        openTo="year"
      />
    </DropdownSection>
  );
};

export default GeneralDropdownSection;
