import {
  ClinicCreationDto,
  ClinicResponseDto,
  ClinicUpdateDto,
  CommonEntitiesListResponse,
  Insurance,
  SearchProfessionalsByFiltersResponseDto,
  SearchProfessionalsByFiltersSearchCriteriaDto,
  SpecializationBriefResponse,
} from '@docbay/schemas';
import axiosInstance from 'integration/api/axiosInstance';

export const ClinicsAPI = {
  fetchAllClinics: () =>
    axiosInstance.get<CommonEntitiesListResponse<ClinicResponseDto>>(
      '/clinics?include=address,photos,mainPhoto,professionalsRelations,owner',
    ),
  removeProfile: (id: string) => axiosInstance.delete(`/clinics/${id}`),
  getClinicProfile: (id: string) =>
    axiosInstance.get<ClinicResponseDto>(
      `/clinics/${id}?include=photos,contacts,professionalsRelations,insurances,professionalsRelations.professional,professionalsRelations.professional.mainPhoto,owner`,
    ),
  createClinicProfileData: (data: ClinicCreationDto) =>
    axiosInstance.post<ClinicResponseDto>('/clinics', data),
  saveClinicProfileData: (id: string, data: ClinicUpdateDto) =>
    axiosInstance.patch<ClinicResponseDto>(`/clinics/${id}`, data),
  fetchInsurances: () =>
    axiosInstance.get<CommonEntitiesListResponse<Insurance>>('/insurances'),
  searchProfessionalsByFilters: (
    data: SearchProfessionalsByFiltersSearchCriteriaDto,
  ) =>
    axiosInstance.post<SearchProfessionalsByFiltersResponseDto[]>(
      '/clinics/search-professionals-by-filters',
      data,
    ),
  getSpecializationsByClinic: (clinicId: string) =>
    axiosInstance.get<SpecializationBriefResponse[]>(
      `/clinics/${clinicId}/specializations`,
    ),
};
