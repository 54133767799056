import React, { FC } from 'react';
import { DataSection, Header, EmptyState, TableStyled } from './styles';
import { SecondaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { useAppSelector } from 'common/hooks/redux';
import Loader from 'common/components/Loader';
import AddFamilyHistoricModal from '../AddFamilyHistoricModal';
import FamilyHistoricItem from '../FamilyHistoricItem';

interface Props {
  updateMedicalBackground: () => void;
}

const AddFamilyHistoric: FC<Props> = ({ updateMedicalBackground }) => {
  const { t } = useTranslation();
  const [familyHistoricModalOpen, setFamilyHistoricModalOpen] =
    React.useState(false);

  const { patientMedicalBackground, isLoading } = useAppSelector(
    (state) => state.consultationData,
  );

  const handleSavedFamilyHistoricModal = () => {
    updateMedicalBackground();
    setFamilyHistoricModalOpen(false);
  };

  return (
    <DataSection>
      {isLoading && <Loader />}
      <Header>
        <h6>{t('consultation_data.family_historic_header')}</h6>
        <SecondaryButton
          onClick={() => setFamilyHistoricModalOpen(true)}
          styleType={'color'}
        >
          <AddIcon />
          {t('consultation_data.add_family_historic_header')}
        </SecondaryButton>
      </Header>
      <>
        {patientMedicalBackground?.familyHistories?.length ? (
          <TableStyled>
            <thead>
              <tr>
                <th>
                  <span>
                    {t('consultation_data.family_historic_table.relationship')}
                  </span>
                </th>
                <th>
                  <span>
                    {t('consultation_data.family_historic_table.disease')}
                  </span>
                </th>
                <th>
                  <span>
                    {t('consultation_data.family_historic_table.comments')}
                  </span>
                </th>
                <th>
                  <span>
                    {t('consultation_data.family_historic_table.actions')}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {patientMedicalBackground?.familyHistories?.map((item) => (
                <FamilyHistoricItem
                  key={item.id}
                  item={item}
                  updateMedicalBackground={updateMedicalBackground}
                />
              ))}
            </tbody>
          </TableStyled>
        ) : (
          <EmptyState>
            <PickIcon />
            <div>
              <p>{t('consultation_data.add_family_historic_empty_1')}</p>
              <p>
                {t('consultation_data.add_family_historic_empty_2')}
                <span onClick={() => setFamilyHistoricModalOpen(true)}>
                  {t('consultation_data.add_family_historic_empty_3')}
                </span>
              </p>
            </div>
          </EmptyState>
        )}
      </>
      {familyHistoricModalOpen && (
        <AddFamilyHistoricModal
          onClose={() => setFamilyHistoricModalOpen(false)}
          onSave={handleSavedFamilyHistoricModal}
        />
      )}
    </DataSection>
  );
};

export default AddFamilyHistoric;
