import { getAccessToken } from 'applicaiton/store/reducers/Chats/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Client, Message } from '@twilio/conversations';
import { ChatExtraInfo } from 'applicaiton/store/reducers/Chats/models';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { setCurrentClient } from 'applicaiton/store/reducers/Chats/ChatsSlice';

export const useChatsClient = () => {
  const userRole = getUserRole();
  const isProfessionalRole = userRole?.includes(UserRoles.professional);
  const dispatch = useAppDispatch();
  const { currentClient } = useAppSelector((state) => state.chats);

  const initClient = async (accessToken: string) => {
    const client: Client = new Client(accessToken);
    client.on('stateChanged', (state) => {
      if (state === 'failed') {
        console.error('conversation client initialization failed');
        dispatch(setCurrentClient(null));
        return;
      }
      if (state === 'initialized') {
        dispatch(setCurrentClient(client));
      }
    });
    client.on('tokenExpired', () => {
      refreshToken(client);
    });
    client.on('tokenAboutToExpire', () => {
      refreshToken(client);
    });
  };

  const refreshToken = async (client: Client) => {
    const token = await fetchToken();
    if (token) {
      client.updateToken(token);
    } else {
      console.error('Failed to refresh access token');
    }
  };

  const getChatExtraInfo = async (
    conversationSID: string,
  ): Promise<ChatExtraInfo> => {
    if (!currentClient) {
      return {};
    }
    const conversation = await currentClient.getConversationBySid(
      conversationSID,
    );
    const unreadMessagesCount = await conversation.getUnreadMessagesCount();
    const messagePaginator = await conversation.getMessages(1);
    const message: Message = messagePaginator.items[0];
    return {
      unreadMessagesCount,
      lastMessageText: message?.body,
      lastMessageTime: message?.dateUpdated || message?.dateCreated,
    };
  };

  const getConversation = async (conversationSID: string) => {
    if (!currentClient) {
      return;
    }
    return await currentClient.getConversationBySid(conversationSID);
  };

  const fetchToken = async () => {
    const result = await dispatch(getAccessToken());
    if (result.type.includes('fulfilled')) {
      return result.payload;
    }
    return null;
  };

  const initChatClient = async () => {
    if (!isProfessionalRole) return;

    const token = await fetchToken();
    if (token) {
      await initClient(token);
      return true;
    }
    return false;
  };

  const clientIsInitialized = !!currentClient;

  const shutdownClient = () => {
    if (currentClient) {
      currentClient.shutdown().then(() => dispatch(setCurrentClient(null)));
    }
  };

  const addMessageListener = (
    event: 'messageAdded' | 'messageRemoved',
    callback: (updateEvent: any) => void,
  ) => {
    if (!currentClient) {
      return;
    }
    currentClient.addListener(event, callback);
  };

  const addConversationListener = (
    event: 'conversationAdded' | 'conversationUpdated' | 'conversationRemoved',
    callback: (updateEvent: any) => void,
  ) => {
    if (!currentClient) {
      return;
    }
    currentClient.addListener(event, callback);
  };

  return {
    initChatClient,
    currentClient,
    getChatExtraInfo,
    clientIsInitialized,
    getConversation,
    shutdownClient,
    addConversationListener,
    addMessageListener,
  };
};
