import React, { FC } from 'react';
import { Wrapper } from './styles';
import { SecretaryUserAdd, SideMenu, SubscriptionsLimitModal } from 'features';

const SecretaryAddPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <SecretaryUserAdd />
      <SubscriptionsLimitModal />
    </Wrapper>
  );
};

export default SecretaryAddPage;
