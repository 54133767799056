import { DocumentActionDto } from '@docbay/schemas';
import { AppointmentDocumentsApi } from 'integration/api/documents';
import { ChatsAPI } from 'integration/api/chats';
import { setIsErrorModalVisible } from '../errorService/errorStore';
import { useAppDispatch } from 'common/hooks/redux';

export const useDocumentDownload = () => {
  const dispatch = useAppDispatch();
  const getFileNameFromResponse = (response: any) => {
    const contentDisposition = response?.headers['content-disposition'];
    let fileName = 'downloaded_file';
    if (!contentDisposition) {
      return fileName;
    }
    let fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (fileNameMatch) {
      fileName = fileNameMatch[1];
    }
    fileNameMatch = contentDisposition.match(/filename\*=(?:UTF-8'')?([^;]+)/);
    if (fileNameMatch) {
      fileName = decodeURIComponent(fileNameMatch[1]);
    }
    return fileName.substring(0, fileName.lastIndexOf('.'));
  };

  const openFile = (response: any) => {
    let fileName = getFileNameFromResponse(response);
    const downloadUrl = window.URL.createObjectURL(new Blob([response?.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const downloadDocument = async (params: DocumentActionDto | string) => {
    try {
      let response;
      if (typeof params !== 'string') {
        response = await AppointmentDocumentsApi.downloadDocument(
          params as DocumentActionDto,
        );
      } else {
        response = await ChatsAPI.downloadDocument(params as string);
      }

      openFile(response);
    } catch (e) {
      dispatch(setIsErrorModalVisible(true));
      console.log('Download document error: ', e);
    }
  };

  const downloadInvoiceDocument = async (documentId: string) => {
    try {
      const response = await AppointmentDocumentsApi.downloadInvoice(
        documentId,
      );
      openFile(response);
    } catch (e) {
      console.log('Download document error: ', e);
    }
  };

  return {
    downloadDocument,
    downloadInvoiceDocument,
  };
};
