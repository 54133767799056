import React, { FC } from 'react';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal } from './styles';

interface DeleteModalProps {
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteProfileModal: FC<DeleteModalProps> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <Modal open={true} onClose={onClose}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <h1>
          {t('professionalsListConfiguration.delete_professional.titlePart1')}{' '}
          {t('professionalsListConfiguration.delete_professional.titlePart2')}
        </h1>
        <section>
          <SecondaryButton onClick={onClose}>
            {t('professionalsListConfiguration.delete_professional.cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={onSubmit} styleType={'error'}>
            {t('professionalsListConfiguration.delete_professional.confirm')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default DeleteProfileModal;
