import { Box } from '@mui/system';
import { styledTheme } from 'applicaiton/theme';
import styled from 'styled-components';

export const CreatePasswordWrapper = styled(Box)`
  padding: 120px 0 0;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  gap: 32px;

  > svg {
    display: none;
  }
  @media screen and (max-width: 960px) {
    padding: 48px 0;
    height: calc(100vh - 130px);
    > svg {
      height: 30%;
      width: auto;
      display: block;
    }
  }

  @media screen and (max-width: 425px) {
    padding: 32px 0;
    > svg {
      width: 180px;
      height: auto;
      display: block;
    }
  }
`;

export const StyledForm = styled.form`
  width: 400px;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

export const FormTitle = styled(Box)`
  color: ${styledTheme.color.grey900};
  text-align: center;
  font-family: 'AcidGrotesk-Medium';
  font-size: 28px;
  line-height: 39.2px;
  letter-spacing: 0.5px;
`;

export const FormSubTitle = styled(Box)`
  color: ${styledTheme.color.grey700};
  text-align: center;
  font: 500 18px/25.2px 'Mulish', sans-serif;
  letter-spacing: 0.09px;
`;

export const TitleWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 28px;
`;
