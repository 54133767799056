import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { emailValidation } from 'applicaiton/constants/email';

export const usePersonalInfoSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const phoneError = t('errors.phone_invalid') || '';
  const emailError = t('errors.email_must_be_email') || '';
  const lengthError = t('errors.at_least_2_characters');
  const emptyLengthError = t('errors.empty_at_least_2_characters');

  return yup.object({
    firstName: yup.string().min(2, lengthError).required(requiredError),
    lastName: yup.string().min(2, lengthError).required(requiredError),
    jobPosition: yup.string().test({
      name: 'is-length',
      test(value, ctx) {
        const isInvalidText = value?.length === 1;

        if (isInvalidText)
          return ctx.createError({
            message: emptyLengthError,
          });
        return !isInvalidText;
      },
    }),
    email: yup
      .string()
      .matches(emailValidation, emailError)
      .required(requiredError),
    phone: yup.string().test({
      name: 'is-valid',
      test(value, ctx) {
        const isValidNumber = isValidPhoneNumber(`+${String(value)}`);
        if (!isValidNumber)
          return ctx.createError({
            message: phoneError,
          });
        return isValidNumber;
      },
    }),
    supportedCountry: yup.object().shape({
      id: yup.string(),
      name: yup.string(),
      code: yup.string(),
    }),
  });
};
