import styled from 'styled-components';

interface StyledProps {
  isRemoteParticipant: boolean;
}

export const ParticipantWrapper = styled.div`
  height: 100%;
  width: 100%;

  video {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }
`;

export const ScreenStyled = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, isRemoteParticipant }) =>
    isRemoteParticipant ? theme.color.grey400 : theme.color.grey500};
  border-radius: 12px;
  height: 100%;
  width: 100%;

  img {
    width: 54px;
    height: 54px;
    border-radius: 50%;
  }
`;

export const IconStyled = styled.div<StyledProps>`
  display: flex;
  width: ${({ isRemoteParticipant }) =>
    isRemoteParticipant ? '72px' : '54px'};
  height: ${({ isRemoteParticipant }) =>
    isRemoteParticipant ? '72px' : '54px'};
  padding: 3px 13px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #eeeefe;

  p {
    color: #5e5bf6;
    text-align: center;
    font-size: 22.909px;
    font-style: normal;
    font-weight: 500;
    line-height: 29.455px;
  }
`;
