import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';

interface StylesProps {
  last?: boolean;
}

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Divider = styled.div`
  height: 1px;
  margin: 24px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const WrapperContact = styled.div`
  display: grid;
  margin-bottom: 24px;

  > label {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-bottom: 6px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const InputWrapper = styled.div<StylesProps>`
  display: flex;
  margin-bottom: ${({ last }) => (last ? 0 : 12)}px;
`;

export const InputContainer = styled.div`
  width: 464px;
`;

export const InputLabel = styled.label`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.08px;
  color: ${({ theme }) => theme.color.grey600};
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
  padding-left: 8px;
  path {
    fill: ${({ theme }) => theme.color.grey500};
  }
`;

export const DividerBottom = styled.div`
  height: 1px;
  margin-top: 32px;
  background: ${({ theme }) => theme.color.grey200};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  > label {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }

  svg {
    margin-left: 8px;
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.color.error2};
    }
  }
`;
