import React from 'react';
import { PathNames } from 'applicaiton/routes';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Wrapper, HelpCenterLink, HelpCenterText } from './styles';
import { ReactComponent as HelpIcon } from 'applicaiton/assets/help.svg';
import { ReactComponent as ArrowLeftIcon } from 'applicaiton/assets/arrow-left.svg';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  clinicProfile,
  setProfileChanges,
  setShowSuccessModal,
} from 'applicaiton/store/reducers/Clinics/ClinicProfileSlice';
import { DocBaySupportLink } from 'applicaiton';
import { checkMandatoryFields } from 'features/feature-edit-profile-settings/helpers/checkMandatoryFields';

const BackToAllClinics = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { clinicProfileData } = useAppSelector((state) => state.clinicProfile);
  const isCreatePage = location.pathname === PathNames.createClinic;

  const handleBack = () => {
    const isRequiredFieldsFilled = checkMandatoryFields(clinicProfileData);
    if (isCreatePage && isRequiredFieldsFilled) {
      dispatch(setShowSuccessModal(true));
    }
    dispatch(clinicProfile(null));
    dispatch(setProfileChanges(null));
  };

  return (
    <Wrapper>
      <HelpCenterLink
        color="grey600"
        to={PathNames.myClinics}
        onClick={() => handleBack()}
      >
        <ArrowLeftIcon />
        <HelpCenterText>
          {t('editProfileSettings.backToAllClinics')}
        </HelpCenterText>
      </HelpCenterLink>
      <HelpCenterLink color="blue800" to={DocBaySupportLink} target="_blank">
        <HelpIcon />
        <HelpCenterText>{t('editProfileSettings.helpCenter')}</HelpCenterText>
      </HelpCenterLink>
    </Wrapper>
  );
};

export default BackToAllClinics;
