import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  StyledSection,
  StepSection,
  StepItem,
  Step,
  StepText,
  StepSectionWrapper,
} from './styles';
import ProfessionalAddForm from '../ProfessionalAddForm';
import GeneralProfileInfo from '../GeneralProfileInfo';
import { ReactComponent as CheckIcon } from 'applicaiton/assets/checked.svg';
import ClinicProfile from '../ClinicProfile';
import { LayoutComponentProps } from './modules';
import ProfessionalSubscriptions from '../ProfessionalSubscriptions';
import { useClinicOwner } from 'common/hooks/useClinicOwner';

const LayoutComponent: FC<LayoutComponentProps> = ({
  isClinicOwnerProfile,
}) => {
  const [activeForm, setActiveForm] = useState<number>(0);
  const [addedClinics, setClinics] = useState<string[]>([]);
  const { t } = useTranslation();
  const { isPrivateDoctor } = useClinicOwner();

  useEffect(() => {
    const professionalAdd: HTMLDivElement | null =
      document.querySelector('#professional-add');

    professionalAdd?.scroll(0, 0);
  }, [activeForm]);

  const subscriptionStep = useMemo(() => {
    return addedClinics.length + 3;
  }, [addedClinics]);

  const handleAddClinic = () => {
    setClinics((prev) => [
      ...prev,
      prev.length === 0
        ? t('professional_profile.new_clinic')
        : `${t('professional_profile.new_clinic')} ${prev.length + 1}`,
    ]);
    setActiveForm((prev) => prev + 1);
  };

  const handleNextBtn = () => {
    setActiveForm((prev) => prev + 1);
  };

  const handleNextGeneralBtn = () => {
    setActiveForm((prev) => prev + 1);

    if (!addedClinics.length) {
      setClinics((prev) => [
        ...prev,
        prev.length === 0
          ? t('professional_profile.new_clinic')
          : `${t('professional_profile.new_clinic')} ${prev.length + 1}`,
      ]);
    }
  };

  const handleBackBtn = () => {
    setActiveForm((prev) => prev - 1);
  };

  const handleRemoveClinic = (index: number) => {
    const filteredClinics: string[] = [...addedClinics].filter(
      (_, i) => index !== i,
    );

    const renamedClinics = filteredClinics.map(
      (_item: string, index: number) => {
        if (index === 1) {
          return t('professional_profile.new_clinic');
        } else {
          return `${t('professional_profile.new_clinic')} ${index + 1}`;
        }
      },
    );

    setActiveForm((prev) => {
      return addedClinics.length >= prev ? prev : prev - 1;
    });

    setClinics(renamedClinics);
  };

  const getCurrentForm = () => {
    switch (activeForm) {
      case 0:
        return (
          <ProfessionalAddForm
            onNextBtnClick={handleNextBtn}
            isClinicOwnerProfile={isClinicOwnerProfile}
          />
        );
      case 1:
        return (
          <GeneralProfileInfo
            onNextBtnClick={handleNextGeneralBtn}
            onBackBtnClick={handleBackBtn}
          />
        );
      case addedClinics.length
        ? addedClinics.length + 2
        : addedClinics.length + 3:
        return (
          <ProfessionalSubscriptions
            onNextBtnClick={handleNextBtn}
            onBackBtnClick={handleBackBtn}
            isClinicOwnerProfile={isClinicOwnerProfile}
          />
        );
      default:
        return (
          <ClinicProfile
            addClinic={handleAddClinic}
            arrayIndex={activeForm > 1 ? activeForm - 1 : 0}
            activeForm={activeForm}
            onNextBtnClick={handleNextBtn}
            onBackBtnClick={handleBackBtn}
            onRemoveClinic={handleRemoveClinic}
            isClinicOwnerProfile={isClinicOwnerProfile}
          />
        );
    }
  };

  return (
    <Wrapper>
      <StyledSection>
        <StepSectionWrapper>
          <StepSection>
            <StepItem>
              {activeForm < 1 ? (
                <Step isActive={activeForm === 0}>1</Step>
              ) : (
                <Step isActive={true}>
                  <CheckIcon />
                </Step>
              )}
              <StepText isActive={activeForm === 0}>
                {t('users.profile_info')}
              </StepText>
            </StepItem>
            <StepItem>
              {activeForm < 2 ? (
                <Step isActive={activeForm === 1}>2</Step>
              ) : (
                <Step isActive={true}>
                  <CheckIcon />
                </Step>
              )}
              <StepText isActive={activeForm === 1}>
                {t('description')}
              </StepText>
            </StepItem>
            {activeForm <= 1 && addedClinics.length === 0 && (
              <StepItem>
                <Step isActive={false}>{3}</Step>
                <StepText isActive={false}>
                  {isPrivateDoctor
                    ? t('professional_profile.my_practice')
                    : t('clinics')}
                </StepText>
              </StepItem>
            )}
            {(activeForm >= 2 || addedClinics.length > 0) &&
              addedClinics.map((clinic, index) => {
                return (
                  <StepItem key={clinic}>
                    <Step isActive={activeForm > index + 2}>
                      {activeForm > index + 2 ? <CheckIcon /> : 3 + index}
                    </Step>
                    <StepText isActive={activeForm === index + 2}>
                      {isPrivateDoctor
                        ? t('professional_profile.my_practice')
                        : clinic}
                    </StepText>
                  </StepItem>
                );
              })}
            <StepItem>
              <Step isActive={false}>{subscriptionStep}</Step>
              <StepText isActive={false}>
                {t('professional_profile.subscriptions.subscription_title')}
              </StepText>
            </StepItem>
          </StepSection>
        </StepSectionWrapper>
        {getCurrentForm()}
      </StyledSection>
    </Wrapper>
  );
};

export default LayoutComponent;
