import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { AttachFileIcon } from 'applicaiton/assets';

import { SendMessageIcon } from 'common/components/Icons';
import DocumentsUploadModal from 'common/components/DocumentsUploadModal';

import { MessageInputProps } from './modules';
import {
  MessageFormStyled,
  InputStyled,
  SendButton,
  DocumentButtonStyled,
} from './styles';
import { uploadChatTeleconsultationDocument } from 'applicaiton/store/reducers/ChatTeleconsultation/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

const MessageInput = ({
  onSendMessage,
  isChatTeleconsultation,
  conversationSid,
}: MessageInputProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isDocumentLoading, documentError } = useAppSelector(
    (state) => state.chatTeleconsultation,
  );
  const [value, setValue] = React.useState('');
  const [isOpenAddDocumentModal, setIsOpenAddDocumentModal] =
    React.useState(false);

  const handleSendMessage = (event: FormEvent) => {
    event.preventDefault();
    if (!value.length) {
      return;
    }
    onSendMessage(value);
    setValue('');
  };

  const handleAddDocument = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpenAddDocumentModal(true);
  };

  const handleUploadDocument = async (file: FormData) => {
    const response = await dispatch(
      uploadChatTeleconsultationDocument({
        conversationSid,
        file,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      setIsOpenAddDocumentModal(false);
    }
  };

  return (
    <MessageFormStyled
      onSubmit={handleSendMessage}
      isChatTeleconsultation={isChatTeleconsultation}
    >
      <DocumentButtonStyled type={'button'} onClick={handleAddDocument}>
        <AttachFileIcon />
      </DocumentButtonStyled>
      <InputStyled
        placeholder={t('teleconsultation.message_input_placeholder') || ''}
        onChange={(event) => setValue(event.target.value)}
        value={value}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            handleSendMessage(e);
          }
        }}
      />
      <SendButton onClick={handleSendMessage} type="submit">
        <SendMessageIcon />
      </SendButton>
      {isOpenAddDocumentModal && (
        <DocumentsUploadModal
          isOpen={isOpenAddDocumentModal}
          onClose={() => setIsOpenAddDocumentModal(false)}
          error={documentError}
          isLoading={isDocumentLoading}
          onSave={handleUploadDocument}
        />
      )}
    </MessageFormStyled>
  );
};
export default MessageInput;
