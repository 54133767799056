import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ButtonsWrapper } from './styles';
import { ButtonSectionProps } from './modules';

const ButtonSection: FC<ButtonSectionProps> = ({
  onCancel,
  onSave,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonsWrapper>
      <SecondaryButton onClick={onCancel}>{t('cancel')}</SecondaryButton>
      <PrimaryButton disabled={disabled} onClick={onSave}>
        {t('save')}
      </PrimaryButton>
    </ButtonsWrapper>
  );
};

export default ButtonSection;
