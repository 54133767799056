import React, { useState, useMemo, FC } from 'react';
import { ReactComponent as EmptyStateIcon } from 'applicaiton/assets/empty-state-appointments-past.svg';
import { Wrapper, InfoText } from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useTranslation } from 'react-i18next';
import {
  deleteDocuments,
  postDocumentsToAppointment,
} from 'applicaiton/store/reducers/AppointmentDocuments/ActionCreators';

import {
  AddDocument,
  Loader,
  StyledPlusIcon,
  UploadingProgress,
  UploadingText,
} from '../AppointmentDocuments/styles';
import AppointmentDocumentsItem from '../AppointmentDocumentsItem';
import { ReactComponent as LoadIcon } from 'applicaiton/assets/loader.svg';
import { ReactComponent as AttentionIcon } from 'applicaiton/assets/attention.svg';
import { fetchAppointmentById } from 'applicaiton/store/reducers/Appointments/ActionCreators';
import ConfirmDeleteDocument from '../ConfirmDeleteDocument';
import { setDeletedDocument } from 'applicaiton/store/reducers/AppointmentDocuments/AppoinmentsDocumentsSlice';
import SuccessDocumentDeletedModal from '../SuccessDocumentDeletedModal';
import { EventEditDocumentsProps } from './models';

const EventEditDocuments: FC<EventEditDocumentsProps> = ({
  isTeleconsultationDocuments = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);

  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const { error, isLoading, deletedDocument } = useAppSelector(
    (state) => state.appointmentDocumentsSlice,
  );

  const documentList = useMemo(() => {
    return currentAppointment?.documents || [];
  }, [currentAppointment?.documents]);

  const handleDocsUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const formData = new FormData();

    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('documents', files![i]);
      }

      dispatch(
        postDocumentsToAppointment({
          appointmentId: currentAppointment?.id || '',
          data: formData,
        }),
      ).then(() => {
        e.target.value = '';
        dispatch(fetchAppointmentById(String(currentAppointment?.id)));
      });
    }
  };

  const handleDeleteDocument = () => {
    dispatch(
      deleteDocuments({
        relatedEntityId: currentAppointment?.id,
        key: deletedDocument?.key || '',
        bucketType: 'DEFAULT',
      }),
    ).then(() => {
      setShowDeleteModal(false);
      dispatch(fetchAppointmentById(String(currentAppointment?.id)));
      setShowSuccessDeleteModal(true);
    });
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    dispatch(setDeletedDocument(null));
  };

  const handleCloseSuccessDelete = () => {
    setShowSuccessDeleteModal(false);
    dispatch(setDeletedDocument(null));
  };

  return (
    <Wrapper isTeleconsultationDocuments={isTeleconsultationDocuments}>
      {!documentList.length && (
        <>
          <EmptyStateIcon />
          <InfoText>{t('appointment.no_documents')}</InfoText>
        </>
      )}
      {!!documentList?.length && (
        <div>
          {documentList?.map((item) => (
            <AppointmentDocumentsItem
              key={item.key}
              item={item}
              onDelete={() => setShowDeleteModal(true)}
              editMode
              currentAppointmentId={currentAppointment?.id}
              isTeleconsultationDocuments={isTeleconsultationDocuments}
            />
          ))}
        </div>
      )}
      <AddDocument isLoading={isLoading}>
        {!!isLoading && (
          <UploadingProgress>
            <Loader>
              <LoadIcon />
            </Loader>
            <UploadingText>
              <p>{t('appointment.uploadingDocuments')}</p>
              <p>{t('editProfileSettings.wait')}</p>
            </UploadingText>
          </UploadingProgress>
        )}
        {!!error && (
          <UploadingProgress>
            <Loader isError={!!error}>
              <AttentionIcon />
            </Loader>
            <UploadingText isError={!!error}>
              <p>{t('editProfileSettings.errorTitle')}</p>
              <p>{t('appointment.errorMessage')}</p>
            </UploadingText>
          </UploadingProgress>
        )}
        <div className={isLoading ? 'disabled' : ''}>
          <label htmlFor="photo" />
          <input
            id="photo"
            type="file"
            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .odt"
            onChange={handleDocsUpload}
            multiple
            disabled={isLoading}
          />
          <StyledPlusIcon /> {t('appointment.add_documents')}
        </div>
      </AddDocument>

      <ConfirmDeleteDocument
        isOpen={showDeleteModal}
        documentName={
          `${deletedDocument?.originalName}.${deletedDocument?.extension}` || ''
        }
        onClose={handleCancelDelete}
        onSubmit={handleDeleteDocument}
      />
      {showSuccessDeleteModal && (
        <SuccessDocumentDeletedModal
          documentName={
            `${deletedDocument?.originalName}.${deletedDocument?.extension}` ||
            ''
          }
          onClose={handleCloseSuccessDelete}
        />
      )}
    </Wrapper>
  );
};

export default EventEditDocuments;
