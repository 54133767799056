import React, { useCallback, useMemo, useRef } from 'react';
import dayjs from 'dayjs';

import { MessageWrapper, MessagesListWrapper } from './styles';
import { MessageBriefInfo, MessagesListProps } from './module';
import { MessageListItem } from './MessageListItem';
import { useTranslation } from 'react-i18next';
import { MessageMenu } from './MessageMenu';
import { useAppSelector } from 'common/hooks/redux';
import { formatMessageDate } from 'features/feature-chats/helpers/formatMessageDate';
require('dayjs/locale/pt');
require('dayjs/locale/fr');

export const MessagesList = ({
  topic,
  messages,
  onDeleteMessage,
  hideTopic,
  onDownloadDocument,
  messagesPaginator,
  setMessages,
  setMessagesPaginator,
}: MessagesListProps) => {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language);

  const observer = useRef<HTMLDivElement | null>(null) as any;
  const { chatsReadMessagesInfo, selectedChat } = useAppSelector(
    (state) => state.chats,
  );
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [selectedMessage, setSelectedMessage] =
    React.useState<MessageBriefInfo | null>(null);

  const messagesEndRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && !!messagesPaginator?.hasPrevPage) {
          const newMessagesPaginator = await messagesPaginator?.prevPage();

          if (newMessagesPaginator && setMessages && setMessagesPaginator) {
            setMessagesPaginator(newMessagesPaginator);
            setMessages((prev) => [...prev, ...newMessagesPaginator.items]);
          }
        }
      });

      if (node) observer?.current.observe(node);
    },
    [messagesPaginator],
  );

  const handleCloseMenu = () => {
    setMenuAnchor(null);
    setSelectedMessage(null);
  };

  const handleDeleteMessage = () => {
    handleCloseMenu();
    if (!selectedMessage) return;
    onDeleteMessage(selectedMessage.id);
  };

  const handleCopyMessage = () => {
    if (!selectedMessage) return;
    navigator.clipboard.writeText(selectedMessage.text);
    handleCloseMenu();
  };
  const handleSelectMessage = (
    message: MessageBriefInfo,
    anchorElement: HTMLElement,
  ) => {
    setMenuAnchor(anchorElement);
    setSelectedMessage(message);
  };

  const handleDownloadDocument = () => {
    handleCloseMenu();
    if (!selectedMessage) return;
    onDownloadDocument(selectedMessage.id);
  };

  const sortedMessages = useMemo(() => {
    if (!messages.length) return [];
    const readMessageChat = chatsReadMessagesInfo.find(
      (item) => selectedChat?.twilioConversationSID === item.sid,
    );

    // Add title to messages
    const updatedMessagesList = messages.reduce(
      (acc: MessageBriefInfo[], currentMessage, currentIndex) => {
        const prevMessage = acc[acc.length - 1];
        const isSameDate = dayjs(prevMessage?.date).isSame(
          currentMessage.date,
          'date',
        );
        const isLastMessage = currentIndex === messages.length - 1;
        const isLastReadMessage =
          readMessageChat?.lastReadMessageIndex === currentMessage.index;
        const isToday = dayjs(prevMessage?.date).isToday();

        const messageTitle = isLastReadMessage
          ? t('new_message')
          : isSameDate && !isLastMessage
          ? currentMessage.messageTitle || ''
          : isToday
          ? t('today')
          : formatMessageDate(prevMessage?.date);

        return [
          ...acc,
          {
            ...currentMessage,
            messageTitle,
            isLastMessage,
          },
        ];
      },
      [],
    );

    return updatedMessagesList;
  }, [messages, t, chatsReadMessagesInfo, selectedChat]);

  return (
    <>
      <MessagesListWrapper>
        {!hideTopic && (
          <h1 className={'topic-name'}>
            {t('chats.topic') || ''} : {topic || t('chats.no_topic')}
          </h1>
        )}
        <div className={'messages-list'}>
          {sortedMessages.map((message) => (
            <MessageWrapper
              key={message.id}
              ref={messagesEndRef}
              isLastMessage={!!message.isLastMessage}
            >
              <MessageListItem
                onSelectMessage={handleSelectMessage}
                message={message}
              />
            </MessageWrapper>
          ))}
        </div>
      </MessagesListWrapper>
      <MessageMenu
        isOwnMessage={selectedMessage?.isOwnMessage || false}
        anchorElement={menuAnchor}
        onClose={handleCloseMenu}
        onDelete={handleDeleteMessage}
        onCopy={handleCopyMessage}
        onDownload={handleDownloadDocument}
        isDocumentMessage={!!selectedMessage?.document || false}
      />
    </>
  );
};
