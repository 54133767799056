import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  button {
    width: 150px;

    &:last-child {
      margin-left: 20px;
    }
  }
`;
