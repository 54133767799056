import { PhotoResponseDto, UserLoginResDto } from '@docbay/schemas';
import { parseJwt } from 'common/helpers/parseJwt';
import { StorageFields } from '../constants';
import { UserRoles } from '../constants/userRoles';
import { SupportedCountries } from 'common/types/countries';
import i18next from 'i18next';

export enum AuthLocalStorage {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  accessTokenExpiresAt = 'accessTokenExpiresAt',
  profiles = 'profiles',
  userTimezone = 'userTimezone',
  userCountryIso = 'userCountryIso',
  userLanguage = 'i18nextLng',
}

export interface Profile {
  token: string;
  refreshToken: string;
  email: string;
  firstName: string;
  lastName: string;
  photo: PhotoResponseDto;
  id: string;
  userTypes?: string;
  cognitoUuid?: string;
  userCountryIso?: string;
}

export const setUsersAuthSessionStorage = (value: UserLoginResDto) => {
  localStorage.setItem(
    AuthLocalStorage.accessToken,
    JSON.stringify(value.token),
  );
  localStorage.setItem(
    AuthLocalStorage.refreshToken,
    JSON.stringify(value.refreshToken),
  );

  localStorage.setItem(AuthLocalStorage.accessTokenExpiresAt, value.expiresAt);

  const token = parseJwt(value.token);

  localStorage.setItem(StorageFields.userRole, token['custom:userTypes']);
  localStorage.setItem(StorageFields.userId, value.user?.id as string);
  localStorage.setItem(
    StorageFields.userPermissions,
    token['custom:permissions'],
  );

  token['custom:timezone'] &&
    localStorage.setItem(
      AuthLocalStorage.userTimezone,
      token['custom:timezone'],
    );

  if (token && token['custom:privateDoctor']) {
    localStorage.setItem(
      StorageFields.privateDoctor,
      token['custom:privateDoctor'],
    );
  }

  if (token && token['custom:proPermissions']?.length) {
    localStorage.setItem(
      StorageFields.userProPermissions,
      JSON.stringify(token['custom:proPermissions']),
    );
  }

  if (token && token['custom:countryIso']) {
    localStorage.setItem(
      StorageFields.userCountryIso,
      token['custom:userCountryIso'] || SupportedCountries.Portugal,
    );
  }

  if (token && token['custom:languageIso']) {
    i18next.changeLanguage(token['custom:languageIso'].toLowerCase());
    localStorage.setItem(
      AuthLocalStorage.userLanguage,
      token['custom:languageIso'] || SupportedCountries.Portugal,
    );
  }
};

export const setUsersRefreshTokenSessionStorage = (value: {
  token: string;
  expiresAt: string;
}) => {
  localStorage.setItem(
    AuthLocalStorage.accessToken,
    JSON.stringify(value.token),
  );

  localStorage.setItem(AuthLocalStorage.accessTokenExpiresAt, value.expiresAt);

  const parsedToken = parseJwt(value.token);

  if (parsedToken && parsedToken['custom:proPermissions']?.length) {
    localStorage.setItem(
      StorageFields.userProPermissions,
      JSON.stringify(parsedToken['custom:proPermissions']),
    );
  }
  if (parsedToken && parsedToken['custom:countryIso']) {
    localStorage.setItem(
      StorageFields.userCountryIso,
      parsedToken['custom:userCountryIso'] || SupportedCountries.Portugal,
    );
  }
  if (parsedToken && parsedToken['custom:timezone']) {
    localStorage.setItem(
      AuthLocalStorage.userTimezone,
      parsedToken['custom:timezone'],
    );
  }
};

export const getProfilesFromSessionStorage = (): Profile[] | null => {
  const profiles = localStorage.getItem(AuthLocalStorage.profiles);

  return profiles ? (JSON.parse(profiles) as Profile[]) : null;
};

export const setProfilesInSessionStorage = (profiles: Profile[]) => {
  const parsedProfile = profiles.map((item) => {
    const jwtData = parseJwt(item.token);
    return {
      ...item,
      userTypes: jwtData ? jwtData['custom:userTypes'] : '',
      cognitoUuid: jwtData ? jwtData.sub : '',
    };
  });
  localStorage.setItem(
    AuthLocalStorage.profiles,
    JSON.stringify(parsedProfile),
  );

  localStorage.setItem(StorageFields.userRole, parsedProfile[0].userTypes);
};

export const getUserRole = () => localStorage.getItem(StorageFields.userRole);
export const getUserId = () => localStorage.getItem(StorageFields.userId);
export const getUserPermissions = () =>
  localStorage.getItem(StorageFields.userPermissions);
export const getToken = () => {
  const token = localStorage.getItem(AuthLocalStorage.accessToken);
  return token ? JSON.parse(token) : '';
};
export const getIsUserPrivateDoctor = () =>
  Boolean(localStorage.getItem(StorageFields.privateDoctor));
export const getIsShowMyAgenda = () => {
  return (
    localStorage.getItem(StorageFields.privateDoctor) &&
    localStorage
      .getItem(StorageFields.userRole)
      ?.includes(UserRoles.professional)
  );
};

export const getUserProPermissions = () => {
  const data = localStorage.getItem(StorageFields.userProPermissions);
  if (data) {
    return JSON.parse(data);
  }
};

export const getCurrentProfileCognitoUuid = () => {
  const profiles = getProfilesFromSessionStorage();
  return profiles ? profiles[0]?.cognitoUuid : '';
};

export const getUserTimezone = () => {
  const timezone = localStorage.getItem(AuthLocalStorage.userTimezone);
  return timezone ? timezone : '';
};

export const setUserTimezoneInLocalStorage = (timezone: string) => {
  localStorage.setItem(AuthLocalStorage.userTimezone, timezone);
};

export const getUserCountryIso = () => {
  const storedCountry =
    localStorage.getItem(StorageFields.userCountryIso) || '';

  switch (storedCountry.toUpperCase()) {
    case 'PT':
      return 'PT';
    case 'FR':
      return 'FR';
    default:
      return 'PT';
  }
};
