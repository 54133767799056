import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyStateIcon } from 'applicaiton/assets/empty-state-appointments-future.svg';
import { StyledTr } from './styles';

const EmptyStateByFuture: FC = () => {
  const { t } = useTranslation();
  return (
    <StyledTr>
      <td colSpan={5}>
        <div>
          <EmptyStateIcon />
          <h2>{t('patient_profile_empty_state.future.title')}</h2>
          <p>{t('patient_profile_empty_state.future.text')}</p>
        </div>
      </td>
    </StyledTr>
  );
};
export default EmptyStateByFuture;
