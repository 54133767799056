import React, { FC, useMemo, useState } from 'react';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import { ClinicNameStyled, StyledTr, ActionsStyled } from './styles';
import DeleteModal from '../DeleteModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { deleteTopic } from 'applicaiton/store/reducers/Topics/ActionCreators';
import SuccessModal from '../SuccessModal';
import { editedTopic } from 'applicaiton/store/reducers/Topics/TopicsSlice';
import { TopicChanges } from 'applicaiton/store/reducers/Topics/models';

interface ClinicItemProps {
  topic: TopicChanges;
  updateList: () => void;
}

const TopicItem: FC<ClinicItemProps> = ({ topic, updateList }) => {
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);

  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const userRole = getUserRole();
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);

  const userId = useMemo(() => {
    return isClinicOwnerRole
      ? currentClinicOwner?.professional?.id
      : getUserId();
  }, [currentClinicOwner]);

  const handleDeleteTopic = () => {
    dispatch(
      deleteTopic({
        id: userId!,
        topicId: String(topic.id),
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setShowDeleteModal(false);
        setShowSuccessDeleteModal(true);
      }
    });
  };

  const handleCloseSuccessDeleteModal = () => {
    setShowSuccessDeleteModal(false);

    updateList();
  };

  const handleEditTopic = () => {
    dispatch(editedTopic(topic));
  };

  return (
    <StyledTr>
      <td>
        <ClinicNameStyled>
          <p>{topic.name}</p>
        </ClinicNameStyled>
      </td>
      <td>{topic.clinicName}</td>
      <td>
        <ActionsStyled>
          <button onClick={handleEditTopic}>
            <EditIcon />
          </button>
          <button onClick={() => setShowDeleteModal(true)}>
            <DeleteIcon />
          </button>
        </ActionsStyled>
      </td>
      <DeleteModal
        isOpen={showDeleteModal}
        topicName={topic.name}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={handleDeleteTopic}
      />
      {showSuccessDeleteModal && (
        <SuccessModal
          topicName={topic.name}
          onClose={handleCloseSuccessDeleteModal}
        />
      )}
    </StyledTr>
  );
};
export default TopicItem;
