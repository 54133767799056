import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { OnlineHoursActions } from 'applicaiton/constants/actions';
import { OnlineHoursAPI } from 'integration/api/online-hours';
import {
  CreateOnlineHoursData,
  UpdateOnlineHoursData,
} from 'common/types/onlineHours';

export const fetchOnlineHours = createAsyncThunk(
  OnlineHoursActions.fetchOnlineHours,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await OnlineHoursAPI.fetchOnlineHours(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createOnlineHours = createAsyncThunk(
  OnlineHoursActions.createOnlineHours,
  async (data: CreateOnlineHoursData, { rejectWithValue }) => {
    try {
      const { data: responseData } = await OnlineHoursAPI.createOnlineHours(
        data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateOnlineHours = createAsyncThunk(
  OnlineHoursActions.updateOnlineHours,
  async (data: UpdateOnlineHoursData, { rejectWithValue }) => {
    try {
      const { data: responseData } = await OnlineHoursAPI.updateOnlineHours(
        data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
