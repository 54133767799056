import styled from 'styled-components';

export const ModalStyled = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  width: 664px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  background: ${({ theme }) => theme.color.white};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  width: 100%;

  h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
  }

  button {
    all: unset;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }
  }
`;

export const InfoSection = styled.div`
  display: grid;
  gap: 24px;
  grid-auto-rows: max-content;
  padding: 32px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

  > div:first-child {
    width: 320px;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px;
  width: 100%;

  button {
    width: 150px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;
