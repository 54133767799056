import styled from 'styled-components';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 500px;
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.success4};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.color.grey500};
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      color: ${({ theme }) => theme.color.grey700};
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      margin: 12px 0 32px 0;
      > span {
        font-weight: 600;
      }
    }

    > button {
      min-width: 120px;
      width: fit-content;
      background: ${({ theme }) => theme.color.white};
    }
  }
`;
