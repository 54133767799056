import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { SchedulerDateTime } from '@devexpress/dx-react-scheduler';
import dayjs from 'dayjs';

import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as CheckCircleIcon } from 'applicaiton/assets/check-circle.svg';

import { PrimaryButton, SecondaryButton } from 'common/components';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import { StyledModal } from './styles';

interface DeleteModalProps {
  isOpen: boolean;
  patientName: string;
  startDate: SchedulerDateTime;
  oldDate: SchedulerDateTime;
  onClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
}

const VerifyEditingAppointmentModal: FC<DeleteModalProps> = ({
  isOpen,
  patientName,
  startDate,
  oldDate,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { tz } = useUserTimeZone();
  const formattedDate = dayjs.tz(startDate, tz).format('DD MMM HH:mm');
  const oldFormattedDate = dayjs.tz(oldDate, tz).format('DD MMM HH:mm');

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <h1>
          {t('appointment.edit_type.titlePart1')} <span>{patientName}</span>{' '}
          {t('appointment.edit_type.titlePart2')}{' '}
          <span>{oldFormattedDate}</span> {t('to')} <span>{formattedDate}</span>
        </h1>
        <div>
          <p> {t('appointment.edit_type.description')}</p>
          <div>
            <CheckCircleIcon />
            {t('appointment.edit_type.descriptionText1')}
          </div>
          <div>
            <CheckCircleIcon />
            {t('appointment.edit_type.descriptionText2')}
          </div>
          <div>
            <CheckCircleIcon />
            {t('appointment.edit_type.descriptionText3')}
          </div>
        </div>
        <section>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              onSubmit();
            }}
            disabled={isLoading}
          >
            {t('appointment_types.delete_type.confirm')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default VerifyEditingAppointmentModal;
