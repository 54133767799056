import { Box, styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(0, 4, 4)};
  gap: ${({ theme }) => theme.spacing(4)};
  background: ${commonColors.background};
`;

export const MainLayoutWrapper = styled(Box)`
  display: flex;
  height: 100%;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;
