import React, { useEffect, useState, useRef } from 'react';
import { SearchInput } from './styles';
import { Input } from 'common/components';
import { SubscriberSearchProps } from './model';
import { ProfessionalsAPI } from 'integration/api/professionals';
import { PatientBriefResponseDto } from '@docbay/schemas';
import { SubscribersList } from './SubscribersList';
import { useDebounce } from 'common/hooks/useDebounce';

export const SubscriberSearch = ({
  label,
  placeholder,
  onSubscriberSelected,
}: SubscriberSearchProps) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchResults, setSearchResults] = useState<PatientBriefResponseDto[]>(
    [],
  );
  const searchItem = useDebounce(searchInputValue, 500);
  const preventDoubleSearchRef = useRef(false);

  const handleSearch = () => {
    if (preventDoubleSearchRef.current) {
      return;
    }
    ProfessionalsAPI.fetchSubscribedPatients(searchInputValue).then((res) => {
      setSearchResults(res.data);
    });
  };

  useEffect(() => {
    if (searchItem.length > 2) {
      handleSearch();
    }
  }, [searchItem]);

  const handleClearSearch = () => {
    setSearchInputValue('');
    onSubscriberSelected(null);
    setSearchResults([]);
  };

  // Code responsible to block double search after selecting a subscriber from the list
  const blockDoubleSearch = () => {
    preventDoubleSearchRef.current = true;
    setTimeout(() => {
      preventDoubleSearchRef.current = false;
    }, 1000);
  };

  const handleSelectSubscriber = (subscriber: PatientBriefResponseDto) => {
    onSubscriberSelected(subscriber.id);
    setSearchInputValue(subscriber.firstName + ' ' + subscriber.lastName);
    blockDoubleSearch();
    setSearchResults([]);
  };

  return (
    <SearchInput>
      <Input
        id={'search'}
        type="search"
        label={label}
        value={searchInputValue}
        onClear={handleClearSearch}
        onChange={(e) => setSearchInputValue(e.target.value)}
        placeholder={placeholder}
      />
      {searchResults.length > 0 ? (
        <SubscribersList
          subscribers={searchResults}
          clearSearch={handleClearSearch}
          onSubscriberSelected={handleSelectSubscriber}
        />
      ) : (
        <></>
      )}
    </SearchInput>
  );
};
