import styled from 'styled-components';

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    &:nth-child(2) {
      width: 200px;
    }
    &:last-child {
      width: 144px;
    }
  }
`;

export const ClinicNameStyled = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  > p {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    margin: 0 12px;
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > a {
    display: flex;
    padding: 10px;
    align-items: center;
    > svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }

  > button {
    display: flex;
    padding: 0;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    color: ${({ theme }) => theme.color.blue800};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;

    &:first-child {
      > svg {
        path {
          fill: ${({ theme }) => theme.color.blue800};
        }
      }
    }
    &:last-child {
      padding: 10px;
      > svg {
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }
  }
`;
