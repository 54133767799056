import { createSlice } from '@reduxjs/toolkit';
import {
  addOpenAgenda,
  editOpenAgenda,
  removeOpenAgenda,
  searchAgenda,
} from './ActionCreators';
import { AgendaState } from './models';
import { fetchOpenAgenda } from '../Agenda/ActionCreators';

const initialState: AgendaState = {
  agenda: [],
  isLoading: false,
  error: '',
  openAgenda: {
    id: '',
    day: {
      date: '',
      slots: [],
    },
  },
  editedOpenAgenda: null,
  errorOpenAgenda: false,
};

const AgendaSlice = createSlice({
  name: 'agendaSlice',
  initialState,
  reducers: {
    resetAgenda: (state) => {
      state.agenda = [];
    },
    resetOpenAgenda: (state) => {
      state.openAgenda = initialState.openAgenda;
    },
    setEditedOpenAgenda: (state, action) => {
      state.editedOpenAgenda = action.payload;
    },
    setErrorOpenAgenda: (state, action) => {
      state.errorOpenAgenda = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchAgenda.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchAgenda.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.agenda = [];
    });
    builder.addCase(searchAgenda.fulfilled, (state, action) => {
      state.agenda = action.payload;
      state.isLoading = false;
    });
    //professional create password
    builder.addCase(fetchOpenAgenda.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOpenAgenda.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchOpenAgenda.fulfilled, (state, action) => {
      state.isLoading = false;
      state.openAgenda = action.payload
        ? action.payload
        : initialState.openAgenda;
    });
    //professional create openAgenda hours
    builder.addCase(addOpenAgenda.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addOpenAgenda.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(addOpenAgenda.fulfilled, (state, action) => {
      state.isLoading = false;
      state.openAgenda = action.payload;
    });
    //professional edit openAgenda hours
    builder.addCase(editOpenAgenda.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editOpenAgenda.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(editOpenAgenda.fulfilled, (state, action) => {
      state.isLoading = false;
      state.openAgenda = action.payload;
    });
    //professional remove openAgenda hours
    builder.addCase(removeOpenAgenda.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removeOpenAgenda.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(removeOpenAgenda.fulfilled, (state) => {
      state.isLoading = false;
      state.openAgenda = initialState.openAgenda;
    });
  },
});

export const {
  resetAgenda,
  setEditedOpenAgenda,
  resetOpenAgenda,
  setErrorOpenAgenda,
} = AgendaSlice.actions;

export default AgendaSlice.reducer;
