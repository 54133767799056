import styled from 'styled-components';

export const MenuItemStyled = styled.div`
  > a {
    text-decoration: none;
  }
  button {
    text-decoration: none;
    width: 100%;
    background: transparent;
    border-radius: 4px;
    border: none;
    text-align: start;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .menu_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    padding: 10px 0;
    color: ${({ theme }) => theme.color.white};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 0.08px;
    gap: 4px;

    .image-wrapper {
      padding: 4px 8px;
    }

    > svg {
      min-width: 24px;
      min-height: 24px;

      path {
        fill: ${({ theme }) => theme.color.white};
      }
    }
    &.stroke-icon {
      path {
        fill: none;
        stroke: ${({ theme }) => theme.color.white};
      }
    }
  }

  .active {
    .image-wrapper {
      border-radius: 4px;
      background: ${({ theme }) => theme.color.green100};

      svg {
        path {
          fill: ${({ theme }) => theme.color.menu};
        }
      }
      &.stroke-icon {
        path {
          fill: none;
          stroke: ${({ theme }) => theme.color.menu};
        }
      }
      &.doc-box-icon {
        svg {
          path {
            fill: none;
            stroke: ${({ theme }) => theme.color.menu};
          }
          circle {
            stroke: ${({ theme }) => theme.color.menu};
          }
        }
      }
    }
  }
`;

export const NotificationIcon = styled.div`
  position: relative;

  > svg:last-child {
    position: absolute;
    top: 1px;
    right: 2px;
    width: 7px;
    height: 7px;
  }
`;
