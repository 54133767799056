import styled from 'styled-components';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  border-right: 1px solid ${commonColors.grey200};
`;

export const FieldsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
  overflow-y: auto;

  .appointment-types-options {
    min-width: unset;
  }
`;

export const DateSection = styled.div`
  display: flex;
  gap: 12px;
`;

export const TimePickerWrapper = styled.div`
  margin-left: 12px;

  label {
    display: block;
    margin-bottom: 6px;
    color: ${commonColors.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;

export const ConfusingStyled = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid ${commonColors.warning2};
  background: ${commonColors.warning3};
  padding: 16px;
  color: ${commonColors.warning};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  > svg {
    width: 20px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
    margin-right: 12px;

    path: {
      fill: ${commonColors.warning2};
    }
  }
`;

export const ButtonSection = styled.div`
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
  display: flex;
  justify-content: flex-end;

  button {
    min-width: 150px;
    width: fit-content;
    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const AppointmentStatusStyled = styled.div`
  display: grid;
  gap: 13px;

  > p {
    color: ${commonColors.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: ${commonColors.grey200};
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const CheckboxWrapper = styled.div`
  display: block !important;

  > label {
    display: block;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 8px;
  }
`;

export const SwitchStyled = styled.div`
  display: flex;

  > p {
    color: ${({ theme }) => theme.color.grey500};
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-left: 12px;

    > span {
      margin-left: 8px;
      font-weight: 700;
      color: ${({ theme }) => theme.color.blue800};
      cursor: pointer;
    }
  }
`;
