import styled from 'styled-components';

export const Title = styled.p`
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: ${({ theme }) => theme.color.grey600};
  margin-bottom: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 38px);
`;

export const QuestionsWrapper = styled.section`
  > div:first-child {
    display: flex;
    margin-bottom: 24px;

    > svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      path {
        fill: ${({ theme }) => theme.color.grey600};
      }
    }

    > h2 {
      color: ${({ theme }) => theme.color.grey600};
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.07px;
    }
  }
  > button:last-child {
    display: flex;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;

    > svg {
      width: 20px;
      height: 20px;
      margin-right: 4px;
      path {
        fill: ${({ theme }) => theme.color.grey600};
      }
    }

    > h2 {
      color: ${({ theme }) => theme.color.grey600};
      font-size: 14px;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.07px;
    }

    &:disabled {
      cursor: not-allowed;
      > svg {
        g {
          path {
            fill: ${({ theme }) => theme.color.grey400};
          }
        }
      }
      > h2 {
        color: ${({ theme }) => theme.color.grey400};
      }
    }
  }
`;

export const QuestionItem = styled.div`
  display: flex;
  max-width: 626px;
  margin-bottom: 24px;

  > button {
    display: flex;
    border: none;
    background: none;
    padding: 0;

    > svg {
      width: 20px;
      height: 20px;
      margin-left: 6px;
      cursor: pointer;

      g {
        path {
          fill: ${({ theme }) => theme.color.grey500};
        }
      }
    }
  }
`;

export const QuestionList = styled.div`
  display: grid;
  max-height: calc(100vh - 500px);
  overflow: auto;
  margin-bottom: 24px;

  > div:last-child {
    margin-bottom: 0px;
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.color.grey200};
`;
