import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as MicrophoneIcon } from 'applicaiton/assets/microphone.svg';
import { ReactComponent as MicrophoneOffIcon } from 'applicaiton/assets/microphone-off.svg';
import { ReactComponent as CameraIcon } from 'applicaiton/assets/camera.svg';
import { ReactComponent as CameraOffIcon } from 'applicaiton/assets/camera-off.svg';
import { ReactComponent as DotsIcon } from 'applicaiton/assets/dots-vertical.svg';
import { ReactComponent as PhoneIcon } from 'applicaiton/assets/drop-phone.svg';
import { ReactComponent as ChatIcon } from 'applicaiton/assets/chat.svg';
import { ReactComponent as DocumentsIcon } from 'applicaiton/assets/documents.svg';
import { ReactComponent as InfoIcon } from 'applicaiton/assets/teleconsultation-info.svg';
import { PreviewModal } from 'features/feature-teleconsultation/constants';
import Watch from '../Watch';
import MoreModal from '../MoreModal';
import { ActionsSectionProps } from './modules';
import {
  Wrapper,
  IconStyled,
  BoxCentral,
  BoxRight,
  ButtonStyled,
} from './styles';
import { useTeleconsultationChat } from 'features/feature-teleconsultation/hooks/useTeleconsultationChat';

const ActionsSection: FC<ActionsSectionProps> = ({
  isEventStarted,
  handleSetPreviewModal,
  previewModal,
  isCameraOn,
  toggleCamera,
  isMicrophoneOn,
  toggleMicrophone,
  handleDisconnect,
  isChatTeleconsultation,
}) => {
  const [showMore, setShowMore] = useState(false);

  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const { conversation, messages, initConversation } =
    useTeleconsultationChat();

  const isChatOpened = previewModal === PreviewModal.Chat;
  const isDocumentsOpened = previewModal === PreviewModal.Documents;
  const isInfoOpened = previewModal === PreviewModal.Information;

  useEffect(() => {
    if (!isChatOpened && messages.length) {
      const getUnreadMessagesCount = async () => {
        const messages = await conversation?.getMessages();
        const count = await conversation?.getUnreadMessagesCount();
        const hasUnReadMessage =
          !count && isChatTeleconsultation ? !!messages?.items.length : !!count;
        setHasUnreadMessages(hasUnReadMessage);
      };
      getUnreadMessagesCount();
    }
  }, [conversation, messages, isChatTeleconsultation]);

  const handleOpenChat = async () => {
    if (!conversation) {
      initConversation();
    }
    await conversation?.setAllMessagesRead();
    handleSetPreviewModal(PreviewModal.Chat);
    setHasUnreadMessages(false);
  };

  return (
    <Wrapper>
      {isEventStarted && <Watch />}
      <BoxCentral>
        <IconStyled
          isActive={isMicrophoneOn}
          type="button"
          onClick={() => {
            toggleMicrophone();
          }}
          className={`${isEventStarted ? 'active' : ''}`}
        >
          {isMicrophoneOn ? <MicrophoneIcon /> : <MicrophoneOffIcon />}
        </IconStyled>
        <IconStyled
          isActive={isCameraOn}
          type="button"
          onClick={() => toggleCamera()}
          className={`${isEventStarted ? 'active' : ''}`}
        >
          {isCameraOn ? <CameraIcon /> : <CameraOffIcon />}
        </IconStyled>
        <IconStyled
          isActive={true}
          type="button"
          className={`more${isEventStarted ? ' active' : ''}`}
          onClick={() => setShowMore(true)}
        >
          <DotsIcon />
        </IconStyled>
        <IconStyled
          isActive={false}
          type="button"
          className={`${isEventStarted ? 'active' : ''}`}
          onClick={handleDisconnect}
        >
          <PhoneIcon />
        </IconStyled>
      </BoxCentral>
      <BoxRight>
        {isEventStarted && (
          <ButtonStyled
            isActive={isChatOpened}
            type="button"
            onClick={handleOpenChat}
          >
            {hasUnreadMessages && <span />}
            <ChatIcon />
          </ButtonStyled>
        )}
        {!isChatTeleconsultation && (
          <ButtonStyled
            isActive={isDocumentsOpened}
            type="button"
            onClick={() => handleSetPreviewModal(PreviewModal.Documents)}
          >
            <DocumentsIcon />
          </ButtonStyled>
        )}
        {!isChatTeleconsultation && (
          <ButtonStyled
            isActive={isInfoOpened}
            type="button"
            onClick={() => handleSetPreviewModal(PreviewModal.Information)}
          >
            <InfoIcon />
          </ButtonStyled>
        )}
      </BoxRight>
      {showMore && (
        <MoreModal
          isEventStarted={isEventStarted}
          onClose={() => setShowMore(false)}
          handleSetPreviewModal={handleSetPreviewModal}
          previewModal={previewModal}
        />
      )}
    </Wrapper>
  );
};

export default ActionsSection;
