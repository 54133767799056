import React, { FC, useEffect } from 'react';
import { Wrapper, AddNewType } from './styles';
import { Header, LayoutComponent } from './components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'common/hooks/redux';
import { resetSecretaryData } from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';

const SecretaryUserAdd: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetSecretaryData());
      dispatch(setHasUnsavedChanges(false));
    };
  }, []);

  return (
    <Wrapper>
      <Header />

      <AddNewType>{t('users.adding_new_secretary')}</AddNewType>

      <LayoutComponent />
    </Wrapper>
  );
};

export default SecretaryUserAdd;
