import React from 'react';

import AuthImage from './AuthImage';
import AuthFooter from './AuthFooter';
import NavBar from 'features/feature-auth-navbar';

import {
  LoginContentWrapper,
  LoginPageContentWrapper,
  LoginPageWrapper,
} from './styles';
export const AuthPageWrapper = ({ children }: { children: JSX.Element }) => {
  return (
    <LoginPageWrapper>
      <NavBar />
      <LoginContentWrapper>
        <LoginPageContentWrapper>{children}</LoginPageContentWrapper>{' '}
        <AuthImage />
      </LoginContentWrapper>
      <AuthFooter />
    </LoginPageWrapper>
  );
};
