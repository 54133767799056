import React, { FC, useEffect, useState } from 'react';
import SideMenu from 'features/feature-stepper-aside';
import { ClinicRegistration } from 'features';
import { MainStyled } from 'applicaiton/pages/ClinicRegistrationPage/styles';
import {
  currentStep,
  setStepSessionStorage,
} from 'applicaiton/sessionStorage/clinicRegistration';

const ClinicRegistrationPage: FC = () => {
  const [step, setStep] = useState(currentStep);

  useEffect(() => {
    setStepSessionStorage(step);
  }, [step]);

  return (
    <MainStyled>
      <SideMenu activeStep={step} />
      <ClinicRegistration step={step} setStep={setStep} />
    </MainStyled>
  );
};

export default ClinicRegistrationPage;
