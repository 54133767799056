import React, { FC } from 'react';
import { Wrapper } from './styles';
import { UserAdd, SideMenu, SubscriptionsLimitModal } from 'features';

const UserAddPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <UserAdd />
      <SubscriptionsLimitModal />
    </Wrapper>
  );
};

export default UserAddPage;
