import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputPhone } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import ButtonSection from 'features/feature-secretary-profile/components/ButtonSection';
import { DividerStyled, Wrapper } from './styles';
import ChangePhoneModal from '../ChangePhoneModal';
import { secretaryVerifyPhone } from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import { usePhoneForm } from 'features/feature-secretary-profile/hooks/usePhoneForm';
import { UserType } from '@docbay/schemas/dist/user/user.types';
import PhoneChangedPopUp from '../PhoneChangedPopUp';
import { resetPhoneData } from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';

const Phone: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { currentUser, isVerifiedOldPhone, error } = useAppSelector(
    (state) => state.secretaries,
  );
  const attemptLimitExceeded = error.endsWith('500');
  const [showPhonePopUp, setShowPhonePopUp] = useState(false);
  const [isPhoneExist, setIsPhoneExist] = useState(false);

  const { register, errors, handleSubmit, watch, setValue } = usePhoneForm({
    requiredError: t('errors.required'),
  });

  useEffect(() => {
    setValue('phone', currentUser?.phone || '');
  }, [currentUser]);

  const handleCloseModal = () => {
    isPhoneExist && setIsPhoneExist(false);
    setShowPhonePopUp(false);
  };

  const handleClosePhoneChangeModal = () => {
    setValue('phone', currentUser?.phone || '');
    dispatch(resetPhoneData());
  };

  const handleOpenForm = async () => {
    await dispatch(
      secretaryVerifyPhone({
        phone: String(currentUser?.phone),
        userType: UserType.Secretary,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        sessionStorage.setItem('newPhone', `+${watch('phone')}`);
        sessionStorage.setItem('oldPhone', String(currentUser?.phone));
      }
    });
    setShowPhonePopUp(false);
  };

  const onSubmit = () => {
    setShowPhonePopUp(true);
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <div>
        <InputPhone
          id="phone"
          label={t('phone') || ''}
          register={register}
          errorMessage={
            attemptLimitExceeded
              ? t('error_attempt_limit') || ''
              : errors.phone?.message
          }
          value={watch('phone')}
          isValid={true}
        />
      </div>
      <div>
        <DividerStyled />
        <ButtonSection
          disabled={
            !!Object.keys(errors).length ||
            watch('phone') === currentUser?.phone
          }
        />
      </div>
      {showPhonePopUp && (
        <PhoneChangedPopUp
          onClose={handleCloseModal}
          onSubmit={handleOpenForm}
        />
      )}
      {isVerifiedOldPhone && (
        <ChangePhoneModal
          isOpen={isVerifiedOldPhone}
          onClose={handleClosePhoneChangeModal}
        />
      )}
    </Wrapper>
  );
};

export default Phone;
