import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'common/components';
import {
  createClinicProfile,
  fetchInsurances,
  saveClinicProfile,
} from 'applicaiton/store/reducers/Clinics/ActionCreators';
import {
  InsurancesWrapper,
  InsurancesInput,
  DividerStyled,
  InsurancesToAddSection,
  InsurancesToAdd,
  StyledCloseButton,
  InsurancesItem,
} from './styles';
import ButtonSection from '../ButtonSection';
import { PathNames } from 'applicaiton/routes';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { InsurancesProps } from './models';

const Insurances: FC<InsurancesProps> = ({ handleSetNextPage }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isCreatePage = location.pathname.endsWith('create');
  const { clinicProfileData } = useAppSelector((state) => state.clinicProfile);
  const { insurances } = useAppSelector((state) => state.insurances);
  const [insurancesToAdd, setInsurancesToAdd] = useState<string[]>([]);
  const [insurancesToDelete, setInsurancesToDelete] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchInsurances());
  }, []);

  const insurancesOptions = useMemo(() => {
    const options = insurances.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    return options;
  }, [insurances]);

  const addedInsurancesIds = useMemo(() => {
    const allInsurancesId =
      insurancesToAdd.length || insurancesToDelete.length
        ? insurancesToAdd
        : (clinicProfileData?.insurances || []).map((item) => item.id!);

    return allInsurancesId;
  }, [clinicProfileData?.insurances, insurancesToDelete, insurancesToAdd]);

  const handleSetChanges = (value: string[]) => {
    const existingInsurances = (clinicProfileData?.insurances || []).map(
      (item) => item.id!,
    );
    const filteredForDelete = existingInsurances.filter(
      (val) => !value.includes(String(val)),
    );
    setInsurancesToDelete(filteredForDelete || []);
    setInsurancesToAdd(value);
  };

  const chosenInsurances = useMemo(() => {
    const allIds = [...addedInsurancesIds];

    const filteredInsurance = allIds.map((id) => {
      return insurances.filter((insurance) => insurance.id === id);
    });

    return filteredInsurance.flatMap((e) => e);
  }, [
    clinicProfileData?.insurances,
    insurancesToAdd,
    addedInsurancesIds,
    insurances,
  ]);

  const removeInsurance = (id: string) => {
    const filteredInsurances = addedInsurancesIds.filter((item) => item !== id);
    const insurancesToDelete = (clinicProfileData?.insurances || []).find(
      (item) => item.id === id,
    );
    setInsurancesToAdd(filteredInsurances);
    if (insurancesToDelete) {
      setInsurancesToDelete((prev) => [...prev, insurancesToDelete.id!]);
    }
  };
  const canMoveNext = isCreatePage;
  const handleSave = async () => {
    if (isCreatePage && !clinicProfileData) {
      const response = await dispatch(
        createClinicProfile({
          name: '',
          ...(insurancesToAdd.length ? { insurancesToAdd } : {}),
        }),
      );
      if (response.meta.requestStatus === 'fulfilled' && canMoveNext) {
        handleSetNextPage();
      }
    } else {
      const response = await dispatch(
        saveClinicProfile({
          id: clinicProfileData?.id!,
          data: {
            insurancesToAdd: insurancesToAdd,
            insurancesToDelete: insurancesToDelete,
          },
        }),
      );
      if (response.meta.requestStatus === 'fulfilled' && canMoveNext) {
        handleSetNextPage();
      }
    }
    setInsurancesToDelete([]);
    setInsurancesToAdd([]);
  };

  const handleCancel = () => {
    navigate(PathNames.myClinics);
  };

  const isStateChanged = useMemo(() => {
    return !!insurancesToAdd.length || !!insurancesToDelete.length;
  }, [insurancesToAdd, insurancesToDelete]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const isButtonDisabled = isCreatePage ? false : !isStateChanged;
  const showNextButton = isCreatePage;

  return (
    <div>
      <InsurancesWrapper>
        <label>
          {t('clinicsConfiguration.insurance.insurance_title') || ''}
        </label>
        <InsurancesInput>
          <Dropdown
            id={'insurances'}
            value={addedInsurancesIds}
            placeholder={
              t(
                'clinicsConfiguration.insurance.placeholder_select_insurances',
              ) || ''
            }
            searchPlaceholder={
              t('clinicsConfiguration.insurance.search_placeholder') || ''
            }
            onChange={(value) => {
              handleSetChanges(value as string[]);
            }}
            options={insurancesOptions}
            isMulti={true}
            withSearch
          />
        </InsurancesInput>

        {!!chosenInsurances.length && (
          <InsurancesToAddSection>
            <label>{`${chosenInsurances.length} ${t('selected')}`}</label>
            <InsurancesToAdd>
              {chosenInsurances.map((item) => {
                return (
                  <InsurancesItem key={item.id}>
                    <span>{item.name}</span>
                    <StyledCloseButton
                      onClick={() => removeInsurance(item.id)}
                    />
                  </InsurancesItem>
                );
              })}
            </InsurancesToAdd>
          </InsurancesToAddSection>
        )}
      </InsurancesWrapper>
      <div>
        <DividerStyled />
        <ButtonSection
          onSave={handleSave}
          onCancel={handleCancel}
          disabled={isButtonDisabled}
          showNextButton={showNextButton}
        />
      </div>
    </div>
  );
};

export default Insurances;
