import React, { FC } from 'react';
import { SwitchStyled, Wrapper } from './styles';
import { useTranslation } from 'react-i18next';
import Switch from 'common/components/Switch';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setOnlineStatusVisible } from 'applicaiton/store/reducers/OnlineHours/OnlineHoursSlice';

const OnlineConfigure: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onlineStatusVisible } = useAppSelector((state) => state.onlineHours);

  const onToggleStatusVisible = () => {
    dispatch(setOnlineStatusVisible(!onlineStatusVisible));
  };

  return (
    <Wrapper>
      <h2>{t('subscription_settings.online_configure')}</h2>
      <h3>{t('subscription_settings.online_status_visibility')}</h3>
      <SwitchStyled>
        <Switch
          checked={onlineStatusVisible}
          onChange={onToggleStatusVisible}
        />
        <p>{t('subscription_settings.switcher_label')}</p>
      </SwitchStyled>
    </Wrapper>
  );
};

export default OnlineConfigure;
