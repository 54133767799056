import styled from 'styled-components';

export const DocumentActions = styled.div`
  display: flex;

  > div {
    padding: 10px;
    cursor: pointer;

    > svg {
      width: 24px;
      height: 24px;

      path {
        fill: ${({ theme }) => theme.color.grey600};
      }
    }
  }
`;

export const DocumentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border-radius: 4px;
    background: ${({ theme }) => theme.color.grey50};
  }

  > div {
    display: flex;
    align-items: center;

    > svg {
      min-width: 20px;
      min-height: 20px;
      margin-right: 8px;
    }
  }
`;

export const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const InputWrapper = styled.div`
  position: relative;
  flex-grow: 1;

  input {
    width: 100%;
    color: ${({ theme }) => theme.color.grey900};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.04px;

    padding: 10px 14px 10px 42px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.color.blue800};
    background: ${({ theme }) => theme.color.blue50};
    box-shadow: 1px 1px 2px 0 rgba(54, 54, 54, 0.1);
  }

  > svg {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 10px;
  > :last-child {
    margin-left: 8px;
  }
`;
