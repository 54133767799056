import { createAsyncThunk } from '@reduxjs/toolkit';
import { SpecializationsActions } from 'applicaiton/constants/actions';
import { AxiosError } from 'axios';
import { SpecializationsAPI } from 'integration/api/specializations';

export const fetchSpecializations = createAsyncThunk(
  SpecializationsActions.fetchSpecializations,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await SpecializationsAPI.fetchSpecializations();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
