import { createSlice } from '@reduxjs/toolkit';
import { MapBox } from 'applicaiton/constants/actions';
import { Location } from 'common/types/mapBox';

interface InitialState {
  isLoading: boolean;
  error: string;
  suggestions: Location[];
}

const initialState: InitialState = {
  suggestions: [],
  isLoading: false,
  error: '',
};

const isCurrentSliceChanged = (type: string, status: string) => {
  return type.endsWith(`${MapBox.fetchLocation}/${status}`);
};

const mapBox = createSlice({
  name: 'mapBox',
  initialState,
  reducers: {
    resetSuggestions: (state) => {
      state.suggestions = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'pending'),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'rejected'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'fulfilled'),
      (state, action) => {
        state.isLoading = false;
        state.suggestions = action.payload.suggestions;
      },
    );
  },
});

export const { resetSuggestions } = mapBox.actions;

export default mapBox.reducer;
