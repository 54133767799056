import React, { FC, useState } from 'react';
import {
  Wrapper,
  StyledSection,
  StepSection,
  StepItem,
  Step,
  StepText,
  StepSectionWrapper,
} from './styles';

import ProfileInfo from '../ProfileInfo';
import { ReactComponent as CheckIcon } from 'applicaiton/assets/checked.svg';
import { useTranslation } from 'react-i18next';
import ClinicProfile from '../ClinicProfile';

const LayoutComponent: FC = () => {
  const [activeForm, setActiveForm] = useState<number>(0);
  const [addedClinics, setClinics] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleAddClinic = () => {
    setClinics((prev) => [
      ...prev,
      prev.length === 0
        ? t('users.clinic')
        : `${t('users.clinic')} ${prev.length + 1}`,
    ]);
    setActiveForm((prev) => prev + 1);
  };

  const handleNextBtn = () => {
    setActiveForm((prev) => prev + 1);
  };

  const handleNextGeneralBtn = () => {
    setActiveForm((prev) => prev + 1);

    if (!addedClinics.length) {
      setClinics((prev) => [
        ...prev,
        prev.length === 0
          ? t('users.clinic')
          : `${t('users.clinic')} ${prev.length + 1}`,
      ]);
    }
  };

  const handleBackBtn = () => {
    setActiveForm((prev) => prev - 1);
  };

  const handleRemoveClinic = (index: number) => {
    const filteredClinics: string[] = [...addedClinics].filter(
      (_, i) => index !== i,
    );

    const renamedClinics = filteredClinics.map(
      (_item: string, index: number) => {
        if (index === 0) {
          return t('users.clinic');
        } else {
          return `${t('users.clinic')} ${index}`;
        }
      },
    );

    setActiveForm((prev) => {
      return addedClinics.length > prev ? prev : prev - 1;
    });

    setClinics(renamedClinics);
  };

  const getCurrentForm = () => {
    switch (activeForm) {
      case 0:
        return <ProfileInfo onNextBtnClick={handleNextGeneralBtn} />;

      default:
        return (
          <ClinicProfile
            addClinic={handleAddClinic}
            arrayIndex={activeForm > 1 ? activeForm - 1 : 0}
            activeForm={activeForm}
            onNextBtnClick={handleNextBtn}
            onBackBtnClick={handleBackBtn}
            onRemoveClinic={handleRemoveClinic}
          />
        );
    }
  };

  return (
    <Wrapper>
      <StyledSection>
        <StepSectionWrapper>
          <StepSection>
            <StepItem>
              {activeForm < 1 ? (
                <Step isActive={activeForm === 0}>1</Step>
              ) : (
                <Step isActive={true}>
                  <CheckIcon />
                </Step>
              )}
              <StepText isActive={activeForm === 0}>
                {t('users.profile_info')}
              </StepText>
            </StepItem>

            {activeForm <= 1 && addedClinics.length === 0 && (
              <StepItem>
                {activeForm <= 1 ? (
                  <Step isActive={activeForm === 1}>2</Step>
                ) : (
                  <Step isActive={true}>
                    <CheckIcon />
                  </Step>
                )}
                <StepText isActive={activeForm === 1}>
                  {t('users.clinic')}
                </StepText>
              </StepItem>
            )}

            {(activeForm >= 2 || addedClinics.length > 0) &&
              addedClinics.map((clinic, index) => {
                return (
                  <StepItem key={clinic}>
                    <Step isActive={activeForm >= index + 1}>
                      {activeForm > index + 1 ? <CheckIcon /> : 2 + index}
                    </Step>
                    <StepText isActive={activeForm === index + 1}>
                      {clinic}
                    </StepText>
                  </StepItem>
                );
              })}
          </StepSection>
        </StepSectionWrapper>
        {getCurrentForm()}
      </StyledSection>
    </Wrapper>
  );
};

export default LayoutComponent;
