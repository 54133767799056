import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div:first-child {
    display: flex;
    align-items: center;
  }

  button {
    max-width: max-content;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.color.blue800};
    }
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.blueDark100};
  margin-right: 16px;
  color: ${({ theme }) => theme.color.white};

  > svg {
    path {
      fill: ${({ theme }) => theme.color.grey900};
    }
  }

  > img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
`;

export const Name = styled.h1`
  color: ${({ theme }) => theme.color.grey900};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: capitalize;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  > button {
    padding: 12px 24px;
    min-width: fit-content;
    white-space: nowrap;

    &:last-child {
      margin-left: 16px;
    }
  }
`;
