import React, { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  FormSubTitle,
  FormTitle,
  CreatePasswordWrapper,
  TitleWrapper,
} from './styles';
import { useAppDispatch } from 'common/hooks/redux';
import { setIsVerificationCodeExpired } from 'applicaiton/store/reducers/Professionals/ProfessionalsSlice';
import { ReactComponent as ExpiredLinkIcon } from 'applicaiton/assets/expired.svg';

export const ExpiredInvitationLink: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsVerificationCodeExpired(false));
  }, []);

  return (
    <CreatePasswordWrapper>
      <ExpiredLinkIcon />
      <TitleWrapper>
        <FormTitle>{t('auth.create_password.expired_title')}</FormTitle>
        <FormSubTitle>
          {t('auth.create_password.expired_sub_title')}
        </FormSubTitle>
      </TitleWrapper>
    </CreatePasswordWrapper>
  );
};
