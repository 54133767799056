import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccesslIcon } from 'applicaiton/assets/success-icon.svg';
import { StyledModal } from './styles';

interface SuccessModalProps {
  open: boolean;
  profileName: string;
  profileEmail: string;
  onClose: () => void;
  isClinicOwnerProfile: boolean;
}

const SuccessModal: FC<SuccessModalProps> = ({
  open,
  profileName,
  profileEmail,
  onClose,
  isClinicOwnerProfile,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={open}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccesslIcon />
          {isClinicOwnerProfile ? (
            <p>
              {t(
                'professionalsListConfiguration.create_professional.clinic_owner_profile.titlePart1',
              )}{' '}
              <span>{profileName}</span>{' '}
              {t(
                'professionalsListConfiguration.create_professional.clinic_owner_profile.titlePart2',
              )}
            </p>
          ) : (
            <p>
              {t(
                'professionalsListConfiguration.create_professional.success.profile_of',
              )}{' '}
              <span>{profileName}</span>{' '}
              {t(
                'professionalsListConfiguration.create_professional.success.successfully',
              )}{' '}
              <span>{profileEmail}</span>
            </p>
          )}
          <SecondaryButton onClick={onClose}>
            {t('professionalsListConfiguration.create_professional.success.ok')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessModal;
