import { Link } from 'react-router-dom';
import { Box, styled } from '@mui/system';
import { Button, TextField, Typography } from '@mui/material';
import { styledTheme } from 'applicaiton/theme';

export const TextFieldStyled = styled(TextField)`
  margin-bottom: 30px;
`;
export const LinkStyled = styled(Link)`
  margin-left: 30px;
  color: ${({ theme }) => theme.palette.primary.dark};
`;
export const ButtonStyled = styled(Button)`
  min-width: 400px;
`;

export const paperStyled = {
  maxWidth: 400,
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#fff',
  borderRadius: 1,
  padding: 2,
  heigth: '100vh',
};

export const LoginPageContentWrapper = styled(Box)`
  padding: 0;
  width: 50%;
  min-width: 480px;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    min-width: unset;
    padding: 0 16px;
  }
`;

export const ImageContentWrapper = styled('div')`
  width: 50%;
  min-width: 480px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
`;

export const ImageAuth = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${styledTheme.color.green50};
`;

export const LoginPageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

export const FooterWrapper = styled('div')`
  background-color: ${styledTheme.color.menu};
  padding: 20px 32px 0;
  width: 100%;

  > div:last-child {
    display: flex;
    justify-content: space-between;
  }
`;

export const LoginContentWrapper = styled(Box)`
  display: flex;
  height: 100%;
  flex-wrap: wrap;

  @media screen and (max-width: 960px) {
    > div:last-child {
      display: none;
    }
  }
`;

export const FooterContentStyled = styled(Typography)`
  color: ${styledTheme.color.grey200};
  font-family: 'Mulish', sans-serif;
  padding-bottom: 12px;
`;

export const StyledDivider = styled(Box)`
  height: 1px;
  margin: 0 0 16px;
  background-color: ${({ theme }) => styledTheme.color.grey200};
`;
