import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FormStyled = styled.form`
  display: grid;
  gap: 24px;
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;

  div {
    max-width: unset;
  }

  > section {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 24px;
  }
`;

export const AccessibilityStyled = styled.div`
  display: grid;
  gap: 12px;
  width: 100%;
  height: fit-content;

  > div {
    > label {
      font-weight: 500;
    }
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.color.grey200};
`;

export const AddressSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  > div:first-of-type {
    width: 492px;
    margin-bottom: 12px;
  }
`;
