import styled from 'styled-components';
import { commonColors } from 'applicaiton/theme';

interface Props {
  isActive: boolean;
}

export const ModalStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  flex-direction: column;
  width: 664px;
  padding: 32px;
  max-height: 80vh;
  gap: 16px;
  border-radius: 8px;
  background: ${commonColors.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  .content-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;

      path {
        fill: ${commonColors.grey700};
      }
    }
  }
  h6 {
    color: ${commonColors.grey700};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  button {
    padding: 12px 24px;
    width: fit-content;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Tab = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  margin-right: 20px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.blue700 : theme.color.grey600};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 140%;
  cursor: pointer;
  border-bottom: ${({ theme, isActive }) =>
    isActive ? `2px solid ${theme.color.blue700}` : 'none'};

  > svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.blue700 : theme.color.grey600};
    }
  }
`;
