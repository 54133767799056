import styled from 'styled-components';

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 340px;
  width: 340px;
  padding: 20px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    color: ${({ theme }) => theme.color.grey800};
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: 0.09px;
  }

  button {
    all: unset;
    height: 44px;
    display: flex;
    align-items: center;
    width: fit-content;
    color: ${({ theme }) => theme.color.grey700};
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.04px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }

    &:hover {
      color: ${({ theme }) => theme.color.blue800};
      svg {
        path {
          fill: ${({ theme }) => theme.color.blue800};
        }
      }
    }
  }
`;
