import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useSetPasswordSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required') || '';
  const passwordError = t('errors.password_must_contain') || '';

  return yup
    .object({
      code: yup.string().required(requiredError),
      password: yup
        .string()
        .required(requiredError)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.{10,})/, passwordError),
    })
    .required();
};
