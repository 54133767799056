import React, { useEffect } from 'react';
import { StyledLink } from './styles';
import { ReactComponent as LinkIcon } from 'applicaiton/assets/link.svg';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  getCustomerBillingActivationLink,
  getCustomerBillingLink,
  getCustomerBillingUpdateLink,
  getCustomerPortalLink,
} from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { useTranslation } from 'react-i18next';
import Loader from 'common/components/Loader';

const PersonalInfo = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { customerPortalLink, currentClinicOwner, customerBillingLink } =
    useAppSelector((state) => state.clinicOwnerSlice);

  useEffect(() => {
    if (currentClinicOwner) {
      const getLinks = async () => {
        await dispatch(getCustomerPortalLink());
        await dispatch(getCustomerBillingLink(currentClinicOwner.id!)).then(
          (data) => {
            if (data.meta.requestStatus === 'fulfilled') {
              if (data.payload) {
                dispatch(getCustomerBillingUpdateLink());
              } else {
                dispatch(getCustomerBillingActivationLink());
              }
            }
          },
        );
      };

      getLinks();
    }
  }, [currentClinicOwner]);

  if (!customerPortalLink?.length || !customerBillingLink?.length) {
    return <Loader />;
  }

  return (
    <div>
      {customerPortalLink && (
        <StyledLink to={customerPortalLink} target="_blank">
          {t('clinic_owner.billing.button_text')} <LinkIcon />
        </StyledLink>
      )}
      {customerBillingLink && (
        <StyledLink to={customerBillingLink} target="_blank">
          {t('clinic_owner.billing.button_billing_text')} <LinkIcon />
        </StyledLink>
      )}
    </div>
  );
};

export default PersonalInfo;
