import * as yup from 'yup';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Gender } from 'applicaiton/constants/gender';
import { nifValidate } from 'common/helpers/nifValidate';
import { useTranslation } from 'react-i18next';

export interface PatientForm {
  gender: Gender;
  firstName: string | null;
  lastName: string | null;
  phone: string;
  dateOfBirth: string | null;
  email: string;
  fin: string;
  ssn: string;
}

export const useCreatePatient = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const emailError = t('errors.emailFormat');
  const numberError = t('errors.number_error');
  const lengthFinError = t('errors.lengthFinError');
  const lengthSsnError = t('errors.lengthSsnError');
  const phoneError = t('errors.phone_invalid');

  const formValues = {
    gender: Gender.Male,
    firstName: null,
    lastName: null,
    phone: '',
    dateOfBirth: null,
    email: '',
    fin: '',
    ssn: '',
  };

  const patientSchema = yup
    .object({
      gender: yup.string(),
      firstName: yup.string().required(requiredError!).nullable(),
      lastName: yup.string().required(requiredError!).nullable(),
      phone: yup.string().test({
        name: 'is-valid',
        test(value, ctx) {
          const isValidNumber = isValidPhoneNumber(`+${String(value)}`);
          if (!isValidNumber) return ctx.createError({ message: phoneError });
          return isValidNumber;
        },
      }),
      dateOfBirth: yup.string().required(requiredError!).nullable(),
      email: yup.string().email(emailError!),
      fin: yup.string().test({
        name: 'nif',
        test(value, ctx) {
          if (!value || value?.length === 0) return true;

          const isNum = nifValidate(value);

          if (value?.length !== 9) {
            return ctx.createError({ message: lengthFinError });
          } else if (!isNum) {
            return ctx.createError({ message: numberError });
          }
          return isNum;
        },
      }),
      ssn: yup.string().test({
        name: 'socialSecurityNumber',
        test(value, ctx) {
          if (!value || value?.length === 0) return true;

          const isNum = /^\d+$/.test(value!);

          if (!isNum) {
            return ctx.createError({ message: numberError });
          } else if (value?.length !== 9) {
            return ctx.createError({ message: lengthSsnError });
          }

          return isNum;
        },
      }),
    })
    .required();

  const {
    watch: watchPatient,
    getValues: getValuesPatient,
    setValue: setValuePatient,
    register: registerPatient,
    handleSubmit: handleSubmitPatient,
    clearErrors: clearErrorsPatient,
    setError: setErrorPatient,
    control,
    reset: resetPatient,
    formState: { errors: errorsPatient },
  } = useForm<PatientForm>({
    resolver: yupResolver(patientSchema),
    defaultValues: formValues,
  });

  const { isDirty } = useFormState({
    control,
  });

  return {
    watchPatient,
    getValuesPatient,
    setValuePatient,
    errorsPatient,
    registerPatient,
    handleSubmitPatient,
    clearErrorsPatient,
    setErrorPatient,
    isDirtyPatient: isDirty,
    resetPatient,
  };
};
