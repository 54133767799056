import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PatientsState } from './models';
import {
  createFamilyMember,
  createPatient,
  deleteFamilyMember,
  deletePatient,
  fetchPatients,
  fetchPatientsAndFamilyMembers,
  getFamilyMembers,
  getPatientById,
  getPatientsSubscriptions,
  updateFamilyMember,
  updatePatient,
  uploadCSV,
} from './ActionCreators';
import {
  FamilyMemberResponseDto,
  PatientResponseDto,
  PatientSearchResponseDto,
} from '@docbay/schemas';

const initialState: PatientsState = {
  patients: null,
  patientsFamilyMembersSearch: null,
  currentFamilyMemberSearch: null,
  patientSubscriptions: null,
  familyMembers: null,
  isLoading: false,
  error: '',
  errorCode: '',
  currentPatient: null,
  editedPatientId: null,
  clearPatientSearch: false,
  editedFamilyMember: null,
  isCreatedFamilyMember: false,
  isDeletedFamilyMember: false,
  isUpdatedFamilyMember: false,
  page: 1,
  total: 0,
  totalPages: 0,
  deletedSuccess: false,
  patientToDelete: null,
  importedPatients: null,
  importError: '',
};

const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setCurrentPatient: (
      state,
      actions: PayloadAction<PatientResponseDto | null>,
    ) => {
      state.currentPatient = actions.payload;
    },
    setPatientsFamilyMembersSearch: (
      state,
      actions: PayloadAction<PatientSearchResponseDto[] | null>,
    ) => {
      state.patientsFamilyMembersSearch = actions.payload;
    },
    setCurrentSearchItem: (
      state,
      actions: PayloadAction<PatientSearchResponseDto | null>,
    ) => {
      state.currentFamilyMemberSearch = actions.payload;
    },
    setPatients: (
      state,
      actions: PayloadAction<PatientResponseDto[] | null>,
    ) => {
      state.patients = actions.payload;
    },
    setEditedPatientId: (state, actions: PayloadAction<string | null>) => {
      state.editedPatientId = actions.payload;
    },
    setClearPatientSearch: (state, actions: PayloadAction<boolean>) => {
      state.clearPatientSearch = actions.payload;
    },
    setIsCreatedFamilyMember: (state, actions: PayloadAction<boolean>) => {
      state.isCreatedFamilyMember = actions.payload;
    },
    setIsDeletedFamilyMember: (state, actions: PayloadAction<boolean>) => {
      state.isDeletedFamilyMember = actions.payload;
    },
    setIsUpdatedFamilyMember: (state, actions: PayloadAction<boolean>) => {
      state.isUpdatedFamilyMember = actions.payload;
    },
    setEditedFamilyMember: (
      state,
      actions: PayloadAction<FamilyMemberResponseDto | null>,
    ) => {
      state.editedFamilyMember = actions.payload;
    },
    selectPatientToDelete: (
      state,
      actions: PayloadAction<PatientResponseDto | null>,
    ) => {
      state.patientToDelete = actions.payload;
      if (!actions.payload) {
        state.deletedSuccess = false;
      }
    },
    clearError: (state) => {
      state.error = '';
      state.errorCode = '';
    },
    resetImportedPatients: (state) => {
      state.importedPatients = null;
    },
    resetImportError: (state) => {
      state.importError = '';
    },
  },
  extraReducers: (builder) => {
    //Patients and family members fetch
    builder.addCase(fetchPatientsAndFamilyMembers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPatientsAndFamilyMembers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(
      fetchPatientsAndFamilyMembers.fulfilled,
      (state, action) => {
        state.patientsFamilyMembersSearch = action.payload;
        state.isLoading = false;
      },
    );
    //create Patient
    builder.addCase(createPatient.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createPatient.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createPatient.fulfilled, (state, action) => {
      state.currentPatient = action.payload;
      state.isLoading = false;
    });
    //get patient by id
    builder.addCase(getPatientById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPatientById.rejected, (state, action) => {
      state.isLoading = false;
      const payload = action.payload as any;
      state.error = payload?.message;
      state.errorCode = payload?.statusCode;
      state.currentPatient = null;
    });
    builder.addCase(getPatientById.fulfilled, (state, action) => {
      state.currentPatient = action.payload;
      state.isLoading = false;
    });
    //get patient by id
    builder.addCase(updatePatient.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePatient.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updatePatient.fulfilled, (state, action) => {
      state.currentPatient = action.payload;
      state.isLoading = false;
    });
    //get patients subscriptions
    builder.addCase(getPatientsSubscriptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPatientsSubscriptions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getPatientsSubscriptions.fulfilled, (state, action) => {
      state.patientSubscriptions = action.payload.entities;
      state.isLoading = false;
    });
    //get familyMembers for patient
    builder.addCase(getFamilyMembers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFamilyMembers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getFamilyMembers.fulfilled, (state, action) => {
      state.familyMembers = action.payload;
      state.isLoading = false;
    });
    // add familyMember for patient
    builder.addCase(createFamilyMember.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createFamilyMember.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createFamilyMember.fulfilled, (state, action) => {
      state.isCreatedFamilyMember = true;
      state.isLoading = false;
    });
    // delete familyMember for patient
    builder.addCase(deleteFamilyMember.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteFamilyMember.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteFamilyMember.fulfilled, (state, action) => {
      state.isDeletedFamilyMember = true;
      state.isLoading = false;
    });
    // update familyMember for patient
    builder.addCase(updateFamilyMember.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateFamilyMember.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateFamilyMember.fulfilled, (state, action) => {
      state.isUpdatedFamilyMember = true;
      state.isLoading = false;
    });
    // update familyMember for patient
    builder.addCase(fetchPatients.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPatients.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchPatients.fulfilled, (state, action) => {
      state.patients = action.payload.entities;
      state.page = action.payload.page || 1;
      state.total = action.payload.total || 0;
      state.totalPages = action.payload.totalPages || 0;
      state.isLoading = false;
    });
    // delete patient
    builder.addCase(deletePatient.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deletePatient.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deletePatient.fulfilled, (state) => {
      state.isLoading = false;
      state.deletedSuccess = true;
      state.patients =
        state.patients?.filter(
          (item) => item.id !== state.patientToDelete?.id,
        ) || [];
      state.error = '';
    });
    // upload CSV
    builder.addCase(uploadCSV.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadCSV.rejected, (state, action) => {
      state.isLoading = false;
      state.importError = action.payload as string;
    });
    builder.addCase(uploadCSV.fulfilled, (state, action) => {
      state.isLoading = false;
      state.importedPatients = action.payload;
      state.importError = '';
    });
  },
});

export const {
  setCurrentPatient,
  setCurrentSearchItem,
  setPatients,
  setEditedPatientId,
  setClearPatientSearch,
  setIsCreatedFamilyMember,
  setIsDeletedFamilyMember,
  setIsUpdatedFamilyMember,
  setEditedFamilyMember,
  setPatientsFamilyMembersSearch,
  selectPatientToDelete,
  resetImportedPatients,
  resetImportError,
  clearError,
} = patientsSlice.actions;

export default patientsSlice.reducer;
