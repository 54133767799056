import React, { FC } from 'react';
import { ReactComponent as LogoIcon } from 'applicaiton/assets/logo.svg';
import { LoaderStyled } from './styles';
import { useTranslation } from 'react-i18next';

interface LoaderProps {
  text?: string;
}

const Loader: FC<LoaderProps> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <LoaderStyled>
      <div>
        <LogoIcon />
      </div>
      <h1>
        {t('loader')}
        <br />
        {text}
      </h1>
    </LoaderStyled>
  );
};

export default Loader;
