import React, { FC, memo, useEffect, useRef, useState } from 'react';

import { ReactComponent as LocationIcon } from 'applicaiton/assets/location.svg';
import { fetchLocations } from 'applicaiton/store/reducers/MapBox/ActionCreators';
import { getUserCountryIso } from 'applicaiton/sessionStorage/auth';
import { resetSuggestions } from 'applicaiton/store/reducers/MapBox/MapBoxSlice';

import { Context } from 'common/types/mapBox';
import { useDebounce } from 'common/hooks/useDebounce';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import Input from '../Input';

import { MapBoxStyled, LocationItem } from './styles';

interface MapBoxProps {
  id: string;
  defaultValue: string;
  setLocation: (location: Context) => void;
  label?: string;
  showRequiredIcon?: boolean;
  errorMessage?: string;
  countryIso?: string;
}

const MapBoxAutocompleteInput: FC<MapBoxProps> = ({
  id,
  defaultValue,
  setLocation,
  label,
  showRequiredIcon,
  errorMessage,
  countryIso = '',
}) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { suggestions } = useAppSelector((state) => state.mapBox);
  const [searchItem, setSearchItem] = useState(defaultValue);
  const [showList, setShowList] = useState(false);
  const [country, setCountry] = useState<string>(
    countryIso || getUserCountryIso() || '',
  );
  const searchQuery = useDebounce(
    searchItem !== defaultValue ? searchItem : '',
    500,
  );

  useOnClickOutside(ref, () => {
    setShowList(false);
    setSearchItem(defaultValue);
  });

  useEffect(() => {
    if (!defaultValue) {
      setSearchItem('');
      setCountry(countryIso);
      dispatch(resetSuggestions());
    }
  }, [defaultValue, countryIso]);

  useEffect(() => {
    if (searchQuery) {
      dispatch(
        fetchLocations({
          searchItem: searchQuery,
          country: country.toLowerCase(),
        }),
      );
    }
  }, [searchQuery]);

  const handleSetLocation = (location: Context) => {
    const streetName = (
      location?.address?.street_name || location?.street?.name
    )
      ?.split(' ')
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(' ');

    const address = [
      streetName || '',
      location?.postcode?.name || '',
      location?.place?.name || '',
      location?.country?.name || '',
    ]
      .filter((item) => !!item)
      .join(' ');

    setSearchItem(address);
    setLocation(location);
    setShowList(false);
  };

  const onChange = (value: string) => {
    setShowList(true);
    setSearchItem(value);
  };

  return (
    <MapBoxStyled ref={ref}>
      <Input
        id={id}
        type="text"
        value={searchItem}
        onChange={(e) => onChange(e.target.value)}
        placeholder={'Ex. Lisbon, Av. dos Cravos Vermelhos, 2720-354'}
        label={label}
        showRequiredIcon={showRequiredIcon}
        autoComplete={'off'}
        errorMessage={errorMessage}
      />
      {showList ? (
        <section>
          {suggestions?.map((item) => (
            <LocationItem
              key={item.mapbox_id}
              onClick={() => {
                handleSetLocation(item.context);
              }}
            >
              <LocationIcon />
              <div>
                <h2>{item.name}</h2>
                <p>{item.place_formatted}</p>
              </div>
            </LocationItem>
          ))}
        </section>
      ) : (
        ''
      )}
    </MapBoxStyled>
  );
};

export default memo(MapBoxAutocompleteInput);
