import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClinicsAPI } from 'integration/api/clinics';
import { ClinicsActions } from 'applicaiton/constants/actions';
import { AxiosError } from 'axios';
import { SearchProfessionalsByFiltersSearchCriteriaDto } from '@docbay/schemas';

export const searchProfessionalsByFilters = createAsyncThunk(
  ClinicsActions.searchProfessionalsByFilters,
  async (
    data: SearchProfessionalsByFiltersSearchCriteriaDto,
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } =
        await ClinicsAPI.searchProfessionalsByFilters(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
