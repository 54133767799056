import React, { FC, useState } from 'react';
import {
  ArrowStyled,
  ButtonStyled,
  DividerStyled,
  DropdownGroupStyled,
  DropdownMenuItemStyled,
  DropdownMenuWrapper,
  MenuDropdown,
  MenuItemStyled,
} from './styles';
import { ReactComponent as KeyboardArrowDownIcon } from 'applicaiton/assets/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes';
import { DocBayPatientLandingLink } from 'applicaiton';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

const AuthMenuDropdown: FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const language = getLanguageFromLocalStorage();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonStyled
        id="customized-button"
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={
          <ArrowStyled active={open ? 1 : 0}>
            <KeyboardArrowDownIcon />
          </ArrowStyled>
        }
      >
        <MenuItemStyled variant="overline" open={open ? 1 : 0}>
          {t('auth.navbar.log_in')} / {t('auth.navbar.sign_up')}
        </MenuItemStyled>
      </ButtonStyled>
      <MenuDropdown
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <DropdownMenuWrapper>
          <DropdownGroupStyled>
            <span>{t('auth.navbar.patient')}</span>

            <DropdownMenuItemStyled onClick={handleClose}>
              <a
                href={`${DocBayPatientLandingLink}${language}/`}
                target="_blank"
                rel="noreferrer"
              >
                {t('auth.navbar.log_in')}
              </a>
            </DropdownMenuItemStyled>
          </DropdownGroupStyled>

          <DividerStyled />

          <DropdownGroupStyled>
            <span>{t('auth.navbar.professional')}</span>

            <DropdownMenuItemStyled onClick={handleClose}>
              <a href={PathNames.login}>{t('auth.navbar.log_in')}</a>
            </DropdownMenuItemStyled>
          </DropdownGroupStyled>

          <DividerStyled />

          <DropdownGroupStyled>
            <span>{t('auth.navbar.clinic')}</span>

            <DropdownMenuItemStyled onClick={handleClose}>
              <a href={PathNames.clinicRegistration}>
                {t('auth.navbar.sign_up')}
              </a>
            </DropdownMenuItemStyled>
          </DropdownGroupStyled>
        </DropdownMenuWrapper>
      </MenuDropdown>
    </>
  );
};

export default AuthMenuDropdown;
