import React, { FC, createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';

import { ReCaptchaProps } from './models';
import { ReCaptchaStyled } from './styles';

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

const ReCaptcha: FC<ReCaptchaProps> = ({ error, onChange, onExpired }) => {
  const { t, i18n } = useTranslation();
  const reCaptchaRef = createRef() as any;
  const [recaptchaKey, setRecaptchaKey] = useState(0); // Change this key to trigger re-render

  useEffect(() => {
    return () => {
      // do something with dependency
      // change language in captcha
      setRecaptchaKey((prevKey) => prevKey + 1);
      reCaptchaRef.current?.reset();
    };
  }, [i18n.language]);

  return (
    <ReCaptchaStyled error={!!error}>
      <ReCAPTCHA
        key={recaptchaKey}
        ref={reCaptchaRef}
        sitekey={SITE_KEY}
        onChange={onChange}
        hl={i18n.language}
        onExpired={onExpired}
      />
      {error && <p>{t('errors.captcha_required')}</p>}
    </ReCaptchaStyled>
  );
};

export default ReCaptcha;
