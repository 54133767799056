import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getUserTimezone } from 'applicaiton/sessionStorage/auth';

import { InitialState } from './models';
import { setTimeZoneData } from './ActionCreators';

const initialState: InitialState = {
  tz:
    getUserTimezone() ||
    (Intl.DateTimeFormat().resolvedOptions().timeZone as any),
  error: '',
  isLoading: false,
};

const timeZoneSlice = createSlice({
  name: 'timeZoneSlice',
  initialState,
  reducers: {
    setTimeZone: (state, action: PayloadAction<string>) => {
      state.tz = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTimeZoneData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setTimeZoneData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(setTimeZoneData.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setTimeZone } = timeZoneSlice.actions;

export default timeZoneSlice.reducer;
