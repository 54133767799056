import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionPackageDto } from '@docbay/schemas';
import { ReactComponent as QuantityIcon } from 'applicaiton/assets/quantity.svg';
import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';
import { PaymentOrderStyled, SummarySectionStyled } from './styles';
import { PaymentOrderProps } from './modules';
import PromoCodes from '../PromoCodes';

const PaymentOrder: FC<PaymentOrderProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const { subscriptionPackages } = currentClinicOwnerRegistration();
  const { promoCode } = currentClinicOwnerRegistration();

  const packages: SubscriptionPackageDto[] = useMemo(() => {
    let uniqList = [];
    for (const key in subscriptionPackages) {
      uniqList.push(subscriptionPackages[key][0]);
    }
    return uniqList;
  }, [subscriptionPackages]);

  const packagesSum = useMemo(() => {
    if (!subscriptionPackages) return 0;
    const packages = Object.values(
      subscriptionPackages,
    ).flat() as SubscriptionPackageDto[];
    const sum = packages.reduce(
      (prevValue: number, item) =>
        (prevValue += Number(item.metadata.price.unitAmountDecimal) / 100),
      0,
    );

    return sum;
  }, [subscriptionPackages]);

  const getQuantity = (item: SubscriptionPackageDto) => {
    const packages = Object.values(
      subscriptionPackages,
    ).flat() as SubscriptionPackageDto[];
    const quantity = packages.filter(
      (pack) => pack.metadata.productId === item.metadata.productId,
    ).length;

    return Number(quantity);
  };

  const summary = packagesSum;

  return (
    <PaymentOrderStyled>
      <div>
        <h1>{t('clinicRegistration.payment_process.my_order')}</h1>
        <button type="button" onClick={() => setStep(0)}>
          {t(
            'clinicRegistration.payment_process.change_subscription_selection',
          )}
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(packages || []).map((item: SubscriptionPackageDto) => (
            <tr key={item.metadata.productId}>
              <td>
                <div>
                  <p>{item.name}</p>
                  <span>standard profile</span>
                </div>
              </td>
              <td>{`€${
                Number(item.metadata.price.unitAmountDecimal) / 100
              }/per1`}</td>
              <td>
                <div>
                  <QuantityIcon />
                  <span>{getQuantity(item)}</span>
                </div>
              </td>
              <td>
                €
                {getQuantity(item) *
                  (Number(item.metadata.price.unitAmountDecimal) / 100)}
              </td>
            </tr>
          ))}

          <tr>
            <td colSpan={4}>Sum: €{packagesSum}</td>
          </tr>
        </tbody>
      </table>
      <SummarySectionStyled>
        <PromoCodes />
        <p>
          {t('clinicRegistration.payment_process.discount')}: €
          {promoCode ? summary - Number(promoCode?.newAmount) / 100 : '0'}
        </p>
        {/* TODO */}
        {/* <p>{t('clinicRegistration.payment_process.taxes')}: €120</p> */}
        <h2>
          {t('clinicRegistration.payment_process.total')}: €
          {promoCode ? Number(promoCode?.newAmount) / 100 : summary}
        </h2>
      </SummarySectionStyled>
    </PaymentOrderStyled>
  );
};

export default PaymentOrder;
