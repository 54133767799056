import { DocBayPatientLandingLink } from 'applicaiton';
import { useTranslation } from 'react-i18next';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

export const useMenuItems = () => {
  const { t } = useTranslation();
  const language = getLanguageFromLocalStorage();

  const steps = [
    {
      id: 'home',
      label: t('auth.navbar.home'),
      path: `${DocBayPatientLandingLink}${language}/`,
    },
    // Will be needed in the future
    // {
    //   id: 'about-us',
    //   label: t('auth.navbar.about_us'),
    //   path: '#',
    // },
    // {
    //   id: 'list-of-practices-information',
    //   label: t('auth.navbar.list_of_practices'),
    //   path: '#',
    // },
    // {
    //   id: 'help-information',
    //   label: t('auth.navbar.help'),
    //   path: '#',
    // },
  ];

  return steps;
};
