import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useDebounce } from 'common/hooks/useDebounce';
import { fetchProfessionalsForAutosuggest } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { EmailProps } from './modules';
import {
  IconStyled,
  InfStyled,
  ProfessionalItem,
  ProfessionalsListStyled,
  Wrapper,
} from './styles';
import useOnClickOutside from 'common/hooks/useClickOutside';
import {
  selectExistingProfessional,
  setProfileChanges,
} from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { ProfessionalResponseDto } from '@docbay/schemas';

const Email: FC<EmailProps> = ({
  errorMessage,
  setValue,
  trigger,
  defaultValue,
  reset,
  clearErrors,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { professionalsAutosuggest: professionals } = useAppSelector(
    (state) => state.professionals,
  );
  const { existingProfessional } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );
  const [search, setSearch] = useState(defaultValue);
  const [isActive, setIsActive] = useState(false);
  const searchItem = useDebounce(search, 500);

  const handleChangeEmail = async (value: string) => {
    setSearch(value);
    setValue('email', value);
    if (existingProfessional) {
      dispatch(selectExistingProfessional(null));
      reset();
      dispatch(setProfileChanges(null));
      setValue('email', value);
    }
    await trigger('email');
  };

  const handleSelectProfessional = (
    professional: ProfessionalResponseDto | null,
  ) => {
    clearErrors();
    setSearch(professional?.user?.email || '');
    dispatch(selectExistingProfessional(professional));
  };

  useOnClickOutside(ref, async () => {
    if (isActive) {
      setIsActive(false);

      if (!searchItem) {
        setValue('email', '');
        setValue('firstName', '');
        setValue('lastName', '');
        setValue('medicalNumber', '');
      }
    }
  });

  useEffect(() => {
    if (searchItem.length > 2) {
      dispatch(
        fetchProfessionalsForAutosuggest({
          searchItem,
          invertedChain: true,
        }),
      );
      const existingProfessionalByEmail = professionals?.find(
        (item) => item.user?.email === searchItem,
      );
      if (!existingProfessionalByEmail && existingProfessional) {
        dispatch(setProfileChanges(null));
      }
    }
  }, [searchItem]);

  useEffect(() => {
    if (professionals?.length) {
      const existingProfessionalByEmail = professionals?.find(
        (item) => item.user?.email === searchItem,
      );
      if (existingProfessionalByEmail) {
        clearErrors();
        dispatch(selectExistingProfessional(existingProfessionalByEmail));
      }
      if (!existingProfessionalByEmail) {
        if (existingProfessional) {
          dispatch(selectExistingProfessional(null));

          reset();
          setValue('email', searchItem);
        }
      }
    }
  }, [professionals]);

  const showList = isActive && !!professionals?.length && searchItem;

  return (
    <Wrapper
      ref={ref}
      onClick={(e: any) => {
        if (e.target.id === 'email') {
          setIsActive(true);
        }
      }}
    >
      <Input
        label={t('email') || ''}
        id={'email'}
        type="email"
        errorMessage={errorMessage}
        value={search}
        onChange={(e) => handleChangeEmail(e.target.value)}
        placeholder={t('email_placeholder') || ''}
        autoComplete="off"
      />
      {showList && (
        <ProfessionalsListStyled>
          {professionals.map((professional) => (
            <ProfessionalItem
              key={professional.id}
              onClick={() => {
                handleSelectProfessional(professional);
                setIsActive(false);
              }}
            >
              <IconStyled>
                {professional.mainPhoto ? (
                  <img
                    src={professional.mainPhoto.thumbnailUrl}
                    alt={professional.mainPhoto.key}
                  />
                ) : (
                  <>
                    {professional.firstName?.slice(0, 1).toLocaleUpperCase()}
                    {professional.lastName?.slice(0, 1).toLocaleUpperCase()}
                  </>
                )}
              </IconStyled>
              <InfStyled>
                <h2>{`${professional.firstName} ${professional.lastName}`}</h2>
                <p>{professional.user?.email}</p>
              </InfStyled>
            </ProfessionalItem>
          ))}
        </ProfessionalsListStyled>
      )}
    </Wrapper>
  );
};

export default Email;
