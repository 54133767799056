import React, { useRef, useState } from 'react';

import { socialIcons } from './constants';
import { SocialInputProps } from './models';
import { ContactsType } from 'common/types/clinics';
import useOnClickOutside from 'common/hooks/useClickOutside';

import {
  List,
  Label,
  Error,
  Arrow,
  Wrapper,
  Container,
  InputStyled,
  SocialContainer,
} from './styled';

const SocialInput = ({
  id,
  label,
  disabled,
  socialType,
  errorMessage,
  register,
  onTypeSelect,
  ...rest
}: SocialInputProps) => {
  const ref = useRef(null);

  const [visible, setVisible] = useState(false);

  useOnClickOutside(ref, () => setVisible(false));

  const toggleVisible = () => {
    if (disabled) return;
    setVisible(!visible);
  };

  return (
    <Wrapper>
      {label && (
        <Label errorMessage={errorMessage} htmlFor={id}>
          {label}
        </Label>
      )}

      <Container errorMessage={errorMessage}>
        <SocialContainer ref={ref} disabled={disabled}>
          <div onClick={toggleVisible}>
            {socialIcons[socialType]}
            <Arrow $visible={visible} />
          </div>

          {visible && (
            <List>
              {Object.keys(socialIcons).map((key) => (
                <div
                  key={key}
                  onClick={() => onTypeSelect(key as ContactsType)}
                >
                  {socialIcons[key as ContactsType]}
                </div>
              ))}
            </List>
          )}
        </SocialContainer>

        <InputStyled
          id={id}
          disabled={disabled}
          errorMessage={errorMessage}
          {...register(id)}
          {...rest}
        />
      </Container>

      {errorMessage && <Error>{errorMessage}</Error>}
    </Wrapper>
  );
};

export default SocialInput;
