import { Box, styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: ${commonColors.white};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  width: 100%;
`;

export const NoActiveChatWrapper = styled(Box)`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  svg {
    width: 60px;
    height: 64px;
  }
  .text {
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.05px;
  }
`;
