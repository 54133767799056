import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { ClinicContactUpdateDto, ClinicResponseDto } from '@docbay/schemas';

import {
  createClinicProfile,
  saveClinicProfile,
} from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { PathNames } from 'applicaiton/routes';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { Contacts } from 'common/types/clinics';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Checkbox } from 'common/components';
import { formatProfileContact } from 'features/feature-edit-profile-settings/helpers/number';
import { useContactsSliced } from 'features/feature-edit-profile-settings/hooks/useContactsSliced';
import {
  useContactsScheme,
  useEditContactsScheme,
} from 'features/feature-edit-profile-settings/hooks/useContactsScheme';
import Emails from './Emails';
import Phones from './Phones';
import Social from './Social';
import Website from './WebSite';
import Emergency from './Emergency';
import ButtonSection from '../ButtonSection';
import { ContactsFormValues, ContactsProps } from './models';
import { Divider, DividerBottom, Wrapper } from './styles';
import { setShowSuccessModal } from 'applicaiton/store/reducers/Clinics/ClinicProfileSlice';

const EditContacts: FC<ContactsProps> = ({ handleSetNextPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isCreatePage = location.pathname.endsWith('create');
  const { clinicProfileData } = useAppSelector((state) => state.clinicProfile);
  const { emails, phones, emergency, website, social } = useContactsSliced();
  const defaultValues = { emails, phones, emergency, website, social };
  const [showContactsOnProfile, setShowContactsOnProfile] = useState(
    !!clinicProfileData?.options?.showContactsOnProfile,
  );
  const [contactsToDelete, setContactsToDelete] = useState<string[]>([]);
  const isClinicActive = clinicProfileData?.status === 'Active';

  const editContactsSchema = useEditContactsScheme();
  const contactsSchema = useContactsScheme();

  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm<ContactsFormValues>({
    resolver: yupResolver(isClinicActive ? editContactsSchema : contactsSchema),
    defaultValues,
  });

  const formData = useWatch({ control });

  useEffect(() => {
    reset({
      emails,
      phones,
      emergency,
      website,
      social,
    });
  }, [clinicProfileData]);

  const onSubmit = async (values?: ContactsFormValues) => {
    const array = values
      ? Object.keys(values).reduce((acc, curr) => {
          const arr = values[curr] as ClinicContactUpdateDto[];
          const filtered = arr.filter((item) => item.contactDetail !== '');
          const formatted = filtered.map((item) => formatProfileContact(item));
          return acc.concat(formatted);
        }, [] as ClinicContactUpdateDto[])
      : [];

    const options = {
      showSocialOnProfile: true,
      showContactsOnProfile,
    };
    const emailAdded = values?.emails?.some((item) => !!item.contactDetail);
    const phoneAdded = values?.phones?.some((item) => !!item.contactDetail);

    const canMoveNext = isCreatePage && phoneAdded && emailAdded;

    if (isCreatePage && !clinicProfileData) {
      const response = await dispatch(
        createClinicProfile({
          name: '',
          options,
          ...(array.length ? { contacts: array } : {}),
        }),
      );
      if (response.meta.requestStatus === 'fulfilled' && canMoveNext) {
        handleSetNextPage();
      }
    } else {
      const deletedContacts =
        Object.values(values || {})
          .flat(1)
          .filter((item) => !item.contactDetail && !!item.id)
          .map((item) => item.id) || [];

      const response = await dispatch(
        saveClinicProfile({
          id: clinicProfileData?.id || '0',
          data: {
            options,
            contacts: array,
            ...(contactsToDelete.length || deletedContacts.length
              ? { contactsToDelete: [...contactsToDelete, ...deletedContacts] }
              : {}),
          },
        }),
      );

      if (
        response.meta.requestStatus === 'fulfilled' &&
        phoneAdded &&
        emailAdded
      ) {
        const currentClinic = response.payload as ClinicResponseDto;
        const showSuccessActivated =
          currentClinic.status === 'Active' &&
          !isCreatePage &&
          clinicProfileData?.status !== 'Active';
        if (showSuccessActivated) {
          dispatch(setShowSuccessModal(true));
        }
        setContactsToDelete([]);
        handleSetNextPage();
      }
    }
  };

  const handleCancel = () => {
    navigate(PathNames.myClinics);
  };

  const isStateChanged = useMemo(() => {
    const { emails, phones, emergency, website, social } = getValues();
    const contacts = [
      ...emails,
      ...phones,
      ...emergency,
      ...website,
      ...social,
    ];
    const defaultContacts = [
      ...defaultValues.emails,
      ...defaultValues.phones,
      ...defaultValues.emergency,
      ...defaultValues.website,
      ...defaultValues.social,
    ];

    const defaultData = !clinicProfileData
      ? {
          contacts: defaultContacts,
          options: { showContactsOnProfile: false },
        }
      : {
          contacts: defaultContacts,
          options: {
            showContactsOnProfile:
              clinicProfileData.options?.showContactsOnProfile || false,
          },
        };
    const hasChanges = compareObjectsChanges(
      {
        contacts,
        options: { showContactsOnProfile },
      },
      defaultData,
    );
    const deletedContacts =
      Object.values(getValues() || {})
        .flat(1)
        .filter((item) => !item.contactDetail && !!item.id)
        .map((item) => item.id) || [];

    return hasChanges || !!contactsToDelete.length || !!deletedContacts.length;
  }, [formData, showContactsOnProfile, clinicProfileData]);

  useEffect(() => {
    trigger();
  }, [formData]);

  useEffect(() => {
    clinicProfileData && setValue('website', website as Contacts[]);
  }, [clinicProfileData]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const submitHandler = useMemo(() => {
    return handleSubmit(onSubmit);
  }, [
    isCreatePage,
    contactsToDelete,
    showContactsOnProfile,
    clinicProfileData,
    emails,
    phones,
  ]);

  const handleRemoveContact = (id: string) => {
    setContactsToDelete((prev) => [...prev, id]);
  };

  const hasError = Object.keys(errors).length > 0;

  const isButtonDisabled = isCreatePage ? false : !isStateChanged || hasError;
  const showNextButton = useMemo(() => {
    const emailAdded = formData.emails?.some((item) => !!item.contactDetail);
    const phoneAdded = formData.phones?.some((item) => !!item.contactDetail);

    return isCreatePage && emailAdded && phoneAdded && !hasError;
  }, [isCreatePage, formData, hasError]);

  return (
    <Wrapper>
      <div>
        <Emails
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          handleRemoveContact={handleRemoveContact}
        />
        <Phones
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          handleRemoveContact={handleRemoveContact}
        />
        <Emergency
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          handleRemoveContact={handleRemoveContact}
        />
        <Checkbox
          id="showContactsOnProfile"
          checked={showContactsOnProfile}
          onChange={(value) => setShowContactsOnProfile(value)}
          label={t('edit_contacts.show_contacts_on_profile') as string}
        />
        <Divider />
        <Website
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          handleRemoveContact={handleRemoveContact}
        />
        <Divider />
        <Social
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          handleRemoveContact={handleRemoveContact}
        />
      </div>

      <div>
        <DividerBottom />
        <ButtonSection
          disabled={isButtonDisabled}
          onCancel={handleCancel}
          onSave={submitHandler}
          showNextButton={showNextButton}
        />
      </div>
    </Wrapper>
  );
};

export default EditContacts;
