import styled from 'styled-components';

import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  gap: 12px;
  overflow-y: auto;
`;

export const PronunciationStyled = styled.section`
  display: flex;
  align-items: center;
  width: 100%;

  > p {
    color: ${commonColors.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-right: 4px;
  }

  > div {
    margin-left: 16px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.08px;

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      margin-right: 4px;
    }

    &:first-of-type {
      color: ${commonColors.grey600};
      margin-right: 12px;
      svg {
        path {
          fill: ${commonColors.grey600};
        }
      }
    }

    &:last-of-type {
      color: ${commonColors.blue800};
      svg {
        path {
          fill: ${commonColors.blue800};
        }
      }
    }
  }
`;
