import { createAsyncThunk } from '@reduxjs/toolkit';
import { AbsenceActions } from '../../../constants/actions';
import { AxiosError } from 'axios';
import { AbsenceCreationDto, AbsenceUpdateDto } from '@docbay/schemas';
import { AbsenceApi } from '../../../../integration/api/professionalAbsences';

export const createAbsence = createAsyncThunk(
  AbsenceActions.createAbsence,
  async (data: AbsenceCreationDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await AbsenceApi.createAbsence(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteAbsence = createAsyncThunk(
  AbsenceActions.deleteAbsence,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await AbsenceApi.deleteAbsence(id);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
export const getAbsenceById = createAsyncThunk(
  AbsenceActions.getAbsenceById,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await AbsenceApi.gteAbsenceById(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
export const updateAbsence = createAsyncThunk(
  AbsenceActions.updateAbsence,
  async (data: AbsenceUpdateDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await AbsenceApi.updateAbsence(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
