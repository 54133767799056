import styled from 'styled-components';

export const Wrapper = styled.main`
  flex-grow: 1;
  padding: 32px;
  max-height: 100vh;
  background: ${({ theme }) => theme.color.background};
  overflow-y: auto;
`;

export const EditType = styled.div`
  font-family: AcidGrotesk-Bold;
  font-size: 28px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: capitalize;
  margin-top: 32px;
  margin-bottom: 20px;
`;
