import axiosInstance from 'integration/api/axiosInstance';
import {
  CommonEntitiesListResponse,
  PatientBriefCreationDto,
  PatientResponseDto,
  PatientUpdateDto,
  PatientSubscriptionsProAppResponseDto,
  FamilyMemberResponseDto,
  FamilyMemberUpdateDto,
  FamilyMemberCreationDto,
  PatientSearchResponseDto,
} from '@docbay/schemas';
import { FetchPatientsParams } from 'common/types/patients';
import axiosBlobInstance from './axiosBlobInstance';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

export const PatientsApi = {
  fetchPatients: (params: FetchPatientsParams) => {
    const { search, page, limit, clinicIds } = params;
    return axiosInstance.get<CommonEntitiesListResponse<PatientResponseDto>>(
      '/patients',
      {
        params: {
          ...(search ? { search } : {}),
          ...(clinicIds?.length ? { clinicIds } : {}),
          page,
          limit,
        },
      },
    );
  },
  fetchPatientsAndFamilyMembers: (params: { search: string }) =>
    axiosInstance.get<PatientSearchResponseDto[]>('/patients/search', {
      params,
    }),
  createPatients: (data: PatientBriefCreationDto) =>
    axiosInstance.post<PatientResponseDto>('/patients/brief', data),
  updatePatients: (id: string, data: PatientUpdateDto) =>
    axiosInstance.patch<PatientResponseDto>(`/patients/${id}`, data),
  deletePatient: (id: string) =>
    axiosInstance.delete<PatientResponseDto>(`/patients/${id}`),
  getPatientById: (id: string) =>
    axiosInstance.get<PatientResponseDto>(`/patients/${id}?include=address`),
  getPatientsSubscriptions: (id: string) =>
    axiosInstance.get<
      CommonEntitiesListResponse<PatientSubscriptionsProAppResponseDto>
    >(`/patients/${id}/subscriptions`),
  getFamilyMembers: (patientId: string) =>
    axiosInstance.get<FamilyMemberResponseDto[]>(
      `/pro/family-members/${patientId}`,
    ),
  createFamilyMember: (patientId: string, data: FamilyMemberCreationDto) =>
    axiosInstance.post<PatientResponseDto>(
      `/pro/family-members/${patientId}`,
      data,
    ),
  deleteFamilyMember: (patientId: string, fmId: string) =>
    axiosInstance.delete(`/pro/family-members/${fmId}/${patientId}`),
  updateFamilyMember: (
    patientId: string,
    memberId: string,
    data: FamilyMemberUpdateDto,
  ) =>
    axiosInstance.patch<FamilyMemberResponseDto>(
      `/pro/family-members/${memberId}/${patientId}`,
      data,
    ),
  downloadCSVTemplate: () => {
    const locale = getLanguageFromLocalStorage();
    return axiosBlobInstance.get('/patients-management/templates/export', {
      params: { locale },
    });
  },
  uploadCSV: (data: FormData) =>
    axiosInstance.post('/patients-management/import', data),
};
