import { SearchProfessionalsByFiltersResponseDto } from '@docbay/schemas';
import {
  AgendaView,
  CalendarView,
  ViewBy,
} from 'applicaiton/constants/scheduler';
import { Dayjs } from 'dayjs';

enum SessionStorage {
  schedulerFilters = 'schedulerFilters',
}

interface SchedulerData {
  calendarView?: CalendarView;
  selectedDate?: string;
  clinicId?: string;
  selectedProfessionals?: SearchProfessionalsByFiltersResponseDto[];
  agendaProfessionals?: SearchProfessionalsByFiltersResponseDto[];
  daysCount?: number;
  slotsTimeView?: string;
  agendaView?: AgendaView;
}

interface AgendaFiltersSessionStorage {
  viewBy?: ViewBy;
  timeView?: string;
  showNearest?: boolean;
  selectedSpecializations?: string[];
  selectedDays?: Dayjs[];
  byProfessionals?: SchedulerData;
  byAvailability?: SchedulerData;
}

export const agendaFiltersSessionStorage = (): AgendaFiltersSessionStorage => {
  const data: AgendaFiltersSessionStorage = JSON.parse(
    sessionStorage.getItem(SessionStorage.schedulerFilters) || '{}',
  );

  return {
    viewBy: data?.viewBy,
    timeView: data?.timeView,
    showNearest: data?.showNearest,
    selectedSpecializations: data?.selectedSpecializations,
    selectedDays: data?.selectedDays,
    byProfessionals: {
      calendarView: data?.byProfessionals?.calendarView,
      clinicId: data?.byProfessionals?.clinicId,
      selectedDate: data?.byProfessionals?.selectedDate,
      selectedProfessionals: data?.byProfessionals?.selectedProfessionals,
      agendaProfessionals: data?.byProfessionals?.agendaProfessionals,
      daysCount: data?.byProfessionals?.daysCount,
      slotsTimeView: data?.byProfessionals?.slotsTimeView,
      agendaView: data?.byProfessionals?.agendaView,
    },
    byAvailability: {
      calendarView: data?.byAvailability?.calendarView,
      clinicId: data?.byAvailability?.clinicId,
      selectedDate: data?.byAvailability?.selectedDate,
      selectedProfessionals: data?.byAvailability?.selectedProfessionals,
      agendaProfessionals: data?.byAvailability?.agendaProfessionals,
      daysCount: data?.byAvailability?.daysCount,
      slotsTimeView: data?.byAvailability?.slotsTimeView,
      agendaView: data?.byAvailability?.agendaView,
    },
  };
};

export const setAgendaFiltersSessionStorage = (
  value: AgendaFiltersSessionStorage,
) => {
  sessionStorage.setItem(
    SessionStorage.schedulerFilters,
    JSON.stringify({
      ...agendaFiltersSessionStorage(),
      ...value,
    }),
  );
};

export const resetAgendaFiltersSessionStorage = () => {
  sessionStorage.removeItem(SessionStorage.schedulerFilters);
};
