import styled from 'styled-components';

export const MainStyled = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100%;
`;
