import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InfoSectionStyled } from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { getSubscriptions } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { PathNames } from 'applicaiton/routes';

const InfoSection: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { subscriptions } = useAppSelector((state) => state.clinicOwnerSlice);
  const { deletedSuccess } = useAppSelector((state) => state.professionals);
  const userRole = getUserRole();
  const isClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const isProfessional = userRole === UserRoles.professional;

  const subscriptionsUsed = subscriptions
    ? subscriptions.totalUserSlots - subscriptions.remainingUserSlots
    : 0;

  useEffect(() => {
    if (isProfessional) return;
    dispatch(getSubscriptions());
  }, [deletedSuccess]);

  return (
    <InfoSectionStyled>
      <h1>{t('professionalsListConfiguration.my_professionals')}</h1>
      {!isProfessional && (
        <div>
          <p>{`${t('created')} ${subscriptionsUsed} ${t('from')} ${
            subscriptions?.totalUserSlots || 0
          } ${t('available')}`}</p>
          {isClinicOwner && (
            <Link to={PathNames.clinicBilling}>
              {t('adminsListConfiguration.go_to_subscription')}
            </Link>
          )}
        </div>
      )}
    </InfoSectionStyled>
  );
};

export default InfoSection;
