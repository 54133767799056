import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  ModalComponent,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import { ReactComponent as UnsuccessIcon } from 'applicaiton/assets/unsuccess-icon.svg';
import {
  CheckboxStyled,
  HeaderWrapper,
  ModalContentWrapper,
  Title,
} from './styles';
import { setIsBackModalShowSessionStorage } from 'applicaiton/sessionStorage/clinicRegistration';
import { useAppDispatch } from 'common/hooks/redux';
import {
  clearClinicOwnerSuccessSignUp,
  setClinicRegistrationError,
} from 'applicaiton/store/reducers/ClinicRegistration/ClinicRegistrationSlice';

interface BackModalProps {
  open: boolean;
  onClose: () => void;
  backToStep: () => void;
}

const BackModal: FC<BackModalProps> = ({ open, onClose, backToStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [dontShowModal, setDontShowModal] = useState(false);

  const handleDontShowModal = () => {
    if (dontShowModal) {
      setIsBackModalShowSessionStorage();
    }
  };

  const handleBack = () => {
    handleDontShowModal();
    onClose();
    backToStep();
    dispatch(setClinicRegistrationError(''));
    dispatch(clearClinicOwnerSuccessSignUp());
  };

  return (
    <ModalComponent
      isOpen={open}
      handleClose={onClose}
      width="500px"
      padding="32px"
    >
      <ModalContentWrapper>
        <HeaderWrapper>
          <UnsuccessIcon />
          <Title>
            {t('clinicRegistration.header.back_modal.confirm_title')}
          </Title>
        </HeaderWrapper>
        <section>
          <SecondaryButton onClick={handleBack}>
            {t('clinicRegistration.back')}
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              handleDontShowModal();
              onClose();
            }}
          >
            {t('clinicRegistration.stay_here')}
          </PrimaryButton>
        </section>
        <CheckboxStyled>
          <Checkbox
            checked={dontShowModal}
            id={'dontShowModal'}
            onChange={(checked) => setDontShowModal(checked)}
            label={t('clinicRegistration.dont_show_again') || ''}
          />
        </CheckboxStyled>
      </ModalContentWrapper>
    </ModalComponent>
  );
};

export default BackModal;
