import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import LeavePageModal from '../LeavePageModal';
import { SideBarProps } from './modules';
import { AsideStyled, StyledItem } from './styles';

const SideBar: FC<SideBarProps> = ({ selectedPage, setSelectedPage }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { hasUnsavedChanges } = useAppSelector((state) => state.savedChanges);
  const [showModal, setShowModal] = useState(false);
  const [nextPageIndex, setNextPageIndex] = useState<number | null>(null);

  const handleChangePage = (index: number) => {
    if (selectedPage === index) return;
    if (hasUnsavedChanges) {
      setShowModal(true);
      setNextPageIndex(index);
    } else {
      setSelectedPage(index);
      setNextPageIndex(null);
    }
  };

  const handleSubmit = () => {
    setSelectedPage(Number(nextPageIndex));
    setShowModal(false);
    dispatch(setHasUnsavedChanges(false));
  };

  return (
    <AsideStyled>
      <LeavePageModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleSubmit}
      />
      <StyledItem
        isSelected={selectedPage === 0}
        onClick={() => handleChangePage(0)}
      >
        {t('general_info')}
      </StyledItem>
      <StyledItem
        isSelected={selectedPage === 1}
        onClick={() => handleChangePage(1)}
      >
        {t('phone')}
      </StyledItem>
      <StyledItem
        isSelected={selectedPage === 2}
        onClick={() => handleChangePage(2)}
      >
        {t('email')}
      </StyledItem>
      <StyledItem
        isSelected={selectedPage === 3}
        onClick={() => handleChangePage(3)}
      >
        {t('password')}
      </StyledItem>
    </AsideStyled>
  );
};

export default SideBar;
