import { styled } from '@mui/system';
import { Menu, MenuItem } from '@mui/material';
import { commonColors } from 'applicaiton/theme';

export const MenuStyled = styled(Menu)`
  .MuiPaper-root {
    border-radius: 8px;
    border: 1px solid ${commonColors.grey300};
    background: ${commonColors.white};
    box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
    display: flex;
    padding: 0 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`;
export const MenuItemStyled = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px;
  color: ${commonColors.grey800};
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;

  a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: ${commonColors.grey800};
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    text-decoration: none;
  }

  svg {
    path {
      fill: ${commonColors.grey700};
    }
  }
`;
