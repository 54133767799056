import React, { FC } from 'react';

import { AuthPageWrapper } from '../AuthPageLayout';
import { FinishRegistration } from 'features';

export const Registration: FC = () => {
  return (
    <AuthPageWrapper>
      <FinishRegistration />
    </AuthPageWrapper>
  );
};
