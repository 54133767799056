import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export interface EmailT {
  email: string;
}

export const useEmailForm = ({ requiredError }: { requiredError: string }) => {
  const formEmailValues = {
    email: '',
  };

  const EmailSchema = yup
    .object({
      email: yup.string().email(requiredError).required(requiredError),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<EmailT>({
    resolver: yupResolver(EmailSchema),
    defaultValues: formEmailValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    trigger,
  };
};
