import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import {
  ButtonsWrapper,
  StyledModal,
  OptionCheckBox,
  ClinicsWrapper,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchClinics } from 'applicaiton/store/reducers/Clinics/ActionCreators';
import Checkbox from 'common/components/Checkbox';
import { restoreClinicOwnerProfessional } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import {
  setIsExistDeletedDoctor,
  setShowClinicListModal,
} from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { PathNames } from 'applicaiton/routes';
import { useNavigate } from 'react-router-dom';

interface ClinicListModalProps {
  open: boolean;
  onClose: () => void;
}

const ClinicListModal: FC<ClinicListModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [checkedClinics, setCheckedClinics] = useState<string[]>([]);
  const { clinics } = useAppSelector((state) => state.clinics);
  const { isExistDeletedDoctor } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );

  useEffect(() => {
    fetchClinics();
  }, []);

  const handleChange = (id: string) => {
    if (checkedClinics.includes(id)) {
      setCheckedClinics(checkedClinics.filter((clinic) => clinic !== id));
    } else {
      setCheckedClinics([...checkedClinics, id]);
    }
  };

  const IsValueChecked = (id: string) => {
    return checkedClinics.includes(id);
  };

  const handleRestore = () => {
    dispatch(
      restoreClinicOwnerProfessional({
        email: isExistDeletedDoctor || '',
        clinicIds: checkedClinics,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        dispatch(setIsExistDeletedDoctor(null));
        dispatch(setShowClinicListModal(false));
        navigate(PathNames.professionals);
      }
    });
  };

  return (
    <Modal open={open}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <ClinicsWrapper>
          <p>{t('professional_profile.choose_clinic')}</p>
          <>
            {clinics.map((item) => {
              return (
                <OptionCheckBox
                  htmlFor={String(item.name)}
                  key={item.id}
                  selected={IsValueChecked(item.id!)}
                  isMulti={true}
                >
                  <div>
                    <p>{item.name}</p>
                  </div>
                  <Checkbox
                    id={String(item.name)}
                    checked={IsValueChecked(item.id)}
                    onChange={() => handleChange(item.id)}
                  />
                </OptionCheckBox>
              );
            })}
          </>
        </ClinicsWrapper>
        <ButtonsWrapper>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton
            disabled={!checkedClinics.length}
            onClick={handleRestore}
          >
            {t('professional_profile.restore')}
          </PrimaryButton>
        </ButtonsWrapper>
      </StyledModal>
    </Modal>
  );
};

export default ClinicListModal;
