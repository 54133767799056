import { useEffect, useState } from 'react';
import { AudioTrackProps } from 'features/feature-teleconsultation/types';
import { useAppSelector } from 'common/hooks/redux';

const useTrackAudio = ({ audioTrack }: AudioTrackProps) => {
  const { isAudioOn } = useAppSelector((state) => state.teleconsultation);
  const [audioOn, setAudioOn] = useState(isAudioOn);

  useEffect(() => {
    if (audioTrack) {
      setAudioOn(audioTrack.isEnabled);

      const handleAudioDisabled = () => {
        setAudioOn(false);
      };

      const handleAudioEnabled = () => {
        setAudioOn(true);
      };

      audioTrack.on('disabled', handleAudioDisabled);
      audioTrack.on('enabled', handleAudioEnabled);

      return () => {
        audioTrack.off('disabled', handleAudioDisabled);
        audioTrack.off('enabled', handleAudioEnabled);
      };
    }
  }, [audioTrack]);

  useEffect(() => {
    if (audioTrack?.id) {
      !audioOn ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack, audioOn]);

  return { audioOn };
};

export default useTrackAudio;
