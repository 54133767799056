import styled from 'styled-components';

export const AccordionStyled = styled.div`
  grid-template-columns: auto auto auto;
  cursor: pointer;
  height: fit-content;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.grey50};

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .accordion-title {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.color.grey600};
      font-size: 16px;
      font-weight: 700;
      line-height: 140%;
    }
    .accordion-expand {
      display: flex;
      align-items: center;
      > svg {
        path: {
          fill: ${({ theme }) => theme.color.grey500};
        }
      }
    }
  }
  .accordion-list {
    display: grid;
    margin-top: 10px;
  }
`;
