import { createSlice } from '@reduxjs/toolkit';
import {
  getTopics,
  createTopic,
  deleteTopic,
  editTopic,
} from './ActionCreators';
import { InitialState } from './models';

const initialState: InitialState = {
  isLoading: false,
  topics: [],
  topicsChanges: null,
  error: '',
  total: 0,
  page: 0,
  totalPages: 0,
  isAdded: false,
  isUpdated: false,
  isDeleted: false,
};

const topicsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    resetActionsTopic: (state) => {
      state.isAdded = false;
      state.isUpdated = false;
      state.topicsChanges = null;
    },
    editedTopic: (state, action) => {
      state.topicsChanges = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get all topics
    builder.addCase(getTopics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTopics.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getTopics.fulfilled, (state, action) => {
      state.topics = action.payload.entities;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    });
    // create topic
    builder.addCase(createTopic.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createTopic.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createTopic.fulfilled, (state) => {
      state.isAdded = true;
      state.isLoading = false;
    });
    // edit topic
    builder.addCase(editTopic.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editTopic.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(editTopic.fulfilled, (state) => {
      state.isUpdated = true;
      state.isLoading = false;
    });
    // delete topic
    builder.addCase(deleteTopic.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteTopic.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteTopic.fulfilled, (state) => {
      state.isDeleted = true;
      state.isLoading = false;
    });
  },
});

export const { resetActionsTopic, editedTopic } = topicsSlice.actions;

export default topicsSlice.reducer;
