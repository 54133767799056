import React, { useEffect } from 'react';
import { useChatsClient } from './hooks/useChatsClient';
import { useChats } from './hooks/useChats';
import { useDynamicChats } from './hooks/useDynamicChats';
import { useAppSelector } from '../../common/hooks/redux';
import { MainLayoutWrapper, Wrapper } from './styles';
import { Header, Loader } from '../../common/components';
import { ActiveChat, ChatsSideBar } from './components';
import { ActiveMassMessageChat } from './components/ActiveMassMessageChat';

export const Chats = () => {
  const { clientIsInitialized, currentClient } = useChatsClient();
  const { fetchChats, isLoading, chatsAll } = useChats();
  const { initDynamicChat, initDynamicChatListeners } = useDynamicChats();
  const { massMessagesPatientsIds } = useAppSelector((state) => state.chats);

  useEffect(() => {
    if (clientIsInitialized && chatsAll?.length > 0) {
      initDynamicChat(chatsAll);
    }
  }, [clientIsInitialized, chatsAll]);

  useEffect(() => {
    if (currentClient) {
      fetchChats().then(() => initDynamicChatListeners());
    }
  }, [currentClient]);

  return (
    <Wrapper>
      <Header />
      {clientIsInitialized && (
        <MainLayoutWrapper>
          <ChatsSideBar />
          {massMessagesPatientsIds ? <ActiveMassMessageChat /> : <ActiveChat />}
        </MainLayoutWrapper>
      )}
      {isLoading && <Loader />}
    </Wrapper>
  );
};
