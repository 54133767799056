import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';

interface DeleteModalProps {
  isOpen: boolean;
  topicName: string;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteModal: FC<DeleteModalProps> = ({
  isOpen,
  topicName,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <h1>
          {t('topics_of_chats.delete_topic.title')} <span>{topicName}</span>?
        </h1>
        <section>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton onClick={onSubmit} styleType={'error'}>
            {t('topics_of_chats.delete_topic.confirm')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default DeleteModal;
