import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Header } from 'common/components';

import { SettingsTable } from './components';
import { Wrapper } from './styles';

const NotificationsSettings: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header />
      <h1>{t('clinicsConfiguration.aside_menu.notifications_settings')}</h1>
      <SettingsTable />
    </Wrapper>
  );
};

export default NotificationsSettings;
