import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { StyledTr } from './styles';

const NoDataElement: FC = () => {
  const { t } = useTranslation();
  return (
    <StyledTr>
      <td colSpan={5}>
        <div>
          <PickIcon />
          <p>
            {t('professionalsListConfiguration.no_data_element.title')}
            <br />
            {t(
              'professionalsListConfiguration.no_data_element.subtitlePart1',
            )}{' '}
            <Link to={PathNames.addProfessional}>
              {t(
                'professionalsListConfiguration.no_data_element.subtitlePart2',
              )}
            </Link>{' '}
            {t('professionalsListConfiguration.no_data_element.subtitlePart3')}
          </p>
        </div>
      </td>
    </StyledTr>
  );
};
export default NoDataElement;
