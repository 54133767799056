import styled from 'styled-components';

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  > a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.08px;

    > svg {
      margin-right: 4px;
    }

    &:last-child {
      color: ${({ theme }) => theme.color.blue800};
    }
  }
`;
