import { useTranslation } from 'react-i18next';
import { SettingsTypes } from '../constants';

export const useSettingsConfiguration = () => {
  const { t } = useTranslation();

  const configurations = [
    {
      key: SettingsTypes.OneDayReminder,
      name: t('notifications_settings.types.one_day_reminder'),
    },
    {
      key: SettingsTypes.TwoHourReminder,
      name: t('notifications_settings.types.two_hours_reminder'),
    },
    {
      key: SettingsTypes.SevenDayReminder,
      name: t('notifications_settings.types.seven_day_reminder'),
    },
    {
      key: SettingsTypes.AppointmentCancellation,
      name: t('notifications_settings.types.appointment_cancellation'),
    },
    {
      key: SettingsTypes.AppointmentRescheduled,
      name: t('notifications_settings.types.appointment_rescheduled'),
    },
    {
      key: SettingsTypes.WaitingList,
      name: t('notifications_settings.types.waiting_list'),
    },
    {
      key: SettingsTypes.DocumentAddedToAppointment,
      name: t('notifications_settings.types.document_added_to_appointment'),
    },
    {
      key: SettingsTypes.NotificationOfMedicalResults,
      name: t('notifications_settings.types.notification_of_medical_results'),
    },
    {
      key: SettingsTypes.BookingConfirmation,
      name: t('notifications_settings.types.booking_confirmation'),
    },
  ];

  return configurations;
};
