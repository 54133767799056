import { Box, styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

export const ModalStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 32px;
  gap: 32px;
  border-radius: 8px;
  background: ${commonColors.success4};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

  p {
    color: ${commonColors.grey700};
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
  }
  .actions-wrapper {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    > button {
      width: 120px;
    }
  }
`;
