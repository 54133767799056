import { User } from '@twilio/conversations';
import { useEffect, useState } from 'react';
import { getCurrentProfileCognitoUuid } from 'applicaiton/sessionStorage/auth';
import { useConversation } from './useConversation';
import { useChatsClient } from './useChatsClient';

export const useChatParticipants = (
  conversationSid: string,
  selectedChat: any,
) => {
  const { currentClient } = useChatsClient();
  const { conversation } = useConversation();
  const currentProfileCognitoUuid = getCurrentProfileCognitoUuid();
  const [oppositeUser, setOppositeUser] = useState<User | null | undefined>(
    null,
  );
  useEffect(() => {
    getOppositeUser().then(setOppositeUser);
  }, [conversation, selectedChat]);

  const getOppositeUser = async (): Promise<User | null | undefined> => {
    try {
      const conversation = await currentClient?.getConversationBySid(
        conversationSid,
      );
      if (!conversation) {
        return null;
      }
      const participants = await conversation.getParticipants();
      const participant =
        participants.find(
          (participant) => participant.identity !== currentProfileCognitoUuid,
        ) || null;
      return participant ? participant.getUser() : null;
    } catch (e) {
      console.log('Error with twilio conversation SID');
    }
  };

  return {
    oppositeUser,
  };
};
