import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTr, ActionButton } from './styles';
import { NotificationItemProps } from './models';

const NotificationItem: FC<NotificationItemProps> = ({
  type,
  medium,
  handleEditNotification,
  isCustomNotification,
}) => {
  const { t } = useTranslation();

  const format = isCustomNotification
    ? t('appointment_types.notification_table.custom')
    : t('appointment_types.notification_table.by_default');

  return (
    <StyledTr>
      <td>{type}</td>
      <td>{medium}</td>
      <td>{format}</td>
      <td>
        <ActionButton
          type="button"
          onClick={() => handleEditNotification(type)}
        >
          {t('appointment_types.notification_table.add_specific_info')}
        </ActionButton>
      </td>
    </StyledTr>
  );
};

export default NotificationItem;
