import { createAsyncThunk } from '@reduxjs/toolkit';
import { PatientsActions } from '../../../constants/actions';
import { AxiosError } from 'axios';
import { PatientsApi } from 'integration/api/patients';
import {
  FamilyMemberCreationDto,
  FamilyMemberUpdateDto,
  PatientBriefCreationDto,
} from '@docbay/schemas';
import { PatientsUpdateProps } from './models';
import { FetchPatientsParams } from 'common/types/patients';

export const fetchPatients = createAsyncThunk(
  PatientsActions.fetchPatients,
  async (params: FetchPatientsParams, { rejectWithValue }) => {
    try {
      const { data: responseData } = await PatientsApi.fetchPatients(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchPatientsAndFamilyMembers = createAsyncThunk(
  PatientsActions.fetchPatientsAndFamilyMembers,
  async (params: { search: string }, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await PatientsApi.fetchPatientsAndFamilyMembers(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createPatient = createAsyncThunk(
  PatientsActions.createPatient,
  async (params: PatientBriefCreationDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await PatientsApi.createPatients(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updatePatient = createAsyncThunk(
  PatientsActions.updatePatients,
  async ({ id, data }: PatientsUpdateProps, { rejectWithValue }) => {
    try {
      const { data: responseData } = await PatientsApi.updatePatients(id, data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deletePatient = createAsyncThunk(
  PatientsActions.deletePatient,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await PatientsApi.deletePatient(id);
      fetchPatients({ page: 1, limit: 10 });
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getPatientById = createAsyncThunk(
  `patients/${PatientsActions.getPatientById}`,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await PatientsApi.getPatientById(id);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      const rejectPayload = {
        statusCode: error?.response?.status,
        message: error.message,
      };
      return rejectWithValue(rejectPayload);
    }
  },
);

export const getPatientsSubscriptions = createAsyncThunk(
  `patients/${PatientsActions.getPatientsSubscriptions}`,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await PatientsApi.getPatientsSubscriptions(
        id,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getFamilyMembers = createAsyncThunk(
  `patients/${PatientsActions.getFamilyMembers}`,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await PatientsApi.getFamilyMembers(id);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createFamilyMember = createAsyncThunk(
  `patients/${PatientsActions.createFamilyMember}`,
  async (
    {
      patientId,
      data,
    }: {
      patientId: string;
      data: FamilyMemberCreationDto;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await PatientsApi.createFamilyMember(
        patientId,
        data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteFamilyMember = createAsyncThunk(
  `patients/${PatientsActions.deleteFamilyMember}`,
  async (
    { patientId, fmId }: { patientId: string; fmId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await PatientsApi.deleteFamilyMember(
        patientId,
        fmId,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateFamilyMember = createAsyncThunk(
  `patients/${PatientsActions.updateFamilyMember}`,
  async (
    {
      patientId,
      data,
      memberId,
    }: { patientId: string; memberId: string; data: FamilyMemberUpdateDto },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await PatientsApi.updateFamilyMember(
        patientId,
        memberId,
        data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const uploadCSV = createAsyncThunk(
  PatientsActions.uploadCSV,
  async (data: FormData, { rejectWithValue }) => {
    try {
      const { data: responseData } = await PatientsApi.uploadCSV(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
