import React, { FC, useEffect, useState } from 'react';
import { FormProps } from './types';
import { useNavigate } from 'react-router-dom';
import {
  DropdownClinics,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import {
  Wrapper,
  ButtonsWrapper,
  StyledDivider,
  SelectSection,
  SelectedClinicsCounts,
  SelectedClinics,
  SelectedClinicsItem,
  ClinicImage,
  ClinicInfo,
  StyledCloseIcon,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useClinicsForm } from '../../hooks/useClinicsForm';
import SuccessModal from '../SuccessModal';
import { PathNames } from 'applicaiton/routes';
import {
  resetAdminData,
  setAdminData,
} from 'applicaiton/store/reducers/Admins/AdminsSlice';
import { Option } from 'common/components/DropdownClinics/models';
import { ReactComponent as HomeIcon } from 'applicaiton/assets/home.svg';
import { createAdmin } from 'applicaiton/store/reducers/Admins/ActionCreators';
import { uploadedPhoto } from 'applicaiton/store/reducers/Photo/PhotoSlice';

const Assigning: FC<FormProps> = ({ onBackBtnClick }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addedClinics, setAddedClinics] = useState<Option[]>([]);

  const { errors, handleSubmit, setValue } = useClinicsForm({
    requiredError: t('errors.required'),
  });
  const { clinics } = useAppSelector((state) => state.clinics);

  const { profileChanges, isAdded } = useAppSelector((state) => state.admins);

  useEffect(() => {
    if (profileChanges) {
      const selectedClinics = clinics.filter((item) => {
        const findClinic = profileChanges?.clinicIds?.find(
          (id) => id === item.id,
        );
        if (findClinic) return true;
        return false;
      });

      const filteredClinics = selectedClinics.map((clinic) => ({
        label: clinic.name!,
        value: clinic.id!,
        image: clinic.mainPhoto?.photoUrl,
        address: clinic.address,
      }));

      setAddedClinics(filteredClinics);
      setValue('clinic', filteredClinics);
    }
  }, [profileChanges]);

  const handleBackBtn = () => {
    const ids: string[] = addedClinics.map((a) => a.value as string);

    dispatch(
      setAdminData({
        ...profileChanges!,
        clinicIds: ids,
      }),
    );
    onBackBtnClick();
  };

  const onSubmit = () => {
    const ids: string[] = addedClinics.map((a) => a.value as string);

    dispatch(
      createAdmin({
        firstName: profileChanges?.firstName!,
        lastName: profileChanges?.lastName!,
        email: profileChanges?.email!,
        phone: profileChanges?.phone!,
        clinicIds: ids,
        photo: profileChanges?.photo!,
      }),
    );
  };

  const handleRemoveClinic = (id: string) => {
    const filteredClinics = addedClinics.filter((item) => item.value !== id);

    setAddedClinics(filteredClinics);
    setValue('clinic', filteredClinics);
  };

  const closeSuccessModal = () => {
    dispatch(resetAdminData());
    navigate(PathNames.myUsers);
    dispatch(uploadedPhoto([]));
  };

  return (
    <Wrapper>
      <SelectSection>
        <DropdownClinics
          value={addedClinics}
          label={t('users.select_a_clinic')!}
          placeholder={t('professional_profile.select_clinic')!}
          onChange={(value) => {
            const clinicsArr: Option[] = value as Option[];
            setAddedClinics(clinicsArr);
            setValue('clinic', clinicsArr, { shouldValidate: true });
          }}
          isMulti={true}
          isError={!!errors.clinic}
          errorMessage={errors.clinic && t('errors.required')!}
          filterByFirstCOId
        />
      </SelectSection>
      {!!addedClinics.length && (
        <>
          <SelectedClinicsCounts>
            {`${addedClinics.length} ${t('selected')}`}
          </SelectedClinicsCounts>
          <SelectedClinics>
            {addedClinics.map((item) => {
              return (
                <SelectedClinicsItem key={item.value}>
                  <ClinicImage>
                    {item.image ? (
                      <img src={item.image} alt="image" />
                    ) : (
                      <HomeIcon />
                    )}
                  </ClinicImage>
                  <ClinicInfo>
                    <div>{item.label}</div>
                    <div>
                      {item.address?.streetAddress}, {item.address?.city} ,
                      {item.address?.country}
                    </div>
                  </ClinicInfo>
                  <StyledCloseIcon
                    onClick={() => {
                      handleRemoveClinic(String(item.value));
                    }}
                  />
                </SelectedClinicsItem>
              );
            })}
          </SelectedClinics>
        </>
      )}

      <StyledDivider />
      <ButtonsWrapper>
        <SecondaryButton onClick={handleBackBtn}>{t('back')}</SecondaryButton>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={!!Object.keys(errors).length}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonsWrapper>

      {isAdded && (
        <SuccessModal
          typeName={`${profileChanges?.firstName} ${profileChanges?.lastName}`}
          onClose={closeSuccessModal}
        />
      )}
    </Wrapper>
  );
};

export default Assigning;
