import React, { FC } from 'react';
import {
  DividerStyled,
  LanguageSwitcherStyled,
  LanguageWrapper,
} from './styles';
import { ReactComponent as LanguageIcon } from 'applicaiton/assets/language.svg';
import { useLanguage } from 'common/hooks/useLanguage';

const LanguageSwitcher: FC = () => {
  const { language, switchLanguage } = useLanguage();

  return (
    <LanguageWrapper>
      <LanguageIcon />
      <LanguageSwitcherStyled
        onClick={() => switchLanguage('en')}
        selected={language === 'en'}
      >
        EN
      </LanguageSwitcherStyled>
      <DividerStyled />
      <LanguageSwitcherStyled
        onClick={() => switchLanguage('pt')}
        selected={language === 'pt'}
      >
        PT
      </LanguageSwitcherStyled>
      <DividerStyled />
      <LanguageSwitcherStyled
        onClick={() => switchLanguage('fr')}
        selected={language === 'fr'}
      >
        FR
      </LanguageSwitcherStyled>
    </LanguageWrapper>
  );
};

export default LanguageSwitcher;
