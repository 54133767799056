import React from 'react';
import { ActiveChatMenuProps } from './module';
import { MenuItemStyled, MenuStyled } from './styles';
import { MassMembersIcon } from 'applicaiton/assets';
import { useTranslation } from 'react-i18next';

export const ActiveMassMessageMenu = ({
  anchorEl,
  onClose,
  showPatientsList,
}: ActiveChatMenuProps) => {
  const { t } = useTranslation();
  const menuIsOpen = !!anchorEl;

  const handleShowPatients = () => {
    onClose();
    showPatientsList();
  };

  return (
    <>
      <MenuStyled
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuIsOpen}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItemStyled onClick={handleShowPatients}>
          <MassMembersIcon />
          {t('chats.view_mass_message_members')}
        </MenuItemStyled>
        {/*TODO: maybe needed later*/}
        {/*<MenuItemStyled onClick={() => setDeleteChatModalIsOpen(true)}>*/}
        {/*  <DeleteIcon />*/}
        {/*  {t('chats.delete_mass_message')}*/}
        {/*</MenuItemStyled>*/}
      </MenuStyled>
    </>
  );
};
