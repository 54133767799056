import { useTranslation } from 'react-i18next';

import { AppointmentStatus } from 'common/types/appointmentStatus';

export const useAppointmentStatus = () => {
  const { t } = useTranslation();

  return [
    {
      value: AppointmentStatus.UPCOMING,
      label: t('appointment.upcoming'),
    },
    {
      value: AppointmentStatus.IN_CONSULTATION,
      label: t('appointment.in_consultation'),
    },
    {
      value: AppointmentStatus.WAITING_ROOM,
      label: t('appointment.waiting_room'),
    },
    {
      value: AppointmentStatus.JUSTIFIED_ABSENCE,
      label: t('appointment.justified_absence'),
    },
    {
      value: AppointmentStatus.UNJUSTIFIED_ABSENCE,
      label: t('appointment.unjustified_absence'),
    },
    { value: AppointmentStatus.SEEN, label: t('appointment.seen') },
  ];
};
