import React, { FC, useEffect, useState } from 'react';
import {
  Wrapper,
  StyledSection,
  StepSection,
  StepItem,
  StepText,
  StepSectionWrapper,
} from './styles';

import GeneralTypeInfo from '../GeneralTypeInfo';
import { useTranslation } from 'react-i18next';
import Assigning from '../Assigning';
import { fetchAppointmentTypeById } from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import SuccessModal from '../SuccessModal';
import { resetUpdatedState } from 'applicaiton/store/reducers/AppointmentTypes/AppointmentTypesSlice';
import LeavePageModal from 'features/feature-edit-profile-settings/component/LeavePageModal';
import Messages from '../Messages';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';

const LayoutComponent: FC = () => {
  const [activeForm, setActiveForm] = useState<number>(0);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { hasUnsavedChanges } = useAppSelector((state) => state.savedChanges);
  const [showModal, setShowModal] = useState(false);
  const [nextPageIndex, setNextPageIndex] = useState<number | null>(null);
  const canUserManageAppointmentTypes = userHasPermission(
    UserPermissions.clinicCommunicationWithPatients,
  );

  const firstStep = activeForm === 0;
  const secondStep = activeForm === 1;
  const thirdStep = activeForm === 2;

  const handleChangePage = (index: number) => {
    if (hasUnsavedChanges) {
      setNextPageIndex(index!);
      setShowModal(true);
    } else {
      setActiveForm(index);
    }
  };

  const handleSubmit = () => {
    setActiveForm(nextPageIndex!);
    setShowModal(false);
  };

  const { currentAppointmentTypeChanges, isUpdated } = useAppSelector(
    (state) => state.appointmentTypes,
  );

  useEffect(() => {
    id && dispatch(fetchAppointmentTypeById(id));
  }, [id]);

  const getCurrentForm = () => {
    switch (activeForm) {
      case 0:
        return <GeneralTypeInfo />;
      case 1:
        return <Assigning />;
      case 2:
        return <Messages />;
    }
  };

  const closeSuccessModal = () => {
    dispatch(resetUpdatedState());
  };

  return (
    <Wrapper>
      <LeavePageModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleSubmit}
      />
      <StyledSection>
        <StepSectionWrapper isMessagesPage={thirdStep}>
          <StepSection>
            <StepItem onClick={() => handleChangePage(0)}>
              <StepText isActive={firstStep}>
                {t('appointment_types.general_info')}
              </StepText>
            </StepItem>
            <StepItem onClick={() => handleChangePage(1)}>
              <StepText isActive={secondStep}>
                {t('appointment_types.assigning')}
              </StepText>
            </StepItem>
            {canUserManageAppointmentTypes && (
              <StepItem onClick={() => handleChangePage(2)}>
                <StepText isActive={thirdStep}>
                  {t('appointment_types.messages')}
                </StepText>
              </StepItem>
            )}
          </StepSection>
        </StepSectionWrapper>
        {getCurrentForm()}
      </StyledSection>
      {isUpdated && (
        <SuccessModal
          typeName={currentAppointmentTypeChanges?.title || ''}
          onClose={closeSuccessModal}
        />
      )}
    </Wrapper>
  );
};

export default LayoutComponent;
