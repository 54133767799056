import React, { FC, useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import {
  resetCurrentAppointment,
  resetSlotsDetails,
  setCurrentFamilyMemberSearch,
  setCurrentPatient,
  setIsCreatedAppointment,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { resetAbsence } from 'applicaiton/store/reducers/Absence/AbsenceSlice';
import {
  setConfused,
  setCreatedAbsence,
} from 'applicaiton/store/reducers/ConfusingAgenda/ConfusingAgendaSlice';
import {
  resetDocumentsErrors,
  setAppointmentDocuments,
} from 'applicaiton/store/reducers/AppointmentDocuments/AppoinmentsDocumentsSlice';
import {
  resetOpenAgenda,
  setEditedOpenAgenda,
} from 'applicaiton/store/reducers/Agenda/AgendaSlice';

import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import {
  SuccessAppointmentCreated,
  SuccessAppointmentEditing,
  SuccessWithConfuseModal,
  WarningModal,
  SuccessModal,
} from './components';
import {
  Header,
  AppointmentForm,
  OpenAgendaForm,
  AbsenceForm,
  AppointmentDetails,
} from './containers';
import { ModalStyled, Container } from './styles';

const ReserveSlotModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mode, isCreatedAppointment, isEdited, currentAppointment } =
    useAppSelector((state) => state.appointmentsSlice);
  const { confusingAgendas } = useAppSelector(
    (state) => state.confusingAgendasSlice,
  );

  const [isContinueSettings, setIsContinueSettings] = useState<boolean>(false);
  const [isConfused, setIsConfused] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const appointmentID = searchParams.get('appointment');

  const isCreateAppointment = mode === ReserveSlotsMode.CreateAppointment;
  const isEditAppointment = mode === ReserveSlotsMode.EditAppointment;
  const isBookAgain = mode === ReserveSlotsMode.BookAgain;
  const isPreviewAppointment = mode === ReserveSlotsMode.PreviewAppointment;

  const isAppointmentForm = useMemo(() => {
    return isCreateAppointment || isEditAppointment || isBookAgain;
  }, [isCreateAppointment, isEditAppointment, isBookAgain]);

  const hasConfusingAgendas = useMemo(() => {
    const confusingEvents = [
      ...(confusingAgendas?.absences || []),
      ...(confusingAgendas?.appointments?.filter(
        (appointment) => appointment.id !== currentAppointment?.id,
      ) || []),
    ];
    return !!confusingEvents.length;
  }, [confusingAgendas]);

  const isOpenAgendaForm = useMemo(() => {
    const isOpenAgenda = mode === ReserveSlotsMode.OpenAgenda;

    return isOpenAgenda;
  }, [mode]);

  const isAbsenceForm = useMemo(() => {
    const isCreateAbsence = mode === ReserveSlotsMode.CreateAbsence;
    const isEditAbsence = mode === ReserveSlotsMode.EditAbsence;

    return isCreateAbsence || isEditAbsence;
  }, [mode]);

  const handleSave = (success: boolean) => {
    success ? setIsSuccess(true) : setIsContinueSettings(true);
  };

  const onCloseModal = () => {
    dispatch(resetSlotsDetails());
    dispatch(resetAbsence());
    dispatch(setIsCreatedAppointment(false));
    dispatch(setAppointmentDocuments([]));
    dispatch(resetDocumentsErrors());
    dispatch(resetCurrentAppointment());
    dispatch(setConfused(null));
    dispatch(setCurrentPatient(null));
    dispatch(setCurrentFamilyMemberSearch(null));
    dispatch(resetOpenAgenda());
    dispatch(setEditedOpenAgenda(null));
    appointmentID && setSearchParams('');
  };

  const handleCloseSuccessModal = () => {
    setIsSuccess(false);
    onCloseModal();
  };

  const handleContinueSettings = () => {
    setIsContinueSettings(true);
    setIsConfused(false);
    onCloseModal();
  };

  const handleSuccessConfuseModal = () => {
    setIsContinueSettings(false);
    onCloseModal();
    dispatch(setCreatedAbsence(null));
  };

  const modalTitle = useMemo(() => {
    if (isCreateAppointment || isEditAppointment || isBookAgain) {
      return t('absenceModal.appointmentCreatedText');
    } else {
      return t('absenceModal.createdText');
    }
  }, [isCreateAppointment, isEditAppointment, isBookAgain, mode]);

  return (
    <Modal open={!!mode}>
      <ModalStyled>
        <Container>
          <Header onClose={onCloseModal} />
          {isPreviewAppointment && (
            <AppointmentDetails onClose={onCloseModal} />
          )}
          {isAppointmentForm && (
            <AppointmentForm
              setIsContinueSettings={setIsContinueSettings}
              onClose={onCloseModal}
            />
          )}
          {isAbsenceForm && (
            <AbsenceForm
              onSaveWithConfuse={() => setIsContinueSettings(true)}
              onClose={onCloseModal}
              onSave={handleSave}
            />
          )}
          {isOpenAgendaForm && <OpenAgendaForm onClose={onCloseModal} />}
        </Container>

        {!hasConfusingAgendas && isSuccess && (
          <SuccessModal opened={isSuccess} onClose={handleCloseSuccessModal} />
        )}
        {isConfused && <WarningModal onClose={handleContinueSettings} />}
        {isContinueSettings && (
          <SuccessWithConfuseModal
            modalTitle={modalTitle}
            isAppointmentType={
              isEditAppointment || isCreateAppointment || isBookAgain
            }
            onCloseModal={handleSuccessConfuseModal}
          />
        )}
        {isCreatedAppointment && !isConfused && !isContinueSettings && (
          <SuccessAppointmentCreated onClose={onCloseModal} />
        )}
        {isEdited && !isConfused && !isContinueSettings && (
          <SuccessAppointmentEditing onClose={onCloseModal} />
        )}
      </ModalStyled>
    </Modal>
  );
};

export default ReserveSlotModal;
