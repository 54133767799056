import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ClinicsState } from './models';
import {
  deleteProfile,
  fetchClinics,
  getSpecializationsByClinic,
  searchClinicsInChain,
} from './ActionCreators';
import { ClinicResponseDto } from '@docbay/schemas';

const initialState: ClinicsState = {
  clinics: [],
  clinicsByOwnerId: [],
  total: 0,
  page: 0,
  totalPages: 0,
  isLoading: false,
  error: '',
  currentProfile: null,
  deletedSuccess: false,
  specializations: [],
};

const clinicsSlice = createSlice({
  name: 'clinics',
  initialState,
  reducers: {
    selectProfile: (
      state,
      actions: PayloadAction<ClinicResponseDto | null>,
    ) => {
      state.currentProfile = actions.payload;
      state.deletedSuccess = false;
    },
  },
  extraReducers: (builder) => {
    //clinic fetch
    builder.addCase(fetchClinics.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchClinics.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchClinics.fulfilled, (state, action) => {
      state.clinics = action.payload.entities;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    });
    //clinic delete
    builder.addCase(deleteProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteProfile.fulfilled, (state) => {
      state.deletedSuccess = true;
      state.isLoading = false;
    });
    //searchClinicsInChain
    builder.addCase(searchClinicsInChain.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchClinicsInChain.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(searchClinicsInChain.fulfilled, (state, action) => {
      state.clinicsByOwnerId = action.payload.entities;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    });
    //get specializations by Clinic
    builder.addCase(getSpecializationsByClinic.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSpecializationsByClinic.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getSpecializationsByClinic.fulfilled, (state, action) => {
      state.specializations = action.payload;
      state.isLoading = false;
    });
  },
});

export const { selectProfile } = clinicsSlice.actions;

export default clinicsSlice.reducer;
