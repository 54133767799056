import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, PrimaryButton, PhotoUploader } from 'common/components';
import {
  Wrapper,
  ButtonsWrapper,
  FieldsSection,
  StyledDivider,
} from './styles';
import { useOwnerInfoForm } from '../../hooks/useOwnerInfoForm';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { uploadedPhoto } from 'applicaiton/store/reducers/Photo/PhotoSlice';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import SuccessModal from '../SuccessModal';
import { updateClinicOwner } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { resetClinicOwnerData } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';

const ProfileInfo: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { register, errors, handleSubmit, watch, setValue, getValues } =
    useOwnerInfoForm({
      requiredError: t('errors.required'),
    });

  const { isLoading, currentClinicOwner, isUpdated } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );

  const { uploadedPhotos } = useAppSelector((state) => state.photos);

  useEffect(() => {
    if (currentClinicOwner) {
      setValue('firstName', currentClinicOwner.firstName);
      setValue('lastName', currentClinicOwner.lastName);
      setValue('jobPosition', currentClinicOwner.jobPosition || '');

      if (currentClinicOwner?.photo) {
        const photo = {
          key: currentClinicOwner?.photo.key,
          photoUrl: currentClinicOwner.photo.photoUrl,
          thumbnailUrl: currentClinicOwner.photo.thumbnailUrl,
        };
        uploadedPhoto([photo]);
      }
    }
  }, [currentClinicOwner]);

  const onSubmit = async () => {
    if (currentClinicOwner) {
      dispatch(
        updateClinicOwner({
          id: String(currentClinicOwner.id),
          data: {
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
            jobPosition: getValues('jobPosition'),
            ...(uploadedPhotos.length ? { photo: uploadedPhotos[0] } : {}),
          },
        }),
      ).then(() => {
        dispatch(uploadedPhoto([]));
      });
    }
  };

  const closeSuccessModal = () => {
    dispatch(resetClinicOwnerData());
  };

  const isStateChanged = useMemo(() => {
    const { firstName, lastName, jobPosition } = getValues();
    const hasChanges = compareObjectsChanges(
      {
        firstName,
        lastName,
        ...(jobPosition ? { jobPosition } : {}),
      },
      {
        firstName: currentClinicOwner?.firstName,
        lastName: currentClinicOwner?.lastName,
        ...(currentClinicOwner?.jobPosition
          ? { jobPosition: currentClinicOwner?.jobPosition }
          : {}),
      },
    );

    return hasChanges || !!uploadedPhotos.length;
  }, [
    currentClinicOwner,
    watch('firstName'),
    watch('lastName'),
    watch('jobPosition'),
    uploadedPhotos,
  ]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  return (
    <Wrapper>
      <FieldsSection>
        <Input
          label={t('personal_information.first_name') || ''}
          id={'firstName'}
          type="text"
          register={register}
          errorMessage={errors?.firstName?.message}
          placeholder={t('patient.new_patient_name') || ''}
        />
        <Input
          label={t('personal_information.last_name') || ''}
          id={'lastName'}
          type="text"
          register={register}
          errorMessage={errors?.lastName?.message}
          placeholder={t('patient.new_patient_lastname') || ''}
        />
        <Input
          label={t('personal_information.job_position') || ''}
          id={'jobPosition'}
          type="text"
          register={register}
          placeholder={t('personal_information.job_position_placeholder') || ''}
        />
        <PhotoUploader defaultPhoto={currentClinicOwner?.photo} />
      </FieldsSection>
      <StyledDivider />
      <ButtonsWrapper>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={!isStateChanged || isLoading}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonsWrapper>
      {isUpdated && (
        <SuccessModal
          name={`${currentClinicOwner?.firstName} ${currentClinicOwner?.lastName}`}
          onClose={closeSuccessModal}
        />
      )}
    </Wrapper>
  );
};

export default ProfileInfo;
