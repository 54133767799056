import styled from 'styled-components';

export const MapBoxStyled = styled.div`
  position: relative;
  > section {
    position: absolute;
    max-height: 242px;
    overflow-y: auto;
    z-index: 10;
    width: 100%;
    display: grid;
    padding: 12px;
    gap: 6px;
    border-radius: 8px;
    background: ${({ theme }) => theme.color.white};
    box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);
  }
`;

export const LocationItem = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }

  > svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    g {
      path {
        fill: ${({ theme }) => theme.color.grey500};
      }
    }
  }

  > div {
    > h2 {
      color: ${({ theme }) => theme.color.grey800};
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.04px;
    }

    > p {
      color: ${({ theme }) => theme.color.grey600};
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.035px;
    }
  }
`;
