import { useAppSelector } from 'common/hooks/redux';
import { ProfessionalChatResponseDto } from '@docbay/schemas';
import { RootState, store } from 'applicaiton/store';
import { useChatsClient } from './useChatsClient';
import { handleParallelPromises } from 'common/helpers/promiseHelper';
import { useMemo } from 'react';
import { Conversation, Message } from '@twilio/conversations';
import { useChatsContext } from './useChatsContext';
import {
  ChatsType,
  ChatWithExtraInfo,
} from 'applicaiton/store/reducers/Chats/models';
import { ChatsAPI } from 'integration/api/chats';
import { ChatsMode } from 'applicaiton/constants/chats';
import { useChats } from './useChats';
import { getCurrentProfileCognitoUuid } from 'applicaiton/sessionStorage/auth';
import { setChatsReadMessagesInfo } from 'applicaiton/store/reducers/Chats/ChatsSlice';

export const useDynamicChats = () => {
  const currentProfileCognitoUuid = getCurrentProfileCognitoUuid();
  const { getChatExtraInfo, addMessageListener, addConversationListener } =
    useChatsClient();
  const { dynamicChats, setDynamicChats, chatSearch, selectedTopicsIds } =
    useChatsContext();
  const { setSelectedChat, updateSelectedChat, deselectChatIfActive } =
    useChats();
  const { selectedChatsType, mode } = useAppSelector(
    (state: RootState) => state.chats,
  );

  const runUpdateProcess = async (allChats: ProfessionalChatResponseDto[]) => {
    const updatedData: any[] = [];
    const promisesAndActions = allChats.map((chat) => ({
      promise: getChatExtraInfo(chat.twilioConversationSID),
      action: (result: any) => {
        updatedData.push({
          ...chat,
          ...result,
        });
      },
    }));
    const resultCallback = () => setDynamicChats(updatedData);
    handleParallelPromises(promisesAndActions, resultCallback);
  };

  const initDynamicChat = (allChats: ProfessionalChatResponseDto[]) => {
    setDynamicChats(allChats);
    runUpdateProcess(allChats);
  };

  const updateChatWithLastMessageData = async (message: Message) => {
    const chatState = store.getState().chats;
    const isCurrentConversation =
      chatState.selectedChat?.twilioConversationSID ===
      message.conversation.sid;
    const isMessageAuthor = message.author === currentProfileCognitoUuid;
    const isMessageRead = isMessageAuthor || isCurrentConversation;

    const readMessageChat = chatState.chatsReadMessagesInfo?.find(
      (item) => item.sid === message.conversation.sid,
    );
    if (readMessageChat) {
      const updatedChatsReadMessages = chatState.chatsReadMessagesInfo?.map(
        (item) => {
          if (item.sid === message.conversation.sid && !isMessageAuthor) {
            return {
              ...item,
              lastReadMessageIndex: message.conversation.lastReadMessageIndex,
            };
          }
          return item;
        },
      );
      store.dispatch(setChatsReadMessagesInfo(updatedChatsReadMessages));
    } else {
      store.dispatch(
        setChatsReadMessagesInfo([
          ...chatState.chatsReadMessagesInfo,
          {
            sid: message.conversation.sid,
            lastReadMessageIndex: isMessageAuthor
              ? null
              : message.conversation.lastReadMessageIndex,
          },
        ]),
      );
    }

    isMessageAuthor &&
      (await message.conversation?.updateLastReadMessageIndex(message.index));
    const unreadMessagesCount = isMessageRead
      ? 0
      : await message.conversation?.getUnreadMessagesCount();

    const updateData = {
      twilioConversationSID: message.conversation.sid,
      lastMessageText: message.body,
      lastMessageTime: message.dateUpdated || message.dateCreated,
      unreadMessagesCount,
    };
    setDynamicChats((prevChats) =>
      prevChats.map((chat) =>
        chat.twilioConversationSID === message.conversation.sid
          ? {
              ...chat,
              ...updateData,
            }
          : chat,
      ),
    );
  };

  const runAsyncUpdateTopicProcess = async (conversationSid: string) => {
    let response = undefined;
    try {
      response = await ChatsAPI.getChatBySID(conversationSid);
    } catch (e) {
      console.error(
        `Error while fetching  the chat data by sid: ${conversationSid}`,
        e,
      );
      return;
    }
    const { data } = response;
    updateSelectedChat(data);
    setDynamicChats((prevChats) =>
      prevChats.map((chat) =>
        chat.twilioConversationSID === conversationSid
          ? {
              ...chat,
              ...data,
            }
          : chat,
      ),
    );
  };

  const handleTopicUpdate = (conversation: Conversation) => {
    const attributes = conversation.attributes as any;
    const topicId = attributes.topicId;

    setDynamicChats((prevChats) => {
      const conversationToUpdate = prevChats.find(
        (chat) => chat.twilioConversationSID === conversation.sid,
      );
      if (
        conversationToUpdate &&
        conversationToUpdate?.clinicProfessionalTopic?.id !== topicId
      ) {
        runAsyncUpdateTopicProcess(conversation.sid);
      }
      return prevChats;
    });
  };

  const updateChatWithConversationData = ({
    conversation,
    updateReasons,
  }: {
    conversation: Conversation;
    updateReasons: [string];
  }) => {
    if (updateReasons.includes('lastMessage')) {
      return;
    }
    const attributes = conversation.attributes as any;
    const updateData = {
      twilioConversationSID: conversation.sid,
      emergency: attributes.emergency,
      teleconsultation: attributes.teleconsultation,
      archived: attributes.archived,
    };
    handleTopicUpdate(conversation);
    setDynamicChats((prevChats) =>
      prevChats.map((chat) =>
        chat.twilioConversationSID === conversation.sid
          ? {
              ...chat,
              ...updateData,
            }
          : chat,
      ),
    );
  };

  const handleRemoveChat = (conversationSID: string) => {
    deselectChatIfActive(conversationSID);
    setDynamicChats((prevChats) =>
      prevChats.filter(
        (chat) => chat.twilioConversationSID !== conversationSID,
      ),
    );
  };

  const setNewChatAsActive = (newChat: ProfessionalChatResponseDto) => {
    if (newChat.createdByUserType !== 'Patient') {
      setSelectedChat(newChat);
    }
  };

  const fetchNewChatAndAddToDynamicChats = async (chatSID: string) => {
    let response = undefined;
    try {
      response = await ChatsAPI.getChatBySID(chatSID);
    } catch (e) {
      console.error(
        `Error while fetching  the chat data by sid: ${chatSID}`,
        e,
      );
      return;
    }
    const newChat = response.data as ProfessionalChatResponseDto;
    setNewChatAsActive(newChat);
    setDynamicChats((prevChats) => {
      const isExist = prevChats.some(
        (chat) => newChat.twilioConversationSID === chat.twilioConversationSID,
      );
      return isExist ? prevChats : [...prevChats, newChat];
    });
  };

  const handleAddNewChat = (chatSID: string) => {
    setDynamicChats((prevChats) => {
      const isExist = prevChats.some(
        (chat) => chatSID === chat.twilioConversationSID,
      );
      if (!isExist) {
        fetchNewChatAndAddToDynamicChats(chatSID);
      }
      return prevChats;
    });
  };

  const initDynamicChatListeners = () => {
    addMessageListener('messageAdded', (message) => {
      updateChatWithLastMessageData(message);
    });
    addMessageListener('messageRemoved', (message) => {
      updateChatWithConversationData({
        conversation: message.conversation,
        updateReasons: ['messageRemoved'],
      });
    });
    addConversationListener('conversationUpdated', (updateEvent) => {
      updateChatWithConversationData(updateEvent);
    });
    addConversationListener('conversationRemoved', (conversation) => {
      handleRemoveChat(conversation.sid);
    });
    addConversationListener('conversationAdded', (conversation) => {
      handleAddNewChat(conversation.sid);
    });
  };

  const filterChatsByType = (chats: ChatWithExtraInfo[], type: string) => {
    switch (type) {
      case 'ALL':
        return chats;
      case 'UNREAD':
        return chats.filter((chat) => chat.unreadMessagesCount);
      case 'EMERGENCY':
        return chats.filter((chat) => chat.emergency);
      case 'TELECONSULTATION':
        return chats.filter((chat) => chat.teleconsultation);
      default:
        return chats;
    }
  };

  const getFullName = (chat: ChatWithExtraInfo) => {
    const firstName = chat.patient?.firstName || '';
    const lastName = chat.patient?.lastName || '';
    return `${firstName} ${lastName}`.toLowerCase();
  };

  const filterChatsByPatientName = (
    chats: ChatWithExtraInfo[],
    name: string,
  ) => {
    return name
      ? chats.filter((chat) => getFullName(chat).includes(name.toLowerCase()))
      : chats;
  };

  const filterChatsByTopics = (
    chats: ChatWithExtraInfo[],
    topicIds: string[],
  ) => {
    return topicIds.length > 0
      ? chats.filter((chat) => {
          const topicId = chat.clinicProfessionalTopic?.id || '';
          return topicIds.includes(topicId);
        })
      : chats;
  };

  const filteredDynamicChats = useMemo(() => {
    const chatState = store.getState().chats;
    const updatedChats = dynamicChats.map((item) => {
      const isSelectedChat =
        chatState.selectedChat?.twilioConversationSID ===
        item.twilioConversationSID;
      if (isSelectedChat) {
        return { ...item, unreadMessagesCount: 0 };
      } else {
        return item;
      }
    });
    const archivedChats = updatedChats.filter((chat) => {
      return chat.archived;
    });

    const chats = updatedChats.filter((chat) => {
      return !chat.archived;
    });

    const filterByType = filterChatsByType(
      mode === ChatsMode.Chats ? chats : archivedChats,
      mode === ChatsMode.Chats ? selectedChatsType : ChatsType.ALL,
    );
    const filteredByPatientName = filterChatsByPatientName(
      filterByType,
      chatSearch,
    );
    return filterChatsByTopics(filteredByPatientName, selectedTopicsIds);
  }, [dynamicChats, selectedChatsType, chatSearch, selectedTopicsIds, mode]);

  return {
    dynamicChats,
    initDynamicChat,
    filteredDynamicChats,
    initDynamicChatListeners,
  };
};
