import React, { FC, useEffect } from 'react';

import { fetchAppointmentById } from 'applicaiton/store/reducers/Appointments/ActionCreators';

import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader } from 'common/components';

import {
  PatientInfo,
  AppointmentClinicDetails,
  AppointmentActions,
} from 'features/feature-agenda-reserve-slots-modal/components';

import { AppointmentDetailsProps } from './models';
import { Wrapper, PatientInfoStyled, ClinicInfoStyled } from './styles';

const AppointmentDetails: FC<AppointmentDetailsProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const { isLoading: isLoadingAppointment, appointmentId } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const { isLoading: isLoadingAppointmentDocument } = useAppSelector(
    (state) => state.appointmentDocumentsSlice,
  );

  const isLoading = isLoadingAppointment || isLoadingAppointmentDocument;

  useEffect(() => {
    if (appointmentId) {
      dispatch(fetchAppointmentById(String(appointmentId)));
    }
  }, [appointmentId]);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <PatientInfoStyled>
        <PatientInfo />
      </PatientInfoStyled>
      <ClinicInfoStyled>
        <AppointmentClinicDetails onCloseModal={onClose} />
        {!isLoading && <></>}
      </ClinicInfoStyled>
      <AppointmentActions />
    </Wrapper>
  );
};

export default AppointmentDetails;
