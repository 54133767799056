import { commonColors } from 'applicaiton/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const PatientsList = styled.div`
  position: absolute;
  width: 100%;
  top: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: max-content;
  gap: 4px;
  height: fit-content;
  max-height: 300px;
  overflow-y: auto;

  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);
`;

export const PatientItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;

  cursor: pointer;
  &:hover {
    background-color: ${commonColors.grey50};
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 8px;

    path {
      fill: ${commonColors.grey500};
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 32px);

    h2 {
      color: ${commonColors.grey800};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.08px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    p {
      color: ${commonColors.grey600};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.6px;
      letter-spacing: 0.035px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const EmptyResults = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  padding: 12px;

  > svg {
    height: 40%;
  }

  > p {
    color: ${commonColors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-left: 12px;
  }
`;
