import React, { FC } from 'react';
import { Wrapper } from './styles';
import LayoutComponent from './components/LayoutComponent';
import Header from './components/Header';

const ClinicBilling: FC = () => {
  return (
    <Wrapper>
      <Header />
      <LayoutComponent />
    </Wrapper>
  );
};

export default ClinicBilling;
