import styled from 'styled-components';

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    width: 220px;

    &:first-child {
      width: inherit;
    }
    &:last-child {
      width: 150px;
    }
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > button {
    display: flex;
    padding: 0;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }

    &:first-child {
      svg {
        path {
          fill: ${({ theme }) => theme.color.blue700};
        }
      }
    }
  }
`;
