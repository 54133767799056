import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as ChatIcon } from 'applicaiton/assets/chat.svg';
import { ReactComponent as DocumentsIcon } from 'applicaiton/assets/documents.svg';
import { ReactComponent as InfoIcon } from 'applicaiton/assets/teleconsultation-info.svg';
import { PreviewModal } from 'features/feature-teleconsultation/constants';
import { MoreModalProps } from './modules';
import { Header, Wrapper, ActionsList, ButtonStyled } from './styles';

const MoreModal: FC<MoreModalProps> = ({
  isEventStarted,
  onClose,
  handleSetPreviewModal,
  previewModal,
}) => {
  const { t } = useTranslation();

  const isChatOpened = previewModal === PreviewModal.Chat;
  const isDocumentsOpened = previewModal === PreviewModal.Documents;
  const isInfoOpened = previewModal === PreviewModal.Information;

  return (
    <Wrapper>
      <Header>
        <button type="button" onClick={onClose}>
          <CloseIcon />
          {t('close')}
        </button>
        <p>{t('more')}</p>
      </Header>
      <ActionsList>
        {isEventStarted && (
          <ButtonStyled
            isActive={isInfoOpened}
            type="button"
            onClick={() => handleSetPreviewModal(PreviewModal.Information)}
          >
            <span />
            <ChatIcon />
            {t('teleconsultation.patient_information')}
          </ButtonStyled>
        )}
        <ButtonStyled
          isActive={isDocumentsOpened}
          type="button"
          onClick={() => handleSetPreviewModal(PreviewModal.Documents)}
        >
          <DocumentsIcon />
          {t('teleconsultation.documents')}
        </ButtonStyled>
        <ButtonStyled
          isActive={isChatOpened}
          type="button"
          onClick={() => handleSetPreviewModal(PreviewModal.Chat)}
        >
          <InfoIcon />
          {t('teleconsultation.chat')}
        </ButtonStyled>
      </ActionsList>
    </Wrapper>
  );
};

export default MoreModal;
