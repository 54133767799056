import React, { FC } from 'react';
import { useAppDispatch } from 'common/hooks/redux';
import Switch from 'common/components/Switch';
import { PermissionItemProps } from './modules';
import { StyledTr } from './styles';
import {
  getPermissionsByClinicOwnerId,
  updatePermissionsByClinicOwnerId,
} from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { getUserId } from 'applicaiton/sessionStorage/auth';
import {
  Permissions,
  UserType,
} from 'features/feature-user-permissions/constants';

const PermissionItem: FC<PermissionItemProps> = ({
  permissionKey,
  name,
  canBeChangedForAdmin,
  enabledForAdmin,
  canBeChangedForProfessional,
  enabledForProfessional,
  canBeChangedForSecretary,
  enabledForSecretary,
}) => {
  const userId = getUserId();
  const dispatch = useAppDispatch();

  const handleEditPermission = async (
    userType: UserType,
    permission: Permissions,
    value: boolean,
  ) => {
    await dispatch(
      updatePermissionsByClinicOwnerId({
        id: String(userId),
        permission: {
          userType: userType,
          permission: permission as any,
          enabled: value,
        },
      }),
    );
    await dispatch(getPermissionsByClinicOwnerId(String(userId)));
  };

  return (
    <StyledTr>
      <td>
        <p>{name}</p>
      </td>
      <td>
        <Switch
          checked={enabledForAdmin}
          disabled={!canBeChangedForAdmin}
          onChange={(value) => {
            handleEditPermission(UserType.Admin, permissionKey, value);
          }}
        />
      </td>
      <td>
        <Switch
          checked={enabledForProfessional}
          disabled={!canBeChangedForProfessional}
          onChange={(value) => {
            handleEditPermission(UserType.Professional, permissionKey, value);
          }}
        />
      </td>
      <td>
        <Switch
          checked={enabledForSecretary}
          disabled={!canBeChangedForSecretary}
          onChange={(value) => {
            handleEditPermission(UserType.Secretary, permissionKey, value);
          }}
        />
      </td>
    </StyledTr>
  );
};
export default PermissionItem;
