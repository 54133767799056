import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownPackage, DropdownClinics } from 'common/components';
import { FilterSectionProps } from './modules';
import { ControllerSection, FilterSectionStyled } from './styles';
import { Option } from 'common/components/Dropdown/models';

const FilterSection: FC<FilterSectionProps> = ({
  clinics,
  setClinic,
  setSubscriptionsId,
  subscriptionsId,
  subscriptionsOptions,
}) => {
  const { t } = useTranslation();

  return (
    <FilterSectionStyled>
      <ControllerSection>
        <DropdownClinics
          isAllDataReturn
          value={clinics!}
          onChange={(value) => {
            setClinic(value as Option[]);
          }}
          isMulti={true}
          hideByClinicCount={1}
        />

        <DropdownPackage
          id={'subscriptions'}
          value={subscriptionsId}
          placeholder={t(
            'subscriptions_statistics.subscription_package_placeholder',
          )}
          onChange={(value) => {
            setSubscriptionsId(value as string[]);
          }}
          options={subscriptionsOptions || []}
          withSearch={false}
          isMulti={true}
          disabled={!subscriptionsOptions?.length}
        />
      </ControllerSection>
    </FilterSectionStyled>
  );
};

export default FilterSection;
