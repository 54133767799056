import { createSlice } from '@reduxjs/toolkit';
import {
  usersVerifyCode,
  usersCreateNewPassword,
  usersForgotPassword,
  usersLogin,
  usersRefreshToken,
  userChangePassword,
  signOut,
  checkUsersEmailExisting,
  setUserLanguage,
} from './ActionCreators';
import {
  getProfilesFromSessionStorage,
  setProfilesInSessionStorage,
  setUsersAuthSessionStorage,
  setUsersRefreshTokenSessionStorage,
} from 'applicaiton/sessionStorage/auth';
import { authService } from 'common';
import { AxiosError } from 'axios';
import { ErrorDto } from 'common/types/error';

const initialState = {
  isLoading: false,
  error: '',
  isAuth: false,
  isSuccessResetPassword: false,
  isValidCode: false,
  isCreateNewPassword: false,
  isPasswordChanged: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setAuthError: (state, action) => {
      state.error = action.payload;
    },
    setIsSuccessResetPassword: (state, actions) => {
      state.isSuccessResetPassword = actions.payload!;
    },
    setIsValidCode: (state, actions) => {
      state.isValidCode = actions.payload!;
    },
    setIsCreateNewPassword: (state, actions) => {
      state.isCreateNewPassword = actions.payload!;
    },
    setIsPasswordChanged: (state, actions) => {
      state.isPasswordChanged = false;
    },
    clearStore: () => {
      authService.cleanStorage();
    },
    setIsAuth: (state) => {
      state.isAuth = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(usersLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(usersLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuth = false;
      state.error = action.payload as string;
    });
    builder.addCase(usersLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuth = true;

      const { token, refreshToken, user } = action.payload;

      setUsersAuthSessionStorage(action.payload);
      setProfilesInSessionStorage([
        {
          id: user?.id!,
          email: user?.email!,
          firstName: user?.firstName!,
          lastName: user?.lastName!,
          photo: user?.photo!,
          token,
          refreshToken,
        },
      ]);
    });
    // refresh token
    builder.addCase(usersRefreshToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(usersRefreshToken.rejected, (state, action) => {
      state.isLoading = false;
      state.isAuth = false;
      state.error = action.payload as string;
      authService.cleanStorage();
      clearStore();
    });
    builder.addCase(usersRefreshToken.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAuth = true;

      setUsersRefreshTokenSessionStorage(action.payload);

      const profiles = getProfilesFromSessionStorage();
      const filteredProfiles = profiles?.map((item) => {
        return { ...item, token: action.payload.token };
      });
      setProfilesInSessionStorage(filteredProfiles || []);
    });
    // forgot password
    builder.addCase(usersForgotPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(usersForgotPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccessResetPassword = false;
      const error = action.payload as AxiosError<ErrorDto>;
      state.error = error.response?.data.errorCode || '';
    });
    builder.addCase(usersForgotPassword.fulfilled, (state) => {
      state.isLoading = false;
      state.isSuccessResetPassword = true;
    });

    // verify code
    builder.addCase(usersVerifyCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(usersVerifyCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(usersVerifyCode.fulfilled, (state) => {
      state.isLoading = false;
    });

    // create new password
    builder.addCase(usersCreateNewPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(usersCreateNewPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.isCreateNewPassword = false;
      state.error = action.payload as string;
    });
    builder.addCase(usersCreateNewPassword.fulfilled, (state) => {
      state.isLoading = false;
      state.isCreateNewPassword = true;
    });

    // change password
    builder.addCase(userChangePassword.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(userChangePassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(userChangePassword.fulfilled, (state) => {
      state.isLoading = false;
      state.error = '';
      state.isPasswordChanged = true;
    });
    // user sign out
    builder.addCase(signOut.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signOut.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuth = false;
    });
    //check professional email existing
    builder.addCase(checkUsersEmailExisting.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkUsersEmailExisting.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(checkUsersEmailExisting.fulfilled, (state) => {
      state.isLoading = false;
    });
    //set user language
    builder.addCase(setUserLanguage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setUserLanguage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(setUserLanguage.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setAuthError,
  setIsSuccessResetPassword,
  setIsValidCode,
  setIsCreateNewPassword,
  setIsPasswordChanged,
  clearStore,
  setIsAuth,
} = usersSlice.actions;

export default usersSlice.reducer;
