import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { SecondaryButton } from 'common/components';
import { PathNames } from 'applicaiton/routes';
import { setShowSuccessModal } from 'applicaiton/store/reducers/Clinics/ClinicProfileSlice';
import { ReactComponent as SuccessIcon } from 'applicaiton/assets/success-icon.svg';
import { StyledModal, SuccessCreated } from './styles';

const ClinicSuccessCreatedModal: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showSuccessModal } = useAppSelector((state) => state.clinicProfile);
  const isMyClinicsPage = pathname === PathNames.myClinics;
  const isMyClinicsEditPage = pathname.startsWith(PathNames.editClinic);

  const handleSubmit = () => {
    dispatch(setShowSuccessModal(false));
    if (!isMyClinicsEditPage && !isMyClinicsPage) {
      navigate(PathNames.myClinics);
    }
  };

  return (
    <Modal open={showSuccessModal}>
      <StyledModal>
        <div>
          <SuccessIcon />
          {isMyClinicsEditPage ? (
            <p>
              {t('clinicsConfiguration.success_modal.successfully_activated')}
            </p>
          ) : (
            <SuccessCreated>
              <p>
                {t(
                  'clinicsConfiguration.success_modal.successfully_created_text_1',
                )}
              </p>
              <p>
                {t(
                  'clinicsConfiguration.success_modal.successfully_created_text_2',
                )}
              </p>
            </SuccessCreated>
          )}

          <SecondaryButton onClick={handleSubmit}>{t('ok')}</SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default ClinicSuccessCreatedModal;
