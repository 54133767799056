import { Box } from '@mui/system';
import styled from 'styled-components';

export const NavBarStyled = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: ${({ theme }) => theme.color.menu};
  padding: 0 32px;
  font-family: 'Mulish', sans-serif;
`;
