import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import {
  addMessage,
  removeMessage,
  setClient,
  setConversation,
  setMessages,
} from 'applicaiton/store/reducers/Teleconsultation/TeleconsultationSlice';
import {
  Client,
  ConnectionState,
  Message as MessageType,
} from '@twilio/conversations';
import { useEffect, useState } from 'react';

export const useTeleconsultationChat = () => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<ConnectionState>('disconnected');
  const { room, messages, client, conversation } = useAppSelector(
    (state) => state.teleconsultation,
  );

  const initConversation = async () => {
    if (!client || !room) return;
    try {
      const conversation = await client.getConversationByUniqueName(room);
      dispatch(setConversation(conversation));
      await conversation
        ?.getMessages()
        .then(async (data) => {
          data?.items?.forEach(async (message) => {
            await conversation.updateLastReadMessageIndex(message.index);
          });
          return data;
        })
        .then((data) => {
          dispatch(setMessages(data.items));
        });
      await conversation?.getUnreadMessagesCount();
    } catch (error) {
      console.error('getConversation Error: ', error);
    }
  };
  useEffect(() => {
    if (status === 'connected' && !conversation) {
      initConversation();
    }
  }, [status, conversation]);

  const sendMessage = async (message: string) => {
    try {
      await conversation?.sendMessage(message);
    } catch (error) {
      console.error('sendMessage Error: ', error);
    }
  };
  const initChatClient = (accessToken: string) => {
    const client = new Client(accessToken);
    client.on('participantJoined', () => {});
    client.on('initFailed', () => {
      setStatus('error');
    });
    client.on('connectionStateChanged', (state) => {
      setStatus(state);
    });
    client.on('messageAdded', async (message: MessageType) => {
      dispatch(addMessage(message));
    });
    client.on('messageRemoved', async (message: MessageType) => {
      dispatch(removeMessage(message));
    });
    dispatch(setClient(client));
  };
  const destroyChatClient = () => {
    if (client) {
      client.removeAllListeners();
    }
  };
  return {
    messages,
    destroyChatClient,
    initChatClient,
    sendMessage,
    conversation,
    initConversation,
  };
};
