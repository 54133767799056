import styled from 'styled-components';

import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  gap: 12px;
`;

export const PronunciationStyled = styled.section`
  display: flex;
  align-items: center;
  width: 100%;

  > p {
    color: ${commonColors.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-right: 4px;
  }

  /* 
  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.error2};
  } */

  > div {
    margin-left: 16px;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

export const CancelButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;

  color: ${commonColors.grey600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.08px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 4px;

    path {
      fill: ${commonColors.grey600};
    }
  }
`;

export const CheckboxStyled = styled.div`
  label {
    color: ${commonColors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.6px;
    letter-spacing: 0.07px;
  }
`;
