import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionsWrapper, Wrapper } from './styles';
import { EmptySubscriptionProps } from './modules';
import { SecondaryButton } from 'common/components';
import { WarningCircleIcon, AddIcon } from 'applicaiton/assets';
import { Link } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { useAppSelector } from 'common/hooks/redux';

const EmptySubscriptionSection: FC<EmptySubscriptionProps> = ({
  onRefreshPaymentAccountStatus,
}) => {
  const { t } = useTranslation();
  const userRole = getUserRole();
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);
  const { editedProfessional, clinicOwnerIdsWithNotConfiguredPayment } =
    useAppSelector((state) => state.professionalProfile);

  const emptyStateMessage = useMemo(() => {
    const clinicsWithoutAccount = editedProfessional?.clinicsRelations?.filter(
      (item) =>
        clinicOwnerIdsWithNotConfiguredPayment.includes(
          item?.clinic?.owner?.id!,
        ),
    );

    const clinicNames = clinicsWithoutAccount?.map(
      (clinic) => clinic?.clinic?.name,
    );

    return clinicNames;
  }, [clinicOwnerIdsWithNotConfiguredPayment]);

  return (
    <Wrapper>
      {isClinicOwnerRole ? (
        <>
          <WarningCircleIcon />
          <p>{t('professional_profile.subscriptions.configure_billing')}</p>
          <ActionsWrapper>
            <Link to={PathNames.clinicBilling} target="_blank">
              <SecondaryButton>
                <AddIcon />
                {t('professional_profile.subscriptions.go_to_billing')}
              </SecondaryButton>
            </Link>
            <p onClick={onRefreshPaymentAccountStatus}>
              {t('professional_profile.subscriptions.refresh')}
            </p>
          </ActionsWrapper>
        </>
      ) : (
        <>
          <WarningCircleIcon />
          <p>
            {t(
              'professional_profile.subscriptions.askToConfigureSubscriptionsText1',
            )}{' '}
            <br />{' '}
            {t(
              'professional_profile.subscriptions.askToConfigureSubscriptionsText3',
            )}{' '}
            <span>{emptyStateMessage?.join(', ')}</span>{' '}
            {t(
              'professional_profile.subscriptions.askToConfigureSubscriptionsText4',
            )}
          </p>
        </>
      )}
    </Wrapper>
  );
};
export default EmptySubscriptionSection;
