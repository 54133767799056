import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export interface UserInfoT {
  firstName: string;
  lastName: string;
  jobPosition: string;
}

export const useOwnerInfoForm = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const formUserValues = {
    firstName: '',
    lastName: '',
    jobPosition: '',
  };

  const UserSchema = yup
    .object({
      firstName: yup.string().required(requiredError),
      lastName: yup.string().required(requiredError),
      jobPosition: yup.string(),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<UserInfoT>({
    resolver: yupResolver(UserSchema),
    defaultValues: formUserValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    trigger,
  };
};
