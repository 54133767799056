import React, { FC } from 'react';
import { Wrapper } from './styles';
import MyProfile from 'common/components/MyProfile';
import AccordionSettings from './components/AccordionSettings';

const SettingsSubmenu: FC = () => {
  return (
    <Wrapper>
      <MyProfile />
      <AccordionSettings />
    </Wrapper>
  );
};

export default SettingsSubmenu;
