import { UseDominantSpeakerProps } from 'features/feature-teleconsultation/types';
import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';

/**
 * hanya remote participant yang dapat didetect as dominant speaker
 * https://www.twilio.com/docs/video/detecting-dominant-speaker#javascript
 */

const useDominantSpeaker = ({ room }: UseDominantSpeakerProps) => {
  const [dominantSpeaker, setDominantSpeaker] =
    useState<RemoteParticipant | null>(null);

  useEffect(() => {
    if (room) {
      const handleDominantSpeakerChanged = (participant: RemoteParticipant) =>
        setDominantSpeaker(participant);

      room.on('dominantSpeakerChanged', handleDominantSpeakerChanged);

      return () => {
        room.off('dominantSpeakerChanged', handleDominantSpeakerChanged);
      };
    }
  }, [room]);

  return { dominantSpeaker };
};

export default useDominantSpeaker;
