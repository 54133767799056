import axiosInstance from 'integration/api/axiosInstance';
import {
  AppointmentBriefResponseDto,
  AppointmentCreationDto,
  AppointmentCreationResponseDto,
  AppointmentResponseDto,
  AppointmentStatusUpdateDto,
  AppointmentUpdateDto,
  AppointmentUpdateResponseDto,
  PatientSearchResponseDto,
} from '@docbay/schemas';

interface Props {
  patientId: string;
  startDateTime?: string;
  endDateTime?: string;
}

interface WaitingListProps {
  page: number;
  limit: number;
  clinicIds?: string[];
}

export const AppointmentsApi = {
  fetchPatientAppointmentsBrief: (params: Props) =>
    axiosInstance.get<AppointmentBriefResponseDto[]>(
      '/appointments/appointments-brief',
      {
        params,
      },
    ),
  fetchAppointmentsWaitingList: (params: WaitingListProps) =>
    axiosInstance.get('/appointments/waiting-list', {
      params,
    }),
  createAppointment: (data: AppointmentCreationDto) =>
    axiosInstance.post<AppointmentCreationResponseDto>('/appointments', data),
  fetchAppointmentPatients: (params: { search: string }) =>
    axiosInstance.get<PatientSearchResponseDto[]>('/patients/search', {
      params,
    }),
  deleteAppointment: (id: string) =>
    axiosInstance.delete(`/appointments/${id}`),
  fetchAppointmentById: (id: string) =>
    axiosInstance.get<AppointmentResponseDto>(
      `/appointments/${id}?include=patient,clinicProfessional,appointmentType,documents,appointmentType.specialization`,
    ),
  editAppointment: (id: string, data: AppointmentUpdateDto) =>
    axiosInstance.patch<AppointmentUpdateResponseDto>(
      `/appointments/${id}`,
      data,
    ),
  getTeleconsultationConnectionData: (id: string) =>
    axiosInstance.get(`/appointments/${id}/connection-data`),
  createTeleconsultationChat: (id: string) =>
    axiosInstance.post(`/appointments/${id}/chat`),
  endAppointment: (id: string) => {
    return axiosInstance.post(`/appointment-documents/${id}/generate-invoice`);
  },
  updateAppointmentStatus: (id: string, data: AppointmentStatusUpdateDto) =>
    axiosInstance.patch<AppointmentStatusUpdateDto>(
      `/appointments/${id}/status`,
      data,
    ),
};
