import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { ReactComponent as TeleconsultationCameraIcon } from 'applicaiton/assets/teleconsultation_camera.svg';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import { ReactComponent as ContentPasteIcon } from 'applicaiton/assets/content_paste.svg';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { PathNames } from 'applicaiton/routes';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { MeetingPlace } from 'applicaiton/constants/meetingPlace';
import { useAppSelector } from 'common/hooks/redux';

import AppointmentDocuments from '../AppointmentDocuments';

import { Wrapper, LinkStyled } from './styles';

const AppointmentActions: FC = () => {
  const { t } = useTranslation();
  const userRole = getUserRole();
  const location = useLocation();

  const { mode, currentAppointment, currentPatient } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );

  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);
  const isProfessionalRole = userRole?.includes(UserRoles.professional);
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const isCreateMode =
    mode === ReserveSlotsMode.CreateAppointment ||
    mode === ReserveSlotsMode.BookAgain;

  const isShowConsultationData = useMemo(() => {
    const isClinicOwnerAccess = currentClinicOwner
      ? currentClinicOwner?.professional?.id ===
        currentAppointment?.professional?.id
      : true;

    return !isCreateMode && isProfessionalRole && isClinicOwnerAccess;
  }, [currentClinicOwner, currentAppointment]);

  const showTeleconsultationButton = useMemo(() => {
    if (!currentAppointment || (!isMyAgendaPage && isClinicOwnerRole))
      return false;
    const tenMinutesInSeconds = 600;
    const isTeleconsultation =
      currentAppointment.appointmentType.meetingPlace ===
      MeetingPlace.Teleconsultation;
    const eventStartInSeconds = dayjs(currentAppointment.startDate).diff(
      dayjs(),
      'second',
    );
    const eventEndInSeconds = dayjs(currentAppointment.endDate).diff(
      dayjs(),
      'second',
    );

    const isTimeToShow =
      eventStartInSeconds < tenMinutesInSeconds && eventEndInSeconds > 0;

    return isTeleconsultation && isTimeToShow;
  }, [currentAppointment, isMyAgendaPage, isClinicOwnerRole]);

  return (
    <Wrapper>
      <h2>{t('actions')}</h2>
      {!!currentPatient && (
        <LinkStyled
          to={`${PathNames.patient}/${currentPatient.id}`}
          target="_blank"
        >
          <PersonIcon />
          {t('see_patient_profile')}
        </LinkStyled>
      )}
      {isShowConsultationData && (
        <LinkStyled
          to={`${PathNames.consultationData}/${currentAppointment?.id}`}
          target="_blank"
        >
          <ContentPasteIcon />
          {t('consultation_data.consultation_data')}
        </LinkStyled>
      )}
      {showTeleconsultationButton && (
        <LinkStyled
          to={`${PathNames.teleconsultation}/${currentAppointment?.id}`}
          target="_blank"
        >
          <TeleconsultationCameraIcon />{' '}
          {t('appointment.join_teleconsultation')}
        </LinkStyled>
      )}
      <AppointmentDocuments isCreateMode={isCreateMode} />
    </Wrapper>
  );
};

export default AppointmentActions;
