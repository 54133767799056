import styled from 'styled-components';

export const StyledTr = styled.tr`
  background: ${({ theme }) => theme.color.white};
  > td {
    vertical-align: middle;
    text-align: start;
    padding: 40px 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    &:first-child {
      border-left: 1px solid ${({ theme }) => theme.color.grey200};
    }

    &:last-child {
      border-right: 1px solid ${({ theme }) => theme.color.grey200};
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        margin-right: 24px;
      }

      > p {
        color: ${({ theme }) => theme.color.grey900};
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        > span {
          cursor: pointer;
          font-weight: 600;
          color: ${({ theme }) => theme.color.blue800};
          text-underline-offset: 2px;
        }
      }
    }
  }
`;
