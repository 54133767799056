import React, { FC, useEffect, useRef, useState } from 'react';

import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as PortugalIcon } from 'applicaiton/assets/countries-flags/portugal.svg';
import { ReactComponent as FranceIcon } from 'applicaiton/assets/countries-flags/france.svg';
import { getSupportedCountries } from 'applicaiton/store/reducers/Countries/ActionCreators';

import useOnClickOutside from 'common/hooks/useClickOutside';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { SupportedCountries } from 'common/types/countries';
import { LocaleName } from 'common/types/locale';
import { useLanguage } from 'common/hooks/useLanguage';

import { CountryDropdownProps } from './models';
import { DropdownStyled, OptionItem, OptionsList } from './styles';

const getCountryFlag = (iso: string) => {
  switch (iso) {
    case SupportedCountries.Portugal: {
      return <PortugalIcon />;
    }
    case SupportedCountries.France: {
      return <FranceIcon />;
    }
    default:
      return '';
  }
};

const CountryDropdown: FC<CountryDropdownProps> = ({
  label,
  placeholder,
  country,
  onChange,
  hint,
  disabled,
  errorMessage,
}) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { supportedCountries } = useAppSelector((state) => state.countries);
  const [isActive, setIsActive] = useState(false);

  const { language } = useLanguage();

  useOnClickOutside(ref, () => setIsActive(false));

  const countryName =
    supportedCountries.find((item) => item.code === country.code)?.[
      `name_${language.toUpperCase()}` as LocaleName
    ] || '';

  useEffect(() => {
    dispatch(getSupportedCountries());
  }, []);

  return (
    <DropdownStyled
      isActive={isActive}
      disabled={disabled}
      ref={ref}
      isError={!!errorMessage}
    >
      {label && <label htmlFor={'country-dropdown'}>{label}</label>}
      <section onMouseDown={() => !disabled && setIsActive(!isActive)}>
        <>
          {!countryName ? (
            <span>{placeholder}</span>
          ) : (
            <h2>
              {getCountryFlag(country.code)} {countryName}
            </h2>
          )}
        </>
        <ExpandMoreIcon />
      </section>
      {isActive && !!supportedCountries.length && (
        <OptionsList className="country-option-list">
          {supportedCountries.map((item) => (
            <OptionItem
              key={item.id}
              onClick={() => {
                onChange({
                  code: item.code,
                  id: String(item.id),
                  name: item.name,
                });
                setIsActive(false);
              }}
              selected={country.code === String(item.code)}
            >
              {getCountryFlag(item.code)}
              {item[`name_${language.toUpperCase()}` as LocaleName]}
            </OptionItem>
          ))}
        </OptionsList>
      )}
      {(hint || errorMessage) && (
        <span>{errorMessage ? errorMessage : hint}</span>
      )}
    </DropdownStyled>
  );
};

export default CountryDropdown;
