import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ReactComponent as PersonsIcon } from 'applicaiton/assets/persons.svg';
import { ReactComponent as BadgeIcon } from 'applicaiton/assets/badge.svg';
import { ReactComponent as PhoneIcon } from 'applicaiton/assets/phone.svg';
import { ReactComponent as EmailIcon } from 'applicaiton/assets/email.svg';
import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar.svg';
import { ReactComponent as LocationIcon } from 'applicaiton/assets/location.svg';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-pencil.svg';
import { ReactComponent as GroupIcon } from 'applicaiton/assets/group.svg';
import { getPatientById } from 'applicaiton/store/reducers/Patients/ActionCreators';
import { SecondaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Gender } from 'features/feature-patient-profile/constants';
import { Wrapper, ItemsList, ItemStyled } from './styles';
import { setEditedPatientId } from 'applicaiton/store/reducers/Patients/PatientsSlice';
import { MembersIcon } from 'common/components/Icons';

const ProfileInfo: FC = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { currentPatient } = useAppSelector((state) => state.patients);

  useEffect(() => {
    dispatch(getPatientById(String(id)));
  }, [id]);

  const pronunciation = useMemo(() => {
    const isMale = currentPatient?.gender === Gender.Male;
    return isMale ? t('mr') : t('mrs');
  }, [currentPatient?.gender, i18n.language]);

  const fullName = useMemo(() => {
    const name = [currentPatient?.firstName, currentPatient?.lastName]
      .filter((item) => !!item)
      .join(' ');
    return name;
  }, [currentPatient?.firstName, currentPatient?.lastName]);

  const dateOfBirth = useMemo(() => {
    if (!currentPatient?.dateOfBirth) return '';
    const formattedDate = dayjs(currentPatient.dateOfBirth).format(
      'DD/MM/YYYY',
    );
    const yearsOld =
      dayjs().get('years') - dayjs(currentPatient.dateOfBirth).get('years');

    return `${formattedDate} (${yearsOld} ${t('years')})`;
  }, [currentPatient?.dateOfBirth, i18n.language]);

  const address = useMemo(() => {
    const fullAddressName = [
      currentPatient?.address?.city,
      currentPatient?.address?.addressLine,
      currentPatient?.address?.zipCode,
    ]
      .filter((item) => !!item)
      .join(',');

    return fullAddressName;
  }, [currentPatient?.address]);

  const handleSetEditedPatientId = () => {
    dispatch(setEditedPatientId(String(id)));
  };

  return (
    <Wrapper>
      <ItemsList>
        {pronunciation && (
          <ItemStyled>
            <PersonsIcon />
            <span>{t('pronunciation')}</span>
            <p>{pronunciation}</p>
          </ItemStyled>
        )}
        {fullName && (
          <ItemStyled>
            <BadgeIcon />
            <p>
              <span>{t('name')}</span>
              {fullName}
            </p>
          </ItemStyled>
        )}
        {currentPatient?.phone && (
          <ItemStyled>
            <PhoneIcon />
            <p>
              <span>{t('phone')}</span>
              {currentPatient?.phone}
            </p>
          </ItemStyled>
        )}
        {currentPatient?.email && (
          <ItemStyled>
            <EmailIcon />
            <p>
              <span>{t('email')}</span>
              {currentPatient?.email}
            </p>
          </ItemStyled>
        )}
        {dateOfBirth && (
          <ItemStyled>
            <CalendarIcon />
            <span>{t('date_of_birth')}</span>
            <p>{dateOfBirth}</p>
          </ItemStyled>
        )}
        {address && (
          <ItemStyled>
            <LocationIcon />
            <p>
              <span>{t('address')}</span>
              {address}
            </p>
          </ItemStyled>
        )}
        {currentPatient?.cityOfBirth && (
          <ItemStyled>
            <LocationIcon />
            <p>
              <span>{t('city_of_birth')}</span>
              {currentPatient?.cityOfBirth}
            </p>
          </ItemStyled>
        )}
        {currentPatient?.countryOfBirth && (
          <ItemStyled>
            <LocationIcon />
            <p>
              <span>{t('country_of_birth')}</span>
              {currentPatient?.countryOfBirth}
            </p>
          </ItemStyled>
        )}
        {currentPatient?.nif && (
          <ItemStyled>
            <GroupIcon />
            <p>
              {' '}
              <span>{t('my_patients.fiscal_identification_number')}</span>
              {currentPatient?.nif}
            </p>
          </ItemStyled>
        )}
        {currentPatient?.socialSecurityNumber && (
          <ItemStyled>
            <GroupIcon />
            <p>
              <span>{t('my_patients.social_security_number')}</span>
              {currentPatient?.socialSecurityNumber}
            </p>
          </ItemStyled>
        )}
        {!!currentPatient?.familyMembers?.length &&
          currentPatient?.familyMembers?.map((member) => (
            <ItemStyled key={member.id} isStrokeSvg={true}>
              <MembersIcon />
              <p>
                <span>{member.relationship}</span>
                {`${member.firstName} ${member.lastName}`}
              </p>
            </ItemStyled>
          ))}
      </ItemsList>
      {!currentPatient?.user?.id && (
        <SecondaryButton
          styleType="color"
          type="button"
          onClick={handleSetEditedPatientId}
        >
          <EditIcon />
          {t('edit')}
        </SecondaryButton>
      )}
    </Wrapper>
  );
};

export default ProfileInfo;
