import { useSelector } from 'react-redux';
import { RootState, store } from 'applicaiton/store';
import { useChatsContext } from './useChatsContext';
import { ProfessionalChatUpdateDto } from '@docbay/schemas';
import { useAppDispatch } from 'common/hooks/redux';
import {
  updateChat as updateChatAction,
  archiveChat as archiveChatAction,
  unArchiveChat as unArchiveChatAction,
  deleteChat as deleteChatAction,
  addDocumentMessage,
  closeTopic as closeTopicAction,
} from 'applicaiton/store/reducers/Chats/ActionCreators';
import { ChatsAPI } from 'integration/api/chats';
type MessageEvent = 'messageAdded' | 'messageRemoved' | 'messageUpdated';
export const useConversation = () => {
  const { selectedChat } = useSelector((state: RootState) => state.chats);
  const dispatch = useAppDispatch();
  const { conversation, setConversation } = useChatsContext();
  const conversationIsReady = !!conversation;

  const initConversation = async (conversationSID: string) => {
    const client = store.getState().chats.currentClient;
    const newChatsConversation = await client?.getConversationBySid(
      conversationSID,
    );
    await newChatsConversation?.setAllMessagesRead();
    setConversation(newChatsConversation || null);
    return !!newChatsConversation;
  };

  const getMessages = async () => {
    if (!conversation) {
      return;
    }
    return await conversation.getMessages();
  };

  const handleSaveDocumentMessage = async (data: FormData) => {
    const saveDocumentMessage = await dispatch(
      addDocumentMessage({
        conversationSID: selectedChat?.twilioConversationSID!,
        data,
      }),
    );

    return saveDocumentMessage;
  };

  const handleDeleteDocumentMessage = async (messageToDelete: string) => {
    const deleteDocumentMessage = await ChatsAPI.deleteDocumentMessage(
      messageToDelete,
    );

    return deleteDocumentMessage;
  };

  const addListener = (
    event: MessageEvent,
    callback: (payload: any) => void,
  ) => {
    if (!conversation) {
      return;
    }
    conversation.addListener(event, callback);
  };
  const removeListener = (
    event: MessageEvent,
    callback: (payload: any) => void,
  ) => {
    if (!conversation) {
      return;
    }
    conversation.removeListener(event, callback);
  };

  const sendMessage = async (message: string) => {
    if (!conversation) {
      return;
    }
    await conversation.sendMessage(message);
  };

  const updateChat = async (
    conversationSID: string,
    data: ProfessionalChatUpdateDto,
  ) => {
    return await dispatch(updateChatAction({ conversationSID, data }));
  };

  const archiveChat = async (conversationSID: string) => {
    return await dispatch(archiveChatAction(conversationSID));
  };

  const unArchiveChat = async (conversationSID: string) => {
    return await dispatch(unArchiveChatAction(conversationSID));
  };

  const deleteChat = async (conversationSID: string) => {
    const result = await dispatch(deleteChatAction(conversationSID));
    if (result) {
      setConversation(null);
    }
    return result;
  };

  const closeTopic = async (conversationSID: string) => {
    return await dispatch(closeTopicAction(conversationSID));
  };

  return {
    initConversation,
    conversationIsReady,
    sendMessage,
    selectedChat,
    getMessages,
    conversation,
    archiveChat,
    updateChat,
    deleteChat,
    addListener,
    removeListener,
    unArchiveChat,
    handleSaveDocumentMessage,
    handleDeleteDocumentMessage,
    setConversation,
    closeTopic,
  };
};
