import React, { FC } from 'react';
import { Teleconsultation } from 'features';
import { Wrapper } from './styles';

const TeleconsultationPage: FC = () => {
  return (
    <Wrapper>
      <Teleconsultation />
    </Wrapper>
  );
};

export default TeleconsultationPage;
