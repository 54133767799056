import styled from 'styled-components';

interface RuleStyledProps {
  isValid: boolean;
}

export const RulesSectionStyled = styled.div`
  display: grid;
  gap: 4px;
  margin-top: 4px;
`;

export const RuleStyled = styled.div<RuleStyledProps>`
  display: flex;
  align-items: center;
  font-family: 'Mulish', sans-serif;

  > span {
    font-size: 14px;
    line-height: 140%;
    color: ${({ theme, isValid }) =>
      isValid ? theme.color.success1 : theme.color.grey600};
  }

  > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: ${({ theme, isValid }) =>
        isValid ? theme.color.success1 : theme.color.grey600};
    }
  }
`;
