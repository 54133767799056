import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ProfessionalCustomScheduleCreationDto,
  ProfessionalCustomScheduleUpdateDto,
  SearchAgendaDto,
} from '@docbay/schemas';
import { AxiosError } from 'axios';
import { AgendaActions } from 'applicaiton/constants/actions';
import { AgendaAPI } from 'integration/api/agenda';

export const searchAgenda = createAsyncThunk(
  AgendaActions.searchAgenda,
  async (data: SearchAgendaDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await AgendaAPI.search(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const searchAgendaSlots = createAsyncThunk(
  AgendaActions.searchAgendaSlots,
  async (data: SearchAgendaDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await AgendaAPI.searchSlots(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchOpenAgenda = createAsyncThunk(
  AgendaActions.fetchOpenAgenda,
  async (
    { id, params }: { id: string; params: { clinicId: string; date: string } },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await AgendaAPI.fetchOpenAgenda(id, params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const addOpenAgenda = createAsyncThunk(
  AgendaActions.addOpenAgenda,
  async (
    { id, hours }: { id: string; hours: ProfessionalCustomScheduleCreationDto },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await AgendaAPI.addOpenAgenda(id, hours);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const editOpenAgenda = createAsyncThunk(
  AgendaActions.editOpenAgenda,
  async (
    {
      id,
      customScheduleId,
      hoursData,
    }: {
      id: string;
      customScheduleId: string;
      hoursData: ProfessionalCustomScheduleUpdateDto;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await AgendaAPI.editOpenAgenda(
        id,
        customScheduleId,
        hoursData,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const removeOpenAgenda = createAsyncThunk(
  AgendaActions.removeOpenAgenda,
  async (
    {
      id,
      customScheduleId,
      clinicId,
    }: {
      id: string;
      customScheduleId: string;
      clinicId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await AgendaAPI.removeOpenAgenda(
        id,
        customScheduleId,
        clinicId,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
