import React, { FC } from 'react';
import { Wrapper } from './styles';
import EventEditDocuments from 'features/feature-teleconsultation/components/EventEditDocuments';

const Documents: FC = () => {
  return (
    <Wrapper>
      <EventEditDocuments isTeleconsultationDocuments={true} />
    </Wrapper>
  );
};

export default Documents;
