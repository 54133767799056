import * as React from 'react';
import { FC, ReactNode } from 'react';
import { Header } from './Header';

type Props = {
  children: ReactNode;
};

export const WithHeader: FC<Props> = ({ children }: Props) => (
  <section className="App-content">
    <Header />
    {children}
  </section>
);
