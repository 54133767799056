import {
  ProfessionalAgendaResponseDto,
  ProfessionalAgendaSlotsResponseDto,
  ProfessionalCustomScheduleCreationDto,
  ProfessionalCustomScheduleResponseDto,
  ProfessionalCustomScheduleUpdateDto,
  SearchAgendaDto,
  SearchConfusingAgendaDto,
} from '@docbay/schemas';

import axiosInstance from 'integration/api/axiosInstance';

export const AgendaAPI = {
  search: (data: SearchAgendaDto) => {
    return axiosInstance.post<ProfessionalAgendaResponseDto[]>(
      '/agendas/search',
      data,
    );
  },
  searchSlots: (data: SearchAgendaDto) =>
    axiosInstance.post<ProfessionalAgendaSlotsResponseDto>(
      '/agendas/search-slots',
      data,
    ),
  fetchConfusingAgenda: (data: SearchConfusingAgendaDto) =>
    axiosInstance.post('/agendas/search-confusing-agenda', data),
  fetchOpenAgenda: (id: string, params: { date: string; clinicId: string }) => {
    return axiosInstance.get<ProfessionalCustomScheduleResponseDto>(
      `/professionals/${id}/custom-schedule`,
      {
        params,
      },
    );
  },
  addOpenAgenda: (id: string, data: ProfessionalCustomScheduleCreationDto) => {
    return axiosInstance.post(`/professionals/${id}/custom-schedule`, data);
  },
  editOpenAgenda: (
    id: string,
    customScheduleId: string,
    hoursData: ProfessionalCustomScheduleUpdateDto,
  ) => {
    return axiosInstance.patch(
      `/professionals/${id}/custom-schedule/${customScheduleId}`,
      hoursData,
    );
  },
  removeOpenAgenda: (
    id: string,
    customScheduleId: string,
    clinicId: string,
  ) => {
    return axiosInstance.delete(
      `/professionals/${id}/custom-schedule/${customScheduleId}/${clinicId}`,
    );
  },
};
