import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'applicaiton/assets/info.svg';
import { WarningMessageProps } from './module';
import { Wrapper } from './styles';

const WarningMessage: FC<WarningMessageProps> = ({
  isExistingProfessional,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <InfoIcon />
      <p>
        {isExistingProfessional
          ? t('personal_information.warning_message')
          : t('personal_information.enter_email_first')}
      </p>
    </Wrapper>
  );
};

export default WarningMessage;
