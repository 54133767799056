import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { FetchLocationParams, Location } from 'common/types/mapBox';

const sessionToken = uuid();
const accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;

const axiosInstance = axios.create({
  baseURL: 'https://api.mapbox.com/search/searchbox/v1',
});

export const fetchAllLocations = ({
  searchItem,
  country,
}: FetchLocationParams) =>
  axiosInstance.get<Location[]>(
    `suggest?q=${searchItem}&country=${
      country || 'pt'
    }&types=street,address&access_token=${accessToken}&session_token=${sessionToken}`,
  );
