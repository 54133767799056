import React, { FC } from 'react';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { ReactComponent as EmptyStateIcon } from 'applicaiton/assets/empty-state-appointments-past.svg';

import { StyledTr, EmptyStateContainer } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  emptyCurrentClinic: boolean;
}

const NoDataElement: FC<Props> = ({ emptyCurrentClinic }) => {
  const { t } = useTranslation();

  return (
    <>
      {emptyCurrentClinic ? (
        <StyledTr emptyCurrentClinic={emptyCurrentClinic}>
          <td colSpan={6}>
            <div>
              <EmptyStateIcon />
              <p>{t('subscriptions_statistics.emptyTableForClinic')}</p>
            </div>
          </td>
        </StyledTr>
      ) : (
        <EmptyStateContainer>
          <PickIcon />
          <p>{t('subscriptions_statistics.emptyTable')}</p>
        </EmptyStateContainer>
      )}
    </>
  );
};
export default NoDataElement;
