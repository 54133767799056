import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';

import { PatientItemProps } from './models';
import { ProfessionalNameStyled, StyledTr, ActionsStyled } from './styles';
import { useTranslation } from 'react-i18next';

const PatientItem: FC<PatientItemProps> = ({
  patient,
  handleDeletePatient,
}) => {
  const { t } = useTranslation();

  const formattedDateOfBirth = useMemo(() => {
    const dateOfBirth = dayjs(patient.dateOfBirth).format('DD/MM/YYYY');
    const age = dayjs().diff(patient.dateOfBirth, 'year');
    return `${dateOfBirth} (${age} ${t('years')})`;
  }, [patient.dateOfBirth]);

  const handleDelete = () => {
    handleDeletePatient(patient);
  };

  return (
    <StyledTr>
      <td>
        <ProfessionalNameStyled>
          {patient.firstName} {patient.lastName}
        </ProfessionalNameStyled>
      </td>
      <td>{patient.email}</td>
      <td>{formattedDateOfBirth}</td>
      <td>{patient.phone}</td>
      <td>
        <ActionsStyled>
          <Link to={`${PathNames.patient}/${patient.id}`}>
            <EditIcon />
          </Link>
          <button onClick={handleDelete}>
            <DeleteIcon />
          </button>
        </ActionsStyled>
      </td>
    </StyledTr>
  );
};
export default PatientItem;
