import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  DataWrapper,
  DataSection,
  Information,
  Metrics,
  MetricsWrapper,
  HistoryList,
  DataItem,
  MainData,
  MainDataText,
  SymptomList,
  EmptyMetrics,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { getConsultationDataHistory } from 'applicaiton/store/reducers/Consultation/ActionCreators';
import HistoryListItem from '../HistoryListItem';
import Loader from 'common/components/Loader';
import { AppointmentHistoryResponseDto } from '@docbay/schemas';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import { MarkdownText } from 'common/components';
import { EmptyStateIcon } from 'applicaiton/assets';
import { useLanguage } from 'common/hooks/useLanguage';

const ConsultationHistory = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();
  const { appointmentId } = useParams();
  const { language } = useLanguage();

  const [currentAppointmentData, setCurrentAppointmentData] =
    useState<AppointmentHistoryResponseDto>();

  const { history, isLoading } = useAppSelector(
    (state) => state.consultationData,
  );

  useEffect(() => {
    appointmentId && dispatch(getConsultationDataHistory(appointmentId!));
  }, []);

  useEffect(() => {
    if (history) {
      const currentAppointment = history.find(
        (item) => item.id === appointmentId,
      );
      setCurrentAppointmentData(currentAppointment);
    }
  }, [history]);

  const getCurrentConsultationData = (
    appointmentData: AppointmentHistoryResponseDto,
  ) => {
    setCurrentAppointmentData(appointmentData);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Wrapper>
      <DataWrapper>
        <DataSection>
          <HistoryList>
            {history?.map((item) => {
              return (
                <HistoryListItem
                  key={item.id}
                  item={item}
                  isActive={item.id === currentAppointmentData?.id!}
                  onClick={getCurrentConsultationData}
                />
              );
            })}
          </HistoryList>
          <div>
            <MainData>
              <Information>
                <h3>{t('consultation_data.consultation_information')}</h3>
                <DataItem>
                  <h6>{t('consultation_data.reasons_for_consultation')}</h6>
                  <div>{currentAppointmentData?.appointmentData?.reason}</div>
                </DataItem>
                <DataItem>
                  <h6>{t('consultation_data.select_symptoms')}</h6>
                  <SymptomList>
                    {currentAppointmentData?.appointmentData?.symptoms?.map(
                      (item) => {
                        return (
                          <li key={`symptom_${item.id}`}>
                            {item[localizeNameKey]}
                          </li>
                        );
                      },
                    )}
                  </SymptomList>
                </DataItem>
                <MainDataText>
                  <h6>{t('consultation_data.notes')}</h6>
                  <MarkdownText
                    text={currentAppointmentData?.appointmentData?.notes || '-'}
                  />
                </MainDataText>
                <MainDataText>
                  <h6>{t('consultation_data.treatment_plan')}</h6>
                  <MarkdownText
                    text={
                      currentAppointmentData?.appointmentData?.treatmentPlan ||
                      '-'
                    }
                  />
                </MainDataText>
              </Information>
              <Metrics>
                <h3>{t('consultation_data.health_metrics')}</h3>
                <MetricsWrapper>
                  {!currentAppointmentData?.appointmentData?.healthMetrics
                    ?.length ? (
                    <EmptyMetrics>
                      <EmptyStateIcon />
                      <p>{t('consultation_data.empty_health_metrics')}</p>
                    </EmptyMetrics>
                  ) : (
                    <>
                      {currentAppointmentData?.appointmentData?.healthMetrics.map(
                        (item) => {
                          const currentMetric =
                            item.healthMetric.localizations.find(
                              (metric) => metric.languageIsoCode === language,
                            );
                          return (
                            <DataItem key={item.id}>
                              <h6>{currentMetric?.translation}</h6>
                              <div>
                                {item.value}{' '}
                                {currentMetric?.unitOfMeasurement?.name}
                              </div>
                            </DataItem>
                          );
                        },
                      )}
                    </>
                  )}
                </MetricsWrapper>
              </Metrics>
            </MainData>
          </div>
        </DataSection>
      </DataWrapper>
    </Wrapper>
  );
};

export default ConsultationHistory;
