import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ReactComponent as OnlineIcon } from 'applicaiton/assets/online.svg';
import { ReactComponent as PinIcon } from 'applicaiton/assets/pin.svg';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { meetingPlaces } from 'features/feature-scheduler/constants/meetingPlace';
import { StyledAppointment } from './styles';
import { setSlotsDetails } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import { AppointmentProps } from './models';
import { useSearchParams } from 'react-router-dom';

const Appointment: FC<AppointmentProps> = ({
  data,
  isWeeklyView,
  isGroupingView,
  scrollEvent,
  ...restProps
}) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const {
    id,
    startDate,
    endDate,
    appointmentType,
    meetingPlace,
    colorCode,
    patientName,
    highLight,
    bookedForMember,
  } = data;
  const [offset, setOffset] = useState({
    height: 0,
    width: 0,
  });
  const startTime = dayjs(startDate).format('HH:mm');
  const endTime = dayjs(endDate).format('HH:mm');
  const isTeleconsultation = meetingPlace === meetingPlaces.teleconsultation;
  const appointmentDurationInMinutes = dayjs(endDate).diff(startDate, 'minute');

  const isSmallDuration = appointmentDurationInMinutes <= 15;

  const { currentAppointment } = useAppSelector(
    (state) => state.patientAppointments,
  );

  useEffect(() => {
    const appointmentID = searchParams.get('appointment');
    if (appointmentID) {
      dispatch(
        setSlotsDetails({
          appointmentId: String(appointmentID),
          mode: ReserveSlotsMode.PreviewAppointment,
        }),
      );
    }
    if (scrollEvent && !currentAppointment) {
      const wrapper = document.querySelector('.MainLayout-container');
      const Appointment: HTMLElement | null = document.querySelector(
        '.Appointment-appointment',
      );
      if (Appointment && wrapper) {
        const arrayOfTops = Array.from(
          (Appointment.closest('.Container-container')
            ?.children as HTMLCollectionOf<HTMLElement>) || [],
        )?.map((item) => {
          return +(item.style.transform.match(/\d/g)?.join('') || 0);
        });
        const currentTop = arrayOfTops.reduce((acc, curr) => {
          return (acc = curr < +acc ? curr : acc);
        }, 9999);

        wrapper.scrollTo({ top: currentTop || 0, behavior: 'smooth' });
      }
    }
  }, []);

  useEffect(() => {
    const Appointment: HTMLDivElement | null = document.querySelector(
      `#appointment-${id}`,
    );

    if (Appointment?.offsetHeight) {
      setOffset({
        height: Appointment.offsetHeight,
        width: Appointment.offsetWidth,
      });
    }
  }, []);

  useEffect(() => {
    if (highLight) {
      const highLightedAppointment: HTMLDivElement | null =
        document.querySelector('.highLighted');

      if (highLightedAppointment) {
        const wrapper = document.querySelector('.MainLayout-container')!;
        const box = highLightedAppointment.getBoundingClientRect();

        const scrollTop = wrapper.scrollTop;
        const clientTop = window.innerHeight / 2;
        const top = box.top + scrollTop - clientTop;
        const scrollLeft = wrapper.scrollLeft;
        const clientWidth = window.innerWidth / 2;
        const left = box.left + scrollLeft - clientWidth;
        wrapper.scrollTo({ top: top, left: left, behavior: 'smooth' });
      }
    }
  }, []);

  const maxSize = offset.height >= 60 && offset.width >= 110;
  const dailyMaxSize = offset.height >= 76;
  const hideInfoWeeklyView = isWeeklyView && isSmallDuration;
  const hideInfoDailyView = isGroupingView && !isWeeklyView && isSmallDuration;

  const handleAppointmentClick = () => {
    dispatch(
      setSlotsDetails({
        appointmentId: String(id),
        mode: ReserveSlotsMode.PreviewAppointment,
      }),
    );
  };

  const currentAppointmentName = bookedForMember
    ? `${bookedForMember.firstName} ${bookedForMember.lastName}`
    : patientName;

  return (
    <StyledAppointment
      {...restProps}
      className={highLight ? 'highLighted' : ''}
      colorCode={colorCode}
      isSmall={offset.height < 45}
      data={data}
      id={`appointment-${id}`}
      onClick={handleAppointmentClick}
    >
      {isWeeklyView && (
        <div>
          {maxSize && (
            <div>
              <h2>{currentAppointmentName}</h2>
              <>
                <h3>
                  {appointmentType} {isTeleconsultation && <OnlineIcon />}
                </h3>
                <p>{startTime}</p>
                {data.isShowClinicName && (
                  <div className={'clinicName'}>
                    <PinIcon />
                    <span>{data.clinicName}</span>
                  </div>
                )}
              </>
            </div>
          )}
          <div className={'minText'}>
            {!maxSize && <h2>{currentAppointmentName}</h2>}
            {!hideInfoWeeklyView && !maxSize && (
              <h3>
                {appointmentType} {isTeleconsultation && <OnlineIcon />}
              </h3>
            )}
          </div>
        </div>
      )}
      {!isWeeklyView && (
        <div className={dailyMaxSize ? 'column' : 'row'}>
          {!hideInfoDailyView && (
            <div>
              <div className={'patientName'}>
                <h2>{currentAppointmentName} </h2>
                <h3>
                  {appointmentType} {isTeleconsultation && <OnlineIcon />}
                </h3>
              </div>
              <>
                {data.isShowClinicName && (
                  <div className={'clinicName'}>
                    <PinIcon />
                    <span>{data.clinicName}</span>
                  </div>
                )}
              </>
            </div>
          )}
          {hideInfoDailyView && (
            <div className={'patientName'}>
              <h2>{currentAppointmentName} </h2>{' '}
            </div>
          )}
          <p>{`${startTime} - ${endTime}`}</p>
        </div>
      )}
    </StyledAppointment>
  );
};

export default Appointment;
