import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  background: ${({ theme }) => theme.color.background};
`;

export const ErrorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  h2 {
    color: ${({ theme }) => theme.color.grey800};
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 70%;
  }

  h4 {
    color: ${({ theme }) => theme.color.grey800};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  p {
    color: ${({ theme }) => theme.color.grey600};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  button {
    width: 200px;
  }
`;
