import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadIcon } from 'applicaiton/assets/upload.svg';
import { ReactComponent as AttentionIcon } from 'applicaiton/assets/attention.svg';
import { ReactComponent as LoadIcon } from 'applicaiton/assets/loader.svg';
import SecondaryButton from '../SecondaryButton';
import {
  Loader,
  PhotoUploaderStyled,
  UploadWrapper,
  UploadingProgress,
  UploadingText,
  UploadingProgressError,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { uploadNewPhoto } from 'applicaiton/store/reducers/Photo/ActionCreators';
import { PhotoUploaderProps } from './modules';
import { setErrorPhoto } from 'applicaiton/store/reducers/Photo/PhotoSlice';

const PhotoUploader: FC<PhotoUploaderProps> = ({ defaultPhoto, label }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, isError, uploadedPhotos } = useAppSelector(
    (state) => state.photos,
  );

  useEffect(() => {
    return () => {
      dispatch(setErrorPhoto());
    };
  }, []);

  const currentPhoto = uploadedPhotos.length
    ? uploadedPhotos[0].thumbnailUrl
    : defaultPhoto?.thumbnailUrl;

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const formData = new FormData();

    if (files?.length) {
      formData.append('files', files[0]);
      dispatch(uploadNewPhoto(formData));
    }
  };

  return (
    <PhotoUploaderStyled>
      <h2>{label || t('upload_photo')}</h2>
      {currentPhoto && <img src={currentPhoto} alt={currentPhoto} />}
      {isLoading && (
        <UploadingProgress>
          <Loader>
            <LoadIcon />
          </Loader>
          <UploadingText>
            <p>{t('editProfileSettings.uploadingPhotos')}</p>
            <p>{t('editProfileSettings.wait')}</p>
          </UploadingText>
        </UploadingProgress>
      )}
      {isError && (
        <UploadingProgressError>
          <Loader isError={isError}>
            <AttentionIcon />
          </Loader>
          <UploadingText isError={isError}>
            <p>{t('editProfileSettings.errorTitle')}</p>
            <p>{t('editProfileSettings.errorMessage')}</p>
          </UploadingText>
        </UploadingProgressError>
      )}
      <UploadWrapper>
        <SecondaryButton styleType="color">
          <label htmlFor="photo" />
          <input
            id="photo"
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={handleImageUpload}
            disabled={isLoading}
          />
          <UploadIcon />
          {t('editProfileSettings.uploadPhoto')}
        </SecondaryButton>
        <span>PNG, JPG, JPEG (max. 800x400px)</span>
      </UploadWrapper>
    </PhotoUploaderStyled>
  );
};

export default PhotoUploader;
