import styled from 'styled-components';

export const FilterSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  background: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const ControllerSection = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
  > div:first-child {
    min-width: 220px;
    max-width: 220px;
  }
  > div:last-child {
    width: 220px;
  }
`;
