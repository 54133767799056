import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export interface SecretaryClinicT {
  id: string;
  professionalIds: string[];
}

export const useClinicForm = ({ requiredError }: { requiredError: string }) => {
  const secretaryGeneralSchema = yup
    .object({
      id: yup.string().required(requiredError!),
      professionalIds: yup
        .array()
        .min(1, requiredError!)
        .required(requiredError!),
    })
    .required();

  const methods = useForm<SecretaryClinicT>({
    resolver: yupResolver(secretaryGeneralSchema),
  });

  return methods;
};
