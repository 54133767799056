import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { StyledTr } from './styles';

const NoDataElement = () => {
  const { t } = useTranslation();

  return (
    <StyledTr>
      <td colSpan={5}>
        <div>
          <PickIcon />
          <p>{t('subscriptions.no_subscribers')}</p>
        </div>
      </td>
    </StyledTr>
  );
};
export default NoDataElement;
