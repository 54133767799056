import { Box } from '@mui/material';
import styled from 'styled-components';
import { ProfessionalStatus } from '@docbay/schemas';

export const BadgeWrapper = styled(Box)<{
  status: ProfessionalStatus | string;
}>`
  background-color: ${({ theme }) => theme.color.errorBackground};
  color: ${({ theme }) => theme.color.error2};
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-radius: 100px;
  height: 32px;
  width: fit-content;
  min-width: 110px;
  white-space: nowrap;
`;
