import React, { FC } from 'react';
import { ReactComponent as Logo } from 'applicaiton/assets/logo-black.svg';
import { Wrapper, LogoWrapper } from './styles';
import AccordionProfiles from '../AccordionProfiles';

const MyProfile: FC = () => {
  return (
    <Wrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <AccordionProfiles />
    </Wrapper>
  );
};

export default MyProfile;
