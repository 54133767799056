import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { CountriesActions } from 'applicaiton/constants/actions';

import { CountriesAPI } from 'integration/api/countries';

export const getSupportedCountries = createAsyncThunk(
  CountriesActions.getSupportedCountries,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await CountriesAPI.getSupportedCountries();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
