import { Box } from '@mui/system';
import { styledTheme } from 'applicaiton/theme';
import styled from 'styled-components';

export const DivStyled = styled(Box)`
  width: fit-content;
`;

export const MenuItemStyled = styled('a')`
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  line-height: 22.4px;
  text-decoration: none;
  cursor: pointer;
  color: ${styledTheme.color.white};
  &:hover {
    color: ${styledTheme.color.blue200};
  }
`;

export const MenuItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 24px;
`;
