import React, { useEffect, useMemo, useState } from 'react';
import { SecondaryButton, PrimaryButton, Loader } from 'common/components';
import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  ScheduleFieldItem,
  ButtonWrapper,
  AddClinic,
  DividerStyled,
  ScheduleWrapper,
  StyledDivider,
  MainButtonsWrapper,
  Header,
} from './styles';
import Schedule from '../Schedule';
import { setProfileChanges } from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import ClinicDropdownSection from '../ClinicDropdownSection';
import { ClinicForm } from './modules';
import { useClinicForm } from '../../hooks/useClinicForm';
// import { ReactComponent as CopyIcon } from 'applicaiton/assets/content-copy.svg'; don't remove it
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import {
  ClinicProfessionalUpdateDto,
  ClinicResponseDto,
} from '@docbay/schemas';
import { getSchedule } from 'common/helpers/getSchedule';
import dayjs from 'dayjs';
import { ClinicProfessionalActiveToggle } from 'features/feature-professional-edit/components/ClinicProfessionalActiveToggle';
import { ProfessionalClinicRelationI } from 'features/feature-professional-edit/components/ClinicInformation/types';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { fetchClinics } from 'applicaiton/store/reducers/Clinics/ActionCreators';

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

const ClinicProfile = ({
  onNextBtnClick,
  onBackBtnClick,
  addClinic,
  arrayIndex,
  onRemoveClinic,
  isClinicOwnerProfile,
}: ClinicForm) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [chosenClinic, setChosenClinic] = useState<
    ClinicResponseDto | undefined
  >(undefined);

  const [clinicActiveRelations, setClinicActiveRelations] = useState<
    ProfessionalClinicRelationI[]
  >([]);

  const { clinics } = useAppSelector((state) => state.clinics);

  const { profileChanges, isLoading } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );

  const {
    setValue,
    errors,
    getValues,
    watch,
    handleSubmit,
    clearErrors,
    setError,
    reset,
  } = useClinicForm({
    requiredError: t('errors.required'),
  });

  const { isPrivateDoctor } = useClinicOwner();

  const allClinic = [...(profileChanges?.clinicsRelations || [])];
  const currentClinic = allClinic[arrayIndex - 1];

  useEffect(() => {
    if (isPrivateDoctor) {
      dispatch(fetchClinics());
    }
  }, [isPrivateDoctor]);

  // set default clinic value for private doctor
  useEffect(() => {
    if (isPrivateDoctor) {
      setValue('clinic', clinics[0]?.id);
    }
  }, [isPrivateDoctor, clinics]);

  const enabledWorkingHours =
    chosenClinic?.openingHours
      ?.filter((item) => item.isEnabled)
      .map((item) => ({
        ...item,
        day: t(`${item.day.toLocaleLowerCase()}`),
      })) || [];
  const allClinicsAdded = useMemo(() => {
    const addedClinics = clinics.filter((clinic) => {
      const clinicAlreadyAdded = allClinic.some(
        (item) => item.id === clinic.id,
      );
      if (clinicAlreadyAdded) return clinic;
    });
    return (
      addedClinics.length === clinics.length ||
      allClinic.length === clinics.length
    );
  }, [clinics, allClinic]);
  const canUserAddMoreClinic = clinics?.length > 1 && !allClinicsAdded;
  const getChosenClinic = (id: string) => {
    return clinics.find((clinic) => String(clinic.id) === id);
  };

  useEffect(() => {
    setChosenClinic(getChosenClinic(getValues('clinic')));
  }, [watch('clinic')]);

  useEffect(() => {
    if (currentClinic) {
      setValue('clinic', currentClinic.clinicId || '');
      setValue('specializationsToAdd', currentClinic.specializationsToAdd!);
      setValue('appointmentTypes', currentClinic?.appointmentTypesToAdd!);
      setChosenClinic(getChosenClinic(currentClinic.clinicId));
    } else {
      reset();
      dispatch(
        //adding new clinic for professional
        setProfileChanges({
          ...profileChanges!,
          clinicsRelations: [
            ...(profileChanges?.clinicsRelations || []),
            {
              clinicId: '',
              specializationsToAdd: [],
              appointmentTypesToAdd: [],
              schedule: {
                hours: getSchedule(),
              },
            },
          ],
        }),
      );
    }
  }, [currentClinic]);

  const getCurrentClinicData = () => {
    const { clinic, specializationsToAdd, hours, appointmentTypes } =
      getValues();

    const newClinicData: ClinicProfessionalUpdateDto = {
      ...currentClinic,
      schedule: {
        ...currentClinic.schedule,
        hours: hours,
      },
      appointmentTypesToAdd: appointmentTypes,
      clinicId: clinic,
      specializationsToAdd,
    };

    return newClinicData;
  };

  // ---- Don't remove it
  // const handleDuplicate = () => {
  //   if (!Object.keys(errors).length) {
  //     const newClinicData = getCurrentClinicData();

  //     allClinic.splice(arrayIndex - 1, 1, newClinicData);

  //     dispatch(
  //       setProfileChanges({
  //         ...profileChanges!,
  //         clinicsRelations: [...allClinic, newClinicData],
  //       }),
  //     );
  //     addClinic();
  //   }
  // };
  //------

  const handleBack = () => {
    const newClinicData = getCurrentClinicData();

    allClinic.splice(arrayIndex - 1, 1, newClinicData);

    dispatch(
      setProfileChanges({
        ...profileChanges!,
        clinicsRelations: [...allClinic],
      }),
    );

    onBackBtnClick();
  };

  const handleDelete = () => {
    const filteredClinics =
      allClinic.filter((_, index) => index + 1 !== arrayIndex) || [];
    dispatch(
      setProfileChanges({
        ...profileChanges!,
        clinicsRelations: filteredClinics,
      }),
    );

    onRemoveClinic(arrayIndex - 1);
  };

  const addNewClinic = () => {
    if (!Object.keys(errors).length) {
      const setClinicData = getCurrentClinicData();
      reset();
      allClinic.splice(arrayIndex - 1, 1, setClinicData);
      const defaultSchedule = getSchedule();

      const newClinicData: ClinicProfessionalUpdateDto = {
        schedule: {
          hours: defaultSchedule,
        },
        clinicId: '',
        specializationsToAdd: [],
        appointmentTypesToAdd: [],
      };

      dispatch(
        setProfileChanges({
          ...profileChanges!,
          clinicsRelations: [...allClinic, newClinicData],
        }),
      );

      addClinic();
    }
  };

  const updateClinicActiveRelations = (
    clinicProfessionalData: ClinicProfessionalUpdateDto[],
  ) => {
    clinicProfessionalData.forEach((relation) => {
      const updatedRelation = clinicActiveRelations.find(
        (item) => item.clinicId === relation.clinicId,
      );
      if (updatedRelation) {
        relation.active = updatedRelation.active;
      } else {
        relation.active = false;
      }
    });
  };

  const onSubmit = async () => {
    const newClinicData = getCurrentClinicData();
    allClinic.splice(+arrayIndex - 1, 1, newClinicData);
    updateClinicActiveRelations(allClinic);
    const setClinicData = {
      ...profileChanges,
      gender: profileChanges?.gender || Gender.Male,
      email: profileChanges?.email! || '',
      medicalNumber: profileChanges?.medicalNumber! || '',
      dateOfBirth: dayjs(profileChanges?.dateOfBirth).utc(true).format() || '',
      clinicsRelations: allClinic,
      // --- Will use in future ---
      // professionalActivityStartDate:
      //   profileChanges?.professionalActivityStartDate || '',
      // --------------------------
    };

    dispatch(setProfileChanges(setClinicData));
    onNextBtnClick();
  };

  const submitButtonText = useMemo(() => {
    if (isClinicOwnerProfile) {
      return t('save');
    }
    return t('next');
  }, [t, isClinicOwnerProfile]);

  const selectedClinics = useMemo(() => {
    return clinics.filter((clinic) => {
      const clinicAlreadyAdded = allClinic.some(
        (item) =>
          item.clinicId === clinic.id && clinic.id !== currentClinic?.clinicId,
      );
      if (clinicAlreadyAdded) return clinic;
    });
  }, [clinics, allClinic, currentClinic]);

  const clinicHeaderText = useMemo(() => {
    if (isPrivateDoctor) {
      return t('professional_profile.my_practice');
    }
    return arrayIndex === 1
      ? t('professional_profile.new_clinic')
      : `${t('professional_profile.new_clinic')} ${arrayIndex}`;
  }, [isPrivateDoctor, arrayIndex]);

  const showClinicActiveToggle = useMemo(() => {
    return !!chosenClinic && !isPrivateDoctor;
  }, [isPrivateDoctor, chosenClinic]);

  const isClinicActive = useMemo(() => {
    const selectedRelation = clinicActiveRelations.find(
      (item) => item.clinicId === chosenClinic?.id,
    );
    return !!selectedRelation?.active;
  }, [clinicActiveRelations, chosenClinic]);

  const toggleClinicActive = (isActive: boolean) => {
    if (!chosenClinic?.id) return;
    const newRelations = clinicActiveRelations.filter(
      (rel) => rel.clinicId !== chosenClinic.id,
    );
    setClinicActiveRelations([
      ...newRelations,
      { clinicId: chosenClinic?.id, active: isActive },
    ]);
  };

  if (!currentClinic) return <></>;

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Header>
        <h1>{clinicHeaderText}</h1>
        <div>
          {/*Don't remove it */}
          {/* <button type="button" onClick={handleSubmit(handleDuplicate)}>
            <CopyIcon />
            <h2>{t('professional_profile.duplicate_settings')}</h2>
          </button> */}
          {!isPrivateDoctor ? (
            <button type="button" onClick={handleDelete}>
              <DeleteIcon />
              <h2>{t('delete')}</h2>
            </button>
          ) : (
            <></>
          )}
        </div>
      </Header>
      {showClinicActiveToggle ? (
        <ClinicProfessionalActiveToggle
          isActive={isClinicActive}
          setIsActive={toggleClinicActive}
        />
      ) : (
        <></>
      )}
      <DividerStyled />
      <ClinicDropdownSection
        getValues={getValues}
        clearErrors={clearErrors}
        watch={watch}
        setValue={setValue}
        errors={errors}
        clinic={currentClinic}
        arrayIndex={arrayIndex}
        selectedClinics={selectedClinics}
        hideClinicDropdown={isPrivateDoctor}
      />
      <DividerStyled />
      <ScheduleFieldItem isError={!!errors?.hours}>
        <label>{t('professional_profile.agenda_hours')}</label>
        <ScheduleWrapper>
          <Schedule
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            arrayIndex={arrayIndex}
            clinicEnabledWorkingHours={enabledWorkingHours}
            chosenClinicName={chosenClinic?.name || ''}
          />
        </ScheduleWrapper>
      </ScheduleFieldItem>

      {canUserAddMoreClinic && (
        <ButtonWrapper>
          <SecondaryButton onClick={handleSubmit(addNewClinic)}>
            <AddClinic />
            {t('professional_profile.add_clinic')}
          </SecondaryButton>
        </ButtonWrapper>
      )}

      <StyledDivider />
      <MainButtonsWrapper>
        <SecondaryButton onClick={handleBack}>{t('back')}</SecondaryButton>
        <PrimaryButton
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={!!Object.keys(errors).length || isLoading}
        >
          {submitButtonText}
        </PrimaryButton>
      </MainButtonsWrapper>
    </Wrapper>
  );
};

export default ClinicProfile;
