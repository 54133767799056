import React, { useState, useMemo, useEffect } from 'react';
import {
  Wrapper,
  DataWrapper,
  DataSection,
  Information,
  Metrics,
  MetricsWrapper,
  EditorWrapper,
  ButtonWrapper,
  EmptyMetrics,
} from './styles';
import { useTranslation } from 'react-i18next';
import {
  InfinityScrollDropdown,
  Input,
  PrimaryButton,
  SuccessModal,
} from 'common/components';
import Editor from '../lexical';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  getConsultationData,
  getHealthMetricsBySpecialization,
  getSymptoms,
  saveConsultationData,
} from 'applicaiton/store/reducers/Consultation/ActionCreators';
import { useConsultationDataForm } from '../../hooks/useConsultationDataForm';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import { setSymptoms } from 'applicaiton/store/reducers/Consultation/ConsultationSlice';
import {
  CommonEntitiesListResponse,
  SymptomResponseDto,
} from '@docbay/schemas';
import { useLanguage } from 'common/hooks/useLanguage';
import Loader from 'common/components/Loader';
import { EmptyStateIcon } from 'applicaiton/assets';
import MetricsSection from '../MetricsSection';

const ConsultationData = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchItem, setSearchItem] = useState('');
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { localizeNameKey } = useLocalizeKey();
  const [notes, setNotes] = useState('');
  const [plan, setPlan] = useState('');
  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const dispatch = useAppDispatch();
  const { appointmentId } = useParams();

  const {
    symptoms,
    data,
    symptomsTotalPages,
    isLoading,
    isConsultationDataLoading,
    healthMetrics,
  } = useAppSelector((state) => state.consultationData);

  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const loading = isLoading || isConsultationDataLoading;

  useEffect(() => {
    dispatch(getConsultationData(appointmentId!));
  }, []);

  useEffect(() => {
    if (currentAppointment) {
      dispatch(
        getHealthMetricsBySpecialization(
          currentAppointment.appointmentType?.specialization?.id!,
        ),
      );
    }
  }, [currentAppointment]);

  const setConsultationData = () => {
    const symptomsIds =
      data?.symptoms?.map((symptom) => String(symptom.id)) || [];

    setValue('symptoms', symptomsIds! as string[], {
      shouldValidate: true,
    });
    setValue('reason', data?.reason || '');
    setNotes(data?.notes || '');
    setPlan(data?.treatmentPlan || '');
  };

  useEffect(() => {
    data && setConsultationData();
  }, [data]);

  const symptomsOptions = useMemo(() => {
    const options = symptoms?.map((item) => {
      const symptomsName = item[localizeNameKey];

      return {
        value: item.id,
        label: symptomsName as string,
      };
    });
    return options;
  }, [symptoms, localizeNameKey]);

  const getSymptomsData = () => {
    dispatch(
      getSymptoms({
        page: currentPage,
        limit: 10,
        languageIso: language,
        search: searchItem,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        const dataArr =
          data.payload as CommonEntitiesListResponse<SymptomResponseDto>;

        dispatch(
          setSymptoms(
            currentPage === 1
              ? dataArr?.entities!
              : [...symptoms, ...dataArr.entities!],
          ),
        );

        if (!dataArr?.entities.length) {
          setHasNextPage(!dataArr?.entities.length);
        }
      }
    });
  };

  useEffect(() => {
    if (currentPage <= symptomsTotalPages || symptomsTotalPages === 0) {
      getSymptomsData();
    }
  }, [currentPage, searchItem]);

  const handleGetNewData = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSearchData = (search: string) => {
    setSearchItem(search);
    setHasNextPage(true);
    setCurrentPage(1);
  };

  const {
    register,
    errors,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    reset,
  } = useConsultationDataForm();

  const onSubmit = () => {
    const { reason, symptoms, metric } = getValues();

    const filteredMetrics = metric?.filter((item) => {
      return typeof +item.value === 'number' && +item.value > 0;
    });

    const metricData = filteredMetrics?.map((item) => {
      return {
        healthMetricId: item.metricId!,
        value: +item.value!,
      };
    });

    dispatch(
      saveConsultationData({
        appointmentId: appointmentId!,
        data: {
          reason,
          symptomIds: symptoms,
          notes,
          treatmentPlan: plan,
          healthMetrics: metricData || [],
        },
      }),
    ).then((result) => {
      if (result.meta.requestStatus === 'fulfilled') {
        setSuccessModalOpened(true);
        reset();
      }
    });
  };

  return (
    <Wrapper>
      {loading && <Loader />}
      <DataWrapper>
        <DataSection>
          <Information>
            <h3>{t('consultation_data.consultation_information')}</h3>
            <Input
              register={register}
              type={'text'}
              id={'reason'}
              label={t('consultation_data.reasons_for_consultation') || ''}
              placeholder={t('consultation_data.reason_placeholder') || ''}
            />
            <InfinityScrollDropdown
              id={'symptoms'}
              label={t('consultation_data.symptoms') || ''}
              placeholder={t('consultation_data.select_symptoms')}
              onChange={(value) => {
                setValue('symptoms', value as string[], {
                  shouldValidate: true,
                });
              }}
              value={(watch('symptoms') as string[]) || []}
              options={symptomsOptions}
              isMulti
              withSearch
              getNewData={handleGetNewData}
              hasNextPage={hasNextPage}
              onSearchData={handleSearchData}
            />
            {!isConsultationDataLoading && (
              <>
                <EditorWrapper>
                  <h3>{t('consultation_data.notes')}</h3>
                  <Editor
                    editorKey={'notes'}
                    value={notes || ''}
                    onChange={(val) => {
                      setNotes(val);
                    }}
                    hasError={false}
                  />
                </EditorWrapper>
                <EditorWrapper>
                  <h3>{t('consultation_data.treatment_plan')}</h3>
                  <Editor
                    editorKey={'plan'}
                    value={plan || ''}
                    onChange={(val) => {
                      setPlan(val);
                    }}
                    hasError={false}
                  />
                </EditorWrapper>
              </>
            )}
          </Information>
          <Metrics>
            <h3>{t('consultation_data.health_metrics')}</h3>
            <MetricsWrapper>
              {!healthMetrics.length ? (
                <EmptyMetrics>
                  <EmptyStateIcon />
                  <p>{t('consultation_data.empty_health_metrics')}</p>
                </EmptyMetrics>
              ) : (
                <MetricsSection
                  errors={errors}
                  control={control}
                  register={register}
                  getValues={getValues}
                />
              )}
            </MetricsWrapper>
          </Metrics>
        </DataSection>
        <ButtonWrapper>
          <PrimaryButton onClick={handleSubmit(onSubmit)}>
            {t('save')}
          </PrimaryButton>
        </ButtonWrapper>
      </DataWrapper>
      <SuccessModal
        isOpen={successModalOpened}
        onClose={() => {
          dispatch(getConsultationData(appointmentId!));
          setSuccessModalOpened(false);
        }}
        message={t('consultation_data.success_modal.message') || ''}
        closeButtonText={t('consultation_data.success_modal.buttonText') || ''}
      />
    </Wrapper>
  );
};

export default ConsultationData;
