import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;

  > span {
    background: ${({ theme }) => theme.color.grey200};
    padding: 6px 12px;
    gap: 4px;
    border-radius: 100px;
    white-space: nowrap;
  }

  > svg {
    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.color.grey500};
    }
  }
`;
