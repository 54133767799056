import {
  AuthLocalStorage,
  getProfilesFromSessionStorage,
  getToken,
  Profile,
} from 'applicaiton/sessionStorage/auth';
import axios from 'axios';
import { usersRefreshToken } from 'applicaiton/store/reducers/Users/ActionCreators';
import dayjs from 'dayjs';
import { store } from 'applicaiton/store';
import { ApplicationType, x_app_type } from '../../applicaiton';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config) {
    const profile: Profile[] = getProfilesFromSessionStorage()!;
    const date = localStorage.getItem(AuthLocalStorage.accessTokenExpiresAt);
    const minusMinuteDate = date
      ? dayjs(date).subtract(59, 'minute').toISOString()
      : null;
    const isDateValid =
      dayjs().isValid() && dayjs(minusMinuteDate).diff(dayjs(), 'minute') <= 59;
    const isExpiredToken = minusMinuteDate
      ? dayjs().isAfter(minusMinuteDate)
      : false;

    if (!isDateValid || isExpiredToken) {
      if (!profile?.length) return;

      const data = await store.dispatch(
        usersRefreshToken({
          refreshToken: profile[0].refreshToken,
          email: profile[0].email,
        }),
      );

      if (data && data.meta.requestStatus === 'fulfilled') {
        return {
          ...config,
          ...{
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-type': 'Application/json',
              Authorization: `Bearer ${getToken()}`,
              [x_app_type]: ApplicationType.ProfessionalWeb,
            },
          },
        };
      }
    }

    // Do something before request is sent

    const token = getToken();
    if (!token) return;

    return {
      ...config,
      ...(token
        ? {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-type': 'Application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        : {}),
    };
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export default axiosInstance;
