import React, { FC } from 'react';
import { AuthPageWrapper } from '../AuthPageLayout';
import { ForgotPasswordPage } from 'features';

export const ForgotPassword: FC = () => {
  return (
    <AuthPageWrapper>
      <ForgotPasswordPage />
    </AuthPageWrapper>
  );
};
