import React, { FC } from 'react';
import { StyledModal } from './styles';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { SecondaryButton } from 'common/components';
import { ReactComponent as SuccesslIcon } from 'applicaiton/assets/success-icon.svg';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
require('dayjs/locale/pt');
require('dayjs/locale/fr');

interface SuccessModalProps {
  onClose: () => void;
  patientName: string;
}

const SuccessDeletedModal: FC<SuccessModalProps> = ({
  onClose,
  patientName,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccesslIcon />
          <p>
            {t('appointment.delete_type.success.titlePart1')}{' '}
            <span>{patientName}</span>{' '}
            {t('appointment.delete_type.success.titlePart2')}
          </p>
          <SecondaryButton onClick={onClose}>
            {t('appointment.delete_type.success.ok')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessDeletedModal;
