import React, { FC, useEffect, useState } from 'react';
import AudioTrack from './AudioTrack';
import VideoTrack from './VideoTrack';
import { useTrack } from '../hooks';
import { IconStyled, ParticipantWrapper, ScreenStyled } from './styles';
import {
  getProfilesFromSessionStorage,
  getUserId,
} from 'applicaiton/sessionStorage/auth';
import { ParticipantProps } from 'features/feature-teleconsultation/types';

const Participant: FC<ParticipantProps> = ({
  participant,
  isRemoteParticipant,
  patientInitials,
}) => {
  const { videoOn, audioOn, videoTrack, audioTrack } = useTrack({
    participant,
  });
  const userId = getUserId();
  const profiles = getProfilesFromSessionStorage();
  const [width, setWidth] = useState<string>('100%');

  const currentProfile = profiles?.find((item) => item.id === userId);
  const userInitials = `${currentProfile?.firstName.slice(
    0,
    1,
  )}${currentProfile?.lastName.slice(0, 1)}`;
  const wrapper = document.getElementById(`video-${participant?.sid}`);

  useEffect(() => {
    //calc width screen by height
    setWidth(
      wrapper?.offsetHeight
        ? `${wrapper?.offsetHeight * 1.77 + 100}px`
        : '100%',
    );
  }, [wrapper?.offsetHeight]);

  return (
    <ParticipantWrapper
      style={{ maxWidth: width }}
      id={`video-${participant?.sid}`}
    >
      {videoOn ? (
        <VideoTrack videoTrack={videoTrack} />
      ) : (
        <ScreenStyled isRemoteParticipant={!!isRemoteParticipant}>
          {isRemoteParticipant ? (
            <IconStyled isRemoteParticipant={!!isRemoteParticipant}>
              <p>{patientInitials?.toUpperCase()}</p>
            </IconStyled>
          ) : (
            <>
              {currentProfile?.photo ? (
                <img
                  src={currentProfile?.photo.thumbnailUrl}
                  alt={currentProfile?.photo.key}
                />
              ) : (
                <IconStyled isRemoteParticipant={!!isRemoteParticipant}>
                  <p>{userInitials?.toUpperCase()}</p>
                </IconStyled>
              )}
            </>
          )}
        </ScreenStyled>
      )}
      <br />
      {audioOn ? <AudioTrack audioTrack={audioTrack} /> : ''}
    </ParticipantWrapper>
  );
};

export default Participant;
