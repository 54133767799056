import styled from 'styled-components';

interface Props {
  isActive?: boolean;
}

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.blue800 : theme.color.grey100};
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.white : theme.color.grey800};
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.grey100 : theme.color.grey600};
    }
  }
`;

export const Notes = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-family: Mulish;
    font-weight: 700;
    font-size: 16px;
    line-height: 22.4px;
  }
`;

export const ItemStyled = styled.div<Props>`
  display: flex;
  align-items: center;

  span {
    color: ${({ theme, isActive }) =>
      isActive ? theme.color.white : theme.color.grey500};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin: 0 6px 0 8px;
  }

  p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    min-width: 20px;
    height: 20px;
    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.white : theme.color.grey600}
  }
`;

export const ItemTeleconsultationStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  min-width: 400px;
  max-width: 400px;

  span {
    color: ${({ theme, isActive }) =>
      isActive ? theme.color.white : theme.color.grey500};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin: 0 6px 0 8px;
  }

  p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    min-width: 20px;
    height: 20px;
    path {
      fill: ${({ theme }) => theme.color.white}
  }
`;
