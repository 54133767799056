import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';
import { Wrapper, DividerStyled, ButtonWrapper } from './styles';
import EmailChangingPopUp from '../EmailChangingPopUp';
import { professionalVerifyOldEmail } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import ChangeEmailModal from '../ChangeEmailModal';
import { useEmailForm } from 'features/feature-professional-edit/hooks/useEmailForm';
import ButtonSectionContacts from '../ButtonSectionContacts';

const Email: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { editedProfessional, isVerifiedOldEmail, error } = useAppSelector(
    (state) => state.professionalProfile,
  );

  const [showEmailPopUp, setShowEmailPopUp] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);

  const { register, errors, handleSubmit, watch, setValue } = useEmailForm({
    requiredError: t('errors.required'),
  });

  useEffect(() => {
    return () => {
      dispatch(professionalProfileActions.rersetProfessionalError());
    };
  }, []);

  useEffect(() => {
    setValue('email', editedProfessional?.user?.email || '');
  }, [editedProfessional]);

  const handleCloseModal = () => {
    isEmailExist && setIsEmailExist(false);
    setShowEmailPopUp(false);
  };

  const handleCloseEmailChangeModal = () => {
    setValue('email', editedProfessional?.user?.email || '');
    dispatch(professionalProfileActions.resetEmailData());
  };

  const handleOpenForm = () => {
    sessionStorage.setItem('newEmail', watch('email'));
    sessionStorage.setItem('oldEmail', String(editedProfessional?.user?.email));
    setShowEmailPopUp(false);

    dispatch(
      professionalVerifyOldEmail({
        email: String(editedProfessional?.user?.email),
      }),
    );
  };

  const onSubmit = () => {
    setShowEmailPopUp(true);
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Input
        id="email"
        type={'email'}
        label={t('email') || ''}
        register={register}
        errorMessage={
          errors.email?.message || (error && t('error_attempt_limit')) || ''
        }
      />
      <ButtonWrapper>
        <DividerStyled />
        <ButtonSectionContacts
          disabled={
            !!Object.keys(errors).length ||
            watch('email') === editedProfessional?.user?.email
          }
        />
      </ButtonWrapper>
      {showEmailPopUp && (
        <EmailChangingPopUp
          onClose={handleCloseModal}
          onSubmit={handleOpenForm}
        />
      )}
      {isVerifiedOldEmail && (
        <ChangeEmailModal
          isOpen={isVerifiedOldEmail}
          onClose={handleCloseEmailChangeModal}
        />
      )}
    </Wrapper>
  );
};

export default Email;
