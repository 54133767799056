import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RefreshIcon } from 'applicaiton/assets/refresh.svg';
import { PrimaryButton } from 'common/components';
import { RefreshAgendaModalProps } from './modules';
import { StyledModal } from './styles';

const RefreshAgendaModal: FC<RefreshAgendaModalProps> = ({
  opened,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={opened} onClose={() => {}}>
      <StyledModal>
        <RefreshIcon />
        <h1>{t('please_refresh_agenda')}</h1>
        <PrimaryButton type="button" onClick={onSubmit}>
          {t('refresh_agenda')}
        </PrimaryButton>
      </StyledModal>
    </Modal>
  );
};

export default RefreshAgendaModal;
