import React, { useEffect } from 'react';
import { Wrapper, DataWrapper, DataSection, DataChipWrapper } from './styles';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { useTranslation } from 'react-i18next';
import DataChip from '../DataChip';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  getPatientMedicalBackground,
  removePatientAllergy,
  removePatientDisease,
  setPatientAllergy,
  setPatientDisease,
} from 'applicaiton/store/reducers/Consultation/ActionCreators';
import AddMedicalDataModal from '../AddMedicalDataModal';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import AddFamilyHistoric from '../AddFamilyHistoric';
import AddSurgeries from '../AddSurgeries';

const MedicalBackground = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [allergiesModalOpen, setAllergiesModalOpen] = React.useState(false);
  const [diseasesModalOpen, setDiseasesModalOpen] = React.useState(false);

  const language = getLanguageFromLocalStorage();
  const localizeKey = `name_${language.toUpperCase()}` as 'name_EN' | 'name_PT';

  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const { patientMedicalBackground } = useAppSelector(
    (state) => state.consultationData,
  );

  const updateMedicalBackground = () => {
    dispatch(getPatientMedicalBackground(currentAppointment?.patient?.id!));
  };

  useEffect(() => {
    currentAppointment && updateMedicalBackground();
  }, [currentAppointment]);

  const handleAddAllergy = (id: string) => {
    dispatch(
      setPatientAllergy({
        patientId: currentAppointment?.patient?.id!,
        allergyId: id,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setAllergiesModalOpen(false);
        updateMedicalBackground();
      }
    });
  };

  const handleRemoveAllergy = (id: string) => {
    dispatch(
      removePatientAllergy({
        patientId: currentAppointment?.patient?.id!,
        allergyId: id,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setAllergiesModalOpen(false);
        updateMedicalBackground();
      }
    });
  };

  const handleAddDisease = (id: string) => {
    dispatch(
      setPatientDisease({
        patientId: currentAppointment?.patient?.id!,
        diseaseId: id,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setDiseasesModalOpen(false);
        updateMedicalBackground();
      }
    });
  };

  const handleRemoveDisease = (id: string) => {
    dispatch(
      removePatientDisease({
        patientId: currentAppointment?.patient?.id!,
        diseaseId: id,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setDiseasesModalOpen(false);
        updateMedicalBackground();
      }
    });
  };

  return (
    <Wrapper>
      <DataWrapper>
        <DataSection>
          <h6>{t('consultation_data.allergies')}</h6>
          <DataChipWrapper>
            {!!patientMedicalBackground?.allergies.length &&
              patientMedicalBackground.allergies.map((item) => {
                return (
                  <DataChip
                    key={`allergy_${item.id}`}
                    id={item?.id}
                    name={item[localizeKey]}
                    onDelete={handleRemoveAllergy}
                  />
                );
              })}
          </DataChipWrapper>
          <button type="button" onClick={() => setAllergiesModalOpen(true)}>
            <AddIcon />
            <h2>{t('consultation_data.add_allergy')}</h2>
          </button>
        </DataSection>
        <DataSection>
          <h6>{t('consultation_data.chronic_diseases')}</h6>
          <div>
            {!!patientMedicalBackground?.diseases.length &&
              patientMedicalBackground.diseases.map((item) => {
                return (
                  <DataChip
                    key={`disease_${item.id}`}
                    id={item?.id}
                    name={item[localizeKey]}
                    onDelete={handleRemoveDisease}
                  />
                );
              })}
          </div>
          <button type="button" onClick={() => setDiseasesModalOpen(true)}>
            <AddIcon />
            <h2>{t('consultation_data.add_disease')}</h2>
          </button>
        </DataSection>
        <AddFamilyHistoric updateMedicalBackground={updateMedicalBackground} />
        <AddSurgeries updateMedicalBackground={updateMedicalBackground} />
      </DataWrapper>
      {allergiesModalOpen && (
        <AddMedicalDataModal
          header={t('consultation_data.add_allergy_header')}
          placeholder={t('consultation_data.add_allergy_placeholder')}
          title={t('consultation_data.add_allergy_title')}
          onClose={() => setAllergiesModalOpen(false)}
          onAdd={handleAddAllergy}
          isAllergy
        />
      )}
      {diseasesModalOpen && (
        <AddMedicalDataModal
          header={t('consultation_data.add_disease_header')}
          placeholder={t('consultation_data.add_disease_placeholder')}
          title={t('consultation_data.add_disease_title')}
          onClose={() => setDiseasesModalOpen(false)}
          onAdd={handleAddDisease}
        />
      )}
    </Wrapper>
  );
};

export default MedicalBackground;
