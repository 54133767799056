import { Box, styled } from '@mui/system';
import { styledTheme } from 'applicaiton/theme';

export const TimerWrapper = styled(Box)`
  > span {
    font: 400 16px/22.4px 'Mulish', sans-serif;
  }
  span:first-of-type {
    color: ${styledTheme.color.grey800};
  }
  span:last-child {
    color: ${styledTheme.color.green600};
    font-weight: 700;
  }
`;
