import styled from 'styled-components';

export const MetricItem = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  > span {
    font-family: Mulish;
    font-weight: 500;
    font-size: 16px;
    line-height: 44px;
    color: ${({ theme }) => theme.color.grey500};
  }

  input {
    width: 192px;
  }
`;
