import React from 'react';

import { ContactsType } from 'common/types/clinics';

import { ReactComponent as TikTkIcon } from 'applicaiton/assets/social/tik-tok.svg';
import { ReactComponent as TwitterIcon } from 'applicaiton/assets/social/twitter.svg';
import { ReactComponent as FacebookIcon } from 'applicaiton/assets/social/facebook.svg';
import { ReactComponent as LinkedinIcon } from 'applicaiton/assets/social/linkedin.svg';
import { ReactComponent as InstagramIcon } from 'applicaiton/assets/social/instagram.svg';

export type SocialDataType = {
  [key in ContactsType]: JSX.Element;
};

export const socialIcons = {
  Facebook: <FacebookIcon />,
  Instagram: <InstagramIcon />,
  Linkedin: <LinkedinIcon />,
  Tiktok: <TikTkIcon />,
  Twitter: <TwitterIcon />,
} as SocialDataType;
