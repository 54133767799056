import React, { FC } from 'react';
import { AuthPageWrapper } from '../AuthPageLayout';
import { ConfirmPasswordChangePage } from 'features';

export const ConfirmPasswordChange: FC = () => {
  return (
    <AuthPageWrapper>
      <ConfirmPasswordChangePage />
    </AuthPageWrapper>
  );
};
