import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export interface GeneralTypeInfoT {
  title: string;
  clinicIds: string;
}

export const useAddTopicForm = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const addTopicSchema = yup
    .object({
      title: yup.string().required(),
      clinicIds: yup.string().required(requiredError!),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<GeneralTypeInfoT>({
    resolver: yupResolver(addTopicSchema),
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    setError,
  };
};
