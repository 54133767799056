import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccesslIcon } from 'applicaiton/assets/success-icon.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';

interface SuccessModalProps {
  typeName: string;
  onClose: () => void;
}

const SuccessModal: FC<SuccessModalProps> = ({ typeName, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccesslIcon />
          <p>
            {t('users.modal_success.title_part1')} <span>{typeName}</span>{' '}
            {t('users.modal_success.title_part2')}
          </p>
          <SecondaryButton onClick={onClose}>
            {t('users.modal_success.ok')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessModal;
