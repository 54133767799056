import styled from 'styled-components';
import { commonColors } from 'applicaiton/theme';

interface Props {
  isError?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background: ${commonColors.grey50};

  > h1 {
    color: ${commonColors.grey800};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 33.6px;
    letter-spacing: 0.12px;
  }
`;

export const FieldsSection = styled.div`
  width: 470px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > h1 {
    color: ${commonColors.grey800};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.1px;
  }
`;

export const RadioButtonSection = styled.section<Props>`
  display: grid;
  width: 100%;
  gap: 12px;
  margin-bottom: 24px;

  > p {
    color: ${({ theme, isError }) =>
      isError ? theme.color.error2 : theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.error2};
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  background-color: ${commonColors.grey200};
`;

export const DropdownList = styled.section<Props>`
  display: grid;
  width: 492px;

  > label {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.08px;
    margin-bottom: 6px;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error2 : theme.color.grey600};
  }

  .optionList {
    top: 44px;
  }

  button {
    height: 100%;
    display: flex;
    border: none;
    background: none;
    padding: 0;
    color: ${({ theme }) => theme.color.grey600};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: 0.07px;
    cursor: pointer;

    > svg {
      width: 20px;
      height: 20px;
      cursor: pointer;

      g {
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
      > svg {
        g {
          path {
            fill: ${({ theme }) => theme.color.grey400};
          }
        }
      }
      > h2 {
        color: ${({ theme }) => theme.color.grey400};
      }
    }
  }
`;

export const Specializations = styled.div`
  display: grid;

  > div {
    display: flex;
    margin-bottom: 12px;
    button {
      > svg {
        margin-left: 8px;
        cursor: pointer;
      }
    }
    > div {
      width: 470px;
    }
  }
`;

export const ScheduleFieldItem = styled.div<Props>`
  margin-bottom: 24px;

  label {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error2 : theme.color.grey600};
    margin-bottom: 16px;
  }
`;

export const ScheduleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;
