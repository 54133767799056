import React, { FC, useState } from 'react';
import {
  ModalStyled,
  ButtonWrapper,
  AddDocument,
  Loader,
  UploadingProgress,
  UploadingText,
  ImageWrapper,
} from './styles';
import { PrimaryButton, SecondaryButton } from '../index';
import Modal from '@mui/material/Modal';
import { DocumentsUploadProps } from './models';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoadIcon } from 'applicaiton/assets/loader.svg';
import { ReactComponent as AttentionIcon } from 'applicaiton/assets/attention.svg';
import { ReactComponent as ImageIcon } from 'applicaiton/assets/document-types/img.svg';
import { ReactComponent as PdfIcon } from 'applicaiton/assets/document-types/pdf.svg';
import { ReactComponent as DocumentIcon } from 'applicaiton/assets/document-types/doc.svg';
import { ReactComponent as ExelIcon } from 'applicaiton/assets/document-types/exel.svg';

const DocumentsUploadModal: FC<DocumentsUploadProps> = ({
  isOpen,
  onClose,
  onSave,
  isLoading = false,
  error = false,
  extantions = '.jpg, .jpeg, .png, .pdf, .doc, .docx, .odt',
}) => {
  const { t } = useTranslation();
  const [documentData, setDocumentData] = useState<FormData | null>();
  const [files, setFiles] = useState<File | null>(null);

  const handleCloseModal = () => {
    setFiles(null);
    setDocumentData(null);
    onClose();
  };

  const handleSaveDocuments = () => {
    onSave(documentData!);
    setFiles(null);
    setDocumentData(null);
  };

  const getCurrentIcon = () => {
    if (
      files?.name.includes('.jpg') ||
      files?.name.includes('.jpeg') ||
      files?.name.includes('.png')
    ) {
      return <ImageIcon />;
    } else if (files?.name.includes('.pdf')) {
      return <PdfIcon />;
    } else if (files?.name.includes('.xlsx')) {
      return <ExelIcon />;
    } else {
      return <DocumentIcon />;
    }
  };

  return (
    <Modal open={isOpen}>
      <ModalStyled>
        <div className={'content-wrapper'}>
          <h6>{t('upload_modal.title')}</h6>

          <AddDocument isLoading={isLoading}>
            <div className={isLoading ? 'disabled' : ''}>
              <label htmlFor="photo" />
              <input
                id="photo"
                type="file"
                accept={extantions}
                onChange={(e) => {
                  const filesArr = e.target.files!;
                  const file = filesArr[0];
                  const formData = new FormData();

                  formData.append('documents', file);
                  setFiles(file!);
                  setDocumentData(formData);
                }}
                disabled={isLoading}
              />
              {files ? (
                <ImageWrapper>
                  {!!error && (
                    <UploadingProgress>
                      <Loader isError={!!error}>
                        <AttentionIcon />
                      </Loader>
                    </UploadingProgress>
                  )}
                  {isLoading ? (
                    <UploadingProgress>
                      <Loader>
                        <LoadIcon />
                      </Loader>
                    </UploadingProgress>
                  ) : (
                    getCurrentIcon()
                  )}
                  {files?.name}
                </ImageWrapper>
              ) : (
                t('upload_modal.import_file')
              )}
            </div>
          </AddDocument>
          {error ? (
            <UploadingText isError={!!error}>
              <p>{t('editProfileSettings.errorTitle')}</p>
              <p>{t('upload_modal.error')}</p>
            </UploadingText>
          ) : (
            <UploadingText isError={!!error}>
              <p>{extantions} (max. 40 MB)</p>
            </UploadingText>
          )}
        </div>
        <ButtonWrapper>
          <SecondaryButton onClick={handleCloseModal}>
            {t('cancel')}
          </SecondaryButton>
          <PrimaryButton onClick={handleSaveDocuments} disabled={isLoading}>
            {t('save')}
          </PrimaryButton>
        </ButtonWrapper>
      </ModalStyled>
    </Modal>
  );
};

export default DocumentsUploadModal;
