import { commonColors } from 'applicaiton/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  gap: 20px;
  padding: 16px;
  overflow-y: auto;

  > h2 {
    color: ${commonColors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.16px;
  }
`;

export const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;

  text-decoration: none;
  color: ${commonColors.blue800};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.08px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 4px;

    path {
      fill: ${commonColors.blue800};
    }
  }
`;
