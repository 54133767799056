import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import TimePicker from 'common/components/TimePicker';
import { TimeWrapper, Wrapper, DividerStyled } from './styles';
import { HoursRangeProps } from './modules';
import { TimeSlots } from 'common/types/clinics';

const HoursRange: FC<HoursRangeProps> = ({
  defaultDate,
  slots,
  handleEditSlot,
  handleAddSlot,
  handleRemoveSlot,
}) => {
  const { t } = useTranslation();
  const getMinStartTime = (startTime: string, index: number) => {
    if (!slots.length || index === 0) return null;

    return dayjs(
      !startTime
        ? `${dayjs().format('YYYY-MM-DD')}T${slots[index - 1].endTime}`
        : `${defaultDate}T${slots[index - 1].endTime}`,
    );
  };

  const getMinEndTime = (startTime: string) => {
    const incrementedTime = dayjs(startTime).minute() + 5;
    return dayjs(startTime).set('minutes', incrementedTime);
  };

  const isInvalidValue = (index: number, time: string) => {
    return slots.some((slot, i) => {
      if (i !== index) {
        if (slot.startTime === '' || slot.endTime === '') {
          return true;
        }

        return (slot.startTime || '') < time && (slot.endTime || '') > time;
      } else {
        if (slot.startTime === '' || slot.endTime === '') {
          return true;
        }
      }

      return false;
    });
  };

  const handleChange = (
    value: string | null,
    index: number,
    key: keyof TimeSlots,
  ) => {
    handleEditSlot(index, {
      [key]: value ? dayjs(value).format('HH:mm') : '',
    });
  };

  return (
    <Wrapper>
      <div>
        {slots.map((item, index) => (
          <TimeWrapper key={index}>
            <TimePicker
              defaultValue={dayjs(`${defaultDate}T${item.startTime}`)}
              value={
                item.startTime
                  ? dayjs(`${defaultDate}T${item.startTime}`)
                  : null
              }
              onChange={(value) => handleChange(value, index, 'startTime')}
              minTime={getMinStartTime(item.startTime, index)}
              isError={
                isInvalidValue(index, item.startTime!) || !item.startTime
              }
            />
            <DividerStyled />
            <TimePicker
              defaultValue={dayjs(defaultDate + item.endTime)}
              value={item.endTime ? dayjs(defaultDate + item.endTime) : null}
              minTime={getMinEndTime(`${defaultDate}T${item.startTime}`)}
              maxTime={dayjs(defaultDate).hour(23).minute(59)}
              onChange={(value) => handleChange(value, index, 'endTime')}
              isError={isInvalidValue(index, item.endTime!) || !item.endTime}
            />
            <button onClick={() => handleRemoveSlot(index)}>
              <CloseIcon />
            </button>
          </TimeWrapper>
        ))}
        <button type="button" onClick={() => handleAddSlot()}>
          <AddIcon />
          <h2>{t('add_another')}</h2>
        </button>
      </div>
    </Wrapper>
  );
};

export default HoursRange;
