import { commonColors } from 'applicaiton/theme';
import styled from 'styled-components';

export const ModalStyled = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 15px 30px 15px 102px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 8px;
  background-color: ${commonColors.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);
`;
