import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ProfessionalCreationDto,
  ProfessionalRestoreRequestDto,
  ProfessionalSetPasswordDto,
  ProfessionalSetPasswordResDto,
  ProfessionalUpdateDto,
  SupportedLanguageIso,
  UserChangeEmailDto,
  UserChangePhoneDto,
  UserRequestChangePhoneDto,
  UserVerifyCodeDto,
} from '@docbay/schemas';
import { ProfessionalsActions } from 'applicaiton/constants/actions';
import { ProfessionalsAPI } from 'integration/api/professionals';
import { fetchProfessionalsProps } from 'common/types/professionals';
import { AxiosError } from 'axios';
import { setIsVerificationCodeExpired } from './ProfessionalsSlice';
import {
  getToken,
  setProfilesInSessionStorage,
  setUsersAuthSessionStorage,
} from 'applicaiton/sessionStorage/auth';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { UsersAPI } from 'integration/api/users';
import { ProfessionalsSubscribersParams } from './models';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const fetchProfessionals = createAsyncThunk(
  ProfessionalsActions.fetchProfessionals,
  async (params: fetchProfessionalsProps, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.fetchProfessionals(params);

      await delay(1000);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchProfessionalsForAutosuggest = createAsyncThunk(
  ProfessionalsActions.fetchProfessionalsForAutosuggest,
  async (params: fetchProfessionalsProps, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.fetchProfessionals(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchProfessionalById = createAsyncThunk(
  ProfessionalsActions.fetchProfessionalById,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.fetchProfessionalById(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteProfessionals = createAsyncThunk(
  ProfessionalsActions.deleteProfessional,
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.removeProfessional(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createProfessional = createAsyncThunk(
  ProfessionalsActions.createProfessional,
  async (professional: ProfessionalCreationDto, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.createProfessional(professional);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateProfessional = createAsyncThunk(
  ProfessionalsActions.updateProfessional,
  async (
    params: { id: string; professional: Partial<ProfessionalUpdateDto> },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ProfessionalsAPI.updateProfessional(
        params.id,
        params.professional,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const resendProfessionalAuthorizationData = createAsyncThunk(
  ProfessionalsActions.resendAuthorizationData,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.resendAuthorizationData(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const checkProfessionalVerificationCode = createAsyncThunk(
  ProfessionalsActions.checkProfessionalVerificationCode,
  async (
    params: { email: string; code: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await ProfessionalsAPI.checkProfessionalVerificationCode(
        params.email,
        params.code,
      );
      dispatch(setIsVerificationCodeExpired(false));
    } catch (e) {
      dispatch(setIsVerificationCodeExpired(true));

      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createProfessionalPassword = createAsyncThunk(
  ProfessionalsActions.createProfessionalPassword,
  async (params: ProfessionalSetPasswordDto, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.createProfessionalPassword(
        params,
      );
      const professionalProfile: ProfessionalSetPasswordResDto = data;
      const { token, expiresAt, refreshToken, user } = professionalProfile;

      setUsersAuthSessionStorage({ token, expiresAt, refreshToken, user });
      setProfilesInSessionStorage([
        {
          id: user?.id!,
          email: user?.email!,
          firstName: user?.firstName!,
          lastName: user?.lastName!,
          photo: user?.photo!,
          token,
          refreshToken,
        },
      ]);
      return professionalProfile.professional;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateProfessionalEmail = createAsyncThunk(
  ProfessionalsActions.updateProfessionalEmail,
  async (params: { id: string; email: string }, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.updateProfessionalEmail(
        params.id,
        params.email,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
export const fetchProfessionalsSpokenLanguages = createAsyncThunk(
  ProfessionalsActions.updateProfessionalEmail,
  async (_, { rejectWithValue }) => {
    try {
      const { data } =
        await ProfessionalsAPI.fetchProfessionalsSpokenLanguages();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createClinicOwnerProfessional = createAsyncThunk(
  `professionals/${ProfessionalsActions.createClinicOwnerProfessional}`,
  async (profile: ProfessionalCreationDto, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.createClinicOwnerProfessional(
        profile,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const professionalVerifyOldEmail = createAsyncThunk(
  ProfessionalsActions.professionalVerifyOldEmail,
  async (params: { email: string }, { rejectWithValue }) => {
    try {
      const token = getToken();
      const language = getLanguageFromLocalStorage();
      const { data } = await UsersAPI.userVerifyOldEmail(
        { ...params, language },
        token,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const professionalVerifyCode = createAsyncThunk(
  ProfessionalsActions.professionalVerifyCode,
  async (params: UserVerifyCodeDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersVerifyCode(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const professionalSetNewEmail = createAsyncThunk(
  ProfessionalsActions.professionalSetNewEmail,
  async (params: UserChangeEmailDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const { data } = await UsersAPI.userSetNewEmail(params, token);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const restoreClinicOwnerProfessional = createAsyncThunk(
  ProfessionalsActions.restoreClinicOwnerProfessional,
  async (params: ProfessionalRestoreRequestDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const { data } = await ProfessionalsAPI.restoreClinicOwnerProfessional(
        params,
        token,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const professionalVerifyPhone = createAsyncThunk(
  ProfessionalsActions.professionalVerifyPhone,
  async (params: UserRequestChangePhoneDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const language = getLanguageFromLocalStorage();

      const { data } = await UsersAPI.userPhoneVerificationCode(
        { ...params, language: language as SupportedLanguageIso },
        token,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const professionalSetNewPhone = createAsyncThunk(
  ProfessionalsActions.professionalSetNewPhone,
  async (params: UserChangePhoneDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const { data } = await UsersAPI.userSetNewPhone(params, token);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchProfessionalsSubscribers = createAsyncThunk(
  ProfessionalsActions.fetchProfessionalsSubscribers,
  async (
    { id, params }: { id: string; params: ProfessionalsSubscribersParams },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ProfessionalsAPI.fetchPurchasedSubscription(
        id,
        params,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchSubscriptions = createAsyncThunk(
  ProfessionalsActions.fetchSubscriptions,
  async (params: { clinicIds?: string[] }, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.fetchSubscriptions(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchSubscriptionStatistic = createAsyncThunk(
  ProfessionalsActions.fetchSubscriptionStatistic,
  async (params: { packageIds?: string[] }, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalsAPI.fetchSubscriptionStatistic(
        params,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
