import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from '../types/supportedLanguage';

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const isEnLocale = i18n.language.toLocaleLowerCase().includes('en');
  const isFrLocale = i18n.language.toLocaleLowerCase().includes('fr');
  const switchLanguage = (locale: SupportedLanguage) => {
    i18n.changeLanguage(locale);
  };
  const language = isEnLocale ? 'en' : isFrLocale ? 'fr' : 'pt';

  return {
    language,
    switchLanguage,
  };
};
