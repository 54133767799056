import styled from 'styled-components';

export const InfoSectionStyled = styled.div`
  h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 28px;
    line-height: 39.2px;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }
  > div {
    display: flex;
    align-items: center;
    margin-top: 8px;

    p {
      color: ${({ theme }) => theme.color.grey600};
      font-size: 16px;
      font-weight: 700;
      line-height: 22.4px;
      margin-right: 4px;
    }
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.color.blue800};
      font-size: 16px;
      font-weight: 700;
      line-height: 22.4px;
      letter-spacing: 0.08px;
    }
  }
`;
