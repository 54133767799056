import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader, Pagination } from 'common/components';
import { PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import {
  getNotifications,
  readAllNotification,
} from 'applicaiton/store/reducers/NotificationsInbox/ActionCreators';

import NotificationItem from '../NotificationItem';
import NoDataElement from '../NoDataElement';
import { Wrapper, HeaderStyled, TableStyled } from './styles';

const defaultLimit = 10;

const NotificationsTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { notifications, isLoading, total, totalPages } = useAppSelector(
    (state) => state.notificationsInbox,
  );
  const [currentPage, setCurrentPage] = useState(1);

  const unreadMessages = notifications.filter((item) => !item.isRead).length;

  const getNotificationsList = async () => {
    await dispatch(
      getNotifications({ page: currentPage, limit: defaultLimit }),
    );
  };

  useEffect(() => {
    getNotificationsList();
  }, [currentPage]);

  const handleReadAllNotifications = async () => {
    const response = await dispatch(readAllNotification());
    if (response.meta.requestStatus === 'fulfilled') {
      await getNotificationsList();
    }
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <HeaderStyled>
        <div>
          <h1>{t('my_notifications.notifications')}</h1>
          <p>
            {unreadMessages} {t('my_notifications.unread_notifications')}
          </p>
        </div>
        <PrimaryButton
          type="button"
          onClick={handleReadAllNotifications}
          disabled={!unreadMessages}
        >
          {t('my_notifications.mark_all_as_read')}
        </PrimaryButton>
      </HeaderStyled>

      {notifications.length ? (
        <TableStyled>
          <thead>
            <tr>
              <th>
                <span>{t('my_notifications.date')}</span>
              </th>
              <th>
                <span>{t('my_notifications.notification_name')}</span>
              </th>
              <th>
                <span>{t('my_notifications.actions')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </tbody>
        </TableStyled>
      ) : (
        <NoDataElement />
      )}
      {total >= defaultLimit && (
        <Pagination
          pages={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          itemCount={total}
        />
      )}
    </Wrapper>
  );
};

export default NotificationsTable;
