import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const VerifyText = styled.div`
  color: ${({ theme }) => theme.color.grey700};
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  span {
    padding: 0 4px;
    font-weight: 700;
  }
`;

export const CodeActive = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.color.grey800};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;

export const InputWrapper = styled.div`
  width: 464px;
`;

export const NoCode = styled.div`
  margin-top: 24px;
  color: ${({ theme }) => theme.color.grey700};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  div:last-child {
    display: flex;
    align-items: center;
    margin-top: 6px;
    color: ${({ theme }) => theme.color.blue700};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;
    text-decoration: none;

    div {
      cursor: pointer;
      margin-top: 0;
    }

    a {
      cursor: pointer;
      margin-top: 0;
      color: inherit;
      text-decoration: none;
    }

    span {
      margin: 0 12px;
    }

    .disabled {
      color: ${({ theme }) => theme.color.grey600};
      pointer-events: none;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    width: 150px;
  }

  button:last-child {
    margin-left: 16px;
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;
