import { PrivateDocResponseDto } from '@docbay/schemas';
import { PrivateDoctorUpdateParams } from 'common/types/privateDoctor';
import axiosInstance from 'integration/api/axiosInstance';

export const PrivateDoctorAPI = {
  getPrivateDoctor: (id: string) =>
    axiosInstance.get<PrivateDocResponseDto>(`/private-doctor/${id}`),
  updatePrivateDoctor: ({ id, data }: PrivateDoctorUpdateParams) => {
    return axiosInstance.patch<PrivateDocResponseDto>(
      `/private-doctor/${id}`,
      data,
    );
  },
};
