import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { PreviewModal } from 'features/feature-teleconsultation/constants';
import WaitingScreen from '../WaitingScreen';
import ActionsSection from '../ActionsSection';
import Modal from '../Modal';
import { Wrapper, VideoWrapper } from './styles';
import Information from '../Information';
import { useAppointment } from 'common/hooks/useAppointment';
import Chat from '../Chat';
import { useTeleconsultationChat } from 'features/feature-teleconsultation/hooks/useTeleconsultationChat';
import { useTeleconsultation } from 'features/feature-teleconsultation/hooks/useTeleconsultation';
import Room from '../Room';
import { useRoom } from '../Room/hooks';
import Documents from '../Documents';
import RoomsUnavailablePopUp from '../RoomsUnavailablePopUp';

const VideoComponent: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { currentAppointment } = useAppointment();
  const { accessToken, room: roomName } = useTeleconsultation();
  const { conversation } = useTeleconsultationChat();
  const [isEventStarted, setIsEventStarted] = useState(false);
  const [previewModal, setPreviewModal] = useState<PreviewModal | null>(null);
  const [isMeetingFinished, setIsMeetingFinished] = useState<boolean>(false);
  const {
    room,
    error,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    isCameraOn,
    toggleCamera,
    isMicrophoneOn,
    toggleMicrophone,
  } = useRoom();

  const isChatOpened = previewModal === PreviewModal.Chat;
  const isDocumentsOpened = previewModal === PreviewModal.Documents;
  const isInfoOpened = previewModal === PreviewModal.Information;
  const patientInitials = `${
    currentAppointment?.patient?.firstName.slice(0, 1) || ''
  }${currentAppointment?.patient?.lastName.slice(0, 1) || ''}`;
  const eventNotLongActive = dayjs().isAfter(
    dayjs(currentAppointment?.startDate).set(
      'minute',
      dayjs(currentAppointment?.startDate).minute() + 60,
    ),
  );
  const roomId = useMemo(() => searchParams.get('room'), [searchParams]);

  useEffect(() => {
    const isChatTeleconsultation = roomId && !currentAppointment;
    const eventStarted = dayjs().isAfter(dayjs(currentAppointment?.startDate));
    setIsEventStarted(isChatTeleconsultation ? true : eventStarted);
  }, [currentAppointment?.startDate, roomId]);

  const handleAsideModal = () => {
    setPreviewModal(null);
  };

  const handleSetPreviewModal = (name: PreviewModal) => {
    setPreviewModal((prev) => (prev === name ? null : name));
  };

  useEffect(() => {
    const isTimeToConnect =
      !room && accessToken && roomName && isEventStarted && !eventNotLongActive;
    if (isTimeToConnect) {
      connectRoom({
        token: accessToken,
        options: { name: String(roomName) },
      });
      return () => disconnectRoom();
    }
  }, [
    connectRoom,
    disconnectRoom,
    room,
    roomName,
    accessToken,
    isEventStarted,
    eventNotLongActive,
  ]);

  const handleDisconnect = () => {
    disconnectRoom();
    setIsMeetingFinished(true);
  };

  const handleRejoin = () => {
    if (accessToken) {
      setIsMeetingFinished(false);
      connectRoom({
        token: accessToken,
        options: { name: String(roomName) },
      });
    }
  };

  return (
    <Wrapper>
      <VideoWrapper isPreviewOpened={!!previewModal}>
        {isEventStarted && !isMeetingFinished && !eventNotLongActive ? (
          <Room
            localParticipant={localParticipant}
            remoteParticipants={remoteParticipants}
            patientInitials={patientInitials}
            hasError={!!error}
          />
        ) : (
          <WaitingScreen
            startDate={currentAppointment?.startDate!}
            isEventStarted={isEventStarted}
            setIsEventStarted={setIsEventStarted}
            isMeetingFinished={isMeetingFinished}
            handleRejoin={handleRejoin}
            showBackButton={!!currentAppointment}
          />
        )}
        {conversation ? (
          <Chat
            isChatOpened={isChatOpened}
            handleAsideModal={handleAsideModal}
            isChatTeleconsultation={!currentAppointment}
            conversationSid={conversation.sid}
          />
        ) : null}
        {isDocumentsOpened && (
          <Modal
            title={t('teleconsultation.documents') || ''}
            onClose={handleAsideModal}
          >
            <Documents />
          </Modal>
        )}
        {isInfoOpened && (
          <Modal
            title={t('teleconsultation.patient_information') || ''}
            onClose={handleAsideModal}
          >
            <Information />
          </Modal>
        )}
      </VideoWrapper>
      <ActionsSection
        isEventStarted={
          isEventStarted && !isMeetingFinished && !eventNotLongActive
        }
        handleSetPreviewModal={handleSetPreviewModal}
        previewModal={previewModal}
        isCameraOn={isCameraOn}
        toggleCamera={toggleCamera}
        isMicrophoneOn={isMicrophoneOn}
        toggleMicrophone={toggleMicrophone}
        handleDisconnect={handleDisconnect}
        isChatTeleconsultation={!currentAppointment}
      />
      <RoomsUnavailablePopUp />
    </Wrapper>
  );
};

export default VideoComponent;
