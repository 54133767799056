import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  VerifyText,
  CodeActive,
  NoCode,
  ButtonWrapper,
  StyledDivider,
  InputWrapper,
} from './styles';
import { Input, PrimaryButton, SecondaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import LeavePageModal from '../LeavePageModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { AuthLocalStorage, getUserRole } from 'applicaiton/sessionStorage/auth';
import TimeCounter from 'common/components/TimeCounter';
import SuccessEmailModal from '../SuccessEmailModal';
import { VerifyEmailProps } from './modules';
import ErrorEmail from '../ErrorEmail';
import { UserType } from '@docbay/schemas';
import {
  secretarySetNewEmail,
  secretaryVerifyCode,
  secretaryVerifyOldEmail,
} from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import {
  resetSecretaryError,
  setIsResentActive,
  setIsWrongVerificationCode,
} from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';
import { DocBaySupportLink } from 'applicaiton';
import { Link } from 'react-router-dom';

const VerifyEmail = ({ onNext, isNewEmail, onCancel }: VerifyEmailProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [codeValue, setCodeValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [showErrorModal, setErrorModal] = useState(false);
  const oldEmail = sessionStorage.getItem('oldEmail');
  const newEmail = sessionStorage.getItem('newEmail');
  const [timeCounter, setTimeCounter] = useState(true);
  const [timerMinutes, setTimerMinutes] = useState(5);
  const userRole = getUserRole();
  const [resetTimeCounter, setResetTimeCounter] = useState(true);
  const [resetTimerMinutes, setResetTimerMinutes] = useState(1);

  const { isResentActive, isWrongVerificationCode, error } = useAppSelector(
    (state) => state.secretaries,
  );
  const attemptLimitExceeded = error.endsWith('500');
  useEffect(() => {
    return () => {
      dispatch(resetSecretaryError());
    };
  }, []);

  const handleSubmit = () => {
    onCancel();
  };

  const handleNext = () => {
    if (isNewEmail) {
      const refreshToken = JSON.parse(
        localStorage.getItem(AuthLocalStorage.refreshToken) as string,
      );

      dispatch(
        secretarySetNewEmail({
          oldEmail: String(oldEmail),
          email: String(newEmail),
          code: codeValue,
          userType: (String(userRole) as UserType) || '',
          refreshToken: refreshToken as string,
        }),
      ).then((data) => {
        if (data.payload.token) {
          setSuccessModal(true);
        }
      });
    } else {
      dispatch(
        secretaryVerifyCode({
          email: String(oldEmail),
          code: codeValue,
          userType: (String(userRole) as UserType) || '',
        }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          dispatch(setIsResentActive(true));
          data.payload && onNext();
        }
      });
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
    onCancel();
  };
  const handleCloseErrorModal = () => {
    setErrorModal(false);
  };

  const handleCounterChange = (isStarted: boolean) => {
    setTimeCounter(isStarted);
  };

  const handleResendCode = () => {
    dispatch(
      secretaryVerifyOldEmail({
        email: String(isNewEmail ? newEmail : oldEmail),
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        handleCounterChange(true);
        handleResetCounterChange(true);
        setTimerMinutes(5);
        setResetTimerMinutes(1);
        dispatch(setIsResentActive(false));
      }
    });
  };

  const handleResetCounterChange = (isStarted: boolean) => {
    setResetTimeCounter(isStarted);
  };

  const showResetButton = () => {
    dispatch(setIsResentActive(true));
  };

  const handleChangeCode = (value: string) => {
    isWrongVerificationCode && dispatch(setIsWrongVerificationCode(false));
    setCodeValue(value);
  };

  return (
    <Wrapper>
      <VerifyText>
        {isNewEmail
          ? t('clinic_owner.email.enter_code_for_new_email')
          : t('clinic_owner.email.enter_code_for_old_email')}
        <br />
        <span>{isNewEmail ? newEmail : oldEmail}</span>
      </VerifyText>
      <CodeActive>
        {t('clinic_owner.email.code_be_active')}
        <TimeCounter
          key={'generalTime'}
          timeMins={timerMinutes}
          isStart={timeCounter}
          onChange={handleCounterChange}
        />
      </CodeActive>
      <InputWrapper>
        <Input
          id={'code'}
          value={codeValue}
          onChange={(e) => {
            handleChangeCode(e.target.value);
          }}
          label={t('clinic_owner.email.verification_code')!}
          placeholder={t('clinic_owner.email.enter_code')!}
          type={'text'}
          errorMessage={
            isWrongVerificationCode
              ? t('changePhone.phone.wrong_code')!
              : (attemptLimitExceeded && t('error_attempt_limit')) || ''
          }
        />
      </InputWrapper>

      <NoCode>
        <div>{t('clinic_owner.email.still_no_code')}</div>
        <div>
          <Link to={DocBaySupportLink} target="_blank">
            {t('clinic_owner.email.go_to_support')}
          </Link>
          <span>|</span>
          <div
            className={!isResentActive ? 'disabled' : ''}
            onClick={handleResendCode}
          >
            {t('clinic_owner.email.resend_code')}
          </div>
          {!isResentActive && (
            <TimeCounter
              key={'resentButton'}
              timeMins={resetTimerMinutes}
              isStart={resetTimeCounter}
              onChange={handleResetCounterChange}
              showResetButton={showResetButton}
            />
          )}
        </div>
      </NoCode>
      <StyledDivider />
      <ButtonWrapper>
        <SecondaryButton onClick={() => setShowModal(true)}>
          {t('cancel')}
        </SecondaryButton>
        <PrimaryButton onClick={handleNext} disabled={!codeValue.length}>
          {isNewEmail ? t('save') : t('next')}
        </PrimaryButton>
      </ButtonWrapper>
      <LeavePageModal
        showModal={showModal}
        onClose={handleCancel}
        onSubmit={handleSubmit}
      />
      {showSuccessModal && (
        <SuccessEmailModal onClose={handleCloseSuccessModal} />
      )}
      {showErrorModal && <ErrorEmail onClose={handleCloseErrorModal} />}
    </Wrapper>
  );
};

export default VerifyEmail;
