import axiosInstance from 'integration/api/axiosInstance';
import {
  ProfessionalCreationDto,
  ProfessionalSetPasswordDto,
  ProfessionalUpdateDto,
  CommonEntitiesListResponse,
  ProfessionalResponseDto,
  PatientBriefResponseDto,
  SpokenLanguageDto,
  PatientPurchasedSubscriptionForProfessionalResponseDto,
  CpSubscriptionResponseDto,
  CpSubscriptionStatsResponseDto,
  ProfessionalRestoreRequestDto,
  ProfessionalUpdateResponseDto,
} from '@docbay/schemas';
import { fetchProfessionalsProps } from 'common/types/professionals';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import axiosAuthInstance from './axiosAuthInstance';
import { ProfessionalsSubscribersParams } from 'applicaiton/store/reducers/Professionals/models';

export const ProfessionalsAPI = {
  fetchProfessionals: ({
    searchItem,
    page,
    limit,
    clinicIds,
    invertedChain,
    onlyActive,
  }: fetchProfessionalsProps) => {
    const params = {
      includeDeleted: 'false',
      include:
        'user,photos,mainPhoto,clinicsRelations.specializations.specialization,clinicsRelations.clinic,professionalSpokenLanguages,socialMedia',
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(searchItem ? { search: searchItem } : {}),
      ...(clinicIds?.length ? { clinicIds } : {}),
      ...(invertedChain ? { invertedChain } : {}),
      ...(onlyActive ? { onlyActive } : {}),
    };

    return axiosInstance.get<
      CommonEntitiesListResponse<ProfessionalResponseDto>
    >('/professionals', {
      params,
    });
  },
  fetchProfessionalById: (id: string) => {
    const params = {
      include:
        'clinicsRelations,clinicsRelations.clinic,clinicsRelations.schedule,clinicsRelations.specializations.specialization,mainPhoto,photos,user,socialMedia,professionalSpokenLanguages,notificationConfig,clinicsRelations.clinic.owner,clinicsRelations.subscriptions',
    };
    return axiosInstance.get<ProfessionalResponseDto>(`/professionals/${id}`, {
      params,
    });
  },
  removeProfessional: (id: number) =>
    axiosInstance.delete(`/professionals/${id}`),
  checkProfessionalVerificationCode: (email: string, code: string) =>
    axiosAuthInstance('').post('/professionals/verification-code', {
      email,
      code,
    }),
  createProfessionalPassword: (data: ProfessionalSetPasswordDto) =>
    axiosAuthInstance('').post('/professionals/password', data),
  createProfessional: (data: ProfessionalCreationDto) => {
    const language = getLanguageFromLocalStorage();
    return axiosInstance.post('/professionals/sign-up', { ...data, language });
  },
  updateProfessional: (id: string, data: Partial<ProfessionalUpdateDto>) =>
    axiosInstance.patch<ProfessionalUpdateResponseDto>(
      `/professionals/${id}`,
      data,
    ),
  updateProfessionalEmail: (id: string, email: string) => {
    const language = getLanguageFromLocalStorage();
    return axiosInstance.patch<ProfessionalResponseDto>(
      `/professionals/${id}/email`,
      {
        email,
        language,
      },
    );
  },
  resendAuthorizationData: (id: string) => {
    const language = getLanguageFromLocalStorage();
    return axiosInstance.post(
      `/professionals/${id}/resend-authorization-data`,
      { language },
    );
  },
  fetchProfessionalsSpokenLanguages: () => {
    return axiosInstance.get<CommonEntitiesListResponse<SpokenLanguageDto>>(
      '/professionals/spoken-languages',
    );
  },
  createClinicOwnerProfessional: (data: ProfessionalCreationDto) => {
    return axiosInstance.post<ProfessionalResponseDto>(
      '/professionals/create-by-clinic-owner',
      data,
    );
  },
  restoreClinicOwnerProfessional: (
    params: ProfessionalRestoreRequestDto,
    token: string,
  ) => {
    return axiosAuthInstance(token).post('/professionals/restore', {
      email: params.email,
      ...(params.clinicIds ? { clinicIds: params.clinicIds } : {}),
    });
  },
  fetchPurchasedSubscription: (
    id: string,
    params: ProfessionalsSubscribersParams,
  ) => {
    return axiosInstance.get<
      CommonEntitiesListResponse<PatientPurchasedSubscriptionForProfessionalResponseDto>
    >(`/professionals/${id}/patients-purchased-subscriptions`, { params });
  },
  fetchSubscribedPatients: (search: string) => {
    const params = { search };
    return axiosInstance.get<PatientBriefResponseDto[]>(
      '/professionals/subscribed-patients',
      { params },
    );
  },
  fetchSubscriptions: (params: { clinicIds?: string[] }) => {
    return axiosInstance.get<CpSubscriptionResponseDto[]>(
      '/professionals/subscriptions',
      { params },
    );
  },
  fetchSubscriptionStatistic: (params: { packageIds?: string[] }) => {
    return axiosInstance.get<CpSubscriptionStatsResponseDto>(
      '/professionals/subscriptions/statistics',
      { params },
    );
  },
};
