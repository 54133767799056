import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { CreateAbsence } from '../types/absence';

export const useCreateAbsence = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');

  const createAbsenceSchema = yup.object({
    clinicId: yup.string().required(requiredError!),
    startDate: yup.string().required(requiredError!),
    endDate: yup.string().required(requiredError!),
    reason: yup.string(),
    comment: yup.string(),
  });

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    setError,
    trigger,
    control,
    formState: { errors },
  } = useForm<CreateAbsence>({
    resolver: yupResolver(createAbsenceSchema),
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    setError,
    trigger,
    control,
  };
};
