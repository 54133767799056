import React from 'react';
import { FooterContentStyled, FooterWrapper, StyledDivider } from './styles';
import { useTranslation } from 'react-i18next';

const AuthFooter = () => {
  const { t } = useTranslation();
  return (
    <FooterWrapper>
      <StyledDivider />
      <div>
        <FooterContentStyled variant="body2">
          {t('auth.footer.copyright')}
        </FooterContentStyled>
        {/*TODO: will uncommented later*/}
        {/*<FooterContentStyled variant="body2">*/}
        {/*  {t('auth.footer.termsAndConditons')}*/}
        {/*</FooterContentStyled>*/}
      </div>
    </FooterWrapper>
  );
};

export default AuthFooter;
