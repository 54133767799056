import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DotsIcon } from 'applicaiton/assets/dots-vertical.svg';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-pencil.svg';
import { ReactComponent as DownloadIcon } from 'applicaiton/assets/download.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { ExpandedActionsProps } from './models';
import { Wrapper, ActionsListStyled } from './styles';

const ExpandedActions: FC<ExpandedActionsProps> = ({
  handleEdit,
  handleDeleteDocument,
  handleDownloadDocument,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  return (
    <Wrapper ref={ref}>
      <button type="button" onClick={() => setIsActive((prev) => !prev)}>
        <DotsIcon />
      </button>
      {isActive && (
        <ActionsListStyled>
          <button onClick={handleEdit}>
            <EditIcon />
            {t('edit')}
          </button>
          <button onClick={handleDownloadDocument}>
            <DownloadIcon />
            {t('download')}
          </button>
          <button onClick={handleDeleteDocument}>
            <DeleteIcon />
            {t('delete')}
          </button>
        </ActionsListStyled>
      )}
    </Wrapper>
  );
};

export default ExpandedActions;
