import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccesslIcon } from 'applicaiton/assets/success-icon.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';

interface SuccessModalProps {
  text: JSX.Element;
  onClose: () => void;
}

const FamilyMemberSuccessModal: FC<SuccessModalProps> = ({ text, onClose }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccesslIcon />
          {text}
          <SecondaryButton onClick={handleClose}>
            {t('users.modal_success.ok')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default FamilyMemberSuccessModal;
