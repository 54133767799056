import React, { FC, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  AbsenceResponseDto,
  AppointmentBriefResponseDto,
  AppointmentResponseDto,
} from '@docbay/schemas';
import { Modal } from '@mui/material';

import { ReactComponent as SuccessIcon } from 'applicaiton/assets/success-icon.svg';
import { selectAppointmentToDeleted } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import {
  setCurrentAppointment,
  setIsHighlightedAppointment,
} from 'applicaiton/store/reducers/Appointments/PatientAppoinmentsSlice';
import { setAbsenceToDelete } from 'applicaiton/store/reducers/Absence/AbsenceSlice';

import { SecondaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import AppointmentsItem from '../AppointmentsItem';
import AbsenceItem from '../AbsenceItem';

import {
  ButtonWrapper,
  Head,
  StyledCloseIcon,
  StyledModal,
  Title,
  WrapperAppointments,
} from './styles';

require('dayjs/locale/pt');
require('dayjs/locale/fr');

interface Props {
  onCloseModal: () => void;
  modalTitle: string;
  isAppointmentType: boolean;
}

const SuccessWithConfuseModal: FC<Props> = ({
  onCloseModal,
  modalTitle,
  isAppointmentType,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { confusingAgendas } = useAppSelector(
    (state) => state.confusingAgendas,
  );
  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const { absence } = useAppSelector((state) => state.absence);

  const currentType = useMemo(() => {
    return isAppointmentType ? currentAppointment : absence;
  }, [currentAppointment, absence]);

  const confusingAbsences = useMemo(() => {
    return confusingAgendas?.absences.filter((item) => item.id !== absence?.id);
  }, [confusingAgendas?.absences, absence]);

  const confusingAppointments = useMemo(() => {
    return confusingAgendas?.appointments.filter(
      (item) => item.id !== currentAppointment?.id,
    );
  }, [confusingAgendas?.appointments, currentAppointment]);

  const isSame = useMemo(() => {
    const diff = dayjs(dayjs(currentType?.startDate).format('YYYY-MM-DD')).diff(
      dayjs(dayjs(currentType?.endDate).format('YYYY-MM-DD')),
    );

    return diff === 0;
  }, [currentType?.endDate, currentType?.startDate]);

  const handleDeleteAbsence = (absence: AbsenceResponseDto | null) => {
    dispatch(setAbsenceToDelete(absence));
  };

  useEffect(() => {
    if (confusingAgendas) {
      const hasConfusing =
        !!confusingAbsences?.length || !!confusingAppointments?.length;
      if (!hasConfusing) {
        onCloseModal();
      }
    }
  }, [confusingAgendas]);

  const handleDeleteAppointment = (
    appointment: AppointmentResponseDto | AppointmentBriefResponseDto | null,
  ) => {
    dispatch(selectAppointmentToDeleted(appointment));
  };

  const handleMoveToAppointment = async (id: string) => {
    const appointmentForMove = confusingAgendas?.appointments?.find(
      (item) => item.id === id,
    );

    dispatch(setIsHighlightedAppointment(true));
    dispatch(setCurrentAppointment(appointmentForMove!));
    onCloseModal();
  };

  const endDateFormatted = isSame
    ? dayjs(currentType?.endDate).tz().format('HH:mm')
    : dayjs(currentType?.endDate).tz().format('DD MMMM HH:mm');

  return (
    <Modal open={true}>
      <StyledModal>
        <Head>
          <div>
            <SuccessIcon />
            <div>
              <p>
                {modalTitle}{' '}
                <span>
                  {currentType?.professional?.firstName}{' '}
                  {currentType?.professional?.lastName}
                </span>
              </p>
              <p>
                {t('on')}{' '}
                <span>
                  {dayjs(currentType?.startDate).tz().format('DD MMMM')}
                </span>{' '}
                {t('from')}{' '}
                <span>
                  {dayjs(currentType?.startDate).tz().format('HH:mm')}
                </span>{' '}
                {t('to')} <span>{endDateFormatted}</span>
              </p>
            </div>
          </div>
          <StyledCloseIcon onClick={onCloseModal} />
        </Head>
        <WrapperAppointments>
          <Title>{t('absenceModal.bookedAppointments')}</Title>
          <div>
            {!!confusingAbsences?.length &&
              confusingAbsences.map((item) => (
                <AbsenceItem
                  key={item.id}
                  data={item}
                  onDelete={handleDeleteAbsence}
                />
              ))}
            {!!confusingAppointments?.length &&
              confusingAppointments.map((item) => (
                <AppointmentsItem
                  key={item.id}
                  data={item}
                  moveToAppointment={handleMoveToAppointment}
                  onDelete={handleDeleteAppointment}
                />
              ))}
          </div>
        </WrapperAppointments>
        <ButtonWrapper>
          <SecondaryButton onClick={onCloseModal}>
            {t('absenceModal.got_it')}
          </SecondaryButton>
        </ButtonWrapper>
      </StyledModal>
    </Modal>
  );
};

export default SuccessWithConfuseModal;
