import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClinicsAPI } from 'integration/api/clinics';
import {
  ClinicOwnerActions,
  ClinicsActions,
} from 'applicaiton/constants/actions';

import { AxiosError } from 'axios';
import { ClinicCreationDto, ClinicUpdateDto } from '@docbay/schemas';
import { searchClinicsInChainProps } from 'common/types/clinicOwner';
import { ClinicOwnerAPI } from 'integration/api/clinicOwner';

export const fetchClinics = createAsyncThunk(
  ClinicsActions.fetchProfiles,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ClinicsAPI.fetchAllClinics();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchInsurances = createAsyncThunk(
  ClinicsActions.fetchInsurances,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ClinicsAPI.fetchInsurances();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteProfile = createAsyncThunk(
  ClinicsActions.deleteProfile,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicsAPI.removeProfile(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchClinicProfile = createAsyncThunk(
  ClinicsActions.getClinicProfile,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicsAPI.getClinicProfile(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const saveClinicProfile = createAsyncThunk(
  ClinicsActions.saveClinicProfile,
  async (props: { data: ClinicUpdateDto; id: string }, { rejectWithValue }) => {
    try {
      const { data } = await ClinicsAPI.saveClinicProfileData(
        props.id,
        props.data,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createClinicProfile = createAsyncThunk(
  ClinicsActions.createClinicProfile,
  async (data: ClinicCreationDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ClinicsAPI.createClinicProfileData(
        data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const searchClinicsInChain = createAsyncThunk(
  ClinicOwnerActions.searchClinicsInChain,
  async (params: searchClinicsInChainProps, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.searchClinicsInChain(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getSpecializationsByClinic = createAsyncThunk(
  ClinicsActions.getSpecializationsByClinic,
  async (clinicId: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicsAPI.getSpecializationsByClinic(clinicId);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
