import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  border-top: 0;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);

  > div:first-of-type {
    display: flex;
  }
`;

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: separate;

  > thead {
    tr {
      th {
        vertical-align: middle;
        text-align: center;
        background: ${({ theme }) => theme.color.white};
        width: 204px;
        height: 61px;
        color: ${({ theme }) => theme.color.grey900};
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 25.2px;
        padding-bottom: 12px;

        &:first-of-type {
          width: auto;
        }
      }
    }
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.grey200};
  margin: 32px 0;
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  button {
    width: 150px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;
