import { createAsyncThunk } from '@reduxjs/toolkit';
import { ConsultationDataActions } from 'applicaiton/constants/actions';
import { AxiosError } from 'axios';

import { ConsultationDataAPI } from 'integration/api/consultationData';
import {
  AppointmentDataCreationDto,
  FamilyHistoryCreationDto,
  SurgeryCreationDto,
} from '@docbay/schemas';
import { PaginationPropsWithSearch } from 'common/types/pagination';

export const getConsultationData = createAsyncThunk(
  ConsultationDataActions.getConsultationData,
  async (appointmentId: string, { rejectWithValue }) => {
    try {
      const { data } = await ConsultationDataAPI.getConsultationData(
        appointmentId,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const saveConsultationData = createAsyncThunk(
  ConsultationDataActions.saveConsultationData,
  async (
    params: { appointmentId: string; data: AppointmentDataCreationDto },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ConsultationDataAPI.saveConsultationData(
        params.appointmentId,
        params.data,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getConsultationDataHistory = createAsyncThunk(
  ConsultationDataActions.getConsultationDataHistory,
  async (appointmentId: string, { rejectWithValue }) => {
    try {
      const { data } = await ConsultationDataAPI.getConsultationDataHistory(
        appointmentId,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getPatientMedicalBackground = createAsyncThunk(
  ConsultationDataActions.getPatientMedicalBackground,
  async (patientId: string, { rejectWithValue }) => {
    try {
      const { data } = await ConsultationDataAPI.getPatientMedicalBackground(
        patientId,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getAllergies = createAsyncThunk(
  ConsultationDataActions.getAllergies,
  async (params: PaginationPropsWithSearch, { rejectWithValue }) => {
    try {
      const { data } = await ConsultationDataAPI.getAllergies(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getDiseases = createAsyncThunk(
  ConsultationDataActions.getDiseases,
  async (params: PaginationPropsWithSearch, { rejectWithValue }) => {
    try {
      const { data } = await ConsultationDataAPI.getDiseases(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getSurgeries = createAsyncThunk(
  ConsultationDataActions.getSurgeries,
  async (params: PaginationPropsWithSearch, { rejectWithValue }) => {
    try {
      const { data } = await ConsultationDataAPI.getSurgeries(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getSymptoms = createAsyncThunk(
  ConsultationDataActions.getSymptoms,
  async (params: PaginationPropsWithSearch, { rejectWithValue }) => {
    try {
      const { data } = await ConsultationDataAPI.getSymptoms(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const setPatientAllergy = createAsyncThunk(
  ConsultationDataActions.setPatientAllergy,
  async (
    params: { patientId: string; allergyId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ConsultationDataAPI.setPatientAllergy(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const removePatientAllergy = createAsyncThunk(
  ConsultationDataActions.removePatientAllergy,
  async (
    params: { patientId: string; allergyId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ConsultationDataAPI.removePatientAllergy(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const setPatientDisease = createAsyncThunk(
  ConsultationDataActions.setPatientDisease,
  async (
    params: { patientId: string; diseaseId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ConsultationDataAPI.setPatientDisease(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const removePatientDisease = createAsyncThunk(
  ConsultationDataActions.removePatientDisease,
  async (
    params: { patientId: string; diseaseId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ConsultationDataAPI.removePatientDisease(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const setPatientFamilyHistoricData = createAsyncThunk(
  ConsultationDataActions.setPatientFamilyHistoricData,
  async (
    params: { patientId: string; data: FamilyHistoryCreationDto },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ConsultationDataAPI.setPatientFamilyHistoricData(
        params.patientId,
        params.data,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updatePatientFamilyHistoricData = createAsyncThunk(
  ConsultationDataActions.updatePatientFamilyHistoricData,
  async (
    params: {
      patientId: string;
      familyHistoryId: string;
      data: FamilyHistoryCreationDto;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } =
        await ConsultationDataAPI.updatePatientFamilyHistoricData(
          params.patientId,
          params.familyHistoryId,
          params.data,
        );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const setPatientSurgeryData = createAsyncThunk(
  ConsultationDataActions.setPatientSurgeryData,
  async (
    params: { patientId: string; data: SurgeryCreationDto },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ConsultationDataAPI.setPatientSurgeryData(
        params.patientId,
        params.data,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updatePatientSurgeryData = createAsyncThunk(
  ConsultationDataActions.updatePatientSurgeryData,
  async (
    params: {
      patientId: string;
      surgeryDataId: string;
      data: SurgeryCreationDto;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ConsultationDataAPI.updatePatientSurgeryData(
        params.patientId,
        params.surgeryDataId,
        params.data,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deletePatientFamilyHistoricData = createAsyncThunk(
  ConsultationDataActions.deletePatientFamilyHistoricData,
  async (
    params: {
      patientId: string;
      familyHistoryId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } =
        await ConsultationDataAPI.deletePatientFamilyHistoricData(
          params.patientId,
          params.familyHistoryId,
        );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteSurgeryData = createAsyncThunk(
  ConsultationDataActions.deleteSurgeryData,
  async (
    params: {
      patientId: string;
      surgeryDataId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ConsultationDataAPI.deleteSurgeryData(
        params.patientId,
        params.surgeryDataId,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getHealthMetricsBySpecialization = createAsyncThunk(
  ConsultationDataActions.getHealthMetricsBySpecialization,
  async (specializationId: string, { rejectWithValue }) => {
    try {
      const { data } =
        await ConsultationDataAPI.getHealthMetricsBySpecialization(
          specializationId,
        );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
