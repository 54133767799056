import styled from 'styled-components';

export const StyledArticle = styled.article`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  section {
    article {
      overflow-y: auto;
      max-height: calc(100vh - 430px);
      margin-bottom: 30px;
      display: grid;
      gap: 12px;

      > h2,
      h3 {
        height: fit-content;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: ${({ theme }) => theme.color.grey900};
      }
      > h3 {
        font-size: 16px;
      }
      > div {
        font-size: 14px;
        line-height: 18px;
        line-height: 22px;
      }
    }
  }

  & > div {
    div:first-child {
      margin-bottom: 65px;
    }
  }

  button {
    height: 48px;
  }
`;
