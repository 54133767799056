import axiosInstance from './axiosInstance';
import { TopicCreationDto, TopicUpdateDto } from '@docbay/schemas';

export const TopicsAPI = {
  getTopics: (data: {
    id: string;
    params: {
      page: number;
      limit: number;
      clinicIds?: string;
    };
  }) => {
    const params = {
      page: data.params.page,
      limit: data.params.limit,
      ...(data.params.clinicIds ? { clinicIds: data.params.clinicIds } : {}),
    };
    return axiosInstance.get(`/professionals/${data.id}/topics`, { params });
  },
  createTopic: (params: { id: string; data: TopicCreationDto }) => {
    return axiosInstance.post(`/professionals/${params.id}/topics`, {
      ...params.data,
    });
  },
  editTopic: (params: { id: string; topicId: string; data: TopicUpdateDto }) =>
    axiosInstance.patch(
      `/professionals/${params.id}/topics/${params.topicId}`,
      params.data,
    ),
  deleteTopic: (params: { id: string; topicId: string }) =>
    axiosInstance.delete(
      `/professionals/${params.id}/topics/${params.topicId}`,
    ),
};
