import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppointmentTypeItem from '../AppointmentTypeItem';
import { TableStyled, Wrapper } from './styles';
import NoDataElement from '../NoDataElement';
import { Loader, Pagination } from 'common/components';
import FilterSection from '../FilterSection';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchAppointmentTypes } from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import {
  resetIsDeleted,
  setFilteredAppointmentTypes,
} from 'applicaiton/store/reducers/AppointmentTypes/AppointmentTypesSlice';
import { Option } from 'common/components/Dropdown/models';
import SuccessModal from '../SuccessModal';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { fetchClinics } from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { useClinicOwner } from 'common/hooks/useClinicOwner';

const defaultLimit = 10;

const AppointmentTypesTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [showSuccess, setShowSuccess] = useState(false);

  const {
    appointmentTypes,
    isLoading,
    totalPages,
    filteredTypes,
    isDeleted,
    isAdded,
  } = useAppSelector((state) => state.appointmentTypes);
  const { clinics } = useAppSelector((state) => state.clinics);

  const { isPrivateDoctor } = useClinicOwner();

  useEffect(() => {
    dispatch(fetchClinics());
  }, []);

  const handleCloseModal = () => {
    setShowSuccess(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [clinic, setClinic] = useState<Option | null>(null);
  const [specialization, setSpecialization] = useState<string[]>([]);

  useEffect(() => {
    if (isDeleted) {
      setShowSuccess(true);
      fetchAllAppointmentTypes();
    }
  }, [isDeleted]);

  const fetchAllAppointmentTypes = () => {
    const specializationIds = specialization.map((item) => String(item)).join();
    const page = isDeleted
      ? appointmentTypes.length === 1
        ? currentPage === 1
          ? 1
          : currentPage - 1
        : 1
      : currentPage;

    dispatch(
      fetchAppointmentTypes({
        page: page,
        limit: defaultLimit,
        ...(clinic?.value ? { clinicId: String(clinic?.value) } : {}),
        ...(specializationIds.length ? { specializationIds } : {}),
      }),
    );

    if (isDeleted) {
      dispatch(resetIsDeleted());
    }

    setCurrentPage(page);
  };

  useEffect(() => {
    fetchAllAppointmentTypes();
  }, [dispatch, currentPage, specialization, clinic, isAdded]);

  useEffect(() => {
    if (filteredTypes?.clinic && filteredTypes?.specializations) {
      setClinic(filteredTypes!.clinic);
      setSpecialization(filteredTypes!.specializations);
    }
  }, []);

  const handleRemoveFilterItem = (id: string, type: string) => {
    if (type === 'clinic') {
      setClinic(null);
    } else {
      setSpecialization(specialization.filter((item) => item !== id));
    }
  };

  const handleResetFilter = () => {
    setClinic(null);
    setSpecialization([]);
    dispatch(setFilteredAppointmentTypes(null));
  };

  const handleClinicChange = (value: Option) => {
    setClinic(value);
    dispatch(
      setFilteredAppointmentTypes({
        ...(filteredTypes || {}),
        clinic: value,
      }),
    );
  };

  const handleSpecializationsChange = (value: string[]) => {
    setSpecialization(value);
    dispatch(
      setFilteredAppointmentTypes({
        ...(filteredTypes || {}),
        specializations: value,
      }),
    );
  };

  const clinicsByPermissions = useMemo(() => {
    if (!clinics?.length) return [];
    const filteredClinicsByPermissions = clinics.filter((clinic) => {
      const canUserManageAppointmentTypes = userHasPermission(
        UserPermissions.clinicAppointmentTypesConfiguration,
        Number(clinic.owner?.id),
      );
      if (canUserManageAppointmentTypes) return clinic;
    });
    return filteredClinicsByPermissions;
  }, [clinics]);

  const appointmentsByPermissions = useMemo(() => {
    if (!appointmentTypes?.length) return [];
    const filteredAppointmentsByPermissions = appointmentTypes.filter(
      (item) => {
        const hasPermission = clinicsByPermissions.some(
          (clinic) => clinic.id === item.clinic?.id,
        );
        if (hasPermission) return item;
      },
    );
    return filteredAppointmentsByPermissions;
  }, [clinicsByPermissions, appointmentTypes]);

  const notIncludingClinics = useMemo(() => {
    if (!clinics?.length) return [];
    const filteredClinics = clinics.filter((clinic) => {
      const canUserManageAppointmentTypes = userHasPermission(
        UserPermissions.clinicAppointmentTypesConfiguration,
        Number(clinic.owner?.id),
      );
      if (!canUserManageAppointmentTypes) return clinic;
    });
    return filteredClinics;
  }, [clinics]);

  return (
    <>
      <Wrapper>
        {isLoading && <Loader />}
        <FilterSection
          specializationId={specialization}
          setSpecializationId={handleSpecializationsChange}
          clinics={clinic}
          setClinic={handleClinicChange}
          handleRemoveFilterItem={handleRemoveFilterItem}
          handleResetFilter={handleResetFilter}
          notIncludingClinics={notIncludingClinics}
          hideClinicDropdown={isPrivateDoctor}
        />
        <TableStyled>
          <thead>
            <tr>
              <th>
                <span>{t('appointment_types.table.color')}</span>
              </th>
              <th>
                <span>{t('appointment_types.table.title')}</span>
              </th>
              <th>
                <span>{t('appointment_types.table.notifications')}</span>
              </th>
              <th>
                <span>{t('appointment_types.table.duration')}</span>
              </th>
              <th>
                <span>{t('appointment_types.table.booking_online')}</span>
              </th>
              <th>
                <span>{t('appointment_types.table.actions')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {appointmentsByPermissions.map((item) => (
              <AppointmentTypeItem
                key={item.id}
                type={item}
                notificationEnabled={
                  item.notificationConfig?.patientNotificationsEnabled
                }
                onNotificationConfigChange={fetchAllAppointmentTypes}
              />
            ))}
            {!appointmentsByPermissions?.length && <NoDataElement />}
          </tbody>
        </TableStyled>
        {appointmentsByPermissions.length ? (
          <Pagination
            pages={totalPages}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            itemCount={appointmentTypes.length}
          />
        ) : (
          ''
        )}
      </Wrapper>
      {showSuccess && <SuccessModal onClose={handleCloseModal} />}
    </>
  );
};

export default AppointmentTypesTable;
