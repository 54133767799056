import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppointmentsDocumentsActions } from '../../../constants/actions';
import { AxiosError } from 'axios';
import { AppointmentDocumentsApi } from 'integration/api/documents';
import { DocumentUpdateMetadataDto } from '@docbay/schemas';

export const postDocuments = createAsyncThunk(
  AppointmentsDocumentsActions.postDocuments,
  async (params: FormData, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentDocumentsApi.postDocuments(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const postDocumentsToAppointment = createAsyncThunk(
  AppointmentsDocumentsActions.postDocumentsToAppointment,
  async (
    params: {
      appointmentId: string;
      data: FormData;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } =
        await AppointmentDocumentsApi.postDocumentsToAppointment(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const downloadDocuments = createAsyncThunk(
  AppointmentsDocumentsActions.downloadDocuments,
  async (
    params: {
      bucketType: string;
      key: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } =
        await AppointmentDocumentsApi.downloadDocuments(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteDocuments = createAsyncThunk(
  AppointmentsDocumentsActions.deleteDocuments,
  async (
    params: {
      relatedEntityId?: string;
      bucketType: string;
      key: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } =
        await AppointmentDocumentsApi.deleteDocuments(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const editDocuments = createAsyncThunk(
  AppointmentsDocumentsActions.editDocuments,
  async (params: DocumentUpdateMetadataDto, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentDocumentsApi.editDocuments(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
