import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { StyledTr } from './styles';
import { NoDataElementProps } from './models';

const NoDataElement: FC<NoDataElementProps> = ({ handleShowModal }) => {
  const { t } = useTranslation();

  return (
    <StyledTr>
      <td colSpan={5}>
        <div>
          <PickIcon />
          <p>
            {t('my_patients.no_data_element.title')}
            <br />
            {t('my_patients.no_data_element.subtitlePart1')}{' '}
            <button type="button" onClick={handleShowModal}>
              {t('my_patients.no_data_element.subtitlePart2')}
            </button>
          </p>
        </div>
      </td>
    </StyledTr>
  );
};
export default NoDataElement;
