import React, { FC } from 'react';
import { RadioButtonProps } from './modules';
import { RadioButtonStyled } from './styles';

const RadioButton: FC<RadioButtonProps> = ({
  id,
  label,
  checked,
  onChange,
  subLabel,
  disabled,
}) => {
  return (
    <RadioButtonStyled disabled={disabled}>
      <input
        id={id}
        type="radio"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>
        {label}
        <span> {subLabel}</span>
      </label>
    </RadioButtonStyled>
  );
};

export default RadioButton;
