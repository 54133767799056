import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as HouseIcon } from 'applicaiton/assets/house.svg';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import {
  ClinicNameStyled,
  StyledTr,
  ActionsStyled,
  IconStyled,
} from './styles';
import { useAppDispatch } from 'common/hooks/redux';
import { selectProfile } from 'applicaiton/store/reducers/Clinics/ClinicsSlice';
import { ClinicResponseDto } from '@docbay/schemas';
import BadgeWithCheck from 'common/components/BadgeWithCheck';
import { isUserCanEditClinic } from 'common/helpers/permissions';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { useClinicOwner } from 'common/hooks/useClinicOwner';

interface ClinicItemProps {
  clinic: ClinicResponseDto;
}

const ClinicItem: FC<ClinicItemProps> = ({ clinic }) => {
  const dispatch = useAppDispatch();
  const userRole = getUserRole();
  const userId = getUserId();
  const isClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const isCanEditClinic = isUserCanEditClinic();
  const { isPrivateDoctor } = useClinicOwner();
  const clinicOwnerId = useMemo(() => {
    const ownerId = clinic?.owner?.id;
    if (ownerId && ownerId !== userId) {
      return Number(clinic?.owner?.id);
    }
    return undefined;
  }, [clinic?.owner?.id]);

  const canUserDeleteClinic = useMemo(() => {
    return (
      !isPrivateDoctor &&
      userHasPermission(
        UserPermissions.clinicDeletion,
        clinicOwnerId,
        !!clinicOwnerId,
      )
    );
  }, [clinicOwnerId, isPrivateDoctor]);

  const handleDeleteProfile = () => {
    dispatch(selectProfile(clinic));
  };

  return (
    <StyledTr>
      <td>
        <ClinicNameStyled>
          {clinic.mainPhoto ? (
            <img
              src={clinic.mainPhoto.thumbnailUrl}
              alt={clinic.mainPhoto.id}
            />
          ) : (
            <IconStyled>
              <HouseIcon />
            </IconStyled>
          )}
          <p>{clinic.name}</p>
          {clinic?.status !== 'Active' ? <BadgeWithCheck status={''} /> : null}
        </ClinicNameStyled>
      </td>
      <td>{clinic.professionalsRelations?.length || 0}</td>
      <td>
        <ActionsStyled>
          {((!clinicOwnerId && isClinicOwner) || isCanEditClinic) && (
            <Link to={`${PathNames.editClinic}/${clinic.id}`}>
              <EditIcon />
            </Link>
          )}

          {canUserDeleteClinic && (
            <button onClick={handleDeleteProfile}>
              <DeleteIcon />
            </button>
          )}
        </ActionsStyled>
      </td>
    </StyledTr>
  );
};
export default ClinicItem;
