import styled from 'styled-components';

export const WatchStyled = styled.div`
  display: flex;
  align-items: center;
  h2 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  @media screen and (max-width: 600px) {
    h1 {
      font-size: 48px;
      line-height: 67.2px;
    }
  }
`;
