import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';

export const useSetPassword = () => {
  const setPassword = {
    code: currentClinicOwnerRegistration().setPassword.code,
    password: currentClinicOwnerRegistration().setPassword.password,
  };

  return setPassword;
};
