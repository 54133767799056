import {
  getProfilesFromSessionStorage,
  Profile,
} from 'applicaiton/sessionStorage/auth';
import { usersRefreshToken } from 'applicaiton/store/reducers/Users/ActionCreators';
import { useAppDispatch } from './redux';

export const useUserProfile = () => {
  const dispatch = useAppDispatch();

  const refreshTokenForUser = async () => {
    const profile: Profile[] | null = getProfilesFromSessionStorage();
    if (profile) {
      await dispatch(
        usersRefreshToken({
          refreshToken: profile[0].refreshToken,
          email: profile[0].email,
        }),
      );
    }
  };
  return {
    refreshTokenForUser,
  };
};
