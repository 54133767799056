import styled from 'styled-components';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 35% 35% 30%;
  height: calc(100% - 69px);
`;

export const PatientInfoStyled = styled.div`
  border-right: 1px solid ${commonColors.grey200};
  background: ${commonColors.grey50};
  overflow-y: auto;

  .patient-info {
    border: 0;
    background-color: transparent;
  }
`;

export const ClinicInfoStyled = styled.div`
  border-right: 1px solid ${commonColors.grey200};
  overflow-y: auto;
`;
