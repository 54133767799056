import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes/constants';
import { ClinicsListStyled, TableWrapper, NotificationStyled } from './styles';
import { PrimaryButton } from 'common/components';
import { ClinicsTable, Header } from './components';
import { ReactComponent as InfoIcon } from 'applicaiton/assets/info.svg';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { useAppSelector } from 'common/hooks/redux';
import { useClinicOwner } from 'common/hooks/useClinicOwner';

const ClinicsList: FC = () => {
  const { t } = useTranslation();
  const { clinics } = useAppSelector((state) => state.clinics);

  const { isPrivateDoctor, isUserRoleClinicOwner } = useClinicOwner();

  const hasNotActivatedClinic = clinics.some(
    (item) => item.status !== 'Active',
  );

  const showCreateNewClinic = useMemo(() => {
    return isUserRoleClinicOwner && !isPrivateDoctor;
  }, [isPrivateDoctor, isUserRoleClinicOwner]);

  return (
    <ClinicsListStyled>
      <Header />
      {hasNotActivatedClinic && (
        <NotificationStyled>
          <InfoIcon />
          <p>{t('editProfileSettings.activate_instruction')}</p>
        </NotificationStyled>
      )}
      <TableWrapper>
        <h1>{t('clinicsConfiguration.clinics.my_clinics')}</h1>
        {showCreateNewClinic && (
          <Link to={PathNames.createClinic}>
            <PrimaryButton>
              <AddIcon />
              {t('clinicsConfiguration.clinics.add_new_clinic')}
            </PrimaryButton>
          </Link>
        )}
      </TableWrapper>
      <ClinicsTable />
    </ClinicsListStyled>
  );
};

export default ClinicsList;
