import {
  currentClinicOwnerRegistration,
  setClinicOwnerRegistrationSessionStorage,
} from 'applicaiton/sessionStorage/clinicRegistration';
import { useState } from 'react';

export const usePrivateDoctorRegistration = () => {
  const privateDoctor = currentClinicOwnerRegistration().privateDoctor;
  const [isPrivateDoctor, setIsPrivateDoctor] = useState<boolean>(
    !!privateDoctor,
  );
  const setPrivateDoctor = (isPrivate: boolean) => {
    setClinicOwnerRegistrationSessionStorage('privateDoctor', isPrivate);
    setIsPrivateDoctor(isPrivate);
  };

  return {
    isPrivateDoctor,
    setPrivateDoctor,
  };
};
