import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { StyledTr } from './styles';
import { PathNames } from 'applicaiton/routes';

const NoDataElement: FC = () => {
  const { t } = useTranslation();
  return (
    <StyledTr>
      <td colSpan={6}>
        <div>
          <PickIcon />
          <p>
            {t('appointment_types.no_data_element.title')}
            <br />
            {t('appointment_types.no_data_element.subtitlePart1')}{' '}
            <Link to={PathNames.addAppointmentType}>
              {t('appointment_types.no_data_element.subtitlePart2')}
            </Link>
          </p>
        </div>
      </td>
    </StyledTr>
  );
};
export default NoDataElement;
