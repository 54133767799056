import styled from 'styled-components';

export const ModalStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 664px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

  h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
  }

  button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }
  }
`;
