import React, { useMemo, useState, useEffect } from 'react';
import { ChatListItemProps, UserUpdateEvent } from './module';
import {
  ActiveUser,
  AvatarStyled,
  ListItemContent,
  ListItemHeader,
  ListItemWrapper,
  ProfessionalData,
  ProfessionalPhoto,
} from './styles';
import { formatDateTimeInDefaultTimezone } from 'common/helpers/dateTimeHelper';
import { useTranslation } from 'react-i18next';
import { EmergencyMessageIcon } from 'applicaiton/assets/chats';
import { useConversation } from '../../hooks/useConversation';
import { useChatParticipants } from 'features/feature-chats/hooks/useChatParticipants';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import dayjs from 'dayjs';

export const ChatsListItem = ({
  chatData,
  onChatSelect,
}: ChatListItemProps) => {
  const { t } = useTranslation();
  const { selectedChat } = useConversation();
  const { oppositeUser } = useChatParticipants(
    chatData?.twilioConversationSID,
    chatData,
  );
  const [patientIsActive, setPatientIsActive] = useState<boolean>(false);

  const isToday = dayjs().isSame(dayjs(chatData.lastMessageTime), 'day');
  const isSameWeek = dayjs().isSame(dayjs(chatData.lastMessageTime), 'week');

  const getMessageTime = () => {
    if (isToday) {
      return formatDateTimeInDefaultTimezone(
        chatData.lastMessageTime!,
        'HH:mm',
      );
    } else if (isSameWeek) {
      return formatDateTimeInDefaultTimezone(chatData.lastMessageTime!, 'dd');
    } else {
      return formatDateTimeInDefaultTimezone(chatData.lastMessageTime!, 'D/MM');
    }
  };

  const topicName =
    chatData?.clinicProfessionalTopic?.name ?? t('chats.no_topic');

  const isActiveChat = useMemo(() => {
    return selectedChat?.id === chatData?.id;
  }, [selectedChat, chatData]);

  useEffect(() => {
    if (!oppositeUser) {
      setPatientIsActive(false);
      return;
    }

    setPatientIsActive(!!oppositeUser.isOnline);
    oppositeUser.on('updated', ({ user }: UserUpdateEvent) => {
      setPatientIsActive(!!user.isOnline);
    });
    return () => {
      oppositeUser.removeAllListeners('updated');
    };
  }, [oppositeUser, selectedChat]);

  return (
    <ListItemWrapper
      onClick={() => onChatSelect(chatData)}
      active={isActiveChat}
    >
      <ProfessionalPhoto emergency={chatData?.emergency}>
        {patientIsActive && <ActiveUser />}

        <AvatarStyled>
          <PersonIcon />
        </AvatarStyled>
      </ProfessionalPhoto>
      <ProfessionalData>
        <ListItemHeader>
          {chatData?.emergency && <EmergencyMessageIcon />}
          <p className={'text'}>
            {chatData.patient.firstName + ' ' + chatData.patient.lastName}
          </p>
          <p className={'time'}>{getMessageTime()}</p>
        </ListItemHeader>
        <ListItemContent>
          <div className={'content-wrapper'}>
            <p className={'topic'}>{topicName}</p>
            <p className={'message'}>{chatData.lastMessageText}</p>
          </div>
          {chatData.unreadMessagesCount ? (
            <div className={'counter'}>{chatData.unreadMessagesCount}</div>
          ) : (
            <> </>
          )}
        </ListItemContent>
      </ProfessionalData>
    </ListItemWrapper>
  );
};
