import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InitialState } from './modules';

const initialState: InitialState = {
  hasUnsavedChanges: false,
  showModal: false,
  nextPath: null,
};

const detectChangesSavedSlice = createSlice({
  name: 'detectChangesSavedSlice',
  initialState,
  reducers: {
    setHasUnsavedChanges: (state, actions: PayloadAction<boolean>) => {
      state.hasUnsavedChanges = actions.payload;
    },
    setNextPath: (state, actions: PayloadAction<string | null>) => {
      state.nextPath = actions.payload;
      state.showModal = !!actions.payload;
    },
    reset: (state) => (state = initialState),
  },
});

export const { setHasUnsavedChanges, setNextPath, reset } =
  detectChangesSavedSlice.actions;

export default detectChangesSavedSlice.reducer;
