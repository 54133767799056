import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  adminSetNewEmail,
  adminSetNewPhone,
  adminVerifyCode,
  adminVerifyOldEmail,
  adminVerifyPhone,
  createAdmin,
  deleteAdmin,
  editAdmin,
  getAdminById,
} from './ActionCreators';
import { AdminsState } from './models';
import { AdminCreationDto } from '@docbay/schemas';
import {
  AuthLocalStorage,
  setUsersRefreshTokenSessionStorage,
} from 'applicaiton/sessionStorage/auth';

const initialState: AdminsState = {
  total: 0,
  page: 0,
  totalPages: 0,
  profileChanges: null,
  currentUser: null,
  isLoading: false,
  error: '',
  isAdded: false,
  isUpdated: false,
  isVerifiedOldEmail: false,
  isVerifiedOldEmailCode: false,
  isVerifiedOldPhone: false,
  isVerifiedOldPhoneCode: false,
  isResentActive: true,
  isWrongVerificationCode: false,
};

const adminsSlice = createSlice({
  name: 'adminsSlice',
  initialState,
  reducers: {
    setAdminData: (state, actions: PayloadAction<AdminCreationDto | null>) => {
      state.profileChanges = actions.payload;
    },
    resetAdminData: (state) => {
      state.profileChanges = null;
      state.isAdded = false;
      state.isUpdated = false;
      state.error = '';
      state.currentUser = null;
      state.isVerifiedOldEmail = false;
    },
    resetEmailData: (state) => {
      state.isVerifiedOldEmail = false;
      state.isVerifiedOldEmailCode = false;
      state.isWrongVerificationCode = false;
    },
    resetVerifiedOldEmailCode: (state) => {
      state.isVerifiedOldEmailCode = false;
    },
    setCurrentData: (state, action) => {
      state.currentUser = action.payload;
    },
    resetVerifiedOldPhoneCode: (state) => {
      state.isVerifiedOldPhoneCode = false;
    },
    resetPhoneData: (state) => {
      state.isVerifiedOldPhone = false;
      state.isVerifiedOldPhoneCode = false;
      state.isWrongVerificationCode = false;
    },
    setIsResentActive: (state, actions) => {
      state.isResentActive = actions.payload;
    },
    setIsWrongVerificationCode: (state, actions) => {
      state.isWrongVerificationCode = actions.payload;
    },
    setIsAdminUpdated: (state) => {
      state.isUpdated = false;
    },
    resetAdminError: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    //create admins
    builder.addCase(createAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createAdmin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAdded = true;
    });
    //getAdminById admins
    builder.addCase(getAdminById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAdminById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getAdminById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentUser = action.payload;
    });
    //create admins
    builder.addCase(deleteAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAdmin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentUser = null;
      state.profileChanges = null;
    });
    //create admins
    builder.addCase(editAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editAdmin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(editAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isUpdated = true;
      state.currentUser = action.payload;
    });
    //verify old email
    builder.addCase(adminVerifyOldEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(adminVerifyOldEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(adminVerifyOldEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldEmail = true;
    });

    //verify code
    builder.addCase(adminVerifyCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(adminVerifyCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(adminVerifyCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldEmailCode = !!action.payload;
    });

    //set new email
    builder.addCase(adminSetNewEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(adminSetNewEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(adminSetNewEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.token) {
        setUsersRefreshTokenSessionStorage(action.payload);

        state.currentUser = {
          ...state.currentUser!,
          email: String(sessionStorage.getItem('newEmail')),
        };

        const profiles = JSON.parse(
          localStorage.getItem(AuthLocalStorage.profiles) as string,
        );

        const newProfile = {
          ...profiles[0],
          email: String(sessionStorage.getItem('newEmail')),
          token: action.payload.token,
        };

        localStorage.setItem(
          AuthLocalStorage.profiles,
          JSON.stringify([newProfile]),
        );
        sessionStorage.removeItem('oldEmail');
        sessionStorage.removeItem('newEmail');
      }
    });

    //verify old phone
    builder.addCase(adminVerifyPhone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(adminVerifyPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(adminVerifyPhone.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldPhone = true;
    });
    //set new email
    builder.addCase(adminSetNewPhone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(adminSetNewPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(adminSetNewPhone.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload.token) {
        setUsersRefreshTokenSessionStorage(action.payload);

        state.currentUser = {
          ...state.currentUser!,
          phone: String(sessionStorage.getItem('newPhone')),
        };

        const profiles = JSON.parse(
          localStorage.getItem(AuthLocalStorage.profiles) as string,
        );

        const newProfile = {
          ...profiles[0],
          phone: String(sessionStorage.getItem('newPhone')),
          token: action.payload.token,
        };

        localStorage.setItem(
          AuthLocalStorage.profiles,
          JSON.stringify([newProfile]),
        );
        sessionStorage.removeItem('oldPhone');
        sessionStorage.removeItem('newPhone');
      }
    });
  },
});

export const {
  setAdminData,
  resetAdminData,
  setCurrentData,
  resetVerifiedOldPhoneCode,
  resetPhoneData,
  setIsResentActive,
  setIsWrongVerificationCode,
  setIsAdminUpdated,
  resetAdminError,
} = adminsSlice.actions;

export default adminsSlice.reducer;
