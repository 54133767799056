import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';
import { ContactsType } from 'common/types/clinics';
import { ContactsChildProps } from './models';
import SocialInput from 'common/components/SocialInput';

import {
  Close,
  InputWrapper,
  InputContainer,
  WrapperMargin,
  Container,
} from './styles';
import { ProfessionalGeneralInfoT } from '../ProfessionalAddForm/types';

const Social = ({
  errors,
  control,
  register,
  getValues,
  disabled,
}: ContactsChildProps) => {
  const { t } = useTranslation();

  const prependKey = 'socialMedia';
  const { fields, append, remove, update } =
    useFieldArray<ProfessionalGeneralInfoT>({
      control,
      name: prependKey,
    });

  const handleAddAnother = () => {
    append({
      name: 'Facebook' as ContactsType,
      url: '',
    });
  };

  return (
    <WrapperMargin>
      {fields.map((field, index) => {
        const id = `${prependKey}.${index}.url`;
        const values = getValues(`${prependKey}.${index}`);
        const error = errors?.[prependKey]?.[index]?.url?.message;
        const label = index === 0 ? t('edit_contacts.social_media') : undefined;

        return (
          <InputWrapper key={field.id} last={index === fields.length - 1}>
            <InputContainer>
              <SocialInput
                id={id}
                type="text"
                label={label}
                register={register}
                errorMessage={error}
                socialType={values.name as ContactsType}
                placeholder={t('edit_contacts.social_media') as string}
                onTypeSelect={(name) => update(index, { ...values, name })}
                disabled={disabled}
              />
            </InputContainer>
            {index !== 0 && !disabled && (
              <Close onClick={() => remove(index)} />
            )}
          </InputWrapper>
        );
      })}
      <Container onClick={handleAddAnother} disabled={disabled}>
        <PlusIcon />
        <p>{t('add_another')}</p>
      </Container>
    </WrapperMargin>
  );
};

export default Social;
