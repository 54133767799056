import styled from 'styled-components';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 500px;
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.color.grey500};
    }
  }

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-bottom: 16px;
    > span {
      color: ${({ theme }) => theme.color.blue700};
    }
  }

  > div {
    > p {
      color: ${({ theme }) => theme.color.grey700};
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
    }
    > div {
      display: flex;
      margin-top: 8px;
      color: ${({ theme }) => theme.color.grey700};
      font-size: 18px;
      font-weight: 500;
      line-height: 25.2px;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        path {
          fill: ${({ theme }) => theme.color.error2};
        }
      }
    }
  }

  > section {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    > button {
      width: 120px;
      &:last-child {
        margin-left: 20px;
      }
    }
  }
`;
