import React, { FC, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchAppointmentById } from 'applicaiton/store/reducers/Appointments/ActionCreators';
import { useAppDispatch } from 'common/hooks/redux';
import { Header, VideoComponent } from './components';
import { Wrapper } from './styles';
import { useTeleconsultation } from './hooks/useTeleconsultation';
import { useTeleconsultationChat } from './hooks/useTeleconsultationChat';
import { Loader } from 'common/components';

const Teleconsultation: FC = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const {
    startTeleconsultationByAppointmentId,
    accessToken,
    startTeleconsultationByRoomId,
    isLoading,
  } = useTeleconsultation();
  const { initChatClient, destroyChatClient } = useTeleconsultationChat();

  const roomId = useMemo(() => searchParams.get('room'), [searchParams]);

  useEffect(() => {
    if (id) {
      dispatch(fetchAppointmentById(id));
      startTeleconsultationByAppointmentId(id);
    } else if (roomId) {
      startTeleconsultationByRoomId(roomId);
    } else {
      console.error('Teleconsultation data is not provided');
    }
  }, [id, roomId]);

  useEffect(() => {
    if (accessToken) {
      initChatClient(accessToken);
    }
    return () => {
      destroyChatClient();
    };
  }, [accessToken]);

  return (
    <Wrapper>
      <Header />
      <VideoComponent />
      {isLoading && <Loader />}
    </Wrapper>
  );
};

export default Teleconsultation;
