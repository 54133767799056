import { DayView } from '@devexpress/dx-react-scheduler-material-ui';
import styled from 'styled-components';

interface StyledProps {
  isHour: boolean;
  isWorkingTime?: boolean;
  isAbsence?: boolean;
}

export const TimeScaleLabelStyled = styled.div<StyledProps>`
  height: 14px;
  width: 56px;
  display: flex;
  justify-content: center;
  margin: 0;
  border-top: ${({ isHour }) => (isHour ? '1px solid' : 'none')};
  border-color: ${({ theme }) => theme.color.grey300};
  color: ${({ theme }) => theme.color.grey800};
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
`;

export const TimeTableCellStyled = styled.td<StyledProps>`
  height: 14px;
  border-right: 1px solid;
  border-bottom: ${({ isHour }) => (isHour ? '1px solid' : 'none')};
  background-color: ${({ theme, isAbsence, isWorkingTime }) =>
    isAbsence ? theme.color.grey300 : isWorkingTime ? 'white' : '#F3F4F7'};
  cursor: ${({ isAbsence }) => (isAbsence ? 'pointer' : 'auto')};
  line-height: 0;

  > span {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 8px;
    line-height: 12px;
  }

  > p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 8px;
    line-height: 12px;
  }

  @-moz-document url-prefix() {
    height: ${({ isAbsence }) => (isAbsence ? 'auto' : '14px')};
    > span {
      line-height: 0;
    }
  }
`;

export const StyledDayViewDayScaleCell = styled(DayView.DayScaleCell)`
  p {
    color: ${({ theme }) => theme.color.grey800};
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  div {
    color: ${({ theme }) => theme.color.grey800};
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
