import React from 'react';
import { ButtonsGroupStyled } from './styles';
import { ClinicProfessionalActiveToggleProps } from './modules';
import { useTranslation } from 'react-i18next';

export const ClinicProfessionalActiveToggle = ({
  isActive,
  setIsActive,
}: ClinicProfessionalActiveToggleProps) => {
  const { t } = useTranslation();
  return (
    <ButtonsGroupStyled>
      <div
        className={`active ${isActive ? 'selected' : ''}`}
        onClick={() => setIsActive(true)}
      >
        {t('active')}
      </div>
      <div
        className={`${isActive ? '' : 'selected'}`}
        onClick={() => setIsActive(false)}
      >
        {t('not_active')}
      </div>
    </ButtonsGroupStyled>
  );
};
