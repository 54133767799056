import React, { FC } from 'react';
import { DropdownClinics } from 'common/components';
import { FilterSectionProps } from './modules';
import { ControllerSection, FilterSectionStyled } from './styles';
import { Option } from 'common/components/Dropdown/models';

const FilterSection: FC<FilterSectionProps> = ({ clinics, setClinic }) => {
  return (
    <FilterSectionStyled>
      <ControllerSection>
        <DropdownClinics
          isAllDataReturn
          value={clinics}
          onChange={(value) => {
            setClinic(value as Option[]);
          }}
          isMulti={true}
        />
      </ControllerSection>
    </FilterSectionStyled>
  );
};

export default FilterSection;
