import React, { FC, useState, useMemo } from 'react';
import {
  AppointmentWrapper,
  ClinicSlotsWrapper,
  StyledSleketon,
  TimeSlotWrapper,
  Title,
  TitleWrapper,
  ButtonWrapper,
} from './styles';
import DateSelector from './DateSelector';
import { Props } from './models';
import { Box } from '@mui/system';
import { getTimeIn24H } from 'common/helpers/dateTimeFormat';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { TimeZone } from 'applicaiton/constants/timeZone';
import { useAppDispatch } from 'common/hooks/redux';
import { setWaitingStartDate } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';

const tz = TimeZone.Lisbon;

const BookAnAppointment: FC<Props> = ({ appointment, onClose, onEdit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedDateTime, setSelectedDateTime] = useState('');

  const newSlots = useMemo(() => {
    const slotsByDate = appointment?.availableSlots?.reduce(function (r, a) {
      r[dayjs(a.startDate).tz(tz).format('YYYY-MM-DD')] =
        r[dayjs(a.startDate).tz(tz).format('YYYY-MM-DD')] || [];
      r[dayjs(a.startDate).tz(tz).format('YYYY-MM-DD')].push(a);
      return r;
    }, Object.create(null));

    const slots = Object.keys(slotsByDate).map((item) => {
      return { availableSlots: slotsByDate[item], day: item, gaps: [] };
    });

    return slots;
  }, [appointment, selectedDay]);

  const showTimeSlot =
    selectedDay && newSlots && appointment.appointmentType.id;

  const allDaysAreEmpty = newSlots.every((slot) =>
    slot.availableSlots.every(
      (availableSlot: any) => availableSlot.startDate === '-',
    ),
  );

  const filteredFormattedSlots = newSlots.filter(
    (slotMain) => slotMain.day === selectedDay,
  );

  const availableSlot = newSlots && newSlots[0]?.day;

  const handleEdit = () => {
    dispatch(setWaitingStartDate(selectedDateTime));

    onEdit();
  };
  return (
    <AppointmentWrapper>
      <Title>
        <h6>{t('book_appointment.available_early_slots')}</h6>
        <p>{t('book_appointment.select_one')}</p>
      </Title>

      {newSlots && appointment ? (
        <DateSelector
          slots={newSlots}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        />
      ) : null}

      <Box>
        {showTimeSlot && !!filteredFormattedSlots.length && (
          <TitleWrapper>{t('book_appointment.select_time')}</TitleWrapper>
        )}
        <ClinicSlotsWrapper>
          {showTimeSlot
            ? filteredFormattedSlots.map((slot) => {
                return slot.availableSlots.map(
                  (available: any, index: number) => {
                    return (
                      <>
                        {available.startDate !== '-' && (
                          <TimeSlotWrapper
                            key={index}
                            active={
                              String(available.startDate) === selectedDateTime
                                ? 1
                                : 0
                            }
                            onClick={() => {
                              setSelectedDateTime(String(available.startDate));
                            }}
                          >
                            {getTimeIn24H(
                              dayjs
                                .tz(available.startDate, tz)
                                .format('YYYY-MM-DDTHH:mm'),
                            )}
                          </TimeSlotWrapper>
                        )}
                      </>
                    );
                  },
                );
              })
            : null}

          {allDaysAreEmpty && availableSlot ? (
            <StyledSleketon>
              <section>
                <Box>-</Box>
                <Box>-</Box>
              </section>
              <section>
                <Box>-</Box>
                <Box>-</Box>
              </section>
            </StyledSleketon>
          ) : null}
        </ClinicSlotsWrapper>
      </Box>
      <ButtonWrapper>
        <SecondaryButton type={'button'} onClick={onClose}>
          {t('cancel')}
        </SecondaryButton>
        <PrimaryButton
          type={'button'}
          onClick={handleEdit}
          disabled={!selectedDateTime || !selectedDay}
        >
          {t('edit')}
        </PrimaryButton>
      </ButtonWrapper>
    </AppointmentWrapper>
  );
};

export default BookAnAppointment;
