import React, { FC } from 'react';
import { Wrapper } from './styles';
import { SecretaryEdit, SideMenu } from 'features';

const SecretaryEditPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />

      <SecretaryEdit />
    </Wrapper>
  );
};

export default SecretaryEditPage;
