import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AbsenceState } from './modules';
import {
  createAbsence,
  deleteAbsence,
  getAbsenceById,
  updateAbsence,
} from './ActionCreators';
import { AbsenceResponseDto, AbsenceUpdateResponseDto } from '@docbay/schemas';

const initialState: AbsenceState = {
  absence: null,
  isLoading: false,
  error: '',
  showDeleteModal: false,
  isSuccessDeleted: false,
  deletedSuccess: false,
  isConfusingAbsence: false,
};

const AbsenceSlice = createSlice({
  name: 'agendaSlice',
  initialState,
  reducers: {
    resetAbsence: (state) => {
      state.absence = null;
      state.showDeleteModal = false;
      state.deletedSuccess = false;
      state.isSuccessDeleted = false;
      state.isConfusingAbsence = false;
    },
    setAbsenceToDelete: (
      state,
      action: PayloadAction<AbsenceResponseDto | null>,
    ) => {
      state.absence = action.payload;
      state.showDeleteModal = true;
      state.isConfusingAbsence = true;
    },
    setShowDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.showDeleteModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    // update absence
    builder.addCase(updateAbsence.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAbsence.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.absence = null;
    });
    builder.addCase(updateAbsence.fulfilled, (state) => {
      state.isLoading = false;
    });
    // get absence by id
    builder.addCase(getAbsenceById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAbsenceById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.absence = null;
    });
    builder.addCase(getAbsenceById.fulfilled, (state, action) => {
      state.absence = action.payload;
      state.isLoading = false;
    });
    // delete absence
    builder.addCase(deleteAbsence.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAbsence.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.deletedSuccess = false;
    });
    builder.addCase(deleteAbsence.fulfilled, (state) => {
      state.deletedSuccess = true;
      state.isLoading = false;
    });
    // create absence
    builder.addCase(createAbsence.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createAbsence.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(
      createAbsence.fulfilled,
      (state, action: PayloadAction<AbsenceUpdateResponseDto | null>) => {
        state.absence = action.payload?.absence || null;
        state.isLoading = false;
      },
    );
  },
});

export const { resetAbsence, setShowDeleteModal, setAbsenceToDelete } =
  AbsenceSlice.actions;

export default AbsenceSlice.reducer;
