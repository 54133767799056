import { ClinicResponseDto } from '@docbay/schemas';

export const checkMandatoryFields = (
  clinicProfileData: ClinicResponseDto | null,
) => {
  const phoneExist = clinicProfileData?.contacts?.some(
    (item) => item.type === 'Phone',
  );
  const emailExist = clinicProfileData?.contacts?.some(
    (item) => item.type === 'Email',
  );
  const isRequiredFieldsFilled =
    !!clinicProfileData?.name &&
    !!clinicProfileData.description &&
    !!clinicProfileData?.address?.streetName &&
    !!clinicProfileData?.address.streetNumber &&
    !!clinicProfileData?.address.zipCode &&
    phoneExist &&
    emailExist &&
    !!clinicProfileData?.openingHours?.length;

  return isRequiredFieldsFilled;
};
