import axiosInstance from 'integration/api/axiosInstance';

import {
  CommonEntitiesListResponse,
  AppointmentTypeResponseDto,
  ProfessionalAgendaResponseDto,
  AppointmentTypeCreationDto,
  AppointmentTypeUpdateDto,
  CustomAppointmentTypeNotificationsUpdateDto,
  AppointmentTypeNotificationConfigRequestDto,
} from '@docbay/schemas';

interface Props {
  page?: number;
  limit?: number;
  specializationIds?: string;
  clinicId?: string;
  professionalIds?: string;
  onlyProfessional?: boolean;
}

export const AppointmentTypesApi = {
  fetchAppointmentTypes: ({
    page,
    limit,
    clinicId,
    specializationIds,
    professionalIds,
    onlyProfessional,
  }: Props) => {
    const params = {
      includeDeleted: 'false',
      include: 'clinic,professionals,specialization,notificationConfig',
      ...(clinicId ? { clinicId } : {}),
      ...(specializationIds ? { specializationIds } : {}),
      ...(professionalIds ? { professionalIds } : {}),
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(onlyProfessional ? { onlyProfessional } : {}),
    };

    return axiosInstance.get<
      CommonEntitiesListResponse<AppointmentTypeResponseDto>
    >('/appointment-type', {
      params,
    });
  },
  postAppointmentType: (data: AppointmentTypeCreationDto) =>
    axiosInstance.post<ProfessionalAgendaResponseDto[]>(
      '/appointment-type',
      data,
    ),
  fetchAppointmentTypeById: (id: string) => {
    return axiosInstance.get<AppointmentTypeResponseDto>(
      `/appointment-type/${id}?include=clinic,professionals,specialization,notifications,customNotifications`,
    );
  },
  updateAppointmentType: (id: string, data: AppointmentTypeUpdateDto) => {
    return axiosInstance.patch(`/appointment-type/${id}`, data);
  },
  deleteAppointmentType: (id: string) =>
    axiosInstance.delete(`/appointment-type/${id}`),
  updateAppointmentTypeNotifications: (
    id: string,
    data: CustomAppointmentTypeNotificationsUpdateDto,
  ) =>
    axiosInstance.patch(
      `/appointment-type/custom-part-of-notifications/${id}`,
      data,
    ),
  changeAppointmentTypeNotificationsConfig: (
    id: string,
    data: AppointmentTypeNotificationConfigRequestDto,
  ) =>
    axiosInstance.patch(`/appointment-type/notifications-config/${id}`, data),
};
