import React from 'react';

import { ChatHeaderWrapper, MenuButtonStyled, BackButton } from './styles';
import { DotsIcon } from 'applicaiton/assets';
import { ActiveMassMessageMenu } from '../ActiveMassMessageMenu';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'common/hooks/redux';

export const ActiveMassMessageChatHeader = ({
  showPatientsList,
  isShowPatients,
  onBack,
}: {
  showPatientsList: () => void;
  isShowPatients: boolean;
  onBack: () => void;
}) => {
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const { massMessagesPatientsIds } = useAppSelector((state) => state.chats);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <ChatHeaderWrapper>
        <div>
          <h1 className={'patient-name'}>{t('chats.mass_messages')}</h1>
          <p className={'patient-status'}>
            {massMessagesPatientsIds?.length} {t('selected')}
          </p>
        </div>
        {isShowPatients ? (
          <BackButton onClick={onBack}>Back</BackButton>
        ) : (
          <MenuButtonStyled onClick={handleOpenMenu}>
            <DotsIcon />
          </MenuButtonStyled>
        )}
      </ChatHeaderWrapper>
      <ActiveMassMessageMenu
        anchorEl={menuAnchor}
        onClose={handleClose}
        showPatientsList={showPatientsList}
      />
    </>
  );
};
