import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ProfessionalResponseDto,
  SpecializationResponseDto,
} from '@docbay/schemas';
import { useAppDispatch } from 'common/hooks/redux';
import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import {
  ProfessionalNameStyled,
  StyledTr,
  ActionsStyled,
  AvatarStyled,
  ResendButton,
} from './styles';
import { selectProfessional } from 'applicaiton/store/reducers/Professionals/ProfessionalsSlice';
import BadgeWithCheck from 'common/components/BadgeWithCheck';
import { Box } from '@mui/system';
import { resendProfessionalAuthorizationData } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import SuccessResendLink from 'common/components/SuccessResendLink';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';

interface ProfessionalItemProps {
  professional: ProfessionalResponseDto;
}

const ProfessionalItem: FC<ProfessionalItemProps> = ({ professional }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();

  const [isResentLink, setIsResentLink] = useState(false);

  const handleDeleteProfessional = () => {
    dispatch(selectProfessional(professional));
  };

  const getAllSpecializationNames = (
    specializations: SpecializationResponseDto[],
  ) => {
    return specializations.map((spec) => spec[localizeNameKey]);
  };

  const handleResendAuthProfessional = () => {
    dispatch(resendProfessionalAuthorizationData(professional.id)).then(
      (data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          setIsResentLink(true);
        }
      },
    );
  };

  const closeResendModal = () => {
    setIsResentLink(false);
  };

  return (
    <StyledTr>
      <td>
        <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
          <ProfessionalNameStyled>
            {professional.mainPhoto ? (
              <img
                src={professional.mainPhoto.thumbnailUrl}
                alt={professional.mainPhoto.id}
              />
            ) : (
              <AvatarStyled>
                <PersonIcon />
              </AvatarStyled>
            )}
            <div>
              <p>{professional.firstName + ' ' + professional.lastName}</p>
              <span>{professional.user?.email}</span>{' '}
            </div>{' '}
          </ProfessionalNameStyled>
          {!professional.active ? <BadgeWithCheck /> : null}
        </Box>
      </td>
      <td>
        {professional.specializations &&
          professional.specializations.length > 0 &&
          getAllSpecializationNames(professional.specializations).join(', ')}
      </td>
      <td>
        {!professional.active && (
          <>
            <ResendButton onClick={handleResendAuthProfessional}>
              {t('professionalsListConfiguration.resend_access_link')}
            </ResendButton>
            {isResentLink && (
              <SuccessResendLink
                onClose={closeResendModal}
                email={professional.user?.email!}
              />
            )}
          </>
        )}
      </td>
      <td>
        <ActionsStyled>
          <Link to={`${PathNames.editProfessional}/${professional.id}`}>
            <EditIcon />
          </Link>
          <button onClick={() => handleDeleteProfessional()}>
            <DeleteIcon />
          </button>
        </ActionsStyled>
      </td>
    </StyledTr>
  );
};
export default ProfessionalItem;
