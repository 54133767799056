import styled from 'styled-components';

interface StyledItemProps {
  isSelected: boolean;
}

export const AsideStyled = styled.aside`
  display: grid;
  height: inherit;
  min-width: 284px;
  max-width: 284px;
  padding: 12px 64px 32px 0;
  margin-bottom: 80px;
  grid-template-rows: min-content min-content min-content;
  gap: 4px;
  border-bottom: 1px solid #eaecf0;

  > div {
    > hr {
      height: 1px;
      border-color: ${({ theme }) => theme.color.grey200};
    }
    grid-template-rows: min-content;
    display: grid;

    gap: 4px;
  }

  > button {
    height: fit-content;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    > svg {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      g {
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }

    > h2 {
      color: ${({ theme }) => theme.color.grey600};
      font-size: 16px;
      font-weight: 700;
      line-height: 22.4px;
      letter-spacing: 0.08px;
    }
  }
`;

export const StyledItem = styled.p<StyledItemProps>`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px 12px;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.grey800 : theme.color.grey700};
  border-radius: 6px;
  font-size: 16px;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '500')};
  line-height: 22.4px;
  cursor: pointer;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.color.green100 : 'transparent'};

  &:hover {
    background: ${({ theme, isSelected }) =>
      isSelected ? theme.color.green100 : theme.color.grey100};
  }
`;
