import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PaymentSection from './PaymentSection';
import ButtonSection from '../ButtonSection';
import { DividerStyled, Title, Wrapper } from './styles';

import {
  createClinicProfile,
  saveClinicProfile,
} from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { PathNames } from 'applicaiton/routes';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { ClinicPaymentMethod } from '@docbay/schemas';
import { PaymentMethodsProps } from './models';

const PaymentMethods: FC<PaymentMethodsProps> = ({ handleSetNextPage }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const isCreatePage = location.pathname.endsWith('create');
  const { clinicProfileData } = useAppSelector((state) => state.clinicProfile);
  const [paymentMethods, setPaymentMethods] = useState<ClinicPaymentMethod[]>(
    clinicProfileData?.options?.paymentMethods || [],
  );

  const handleChangePaymentMethods = (value: ClinicPaymentMethod) => {
    const isValueExist = paymentMethods.some((item) => item === value);
    if (isValueExist) {
      setPaymentMethods((prev) => prev.filter((item) => item !== value));
    } else {
      setPaymentMethods((prev) => [...prev, value]);
    }
  };

  const handleSave = async () => {
    const canMoveNext = isCreatePage;
    if (isCreatePage && !clinicProfileData) {
      const response = await dispatch(
        createClinicProfile({
          name: '',
          ...(paymentMethods.length ? { options: { paymentMethods } } : {}),
        }),
      );
      if (response.meta.requestStatus === 'fulfilled' && canMoveNext) {
        handleSetNextPage();
      }
    } else {
      const response = await dispatch(
        saveClinicProfile({
          id: clinicProfileData?.id!,
          data: { options: { paymentMethods } },
        }),
      );
      if (response.meta.requestStatus === 'fulfilled' && canMoveNext) {
        handleSetNextPage();
      }
    }
  };

  const handleCancel = () => {
    navigate(PathNames.myClinics);
  };

  const isStateChanged = useMemo(() => {
    const hasChanges =
      (clinicProfileData?.options?.paymentMethods || [])?.length !==
      paymentMethods.length;

    return hasChanges;
  }, [paymentMethods, clinicProfileData?.options?.paymentMethods]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const isButtonDisabled = isCreatePage ? false : !isStateChanged;
  const showNextButton = isCreatePage;

  return (
    <Wrapper>
      <div>
        <Title>{t('clinicPaymentTitle')}</Title>
        <PaymentSection
          paymentMethods={paymentMethods}
          handleChange={handleChangePaymentMethods}
        />
      </div>
      <div>
        <DividerStyled />
        <ButtonSection
          onSave={handleSave}
          onCancel={handleCancel}
          disabled={isButtonDisabled}
          showNextButton={showNextButton}
        />
      </div>
    </Wrapper>
  );
};

export default PaymentMethods;
