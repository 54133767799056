import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, HeaderStyled, ClinicInfo, ClinicImage } from './styles';
import { SecondaryButton } from 'common/components';
import { ReactComponent as EyeOpenIcon } from 'applicaiton/assets/eye-open.svg';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { selectProfessional } from 'applicaiton/store/reducers/Professionals/ProfessionalsSlice';
import { Link } from 'react-router-dom';
import { DocBayPatientLandingLink } from 'applicaiton';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { DeleteProfessionalModal } from '../../../index';

const ProfileEditHeader = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { profileChanges, editedProfessional } = useAppSelector(
    (state) => state.professionalProfile,
  );

  const professional = useMemo(() => {
    return {
      ...editedProfessional,
      ...profileChanges,
    };
  }, [editedProfessional, profileChanges]);

  const currentPhoto =
    profileChanges?.photos?.length && profileChanges.photos[0].isMain
      ? profileChanges.photos[0].thumbnailUrl
      : editedProfessional?.mainPhoto?.thumbnailUrl;

  const handleDeleteProfessional = () => {
    dispatch(selectProfessional(editedProfessional));
  };

  const linkToPatientAppProfile = useMemo(() => {
    const language = getLanguageFromLocalStorage();

    return `${DocBayPatientLandingLink}${language}/professional/${professional.id}`;
  }, [professional.id, i18n.language]);

  return (
    <HeaderStyled>
      <ClinicInfo>
        <ClinicImage>
          {currentPhoto ? (
            <img src={currentPhoto} alt={currentPhoto} />
          ) : (
            <PersonIcon />
          )}
        </ClinicImage>
        <h1>{professional?.firstName + ' ' + professional?.lastName}</h1>
      </ClinicInfo>
      <ButtonWrapper>
        <SecondaryButton onClick={handleDeleteProfessional}>
          {t('delete')}
        </SecondaryButton>
        <Link to={linkToPatientAppProfile} target="_blank">
          <SecondaryButton styleType="color">
            <EyeOpenIcon />
            {t('previewProfile')}
          </SecondaryButton>
        </Link>
      </ButtonWrapper>
      <DeleteProfessionalModal />
    </HeaderStyled>
  );
};

export default ProfileEditHeader;
