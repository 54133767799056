import React, { FC } from 'react';
import { Wrapper } from './styles';

interface Props {
  children: React.ReactNode;
}
const PageSwitcher: FC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default PageSwitcher;
