import React, { useMemo } from 'react';
import {
  Wrapper,
  ItemsList,
  ItemStyled,
  ItemTeleconsultationStyled,
} from './styles';
import dayjs from 'dayjs';
import {
  CalendarIcon,
  ClockIcon,
  ClinicIcon,
  AccountIcon,
  OnlineIcon,
  BadgeIcon,
} from 'applicaiton/assets/index';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'common/hooks/redux';
import { MeetingPlace } from 'applicaiton/store/reducers/AppointmentTypes/AppointmentTypesSlice';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { Gender } from 'features/feature-patient-profile/constants';

const DoctorInfo = () => {
  const { t, i18n } = useTranslation();
  const language = getLanguageFromLocalStorage();
  const localizeKey = `name_${language.toUpperCase()}` as 'name_EN' | 'name_PT';
  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const isTeleconsultation = useMemo(() => {
    return (
      currentAppointment?.appointmentType.meetingPlace ===
      MeetingPlace.Teleconsultation
    );
  }, [currentAppointment]);

  const pronunciation = useMemo(() => {
    const isMale = currentAppointment?.patient?.pronunciation === Gender.Male;
    return isMale ? t('drMale') : t('drFemale');
  }, [currentAppointment, i18n.language]);

  return (
    <Wrapper>
      <h2>{t('consultation_data.consultation_data')}</h2>
      <ItemsList>
        {isTeleconsultation ? (
          <ItemTeleconsultationStyled>
            <OnlineIcon />
            <span>{t('teleconsultationTitle')}</span>
          </ItemTeleconsultationStyled>
        ) : (
          <ItemStyled>
            <ClinicIcon />
            <span>{currentAppointment?.clinic!.name}</span>
          </ItemStyled>
        )}

        <ItemStyled>
          <CalendarIcon />
          <span>Date</span>
          <p>
            {dayjs(currentAppointment?.startDate)
              .local()
              .format('dddd, DD MMMM YYYY')}
          </p>
        </ItemStyled>
        <ItemStyled>
          <ClockIcon />
          <span>Time</span>
          <p>
            {dayjs(currentAppointment?.startDate).local().format('HH:mm')} -{' '}
            {dayjs(currentAppointment?.endDate).local().format('HH:mm')}
          </p>
        </ItemStyled>
        <ItemStyled>
          <AccountIcon />
          <span>
            {pronunciation} {currentAppointment?.professional?.firstName}{' '}
            {currentAppointment?.professional?.lastName}
          </span>
        </ItemStyled>
        <ItemStyled>
          <BadgeIcon />
          <span>{t('scheduler.specialization')}</span>
          <p>
            {currentAppointment?.appointmentType?.specialization?.[localizeKey]}
          </p>
        </ItemStyled>
      </ItemsList>
    </Wrapper>
  );
};

export default DoctorInfo;
