import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/unsuccess-icon.svg';
import { PrimaryButton } from 'common/components';
import { SuccessModalProps } from './models';
import { StyledModal, ButtonWrapper } from './styles';

const PatientAlreadyExistModal: FC<SuccessModalProps> = ({
  opened,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={opened} onClose={onClose}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <WarningIcon />
          <h1>{t('patient.patient_already_exists.title')}</h1>
          <p>{t('patient.patient_already_exists.sub_title')}</p>
          <ButtonWrapper>
            <PrimaryButton onClick={onClose}>{t('ok')}</PrimaryButton>
          </ButtonWrapper>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default PatientAlreadyExistModal;
