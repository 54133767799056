import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPermissionsByClinicOwnerId } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { getUserId } from 'applicaiton/sessionStorage/auth';
import { useClinicsConfiguration } from 'features/feature-user-permissions/hooks/useClinicsConfiguration';
import { UserType, ViewBy } from 'features/feature-user-permissions/constants';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader } from 'common/components';
import FilterSection from '../FilterSection';
import PermissionItem from '../PermissionItem';
import { TableStyled, Wrapper } from './styles';
import { useAgendaConfiguration } from 'features/feature-user-permissions/hooks/useAgendaConfiguration';

const PermissionsTable: FC = () => {
  const { t, i18n } = useTranslation();
  const userId = getUserId();
  const dispatch = useAppDispatch();
  const { permissions, isLoading } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const clinicsConfiguration = useClinicsConfiguration();
  const agendaConfiguration = useAgendaConfiguration();
  const [viewBy, setViewBy] = useState<ViewBy>(ViewBy.Agenda);

  useEffect(() => {
    dispatch(getPermissionsByClinicOwnerId(String(userId)));
  }, [userId]);

  const permissionsList = useMemo(() => {
    if (!permissions.length) return [];
    const isAgendaView = viewBy === ViewBy.Agenda;
    const configurations = isAgendaView
      ? agendaConfiguration
      : clinicsConfiguration;

    const configurationsWithPermissions = configurations.map((item) => {
      const adminPermission = permissions.find(
        (permission) =>
          permission.userType === UserType.Admin &&
          permission.permission.includes(item.key),
      );
      const professionalPermission = permissions.find(
        (permission) =>
          permission.userType === UserType.Professional &&
          permission.permission.includes(item.key),
      );
      const secretaryPermission = permissions.find(
        (permission) =>
          permission.userType === UserType.Secretary &&
          permission.permission.includes(item.key),
      );

      return {
        ...item,
        canBeChangedForAdmin: adminPermission?.canBeChanged,
        enabledForAdmin: adminPermission?.enabled,
        canBeChangedForProfessional: professionalPermission?.canBeChanged,
        enabledForProfessional: professionalPermission?.enabled,
        canBeChangedForSecretary: secretaryPermission?.canBeChanged,
        enabledForSecretary: secretaryPermission?.enabled,
      };
    });

    return configurationsWithPermissions;
  }, [permissions, viewBy, i18n.language]);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <FilterSection viewBy={viewBy} setViewBy={setViewBy} />
      <TableStyled>
        <thead>
          <tr>
            <th>
              <span>{t('features')}</span>
            </th>
            <th>
              <span>{t('admin')}</span>
            </th>
            <th>
              <span>{t('doctor')}</span>
            </th>
            <th>
              <span>{t('secretary')}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          {permissionsList.map((item) => (
            <PermissionItem
              key={item.key}
              permissionKey={item.key}
              name={item.name}
              canBeChangedForAdmin={!!item.canBeChangedForAdmin}
              enabledForAdmin={!!item.enabledForAdmin}
              canBeChangedForProfessional={!!item.canBeChangedForProfessional}
              enabledForProfessional={!!item.enabledForProfessional}
              canBeChangedForSecretary={!!item.canBeChangedForSecretary}
              enabledForSecretary={!!item.enabledForSecretary}
            />
          ))}
        </tbody>
      </TableStyled>
    </Wrapper>
  );
};

export default PermissionsTable;
