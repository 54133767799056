import styled from 'styled-components';
import { ButtonStyledProps } from './modules';

export const Wrapper = styled.section`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 32px 32px 0px 0px;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 10;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 44px;
  border-radius: 32px 32px 0px 0px;
  padding: 0px 16px;
  margin-bottom: 16px;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    height: 44px;
    color: ${({ theme }) => theme.color.grey900};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.04px;
  }

  button {
    all: unset;
    height: 44px;
    display: flex;
    align-items: center;
    width: fit-content;
    color: ${({ theme }) => theme.color.grey700};
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.04px;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }

    &:hover {
      color: ${({ theme }) => theme.color.blue800};
      svg {
        path {
          fill: ${({ theme }) => theme.color.blue800};
        }
      }
    }
  }
`;

export const ActionsList = styled.div`
  display: grid;
  grid-auto-rows: 44px;
  padding: 0 36px;
  margin-bottom: 24px;
`;

export const ButtonStyled = styled.button<ButtonStyledProps>`
  all: unset;
  position: relative;
  height: 44px;
  display: flex;
  align-items: center;

  color: ${({ theme, isActive }) =>
    isActive ? theme.color.blue500 : theme.color.blue800};
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: 0.04px;
  cursor: pointer;

  span {
    position: absolute;
    width: 9px;
    height: 9px;
    top: 7px;
    left: 18px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.color.background};
    background-color: ${({ theme }) => theme.color.error2};
    z-index: 10;
  }

  svg {
    width: 22px;
    height: 22px;
    margin-right: 9px;
    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.blue500 : theme.color.blue800};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.color.blue500};
    svg {
      path {
        fill: ${({ theme }) => theme.color.blue500};
      }
    }
  }
`;
