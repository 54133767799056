import { createAsyncThunk } from '@reduxjs/toolkit';
import { SecretariesActions } from 'applicaiton/constants/actions';
import { AxiosError } from 'axios';
import {
  SecretaryCreationDto,
  SecretarySetPasswordDto,
  SecretaryUpdateDto,
  SupportedLanguageIso,
  UserChangeEmailDto,
  UserChangePhoneDto,
  UserRequestChangePhoneDto,
  UserVerifyCodeDto,
} from '@docbay/schemas';
import { SecretariesAPI } from 'integration/api/secretaries';
import {
  getToken,
  setProfilesInSessionStorage,
  setUsersAuthSessionStorage,
} from 'applicaiton/sessionStorage/auth';

import {
  setIsCreatePassword,
  setIsVerificationCodeExpired,
} from '../Professionals/ProfessionalsSlice';
import { authService } from 'common';
import { UsersAPI } from 'integration/api/users';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

export const createSecretary = createAsyncThunk(
  SecretariesActions.createSecretary,
  async (params: SecretaryCreationDto, { rejectWithValue }) => {
    try {
      const { data } = await SecretariesAPI.createSecretary(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getSecretaryById = createAsyncThunk(
  SecretariesActions.getSecretaryById,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await SecretariesAPI.getSecretaryById(id);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteSecretary = createAsyncThunk(
  SecretariesActions.deleteSecretary,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await SecretariesAPI.deleteSecretary(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const editSecretary = createAsyncThunk(
  SecretariesActions.editSecretary,
  async (
    params: { id: string; data: SecretaryUpdateDto },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await SecretariesAPI.editSecretary(
        params.id,
        params.data,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const resendSecretariesAuthorizationData = createAsyncThunk(
  SecretariesActions.resendAuthorizationData,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await SecretariesAPI.resendSecretariesAuthorizationData(
        id,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const checkSecretariesVerificationCode = createAsyncThunk(
  SecretariesActions.checkSecretariesVerificationCode,
  async (
    params: { email: string; code: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await SecretariesAPI.checkSecretariesVerificationCode(
        params.email,
        params.code,
      );
      dispatch(setIsVerificationCodeExpired(false));
    } catch (e) {
      dispatch(setIsVerificationCodeExpired(true));
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createSecretariesPassword = createAsyncThunk(
  SecretariesActions.createSecretariesPassword,
  async (params: SecretarySetPasswordDto, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await SecretariesAPI.createSecretariesPassword(params);
      const secretaryProfile = data;

      authService.cleanStorage();

      const { token, expiresAt, refreshToken, user, secretary } =
        secretaryProfile;

      setUsersAuthSessionStorage({ token, expiresAt, refreshToken, user });
      setProfilesInSessionStorage([
        {
          id: user?.id!,
          email: user?.email!,
          firstName: user?.firstName!,
          lastName: user?.lastName!,
          photo: user?.photo!,
          token,
          refreshToken,
        },
      ]);
      secretaryProfile && dispatch(setIsCreatePassword(true));

      return secretary;
    } catch (e) {
      dispatch(setIsCreatePassword(false));
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const secretaryVerifyOldEmail = createAsyncThunk(
  SecretariesActions.secretaryVerifyOldEmail,
  async (params: { email: string }, { rejectWithValue }) => {
    try {
      const token = getToken();
      const language = getLanguageFromLocalStorage();
      const { data } = await UsersAPI.userVerifyOldEmail(
        { ...params, language },
        token,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const secretaryVerifyCode = createAsyncThunk(
  SecretariesActions.secretaryVerifyCode,
  async (params: UserVerifyCodeDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersVerifyCode(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const secretarySetNewEmail = createAsyncThunk(
  SecretariesActions.secretarySetNewEmail,
  async (params: UserChangeEmailDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const { data } = await UsersAPI.userSetNewEmail(params, token);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const secretaryVerifyPhone = createAsyncThunk(
  SecretariesActions.secretaryVerifyPhone,
  async (params: UserRequestChangePhoneDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const language = getLanguageFromLocalStorage();

      const { data } = await UsersAPI.userPhoneVerificationCode(
        { ...params, language: language as SupportedLanguageIso },
        token,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const secretarySetNewPhone = createAsyncThunk(
  SecretariesActions.secretarySetNewPhone,
  async (params: UserChangePhoneDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const { data } = await UsersAPI.userSetNewPhone(params, token);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
