export interface PromiseAndAction {
  promise: Promise<any>;
  action: (value: any) => void;
  onError?: (error: any) => void;
}

export const handleParallelPromises = (
  promiseAndActions: PromiseAndAction[],
  onFinally: () => void,
) => {
  const promises = promiseAndActions.map((pa) => pa.promise);
  const actions = promiseAndActions.map((pa) => pa.action);
  const errorHandlers = promiseAndActions.map((pa) => pa.onError);
  Promise.allSettled(promises)
    .then((results) => {
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          actions[index](result.value);
        } else if (result.status === 'rejected') {
          const onErrorCallback = errorHandlers[index];
          if (onErrorCallback) {
            onErrorCallback(result.reason);
          }
        }
      });
    })
    .finally(() => {
      onFinally();
    });
};

export const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));
