export interface GetNotificationsParamsDto {
  page: number;
  limit: number;
  search?: string;
}
export interface UpdateNotificationParamsDto {
  id: string;
  isRead: boolean;
}

export enum AppointmentNotificationsTypes {
  APPOINTMENT_CREATED = 'APPOINTMENT_CREATED',
  APPOINTMENT_RESCHEDULED = 'APPOINTMENT_RESCHEDULED',
  APPOINTMENT_CANCELLED = 'APPOINTMENT_CANCELLED',
}
