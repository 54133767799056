import styled from 'styled-components';

export const Wrapper = styled.main`
  flex-grow: 1;
  padding: 32px;
  max-height: 100vh;
  background: ${({ theme }) => theme.color.background};
  overflow-y: auto;
`;

export const AddNewProfessional = styled.div`
  font-family: AcidGrotesk-Bold;
  font-size: 28px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: capitalize;
  margin-top: 32px;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: AcidGrotesk-Bold;
  font-size: 28px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: capitalize;
  margin-top: 32px;
  margin-bottom: 20px;

  button {
    width: max-content;
  }
`;

export const WarningMessage = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.warning2};
  background: ${({ theme }) => theme.color.warning3};
  padding: 16px;
  color: ${({ theme }) => theme.color.warning};
  text-transform: none;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  margin-top: 20px;

  > svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    path {
      fill: ${({ theme }) => theme.color.warning2};
    }
  }

  > span {
    padding: 0 4px;
    font-weight: 700;
  }
`;
