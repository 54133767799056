import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';

import { useAppSelector } from 'common/hooks/redux';

import {
  PatientSearch,
  PatientInfo,
  PatientForm,
  FamilyMemberForm,
  MemberInfo,
} from 'features/feature-agenda-reserve-slots-modal/components';

import { PatientProps } from './models';
import {
  Wrapper,
  CreatePatientButton,
  PatientsTabsStyled,
  TabsStyled,
  Tab,
} from './styles';

const Patient: FC<PatientProps> = ({
  setValue,
  errors,
  watchPatient,
  getValuesPatient,
  setValuePatient,
  errorsPatient,
  registerPatient,
  handleSubmitPatient,
  clearErrorsPatient,
  isDirtyPatient,
  resetPatient,
  resetFm,
  setValueFM,
  errorsFM,
  getValuesFM,
  registerFM,
  clearErrorsFM,
  watchFM,
  isCreateAppointmentForFM,
  setIsCreateAppointmentForFM,
  showCreatePatientForm,
  setShowCreatePatientForm,
  hideCreateFamilyMemberButton,
  setHideCreateFamilyMemberButton,
  isEditMode,
}) => {
  const { t } = useTranslation();
  const { currentFamilyMemberSearch, currentPatient } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const [showCreateFamilyMemberForm, setShowCreateFamilyMemberForm] =
    useState(false);

  const hasPatientsErrors = !!Object.keys(errorsPatient).length;
  const hasFamilyMemberErrors = !!Object.keys(errorsFM).length;

  const handleShowCreatePatientForm = () => {
    setShowCreatePatientForm(!showCreatePatientForm);
  };

  const handleTogglePatientForms = () => {
    setShowCreatePatientForm(!showCreatePatientForm);
    setShowCreateFamilyMemberForm((prev) => !prev);
  };

  const onCancelCreatePatientForm = () => {
    resetPatient();
    resetFm();
    handleShowCreatePatientForm();
    setHideCreateFamilyMemberButton(false);
  };
  const onCancelFamilyMemberForm = () => {
    resetFm();
    handleTogglePatientForms();
    setHideCreateFamilyMemberButton(false);
  };

  const handleShowFamilyMemberTab = () => {
    handleTogglePatientForms();
    setHideCreateFamilyMemberButton(true);
  };

  const handleResetPatient = () => {
    resetPatient();
    resetFm();
    setShowCreatePatientForm(false);
  };

  const showPatientSearch =
    !!currentPatient ||
    !!currentFamilyMemberSearch ||
    (!showCreatePatientForm && !showCreateFamilyMemberForm);
  const showPatientInfo = !!currentPatient || !!currentFamilyMemberSearch;

  return (
    <Wrapper>
      {showPatientSearch ? (
        <>
          {!isEditMode && (
            <PatientSearch
              setValue={setValue}
              errorMessage={errors.patientId?.message}
              handleResetPatient={handleResetPatient}
            />
          )}
          {!!currentFamilyMemberSearch && <MemberInfo />}
          {!!currentPatient && !currentFamilyMemberSearch && <PatientInfo />}
          {!isEditMode && (
            <CreatePatientButton
              type="button"
              disabled={showPatientInfo}
              onClick={handleShowCreatePatientForm}
            >
              <PlusIcon /> {t('patient.create_patient')}
            </CreatePatientButton>
          )}
        </>
      ) : (
        <PatientsTabsStyled>
          <TabsStyled>
            <Tab
              type="button"
              isActive={showCreatePatientForm}
              onClick={handleTogglePatientForms}
              hasError={hasPatientsErrors}
            >
              {t('patient.new_patient')}
            </Tab>
            {hideCreateFamilyMemberButton && (
              <Tab
                type="button"
                isActive={showCreateFamilyMemberForm}
                onClick={handleTogglePatientForms}
                hasError={hasFamilyMemberErrors}
              >
                {t('patient.new_family_member')}
              </Tab>
            )}
          </TabsStyled>
          {showCreatePatientForm && (
            <PatientForm
              watchPatient={watchPatient}
              getValuesPatient={getValuesPatient}
              setValuePatient={setValuePatient}
              errorsPatient={errorsPatient}
              registerPatient={registerPatient}
              handleSubmitPatient={handleSubmitPatient}
              clearErrorsPatient={clearErrorsPatient}
              isDirtyPatient={isDirtyPatient}
              onCancelCreatePatientForm={onCancelCreatePatientForm}
              hideCreateFamilyMemberButton={hideCreateFamilyMemberButton}
              handleShowFamilyMemberTab={handleShowFamilyMemberTab}
            />
          )}
          {showCreateFamilyMemberForm && (
            <FamilyMemberForm
              setValueFM={setValueFM}
              errorsFM={errorsFM}
              getValuesFM={getValuesFM}
              registerFM={registerFM}
              clearErrorsFM={clearErrorsFM}
              watchFM={watchFM}
              onCancelFamilyMemberForm={onCancelFamilyMemberForm}
              isCreateAppointmentForFM={isCreateAppointmentForFM}
              setIsCreateAppointmentForFM={setIsCreateAppointmentForFM}
            />
          )}
        </PatientsTabsStyled>
      )}
    </Wrapper>
  );
};

export default Patient;
