import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { formatPhoneNumber } from '../helpers/number';
import { socialMediaValidation } from 'common/helpers/socialMediaValidation';

declare module 'yup' {
  interface StringSchema {
    phoneNumber(error: string): StringSchema;
    unique(type: string, error: string): StringSchema;
  }
}

yup.addMethod(yup.string, 'phoneNumber', function (errorMessage) {
  return this.test('test-phone-number', errorMessage, function (value) {
    const { path, createError } = this;
    if (!value) return true;

    return (
      isValidPhoneNumber(formatPhoneNumber(value)) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'unique', function (type, errorMessage) {
  return this.test('unique', errorMessage, (value, context) => {
    // @ts-ignore
    const copyValue = context.from[1].value[type].filter(
      // @ts-ignore
      (item) => {
        if (item.contactDetail.startsWith('+')) {
          return item.contactDetail.replace('+', '') === value;
        }
        return item.contactDetail === value;
      },
    );
    return copyValue.length <= 1;
  });
});

export const useEditContactsScheme = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const urlFiledError = t('errors.must_be_url');
  const phoneError = t('invalid_phone_number');
  const websiteError = t('must_be_url');
  const emailError = t('errors.emailFormat');
  const uniqError = t('errors.uniq_error');

  return yup.object().shape({
    emails: yup.array().of(
      yup.object().shape({
        contactDetail: yup
          .string()
          .email(emailError)
          .required(requiredError)
          .unique('emails', uniqError),
      }),
    ),
    phones: yup.array().of(
      yup.object().shape({
        contactDetail: yup
          .string()
          .phoneNumber(phoneError)
          .required(requiredError)
          .unique('phones', uniqError),
      }),
    ),
    emergency: yup.array().of(
      yup.object().shape({
        contactDetail: yup
          .string()
          .phoneNumber(phoneError)
          .unique('emergency', uniqError),
      }),
    ),
    website: yup.array().of(
      yup.object().shape({
        contactDetail: yup
          .string()
          .url(websiteError)
          .unique('website', uniqError),
      }),
    ),
    social: yup.array().of(
      yup.object().shape({
        contactDetail: yup
          .string()
          .test('matchSocialMedia', urlFiledError, function (value, context) {
            if (!value) return true;
            const isValid = socialMediaValidation(
              String(value),
              context.parent.type,
            );
            return isValid;
          })
          .unique('social', uniqError),
      }),
    ),
  });
};

export const useContactsScheme = () => {
  const { t } = useTranslation();
  const urlFiledError = t('errors.must_be_url');
  const phoneError = t('invalid_phone_number');
  const websiteError = t('must_be_url');
  const emailError = t('errors.emailFormat');
  const uniqError = t('errors.uniq_error');

  return yup.object().shape({
    emails: yup.array().of(
      yup.object().shape({
        contactDetail: yup
          .string()
          .email(emailError)
          .unique('emails', uniqError),
      }),
    ),
    phones: yup.array().of(
      yup.object().shape({
        contactDetail: yup
          .string()
          .phoneNumber(phoneError)
          .unique('phones', uniqError),
      }),
    ),
    emergency: yup.array().of(
      yup.object().shape({
        contactDetail: yup
          .string()
          .phoneNumber(phoneError)
          .unique('emergency', uniqError),
      }),
    ),
    website: yup.array().of(
      yup.object().shape({
        contactDetail: yup
          .string()
          .url(websiteError)
          .unique('website', uniqError),
      }),
    ),
    social: yup.array().of(
      yup.object().shape({
        contactDetail: yup
          .string()
          .test('matchSocialMedia', urlFiledError, function (value, context) {
            if (!value) return true;
            const isValid = socialMediaValidation(
              String(value),
              context.parent.type,
            );
            return isValid;
          })
          .unique('social', uniqError),
      }),
    ),
  });
};
