import styled from 'styled-components';

interface StyledProps {
  isError?: boolean;
  disabled?: boolean;
}

export const StyledInput = styled.div<StyledProps>`
  display: grid;
  gap: 6px;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${({ theme, isError, disabled }) =>
      isError
        ? theme.color.error2
        : theme.color[disabled ? 'grey400' : 'grey600']};
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: ${({ theme, isError, disabled }) =>
      isError
        ? theme.color.error2
        : theme.color[disabled ? 'grey400' : 'grey600']};
  }

  textarea {
    padding: 10px 14px;

    resize: none;
    width: 100%;
    background: ${({ theme, isError }) =>
      isError ? theme.color.error3 : theme.color.white};
    border: 1px solid
      ${({ theme, isError }) =>
        isError ? theme.color.error2 : theme.color.grey300};
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.color.grey700};

    :focus {
      background: ${({ theme, isError }) =>
        isError ? theme.color.error3 : theme.color.blue50};
      border: 1px solid
        ${({ theme, isError }) =>
          isError ? theme.color.error2 : theme.color.blue800};

      span {
        border-right: 1px solid ${({ theme }) => theme.color.blue300};
      }
    }

    ::placeholder {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: ${({ theme, disabled }) =>
        disabled ? theme.color.grey300 : theme.color.grey500};
    }
    :disabled {
      background: ${({ theme }) => theme.color.grey50};
      border: 1px solid ${({ theme }) => theme.color.grey300};
    }
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.color.error2};
    }
  }
`;

export const TextCounter = styled.p`
  margin-top: 6px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.grey600};
`;

export const TextAreaWrapper = styled.div<StyledProps>`
  position: relative;
  > svg {
    width: 17px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
    path {
      fill: ${({ theme, disabled }) =>
        disabled ? theme.color.grey300 : theme.color.grey500};
    }
  }

  span {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    svg {
      margin-top: 4px;
      width: 17px;
      height: 17px;
      path {
        fill: ${({ theme }) => theme.color.error2};
      }
    }
  }

  button {
    position: absolute;
    right: 0;
    cursor: pointer;
    width: 44px;
    height: 44px;
    border: none;
    background: none;
    svg {
      margin-top: 2px;
      path {
        fill: ${({ theme, disabled }) =>
          disabled ? theme.color.grey300 : theme.color.grey500};
      }
    }
  }
`;
