import { ColorsPallet } from 'applicaiton/theme';
import styled from 'styled-components';

interface StyledProps {
  active: boolean;
  theme?: { color: ColorsPallet };
}

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0 3px 0 rgba(11, 37, 80, 0.2);

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.color.grey500};
  }
`;

export const CounterWrapper = styled.div<StyledProps>`
  display: flex;
  width: 104px;
  height: 40px;
  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.color.blueDark700 : theme.color.grey400};
  border-radius: 6px;
  background-color: ${({ theme, active }) =>
    active ? theme.color.blue50 : 'transparent'};

  button {
    border: none;
    outline: none;
    background: transparent;
    border-radius: 6px;
    padding: 0 14px;
    cursor: pointer;
    &:hover {
      path {
        fill: ${({ theme }) => theme.color.blue600};
      }
    }
    &:disabled,
    button[disabled] {
      path {
        fill: ${({ theme }) => theme.color.grey300};
      }
    }
  }
  input {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    leading-trim: both;
    text-edge: cap;
    color: ${({ theme }) => theme.color.grey900};
  }
`;
