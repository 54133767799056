import { useSelector } from 'react-redux';
import { RootState } from 'applicaiton/store';
import { ChatsType } from 'applicaiton/store/reducers/Chats/models';
import {
  deselectChat,
  setSelectedChat,
  setSelectedChatsType,
  updateSelectedChat as updateSelectedChatAction,
} from 'applicaiton/store/reducers/Chats/ChatsSlice';
import {
  getAccessToken,
  getChats,
  createChat as createChatAction,
} from 'applicaiton/store/reducers/Chats/ActionCreators';
import { useAppDispatch } from 'common/hooks/redux';
import { ProfessionalChatResponseDto } from '@docbay/schemas';

export const useChats = () => {
  const dispatch = useAppDispatch();
  const chats = useSelector((state: RootState) => state.chats.chats);
  const { all } = chats;
  const selectedChatsType = useSelector(
    (state: RootState) => state.chats.selectedChatsType,
  );
  const selectedChat = useSelector(
    (state: RootState) => state.chats.selectedChat,
  );
  const accessToken = useSelector(
    (state: RootState) => state.chats.accessToken,
  );
  const isLoading = useSelector((state: RootState) => state.chats.isLoading);

  const fetchChats = async (topicName?: string, participantName?: string) => {
    await dispatch(
      getChats({
        topicName,
        participantName,
      }),
    );
  };

  const selectChat = (chat: ProfessionalChatResponseDto | null) => {
    dispatch(setSelectedChat(chat));
  };

  const updateSelectedChat = (chat: ProfessionalChatResponseDto | null) => {
    dispatch(updateSelectedChatAction(chat));
  };
  const deselectChatIfActive = (conversationSID: string) => {
    dispatch(deselectChat(conversationSID));
  };

  const selectChatsType = (chatsType: ChatsType) => {
    dispatch(setSelectedChatsType(chatsType));
  };

  const getToken = () => {
    dispatch(getAccessToken());
  };

  const createChat = (patientId: string) => {
    dispatch(createChatAction(patientId));
  };

  return {
    chats,
    chatsAll: all,
    selectedChatsType,
    selectedChat,
    accessToken,
    isLoading,
    setSelectedChat: selectChat,
    setSelectedChatType: selectChatsType,
    getToken,
    fetchChats,
    createChat,
    updateSelectedChat,
    deselectChatIfActive,
  };
};
