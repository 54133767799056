import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { DropdownClinics, Input } from 'common/components';
import { FilterSectionProps } from './modules';
import {
  ControllerSection,
  FilterSectionStyled,
  ItemList,
  ResetButton,
} from './styles';

const FilterSection: FC<FilterSectionProps> = ({
  clinics,
  searchItem,
  setSearchItem,
  setClinics,
  handleRemoveClinic,
}) => {
  const { t } = useTranslation();
  return (
    <FilterSectionStyled>
      <ControllerSection>
        <DropdownClinics
          value={clinics}
          onChange={(values) => {
            Array.isArray(values) && setClinics(values);
          }}
          isMulti={true}
        />
        <Input
          id={'search'}
          type="search"
          placeholder={t('search_by_professionals') || ''}
          value={searchItem}
          onChange={(e) => setSearchItem(e.target.value)}
          onClear={() => setSearchItem('')}
        />
      </ControllerSection>
      {clinics.length ? (
        <ItemList>
          <h2>{t('from_clinics')}:</h2>
          {clinics.map((clinic) => (
            <div key={clinic.value}>
              <p>{clinic.label}</p>
              <button onClick={() => handleRemoveClinic(String(clinic.value))}>
                <CloseIcon />
              </button>
            </div>
          ))}
          <ResetButton onClick={() => setClinics([])}>
            {t('clear_all')}
          </ResetButton>
        </ItemList>
      ) : (
        ''
      )}
    </FilterSectionStyled>
  );
};

export default FilterSection;
