export const requestNotificationPermissions = async () => {
  if (!('Notification' in window)) {
    return false;
  } else if (Notification.permission === 'granted') {
    return true;
  } else if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    const permission = await Notification.requestPermission();
    return permission === 'granted';
  }
  return false;
};
