import React, { useMemo } from 'react';
import { MessageListProps } from './modules';
import { Wrapper } from './styles';
import Message from '../Message';
import { getCurrentProfileCognitoUuid } from 'applicaiton/sessionStorage/auth';

const MessageList = ({ messages }: MessageListProps) => {
  const currentProfileCognitoUuid = getCurrentProfileCognitoUuid();
  const messagesToRender = useMemo(() => {
    return messages.map((item) => ({
      id: item.sid,
      isOwnMessage: item.author === currentProfileCognitoUuid,
      text: item.body || '',
      date: item.dateCreated || new Date(),
      file: item.document,
    }));
  }, [messages]);

  return (
    <Wrapper>
      {messagesToRender.map(({ id, text, date, isOwnMessage, file }) => (
        <Message
          key={id}
          id={id}
          message={text}
          date={date}
          isOwnMessage={isOwnMessage}
          file={file}
        />
      ))}
    </Wrapper>
  );
};

export default MessageList;
