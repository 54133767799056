import React from 'react';
import { TabItemStyled, Wrapper } from './styles';
import {
  AllChatsIcon,
  EmergencyChatsIcon,
  TeleconsultationChatsIcon,
  UnreadChatsIcon,
} from 'applicaiton/assets/chats';
import { useTranslation } from 'react-i18next';
import { useChats } from '../../hooks/useChats';
import { ChatsType } from 'applicaiton/store/reducers/Chats/models';

export const ChatsTabSelector = () => {
  const { t } = useTranslation();
  const { selectedChatsType, setSelectedChatType } = useChats();

  const handleTabChange = (type: ChatsType) => {
    setSelectedChatType(type);
  };
  const tabs = [
    {
      Icon: <AllChatsIcon />,
      label: t('chats.tabs.all'),
      type: ChatsType.ALL,
    },
    {
      Icon: <UnreadChatsIcon />,
      label: t('chats.tabs.unread'),
      type: ChatsType.UNREAD,
    },
    {
      Icon: <EmergencyChatsIcon />,
      label: t('chats.tabs.emergency'),
      type: ChatsType.EMERGENCY,
    },
    // todo uncomment after implementation priority chats
    // {
    //   Icon: <PriorityChatsIcon className="color-stroke" />,
    //   label: t('chats.tabs.priority'),
    //   type: ChatsType.PRIORITY,
    // },
    {
      Icon: <TeleconsultationChatsIcon />,
      label: t('chats.tabs.teleconsultation'),
      type: ChatsType.TELECONSULTATION,
    },
  ];

  return (
    <Wrapper>
      {tabs.map(({ Icon, label, type }) => (
        <TabItemStyled
          className={selectedChatsType === type ? 'active' : ''}
          onClick={() => handleTabChange(type)}
          key={type}
        >
          {Icon}
          <span>{label}</span>
        </TabItemStyled>
      ))}
    </Wrapper>
  );
};
