import styled from 'styled-components';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 664px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  gap: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

  h2 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-bottom: 8px;
    text-align: center;
    font-family: AcidGrotesk;
  }

  > svg {
    width: 20px;
    height: 20px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.color.grey500};
    }
  }
`;

export const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px;
  gap: 20px;

  button {
    width: 150px;
  }

  button:last-child {
    margin-left: 16px;
  }
`;
