import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewBy } from 'features/feature-patient-profile/constants';
import { FilterSectionProps } from './modules';
import { ButtonGroup, ButtonStyled, FilterSectionStyled } from './styles';

const FilterSection: FC<FilterSectionProps> = ({ viewBy, setViewBy }) => {
  const { t } = useTranslation();

  return (
    <FilterSectionStyled>
      <h1>{t('appointments')}</h1>
      <ButtonGroup>
        <ButtonStyled
          isActive={viewBy === ViewBy.Future}
          onClick={() => {
            setViewBy(ViewBy.Future);
          }}
        >
          {t('future')}
        </ButtonStyled>
        <ButtonStyled
          isActive={viewBy === ViewBy.Past}
          onClick={() => {
            setViewBy(ViewBy.Past);
          }}
        >
          {t('past')}
        </ButtonStyled>
      </ButtonGroup>
    </FilterSectionStyled>
  );
};

export default FilterSection;
