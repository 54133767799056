import React from 'react';
import { ProfessionalUpdateWarningsModalProps } from './models';
import { ModalComponent } from '../Modal';
import {
  ButtonContainerStyled,
  ContentContainerStyled,
  LinkStyled,
  ModalWrapper,
  WarningItemsListStyled,
  WarningItemStyled,
} from './styles';
import { SecondaryButton } from '../index';
import { WaringOutlinedIcon } from 'applicaiton/assets';
import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes';

export const ProfessionalUpdateWarningsModal = ({
  isOpen,
  warnings,
  onClose,
  onButtonClick,
  isClinicOwnerProfile = false,
}: ProfessionalUpdateWarningsModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalComponent
      width="660px"
      padding="32px"
      isOpen={isOpen}
      handleClose={onClose}
    >
      <ModalWrapper>
        <ContentContainerStyled>
          <h1 className={'title'}>
            {t('professional_update_warnings_modal.title')}
          </h1>
          <p className={'description'}>
            {isClinicOwnerProfile
              ? t(
                  'professional_update_warnings_modal.description_public_profile',
                )
              : t(
                  'professional_update_warnings_modal.description_professional_profile',
                )}
          </p>
          <WarningItemsListStyled>
            {warnings.map((warning, index) => (
              <WarningItemStyled key={index}>
                <WaringOutlinedIcon />
                <div className="warning-text">
                  <p>
                    <span className="clinic-name">{warning.clinicName}: </span>
                    {t('professional_update_warnings_modal.warning_item_text')}
                  </p>
                  <p>
                    {t(
                      'professional_update_warnings_modal.warning_item_description_1',
                    ) + ' '}
                    <LinkStyled
                      to={PathNames.typesAppointments}
                      target="_blank"
                    >
                      {t(
                        'professional_update_warnings_modal.warning_item_go_to_appointment_types_link',
                      )}
                    </LinkStyled>
                    {' ' +
                      t(
                        'professional_update_warnings_modal.warning_item_text_description_2',
                      )}
                  </p>
                </div>
              </WarningItemStyled>
            ))}
          </WarningItemsListStyled>
        </ContentContainerStyled>
        <ButtonContainerStyled>
          <SecondaryButton onClick={onButtonClick}>
            {isClinicOwnerProfile
              ? t('professional_update_warnings_modal.back_to_profile_button')
              : t('professional_update_warnings_modal.close_button')}
          </SecondaryButton>
        </ButtonContainerStyled>
      </ModalWrapper>
    </ModalComponent>
  );
};
