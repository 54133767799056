import React, { FC, useEffect } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  DeleteButton,
  ImageInput,
  ImageItem,
  ImagesInfo,
  ImagesList,
  ImagesListItem,
  ImageTypes,
  MainImage,
  StyledInputLabel,
  UploadedImages,
  UploadWrapper,
  UploadingProgress,
  UploadingText,
  Loader,
  Text,
} from './styles';
import { ReactComponent as AttentionIcon } from 'applicaiton/assets/attention.svg';
import { ReactComponent as RemoveIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as LoadIcon } from 'applicaiton/assets/loader.svg';
import { ReactComponent as InfoIcon } from 'applicaiton/assets/info.svg';
import { SecondaryButton } from '../index';
import { ReactComponent as UploadIcon } from 'applicaiton/assets/upload.svg';
import { useTranslation } from 'react-i18next';
import { Photo } from 'common/types/clinics';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { uploadNewPhoto } from 'applicaiton/store/reducers/Photo/ActionCreators';
import {
  deletePhoto,
  uploadedPhoto,
} from 'applicaiton/store/reducers/Photo/PhotoSlice';
import { PhotoUploaderMultiProps } from './modules';

const PhotoUploaderMulti: FC<PhotoUploaderMultiProps> = ({
  currentPhotos,
  setCurrentPhotos,
  label,
  isDeprecateMainPhotoDeletion = true,
  disableEditing = false,
  disabled,
  showRequiredIcon,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { uploadedPhotos, isLoading, isError } = useAppSelector(
    (state) => state.photos,
  );

  useEffect(() => {
    return () => {
      dispatch(uploadedPhoto([]));
    };
  }, []);

  useEffect(() => {
    const filteredPhotos = uploadedPhotos.filter((photo) => {
      const findPhoto = currentPhotos.find((item) => item.key === photo.key);
      if (findPhoto) return false;
      return true;
    });
    if (!currentPhotos.length) {
      return setCurrentPhotos(
        filteredPhotos.map((item, index) =>
          index === 0 ? { ...item, isMain: true } : item,
        ),
      );
    }
    setCurrentPhotos([...currentPhotos, ...filteredPhotos]);
  }, [uploadedPhotos]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const formData = new FormData();

    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files![i]);
      }

      dispatch(uploadNewPhoto(formData));
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !currentPhotos) {
      return;
    }

    const resultList: Photo[] = Array.from(currentPhotos);
    const [removed] = resultList.splice(result.source.index, 1);

    resultList.splice(result.destination.index, 0, removed);

    const arr = resultList!.map((image, ind) => {
      return {
        ...image,
        isMain: ind === 0,
      };
    });

    setCurrentPhotos(arr);
  };

  const handleDeleteImage = (img: Photo) => {
    const filteredPhotos = currentPhotos.filter(
      (image) => image.photoUrl !== img.photoUrl,
    );
    setCurrentPhotos(
      filteredPhotos.map((photo, index) => ({ ...photo, isMain: index === 0 })),
    );

    if (img.id) {
      dispatch(deletePhoto(String(img.id)));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <StyledInputLabel shrink htmlFor="clinicPhoto">
          <Text>{label || t('upload_photo')}</Text>
          {showRequiredIcon && <InfoIcon />}
        </StyledInputLabel>
        {currentPhotos.length > 0 && (
          <UploadedImages>
            <ImagesInfo>
              <AttentionIcon />
              {t('editProfileSettings.firstPhoto')}
            </ImagesInfo>
            <Droppable
              droppableId="droppable"
              direction="horizontal"
              isDropDisabled={disabled}
            >
              {(provided) => (
                <ImagesList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {currentPhotos.map((img, ind) => (
                    <Draggable
                      key={img.key}
                      draggableId={img.key!}
                      index={ind}
                      isDragDisabled={disabled}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ImagesListItem isMain={ind === 0}>
                            {ind === 0 && (
                              <MainImage>{t('mainPhoto')}</MainImage>
                            )}
                            <ImageItem
                              src={img.thumbnailUrl}
                              alt={img.thumbnailUrl}
                            />
                            {(currentPhotos.length > 1 ||
                              !isDeprecateMainPhotoDeletion) &&
                            !disabled ? (
                              <DeleteButton
                                onClick={() => handleDeleteImage(img)}
                              >
                                <RemoveIcon />
                              </DeleteButton>
                            ) : (
                              ''
                            )}
                          </ImagesListItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </ImagesList>
              )}
            </Droppable>
          </UploadedImages>
        )}
        {!!isLoading && (
          <UploadingProgress>
            <Loader>
              <LoadIcon />
            </Loader>
            <UploadingText>
              <p>{t('editProfileSettings.uploadingPhotos')}</p>
              <p>{t('editProfileSettings.wait')}</p>
            </UploadingText>
          </UploadingProgress>
        )}
        {!!isError && (
          <UploadingProgress>
            <Loader isError={isError}>
              <AttentionIcon />
            </Loader>
            <UploadingText isError={isError}>
              <p>{t('editProfileSettings.errorTitle')}</p>
              <p>{t('editProfileSettings.errorMessage')}</p>
            </UploadingText>
          </UploadingProgress>
        )}
        <UploadWrapper>
          <SecondaryButton styleType="color" disabled={disabled}>
            <ImageInput
              type="file"
              accept=".jpg, .jpeg, .png"
              multiple
              onChange={handleImageUpload}
              disabled={isLoading || disableEditing || disabled}
            />
            <UploadIcon />
            {t('editProfileSettings.uploadPhoto')}
          </SecondaryButton>
          <ImageTypes>PNG, JPG, JPEG (max. 800x400px)</ImageTypes>
        </UploadWrapper>
      </div>
    </DragDropContext>
  );
};

export default PhotoUploaderMulti;
