import React, { FC, useEffect } from 'react';
import { Modal } from '@mui/material';
import { DeleteModal, SuccessModal } from './components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { selectProfile } from 'applicaiton/store/reducers/Clinics/ClinicsSlice';
import {
  deleteProfile,
  fetchClinics,
} from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { Loader } from 'common/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';

const DeleteProfileModal: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoading, currentProfile, deletedSuccess } = useAppSelector(
    (state) => state.clinics,
  );

  const handleClose = () => {
    const isEditClinicPage = location.pathname.startsWith(PathNames.editClinic);
    dispatch(selectProfile(null));

    if (isEditClinicPage) {
      navigate(PathNames.myClinics);
    }
  };

  const handleSubmit = async () => {
    const response = await dispatch(deleteProfile(currentProfile?.id!));
    if (response.meta.requestStatus === 'fulfilled') {
      await dispatch(fetchClinics());
    }
  };

  useEffect(() => {
    if (!deletedSuccess) return;
    const timer = setTimeout(() => {
      handleClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [deletedSuccess]);

  return (
    <Modal open={!!currentProfile}>
      <>
        {isLoading && <Loader />}
        <DeleteModal
          profileName={currentProfile?.name || ''}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />

        {deletedSuccess && (
          <SuccessModal
            profileName={currentProfile?.name || ''}
            onClose={handleClose}
          />
        )}
      </>
    </Modal>
  );
};

export default DeleteProfileModal;
