import { UseToggleProps } from 'features/feature-teleconsultation/types';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setIsVideoOn } from 'applicaiton/store/reducers/Teleconsultation/TeleconsultationSlice';

/**
 * Toggle on/off local participant camera
 *
 * References:
 * https://www.twilio.com/docs/video/javascript-getting-started#mute-your-local-media
 */
const useToggleCamera = ({ room, initialState }: UseToggleProps) => {
  const dispatch = useAppDispatch();
  const { isVideoOn } = useAppSelector((state) => state.teleconsultation);

  const toggleCamera = useCallback(() => {
    if (room) {
      if (isVideoOn) {
        // turn off
        room.localParticipant.videoTracks.forEach((publication) => {
          publication.track.disable();
          publication.track.stop();
        });

        dispatch(setIsVideoOn(false));
      } else {
        // turn on
        room.localParticipant.videoTracks.forEach((publication) => {
          publication.track.enable();
          publication.track.restart();
        });

        dispatch(setIsVideoOn(true));
      }
    }
  }, [isVideoOn, room]);

  return { isCameraOn: isVideoOn, toggleCamera };
};
export default useToggleCamera;
