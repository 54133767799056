import React, { FC, useState } from 'react';
import { Props } from './models';
import { StyledTr, ActionsStyled } from './styles';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { ConfirmModal } from '../ConfirmModal';
import { useTranslation } from 'react-i18next';
import SuccessModal from '../SuccessModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { deleteSurgeryData } from 'applicaiton/store/reducers/Consultation/ActionCreators';
import AddSurgeryModal from '../AddSurgeryModal';
import dayjs from 'dayjs';

const SurgeryItem: FC<Props> = ({ item, updateMedicalBackground }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [surgeryModalOpen, setSurgeryModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const currentLanguage = getLanguageFromLocalStorage();
  const optionLangKey = `name_${currentLanguage.toUpperCase()}` as
    | 'name_EN'
    | 'name_PT';

  const handleSavedSurgeryModal = () => {
    setSurgeryModalOpen(false);
    updateMedicalBackground();
  };

  const handleDeleteSurgeryData = () => {
    dispatch(
      deleteSurgeryData({
        patientId: currentAppointment?.patient?.id!,
        surgeryDataId: item.id,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setConfirmModalOpen(false);
        setSuccessModalOpen(true);
      }
    });
  };

  const handleCloseSuccessModal = () => {
    updateMedicalBackground();
    setSuccessModalOpen(false);
  };

  return (
    <StyledTr isEmergency={item?.status === 'EMERGENCY'}>
      <td>{item?.surgery[optionLangKey]}</td>
      <td>{dayjs(item?.dateOfSurgery).format('DD/MM/YYYY')}</td>
      <td>{item?.comments}</td>
      <td>
        <span>{item?.status}</span>
      </td>
      <td>
        <ActionsStyled>
          <button onClick={() => setSurgeryModalOpen(true)}>
            <EditIcon />
          </button>
          <button onClick={() => setConfirmModalOpen(true)}>
            <DeleteIcon />
          </button>
        </ActionsStyled>
      </td>
      {surgeryModalOpen && (
        <AddSurgeryModal
          surgery={item}
          onClose={() => setSurgeryModalOpen(false)}
          onSave={handleSavedSurgeryModal}
        />
      )}
      <ConfirmModal
        onConfirm={handleDeleteSurgeryData}
        isOpen={confirmModalOpen}
        onCancel={() => setConfirmModalOpen(false)}
      >
        <p>
          {t('consultation_data.confirm_delete')}{' '}
          {t('consultation_data.confirm_delete_surgery')}?
        </p>
      </ConfirmModal>
      {successModalOpen && (
        <SuccessModal
          typeName={t('consultation_data.confirm_delete_surgery')}
          onClose={handleCloseSuccessModal}
        />
      )}
    </StyledTr>
  );
};
export default SurgeryItem;
