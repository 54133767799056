import styled from 'styled-components';
import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px;
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.warning2};
  background: ${({ theme }) => theme.color.warning3};

  > svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.color.warning2};
  }

  > div {
    flex-grow: 1;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.color.grey700};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  margin-left: 8px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.color.grey600};
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  margin-left: 12px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.color.grey600};
  }
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.color.grey800};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 8px;
  margin-top: 4px;
`;
