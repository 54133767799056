import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { NotificationsInboxActions } from 'applicaiton/constants/actions';
import { NotificationsAPI } from 'integration/api/notifications';
import {
  GetNotificationsParamsDto,
  UpdateNotificationParamsDto,
} from 'common/types/notifications';

export const getNotifications = createAsyncThunk(
  NotificationsInboxActions.getNotifications,
  async (params: GetNotificationsParamsDto, { rejectWithValue }) => {
    try {
      const { data } = await NotificationsAPI.getNotifications(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateNotification = createAsyncThunk(
  NotificationsInboxActions.updateNotification,
  async (params: UpdateNotificationParamsDto, { rejectWithValue }) => {
    try {
      const { data } = await NotificationsAPI.updateNotification(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const readAllNotification = createAsyncThunk(
  NotificationsInboxActions.readAllNotification,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await NotificationsAPI.readAllNotification();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
