import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  reset,
  setNextPath,
} from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { StyledModal } from './styles';
import { resetProfileChanges } from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';

const LeavePageModal: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showModal, nextPath } = useAppSelector((state) => state.savedChanges);
  const { profileChanges } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );

  const handleClose = () => {
    dispatch(setNextPath(null));
  };

  const handleSubmit = () => {
    profileChanges && dispatch(resetProfileChanges());
    dispatch(reset());
    navigate(nextPath!);
  };

  return (
    <Modal open={showModal} onClose={handleClose}>
      <StyledModal>
        <CloseIcon onClick={handleClose} />
        <h1>{t('leave_page.want_to_leave')}</h1>
        <p>{t('leave_page.updates_not_saved')}</p>
        <section>
          <SecondaryButton onClick={handleClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton onClick={handleSubmit} styleType={'error'}>
            {t('exit')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default LeavePageModal;
