import { useTranslation } from 'react-i18next';
import { Gender } from 'applicaiton/constants/gender';

export const useGenderOptions = () => {
  const { t } = useTranslation();

  return [
    { label: t('personal_information.gender.male'), value: Gender.Male },
    { label: t('personal_information.gender.female'), value: Gender.Female },
  ];
};
