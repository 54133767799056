import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { TimeRange } from 'common/components';
import {
  createClinicProfile,
  saveClinicProfile,
} from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { OpeningHours, TimeSlots } from 'common/types/clinics';
import { getSchedule } from 'common/helpers/getSchedule';
import { PathNames } from 'applicaiton/routes';
import ButtonSection from '../ButtonSection';
import {
  DividerStyled,
  SlotsWrapper,
  Wrapper,
  SlotsContainer,
  ReplicateButton,
} from './styles';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { SchedulerProps } from './models';
import { useTranslation } from 'react-i18next';
import { ClinicResponseDto } from '@docbay/schemas';
import { setShowSuccessModal } from 'applicaiton/store/reducers/Clinics/ClinicProfileSlice';

const Schedule: FC<SchedulerProps> = ({ handleSetNextPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isCreatePage = location.pathname.endsWith('create');
  const { clinicProfileData } = useAppSelector((state) => state.clinicProfile);
  const [openingHours, setOpeningHours] = useState<OpeningHours[]>(
    clinicProfileData?.openingHours || [],
  );

  useEffect(() => {
    if (!clinicProfileData?.openingHours?.length) {
      setOpeningHours(getSchedule());
    } else {
      const currentOpeningHours = getSchedule().map((day) => {
        return {
          ...day,
          isEnabled: false,
          ...(clinicProfileData?.openingHours?.find(
            (item) => item.day === day.day,
          ) || {}),
        };
      });
      setOpeningHours(currentOpeningHours);
    }
  }, [clinicProfileData?.openingHours]);

  const handleAllDays = () => {
    const firstDay = openingHours.find((item) => item.day === 'Monday')!;

    const currentOpeningHours = openingHours.map((day) => {
      if (day.day === 'Saturday' || day.day === 'Sunday') {
        return day;
      }

      return {
        day: day.day,
        isEnabled: firstDay.isEnabled!,
        slots: firstDay.slots!,
      };
    });

    setOpeningHours(currentOpeningHours);
  };

  const handleAddSlot = (day: string) => {
    const newSlot = {
      startTime: '',
      endTime: '',
    };

    setOpeningHours((prev) => [
      ...prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: [...item.slots, newSlot],
          };
        }
        return item;
      }),
    ]);
  };

  const handleEditSlot = (day: string, index: number, value: TimeSlots) => {
    const openingHoursEdited = openingHours?.map((item, i) => {
      if (item.day === day) {
        return {
          ...item,
          slots: item.slots.map((slot, i) => {
            if (index === i) {
              return { ...slot, ...value };
            }
            return slot;
          }),
        };
      }
      return item;
    });

    setOpeningHours(openingHoursEdited);
  };

  const handleEnableSlot = (day: string, value: boolean) => {
    setOpeningHours((prev) =>
      prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            isEnabled: value,
          };
        }
        return item;
      }),
    );
  };

  const handleRemoveSlot = (day: string, index: number) => {
    setOpeningHours((prev) =>
      prev.map((item) => {
        if (item.day === day) {
          return {
            ...item,
            slots: item.slots.filter((slot, i) => index !== i),
          };
        }
        return item;
      }),
    );
  };

  const getFilteredOpeningHours = () => {
    const filteredOpeningHours = openingHours.filter((item) => {
      const hasValidSlot = item.slots.some(
        (slot) => slot.startTime && slot.endTime,
      );
      return !!hasValidSlot;
    });
    return filteredOpeningHours;
  };

  const handleSave = async () => {
    const filteredOpeningHours = getFilteredOpeningHours();
    const canMoveNext = isCreatePage && filteredOpeningHours.length;

    if (isCreatePage && !clinicProfileData) {
      const response = await dispatch(
        createClinicProfile({
          name: '',
          ...(filteredOpeningHours.length
            ? { openingHours: filteredOpeningHours }
            : {}),
        }),
      );
      if (response.meta.requestStatus === 'fulfilled' && canMoveNext) {
        handleSetNextPage();
      }
    } else {
      const response = await dispatch(
        saveClinicProfile({
          id: clinicProfileData?.id!,
          data: {
            ...(filteredOpeningHours.length
              ? { openingHours: filteredOpeningHours }
              : {}),
          },
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        const currentClinic = response.payload as ClinicResponseDto;
        const showSuccessActivated =
          currentClinic.status === 'Active' &&
          !isCreatePage &&
          clinicProfileData?.status !== 'Active';
        if (showSuccessActivated) {
          dispatch(setShowSuccessModal(true));
        }
        if (canMoveNext) {
          handleSetNextPage();
        }
      }
    }
  };

  const handleCancel = () => {
    navigate(PathNames.myClinics);
  };

  const isStateChanged = useMemo(() => {
    const filteredOpeningHours = getFilteredOpeningHours();

    const hasChanges = compareObjectsChanges(
      { openingHours: filteredOpeningHours },
      {
        openingHours: clinicProfileData?.openingHours?.length
          ? clinicProfileData?.openingHours.map((day) => {
              return {
                day: day.day,
                isEnabled: day.isEnabled,
                slots: day.slots,
              };
            })
          : getSchedule(),
      },
    );

    return hasChanges;
  }, [openingHours, clinicProfileData?.openingHours]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const isScheduleEmpty = useMemo(
    () => openingHours.filter((item) => item.isEnabled).length === 0,
    [openingHours],
  );

  const isSaveButtonDisabled = useMemo(() => {
    if (isScheduleEmpty) {
      return true;
    }
    return isCreatePage ? false : !isStateChanged;
  }, [isStateChanged, isCreatePage, isScheduleEmpty]);

  const showNextButton = isCreatePage && !!getFilteredOpeningHours()?.length;

  const isReplicateButtonDisabled = useMemo(() => {
    if (!openingHours.length) return true;
    const firstDay = openingHours.find((item) => item.day === 'Monday');
    return !firstDay?.slots[0]?.startTime || !firstDay?.slots[0]?.endTime;
  }, [openingHours]);

  return (
    <Wrapper>
      <SlotsContainer>
        <SlotsWrapper>
          {openingHours.map((item, index) => (
            <TimeRange
              key={index}
              day={item.day}
              slots={item.slots}
              isEnabled={item.isEnabled}
              handleAddSlot={handleAddSlot}
              handleEditSlot={handleEditSlot}
              handleRemoveSlot={handleRemoveSlot}
              handleEnableSlot={handleEnableSlot}
            />
          ))}
        </SlotsWrapper>
        <ReplicateButton
          disabled={isReplicateButtonDisabled}
          onClick={handleAllDays}
        >
          {t('clinicsConfiguration.clinics.replicate')}
        </ReplicateButton>
      </SlotsContainer>

      <div>
        <DividerStyled />
        <ButtonSection
          onSave={handleSave}
          onCancel={handleCancel}
          disabled={isSaveButtonDisabled}
          showNextButton={showNextButton}
        />
      </div>
    </Wrapper>
  );
};

export default Schedule;
