import {
  getUserPermissions,
  getUserProPermissions,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { ClinicsForms } from './clinicsForms';
import { UserRoles } from 'applicaiton/constants/userRoles';

const description = [
  ClinicsForms.Profile,
  ClinicsForms.Address,
  ClinicsForms.Contacts,
  ClinicsForms.Payment,
];

export const getClinicFormsList = (clinicOwnerId: number) => {
  const permissions = getUserPermissions();
  const proPermissions = getUserProPermissions();
  const userRole = getUserRole();
  const isClinicOwner = userRole?.includes(UserRoles.clinicOwner);

  if (proPermissions && clinicOwnerId) {
    const currentPermissions = proPermissions.find(
      (item: any) => item[clinicOwnerId],
    );
    const professionalsPermissions = currentPermissions
      ? currentPermissions[clinicOwnerId]
      : '';

    return [
      ...(professionalsPermissions?.includes(
        UserPermissions.clinicDescription,
      ) || isClinicOwner
        ? description
        : []),
      ...(professionalsPermissions?.includes(
        UserPermissions.clinicOpeningHours,
      ) || isClinicOwner
        ? [ClinicsForms.Schedule]
        : []),
      ...(professionalsPermissions?.includes(
        UserPermissions.clinicInsurancesConfiguration,
      ) || isClinicOwner
        ? [ClinicsForms.Insurance]
        : []),
      ...(professionalsPermissions?.includes(UserPermissions.clinicFAQ) ||
      isClinicOwner
        ? [ClinicsForms.FAQs]
        : []),
    ];
  }

  return [
    ...(permissions?.includes(UserPermissions.clinicDescription)
      ? description
      : []),
    ...(permissions?.includes(UserPermissions.clinicOpeningHours)
      ? [ClinicsForms.Schedule]
      : []),
    ...(permissions?.includes(UserPermissions.clinicInsurancesConfiguration)
      ? [ClinicsForms.Insurance]
      : []),
    ...(permissions?.includes(UserPermissions.clinicFAQ)
      ? [ClinicsForms.FAQs]
      : []),
  ];
};
