import React, { FC, useEffect, useMemo, useState } from 'react';
import { ButtonWrapper, ModalStyled, Tab, Tabs } from './styles';
import { PrimaryButton } from 'common/components';
import Modal from '@mui/material/Modal';
import { ImportedCSVInfoProps } from './models';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'applicaiton/assets';
import { InfoTabs } from '../../constants';
import { useAppSelector } from 'common/hooks/redux';
import SavedPatients from '../ImportedPatientItem/SavedPatients';
import ExistingPatients from '../ImportedPatientItem/ExistingPatients';
import ErrorPatients from '../ImportedPatientItem/ErrorPatients';

const ImportedCSVInfoModal: FC<ImportedCSVInfoProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<InfoTabs>();
  const { importedPatients } = useAppSelector((state) => state.patients);

  useEffect(() => {
    if (importedPatients?.savedPatients?.length) {
      setActiveTab(InfoTabs.imported);
    } else if (importedPatients?.existingPatients?.length) {
      setActiveTab(InfoTabs.exist);
    } else if (importedPatients?.invalidPatients?.length) {
      setActiveTab(InfoTabs.error);
    }
  }, [importedPatients]);

  const isSavedPatients = useMemo(() => {
    return !!importedPatients?.savedPatients?.length;
  }, [importedPatients]);

  const isExistingPatients = useMemo(() => {
    return !!importedPatients?.existingPatients?.length;
  }, [importedPatients]);

  const isErrorPatients = useMemo(() => {
    return !!importedPatients?.invalidPatients?.length;
  }, [importedPatients]);

  const handleCloseModal = () => {
    setActiveTab(InfoTabs.imported);
    onClose();
  };

  return (
    <Modal open={isOpen}>
      <ModalStyled>
        <div className={'content-wrapper'}>
          <h6>{t('patient.import_status')}</h6>
          <CloseIcon onClick={handleCloseModal} />
        </div>
        <div>
          <Tabs>
            {isSavedPatients && (
              <Tab
                isActive={activeTab === InfoTabs.imported}
                onClick={() => setActiveTab(InfoTabs.imported)}
              >
                {t('patient.tab_imported')}
              </Tab>
            )}
            {isExistingPatients && (
              <Tab
                isActive={activeTab === InfoTabs.exist}
                onClick={() => setActiveTab(InfoTabs.exist)}
              >
                {t('patient.tab_already_exist')}
              </Tab>
            )}
            {isErrorPatients && (
              <Tab
                isActive={activeTab === InfoTabs.error}
                onClick={() => setActiveTab(InfoTabs.error)}
              >
                {t('patient.tab_error')}
              </Tab>
            )}
          </Tabs>
          {activeTab === InfoTabs.imported && isSavedPatients && (
            <SavedPatients data={importedPatients?.savedPatients!} />
          )}
          {activeTab === InfoTabs.exist && isExistingPatients && (
            <ExistingPatients data={importedPatients?.existingPatients!} />
          )}
          {activeTab === InfoTabs.error && isErrorPatients && (
            <ErrorPatients data={importedPatients?.invalidPatients!} />
          )}
        </div>
        <ButtonWrapper>
          <PrimaryButton onClick={handleCloseModal}>{t('ok')}</PrimaryButton>
        </ButtonWrapper>
      </ModalStyled>
    </Modal>
  );
};

export default ImportedCSVInfoModal;
