import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${({ theme }) => theme.color.white};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px;
`;

export const DataSection = styled.div`
  display: flex;
  flex-direction: column;

  h6 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.grey800};
  }

  button {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.blue800};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    margin-top: 16px;

    svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 32px;
  gap: 24px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
  padding-top: 32px;
  border-top: 1px solid #d0d5dd;

  button {
    width: 150px;

    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const DataChipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`;
