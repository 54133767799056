import React, { FC, memo, useMemo } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  GroupingState,
  IntegratedGrouping,
  ViewState,
} from '@devexpress/dx-react-scheduler';
import {
  Appointments,
  Scheduler,
  GroupingPanel,
  Resources,
} from '@devexpress/dx-react-scheduler-material-ui';

import { CalendarView } from 'applicaiton/constants/scheduler';

import { useAppSelector } from 'common/hooks/redux';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import DailyView from '../DailyView';
import Appointment from '../Apointment';
import WeeklyView from '../WeeklyView';

import { Event, SchedulerByProfessionalProps } from './module';
import { ProfessionalItem } from './styles';

const GroupingDayPanelCell: FC = ({ group, ...restProps }: any) => {
  const { i18n } = useTranslation();
  const { byProfessionals } = useAppSelector((state) => state.scheduler);
  const professional = byProfessionals.selectedProfessionals.find(
    (item) => item.id === group.id,
  )!;

  const formattedDate = dayjs(byProfessionals.selectedDate)
    .locale(i18n.language)
    .format('dddd, DD/MM');
  const currentDate =
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  return (
    <GroupingPanel.Cell group={group} {...restProps}>
      <ProfessionalItem>
        {professional?.photo ? (
          <img src={professional.photo.thumbnailUrl} alt={professional.id} />
        ) : (
          <div>
            <p>{professional?.firstName ? professional.firstName[0] : ''}</p>
            <p>{professional?.lastName ? professional.lastName[0] : ''}</p>
          </div>
        )}
        <h2>
          {professional?.firstName} {professional?.lastName},
          <span>{currentDate}</span>
        </h2>
      </ProfessionalItem>
    </GroupingPanel.Cell>
  );
};

const SchedulerByProfessional: FC<SchedulerByProfessionalProps> = ({
  appointments,
  absences,
  currentLanguage,
  grouping,
  workingHours,
  resources,
  dayHour,
  selectedDate,
  calendarView,
  isAddNewEvent,
  clinicId,
  clinicWorkingHours,
}) => {
  const { tz } = useUserTimeZone();
  const { clinicsByOwnerId } = useAppSelector((state) => state.clinics);
  const isWeeklyView = calendarView === CalendarView.weekly;

  const excludedDays = useMemo(() => {
    const currentClinic = clinicsByOwnerId.find(
      (clinic) => clinic.id === clinicId,
    );
    const workOnSaturday = currentClinic?.openingHours?.find(
      (item) => item.day === 'Saturday' && item.isEnabled,
    );
    const workOnSunday = currentClinic?.openingHours?.find(
      (item) => item.day === 'Sunday' && item.isEnabled,
    );

    return [...(workOnSaturday ? [] : [6]), ...(workOnSunday ? [] : [0])];
  }, [clinicsByOwnerId, clinicId]);

  const earlierEvent = useMemo(() => {
    const isHighLightAppointment = appointments.some(
      (item) => !!item.highLight,
    );
    if (isAddNewEvent || isHighLightAppointment) return { type: null };
    const scheduler = clinicWorkingHours ? clinicWorkingHours : workingHours;
    const workingHoursFiltered = isWeeklyView
      ? scheduler.filter(
          (item) => dayjs.tz(item.startDate, tz).hour() >= dayHour.start,
        )
      : scheduler.filter((item) => {
          return (
            dayjs(item.startDate).format('DD/MM/YYY') ===
              dayjs(selectedDate).format('DD/MM/YYY') &&
            dayjs.tz(item.startDate, tz).hour() >= dayHour.start
          );
        });
    const appointmentsFiltered = isWeeklyView
      ? appointments.filter(
          (item) => dayjs.tz(item.startDate, tz).hour() >= dayHour.start,
        )
      : appointments.filter((item) => {
          return (
            dayjs(item.startDate).format('DD/MM/YYY') ===
              dayjs(selectedDate).format('DD/MM/YYY') &&
            dayjs.tz(item.startDate, tz).hour() >= dayHour.start
          );
        });

    const earlessAppointment = appointmentsFiltered.length
      ? appointmentsFiltered.reduce(
          (acc, curr) => {
            return (acc = dayjs(curr.startDate)
              .set('day', 1)
              .isBefore(dayjs(acc.startDate).set('day', 1))
              ? curr
              : acc);
          },
          { startDate: dayjs().set('year', 3000).toDate() },
        )
      : { startDate: dayjs().set('year', 3000).toDate() };

    const earlessWorkingHour = workingHoursFiltered.length
      ? workingHoursFiltered.reduce(
          (acc, curr) => {
            return (acc = dayjs(curr.startDate)
              .set('day', 1)
              .isBefore(dayjs(acc.startDate).set('day', 1))
              ? curr
              : acc);
          },
          { startDate: dayjs().set('year', 3000).toDate() },
        )
      : { startDate: dayjs().set('year', 3000).toDate() };

    const event: Event = [
      {
        type: 'appointment',
        ...earlessAppointment,
      },
      {
        type: 'workingHour',
        ...earlessWorkingHour,
      },
    ].reduce(
      (acc, curr) => {
        return (acc = dayjs(curr.startDate)
          .set('day', 1)
          .isBefore(dayjs(acc.startDate).set('day', 1))
          ? curr
          : acc);
      },
      { startDate: dayjs().set('year', 3000).toDate() },
    );

    return event;
  }, [
    workingHours,
    appointments,
    selectedDate,
    isWeeklyView,
    clinicWorkingHours,
    dayHour,
  ]);

  return (
    <>
      {isWeeklyView ? (
        <Scheduler
          data={appointments}
          locale={currentLanguage}
          firstDayOfWeek={1}
        >
          <ViewState currentDate={selectedDate} />
          <GroupingState grouping={grouping} />
          <WeeklyView
            workingHours={workingHours}
            dayHour={dayHour}
            absences={absences}
            scrollEvent={
              earlierEvent.type === 'workingHour' ? earlierEvent : null
            }
            excludedDays={excludedDays}
          />
          <Appointments
            appointmentComponent={(props) => (
              <Appointment
                isWeeklyView={isWeeklyView}
                isGroupingView={grouping.length > 1}
                scrollEvent={
                  earlierEvent.type === 'appointment' ? earlierEvent : null
                }
                {...props}
              />
            )}
          />
          <Resources data={resources} />
          <IntegratedGrouping />
        </Scheduler>
      ) : (
        <Scheduler data={appointments} locale={currentLanguage}>
          <ViewState currentDate={selectedDate} />
          <GroupingState grouping={grouping} />
          <DailyView
            workingHours={workingHours}
            intervalCount={1}
            dayHour={dayHour}
            absences={absences}
            scrollEvent={
              earlierEvent.type === 'workingHour' ? earlierEvent : null
            }
          />
          <Appointments
            appointmentComponent={(props) => (
              <Appointment
                isWeeklyView={isWeeklyView}
                isGroupingView={grouping.length > 1}
                scrollEvent={
                  earlierEvent.type === 'appointment' ? earlierEvent : null
                }
                {...props}
              />
            )}
          />
          <Resources data={resources} />
          <IntegratedGrouping />
          <GroupingPanel cellComponent={GroupingDayPanelCell} />
        </Scheduler>
      )}
    </>
  );
};

export default memo(SchedulerByProfessional);
