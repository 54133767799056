import React, { FC, useMemo } from 'react';
import { useAppSelector } from 'common/hooks/redux';
import { ReactComponent as PersonsIcon } from 'applicaiton/assets/persons.svg';
import { ReactComponent as BadgeIcon } from 'applicaiton/assets/badge.svg';
import { ReactComponent as PhoneIcon } from 'applicaiton/assets/phone.svg';
import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar.svg';
import { ReactComponent as EmailIcon } from 'applicaiton/assets/email.svg';
import {
  SelectedPatient,
  PatientInfoItem,
  SeeProfile,
  StyledChevronRightIcon,
  MemberStyled,
} from './styles';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { Gender } from 'applicaiton/constants/gender';
import { MembersIcon } from 'common/components/Icons';

const MemberInfo: FC = () => {
  const { t } = useTranslation();
  const { currentFamilyMemberSearch, isShowProfile } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const patientData = useMemo(() => {
    return {
      pronunciation: currentFamilyMemberSearch?.isPatient
        ? currentFamilyMemberSearch?.pronunciation === Gender.Male
          ? t('mr')
          : t('mrs')
        : currentFamilyMemberSearch?.patient?.pronunciation === Gender.Male
        ? t('mr')
        : t('mrs'),
      name: currentFamilyMemberSearch?.isPatient
        ? `${currentFamilyMemberSearch?.firstName} 
    ${currentFamilyMemberSearch?.lastName}`
        : `${currentFamilyMemberSearch?.patient?.firstName} ${currentFamilyMemberSearch?.patient?.lastName}`,
      dateOfBirth: currentFamilyMemberSearch?.isPatient
        ? dayjs(currentFamilyMemberSearch?.dateOfBirth).format('YYYY-MM-DD')
        : dayjs(currentFamilyMemberSearch?.patient?.dateOfBirth).format(
            'YYYY-MM-DD',
          ),
      years: currentFamilyMemberSearch?.isPatient
        ? dayjs().diff(dayjs(currentFamilyMemberSearch?.dateOfBirth), 'years')
        : dayjs().diff(
            dayjs(currentFamilyMemberSearch?.patient?.dateOfBirth),
            'years',
          ),
    };
  }, [currentFamilyMemberSearch]);

  return (
    <SelectedPatient>
      <div>
        {!currentFamilyMemberSearch?.isPatient && (
          <MemberStyled>
            <PatientInfoItem>
              <PersonsIcon />
              <span>{t('pronunciation')}</span> {patientData.pronunciation}
            </PatientInfoItem>
            <PatientInfoItem isStrokeSvg={true}>
              <MembersIcon />
              <span>{t('relatives')}</span>{' '}
              {currentFamilyMemberSearch?.relationship}
            </PatientInfoItem>
            <PatientInfoItem>
              <BadgeIcon />
              <span>{t('patient.name')}</span>{' '}
              {currentFamilyMemberSearch?.firstName}{' '}
              {currentFamilyMemberSearch?.lastName}
            </PatientInfoItem>
            <PatientInfoItem>
              <CalendarIcon />
              <span>{t('patient.date_of_birth')}</span>{' '}
              {dayjs(currentFamilyMemberSearch?.dateOfBirth).format(
                'YYYY-MM-DD',
              )}{' '}
              (
              {dayjs().diff(
                dayjs(currentFamilyMemberSearch?.dateOfBirth),
                'years',
              )}{' '}
              years)
            </PatientInfoItem>
          </MemberStyled>
        )}
        <PatientInfoItem>
          <PersonsIcon />
          <span>{t('pronunciation')}</span> {patientData.pronunciation}
        </PatientInfoItem>
        <PatientInfoItem>
          <BadgeIcon />
          <span>
            {t('patient.name')} {patientData.name}
          </span>{' '}
        </PatientInfoItem>
        {!!currentFamilyMemberSearch?.phone && (
          <PatientInfoItem>
            <PhoneIcon />
            <span>{t('patient.phone')}</span> {currentFamilyMemberSearch?.phone}
          </PatientInfoItem>
        )}
        <PatientInfoItem>
          <CalendarIcon />
          <span>{t('patient.date_of_birth')}</span> {patientData.dateOfBirth} (
          {patientData.years} {t('years')})
        </PatientInfoItem>
        {!!currentFamilyMemberSearch?.email && (
          <PatientInfoItem>
            <EmailIcon />
            <span>{t('email')}</span> {currentFamilyMemberSearch?.email}
          </PatientInfoItem>
        )}
      </div>
      {isShowProfile && (
        <SeeProfile>
          <div>
            <Link
              to={`${PathNames.patient}/${
                currentFamilyMemberSearch?.isPatient
                  ? currentFamilyMemberSearch?.id
                  : currentFamilyMemberSearch?.patient?.id!
              }`}
              target="_blank"
            >
              {t('see_profile')}
            </Link>
            <StyledChevronRightIcon />
          </div>
        </SeeProfile>
      )}
    </SelectedPatient>
  );
};

export default MemberInfo;
