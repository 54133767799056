import React, { FC } from 'react';
import { ClinicSuccessCreatedModal, SideMenu } from '../../../features';
import MainContent from 'features/feature-edit-profile-settings/component/MainContent';
import { Wrapper } from './styles';

const EditProfileSettings: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <MainContent />
      <ClinicSuccessCreatedModal />
    </Wrapper>
  );
};

export default EditProfileSettings;
