import React, { FC } from 'react';
import { Wrapper } from './styles';
import { SideMenu } from 'features';
import { Chats } from 'features/feature-chats';

const ChatsPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <Chats />
    </Wrapper>
  );
};

export default ChatsPage;
