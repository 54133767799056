import { createAsyncThunk } from '@reduxjs/toolkit';
import { TeleconsultationActions } from 'applicaiton/constants/actions';
import { AxiosError } from 'axios';
import { AppointmentsApi } from 'integration/api/appointments';
import { ChatsAPI } from 'integration/api/chats';

export const getConnectionData = createAsyncThunk(
  TeleconsultationActions.getConnectionData,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await AppointmentsApi.getTeleconsultationConnectionData(
        id,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getConnectionDataByRoomId = createAsyncThunk(
  TeleconsultationActions.getConnectionDataByRoomId,
  async (roomId: string, { rejectWithValue }) => {
    try {
      const { data } = await ChatsAPI.createTeleconsultationTokenByRoomId(
        roomId,
      );
      return {
        room: roomId,
        accessToken: data,
      };
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createChat = createAsyncThunk(
  TeleconsultationActions.createChat,
  async (id: string, { rejectWithValue }) => {
    try {
      await AppointmentsApi.createTeleconsultationChat(id);
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
