import React, { ChangeEvent, FC, memo } from 'react';
import { ReactComponent as InfoIcon } from 'applicaiton/assets/info.svg';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  StyledInput,
  TextCounter,
  LabelWrapper,
  TextAreaWrapper,
} from './styled';

interface TextAreaProps {
  id: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  errorMessage?: string;
  hint?: string;
  register?: UseFormRegister<any>;
  isFocused?: boolean;
  isCount?: boolean;
  textLength?: string;
  maxTextLength?: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  showRequiredIcon?: boolean;
}

const TextArea: FC<TextAreaProps> = ({
  id,
  placeholder,
  label,
  disabled,
  errorMessage,
  hint,
  register,
  isFocused,
  isCount = true,
  textLength = '',
  maxTextLength = 2000,
  value,
  onChange,
  showRequiredIcon,
}) => {
  const { t } = useTranslation();

  return (
    <StyledInput isError={!!errorMessage} disabled={disabled}>
      <LabelWrapper>
        <label>{label}</label>
        {showRequiredIcon && <InfoIcon />}
      </LabelWrapper>
      <TextAreaWrapper disabled={disabled}>
        {register ? (
          <textarea
            placeholder={placeholder}
            disabled={disabled}
            {...register(id)}
            autoFocus={isFocused}
            rows={7}
            maxLength={maxTextLength}
          />
        ) : (
          <textarea
            placeholder={placeholder}
            disabled={disabled}
            id={id}
            autoFocus={isFocused}
            rows={7}
            maxLength={maxTextLength}
            value={value}
            onChange={onChange}
          />
        )}
      </TextAreaWrapper>
      {isCount && (
        <TextCounter>
          {maxTextLength - textLength.length}{' '}
          {textLength.length === 1
            ? t('editProfileSettings.oneCharacterLeft')
            : t('editProfileSettings.charactersLeft')}
        </TextCounter>
      )}
      <span>{errorMessage ? errorMessage : hint}</span>
    </StyledInput>
  );
};

export default memo(TextArea);
