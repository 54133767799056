import styled from 'styled-components';

export const AccordionProfilesStyled = styled.div`
  width: 100%;
  position: relative;
  padding: 8px;
  margin-top: 16px;

  .accordion-root {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08px;
    border-radius: 4px 4px 0 0;
    border-bottom: 0;
  }
  .accordion-info {
    display: flex;
    align-items: flex-start;
    width: 100%;
    color: ${({ theme }) => theme.color.grey900};
    cursor: pointer;
    gap: 12px;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      min-height: 40px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.purple100};

      color: ${({ theme }) => theme.color.grey900};
      text-align: center;
      font-size: 18px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-transform: uppercase;

      > img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: all 0.3ms;
      }
    }
  }
`;

export const ProfessionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > p {
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
  }

  > span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.07px;
    white-space: initial;
  }

  a {
    color: ${({ theme }) => theme.color.blue800};
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    text-decoration: none;
  }
`;
