import axiosInstance from 'integration/api/axiosInstance';
import {
  AbsenceCreationDto,
  AbsenceResponseDto,
  AbsenceUpdateDto,
  AbsenceUpdateResponseDto,
} from '@docbay/schemas';

export const AbsenceApi = {
  createAbsence: (data: AbsenceCreationDto) =>
    axiosInstance.post('/professional-absences', data),
  deleteAbsence: (id: string) =>
    axiosInstance.delete(`/professional-absences/${id}`),
  gteAbsenceById: (id: string) => {
    const params = {
      include: 'clinicProfessional,clinicProfessional.clinic.owner',
    };
    return axiosInstance.get<AbsenceResponseDto>(
      `/professional-absences/${id}`,
      { params },
    );
  },
  updateAbsence: (data: AbsenceUpdateDto) => {
    return axiosInstance.patch<AbsenceUpdateResponseDto>(
      '/professional-absences',
      data,
    );
  },
};
