import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getClinicOwnerById,
  updateClinicOwner,
  clinicOwnerVerifyOldEmail,
  clinicOwnerVerifyCode,
  clinicOwnerSetNewEmail,
  updatePermissionsByClinicOwnerId,
  getPermissionsByClinicOwnerId,
  fetchClinicOwnerStaff,
  clinicOwnerVerifyPhone,
  clinicOwnerSetNewPhone,
  getSubscriptions,
  getCustomerPortalLink,
  getAccountStatus,
  getCustomerBillingLink,
  getCustomerBillingUpdateLink,
  getCustomerBillingActivationLink,
  deleteCOAccount,
} from './ActionCreators';
import { ClinicOwnerState } from './models';
import { ProfileForm } from 'applicaiton/constants/clincOwnerProfile';
import {
  AuthLocalStorage,
  setUsersRefreshTokenSessionStorage,
} from 'applicaiton/sessionStorage/auth';

const initialState: ClinicOwnerState = {
  total: 0,
  page: 0,
  totalPages: 0,
  currentClinicOwner: null,
  isLoading: false,
  error: '',
  isAdded: false,
  isUpdated: false,
  activeForm: ProfileForm.previewProfile,
  isVerifiedOldEmail: false,
  isVerifiedOldEmailCode: false,
  permissions: [],
  clinicOwnerStaff: [],
  isVerifiedOldPhone: false,
  isVerifiedOldPhoneCode: false,
  subscriptions: null,
  canAddNewUser: true,
  showLimitSubscriptionsModal: false,
  customerPortalLink: null,
  customerBillingLink: null,
  isResentActive: true,
  isWrongVerificationCode: false,
  isAccountVerified: false,
};

const clinicOwnerSlice = createSlice({
  name: 'adminsSlice',
  initialState,
  reducers: {
    resetClinicOwnerData: (state) => {
      state.isAdded = false;
      state.isUpdated = false;
      state.error = '';
      state.activeForm = ProfileForm.previewProfile;
      state.isVerifiedOldEmail = false;
      state.isVerifiedOldPhone = false;
    },
    resetClinicOwnerProfile: (state) => {
      state.currentClinicOwner = null;
      state.activeForm = ProfileForm.previewProfile;
    },
    setActiveForm: (state, action: PayloadAction<ProfileForm>) => {
      state.activeForm = action.payload;
    },
    resetEmailData: (state) => {
      state.isVerifiedOldEmail = false;
      state.isVerifiedOldEmailCode = false;
      state.isWrongVerificationCode = false;
    },
    resetVerifiedOldEmailCode: (state) => {
      state.isVerifiedOldEmailCode = false;
    },
    resetPhoneData: (state) => {
      state.isVerifiedOldPhone = false;
      state.isVerifiedOldPhoneCode = false;
      state.isWrongVerificationCode = false;
    },
    resetVerifiedOldPhoneCode: (state) => {
      state.isVerifiedOldPhoneCode = false;
    },
    setCurrentClinicOwnerEmail: (state, action) => {
      state.currentClinicOwner = action.payload;
    },
    setCurrentClinicOwnerPhone: (state, action) => {
      state.currentClinicOwner = action.payload;
    },
    setShowLimitSubscriptionsModal: (state, action: PayloadAction<boolean>) => {
      state.showLimitSubscriptionsModal = action.payload;
    },
    setIsResentActive: (state, actions) => {
      state.isResentActive = actions.payload;
    },
    setIsWrongVerificationCode: (state, actions) => {
      state.isWrongVerificationCode = actions.payload;
    },
    resetCOError: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    //get clinic owner by id
    builder.addCase(getClinicOwnerById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClinicOwnerById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getClinicOwnerById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentClinicOwner = action.payload;
    });
    //update clinic owner
    builder.addCase(updateClinicOwner.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateClinicOwner.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateClinicOwner.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentClinicOwner = action.payload;
      state.isUpdated = true;
    });
    //verify old email
    builder.addCase(clinicOwnerVerifyOldEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(clinicOwnerVerifyOldEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(clinicOwnerVerifyOldEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldEmail = true;
    });

    //verify code
    builder.addCase(clinicOwnerVerifyCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(clinicOwnerVerifyCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(clinicOwnerVerifyCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldEmailCode = !!action.payload;
    });

    //set new email
    builder.addCase(clinicOwnerSetNewEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(clinicOwnerSetNewEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(clinicOwnerSetNewEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.token) {
        setUsersRefreshTokenSessionStorage(action.payload);

        state.currentClinicOwner = {
          ...state.currentClinicOwner!,
          user: {
            ...state.currentClinicOwner?.user!,
            email: String(sessionStorage.getItem('newEmail')),
          },
        };

        const profiles = JSON.parse(
          localStorage.getItem(AuthLocalStorage.profiles) as string,
        );

        const newProfile = {
          ...profiles[0],
          email: String(sessionStorage.getItem('newEmail')),
          token: action.payload.token,
        };

        localStorage.setItem(
          AuthLocalStorage.profiles,
          JSON.stringify([newProfile]),
        );
        sessionStorage.removeItem('oldEmail');
        sessionStorage.removeItem('newEmail');
      }
    });

    //get permissions by clinic owner id
    builder.addCase(getPermissionsByClinicOwnerId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPermissionsByClinicOwnerId.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(
      getPermissionsByClinicOwnerId.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.permissions = action.payload;
      },
    );

    //update permissions by clinic owner id
    builder.addCase(updatePermissionsByClinicOwnerId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updatePermissionsByClinicOwnerId.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      },
    );
    builder.addCase(
      updatePermissionsByClinicOwnerId.fulfilled,
      (state, action) => {
        state.isLoading = false;
      },
    );
    //staff fetch
    builder.addCase(fetchClinicOwnerStaff.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchClinicOwnerStaff.rejected, (state, action) => {
      state.isLoading = false;
      state.clinicOwnerStaff = [];
      state.error = action.payload as string;
    });
    builder.addCase(fetchClinicOwnerStaff.fulfilled, (state, action) => {
      state.clinicOwnerStaff = action.payload.entities;
      state.total = action.payload.total!;
      state.page = action.payload.page!;
      state.totalPages = action.payload.totalPages!;
      state.isLoading = false;
    });
    //verify old phone
    builder.addCase(clinicOwnerVerifyPhone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(clinicOwnerVerifyPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(clinicOwnerVerifyPhone.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldPhone = true;
    });
    //set new email
    builder.addCase(clinicOwnerSetNewPhone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(clinicOwnerSetNewPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(clinicOwnerSetNewPhone.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload.token) {
        setUsersRefreshTokenSessionStorage(action.payload);

        state.currentClinicOwner = {
          ...state.currentClinicOwner!,
          phone: String(sessionStorage.getItem('newPhone')),
        };

        const profiles = JSON.parse(
          localStorage.getItem(AuthLocalStorage.profiles) as string,
        );

        const newProfile = {
          ...profiles[0],
          phone: String(sessionStorage.getItem('newPhone')),
          token: action.payload.token,
        };

        localStorage.setItem(
          AuthLocalStorage.profiles,
          JSON.stringify([newProfile]),
        );
        sessionStorage.removeItem('oldPhone');
        sessionStorage.removeItem('newPhone');
      }
    });
    //get clinic owner subscriptions
    builder.addCase(getSubscriptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubscriptions.rejected, (state, action) => {
      state.isLoading = false;
      state.subscriptions = null;
      state.canAddNewUser = false;
      state.error = action.payload as string;
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      state.subscriptions = action.payload;
      state.canAddNewUser = action.payload.remainingUserSlots > 0;
      state.isLoading = false;
    });

    //get portal link
    builder.addCase(getCustomerPortalLink.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerPortalLink.rejected, (state, action) => {
      state.error = action.payload as string;
    });
    builder.addCase(getCustomerPortalLink.fulfilled, (state, action) => {
      state.customerPortalLink = action.payload;
      state.isLoading = false;
    });
    //get portal link
    builder.addCase(getCustomerBillingLink.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerBillingLink.rejected, (state, action) => {
      state.error = action.payload as string;
    });
    builder.addCase(getCustomerBillingLink.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    //get billing update link
    builder.addCase(getCustomerBillingUpdateLink.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerBillingUpdateLink.rejected, (state, action) => {
      state.error = action.payload as string;
    });
    builder.addCase(getCustomerBillingUpdateLink.fulfilled, (state, action) => {
      state.customerBillingLink = action.payload;
      state.isLoading = false;
    });
    //get billing activation link
    builder.addCase(getCustomerBillingActivationLink.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getCustomerBillingActivationLink.rejected,
      (state, action) => {
        state.error = action.payload as string;
      },
    );
    builder.addCase(
      getCustomerBillingActivationLink.fulfilled,
      (state, action) => {
        state.customerBillingLink = action.payload;
        state.isLoading = false;
      },
    );
    //get account status
    builder.addCase(getAccountStatus.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAccountStatus.rejected, (state, action) => {
      state.error = action.payload as string;
    });
    builder.addCase(getAccountStatus.fulfilled, (state, action) => {
      state.isAccountVerified = action.payload;
      state.isLoading = false;
    });
    //delete account
    builder.addCase(deleteCOAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCOAccount.rejected, (state, action) => {
      state.error = action.payload as string;
    });
    builder.addCase(deleteCOAccount.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  resetClinicOwnerData,
  resetClinicOwnerProfile,
  setActiveForm,
  resetEmailData,
  resetVerifiedOldEmailCode,
  setCurrentClinicOwnerEmail,
  resetPhoneData,
  resetVerifiedOldPhoneCode,
  setCurrentClinicOwnerPhone,
  setShowLimitSubscriptionsModal,
  setIsResentActive,
  setIsWrongVerificationCode,
  resetCOError,
} = clinicOwnerSlice.actions;

export default clinicOwnerSlice.reducer;
