import { FC, useEffect } from 'react';
import React, { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as ErrorIcon } from 'applicaiton/assets/unsuccess-icon.svg';
import { setRoomCompleted } from 'applicaiton/store/reducers/Teleconsultation/TeleconsultationSlice';
import { PathNames } from 'applicaiton/routes';

import { PrimaryButton } from 'common/components';

import { ButtonWrapper, StyledModal } from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

const RoomsUnavailablePopUp: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { roomCompleted } = useAppSelector((state) => state.teleconsultation);

  useEffect(() => {
    return () => {
      dispatch(setRoomCompleted(false));
    };
  }, []);

  return (
    <Modal open={roomCompleted}>
      <StyledModal>
        <div>
          <ErrorIcon />
          <h1>{t('teleconsultation.room_unavailable.title')}</h1>
          <p>{t('teleconsultation.room_unavailable.sub_title')}</p>
          <ButtonWrapper>
            <Link to={PathNames.chats}>
              <PrimaryButton type="button">
                {t('teleconsultation.room_unavailable.action')}
              </PrimaryButton>
            </Link>
          </ButtonWrapper>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default RoomsUnavailablePopUp;
