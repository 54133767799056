import styled from 'styled-components';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 500px;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);

  > svg {
    width: 82px;
    height: 82px;
  }

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin: 12px 0 32px;
  }

  > button {
    width: fit-content;
    padding: 12px 24px;
  }
`;
