import React, { FC, useMemo } from 'react';
import { useAgreementsAndPoliciesModal } from './hooks/useAgreementsAndPoliciesModal';
import { useTranslation } from 'react-i18next';
import { AgreementAndPolicyType } from 'applicaiton/store/reducers/AgreementsAndPolicies/models';
import { ModalComponent } from 'common/components';
import { MarkdownStyled, ModalContentWrapper } from './styles';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

export const AgreementsAndPoliciesModal: FC = () => {
  const { t } = useTranslation();
  const { isModalOpen, policyType, closeModal } =
    useAgreementsAndPoliciesModal();
  const modalContent = useMemo(() => {
    switch (policyType) {
      case AgreementAndPolicyType.COOKIES:
        return t('agreements_and_policies.cookie_policy.markdown');
      case AgreementAndPolicyType.PRIVACY_POLICY:
        return t('agreements_and_policies.privacy_policy.markdown');
      case AgreementAndPolicyType.TERMS_AND_CONDITIONS:
        return t('agreements_and_policies.terms_and_conditions.markdown');
      default:
        return t('agreements_and_policies.terms_and_conditions.markdown');
    }
  }, [policyType, t]);
  return (
    <>
      <ModalComponent
        width="90vw"
        padding="32px"
        isOpen={isModalOpen}
        handleClose={closeModal}
      >
        <ModalContentWrapper>
          <MarkdownStyled
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {modalContent}
          </MarkdownStyled>
        </ModalContentWrapper>
      </ModalComponent>
    </>
  );
};
