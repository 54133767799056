import * as React from 'react';
import { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';

export const Header: FC = () => {
  return (
    <header>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& > *': {
            m: 1,
          },
        }}
      >
        <Button component={NavLink} to={PathNames.home}>
          <Typography variant="body2" component="h2">
            Home
          </Typography>
        </Button>
        <Button component={NavLink} to={PathNames.clinicRegistration}>
          <Typography variant="body2" component="h2">
            Clinic OWner Registration
          </Typography>
        </Button>
      </Box>
    </header>
  );
};
