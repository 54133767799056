import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.grey200};
  border-radius: 12px;
`;

export const InfoSection = styled.div`
  height: fit-content;
  width: 440px;
  display: grid;
  gap: 32px;
  grid-auto-rows: max-content;

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    color: ${({ theme }) => theme.color.grey900};
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
  }

  h3 {
    color: ${({ theme }) => theme.color.grey600};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
`;

export const InstructionList = styled.div`
  display: grid;
  width: 440px;
  height: fit-content;
  gap: 12px;
  grid-auto-rows: max-content;
  grid-auto-columns: 440px;
`;

export const ItemStyled = styled.div`
  display: grid;
  grid-template-columns: 15px 1fr;
  gap: 12px;
  color: ${({ theme }) => theme.color.grey800};
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
`;
