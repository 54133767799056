import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import { StyledTr, ActionsStyled, ColorItem } from './styles';
import { AppointmentTypeResponseDto } from '@docbay/schemas';
import { ReactComponent as OnlineIcon } from 'applicaiton/assets/online.svg';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  changeAppointmentTypeNotificationsConfig,
  deleteAppointmentTypes,
} from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import DeleteModal from '../DeleteModal';
import { getReadableDuration } from 'common/helpers/getReadableDuration';
import { PathNames } from 'applicaiton/routes';
import { MeetingPlace } from 'applicaiton/constants/meetingPlace';
import Switch from 'common/components/Switch';

interface ProfessionalItemProps {
  type: AppointmentTypeResponseDto;
  notificationEnabled: boolean;
  onNotificationConfigChange: () => void;
}

const AppointmentTypeItem: FC<ProfessionalItemProps> = ({
  type,
  notificationEnabled,
  onNotificationConfigChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showIsDelete, setShowIsDelete] = useState(false);

  const { isDeleted } = useAppSelector((state) => state.appointmentTypes);

  useEffect(() => {
    if (isDeleted) {
      setShowIsDelete(false);
    }
  }, [isDeleted]);

  const handleDeleteType = () => {
    dispatch(deleteAppointmentTypes(type.id));
  };

  const handleNotificationEnabledChange = (value: boolean) => {
    dispatch(
      changeAppointmentTypeNotificationsConfig({
        id: type.id,
        data: {
          patientNotificationsEnabled: value,
        },
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        onNotificationConfigChange();
      }
    });
  };

  return (
    <>
      <StyledTr isBookOnline={type.availableForOnlineBooking}>
        <td>
          <ColorItem color={type.colorCode} />
        </td>
        <td>
          <div>
            {type.title}
            {type.meetingPlace === 'Teleconsultation' && <OnlineIcon />}
          </div>
          <div>
            {type.meetingPlace === MeetingPlace.Clinic
              ? t('appointment_types.at_the_clinic')
              : t('appointment_types.teleconsultation')}
          </div>
        </td>
        <td>
          <Switch
            checked={notificationEnabled}
            onChange={(value) => handleNotificationEnabledChange(value)}
          />
        </td>
        <td>{getReadableDuration(Number(type.durationInMinutes))}</td>
        <td>
          <span>{type.availableForOnlineBooking ? t('yes') : t('no')}</span>
        </td>
        <td>
          <ActionsStyled>
            <Link to={`${PathNames.editAppointmentType}/${type.id}`}>
              <EditIcon />
            </Link>
            <button onClick={() => setShowIsDelete(true)}>
              <DeleteIcon />
            </button>
          </ActionsStyled>
        </td>
      </StyledTr>
      <DeleteModal
        isOpen={showIsDelete}
        typeName={type.title!}
        onClose={() => setShowIsDelete(false)}
        onSubmit={handleDeleteType}
      />
    </>
  );
};
export default AppointmentTypeItem;
