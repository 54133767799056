import { createAsyncThunk } from '@reduxjs/toolkit';
import { TopicsActions } from 'applicaiton/constants/actions';
import { AxiosError } from 'axios';
import { TopicsAPI } from 'integration/api/topics';
import { TopicCreationDto, TopicUpdateDto } from '@docbay/schemas';

export const getTopics = createAsyncThunk(
  TopicsActions.getTopics,
  async (
    data: {
      id: string;
      params: {
        page: number;
        limit: number;
        clinicIds?: string;
      };
    },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await TopicsAPI.getTopics({
        id: data.id,
        params: data.params,
      });
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createTopic = createAsyncThunk(
  TopicsActions.createTopic,
  async (
    params: { id: string; data: TopicCreationDto },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await TopicsAPI.createTopic(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const editTopic = createAsyncThunk(
  TopicsActions.editTopic,
  async (
    params: { id: string; topicId: string; data: TopicUpdateDto },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await TopicsAPI.editTopic(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteTopic = createAsyncThunk(
  TopicsActions.deleteTopic,
  async (params: { id: string; topicId: string }, { rejectWithValue }) => {
    try {
      const { data: responseData } = await TopicsAPI.deleteTopic(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
