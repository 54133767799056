import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPrivateDoctor, updatePrivateDoctor } from './ActionCreators';
import { InitialState } from './models';
import { ProfessionalUpdateDto } from '@docbay/schemas';
import { EditSubscription } from '../Professionals/models';

const initialState: InitialState = {
  profileChanges: null,
  editedProfessional: null,
  subscriptions: [],
  isLoading: false,
  error: '',
  privateDoctor: null,
  isUpdated: false,
};

const privateDoctorSlice = createSlice({
  name: 'privateDoctor',
  initialState,
  reducers: {
    resetIsUpdated: (state) => {
      state.isUpdated = false;
    },
    setProfileChanges: (
      state,
      actions: PayloadAction<ProfessionalUpdateDto | null>,
    ) => {
      state.profileChanges = actions.payload!;
    },
    setProfileSubscriptions: (
      state,
      actions: PayloadAction<EditSubscription[]>,
    ) => {
      state.subscriptions = actions.payload!;
    },
  },
  extraReducers: (builder) => {
    // get private doctor
    builder.addCase(getPrivateDoctor.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPrivateDoctor.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getPrivateDoctor.fulfilled, (state, action) => {
      state.isLoading = false;
      state.privateDoctor = action.payload;
      state.editedProfessional = action.payload.professional;
    });
    // update private doctor
    builder.addCase(updatePrivateDoctor.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePrivateDoctor.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updatePrivateDoctor.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isUpdated = true;
      state.privateDoctor = action.payload;
    });
  },
});

export const { resetIsUpdated, setProfileChanges, setProfileSubscriptions } =
  privateDoctorSlice.actions;

export default privateDoctorSlice.reducer;
