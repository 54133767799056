import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyStateIcon } from 'applicaiton/assets/empty-state-appointments-past.svg';
import { StyledSection } from './styles';

const EmptyFamilyMembersState: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledSection>
      <div>
        <EmptyStateIcon />
        <h2>{t('family_members.no_family_members')}</h2>
      </div>
    </StyledSection>
  );
};
export default EmptyFamilyMembersState;
