import axios, { AxiosRequestConfig } from 'axios';

import { ApplicationType, x_app_type } from '../../applicaiton';

const axiosAuthInstance = (token: string) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  axiosInstance.interceptors.request.use(
    async (apiConfig: AxiosRequestConfig) => {
      const newHeaders = {
        ...(apiConfig.headers || {}),
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        [x_app_type]: ApplicationType.ProfessionalWeb,
      };
      return { ...apiConfig, headers: newHeaders };
    },
  );

  return axiosInstance;
};

export default axiosAuthInstance;
