import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { ReactComponent as PersonsIcon } from 'applicaiton/assets/persons.svg';
import { ReactComponent as BadgeIcon } from 'applicaiton/assets/badge.svg';
import { ReactComponent as PhoneIcon } from 'applicaiton/assets/phone.svg';
import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar.svg';
import { ReactComponent as EmailIcon } from 'applicaiton/assets/email.svg';
import { PathNames } from 'applicaiton/routes';
import { Gender } from 'applicaiton/constants/gender';

import { MembersIcon } from 'common/components/Icons';
import { useAppSelector } from 'common/hooks/redux';

import { PatientInfoProps } from './models';
import {
  SelectedPatient,
  PatientInfoItem,
  SeeProfile,
  StyledChevronRightIcon,
  MemberStyled,
} from './styles';

const PatientInfo: FC<PatientInfoProps> = ({ isTeleconsultation }) => {
  const { t } = useTranslation();
  const { currentAppointment, currentPatient, isShowProfile } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const pronunciation =
    currentPatient?.pronunciation === Gender.Male ? t('mr') : t('mrs');

  const familyMember = useMemo(() => {
    if (
      !currentPatient?.familyMembers?.length ||
      !currentAppointment?.bookedForMember
    )
      return null;

    return currentPatient.familyMembers.find(
      (member) => member.id === currentAppointment.bookedForMember?.id,
    );
  }, [currentAppointment, currentPatient]);

  return (
    <SelectedPatient
      className={`patient-info ${
        isTeleconsultation ? 'isTeleconsultation' : ''
      }`}
    >
      <div>
        {!!familyMember && (
          <MemberStyled>
            <h2>{t('appointment_for')}</h2>
            <PatientInfoItem isStrokeSvg={true}>
              <MembersIcon />
              <span>{t('relatives')}</span> {familyMember?.relationship}
            </PatientInfoItem>
            <PatientInfoItem>
              <PersonsIcon />
              <span>{t('pronunciation')}</span>{' '}
              {t(familyMember?.pronunciation.toLowerCase())}
            </PatientInfoItem>
            <PatientInfoItem>
              <BadgeIcon />
              <span>{t('patient.name')}</span> {familyMember?.firstName}{' '}
              {familyMember?.lastName}
            </PatientInfoItem>
            <PatientInfoItem>
              <CalendarIcon />
              <span>{t('patient.date_of_birth')}</span>{' '}
              {dayjs(familyMember?.dateOfBirth).format(
                isTeleconsultation ? 'DD/MM/YYYY' : 'YYYY-MM-DD',
              )}{' '}
              ({dayjs().diff(dayjs(familyMember?.dateOfBirth), 'years')} years)
            </PatientInfoItem>
          </MemberStyled>
        )}
        <PatientInfoItem>
          <PersonsIcon />
          <span>{t('pronunciation')}</span> {pronunciation}
        </PatientInfoItem>
        <PatientInfoItem>
          <BadgeIcon />
          <span>{t('patient.name')}</span> {currentPatient?.firstName}{' '}
          {currentPatient?.lastName}
        </PatientInfoItem>
        {!!currentPatient?.phone && (
          <PatientInfoItem>
            <PhoneIcon />
            <span>{t('patient.phone')}</span> {currentPatient?.phone}
          </PatientInfoItem>
        )}
        <PatientInfoItem>
          <CalendarIcon />
          <span>{t('patient.date_of_birth')}</span>{' '}
          {dayjs(currentPatient?.dateOfBirth).format(
            isTeleconsultation ? 'DD/MM/YYYY' : 'YYYY-MM-DD',
          )}{' '}
          ({dayjs().diff(dayjs(currentPatient?.dateOfBirth), 'years')} years)
        </PatientInfoItem>
        {!!currentPatient?.email && (
          <PatientInfoItem>
            <EmailIcon />
            <span>{t('email')}</span> {currentPatient?.email}
          </PatientInfoItem>
        )}
      </div>
      {isShowProfile && (
        <SeeProfile>
          <div>
            <Link
              to={`${PathNames.patient}/${currentPatient?.id}`}
              target="_blank"
            >
              {t('see_profile')}
            </Link>
            <StyledChevronRightIcon />
          </div>
        </SeeProfile>
      )}
    </SelectedPatient>
  );
};

export default PatientInfo;
