import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as LogOutIcon } from 'applicaiton/assets/logout.svg';
import { ReactComponent as SupportIcon } from 'applicaiton/assets/help.svg';
import { useMenuList } from './hooks/useMenuList';
import { MenuItem } from './components';
import { Wrapper } from './styles';
import { Divider } from '@mui/material';
import i18next from 'i18next';
import {
  Profile,
  getProfilesFromSessionStorage,
  getUserId,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'common/hooks/redux';
import { getClinicOwnerById } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import {
  setUserLanguage,
  signOut,
  usersRefreshToken,
} from 'applicaiton/store/reducers/Users/ActionCreators';
import { logout } from 'common/auth/authService';
import { usePushNotifications } from 'features/feature-chats/hooks/usePushNotifications';

const SideMenu: FC = () => {
  const { t, i18n } = useTranslation();
  const userRole = getUserRole();
  const userId = getUserId();
  const isUserRoleClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const { unregisterPushNotifications } = usePushNotifications();
  const languages = ['PT', 'EN', 'FR'];

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isUserRoleClinicOwner) {
      dispatch(getClinicOwnerById(String(userId)));
    }
  }, []);

  const menuItems = useMenuList();

  const supportItem = {
    path: PathNames.support,
    name: t('clinicsConfiguration.aside_menu.support'),
    image: SupportIcon,
  };

  const currentLanguage = useMemo(() => {
    return (
      localStorage.getItem('i18nextLng')?.slice(0, 2).toUpperCase() || 'PT'
    );
  }, [i18n.language]);

  const changeLanguage = async (language: string) => {
    const userUpdate = async () => {
      const profile: Profile[] | null = getProfilesFromSessionStorage();
      i18next.changeLanguage(language.toLowerCase());

      if (profile) {
        await dispatch(
          usersRefreshToken({
            refreshToken: profile[0].refreshToken,
            email: profile[0].email,
          }),
        );
      }
    };

    dispatch(setUserLanguage(language.toLowerCase())).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        userUpdate();
      }
    });
  };

  const handleLogOut = async () => {
    const response = await dispatch(signOut());
    if (response.meta.requestStatus === 'fulfilled') {
      logout();
      await unregisterPushNotifications();
      i18next.changeLanguage(currentLanguage.toLowerCase() || 'pt');
      navigate(PathNames.login);
    }
  };

  return (
    <Wrapper>
      <div className={'item-list'}>
        <div>
          {menuItems.map((item) => (
            <MenuItem
              key={item.name}
              path={item.path}
              name={item.name}
              Image={item.image}
              isNotificationItem={item.path === PathNames.notifications}
              isDocBoxItem={item.path === PathNames.chats}
              availablePaths={item.availablePaths}
            />
          ))}
        </div>
        <div>
          <MenuItem
            path={supportItem.path}
            name={supportItem.name}
            Image={supportItem.image}
          />
          <div className={'language'}>
            {languages.map((language) => (
              <span
                key={language}
                className={
                  currentLanguage.toUpperCase() === language.toUpperCase()
                    ? 'active'
                    : ''
                }
                onClick={() => changeLanguage(language)}
              >
                {language.toUpperCase()}
              </span>
            ))}
          </div>
        </div>
      </div>
      <Divider />
      <div className={'logout'} onClick={handleLogOut}>
        <LogOutIcon />
      </div>
    </Wrapper>
  );
};

export default SideMenu;
