import React from 'react';
import { MenuStyled } from './styles';
import { MenuItemStyled } from '../ActiveChatMenu/styles';
import { ContentCopyIcon, DeleteIcon } from 'applicaiton/assets';
import { ReactComponent as DownloadIcon } from 'applicaiton/assets/download.svg';
import { MessageMenuProps } from './module';
import { useTranslation } from 'react-i18next';

export const MessageMenu = ({
  isOwnMessage,
  anchorElement,
  onDelete,
  onClose,
  onCopy,
  isDocumentMessage,
  onDownload,
}: MessageMenuProps) => {
  const { t } = useTranslation();
  return (
    <MenuStyled
      id="basic-menu"
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {!isDocumentMessage && (
        <MenuItemStyled onClick={onCopy}>
          <ContentCopyIcon />
          {t('copy')}
        </MenuItemStyled>
      )}
      {isDocumentMessage && (
        <MenuItemStyled onClick={onDownload}>
          <DownloadIcon />
          {t('download')}
        </MenuItemStyled>
      )}
      {isOwnMessage && (
        <MenuItemStyled onClick={onDelete}>
          <DeleteIcon />
          {t('delete')}
        </MenuItemStyled>
      )}
    </MenuStyled>
  );
};
