import { useMemo } from 'react';
import { useAppSelector } from 'common/hooks/redux';
import { ContactsType } from 'common/types/clinics';

export const useContactsSliced = () => {
  const { clinicProfileData } = useAppSelector((state) => state.clinicProfile);

  const emails = useMemo(
    () => clinicProfileData?.contacts?.filter((item) => item.type === 'Email'),
    [clinicProfileData?.contacts],
  );

  const phones = useMemo(
    () => clinicProfileData?.contacts?.filter((item) => item.type === 'Phone'),
    [clinicProfileData?.contacts],
  );

  const emergency = useMemo(
    () =>
      clinicProfileData?.contacts?.filter(
        (item) => item.type === 'EmergencyPhone',
      ),
    [clinicProfileData?.contacts],
  );

  const website = useMemo(
    () =>
      clinicProfileData?.contacts?.filter((item) => item.type === 'Website'),
    [clinicProfileData?.contacts],
  );

  const social = useMemo(
    () =>
      clinicProfileData?.contacts?.filter(
        (item) =>
          item.type === 'Facebook' ||
          item.type === 'Instagram' ||
          item.type === 'Linkedin' ||
          item.type === 'Tiktok' ||
          item.type === 'Twitter',
      ),
    [clinicProfileData?.contacts],
  );

  return {
    emails: emails?.length
      ? emails
      : [
          {
            type: 'Email' as ContactsType,
            contactDetail: '',
          },
        ],
    phones: phones?.length
      ? phones
      : [
          {
            type: 'Phone' as ContactsType,
            contactDetail: '',
          },
        ],
    emergency: emergency?.length
      ? emergency
      : [
          {
            type: 'EmergencyPhone' as ContactsType,
            contactDetail: '',
          },
        ],
    website: website?.length
      ? website
      : [
          {
            type: 'Website' as ContactsType,
            contactDetail: '',
          },
        ],
    social: social?.length
      ? social
      : [
          {
            type: 'Facebook' as ContactsType,
            contactDetail: '',
          },
        ],
  };
};
