import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import WaitingAppointmentsTable from './components/WaitingAppointmentsTable';
import { Wrapper } from './styles';
import { Header } from 'common/components';
import { Link } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import PageSwitcher from 'common/components/PageSwitcher';

const WaitingList: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header />
      <PageSwitcher>
        <Link to={PathNames.myPatients}>
          {t('clinicsConfiguration.aside_menu.my_patients_path')}
        </Link>
        <div>{t('clinicsConfiguration.aside_menu.waiting_list')}</div>
      </PageSwitcher>
      <h1>{t('waiting_list.title')}</h1>
      <WaitingAppointmentsTable />
    </Wrapper>
  );
};

export default WaitingList;
