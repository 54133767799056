import { useTranslation } from 'react-i18next';

export const useOpeningReservationAppointment = () => {
  const { t } = useTranslation();

  return [
    { label: `6 ${t('months')} (180 ${t('days')})`, value: 259200 },
    { label: `3 ${t('months')} (90 ${t('days')})`, value: 129600 },
    { label: `30 ${t('days')}`, value: 43200 },
    { label: `14 ${t('days')}`, value: 20160 },
    { label: `7 ${t('days')}`, value: 10080 },
    { label: `5 ${t('days')}`, value: 7200 },
    { label: `3 ${t('days')}`, value: 4320 },
    { label: `1 ${t('day')}`, value: 1440 },
  ];
};
