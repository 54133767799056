import styled from 'styled-components';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100% - 91px);
  justify-content: space-between;

  div {
    > p {
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
      color: ${commonColors.grey600};
      margin-top: 24px;
    }
  }
`;

export const FieldsSection = styled.div`
  min-width: 664px;
  width: 50%;
  padding: 32px;
  overflow-y: auto;
`;

export const CurrentDate = styled.div`
  display: flex;
  align-items: center;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  color: ${commonColors.grey800};
  margin-bottom: 24px;

  svg {
    margin-right: 8px;

    path {
      fill: ${commonColors.grey600};
    }
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  padding: 32px;
  border-top: 1px solid ${commonColors.grey200};

  button {
    width: 150px;

    &:last-child {
      margin-left: 20px;
    }
  }
`;
