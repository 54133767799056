import { Box, styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';
import { IconButton } from '@mui/material';

export const ChatHeaderWrapper = styled(Box)`
  padding: 20px;
  border-bottom: 1px solid ${commonColors.grey200};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex: 0;

  .patient-name {
    color: ${commonColors.grey900};
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  .patient-status {
    color: ${commonColors.grey700};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const MenuButtonStyled = styled(IconButton)`
  height: 42px;
  width: 42px;
  padding: 0;

  svg {
    path {
      fill: ${commonColors.grey700};
    }
  }
`;

export const BackButton = styled('div')`
  color: ${commonColors.blue800};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  cursor: pointer;
`;
