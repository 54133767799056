import { VideoTrackProps } from 'features/feature-teleconsultation/types';
import React, { FC, useEffect, useRef } from 'react';

const VideoTrack: FC<VideoTrackProps> = ({ videoTrack }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (videoTrack) {
      const el = ref.current || '';
      videoTrack.attach(el);

      return () => {
        videoTrack.detach(el);
      };
    }
  }, [videoTrack]);

  return <video ref={ref} />;
};

export default VideoTrack;
