import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import axiosInstance from './axiosInstance';
import {
  AdminCreationDto,
  AdminSetPasswordDto,
  AdminSetPasswordResDto,
  AdminUpdateDto,
} from '@docbay/schemas';
import axiosAuthInstance from './axiosAuthInstance';

export const AdminsAPI = {
  createAdmin: (data: AdminCreationDto) => {
    const language = getLanguageFromLocalStorage();
    return axiosInstance.post('/admins', { ...data, language });
  },
  getAdminById: (id: string) => {
    const params = {
      include: 'photo,clinics,clinics.owner,clinicOwner',
    };

    return axiosInstance.get(`/admins/${id}`, { params });
  },
  editAdmin: (id: string, data: AdminUpdateDto) =>
    axiosInstance.patch(`/admins/${id}`, data),
  deleteAdmin: (id: string) => axiosInstance.delete(`/admins/${id}`),
  checkAdminVerificationCode: (email: string, code: string) =>
    axiosAuthInstance('').post('/admins/verification-code', { email, code }),
  createAdminPassword: (data: AdminSetPasswordDto) =>
    axiosAuthInstance('').post<AdminSetPasswordResDto>(
      '/admins/password',
      data,
    ),
  resendAdminAuthorizationData: (id: string) => {
    const language = getLanguageFromLocalStorage();
    return axiosInstance.post(`/admins/${id}/resend-authorization-data`, {
      language,
    });
  },
  changeAdminsEmail: (id: string, email: string, userType: string) => {
    const language = getLanguageFromLocalStorage();
    return axiosInstance.patch(`/admins/${id}/email`, {
      email,
      language,
      userType,
    });
  },
};
