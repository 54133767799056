import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CreateNewPasswordDataT } from '../types';

export const useCreateNewPasswordForm = () => {
  const createPasswordSchema = yup
    .object({
      password: yup
        .string()
        .required()
        .min(10)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.{10,})/),
    })
    .required();

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<CreateNewPasswordDataT>({
    resolver: yupResolver(createPasswordSchema),
  });

  return {
    register,
    watch,
    getValues,
    setValue,
    setError,
    errors,
    handleSubmit,
    clearErrors,
  };
};
