import { useTranslation } from 'react-i18next';

export const useTimeView = () => {
  const { t } = useTranslation();
  return [
    { label: `${t('all')}`, value: '00:00-23:59' },
    {
      label: `${t('morning')} -`,
      subLabel: `${t('before')} 12:00`,
      value: '00:00-12:00',
    },
    {
      label: `${t('afternoon')} -`,
      subLabel: `12:00 ${t('to')} 18:00`,
      value: '12:00-18:00',
    },
    {
      label: `${t('evening')} -`,
      subLabel: `${t('after')} 18:00`,
      value: '18:00-23:59',
    },
  ];
};
