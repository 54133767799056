import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  createChat,
  getConnectionData,
  getConnectionDataByRoomId,
} from 'applicaiton/store/reducers/Teleconsultation/ActionCreators';

export const useTeleconsultation = () => {
  const dispatch = useAppDispatch();
  const { room, accessToken, isLoading, chatIsCreated, messages } =
    useAppSelector((state) => state.teleconsultation);

  const startTeleconsultationByAppointmentId = async (
    appointmentId: string,
  ) => {
    await dispatch(getConnectionData(appointmentId));
    await dispatch(createChat(appointmentId));
  };
  const startTeleconsultationByRoomId = async (roomId: string) => {
    await dispatch(getConnectionDataByRoomId(roomId));
  };
  return {
    room,
    accessToken,
    chatIsCreated,
    startTeleconsultationByAppointmentId,
    isLoading,
    messages,
    startTeleconsultationByRoomId,
  };
};
