import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'common/hooks/redux';
import { resetAdminData } from 'applicaiton/store/reducers/Admins/AdminsSlice';
import { GeneralInfo, Email, Password, Header, SideBar } from './components';
import {
  Wrapper,
  DividerStyled,
  LayoutSectionStyled,
  StyledSection,
} from './styles';
import Phone from './components/ChangingPhone/Phone';

const AdminProfile: FC = () => {
  const dispatch = useAppDispatch();
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    return () => {
      dispatch(resetAdminData());
    };
  }, []);

  return (
    <Wrapper>
      <LayoutSectionStyled>
        <Header />
        <DividerStyled />
        <StyledSection>
          <SideBar
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          />
          {selectedPage === 0 && <GeneralInfo />}
          {selectedPage === 1 && <Phone />}
          {selectedPage === 2 && <Email />}
          {selectedPage === 3 && <Password />}
        </StyledSection>
      </LayoutSectionStyled>
    </Wrapper>
  );
};

export default AdminProfile;
