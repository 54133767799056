import React, { FC, useEffect } from 'react';
import { userChangePassword } from 'applicaiton/store/reducers/Users/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { RegistrationData } from 'features/feature-secretary-profile/types/password';
import { useCreatePasswordForm } from 'features/feature-secretary-profile/hooks/useCreatePasswordForm';
import ButtonSection from '../ButtonSection';
import { Wrapper, DividerStyled } from './styles';
import { ChangePassword } from 'common/components';
import { setIsPasswordChanged } from 'applicaiton/store/reducers/Users/UsersSlice';
import SuccessChangePasswordModal from 'common/components/SuccessChangePasswordModal';
import { useTranslation } from 'react-i18next';

const Password: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isPasswordChanged, error } = useAppSelector((state) => state.users);
  const {
    register,
    errors,
    getValues,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useCreatePasswordForm();

  const onSubmit = async (data: RegistrationData) => {
    dispatch(userChangePassword(data));
  };

  const closeSuccessModal = () => {
    setValue('password', '');
    setValue('oldPassword', '');
    dispatch(setIsPasswordChanged(false));
  };

  useEffect(() => {
    if (error) {
      setError('oldPassword', { message: t('incorrect_password')! });
    } else {
      clearErrors();
    }
  }, [error]);

  const isButtonDisabled = !watch('password') || !watch('oldPassword');

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <ChangePassword
        watch={watch}
        getValues={getValues}
        register={register}
        oldPasswordErrorMessage={errors.oldPassword?.message}
        passwordErrorMessage={errors.password?.message}
      />
      <div>
        <DividerStyled />
        <ButtonSection disabled={isButtonDisabled} />
      </div>
      {isPasswordChanged && (
        <SuccessChangePasswordModal onClose={closeSuccessModal} />
      )}
    </Wrapper>
  );
};

export default Password;
