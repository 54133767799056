import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { ProfessionalClinicT } from '../components/ProfessionalAddForm/types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export const useClinicForm = ({ requiredError }: { requiredError: string }) => {
  const formClinicValues = {
    clinic: '',
    specializationsToAdd: [],
    hours: [],
    appointmentTypes: [],
  };

  const profileGeneralSchema = yup
    .object({
      clinic: yup.string().required(requiredError!),
      specializationsToAdd: yup
        .array()
        .min(1, requiredError!)
        .required(requiredError!),
      hours: yup.array().min(0, requiredError!).required(requiredError!),
      appointmentTypes: yup.array(),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm<ProfessionalClinicT>({
    resolver: yupResolver(profileGeneralSchema),
    defaultValues: formClinicValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
    reset,
  };
};
