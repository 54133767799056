import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';

import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';
import { ContactsType } from 'common/types/clinics';
import { ContactsChildProps } from './models';
import SocialInput from 'common/components/SocialInput';

import {
  Close,
  InputWrapper,
  InputContainer,
  WrapperMargin,
  Container,
} from './styles';
import { GeneralProfileInfoForm } from 'features/feature-professional-edit/types/generalProfileInfo';

const Social = ({
  errors,
  control,
  register,
  getValues,
  handleRemoveSocialMedia,
  disableEditing = false,
}: ContactsChildProps) => {
  const { t } = useTranslation();

  const prependKey = 'socialMedia';
  const { fields, append, remove, update } =
    useFieldArray<GeneralProfileInfoForm>({
      control,
      name: prependKey,
    });

  const handleRemove = (index: number, id: string) => {
    remove(index);
    if (id) {
      handleRemoveSocialMedia(id);
    }
  };

  const handleAddAnother = () => {
    append({
      name: 'Facebook' as ContactsType,
      url: '',
    });
  };

  return (
    <WrapperMargin>
      {fields.map((field, index) => {
        const id = `${prependKey}.${index}.url`;
        const values = getValues(`${prependKey}.${index}`);
        const error = errors?.[prependKey]?.[index]?.url?.message;
        const label = index === 0 ? t('edit_contacts.social_media') : undefined;

        return (
          <InputWrapper key={field.id} last={index === fields.length - 1}>
            <InputContainer>
              <SocialInput
                id={id}
                type="text"
                label={label}
                register={register}
                errorMessage={error}
                socialType={values.name! as ContactsType}
                placeholder={t('edit_contacts.social_media') as string}
                onTypeSelect={(name) => update(index, { ...values, name })}
                disabled={disableEditing}
              />
            </InputContainer>
            {index !== 0 && (
              <Close
                onClick={() => handleRemove(index, field.professionalId || '')}
              />
            )}
          </InputWrapper>
        );
      })}
      <Container onClick={handleAddAnother}>
        <PlusIcon />
        <p>{t('add_another')}</p>
      </Container>
    </WrapperMargin>
  );
};

export default Social;
