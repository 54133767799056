import styled from 'styled-components';

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const ClinicInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h1 {
    font-size: 24px;
    color: ${({ theme }) => theme.color.grey900};
    font-weight: 500;
    line-height: 33.6px;
    text-transform: capitalize;
  }
`;

export const ClinicImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.blueDark100};
  margin-right: 16px;
  color: ${({ theme }) => theme.color.white};

  > svg {
    path {
      fill: ${({ theme }) => theme.color.grey900};
    }
  }

  > img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  > button {
    padding: 12px 24px;
    min-width: fit-content;
    white-space: nowrap;
  }

  > a {
    text-decoration: none;
    padding: 0;
    margin-left: 16px;
    min-width: fit-content;
    white-space: nowrap;
    button {
      padding: 12px 24px;
    }
  }
`;
