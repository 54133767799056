import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ModalProps } from './modules';
import { ModalStyled, Header } from './styles';

const Modal: FC<ModalProps> = ({ title, onClose, children }) => {
  return (
    <ModalStyled>
      <Header>
        <p>{title}</p>
        <button type="button" onClick={onClose}>
          <CloseIcon />
        </button>
      </Header>
      {children}
    </ModalStyled>
  );
};

export default Modal;
