import { styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

export const EmojiButtonStyled = styled('button')`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 100%;
  border-right: 1px solid ${commonColors.grey200};
  background: ${commonColors.grey100};

  &:hover {
    background: ${commonColors.grey200};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;
export const EmojiContainer = styled('div')`
  position: relative;
  height: 100%;
  /* bottom: 100%;
  left: 0; */
`;
export const EmojiList = styled('div')`
  position: absolute;
  bottom: 100%;
  left: 0;
`;
