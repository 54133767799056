import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import BookAnAppointment from '../SelectAvailableSlots';
import { AppointmentWaitingListResponseDto } from '@docbay/schemas';

interface SuccessModalProps {
  onClose: () => void;
  onEdit: () => void;
  data: AppointmentWaitingListResponseDto;
}

const AvailableEarlySlotsModal: FC<SuccessModalProps> = ({
  onClose,
  data,
  onEdit,
}) => {
  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <BookAnAppointment
          appointment={data}
          onClose={onClose}
          onEdit={onEdit}
        />
      </StyledModal>
    </Modal>
  );
};

export default AvailableEarlySlotsModal;
