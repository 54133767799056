import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Gender } from 'applicaiton/constants/gender';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';

import { Checkbox, DatePicker, Input, RadioButton } from 'common/components';
import { usePronunciationOptions } from 'common/hooks/usePronunciationOptions';
import { getRoundedDateInUTC } from 'common/helpers/dateTimeHelper';
import { FamilyMemberFormProps } from './models';

import {
  Wrapper,
  PronunciationStyled,
  Row,
  CancelButton,
  CheckboxStyled,
} from './styles';

const FamilyMemberForm: FC<FamilyMemberFormProps> = ({
  setValueFM,
  errorsFM,
  getValuesFM,
  registerFM,
  clearErrorsFM,
  watchFM,
  onCancelFamilyMemberForm,
  isCreateAppointmentForFM,
  setIsCreateAppointmentForFM,
}) => {
  const { t } = useTranslation();
  const pronunciation = usePronunciationOptions();

  const handleChangeDateOfBirth = (value: string) => {
    setValueFM(
      'dateOfBirth',
      getRoundedDateInUTC(value).add(1, 'hour').toISOString(), // Add 1 hour to the date to avoid the date being set to the previous day
    );
    clearErrorsFM('dateOfBirth');
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.id === 'Male' ? Gender.Male : Gender.Female;

    setValueFM('gender', value);
  };

  const onCancel = () => {
    onCancelFamilyMemberForm();
  };

  return (
    <Wrapper>
      <PronunciationStyled>
        <p>{t('pronunciation') || ''}</p>

        {pronunciation.map((item) => (
          <RadioButton
            key={item.value}
            id={item.value}
            label={item.label}
            checked={watchFM('gender') === item.value}
            onChange={handleGenderChange}
          />
        ))}
      </PronunciationStyled>
      <Row>
        <Input
          label={t('personal_information.first_name') || ''}
          id={'firstName'}
          type="text"
          register={registerFM}
          errorMessage={errorsFM?.firstName?.message}
          placeholder={t('patient.new_patient_name') || ''}
        />
        <Input
          label={t('personal_information.last_name') || ''}
          id={'lastName'}
          type="text"
          register={registerFM}
          errorMessage={errorsFM?.lastName?.message}
          placeholder={t('patient.new_patient_lastname') || ''}
        />
      </Row>
      <Input
        id="relationship"
        label={t('patient.relationship') || ''}
        type="text"
        placeholder={t('patient.relationship_placeholder') || ''}
        register={registerFM}
        errorMessage={errorsFM?.relationship?.message}
      />
      <DatePicker
        id={'dateOfBirth'}
        label={t('patient.date_birth') || ''}
        value={String(getValuesFM('dateOfBirth'))}
        onChange={handleChangeDateOfBirth}
        maxDate={Date.now()}
        minDate={0}
        errorMessage={errorsFM?.dateOfBirth?.message}
      />
      <Input
        id="fin"
        label={t('patient.fin') || ''}
        type="text"
        placeholder={t('patient.fin_placeholder') || ''}
        register={registerFM}
      />
      <Input
        id="ssn"
        label={t('patient.ssn') || ''}
        type="email"
        placeholder={t('patient.ssn_placeholder') || ''}
        register={registerFM}
      />
      <CancelButton type="button" onClick={onCancel}>
        <CloseIcon />
        {t('patient.cancel_create_family_member')}
      </CancelButton>
      <CheckboxStyled>
        <Checkbox
          checked={isCreateAppointmentForFM!}
          id={'isCreateAppointmentForFM'}
          onChange={() => {
            setIsCreateAppointmentForFM(!isCreateAppointmentForFM);
          }}
          label={t('patient.create_appointment_for_FM')!}
        />
      </CheckboxStyled>
    </Wrapper>
  );
};

export default FamilyMemberForm;
