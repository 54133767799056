import React, { FC } from 'react';
import { Wrapper } from './styles';
import MyProfile from 'common/components/MyProfile';
import AccordionSubscriptions from './components/AccordionSubscriptions';

const SubscriptionsSubmenu: FC = () => {
  return (
    <Wrapper>
      <MyProfile />
      <AccordionSubscriptions />
    </Wrapper>
  );
};

export default SubscriptionsSubmenu;
