import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader, SecondaryButton } from 'common/components';
import { Wrapper, Title, TableStyled } from './styles';
import {
  createFamilyMember,
  getFamilyMembers,
  getPatientById,
  updateFamilyMember,
} from 'applicaiton/store/reducers/Patients/ActionCreators';
import EmptyFamilyMembersState from '../EmptyFamilyMembersState';
import FamilyMemberItem from '../FamilyMemberItem';
import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';
import AddFamilyMemberModal from '../AddFamilyMemberModal';
import {
  FamilyMemberCreationDto,
  FamilyMemberUpdateDto,
} from '@docbay/schemas';
import {
  setEditedFamilyMember,
  setIsCreatedFamilyMember,
  setIsUpdatedFamilyMember,
} from 'applicaiton/store/reducers/Patients/PatientsSlice';
import FamilyMemberSuccessModal from '../FamilyMemberSuccessModal';

const FamilyMembersTable: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [isAddFamilyMemberModalOpen, setIsAddFamilyMemberModalOpen] =
    useState(false);

  const {
    familyMembers,
    editedFamilyMember,
    isLoading,
    isCreatedFamilyMember,
    isUpdatedFamilyMember,
  } = useAppSelector((state) => state.patients);

  useEffect(() => {
    dispatch(getFamilyMembers(String(id)));
  }, [id]);

  useEffect(() => {
    if (editedFamilyMember) {
      setIsAddFamilyMemberModalOpen(true);
    }
  }, [editedFamilyMember]);

  const handleAddFamilyMember = (data: FamilyMemberCreationDto) => {
    dispatch(createFamilyMember({ patientId: id!, data })).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        dispatch(getPatientById(String(id)));
      }
    });
  };

  const handleEditFamilyMember = ({
    memberId,
    data,
  }: {
    memberId: string;
    data: FamilyMemberUpdateDto;
  }) => {
    dispatch(updateFamilyMember({ patientId: id!, memberId, data })).then(
      (data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          dispatch(getPatientById(String(id)));
        }
      },
    );
  };

  const handleCloseAddModal = () => {
    setIsAddFamilyMemberModalOpen(false);
    dispatch(setEditedFamilyMember(null));
  };

  const handleCloseSuccessAddModal = async () => {
    await dispatch(setIsCreatedFamilyMember(false));
    await dispatch(setIsUpdatedFamilyMember(false));
    await setIsAddFamilyMemberModalOpen(false);
    await dispatch(getFamilyMembers(String(id)));

    await handleCloseAddModal();
  };

  const addSuccessText = <p>{t('family_members.fm_successfully_added')}</p>;
  const updatedSuccessText = (
    <p>{t('family_members.fm_successfully_updated')}</p>
  );

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Title>
        <h1>{t('family_members.title')}</h1>
        <SecondaryButton
          styleType={'color'}
          onClick={() => setIsAddFamilyMemberModalOpen(true)}
        >
          <PlusIcon />
          {t('family_members.add_family_member')}
        </SecondaryButton>
      </Title>

      <TableStyled>
        <thead>
          <tr>
            <th>
              <span>{t('family_members.table_name')}</span>
            </th>
            <th>
              <span>{t('family_members.table_relationship')}</span>
            </th>
            <th>
              <span>{t('family_members.table_date_of_birth')}</span>
            </th>
            <th>
              <span>
                {t('family_members.table_fiscal_identification_number')}
              </span>
            </th>
            <th>
              <span>{t('family_members.table_social_security_number')}</span>
            </th>
            <th>
              <span>{t('family_members.actions')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {familyMembers?.map((member) => (
            <FamilyMemberItem key={member.id} data={member} />
          ))}
        </tbody>
      </TableStyled>

      {!familyMembers?.length && <EmptyFamilyMembersState />}
      <AddFamilyMemberModal
        onClose={handleCloseAddModal}
        isOpen={isAddFamilyMemberModalOpen}
        onAdd={handleAddFamilyMember}
        editMode={!!editedFamilyMember}
        onEdit={handleEditFamilyMember}
        data={editedFamilyMember || null}
      />
      {isCreatedFamilyMember && (
        <FamilyMemberSuccessModal
          text={addSuccessText}
          onClose={handleCloseSuccessAddModal}
        />
      )}
      {isUpdatedFamilyMember && (
        <FamilyMemberSuccessModal
          text={updatedSuccessText}
          onClose={handleCloseSuccessAddModal}
        />
      )}
    </Wrapper>
  );
};

export default FamilyMembersTable;
