import { useTranslation } from 'react-i18next';

export const useSteps = () => {
  const { t } = useTranslation();

  const steps = [
    {
      id: 'select-the-subscription',
      label: t('clinicRegistration.stepper.select_subscription'),
    },
    {
      id: 'contractual-agreement',
      label: t('clinicRegistration.stepper.contractual_agreement'),
    },
    {
      id: 'personal-information',
      label: t('clinicRegistration.stepper.personal_information'),
    },
    {
      id: 'email-verification',
      label: t('clinicRegistration.stepper.set_password'),
    },
    {
      id: 'billing-information',
      label: t('clinicRegistration.stepper.billing_information'),
    },
    {
      id: 'payment-process',
      label: t('clinicRegistration.stepper.payment_process'),
    },
  ];

  return steps;
};
