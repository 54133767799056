import React, { FC, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from 'applicaiton/assets/arrow-left.svg';
import { ReactComponent as HelpIcon } from 'applicaiton/assets/help.svg';
import { PathNames } from 'applicaiton/routes';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';
import { setActiveForm } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';
import { ProfileForm } from 'applicaiton/constants/clincOwnerProfile';
import { useAppDispatch } from 'common/hooks/redux';
import { HeaderStyled } from './styles';
import { DocBaySupportLink } from 'applicaiton';
const Header: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isEditProfessionalPage = location.pathname.includes(
    PathNames.editProfessional,
  );

  const cancelChanges = () => {
    dispatch(professionalProfileActions.setProfileChanges(null));
  };

  useEffect(() => {
    return () => cancelChanges();
  }, []);

  const handleChangeActiveFrom = () => {
    if (isEditProfessionalPage) {
      navigate(-1);
    } else {
      dispatch(setActiveForm(ProfileForm.previewProfile));
    }
  };

  return (
    <HeaderStyled>
      {!isEditProfessionalPage ? (
        <button onClick={handleChangeActiveFrom}>
          <ArrowLeftIcon />
          {t('professionalConfiguration.back_to_docbay_profile')}
        </button>
      ) : (
        <Link to={PathNames.professionals}>
          <ArrowLeftIcon />
          {t('professionalConfiguration.back_to_all_professionals')}
        </Link>
      )}
      <Link to={DocBaySupportLink} target="_blank">
        <HelpIcon />
        {t('professionalConfiguration.help_center')}
      </Link>
    </HeaderStyled>
  );
};

export default Header;
