import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { CheckVerificationCodeT } from './types';
import { useVerificationForm } from './hooks/useVerificationForm';
import { Input, PrimaryButton, ReCaptcha } from 'common/components';

import {
  FormGroupStyled,
  ForgotPasswordWrapper,
  StyledForm,
  FormBlueSubTitle,
  FormSubTitle,
  UseOtherEmailWrapper,
  StyledBlueLink,
  UseOtherEmailTilte,
  ResendCodeWrapper,
  ResendButton,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  usersVerifyCode,
  usersForgotPassword,
} from 'applicaiton/store/reducers/Users/ActionCreators';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import {
  setAuthError,
  setIsValidCode,
} from 'applicaiton/store/reducers/Users/UsersSlice';
import BackButton from './components/BackButton';
import { authService } from 'common';
import { FormTitle } from './components/Header/styles';
import DividerGroup from 'common/components/DividerGroup';
import { Box } from '@mui/system';
import Timer from './components/Timer';
import { SubPathNames } from './constants';

const ConfirmPasswordChangePage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const email = authService.getEmail();
  const location = useLocation();
  const expired = location.search === SubPathNames.expired;
  const { error, isValidCode } = useAppSelector((state) => state.users);

  const {
    register,
    errors,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
  } = useVerificationForm({
    requiredError: t('errors.required'),
  });

  useEffect(() => {
    if (error) {
      setError('code', { message: ' ' });
    } else {
      clearErrors();
    }
  }, [error]);

  useEffect(() => {
    if (isValidCode) {
      navigate(PathNames.createNewPassword);
    }
    return () => {
      dispatch(setIsValidCode(false));
      dispatch(setAuthError(false));
    };
  }, [isValidCode]);

  useEffect(() => {
    if (error) {
      dispatch(setAuthError(false));
    }
  }, [watch('code')]);

  const onChangeReCAPTCHA = (token: string | null) => {
    if (token) {
      setValue('captchaToken', token, {
        shouldValidate: !!errors.captchaToken?.message,
      });
    }
  };

  const onExpiredReCAPTCHA = () => {
    setValue('captchaToken', '', {
      shouldValidate: !!watch('captchaToken'),
    });
  };

  const onSubmit = async (data: CheckVerificationCodeT) => {
    authService.saveCode(data.code);
    dispatch(usersVerifyCode({ email: email!, code: String(data.code) }));
  };

  const handleResendCode = async () => {
    const captchaToken = watch('captchaToken');
    authService.removeTimer();
    const response = await dispatch(
      usersForgotPassword({
        email: email!,
        captchaToken,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled')
      navigate(PathNames.confirmChange);
  };

  return (
    <>
      <BackButton />
      <ForgotPasswordWrapper>
        <Box>
          <FormTitle>{t('auth.forgot_password.title')}</FormTitle>
          {!expired && (
            <FormSubTitle>
              {t('auth.forgot_password.confirm_sub_title_1')}&nbsp;
              <FormBlueSubTitle>{email}.</FormBlueSubTitle>&nbsp;
              {t('auth.forgot_password.confirm_sub_title_2')}
            </FormSubTitle>
          )}
        </Box>
        {!expired && (
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <FormGroupStyled>
              <Input
                id="code"
                label={t('auth.forgot_password.verification_code') || ''}
                type="number"
                placeholder={
                  t('auth.forgot_password.verification_code_placeholder') || ''
                }
                errorMessage={
                  (errors.code?.message &&
                    t('auth.forgot_password.incorrect_code')) ||
                  ''
                }
                register={register}
              />
            </FormGroupStyled>

            <PrimaryButton
              onClick={handleSubmit(onSubmit)}
              type="submit"
              disabled={!!Object.keys(errors).length}
            >
              {t('auth.continue')}
            </PrimaryButton>
          </StyledForm>
        )}
        {!expired && <Timer />}
        {expired && (
          <>
            <ResendCodeWrapper>
              <UseOtherEmailTilte>
                {t('auth.forgot_password.expired_code')}
              </UseOtherEmailTilte>
              &nbsp;
              <ResendButton
                disabled={!watch('captchaToken')}
                onClick={handleResendCode}
              >
                {t('auth.forgot_password.resend_code')}
              </ResendButton>
            </ResendCodeWrapper>
            <ReCaptcha
              error={errors.captchaToken?.message}
              onChange={onChangeReCAPTCHA}
              onExpired={onExpiredReCAPTCHA}
            />
          </>
        )}

        <UseOtherEmailWrapper>
          <DividerGroup />
          <Box textAlign="center">
            <UseOtherEmailTilte>
              {t('auth.forgot_password.still_no_code')}
            </UseOtherEmailTilte>
            <StyledBlueLink to={PathNames.forgotPassword}>
              {t('auth.forgot_password.use_other_email')}
            </StyledBlueLink>
          </Box>
        </UseOtherEmailWrapper>
      </ForgotPasswordWrapper>
    </>
  );
};

export default ConfirmPasswordChangePage;
