import React, { FC, useState } from 'react';
import { StyledTr, ActionsStyled } from './styles';
import { FamilyMemberResponseDto } from '@docbay/schemas';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import ConfirmDeleteFamilyMember from '../ConfirmDeleteFamilyMember';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  deleteFamilyMember,
  getFamilyMembers,
  getPatientById,
} from 'applicaiton/store/reducers/Patients/ActionCreators';
import { useParams } from 'react-router-dom';
import FamilyMemberSuccessModal from '../FamilyMemberSuccessModal';
import { useTranslation } from 'react-i18next';
import {
  setEditedFamilyMember,
  setIsDeletedFamilyMember,
} from 'applicaiton/store/reducers/Patients/PatientsSlice';

interface Props {
  data: FamilyMemberResponseDto;
}

const FamilyMemberItem: FC<Props> = ({ data }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);

  const { currentPatient, isDeletedFamilyMember } = useAppSelector(
    (state) => state.patients,
  );

  const handleDeleteFamilyMember = () => {
    dispatch(deleteFamilyMember({ patientId: id!, fmId: data?.id })).then(
      (data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          dispatch(getPatientById(String(id)));
        }
      },
    );
  };

  const deleteSuccessText = (
    <p>
      {t('family_members.delete_text1')}{' '}
      <span>
        {data.firstName} {data.lastName}
      </span>{' '}
      {t('family_members.delete_text2')}
    </p>
  );

  const handleCloseSuccessModal = async () => {
    await setIsShowConfirmDelete(false);
    await dispatch(setIsDeletedFamilyMember(false));
    await dispatch(getFamilyMembers(String(id)));
  };

  const handleEditFamilyMember = () => {
    dispatch(setEditedFamilyMember(data));
  };

  return (
    <StyledTr>
      <td>
        {data.firstName} {data.lastName}
      </td>
      <td>{data.relationship}</td>
      <td>{data.dateOfBirth}</td>
      <td>{data.nif}</td>
      <td>{data.socialSecurityNumber}</td>
      <td>
        {!currentPatient?.user?.id && (
          <ActionsStyled>
            <button onClick={handleEditFamilyMember}>
              <EditIcon />
            </button>
            <button onClick={() => setIsShowConfirmDelete(true)}>
              <DeleteIcon />
            </button>
          </ActionsStyled>
        )}
      </td>
      <ConfirmDeleteFamilyMember
        isOpen={isShowConfirmDelete}
        familyMemberName={`${data.firstName} ${data.lastName}`}
        onClose={() => setIsShowConfirmDelete(false)}
        onSubmit={handleDeleteFamilyMember}
      />
      {isDeletedFamilyMember && (
        <FamilyMemberSuccessModal
          text={deleteSuccessText}
          onClose={handleCloseSuccessModal}
        />
      )}
    </StyledTr>
  );
};

export default FamilyMemberItem;
