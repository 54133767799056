import { Box, styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

export const ModalStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 500px;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  background: ${commonColors.white};
`;

export const ModalHeaderStyled = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(4)};
  border-bottom: 1px solid ${commonColors.grey200};
  svg {
    cursor: pointer;
    path {
      fill: ${commonColors.grey700};
    }
    :hover {
      path {
        fill: ${commonColors.grey900};
      }
    }
  }
  .title {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const ModalContentStyled = styled(Box)`
  padding: ${({ theme }) => theme.spacing(4)};
  border-bottom: 1px solid ${commonColors.grey200};
`;

export const ModalActionsStyled = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(2.5)};
`;
