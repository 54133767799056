import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientResponseDto } from '@docbay/schemas';

import { fetchPatients } from 'applicaiton/store/reducers/Patients/ActionCreators';
import { selectPatientToDelete } from 'applicaiton/store/reducers/Patients/PatientsSlice';

import { Pagination } from 'common/components';
import { Option as ClinicOption } from 'common/components/DropdownClinics/models';
import { useDebounce } from 'common/hooks/useDebounce';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import PatientItem from '../PatientItem';
import NoDataElement from '../NoDataElement';
import FilterSection from '../FilterSection';

import { TableStyled, Wrapper } from './styles';
import { PatientsTableProps } from './models';

const defaultLimit = 10;

const PatientsTable: FC<PatientsTableProps> = ({
  currentPage,
  setCurrentPage,
  handleShowModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { patients, totalPages, deletedSuccess, total } = useAppSelector(
    (state) => state.patients,
  );
  const [searchItem, setSearchItem] = useState('');
  const [clinics, setClinics] = useState<ClinicOption[]>([]);
  const searchByName = useDebounce(searchItem, 500);

  const getData = (page: number) => {
    const clinicIds = clinics.map((clinic) => String(clinic.value)).join();

    dispatch(
      fetchPatients({
        search: searchByName,
        page: page,
        limit: defaultLimit,
        clinicIds,
      }),
    );

    setCurrentPage(1);
  };

  useEffect(() => {
    const page = deletedSuccess
      ? patients?.length === 1
        ? currentPage === 1
          ? 1
          : currentPage - 1
        : 1
      : currentPage;

    getData(page);

    setCurrentPage(page);
  }, [dispatch, currentPage, deletedSuccess]);

  useEffect(() => {
    getData(1);

    setCurrentPage(1);
  }, [searchByName, clinics]);

  const handleRemoveClinic = (id: string) => {
    setClinics((prev) => [...prev.filter((item) => item.value !== id)]);
  };

  const handleDeletePatient = (patient: PatientResponseDto) => {
    dispatch(selectPatientToDelete(patient));
  };

  return (
    <>
      <Wrapper>
        <FilterSection
          searchItem={searchItem}
          setSearchItem={setSearchItem}
          clinics={clinics}
          setClinics={setClinics}
          handleRemoveClinic={handleRemoveClinic}
        />
        <TableStyled>
          <thead>
            <tr>
              <th>
                <span>{t('my_patients.patient_name')}</span>
              </th>
              <th>
                <span>{t('my_patients.email')}</span>
              </th>
              <th>
                <span>{t('my_patients.date_of_birth')}</span>
              </th>
              <th>
                <span>{t('my_patients.phone')}</span>
              </th>
              <th>
                <span>{t('my_patients.actions')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {patients?.length ? (
              patients.map((patient) => (
                <PatientItem
                  key={patient.id}
                  patient={patient}
                  handleDeletePatient={handleDeletePatient}
                />
              ))
            ) : (
              <NoDataElement handleShowModal={handleShowModal} />
            )}
          </tbody>
        </TableStyled>
        {patients?.length && totalPages > 1 ? (
          <Pagination
            pages={totalPages}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            itemCount={total}
          />
        ) : (
          ''
        )}
      </Wrapper>
    </>
  );
};

export default PatientsTable;
