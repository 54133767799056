import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopicItem from '../TopicItem';
import { Wrapper, TableStyled } from './styles';
import NoDataElement from '../NoDataElement';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchClinics } from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { Loader, Pagination } from 'common/components';
import FilterSection from '../FilterSection';
import { Option } from 'common/components/Dropdown/models';
import { getTopics } from 'applicaiton/store/reducers/Topics/ActionCreators';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { resetActionsTopic } from 'applicaiton/store/reducers/Topics/TopicsSlice';

interface Props {
  openAddModal: () => void;
}

const defaultLimit = 10;

const TopicsTable: FC<Props> = ({ openAddModal }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [clinic, setClinic] = useState<Option[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const userRole = getUserRole();

  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const { topics, totalPages, isLoading, isAdded, isUpdated } = useAppSelector(
    (state) => state.topics,
  );
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);

  const userId = useMemo(() => {
    return isClinicOwnerRole
      ? currentClinicOwner?.professional?.id
      : getUserId();
  }, [currentClinicOwner]);

  const getAllTopics = () => {
    const clinicIds = clinic.map((item) => String(item.value)).join();

    const params = {
      page: currentPage,
      limit: defaultLimit,
      ...(clinic ? { clinicIds: clinicIds as string } : {}),
    };

    if (userId) {
      dispatch(getTopics({ id: userId!, params }));
    }
  };

  useEffect(() => {
    dispatch(fetchClinics());

    userId && getAllTopics();
  }, [isClinicOwnerRole, userId]);

  useEffect(() => {
    getAllTopics();
  }, [currentPage, clinic]);

  useEffect(() => {
    if (isAdded || isUpdated) {
      getAllTopics();

      dispatch(resetActionsTopic());
    }
  }, [isAdded, isUpdated]);

  const handleClinicChange = (value: Option[]) => {
    const clinicsItems = value.map((item) => {
      return {
        label: item.label,
        value: item.value,
      };
    });

    setClinic(clinicsItems);
  };

  const handleUpdateList = () => {
    getAllTopics();
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <FilterSection clinics={clinic} setClinic={handleClinicChange} />
      <TableStyled>
        <thead>
          <tr>
            <th>
              <span>{t('topics_of_chats.table.title')}</span>
            </th>
            <th>
              <span>{t('topics_of_chats.table.clinic_name')}</span>
            </th>
            <th>
              <span>{t('clinicsConfiguration.clinics.actions')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {topics?.map((topic, index) => {
            return (
              <TopicItem
                key={index}
                topic={topic}
                updateList={handleUpdateList}
              />
            );
          })}
          {!isLoading && !topics?.length && (
            <NoDataElement openAddModal={openAddModal} />
          )}
        </tbody>
      </TableStyled>
      {topics?.length && totalPages > 1 ? (
        <Pagination
          pages={totalPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          itemCount={topics.length}
        />
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default TopicsTable;
