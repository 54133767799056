import styled from 'styled-components';

export const StyledForm = styled.form`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:first-child {
    display: grid;
    gap: 16px;
    max-width: 470px;
    margin-bottom: 20px;
  }

  button {
    height: 48px;
  }
`;
