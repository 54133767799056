import styled from 'styled-components';

import { ReactComponent as ArrowIcon } from 'applicaiton/assets/arrow-left.svg';

interface StyledProps {
  $visible?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

export const Wrapper = styled.div``;

export const Label = styled.label<StyledProps>`
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  font-style: normal;
  letter-spacing: 0.005em;
  color: ${({ theme, errorMessage }) =>
    errorMessage ? theme.color.error2 : theme.color.grey600};
`;

export const Container = styled.div<StyledProps>`
  display: flex;
  border-radius: 8px;
  box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid
    ${({ theme, errorMessage }) =>
      errorMessage ? theme.color.error2 : theme.color.grey300};
`;

export const InputStyled = styled.input<StyledProps>`
  width: 100%;
  border: none;
  outline: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px 14px;

  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  font-style: normal;
  font-family: 'Mulish';
  letter-spacing: 0.08px;
  color: ${({ theme, errorMessage }) =>
    errorMessage ? theme.color.error2 : theme.color.grey700};

  ::placeholder {
    color: ${({ theme, errorMessage }) =>
      errorMessage ? theme.color.error2 : theme.color.grey500};
  }

  :disabled {
    background: ${({ theme, disabled }) =>
      disabled ? theme.color.grey50 : theme.color.white};
  }
`;

export const SocialContainer = styled.div<StyledProps>`
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px 14px;
  border-right: 1px solid
    ${({ theme, errorMessage }) =>
      errorMessage ? theme.color.error2 : theme.color.grey300};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.color.grey50 : theme.color.white};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  > div {
    display: flex;
    align-items: center;
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  }
`;

export const Error = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: ${({ theme }) => theme.color.error2};
`;

export const Arrow = styled(ArrowIcon)<StyledProps>`
  margin-left: 8px;
  color: ${({ theme }) => theme.color.grey500};
  transform: ${({ $visible }) => `rotate(${$visible ? 90 : -90}deg)`};
`;

export const List = styled.div`
  left: 0;
  top: 100%;
  z-index: 15;
  position: absolute;

  display: flex;
  flex-direction: column;
  padding: 10px 20px;

  border-radius: 8px;
  box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.color.grey300};
  svg {
    margin: 10px 0;
    cursor: pointer;
  }
`;
