import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Timer from '../Timer';
import { WaitingScreenProps } from './modules';
import { Wrapper, ButtonSection } from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import dayjs from 'dayjs';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { useNavigate } from 'react-router';
import { PathNames } from 'applicaiton/routes';
import { setSlotsDetails } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';

const WaitingScreen: FC<WaitingScreenProps> = ({
  startDate,
  isEventStarted,
  setIsEventStarted,
  isMeetingFinished,
  handleRejoin,
  showBackButton,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const eventNotLongActive = dayjs().isAfter(
    dayjs(currentAppointment?.startDate).set(
      'minute',
      dayjs(currentAppointment?.startDate).minute() + 60,
    ),
  );

  const showTimer = !isMeetingFinished && !eventNotLongActive;
  const showEventNotLongActive = !isMeetingFinished && eventNotLongActive;

  const handleNavigateToAppointment = () => {
    navigate(PathNames.schedule);
    dispatch(
      setSlotsDetails({
        appointmentId: String(currentAppointment?.id),
        mode: ReserveSlotsMode.PreviewAppointment,
      }),
    );
  };

  return (
    <Wrapper>
      {showTimer && (
        <>
          <h2>{t('teleconsultation.live_teleconsultation_start_in')}</h2>
          <Timer startDate={startDate} setIsEventStarted={setIsEventStarted} />
          <p>{t('teleconsultation.patient_not_show_up')}</p>
        </>
      )}
      {showEventNotLongActive && (
        <>
          <h2>{t('teleconsultation.teleconsultation_no_longer_active')}</h2>
          <PrimaryButton type="button" onClick={handleNavigateToAppointment}>
            {t('teleconsultation.back_to_appointment_screen')}
          </PrimaryButton>
        </>
      )}
      {isMeetingFinished && (
        <>
          <h2>{t('teleconsultation.you_left_meeting')}</h2>
          <ButtonSection>
            <SecondaryButton
              type="button"
              styleType="color"
              onClick={handleRejoin}
            >
              {t('teleconsultation.rejoin')}
            </SecondaryButton>
            {showBackButton && (
              <PrimaryButton
                type="button"
                onClick={handleNavigateToAppointment}
              >
                {t('teleconsultation.back_to_appointment_screen')}
              </PrimaryButton>
            )}
          </ButtonSection>
        </>
      )}
    </Wrapper>
  );
};

export default WaitingScreen;
