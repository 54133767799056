import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { DividerGroupStyled, DividerStyled, StyledTypography } from './styles';

const DividerGroup: FC = () => {
  const { t } = useTranslation();

  return (
    <DividerGroupStyled>
      <DividerStyled />

      <StyledTypography>{t('or')}</StyledTypography>

      <DividerStyled />
    </DividerGroupStyled>
  );
};

export default DividerGroup;
