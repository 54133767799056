import styled from 'styled-components';

interface Color {
  color?: string;
  backgroundColor?: string;
}
export const Wrapper = styled.article`
  width: 100%;
`;

export const DropdownSection = styled.section`
  display: grid;
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;
  grid-template-columns: calc(75% - 12px) calc(25% - 12px);
`;

export const BillingTitleSection = styled.section`
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;
`;

export const DropdownBottomSection = styled.section`
  display: grid;
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
`;

export const RadioButtonSection = styled.section`
  display: grid;
  width: 100%;
  gap: 12px;
  margin-bottom: 24px;

  > div {
    display: flex;

    div:last-child {
      margin-left: 16px;
    }
  }

  > p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.error2};
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const ColorSection = styled.div`
  display: flex;
  align-items: flex-start;
`;
export const ColorPicker = styled.div`
  width: 176px;
  height: 44px;
  padding: 10px 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 2px 0 rgba(54, 54, 54, 0.1);
  cursor: pointer;
`;

export const ColorValue = styled.div<Color>`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: ${({ color }) => color};
`;
export const ColorPickerWrapper = styled.div<Color>`
  position: relative;

  > label {
    display: block;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 4px;
  }
`;

export const Picker = styled.div<Color>`
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  z-index: 2;
`;

export const PreviewAppointmentWrapper = styled.div`
  margin-left: 32px;

  > label {
    display: block;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 4px;
  }
`;

export const PreviewAppointments = styled.div<Color>`
  display: flex;

  div {
    padding: 8px;
    width: 150px;
    border-radius: 4px;
    border-left: 2px solid ${({ color }) => color};
    background: ${({ backgroundColor }) => backgroundColor};

    > span {
      display: block;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Mulish;
      font-style: normal;
      line-height: normal;
    }

    &:last-child {
      margin-left: 32px;
      width: 58px;
    }
  }
`;

export const Name = styled.span<Color>`
  color: ${({ theme }) => theme.color.grey900};
  font-size: 14px;
  font-weight: 700;
`;

export const Type = styled.span<Color>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.grey700};
  font-size: 12px;
  font-weight: 500;

  > svg {
    width: 12px;
    height: 12px;
    margin-left: 4px;

    rect {
      fill: ${({ color }) => color};
    }
  }
`;

export const Time = styled.span<Color>`
  color: ${({ theme }) => theme.color.grey500};
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
`;

export const CheckboxWrapper = styled.div`
  margin-top: 24px;

  > label {
    display: block;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 8px;
  }
`;

export const SwitchStyled = styled.div`
  display: flex;

  > p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-left: 12px;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;

  svg {
    path {
      fill: ${({ theme }) => theme.color.grey600};
    }
  }

  > div {
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.07px;
    margin-left: 8px;

    span {
      color: ${({ theme }) => theme.color.blue800};
    }
  }
`;

export const PriceInputStyled = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WarningText = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.grey900};
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  white-space: nowrap;

  > a {
    color: ${({ theme }) => theme.color.blue700};
    font-weight: 700;
    text-decoration-line: underline;
    margin-left: 6px;
  }
`;
