import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.grey900};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  height: 48px;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.blue300};
  box-shadow: 1px 1px 2px 0 rgba(54, 54, 54, 0.1);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    min-width: 24px;
    height: 24px;
    margin-left: 20px;
  }

  :hover {
    background-color: ${({ theme }) => theme.color.blue400};
  }
`;
