import React, { FC, useEffect } from 'react';
import { Wrapper, EditType } from './styles';
import { Header, LayoutComponent } from './components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'common/hooks/redux';
import { resetAppointmentTypeChanges } from 'applicaiton/store/reducers/AppointmentTypes/AppointmentTypesSlice';

const AppointmentTypeAdd: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetAppointmentTypeChanges());
    };
  }, []);

  return (
    <Wrapper>
      <Header />
      <EditType>{t('appointment_types.edit_appointment_types')}</EditType>
      <LayoutComponent />
    </Wrapper>
  );
};

export default AppointmentTypeAdd;
