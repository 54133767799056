import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as CancelIcon } from 'applicaiton/assets/cancel.svg';
import { StyledModal } from './styles';

interface DeleteModalProps {
  fullName: string;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteModal: FC<DeleteModalProps> = ({ fullName, onClose, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <StyledModal>
      <CloseIcon onClick={onClose} />
      <h1>
        {t('my_patients.delete_patient.titlePart1')} <span>{fullName}</span>{' '}
        {t('my_patients.delete_patient.titlePart2')}
      </h1>
      <div>
        <p>{t('my_patients.delete_patient.description')}</p>
        <div>
          <CancelIcon />
          {t('my_patients.delete_patient.descriptionText1')}
        </div>
      </div>
      <section>
        <SecondaryButton onClick={onClose}>
          {t('my_patients.delete_patient.cancel')}
        </SecondaryButton>
        <PrimaryButton onClick={onSubmit} styleType={'error'}>
          {t('my_patients.delete_patient.confirm')}
        </PrimaryButton>
      </section>
    </StyledModal>
  );
};

export default DeleteModal;
