import {
  getUserPermissions,
  getUserProPermissions,
} from 'applicaiton/sessionStorage/auth';
import { UserPermissions } from 'applicaiton/constants/userPermissions';

export const isShowClinicsForProfessional = () => {
  const permissions = getUserPermissions();
  const proPermissions = getUserProPermissions();

  if (proPermissions) {
    const canPreviewClinic = proPermissions.some((item: any) => {
      for (const property in item) {
        return (
          item[property].includes(UserPermissions.clinicDeletion) ||
          item[property].includes(UserPermissions.clinicOpeningHours) ||
          item[property].includes(UserPermissions.clinicFAQ) ||
          item[property].includes(
            UserPermissions.clinicProfessionalsManagement,
          ) ||
          item[property].includes(UserPermissions.clinicDescription) ||
          item[property].includes(UserPermissions.clinicInsurancesConfiguration)
        );
      }
    });
    return canPreviewClinic;
  }

  if (
    permissions?.includes(UserPermissions.clinicDeletion) ||
    permissions?.includes(UserPermissions.clinicOpeningHours) ||
    permissions?.includes(UserPermissions.clinicFAQ) ||
    permissions?.includes(UserPermissions.clinicProfessionalsManagement) ||
    permissions?.includes(UserPermissions.clinicDescription) ||
    permissions?.includes(UserPermissions.clinicInsurancesConfiguration) ||
    permissions?.includes(UserPermissions.clinicAppointmentTypesConfiguration)
  ) {
    return true;
  }
};

export const isUserCanDeleteClinic = () => {
  const permissions = getUserPermissions();

  return permissions?.includes(UserPermissions.clinicDeletion);
};

export const isUserCanEditClinic = () => {
  const permissions = getUserPermissions();
  const proPermissions = getUserProPermissions();

  if (proPermissions) {
    const canEditClinic = proPermissions.some((item: any) => {
      for (const property in item) {
        return (
          item[property].includes(UserPermissions.clinicOpeningHours) ||
          item[property].includes(UserPermissions.clinicFAQ) ||
          item[property].includes(
            UserPermissions.clinicProfessionalsManagement,
          ) ||
          item[property].includes(UserPermissions.clinicDescription) ||
          item[property].includes(UserPermissions.clinicInsurancesConfiguration)
        );
      }
    });
    return canEditClinic;
  }
  if (
    permissions?.includes(UserPermissions.clinicOpeningHours) ||
    permissions?.includes(UserPermissions.clinicFAQ) ||
    permissions?.includes(UserPermissions.clinicProfessionalsManagement) ||
    permissions?.includes(UserPermissions.clinicDescription) ||
    permissions?.includes(UserPermissions.clinicInsurancesConfiguration)
  ) {
    return true;
  }
};

export const isDisabledEditProfessional = (
  isOneClinicChain: boolean,
  sameProfessional: boolean,
) => {
  const permissions = getUserPermissions();

  if (sameProfessional) return false;

  if (
    !isOneClinicChain ||
    (isOneClinicChain &&
      !permissions?.includes(UserPermissions.clinicProfessionalsManagement))
  ) {
    return true;
  }

  return false;
};

export const isUserCanManageProfessional = () => {
  const permissions = getUserPermissions();
  const proPermissions = getUserProPermissions();

  if (proPermissions) {
    const canManageProfessionals = proPermissions.some((item: any) => {
      for (const property in item) {
        return item[property].includes(
          UserPermissions.clinicProfessionalsManagement,
        );
      }
    });

    return canManageProfessionals;
  }

  return permissions?.includes(UserPermissions.clinicProfessionalsManagement);
};

export const isUserCanManageAppointmentTypes = () => {
  const permissions = getUserPermissions();

  return permissions?.includes(
    UserPermissions.clinicAppointmentTypesConfiguration,
  );
};
