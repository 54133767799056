import React, { FC } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { getDayjsFromUTCInDefaultTimezone } from 'common/helpers/dateTimeHelper';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as CancelIcon } from 'applicaiton/assets/cancel.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { DeleteModalProps } from './modules';

const DeleteModal: FC<DeleteModalProps> = ({
  isOpen,
  patientName,
  startDate,
  onClose,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language);
  const formattedDate = getDayjsFromUTCInDefaultTimezone(
    new Date(startDate),
  ).format('DD MMM HH:mm');

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <h1>
          {t('appointment.delete_type.titlePart1')} <span>{patientName}</span>{' '}
          {t('on')} <span>{formattedDate}</span>
        </h1>
        <div>
          <p> {t('appointment.delete_type.description')}</p>
          <div>
            <CancelIcon />
            {t('appointment.delete_type.descriptionText1')}
          </div>
          <div>
            <CancelIcon />
            {t('appointment.delete_type.descriptionText2')}
          </div>
          <div>
            <CancelIcon />
            {t('appointment.delete_type.descriptionText3')}
          </div>
        </div>
        <section>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              onClose();
              onSubmit();
            }}
            styleType={'error'}
          >
            {t('appointment_types.delete_type.confirm')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default DeleteModal;
