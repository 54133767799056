import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input,
  InputPhone,
  PhotoUploader,
  PrimaryButton,
} from 'common/components';
import {
  Wrapper,
  ButtonsWrapper,
  FieldsSection,
  StyledDivider,
} from './styles';
import { useSecretaryInfoForm } from '../../hooks/useSecretaryInfoForm';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { resetIsUpdatedSecretaryData } from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { uploadedPhoto } from 'applicaiton/store/reducers/Photo/PhotoSlice';
import { editSecretary } from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import SuccessModal from '../SuccessModal';

const ProfileInfo: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { register, errors, handleSubmit, watch, setValue } =
    useSecretaryInfoForm({
      requiredError: t('errors.required'),
      phoneError: t('invalid_phone_number'),
    });

  const { uploadedPhotos } = useAppSelector((state) => state.photos);
  const { currentUser, isUpdated } = useAppSelector(
    (state) => state.secretaries,
  );

  const isStateChanged = useMemo(() => {
    const formattedUserPhoneNumber = currentUser?.phone!.replace(/\+/g, '');
    const formattedPhoneNumber = watch('phone').replace(/\+/g, '');

    const hasChanges = compareObjectsChanges(
      {
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
        email: currentUser?.email,
        phone: formattedUserPhoneNumber,
      },
      {
        firstName: watch('firstName'),
        lastName: watch('lastName'),
        email: watch('email'),
        phone: formattedPhoneNumber,
      },
    );

    return hasChanges || !!uploadedPhotos.length;
  }, [
    currentUser,
    watch('firstName'),
    watch('lastName'),
    watch('email'),
    watch('phone'),
    uploadedPhotos,
  ]);

  useEffect(() => {
    if (currentUser) {
      const formattedPhoneNumber = currentUser.phone!.replace(/\+/g, '');

      setValue('firstName', currentUser.firstName);
      setValue('lastName', currentUser.lastName);
      setValue('email', currentUser.email);
      setValue('phone', formattedPhoneNumber);

      if (currentUser?.photo) {
        const photo = {
          key: currentUser?.photo.key,
          photoUrl: currentUser.photo.photoUrl,
          thumbnailUrl: currentUser.photo.thumbnailUrl,
        };
        uploadedPhoto([photo]);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const onSubmit = async () => {
    dispatch(
      editSecretary({
        id: currentUser?.id!,
        data: {
          firstName: watch('firstName'),
          lastName: watch('lastName'),
          phone: `+${watch('phone')}`,
          ...(uploadedPhotos.length
            ? {
                photo: uploadedPhotos[0],
                ...(currentUser?.photo?.id
                  ? { photoToDelete: currentUser?.photo?.id }
                  : {}),
              }
            : {}),
        },
      }),
    ).then(() => {
      dispatch(uploadedPhoto([]));
      dispatch(setHasUnsavedChanges(false));
    });
  };

  const closeSuccessModal = () => {
    dispatch(resetIsUpdatedSecretaryData());
  };

  return (
    <Wrapper>
      <FieldsSection>
        <Input
          id="email"
          label={t('email') || ''}
          type="email"
          placeholder={t('email_placeholder') || ''}
          register={register}
          errorMessage={errors.email?.message}
          disabled={true}
        />
        <Input
          label={t('personal_information.first_name') || ''}
          id={'firstName'}
          type="text"
          register={register}
          errorMessage={errors?.firstName?.message}
          placeholder={t('personal_information.first_name_placeholder') || ''}
        />
        <Input
          label={t('personal_information.last_name') || ''}
          id={'lastName'}
          type="text"
          register={register}
          errorMessage={errors?.lastName?.message}
          placeholder={t('personal_information.last_name_placeholder') || ''}
        />
        <InputPhone
          id="phone"
          label={t('phone') || ''}
          register={register}
          errorMessage={errors.phone?.message}
          value={watch('phone')}
          isValid={true}
        />
        <PhotoUploader
          defaultPhoto={currentUser?.photo}
          label={t('upload_photo_optional') || ''}
        />
      </FieldsSection>
      <StyledDivider />

      <ButtonsWrapper>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={!!Object.keys(errors).length || !isStateChanged}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonsWrapper>

      {isUpdated && (
        <SuccessModal
          typeName={`${currentUser?.firstName} ${currentUser?.lastName}`}
          onClose={closeSuccessModal}
        />
      )}
    </Wrapper>
  );
};

export default ProfileInfo;
