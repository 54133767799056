import React, { useMemo } from 'react';
import {
  Wrapper,
  Date,
  DateWrapper,
  IconsWrapper,
  StyledCalendarIcon,
  StyledDeleteIcon,
  Name,
  Type,
  StyledEditIcon,
  MemberStyled,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'common/hooks/redux';
import {
  setCurrentAppointment,
  setIsHighlightedAppointment,
} from 'applicaiton/store/reducers/Appointments/PatientAppoinmentsSlice';
import { AppointmentBriefResponseDto } from '@docbay/schemas';
import {
  selectAppointmentToDeleted,
  setSlotsDetails,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { MembersIcon } from 'common/components/Icons';
import { formatDateTimeInDefaultTimezone } from 'common/helpers/dateTimeHelper';

interface Props {
  appointment: AppointmentBriefResponseDto;
}

const PatientsAppointment = ({ appointment }: Props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();

  const isCanReschedule = userHasPermission(
    UserPermissions.rescheduleAppointment,
  );

  const canUserCancel = userHasPermission(UserPermissions.cancelAgenda);

  const handleEditAppointment = () => {
    dispatch(
      setSlotsDetails({
        appointmentId: appointment.id,
        mode: ReserveSlotsMode.EditAppointment,
      }),
    );
  };

  const handleDeleteAppointment = () => {
    dispatch(selectAppointmentToDeleted(appointment));
  };

  const familyMember = useMemo(() => {
    if (
      !appointment?.patient?.familyMembers?.length ||
      !appointment?.bookedForMember
    )
      return null;

    return appointment.patient.familyMembers.find(
      (member) => member.id === appointment.bookedForMember?.id,
    );
  }, [appointment]);

  return (
    <Wrapper>
      <DateWrapper>
        <Date>
          <span>
            {formatDateTimeInDefaultTimezone(
              appointment.startDate,
              'MMMM DD | HH:mm',
              i18n.language,
            )}
          </span>
          {' - '}
          <span>
            {formatDateTimeInDefaultTimezone(appointment.endDate, 'HH:mm')}
          </span>
        </Date>

        <IconsWrapper>
          <StyledCalendarIcon
            onClick={() => {
              dispatch(setIsHighlightedAppointment(true));
              dispatch(setCurrentAppointment(appointment));
            }}
          />
          {isCanReschedule && (
            <StyledEditIcon onClick={handleEditAppointment} />
          )}
          {canUserCancel && (
            <StyledDeleteIcon onClick={handleDeleteAppointment} />
          )}
        </IconsWrapper>
      </DateWrapper>
      <Name>
        {appointment.professional?.firstName}{' '}
        {appointment.professional?.lastName}
      </Name>
      <Type>{appointment.appointmentType.title}</Type>
      {!!familyMember && (
        <MemberStyled key={familyMember.id}>
          <MembersIcon />
          <p>{`${t('for')} ${familyMember.relationship} ${
            familyMember.firstName
          } ${familyMember.lastName}`}</p>
        </MemberStyled>
      )}
    </Wrapper>
  );
};

export default PatientsAppointment;
