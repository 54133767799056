import React, { FC, useEffect } from 'react';
import { WatchStyled } from './styles';
import { secondsToTime } from 'common/helpers/dateTimeFormat';
import { useTimer } from 'common/hooks/useTimer';

const Watch: FC = () => {
  const { secondsPassed, startTimer } = useTimer();
  const time = secondsToTime(secondsPassed);
  useEffect(() => {
    startTimer();
  }, []);

  return (
    <WatchStyled className="timer">
      <h1>{time}</h1>
    </WatchStyled>
  );
};

export default Watch;
