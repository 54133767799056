import styled from 'styled-components';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: grid;
  gap: 24px;
`;

export const MessageText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.08px;
  color: ${({ theme }) => theme.color.error1};
`;

export const ButtonsWrapper = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;

    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const SelectSection = styled.div`
  width: 464px;

  > div {
    width: 464px;
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  > h1 {
    color: ${({ theme }) => theme.color.grey800};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.1px;
  }

  > div {
    display: flex;

    > button {
      height: 100%;
      display: flex;
      border: none;
      background: none;
      padding: 0;
      color: ${({ theme }) => theme.color.grey600};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22.4px;
      letter-spacing: 0.08px;
      cursor: pointer;

      &:first-child {
        margin-right: 16px;
      }

      > svg {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        cursor: pointer;

        g {
          path {
            fill: ${({ theme }) => theme.color.grey600};
          }
        }
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 225px;
`;

export const AddClinic = styled(AddIcon)`
  path {
    fill: ${({ theme }) => theme.color.grey700};
  }
`;
