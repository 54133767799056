import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ProfessionalPhotoDto,
  ProfessionalPhotoUpdateDto,
} from '@docbay/schemas';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';
import {
  fetchProfessionalById,
  resendProfessionalAuthorizationData,
  updateProfessional,
  updateProfessionalEmail,
} from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { PathNames } from 'applicaiton/routes';
import {
  Loader,
  RadioButton,
  TextArea,
  PhotoUploaderMultiCrop,
} from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useGenderOptions } from 'common/hooks/useGenderOptions';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { Photo } from 'common/types/clinics';
import { usePersonalInfoSchema } from 'features/feature-professional-edit/hooks/usePersonalInfoSchema';
import ButtonSection from '../ButtonSection';
import GeneralInfoSection from '../GeneralInfoSection';
import GeneralDropdownSection from '../GeneralDropdownSection';
import Social from '../Social';
import EmailChangedPopUp from '../EmailChangedPopUp';
import {
  Wrapper,
  RadioButtonSection,
  DividerStyled,
  FormStyled,
} from './styles';
import { GeneralProfileInfoForm } from 'features/feature-professional-edit/types/generalProfileInfo';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { isUserCanManageProfessional } from 'common/helpers/permissions';
import dayjs from 'dayjs';
import { ProfessionalUpdateWarningsModal } from 'common/components/ProfessionalUpdateWarningsModal';
import { useProfessionalEditWarnings } from '../../hooks/useProfessionalEditWarnings';

const GeneralProfileInfo: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const genders = useGenderOptions();
  const { photosToDelete } = useAppSelector((state) => state.photos);
  const { isLoading, profileChanges, editedProfessional } = useAppSelector(
    (state) => state.professionalProfile,
  );
  const [showEmailPopUp, setShowEmailPopUp] = useState(false);
  const [socialMediaToDelete, setSocialMediaToDelete] = useState<string[]>([]);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const isActive = !!editedProfessional?.active;
  const isCanManageProfessional = isUserCanManageProfessional();

  const personalInfoSchema = usePersonalInfoSchema();
  const { warnings, clearWarnings } = useProfessionalEditWarnings();

  const professional = useMemo(() => {
    const existingImages: Photo[] = [
      ...(editedProfessional?.mainPhoto ? [editedProfessional?.mainPhoto] : []),
      ...(editedProfessional?.photos || []).filter(
        (photo) => photo.key !== editedProfessional?.mainPhoto?.key,
      ),
    ];
    return {
      ...editedProfessional,
      ...profileChanges,
      photos: profileChanges?.photos?.length
        ? profileChanges.photos
        : existingImages,
    };
  }, [editedProfessional, profileChanges]);

  const isClinicOwnerProfessional = useMemo(() => {
    const isClinicOwnerProfessionalProfile =
      editedProfessional?.clinicsRelations?.some(
        (item) => item.clinic?.owner?.user?.id === editedProfessional?.userId,
      );
    return !!isClinicOwnerProfessionalProfile;
  }, [editedProfessional]);

  const {
    control,
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm<GeneralProfileInfoForm>({
    resolver: yupResolver(personalInfoSchema),
  });

  const formData = useWatch({ control });

  useEffect(() => {
    const socialMedia = editedProfessional?.socialMedia?.length
      ? editedProfessional?.socialMedia?.map((item) => ({
          ...item,
          professionalId: item.id,
        }))
      : [{ name: 'Facebook', url: '' }];
    const professionalSpokenLanguages =
      editedProfessional?.professionalSpokenLanguages?.map((item) => item.id) ||
      [];

    setValue('firstName', editedProfessional?.firstName!);
    setValue('lastName', editedProfessional?.lastName!);
    setValue('email', editedProfessional?.user?.email!);
    setValue('medicalNumber', editedProfessional?.medicalNumber!);
    setValue('description', editedProfessional?.description || '');
    setValue('dateOfBirth', editedProfessional?.dateOfBirth!);
    setValue('gender', editedProfessional?.gender!);
    setValue('spokenLanguageIds', professionalSpokenLanguages);
    setValue('socialMedia', socialMedia);
    // Will be use in future
    // setValue(
    //   'professionalActivityStartDate',
    //   editedProfessional?.professionalActivityStartDate!,
    // );
  }, [editedProfessional]);

  const handleSetChanges = (value: string | string[], key: string) => {
    setValue(key as keyof GeneralProfileInfoForm, value, {
      shouldValidate:
        !!Object.keys(errors).length &&
        !!errors[key as keyof GeneralProfileInfoForm],
    });
  };

  const handleSave = async () => {
    const { email, socialMedia, dateOfBirth, ...restFormValue } = getValues();
    const photos = profileChanges?.photos?.length
      ? { photos: profileChanges.photos as ProfessionalPhotoDto[] }
      : {};
    const socialMediaList = socialMedia?.map((item) => ({
      id: item.professionalId,
      name: item.name,
      url: item.url,
    }));
    const socialMediaWithURL = socialMediaList?.filter((item) => !!item.url);
    const socialToDelete =
      socialMediaList
        ?.filter((item) => !item.url && item.id)
        .map((item) => String(item.id)) || [];

    const payload = {
      ...profileChanges!,
      ...restFormValue,
      ...photos,
      ...(dateOfBirth
        ? { dateOfBirth: dayjs(dateOfBirth).utc(true).format() }
        : {}),
      ...(photosToDelete.length ? { photosToDelete } : []),
      ...(socialMediaWithURL?.length
        ? { socialMedia: socialMediaWithURL }
        : []),
      ...(socialMediaToDelete.length || socialToDelete.length
        ? { socialMediaToDelete: [...socialToDelete, ...socialMediaToDelete] }
        : []),
    };
    const isEmailChanged = email !== editedProfessional?.user?.email;

    if (isEmailChanged && !showEmailPopUp) {
      const response = await dispatch(
        updateProfessionalEmail({ id: String(editedProfessional?.id), email }),
      );
      if (response.meta.requestStatus === 'rejected') {
        setError('email', {
          message: t('personal_information.cannot_register_user_error') || '',
        });
        setIsEmailExist(true);
      }
      return setShowEmailPopUp(true);
    }
    const responseUpdateProfessional = await dispatch(
      updateProfessional({
        id: editedProfessional?.id! || '',
        professional: payload,
      }),
    );
    if (responseUpdateProfessional.meta.requestStatus === 'fulfilled') {
      await dispatch(fetchProfessionalById(String(editedProfessional?.id!)));
      dispatch(setHasUnsavedChanges(false));
    }
  };

  const handleChangePhotos = (photos: Photo[]) => {
    dispatch(
      professionalProfileActions.setProfileChanges({
        ...profileChanges,
        photos: photos as ProfessionalPhotoUpdateDto[],
      }),
    );
  };

  const handleCancel = () => {
    dispatch(professionalProfileActions.setProfileChanges(null));
    navigate(PathNames.professionals);
  };

  const handleResendLink = async () => {
    await handleSave();

    await dispatch(
      resendProfessionalAuthorizationData(String(editedProfessional?.id)),
    );
    setShowEmailPopUp(false);
  };

  const handleRemoveSocialMedia = useCallback(
    (id: string) => {
      setSocialMediaToDelete((prev) => [...prev, id]);
    },
    [socialMediaToDelete],
  );
  const handleCloseModal = () => {
    isEmailExist && setIsEmailExist(false);
    setShowEmailPopUp(false);
  };

  const isStateChanged = useMemo(() => {
    const { email, socialMedia, spokenLanguageIds, ...rest } = getValues();
    const mainPhoto = editedProfessional?.mainPhoto;
    const existedPhotos = [
      ...(mainPhoto ? [mainPhoto] : []),
      ...(editedProfessional?.photos || []).filter(
        (item) => item.id !== mainPhoto?.id,
      ),
    ];
    const editedProfessionalPhotos = existedPhotos.map((photo) => ({
      ...photo,
      isMain: mainPhoto?.id === photo.id,
    }));
    const socialMediaList = socialMedia?.map((item) => ({
      name: item.name,
      url: item.url,
      id: item.professionalId,
    }));
    const socialMediaWithURL = socialMediaList?.filter((item) => !!item.url);
    const socialToDelete =
      socialMediaList
        ?.filter((item) => !item.url && item.id)
        .map((item) => String(item.id)) || [];

    const photos = profileChanges?.photos?.length
      ? {
          photos: profileChanges.photos.map((photo, index) => ({
            ...photo,
            isMain: index === 0,
          })),
        }
      : { photos: editedProfessionalPhotos || [] };

    const currentChanges = {
      ...profileChanges!,
      ...rest,
      user: {
        email: email,
      },
      ...photos,
      professionalSpokenLanguages: spokenLanguageIds,
      ...(photosToDelete.length ? { photosToDelete } : []),
      ...(socialMediaWithURL?.length
        ? { socialMedia: socialMediaWithURL }
        : { socialMedia: editedProfessional?.socialMedia || [] }),
      ...(socialMediaToDelete.length || socialToDelete.length
        ? { socialMediaToDelete: [...socialToDelete, ...socialMediaToDelete] }
        : []),
    };

    const defaultData = {
      ...editedProfessional,
      user: {
        email: editedProfessional?.user?.email,
      },
      professionalSpokenLanguages:
        editedProfessional?.professionalSpokenLanguages?.map((item) => item.id),
      description: editedProfessional?.description || '',
      photos: editedProfessionalPhotos,
    };
    const professionalSpokenLanguagesChanged =
      spokenLanguageIds?.length !==
      editedProfessional?.professionalSpokenLanguages?.length;
    const hasChanges = compareObjectsChanges(currentChanges, defaultData);

    return hasChanges || professionalSpokenLanguagesChanged;
  }, [profileChanges?.photos, editedProfessional, formData]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  return (
    <>
      <Wrapper>
        {isLoading && <Loader />}
        {showEmailPopUp && (
          <EmailChangedPopUp
            professionalName={`${watch('firstName')} ${watch('lastName')}`}
            email={watch('email')}
            onClose={handleCloseModal}
            onSubmit={handleResendLink}
            isEmailExist={isEmailExist}
          />
        )}
        <FormStyled>
          <GeneralInfoSection
            register={register}
            errors={errors}
            isActive={isActive}
            disableEditing={!isCanManageProfessional}
            isClinicOwnerProfessional={isClinicOwnerProfessional}
          />
          <GeneralDropdownSection
            errors={errors}
            handleSetChanges={handleSetChanges}
            values={getValues()}
            disableEditing={!isCanManageProfessional}
          />
          <RadioButtonSection>
            <p>{t('personal_information.gender.title') || ''}</p>
            {genders.map((item) => (
              <RadioButton
                key={item.value}
                id={item.value}
                label={item.label}
                checked={getValues('gender') === item.value}
                onChange={(e) => {
                  if (!isCanManageProfessional) {
                    return;
                  }

                  handleSetChanges(e.target.id, 'gender');
                }}
              />
            ))}
          </RadioButtonSection>
          <TextArea
            id={'description'}
            label={t('personal_information.description') || ''}
            register={register}
            textLength={watch('description') || ''}
            maxTextLength={2000}
            errorMessage={errors.description?.message || ''}
            disabled={!isCanManageProfessional}
          />
          <PhotoUploaderMultiCrop
            currentPhotos={(professional?.photos || []) as Photo[]}
            setCurrentPhotos={handleChangePhotos}
            disableEditing={!isCanManageProfessional}
          />
          <Social
            errors={errors}
            control={control}
            register={register}
            getValues={getValues}
            handleRemoveSocialMedia={handleRemoveSocialMedia}
            disableEditing={!isCanManageProfessional}
          />
        </FormStyled>
        <div>
          <DividerStyled />
          <ButtonSection
            onSave={handleSubmit(handleSave)}
            onCancel={handleCancel}
            disabled={!isStateChanged || !!Object.keys(errors).length}
          />
        </div>
      </Wrapper>
      <ProfessionalUpdateWarningsModal
        isClinicOwnerProfile={false}
        isOpen={warnings.length > 0}
        warnings={warnings}
        onClose={clearWarnings}
        onButtonClick={clearWarnings}
      />
    </>
  );
};

export default GeneralProfileInfo;
