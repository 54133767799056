import { createSlice } from '@reduxjs/toolkit';
import { InsurancesState } from './models';
import { fetchInsurances } from './ActionCreators';

const initialState: InsurancesState = {
  insurances: [],
  total: 0,
  page: 0,
  totalPages: 0,
  isLoading: false,
  error: '',
};

const insurancesSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //insurances fetch
    builder.addCase(fetchInsurances.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchInsurances.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchInsurances.fulfilled, (state, action) => {
      state.insurances = action.payload.entities;
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.totalPages = action.payload.totalPages;
      state.isLoading = false;
    });
  },
});

export default insurancesSlice.reducer;
