import React, { FC } from 'react';
import { SecondaryButton } from 'common/components';
import { SuccessIcon, CloseIcon } from 'applicaiton/assets';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { SuccessModalProps } from './modules';

export const SuccessModal: FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  closeButtonText,
  message,
}) => {
  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccessIcon />
          <p>{message}</p>
          <SecondaryButton onClick={onClose}>{closeButtonText}</SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};
