import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { StyledTr } from './styles';
import { UserType } from 'features/feature-users-list/hooks/useUserTypeOptions';

const NoDataElement = ({ userType }: { userType: UserType | '' }) => {
  const { t } = useTranslation();

  const addUserLink =
    userType === UserType.Secretary
      ? PathNames.addSecretary
      : PathNames.addAdmin;

  return (
    <StyledTr>
      <td colSpan={5}>
        <div>
          <PickIcon />
          <p>
            {t('adminsListConfiguration.no_data_element.title')}
            <br />
            {t('adminsListConfiguration.no_data_element.subtitlePart1')}{' '}
            <Link to={addUserLink}>
              {t('adminsListConfiguration.no_data_element.subtitlePart2')}
            </Link>{' '}
            {t('adminsListConfiguration.no_data_element.subtitlePart3')}
          </p>
        </div>
      </td>
    </StyledTr>
  );
};
export default NoDataElement;
