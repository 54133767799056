import React from 'react';
import { userRoleExactMatch } from 'common/helpers/userRoleExactMatch';
import ProfessionalEditPage from '../ProfessionalEditPage';
import HomePage from '../HomePage';
import { UserRoles } from '../../constants/userRoles';
import { getUserRole } from '../../sessionStorage/auth';
import {
  AdminProfile,
  ClinicOwnerProfile,
  SideMenu,
  SecretaryProfile,
} from 'features';
import { Wrapper } from './styles';

const ProfilePage = () => {
  const userRole = getUserRole();
  const isAdmin = userRoleExactMatch(UserRoles.admin);

  if (userRole === UserRoles.professional) {
    return <ProfessionalEditPage />;
  }
  if (isAdmin) {
    return (
      <Wrapper>
        <SideMenu />
        <AdminProfile />
      </Wrapper>
    );
  }
  if (userRole === UserRoles.secretary) {
    return (
      <Wrapper>
        <SideMenu />
        <SecretaryProfile />
      </Wrapper>
    );
  }
  if (userRole?.includes(UserRoles.clinicOwner)) {
    return (
      <Wrapper>
        <SideMenu />
        <ClinicOwnerProfile />
      </Wrapper>
    );
  }
  return <HomePage />;
};

export default ProfilePage;
