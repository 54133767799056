import React, { FC } from 'react';
import { Wrapper } from '../ProfessionalEditPage/styles';
import { ProfessionalAdd, SideMenu, SubscriptionsLimitModal } from 'features';

const ProfessionalAddPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <ProfessionalAdd />
      <SubscriptionsLimitModal />
    </Wrapper>
  );
};

export default ProfessionalAddPage;
