import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/unsuccess-icon.svg';
import { ErrorEmailProps } from './modules';

const ErrorEmail: FC<ErrorEmailProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const newEmail = sessionStorage.getItem('newEmail');

  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <WarningIcon />
          <p>
            {t('clinic_owner.error_email.error_text1')} <br />
            {t('clinic_owner.error_email.error_text2')} <span>{newEmail}</span>{' '}
            {t('clinic_owner.error_email.error_text3')}
          </p>
          <div>{t('clinic_owner.error_email.other_email')}</div>
          <SecondaryButton onClick={onClose}>
            {t('clinic_owner.error_email.back_to_profile')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default ErrorEmail;
