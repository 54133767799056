import { useTranslation } from 'react-i18next';

export const useAppointmentPlacesOptions = () => {
  const { t } = useTranslation();

  return [
    { label: t('appointment_types.at_the_clinic'), value: 'Clinic' },
    {
      label: t('appointment_types.teleconsultation'),
      value: 'Teleconsultation',
    },
  ];
};
