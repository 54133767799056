export const getLanguageFromLocalStorage = () => {
  const language = localStorage.getItem('i18nextLng');
  if (language === 'en' || language?.startsWith('en-')) {
    return 'en';
  }
  if (language === 'pt' || language?.startsWith('pt-')) {
    return 'pt';
  }
  if (language === 'fr' || language?.startsWith('fr-')) {
    return 'fr';
  }
  return 'pt';
};
