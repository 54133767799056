import { requestNotificationPermissions } from 'common/helpers/notificationHelper';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { Client } from '@twilio/conversations';
import { firebaseConfig, firebaseVapidKey } from 'integration/firebase/config';
import { registerServiceWorker } from 'integration/firebase/serviceWorkerRegister';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { wait } from 'common/helpers/promiseHelper';

export const usePushNotifications = () => {
  const { t } = useTranslation();
  const defaultPayloadRef = useRef({
    title: '',
    body: '',
  });
  useEffect(() => {
    defaultPayloadRef.current = {
      title: t('chats.push_notifications.default_title'),
      body: t('chats.push_notifications.default_body'),
    };
  }, [t]);
  const initPushNotifications = async (chatClient: Client) => {
    const notificationPermissions = await requestNotificationPermissions();
    if (!notificationPermissions) {
      console.error('Notification permissions denied from user.');
      return;
    }
    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);
    const serviceWorkerRegistration = await registerServiceWorker(
      firebaseConfig,
    );
    if (messaging && chatClient) {
      await wait(100); // short delay is required to solve issue with no service worker registration
      // requesting permission to use push notifications
      getToken(messaging, {
        vapidKey: firebaseVapidKey,
        serviceWorkerRegistration: serviceWorkerRegistration,
      })
        .then((fcmToken) => {
          chatClient.setPushRegistrationId('fcm', fcmToken);
          onMessage(messaging, (messagePayload) => {
            handleFirebasePushNotification(messagePayload);
          });
        })
        .catch((err) => {
          console.error('can`t get token err: ', err);
        });
    } else {
      console.error('Firebase messaging or chat client not found.');
    }
  };

  const handleFirebasePushNotification = (message: any) => {
    const data = message?.data;
    if (!data) {
      return;
    }
    const author = data.author;
    let body = data['twi_body'];
    if (author || body) {
      const title = author ?? defaultPayloadRef?.current?.title;
      body = body ?? defaultPayloadRef?.current?.body;
      new Notification(title, {
        body,
      });
    }
  };

  const unregisterPushNotifications = async () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        if (registrations.length) {
          for (let registration of registrations) {
            registration.unregister();
          }
        }
      });
    }
  };

  return {
    initPushNotifications,
    unregisterPushNotifications,
  };
};
