import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminsActions } from 'applicaiton/constants/actions';
import { AdminsAPI } from 'integration/api/admins';
import { AxiosError } from 'axios';
import {
  AdminCreationDto,
  AdminSetPasswordDto,
  AdminUpdateDto,
  SupportedLanguageIso,
  UserChangeEmailDto,
  UserChangePhoneDto,
  UserRequestChangePhoneDto,
  UserVerifyCodeDto,
} from '@docbay/schemas';
import {
  getToken,
  setProfilesInSessionStorage,
  setUsersAuthSessionStorage,
} from 'applicaiton/sessionStorage/auth';
import {
  setIsCreatePassword,
  setIsVerificationCodeExpired,
} from '../Professionals/ProfessionalsSlice';
import { UsersAPI } from 'integration/api/users';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

export const createAdmin = createAsyncThunk(
  AdminsActions.createAdmin,
  async (params: AdminCreationDto, { rejectWithValue }) => {
    try {
      const { data } = await AdminsAPI.createAdmin(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getAdminById = createAsyncThunk(
  AdminsActions.getAdminById,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await AdminsAPI.getAdminById(id);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteAdmin = createAsyncThunk(
  AdminsActions.deleteAdmin,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await AdminsAPI.deleteAdmin(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const editAdmin = createAsyncThunk(
  AdminsActions.editAdmin,
  async (params: { id: string; data: AdminUpdateDto }, { rejectWithValue }) => {
    try {
      const { data } = await AdminsAPI.editAdmin(params.id, params.data);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const resendAdminAuthorizationData = createAsyncThunk(
  AdminsActions.resendAuthorizationData,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await AdminsAPI.resendAdminAuthorizationData(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const checkAdminVerificationCode = createAsyncThunk(
  AdminsActions.checkAdminVerificationCode,
  async (
    params: { email: string; code: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await AdminsAPI.checkAdminVerificationCode(params.email, params.code);
      dispatch(setIsVerificationCodeExpired(false));
    } catch (e) {
      dispatch(setIsVerificationCodeExpired(true));
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createAdminPassword = createAsyncThunk(
  AdminsActions.createAdminPassword,
  async (params: AdminSetPasswordDto, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await AdminsAPI.createAdminPassword(params);
      const professionalProfile = data;

      const { token, expiresAt, refreshToken, user, admin } =
        professionalProfile;

      setUsersAuthSessionStorage({ token, expiresAt, refreshToken, user });
      setProfilesInSessionStorage([
        {
          id: user?.id!,
          email: user?.email!,
          firstName: user?.firstName!,
          lastName: user?.lastName!,
          photo: user?.photo!,
          token,
          refreshToken,
        },
      ]);
      professionalProfile && dispatch(setIsCreatePassword(true));

      return admin;
    } catch (e) {
      dispatch(setIsCreatePassword(false));
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const adminVerifyOldEmail = createAsyncThunk(
  AdminsActions.adminVerifyOldEmail,
  async (params: { email: string }, { rejectWithValue }) => {
    try {
      const token = getToken();
      const language = getLanguageFromLocalStorage();
      const { data } = await UsersAPI.userVerifyOldEmail(
        { ...params, language },
        token,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const adminVerifyCode = createAsyncThunk(
  AdminsActions.adminVerifyCode,
  async (params: UserVerifyCodeDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersVerifyCode(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const adminSetNewEmail = createAsyncThunk(
  AdminsActions.adminSetNewEmail,
  async (params: UserChangeEmailDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const { data } = await UsersAPI.userSetNewEmail(params, token);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const adminVerifyPhone = createAsyncThunk(
  AdminsActions.adminVerifyPhone,
  async (params: UserRequestChangePhoneDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const language = getLanguageFromLocalStorage();

      const { data } = await UsersAPI.userPhoneVerificationCode(
        { ...params, language: language as SupportedLanguageIso },
        token,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const adminSetNewPhone = createAsyncThunk(
  AdminsActions.adminSetNewPhone,
  async (params: UserChangePhoneDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const { data } = await UsersAPI.userSetNewPhone(params, token);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
