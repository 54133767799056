import React, { FC } from 'react';
import { FooterStyled } from './styles';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAgreementsAndPoliciesModal } from 'features/feature-agreements-and-policies/hooks/useAgreementsAndPoliciesModal';

const Footer: FC = () => {
  const { t } = useTranslation();
  const { openTermsAndConditionsModal, openPrivacyPolicyModal } =
    useAgreementsAndPoliciesModal();

  return (
    <FooterStyled>
      <p>{t('clinicRegistration.footer.copyright')}</p>
      <div>
        <p className="link" onClick={openPrivacyPolicyModal}>
          {t('clinicRegistration.footer.privacy')}
        </p>
        <Divider orientation="vertical" />
        <p className="link" onClick={openTermsAndConditionsModal}>
          {t('clinicRegistration.footer.termsAndConditions')}
        </p>
      </div>
    </FooterStyled>
  );
};

export default Footer;
