import styled from 'styled-components';

export const SectionStyled = styled.section`
  display: flex;
  flex-direction: column;
  width: 50%;

  > p {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 16px;
  }

  > hr {
    margin: 28px 0;
  }

  > :nth-child(2) {
    margin-bottom: 24px;
  }

  > div {
    display: flex;
    width: 100%;

    > div {
      width: 50%;
      h2 {
        margin-bottom: 6px;
        color: ${({ theme }) => theme.color.grey600};
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.08px;
      }
      p {
        color: ${({ theme }) => theme.color.grey800};
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.09px;
      }
    }
  }
`;
