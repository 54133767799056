import styled from 'styled-components';
import { InputLabel } from '@mui/material';

interface ListItemProps {
  isMain: boolean;
}

interface ErrorProps {
  isError?: boolean;
}

export const StyledInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    position: unset;
    max-width: 100%;
    transform: none;
    margin-bottom: 6px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  > svg {
    margin-left: 8px;
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.color.error2};
    }
  }
`;

export const Text = styled.h2<ErrorProps>`
  color: ${({ theme, isError }) =>
    isError ? theme.color.error2 : theme.color.grey600};
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.08px;
`;

export const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    max-width: fit-content;
    position: relative;
    padding: 8px 20px;
  }
`;

export const ImageTypes = styled.div`
  color: ${({ theme }) => theme.color.grey500};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-left: 16px;
`;

export const ImageInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
`;

export const ImagesInfo = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.color.grey600};

  svg {
    margin-right: 8px;
  }
`;

export const UploadedImages = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
`;

export const ImagesList = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  row-gap: 20px;
`;

export const ImagesListItem = styled.div<ListItemProps>`
  width: 174px;
  height: 150px;
  position: relative;
  border-radius: 6px;
  border: ${({ theme, isMain }) =>
    isMain ? `2px solid ${theme.color.blue700}` : ''};
  overflow: hidden;
  margin-right: 20px;
`;

export const ImageItem = styled.img`
  width: 100%;
  height: available;
`;

export const MainImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  padding: 4px 10px;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.blue700};
`;

export const DeleteButton = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    color: ${({ theme }) => theme.color.white};
  }
`;

export const UploadingProgress = styled.div`
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
`;

export const UploadingText = styled.div<ErrorProps>`
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${({ theme, isError }) =>
    isError ? theme.color.error1 : theme.color.grey900};

  & p:last-child {
    font-weight: 400;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error1 : theme.color.grey700};
  }
`;

export const Loader = styled.div<ErrorProps>`
  display: flex;
  width: 44px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ theme, isError }) =>
    isError ? theme.color.error3 : theme.color.blue100};

  svg {
    animation: ${({ isError }) =>
      isError ? 'none' : 'rotation 3s infinite linear'};
    color: ${({ theme, isError }) =>
      isError ? theme.color.error1 : theme.color.grey700};

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
`;
