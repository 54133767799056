import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExpandMoreIcon } from 'applicaiton/assets';

import { useTimezonesWithOffsets } from 'common/hooks/useTimezonesWithOffsets';
import useOnClickOutside from 'common/hooks/useClickOutside';

import Input from '../Input';

import { Option, TimeZoneDropdownProps } from './models';
import { DropdownStyled, OptionItem, OptionsList, ListStyled } from './styles';

const TimeZoneDropdown: FC<TimeZoneDropdownProps> = ({
  id,
  label,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchItem, setSearchItem] = useState('');

  useOnClickOutside(ref, () => setIsActive(false));

  const { timezoneListSortByGMT } = useTimezonesWithOffsets();

  const timezonesList: Option[] = timezoneListSortByGMT;

  const selectedTimeZone =
    timezonesList.find((item) => item.value === value)?.label || '';

  const optionList = useMemo(() => {
    if (!searchItem) return timezonesList;
    return timezonesList.filter((item) =>
      item.label.toLocaleLowerCase().includes(searchItem.toLocaleLowerCase()),
    );
  }, [timezonesList, searchItem]);

  return (
    <DropdownStyled isActive={isActive} ref={ref}>
      {label && <label htmlFor={id}>{label}</label>}
      <section onMouseDown={() => setIsActive(!isActive)}>
        <div>
          {!selectedTimeZone?.length ? (
            <span>{t('select_timezone')}</span>
          ) : (
            <h2>{selectedTimeZone}</h2>
          )}
        </div>

        <ExpandMoreIcon />
      </section>
      {isActive ? (
        <OptionsList className="optionList">
          <Input
            id={'search'}
            type="search"
            value={searchItem}
            onChange={(e) => setSearchItem(e.target?.value || '')}
            placeholder={t('search') || ''}
            onClear={() => setSearchItem('')}
          />
          <ListStyled>
            {optionList.map((item) => (
              <OptionItem
                key={item.value}
                onClick={() => {
                  onChange(item.value);
                  setIsActive(false);
                }}
                selected={value === String(item.value)}
              >
                {item.label}
              </OptionItem>
            ))}
          </ListStyled>
        </OptionsList>
      ) : (
        ''
      )}
    </DropdownStyled>
  );
};

export default TimeZoneDropdown;
