import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${({ theme }) => theme.color.white};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  max-height: 802px;
  overflow: hidden;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px;
  max-height: 802px;
`;

export const DataSection = styled.div`
  display: grid;
  grid-template-columns: 360px 1fr;

  > div {
    border-right: 1px solid ${({ theme }) => theme.color.grey300};

    &:last-child {
      border-right: unset;
    }
  }

  h3 {
    font-family: Mulish;
    font-weight: 700;
    font-size: 18px;
    line-height: 25.2px;
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 32px;
  padding-left: 32px;
  gap: 24px;
  border-right: 1px solid ${({ theme }) => theme.color.grey300};
`;

export const Metrics = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  gap: 24px;
`;

export const MetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HistoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  max-height: 802px;
  overflow: auto;
`;

export const MainData = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const MainDataText = styled.div`
  h6 {
    font-family: Mulish;
    font-weight: 700;
    font-size: 16px;
    line-height: 22.4px;
  }
`;

export const DataItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h6 {
    font-family: Mulish;
    font-weight: 700;
    font-size: 16px;
    line-height: 22.4px;
  }
`;

export const SymptomList = styled.ul`
  display: grid;
  font-family: Mulish;
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
  color: ${({ theme }) => theme.color.grey700};
  list-style: disc;
  padding-left: 20px;
  gap: 4px;
`;

export const EmptyMetrics = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.color.grey600};
`;
