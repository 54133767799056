import styled from 'styled-components';
import { StyledProps } from './modules';

export const Wrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 84px;
  height: 84px;
  padding: 20px;

  > div:first-child {
    width: 140px;
  }

  @media screen and (max-width: 600px) {
    justify-content: center;
    .timer {
      display: none;
    }
    > div:first-child {
      width: unset;
    }
  }
`;

export const BoxCentral = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const IconStyled = styled.button<StyledProps>`
  all: unset;
  display: none;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color.blue800 : theme.color.error2};
  box-shadow: 1px 1px 2px 0px rgba(54, 54, 54, 0.1);
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.color.blue900 : theme.color.error1};
  }

  &.active {
    display: flex;
  }
  &.more {
    display: none;
  }

  @media screen and (max-width: 600px) {
    &.more {
      display: flex;
    }
  }
`;

export const BoxRight = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;

export const ButtonStyled = styled.button<StyledProps>`
  all: unset;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-right: 4px;
  cursor: pointer;

  span {
    position: absolute;
    width: 9px;
    height: 9px;
    top: 7px;
    right: 7px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.color.background};
    background-color: ${({ theme }) => theme.color.error2};
    z-index: 10;
  }

  svg {
    width: 22px;
    height: 22px;
    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.blue500 : theme.color.blue800};
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.color.blue500};
      }
    }
  }
`;
