import { useTranslation } from 'react-i18next';

export enum UserType {
  Admin = 'Admin',
  Secretary = 'Secretary',
}

export const useUserTypeOptions = () => {
  const { t } = useTranslation();

  return [
    {
      label: t('users.add_user_modal.admin'),
      value: UserType.Admin,
    },
    {
      label: t('users.add_user_modal.secretary'),
      value: UserType.Secretary,
    },
  ];
};
