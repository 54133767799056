import styled from 'styled-components';

interface OptionItemProps {
  selected: boolean;
  isMulti?: boolean;
  isHidden?: boolean;
}

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 500px;
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.color.grey500};
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      color: ${({ theme }) => theme.color.grey700};
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      margin: 12px 0 32px 0;
      > span {
        font-weight: 600;
      }
    }
  }
`;

export const ClinicsWrapper = styled.div`
  margin: 20px 0;

  > p {
    font-weight: 600 !important;
    font-size: 20px !important;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;

  button {
    width: 150px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const OptionCheckBox = styled.label<OptionItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  cursor: ${({ isHidden }) => (isHidden ? 'default' : 'pointer')};
  background: ${({ theme, selected }) =>
    selected ? theme.color.blue100 : 'transparent'};

  opacity: ${({ isHidden }) => (isHidden ? 0.6 : 1)};
  pointer-events: ${({ isHidden }) => (isHidden ? 'none' : 'all')};

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }

  > div:first-child {
    display: flex;
    align-items: center;
  }

  > p {
    color: ${({ theme }) => theme.color.grey800};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }

  > div:first-child {
    margin-right: 12px;
    label {
      padding: 0;
    }
  }
  > div:last-child {
    display: ${({ isMulti }) => (!isMulti ? 'none' : 'flex')};
  }
`;
