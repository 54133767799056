import React, { FC } from 'react';
import { Wrapper } from './styles';
import PatientInfo from '../PatientInfo';
import DoctorInfo from '../DoctorInfo';

const ProfileInfo: FC = () => {
  return (
    <Wrapper>
      <PatientInfo />
      <DoctorInfo />
    </Wrapper>
  );
};

export default ProfileInfo;
