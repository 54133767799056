import React, { FC, useMemo, useRef, useState } from 'react';
import data, { EmojiMartData } from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { ReactComponent as EmojiIcon } from 'applicaiton/assets/emoji.svg';

import { EmojiData, EmojiPickersProps } from './models';
import { EmojiButtonStyled, EmojiContainer, EmojiList } from './styles';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { useLanguage } from 'common/hooks/useLanguage';

const EmojiPickers: FC<EmojiPickersProps> = ({ onSelect, isDisabled }) => {
  const { language } = useLanguage();
  const ref = useRef(null);
  const [isOpened, setIsOpened] = useState(false);

  const emojis = useMemo(() => {
    const emojisData = data as EmojiMartData;
    const filteredCategories = emojisData.categories.map((item) => {
      if (item.id === 'people') {
        const filteredEmojis = item.emojis.filter(
          (emoji) => emoji !== 'relaxed',
        );

        return {
          ...item,
          emojis: filteredEmojis,
        };
      }
      return item;
    });

    return { ...emojisData, categories: filteredCategories };
  }, [data]);

  useOnClickOutside(ref, () => setIsOpened(false));

  const handleSelectEmoji = (data: EmojiData) => {
    onSelect(data.native);
  };

  return (
    <EmojiContainer ref={ref}>
      <EmojiButtonStyled
        type="button"
        onClick={() => {
          !isDisabled && setIsOpened((prev) => !prev);
        }}
      >
        <EmojiIcon />
      </EmojiButtonStyled>
      <EmojiList>
        {isOpened && (
          <Picker
            data={emojis}
            onEmojiSelect={handleSelectEmoji}
            locale={language}
            previewPosition={'none'}
          />
        )}
      </EmojiList>
    </EmojiContainer>
  );
};

export default EmojiPickers;
