import styled from 'styled-components';

export const Wrapper = styled.article`
  width: 100%;
`;

export const FieldsSection = styled.div`
  width: 464px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;
