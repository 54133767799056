import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import {
  ClinicImage,
  DropdownStyled,
  OptionCheckBox,
  OptionsList,
  SpecializationsList,
} from './styles';
import Checkbox from '../Checkbox';
import { DropdownProps, Option } from './models';
import Input from '../Input';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

const DropdownProfessionals: FC<DropdownProps> = ({
  id,
  searchPlaceholder,
  value,
  onChange,
  disabled,
  options,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchItem, setSearchItem] = useState('');
  const language = getLanguageFromLocalStorage();
  const localizeKey = `name_${language.toUpperCase()}` as 'name_EN' | 'name_PT';

  useOnClickOutside(ref, () => setIsActive(false));

  const optionList = useMemo(() => {
    if (!searchItem) return options;
    return options.filter((item) =>
      item.label.toLocaleLowerCase().includes(searchItem.toLocaleLowerCase()),
    );
  }, [options, searchItem]);

  const handleChange = (selectedItem: Option) => {
    const valueAlreadyAdded = value.some(
      (item) => item.value === selectedItem.value,
    );
    if (valueAlreadyAdded) {
      const filteredValue = value.filter(
        (item) => item.value !== selectedItem.value,
      );
      onChange(filteredValue);
    } else {
      onChange([...value, selectedItem]);
    }
    return;
  };

  const IsValueChecked = (item: Option) => {
    //checking if checkbox checked
    const isValueArray = Array.isArray(value);
    return isValueArray && value.some((i) => i.value === item.value);
  };

  return (
    <DropdownStyled
      id={id}
      isActive={isActive}
      ref={ref}
      onMouseDown={() => setIsActive(true)}
    >
      <section>
        {!value.length ? (
          <span>{t('professionals_dropdown_placeholder')}</span>
        ) : (
          <h2>{`${value.length} ${t('selected')}`}</h2>
        )}
        <ExpandMoreIcon />
      </section>
      {isActive && (
        <OptionsList>
          {isActive ? (
            <div>
              <Input
                id={'search'}
                type="search"
                value={searchItem}
                onChange={(e) => setSearchItem(e.target?.value || '')}
                placeholder={searchPlaceholder}
                onClear={() => setSearchItem('')}
              />
              {optionList.map((item) => (
                <OptionCheckBox
                  htmlFor={String(item.value)}
                  key={item.value}
                  selected={IsValueChecked(item!)}
                >
                  <div>
                    <ClinicImage>
                      {item.image ? (
                        <img src={item.image} alt={item.label} />
                      ) : (
                        <PersonIcon />
                      )}
                    </ClinicImage>
                    <div>
                      <p>{item.label}</p>
                      <SpecializationsList>
                        {item.specializations?.map((specialization, index) => {
                          return (
                            <p key={specialization.id}>
                              {specialization[localizeKey]}
                              {index + 1 !== item?.specializations?.length &&
                                ','}
                            </p>
                          );
                        })}
                      </SpecializationsList>
                    </div>
                  </div>
                  <Checkbox
                    id={String(item.value)}
                    checked={IsValueChecked(item)}
                    onChange={() => handleChange(item)}
                    disabled={disabled && IsValueChecked(item!)}
                  />
                </OptionCheckBox>
              ))}
            </div>
          ) : (
            ''
          )}
        </OptionsList>
      )}
    </DropdownStyled>
  );
};

export default DropdownProfessionals;
