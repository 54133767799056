import styled from 'styled-components';

export const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;

  h6 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.grey800};
  }

  button {
    display: flex;
    align-items: center;
    width: max-content;

    svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  height: 144px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.grey900};
  border-top: 1px solid ${({ theme }) => theme.color.grey200};

  span {
    cursor: pointer;
    font-weight: 700;
    color: ${({ theme }) => theme.color.blue800};
  }
`;

export const TableStyled = styled.table`
  width: 100%;
  border-radius: 12px;
  border-collapse: separate;
  border-radius: 12px;
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
  border: 1px solid ${({ theme }) => theme.color.grey200};

  > thead {
    tr {
      th {
        vertical-align: middle;
        text-align: start;
        padding: 12px 24px;
        color: ${({ theme }) => theme.color.grey600};
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.07px;
        background: ${({ theme }) => theme.color.grey50};
        border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

        &:first-of-type {
          width: 200px;
        }
        &:last-of-type {
          width: 150px;
        }
      }
    }
  }
  > tbody {
    > tr {
      &:last-child {
        > td {
          &:first-child {
            border-bottom-left-radius: 12px;
          }
          &:last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
`;
