import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export interface AssigningT {
  clinicId: string;
  specializationId: string;
  professionalIds: string[];
  waitingListNotificationInterval: number;
}

export const useAssigningForm = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const assigningFormValues = {
    clinicId: '',
    specializationId: '',
    professionalIds: [],
    waitingListNotificationInterval: 30,
  };

  const appointmentGeneralSchema = yup
    .object({
      clinicId: yup.string().min(1, requiredError!).required(),
      specializationId: yup.string().min(1, requiredError!).required(),
      professionalIds: yup.array(),
      waitingListNotificationInterval: yup.number(),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<AssigningT>({
    resolver: yupResolver(appointmentGeneralSchema),
    defaultValues: assigningFormValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
  };
};
