import styled from 'styled-components';

export const HeaderStyled = styled.header`
  color: ${({ theme }) => theme.color.grey900};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: AcidGrotesk-Bold;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.28px;
  text-transform: capitalize;
`;
