import { useTranslation } from 'react-i18next';
import { PathNames } from 'applicaiton/routes/constants';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import {
  SubscriptionsIcon,
  CommentIcon,
  SettingsDarkIcon,
  PollIcon,
  ListAltIcon,
} from 'applicaiton/assets/index';

export const useSubscriptionsMenu = () => {
  const { t } = useTranslation();
  const userRole = getUserRole();
  const isClinicOwner = userRole?.includes(UserRoles.clinicOwner);

  const list = [
    {
      path: PathNames.mySubscribers,
      name: t('clinicsConfiguration.aside_menu.my_subscribers'),
      image: SubscriptionsIcon,
    },
    {
      path: PathNames.topicsOfChats,
      name: t('clinicsConfiguration.aside_menu.topics_of_chats'),
      image: CommentIcon,
    },
    {
      path: PathNames.subscriptionSettings,
      name: t('clinicsConfiguration.aside_menu.subscription_settings'),
      image: SettingsDarkIcon,
    },
    {
      path: PathNames.subscriptionsStatistics,
      name: t('clinicsConfiguration.aside_menu.subscriptions_statistics'),
      image: PollIcon,
    },
    ...(isClinicOwner
      ? [
          {
            path: PathNames.clinicOwnerProfessionalSubscriptions,
            name: t('professional_profile.subscriptions.subscription_title'),
            image: ListAltIcon,
          },
        ]
      : []),
  ];

  return list;
};
