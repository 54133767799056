import styled from 'styled-components';

export const MenuItemStyled = styled.div`
  button {
    text-decoration: none;
    width: 100%;
    background: transparent;
    border-radius: 4px;
    border: none;
    text-align: start;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.color.white};
  }

  .active {
    .image-wrapper {
      border-radius: 4px;
      background: ${({ theme }) => theme.color.blue800};

      svg {
        path {
          fill: ${({ theme }) => theme.color.menu};
        }
      }
      &.stroke-icon {
        path {
          fill: none;
          stroke: ${({ theme }) => theme.color.menu};
        }
      }
      &.doc-box-icon {
        svg {
          path {
            fill: none;
            stroke: ${({ theme }) => theme.color.menu};
          }
          circle {
            stroke: ${({ theme }) => theme.color.menu};
          }
        }
      }
    }
  }
`;
