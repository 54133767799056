import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  > button {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    cursor: pointer;

    svg {
      path {
        stroke: ${({ theme }) => theme.color.grey700};
      }
    }
  }
`;

export const ActionsListStyled = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  right: 0;
  z-index: 5;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);

  button,
  a {
    all: unset;
    display: flex;
    align-items: center;
    width: inherit;
    padding: 4px 6px;
    color: ${({ theme }) => theme.color.grey800};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-bottom: 4px;
    border-radius: 4px;
    cursor: pointer;

    svg {
      margin-right: 10px;
      path {
        fill: ${({ theme }) => theme.color.grey600};
      }
    }

    &:hover {
      background: ${({ theme }) => theme.color.grey50};
    }

    button:last-child {
      margin-bottom: 0px;
    }
  }
`;
