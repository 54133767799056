import React, { FC, ReactNode } from 'react';
import Header from 'features/feature-clinic-registration/components/Header';
import Footer from 'features/feature-clinic-registration/components/Footer';
import { MainStyled } from './style';

interface LayoutProps {
  children: ReactNode;
  title: string;
  subtitle?: ReactNode;
  description: string;
}

const Layout: FC<LayoutProps> = ({
  children,
  title,
  subtitle,
  description,
}) => {
  return (
    <MainStyled>
      <Header title={title} subtitle={subtitle} description={description} />
      {children}
      <Footer />
    </MainStyled>
  );
};

export default Layout;
