import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from 'common/components';
import { SearchInput } from './styles';
import { fetchPatientsAndFamilyMembers } from 'applicaiton/store/reducers/Patients/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { resetPatientAppointmentsData } from 'applicaiton/store/reducers/Appointments/PatientAppoinmentsSlice';
import PatientSearchInfo from '../PatientSearchInfo';
import PatientSearchList from '../PatientSearchList';
import {
  setClearPatientSearch,
  setPatientsFamilyMembersSearch,
  setCurrentSearchItem,
} from 'applicaiton/store/reducers/Patients/PatientsSlice';

const PatientSearch = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    patientsFamilyMembersSearch,
    clearPatientSearch,
    currentFamilyMemberSearch,
  } = useAppSelector((state) => state.patients);

  const [patientsInputValue, setPatientsInputValue] = useState('');

  const handlePatientsSearch = (value: string) => {
    setPatientsInputValue(value);

    if (value.length < 3) {
      dispatch(setPatientsFamilyMembersSearch(null));
      dispatch(setCurrentSearchItem(null));
      return;
    }

    dispatch(resetPatientAppointmentsData());
    dispatch(fetchPatientsAndFamilyMembers({ search: value }));
  };

  const clearPatients = () => {
    setPatientsInputValue('');
    dispatch(setPatientsFamilyMembersSearch(null));
    dispatch(setCurrentSearchItem(null));
    dispatch(resetPatientAppointmentsData());
  };

  useEffect(() => {
    if (clearPatientSearch) {
      clearPatients();
      dispatch(setClearPatientSearch(false));
    }
  }, [clearPatientSearch]);

  return (
    <SearchInput>
      <Input
        id={'search'}
        type="search"
        value={patientsInputValue}
        onClear={clearPatients}
        onChange={(e) => handlePatientsSearch(e.target.value)}
        placeholder={t('scheduler.search_patient_placeholder') || ''}
      />
      {!!currentFamilyMemberSearch && <PatientSearchInfo />}
      {patientsFamilyMembersSearch && <PatientSearchList />}
    </SearchInput>
  );
};

export default PatientSearch;
