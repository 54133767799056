export const getMonthDurationOptions = [
  { label: '0', value: String(0) },
  { label: '1', value: String(1) },
  { label: '2', value: String(2) },
  { label: '3', value: String(3) },
  { label: '4', value: String(4) },
  { label: '5', value: String(5) },
  { label: '6', value: String(6) },
  { label: '7', value: String(7) },
  { label: '8', value: String(8) },
  { label: '9', value: String(9) },
  { label: '10', value: String(10) },
  { label: '11', value: String(11) },
  { label: '12', value: String(12) },
];
