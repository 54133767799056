import React, { FC, useEffect } from 'react';
import { Wrapper, AddNewType } from './styles';
import { Header, LayoutComponent } from './components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'common/hooks/redux';
import { setAdminData } from 'applicaiton/store/reducers/Admins/AdminsSlice';

const UserAdd: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setAdminData(null));
    };
  }, []);

  return (
    <Wrapper>
      <Header />
      <AddNewType>{t('users.adding_new_admin')}</AddNewType>
      <LayoutComponent />
    </Wrapper>
  );
};

export default UserAdd;
