import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { NewChatModalProps } from './module';
import {
  ModalContentStyled,
  ModalHeaderStyled,
  ModalStyled,
  ModalActionsStyled,
} from './styles';
import { CloseIcon } from 'applicaiton/assets';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { SubscriberSearch } from 'features/feature-subscriber-search';

export const NewChatModal = ({
  isOpen,
  onClose,
  onCreateChat,
}: NewChatModalProps) => {
  const { t } = useTranslation();
  const [patientId, setPatientId] = useState<string | null>(null); //todo remove after tests.
  const handleCreateChat = () => {
    if (patientId) {
      onCreateChat(patientId);
    }
    onClose();
  };
  const handleSubscriberSelected = (subscriberId: string | null) => {
    setPatientId(subscriberId);
  };
  return (
    <Modal open={isOpen}>
      <ModalStyled>
        <ModalHeaderStyled>
          <p className={'title'}>{t('chats.new_chat_modal.new_chat')}</p>
          <CloseIcon onClick={onClose} />
        </ModalHeaderStyled>
        <ModalContentStyled>
          <SubscriberSearch
            label={''}
            onSubscriberSelected={handleSubscriberSelected}
            placeholder={t('chats.new_chat_modal.search_placeholder')}
          />
        </ModalContentStyled>
        <ModalActionsStyled>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton onClick={handleCreateChat} disabled={!patientId}>
            {t('ok')}
          </PrimaryButton>
        </ModalActionsStyled>
      </ModalStyled>
    </Modal>
  );
};
