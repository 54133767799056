import styled from 'styled-components';
import { StyledProps } from './modules';

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    display: grid;
    grid-auto-rows: max-content;
    gap: 18px;
    padding: 32px;
  }
`;

export const Row = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '1fr 1fr')};
  gap: 12px;
`;

export const RadioButtonSection = styled.section`
  display: grid;
  width: 100%;
  gap: 12px;

  > p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.error2};
  }

  > div {
    display: flex;
    align-items: center;

    div:last-child {
      margin-left: 16px;
    }
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};

  button {
    width: 150px;
    &:first-child {
      margin-right: 20px;
    }
  }
`;
