import React, { FC } from 'react';

import { FormSubTitle, FormTitle, HeaderWrapper } from './styles';
import { HeaderProps } from 'features/feature-forgot-password/types';

const Header: FC<HeaderProps> = ({ title, subtitle }) => {
  return (
    <HeaderWrapper>
      <FormTitle>{title}</FormTitle>
      {subtitle && <FormSubTitle>{subtitle}</FormSubTitle>}
    </HeaderWrapper>
  );
};

export default Header;
