import React, { FC, useEffect, useMemo, useState } from 'react';
import { RegisterStepper } from './components';
import { AsideStyled, BackLink, Steps } from './styles';
import { ReactComponent as Logo } from './assets/docbuy-logo.svg';
import { DocBayPatientLandingLink } from '../../applicaiton';
import { ReactComponent as BackArrow } from 'applicaiton/assets/back-arrow.svg';
import { ReactComponent as LanguageIcon } from 'applicaiton/assets/language.svg';
import {
  DividerStyled,
  LanguageSwitcherStyled,
  LanguageWrapper,
} from '../feature-auth-navbar/components/LanguageSwitcher/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { useLanguage } from 'common/hooks/useLanguage';
import { SupportedLanguage } from 'common/types/supportedLanguage';

interface SideBarProsp {
  activeStep: number;
}

const SideBar: FC<SideBarProsp> = ({ activeStep }) => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState<string>();
  const { language, switchLanguage } = useLanguage();

  useEffect(() => {
    setCurrentLang(language);
  }, []);

  const changeLanguage = (lang: SupportedLanguage) => {
    setCurrentLang(lang);
    switchLanguage(lang);
  };

  const linkToPatientAppProfile = useMemo(() => {
    const language = getLanguageFromLocalStorage();

    return `${DocBayPatientLandingLink}${language}/`;
  }, [i18n.language]);

  return (
    <AsideStyled>
      <BackLink
        onClick={() => {
          sessionStorage.clear();
        }}
        to={linkToPatientAppProfile}
      >
        <BackArrow />
        {t('back_to_landing')}
      </BackLink>
      <Steps>
        <Link
          onClick={() => {
            sessionStorage.clear();
          }}
          to={linkToPatientAppProfile}
        >
          <Logo />
        </Link>
        <RegisterStepper activeStep={activeStep} />
      </Steps>
      <LanguageWrapper>
        <LanguageIcon />
        <LanguageSwitcherStyled
          key={'en'}
          onClick={() => changeLanguage('en')}
          selected={currentLang === 'en'}
        >
          EN
        </LanguageSwitcherStyled>
        <DividerStyled />
        <LanguageSwitcherStyled
          key={'pt'}
          onClick={() => changeLanguage('pt')}
          selected={currentLang === 'pt'}
        >
          PT
        </LanguageSwitcherStyled>
        <DividerStyled />
        <LanguageSwitcherStyled
          key={'fr'}
          onClick={() => changeLanguage('fr')}
          selected={currentLang === 'fr'}
        >
          FR
        </LanguageSwitcherStyled>
      </LanguageWrapper>
    </AsideStyled>
  );
};

export default SideBar;
