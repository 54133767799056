import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccessIcon } from 'applicaiton/assets/success-icon.svg';
import { SecondaryButton } from 'common/components';
import { SuccessRegistrationModalProps } from './modules';
import { StyledModal } from './styles';
import { useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { clearClinicOwnerSessionStorage } from 'applicaiton/sessionStorage/clinicRegistration';
import { clearClinicOwnerSuccessSignUp } from 'applicaiton/store/reducers/ClinicRegistration/ClinicRegistrationSlice';
import { useAppDispatch } from 'common/hooks/redux';

const SuccessRegistrationModal: FC<SuccessRegistrationModalProps> = ({
  opened,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    clearClinicOwnerSessionStorage();
    dispatch(clearClinicOwnerSuccessSignUp());
    navigate(PathNames.login);
  };

  return (
    <Modal open={!!opened}>
      <StyledModal>
        <CloseIcon onClick={handleSubmit} />
        <div>
          <SuccessIcon />
          <p>
            {t('clinicRegistration.photoshoot.success_registering_message')}
          </p>
          <SecondaryButton onClick={handleSubmit}>
            {t('log_in')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessRegistrationModal;
