import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfusingAgenda } from './models';
import { fetchConfusingAgenda } from './ActionCreators';
import { ConfusingAgendaResponseDto } from '@docbay/schemas/dist/agenda/dto/responses/confusing-agenda-response.dto';
import { AbsenceResponseDto } from '@docbay/schemas';

const initialState: ConfusingAgenda = {
  confusingAgendas: null,
  createdAbsence: null,
  isLoading: false,
  error: '',
  showConfusingAgendaErrorModal: false,
};

const confusingAgendasSlice = createSlice({
  name: 'confusingAgendas',
  initialState,
  reducers: {
    setConfused: (
      state,
      actions: PayloadAction<ConfusingAgendaResponseDto | null>,
    ) => {
      state.confusingAgendas = actions.payload;
    },
    setCreatedAbsence: (
      state,
      actions: PayloadAction<AbsenceResponseDto | null>,
    ) => {
      state.createdAbsence = actions.payload!;
    },
    setShowConfusingAgendaErrorModal: (
      state,
      actions: PayloadAction<boolean>,
    ) => {
      state.showConfusingAgendaErrorModal = actions.payload!;
    },
  },
  extraReducers: (builder) => {
    //confusing agenda fetch
    builder.addCase(fetchConfusingAgenda.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchConfusingAgenda.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchConfusingAgenda.fulfilled, (state, action) => {
      if (action.meta.arg.isOnSave && state.confusingAgendas?.appointments) {
        state.showConfusingAgendaErrorModal =
          action.payload.appointments.length >
          state.confusingAgendas?.appointments?.length;

        state.confusingAgendas = action.payload;
      } else {
        setShowConfusingAgendaErrorModal(false);
        state.confusingAgendas = action.payload;
      }
      state.isLoading = false;
    });
  },
});
export const {
  setCreatedAbsence,
  setShowConfusingAgendaErrorModal,
  setConfused,
} = confusingAgendasSlice.actions;

export default confusingAgendasSlice.reducer;
