import { Box, styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';
import { IconButton } from '@mui/material';

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 12px;
`;

export const Title = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  h1 {
    color: ${commonColors.grey900};
    font-size: 28px;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.28px;
    text-transform: capitalize;
    margin-right: 8px;
  }

  svg {
    path {
      fill: ${commonColors.grey600};
    }
  }
`;

export const IconButtonStyled = styled(IconButton)`
  padding: 0;
`;
