import styled from 'styled-components';

export const StyledDivider = styled.div`
  height: 1px;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.color.grey800};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;

  &:last-child {
    margin-bottom: 0;
  }

  > svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;

    path {
      fill: ${({ theme }) => theme.color.grey600};
    }
  }
`;
