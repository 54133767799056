import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ItemsList, ItemStyled, Wrapper } from './styles';
import { Gender } from '../../../feature-patient-profile/constants';
import dayjs from 'dayjs';
import {
  PersonsIcon,
  PhoneIcon,
  GroupIcon,
  BadgeIcon,
  CalendarIcon,
} from 'applicaiton/assets/index';
import { useAppSelector } from 'common/hooks/redux';

const PatientInfo = () => {
  const { t, i18n } = useTranslation();
  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const pronunciation = useMemo(() => {
    const isMale = currentAppointment?.patient?.pronunciation === Gender.Male;
    return isMale ? t('mr') : t('mrs');
  }, [currentAppointment, i18n.language]);

  const fullName = useMemo(() => {
    const name = [
      currentAppointment?.patient?.firstName,
      currentAppointment?.patient?.lastName,
    ]
      .filter((item) => !!item)
      .join(' ');
    return name;
  }, [currentAppointment]);

  const dateOfBirth = useMemo(() => {
    if (!currentAppointment?.patient?.dateOfBirth) return '';
    const formattedDate = dayjs(
      currentAppointment?.patient?.dateOfBirth,
    ).format('DD/MM/YYYY');
    const yearsOld =
      dayjs().get('years') -
      dayjs(currentAppointment?.patient?.dateOfBirth).get('years');

    return `${formattedDate} (${yearsOld} ${t('years')})`;
  }, [currentAppointment, i18n.language]);

  return (
    <Wrapper>
      <h2>{t('consultation_data.patient_data')}</h2>
      <ItemsList>
        {pronunciation && (
          <ItemStyled>
            <PersonsIcon />
            <span>{t('pronunciation')}</span>
            <p>{pronunciation}</p>
          </ItemStyled>
        )}
        {fullName && (
          <ItemStyled>
            <BadgeIcon />
            <p>
              <span>{t('name')}</span>
              {fullName}
            </p>
          </ItemStyled>
        )}
        {currentAppointment?.patient?.phone && (
          <ItemStyled>
            <PhoneIcon />
            <p>
              <span>{t('phone')}</span>
              {currentAppointment?.patient?.phone}
            </p>
          </ItemStyled>
        )}
        {dateOfBirth && (
          <ItemStyled>
            <CalendarIcon />
            <span>{t('date_of_birth')}</span>
            <p>{dateOfBirth}</p>
          </ItemStyled>
        )}
        {currentAppointment?.patient?.nif && (
          <ItemStyled>
            <GroupIcon />
            <p>
              {' '}
              <span>{t('my_patients.fiscal_identification_number')}</span>
              {currentAppointment?.patient?.nif}
            </p>
          </ItemStyled>
        )}
      </ItemsList>
    </Wrapper>
  );
};

export default PatientInfo;
