import React, { FC, useEffect, useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import {
  StyledModal,
  TitleWrapper,
  DataSection,
  ButtonWrapper,
} from './styles';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import {
  InfinityScrollDropdown,
  Input,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import { Props } from './modules';
import { useTranslation } from 'react-i18next';
import { Option } from 'common/components/Dropdown/models';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  getDiseases,
  setPatientFamilyHistoricData,
  updatePatientFamilyHistoricData,
} from 'applicaiton/store/reducers/Consultation/ActionCreators';
import { useForm } from 'react-hook-form';
import { GeneralInfoForm } from './modules';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import {
  CommonEntitiesListResponse,
  DiseaseResponseDto,
} from '@docbay/schemas';
import { setDiseases } from 'applicaiton/store/reducers/Consultation/ConsultationSlice';
import { useLanguage } from 'common/hooks/useLanguage';

const AddFamilyHistoricModal: FC<Props> = ({ onClose, onSave, history }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchItem, setSearchItem] = useState('');
  const [currentSearchText, setCurrentSearchText] = useState('');
  const { t } = useTranslation();
  const { language } = useLanguage();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();
  const isEdit = !!history;

  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const { diseases, diseasesTotalPages } = useAppSelector(
    (state) => state.consultationData,
  );

  const addSchema = yup.object({
    familyMember: yup.string().required(t('errors.required')!),
    diseaseId: yup.string().required(t('errors.required')!),
    comments: yup.string(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm<GeneralInfoForm>({
    resolver: yupResolver(addSchema),
  });

  useEffect(() => {
    if (isEdit) {
      setValue('familyMember', history?.familyMember);
      setValue('diseaseId', history?.disease.id);
      history?.comments && setValue('comments', history?.comments);
      setCurrentSearchText(history?.disease[localizeNameKey]);
    }
  }, [history]);

  const optionsArr = useMemo(() => {
    const options = diseases.map((item) => {
      const optionName = item[localizeNameKey];

      return {
        value: item.id,
        label: optionName as string,
      };
    });
    return options;
  }, [diseases]);

  const getDiseasesData = () => {
    dispatch(
      getDiseases({
        page: currentPage,
        limit: 10,
        languageIso: language,
        ...(searchItem ? { search: searchItem } : {}),
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        const dataArr =
          data.payload as CommonEntitiesListResponse<DiseaseResponseDto>;

        dispatch(
          setDiseases(
            currentPage === 1
              ? dataArr?.entities!
              : [...diseases, ...dataArr.entities!],
          ),
        );

        if (!dataArr?.entities.length) {
          setHasNextPage(!dataArr?.entities.length);
        }
      }
    });
  };

  useEffect(() => {
    if (currentPage <= diseasesTotalPages) {
      getDiseasesData();
    }
  }, [currentPage, searchItem]);

  const handleGetNewData = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSearchData = (search: string) => {
    setSearchItem(search);
    setHasNextPage(true);
    setCurrentPage(1);
  };

  const handleSave = () => {
    const { familyMember, comments, diseaseId } = getValues();

    if (isEdit) {
      dispatch(
        updatePatientFamilyHistoricData({
          patientId: currentAppointment?.patient?.id!,
          familyHistoryId: history?.id,
          data: {
            familyMember,
            comments,
            diseaseId,
          },
        }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          onSave();
        }
      });
    } else {
      dispatch(
        setPatientFamilyHistoricData({
          patientId: currentAppointment?.patient?.id!,
          data: {
            familyMember,
            comments,
            diseaseId,
          },
        }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          onSave();
        }
      });
    }
  };

  const headerText = isEdit
    ? t('consultation_data.edit_family_historic_header')
    : t('consultation_data.add_family_historic_header');

  return (
    <Modal open={true}>
      <StyledModal>
        <TitleWrapper>
          <h2>{headerText}</h2>
          <CloseIcon onClick={onClose} />
        </TitleWrapper>
        <DataSection>
          <Input
            id={'familyMember'}
            type={'text'}
            label={t('consultation_data.add_family_historic_label') || ''}
            register={register}
            placeholder={
              t('consultation_data.add_family_historic_placeholder') || ''
            }
            errorMessage={errors?.familyMember?.message}
          />
          <InfinityScrollDropdown
            id={'diseaseId'}
            placeholder={
              t('consultation_data.add_family_historic_disease_placeholder') ||
              ''
            }
            value={watch('diseaseId') || ''}
            onChange={(value) => {
              const val = value as Option;

              setValue('diseaseId', val.value as string, {
                shouldValidate: true,
              });
              setCurrentSearchText(val.label);
            }}
            options={optionsArr}
            label={
              t('consultation_data.add_family_historic_disease_label') || ''
            }
            withSearch
            errorMessage={errors?.diseaseId?.message}
            getNewData={handleGetNewData}
            hasNextPage={hasNextPage}
            onSearchData={handleSearchData}
            currentValue={currentSearchText}
          />
          <Input
            id={'comments'}
            type={'text'}
            label={
              t('consultation_data.add_family_historic_comments_label') || ''
            }
            register={register}
            placeholder={
              t('consultation_data.add_family_historic_comments_placeholder') ||
              ''
            }
          />
        </DataSection>
        <ButtonWrapper>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton
            disabled={!!Object.keys(errors).length}
            onClick={handleSubmit(handleSave)}
          >
            {t('save')}
          </PrimaryButton>
        </ButtonWrapper>
      </StyledModal>
    </Modal>
  );
};

export default AddFamilyHistoricModal;
