import React, { FC, ReactNode } from 'react';
import { ButtonGroupStyled } from './styles';

interface ButtonGroupProps {
  children: ReactNode;
}

const ButtonGroup: FC<ButtonGroupProps> = ({ children }) => {
  return <ButtonGroupStyled>{children}</ButtonGroupStyled>;
};

export default ButtonGroup;
