import { SocialMedia } from 'features/feature-edit-profile-settings/constants/socialMedia';

export const socialMediaValidation = (
  value: string,
  parentType: SocialMedia,
) => {
  switch (parentType) {
    case SocialMedia.Facebook:
      return /^(https?:\/\/)(www\.)?facebook\.com\/([a-zA-Z0-9_]*)/.test(value);
    case SocialMedia.Instagram:
      return /^(https?:\/\/)(www\.)?instagram\.com\/([a-zA-Z0-9_.]*)/.test(
        value,
      );
    case SocialMedia.Linkedin:
      return /(^(https?:\/\/)(www\.)?linkedin\.com\/)((([\w]{2,3})?)|([^]+\/(([\w|\d-&#?=])+\/?){1,}))$/.test(
        value,
      );
    case SocialMedia.Tiktok:
      return /^(https?:\/\/)(www\.)?tiktok\.com\/@([a-zA-Z0-9_.]*)/.test(value);
    case SocialMedia.Twitter:
      return /^(https?:\/\/)(www\.)?twitter\.com\/([a-zA-Z0-9_]*)/.test(value);

    default:
      return false;
  }
};
