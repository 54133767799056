import styled from 'styled-components';

interface Props {
  isError?: boolean;
}

interface PropsReplicate {
  disabled: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
`;

export const SlotsWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

export const ScheduleFieldItem = styled.div<Props>`
  margin-bottom: 24px;

  label {
    display: block;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error2 : theme.color.grey600};
    margin-bottom: 16px;
  }
`;

export const WorkingSchedule = styled.div`
  height: fit-content;
  max-width: 300px;
  padding: 16px;
  margin-left: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.grey50};

  > h2 {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    margin-bottom: 8px;
  }
`;

export const WorkingDays = styled.div`
  display: grid;
  gap: 5px;
  color: ${({ theme }) => theme.color.grey800};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  > div {
    display: flex;
    > p {
      width: 100px;
    }
  }
`;
export const WorkingHours = styled.div`
  display: grid;
  gap: 5px;
`;

export const SlotsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ReplicateButton = styled.div<PropsReplicate>`
  height: fit-content;
  margin-left: 32px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  color: ${({ theme, disabled }) =>
    disabled ? theme.color.grey400 : theme.color.blue800};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  margin-bottom: 20px;
`;
