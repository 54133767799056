import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoSectionStyled } from './styles';

const InfoSection: FC = () => {
  const { t } = useTranslation();

  return (
    <InfoSectionStyled>
      <h1>{t('adminsListConfiguration.my_users')}</h1>
    </InfoSectionStyled>
  );
};

export default InfoSection;
