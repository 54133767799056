import React from 'react';
import { InfoWrapper, Content, NavBar, FooterWrapper, Apps } from './styles';
import Phones from 'applicaiton/assets/phones.png';
import Google from 'applicaiton/assets/google_play.png';
import Apple from 'applicaiton/assets/app_store.png';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from 'features/feature-auth-navbar/assets/docbay-logo.svg';
import LanguageSwitcher from 'features/feature-auth-navbar/components/LanguageSwitcher';

const MobileInfo = () => {
  const { t } = useTranslation();

  const googleLink = process.env.REACT_APP_DOCBAY_GOOGLE_PLAY_LINK;
  const appleLink = process.env.REACT_APP_DOCBAY_APP_STORE_LINK;

  return (
    <InfoWrapper>
      <NavBar>
        <Logo />
        <LanguageSwitcher />
      </NavBar>
      <Content>
        <img src={Phones} alt={'phones'} />
        <p>{t('mobile_screen')}</p>
        <Apps>
          <a href={googleLink}>
            <img src={Google} alt={'google play'} />
          </a>
          <a href={appleLink}>
            <img src={Apple} alt={'App store'} />
          </a>
        </Apps>
      </Content>
      <FooterWrapper>
        <p>{t('auth.footer.copyright')}</p>
      </FooterWrapper>
    </InfoWrapper>
  );
};

export default MobileInfo;
