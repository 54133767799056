import styled from 'styled-components';

interface DropdownStyledProps {
  isActive?: boolean;
  disabled?: boolean;
  isError?: boolean;
}

interface OptionItemProps {
  selected: boolean;
  isMulti: boolean;
}

interface ErrorProps {
  isError?: boolean;
}

interface ColorProps {
  colorCode: string;
}

export const Wrapper = styled.div<ErrorProps>`
  display: grid;
  gap: 6px;
  width: 100%;

  > label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error2 : theme.color.grey600};
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.error2};
  }
`;

export const DropdownStyled = styled.div<DropdownStyledProps>`
  display: grid;
  gap: 6px;
  width: 100%;
  position: relative;

  > label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: ${({ theme, disabled }) =>
      disabled ? theme.color.grey400 : theme.color.grey600};
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme, disabled }) =>
      disabled ? theme.color.grey400 : theme.color.grey600};
  }
  > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    width: 100%;
    height: 44px;
    background: ${({ theme, isActive, disabled, isError }) =>
      isError
        ? theme.color.error3
        : isActive
        ? theme.color.blue50
        : theme.color[disabled ? 'grey50' : 'white']};
    border: 1px solid
      ${({ theme, isActive, isError }) =>
        isError
          ? theme.color.error2
          : isActive
          ? theme.color.blue800
          : theme.color.grey300};
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.color.grey700};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    h2 {
      display: flex;
      align-items: center;

      > div {
        margin-right: 16px;
      }
    }

    > span {
      color: ${({ theme, disabled }) =>
        disabled ? theme.color.grey300 : theme.color.grey500};
    }
    > svg {
      margin-left: 12px;
      transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : '')};
      path {
        fill: ${({ theme, disabled }) =>
          disabled ? theme.color.grey300 : theme.color.grey700};
      }
    }
  }
`;

export const OptionsList = styled.div`
  display: grid;
  padding: 12px;
  gap: 4px;
  grid-template-rows: max-content;
  position: absolute;
  z-index: 10;
  top: 44px;
  max-height: 390px;
  min-width: 440px;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  cursor: pointer;
`;

export const AppointmentOption = styled.label<OptionItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  background: ${({ theme, selected }) =>
    selected ? theme.color.blue100 : 'transparent'};

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }

  > div:first-child {
    display: flex;
    align-items: center;
  }
  > div:last-child {
    display: ${({ isMulti }) => (!isMulti ? 'none' : 'flex')};
  }
`;

export const TitleText = styled.div`
  > span {
    display: block;
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    &:first-child {
      color: ${({ theme }) => theme.color.grey800};
      font-size: 16px;
      letter-spacing: 0.04px;
    }

    &:last-child {
      color: var(--grey-600, #475467);
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.035px;
    }
  }
`;

export const AppointmentColor = styled.div<ColorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
  border-radius: 4px;
  background: ${({ colorCode }) => colorCode};
  margin-right: 16px;
  color: ${({ theme }) => theme.color.white};
`;

export const EmptyResult = styled.div`
  display: flex;
  svg {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  p {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;

    > a {
      color: ${({ theme }) => theme.color.blue700};
      font-weight: 700;
      text-decoration-line: underline;
    }
  }
`;
