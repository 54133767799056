import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, PhotoUploader } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { uploadedPhoto } from 'applicaiton/store/reducers/Photo/PhotoSlice';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { GeneralInfoScheme } from '../../helpers/validations';
import ButtonSection from '../ButtonSection';
import { GeneralInfoForm } from './modules';
import { DividerStyled, Wrapper } from './styles';
import {
  editSecretary,
  getSecretaryById,
} from 'applicaiton/store/reducers/Secretaries/ActionCreators';

const GeneralInfo: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { currentUser } = useAppSelector((state) => state.secretaries);
  const { uploadedPhotos } = useAppSelector((state) => state.photos);

  useEffect(() => {
    id && dispatch(getSecretaryById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (currentUser) {
      setValue('firstName', currentUser?.firstName || '');
      setValue('lastName', currentUser?.lastName || '');
    }
  }, [currentUser?.firstName, currentUser?.lastName]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm<GeneralInfoForm>({
    resolver: yupResolver(GeneralInfoScheme),
  });

  const onSubmit = async () => {
    const { firstName, lastName } = getValues();
    const response = await dispatch(
      editSecretary({
        id: currentUser?.id!,
        data: {
          firstName,
          lastName,
          ...(uploadedPhotos.length
            ? {
                photo: uploadedPhotos[0],
                ...(currentUser?.photo?.id
                  ? { photoToDelete: currentUser?.photo?.id }
                  : {}),
              }
            : {}),
        },
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(uploadedPhoto([]));
    }
  };

  const isStateChanged = useMemo(() => {
    const { firstName, lastName } = getValues();
    const firstNameChanged = firstName !== currentUser?.firstName;
    const lastNameChanged = lastName !== currentUser?.lastName;

    return !!uploadedPhotos.length || firstNameChanged || lastNameChanged;
  }, [
    currentUser?.firstName,
    currentUser?.lastName,
    watch('firstName'),
    watch('lastName'),
    uploadedPhotos,
  ]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Input
        id={'firstName'}
        type="text"
        label={t('personal_information.first_name') || ''}
        placeholder={t('personal_information.first_name_placeholder') || ''}
        register={register}
        errorMessage={errors.firstName?.message}
      />

      <Input
        id={'lastName'}
        type="text"
        label={t('personal_information.last_name') || ''}
        placeholder={t('personal_information.last_name_placeholder') || ''}
        register={register}
        errorMessage={errors.lastName?.message}
      />
      <PhotoUploader defaultPhoto={currentUser?.photo} />
      <div>
        <DividerStyled />
        <ButtonSection disabled={!isStateChanged} />
      </div>
    </Wrapper>
  );
};

export default GeneralInfo;
