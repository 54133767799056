import { useEffect, useMemo, useState } from 'react';

export const useTimer = (refreshIntervalMs = 250) => {
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  const startTimerToDate = (date: Date) => {
    setEndTime(new Date(date));
  };
  const startTimer = () => {
    setEndTime(new Date());
  };

  const startTimerWithTimeout = (seconds: number) => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + seconds);
    setEndTime(date);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, refreshIntervalMs);

    return () => clearInterval(timer);
  }, []);

  const secondsLeft = useMemo(() => {
    if (!endTime) return 0;
    const endTimeMs = endTime?.getTime() || 0;
    const startTimeMs = currentTime?.getTime() || 0;
    return Math.floor((endTimeMs - startTimeMs) / 1000);
  }, [endTime, currentTime]);

  return {
    isRunning: !!endTime,
    startTimerToDate,
    startTimerWithTimeout,
    secondsLeft,
    secondsPassed: secondsLeft ? -secondsLeft : 0,
    startTimer,
  };
};
