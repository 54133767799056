import { ModalComponent, PrimaryButton } from 'common/components';
import React from 'react';
import { PatentNotfoundModalProps } from './module';
import { ErrorInfo, Wrapper } from './styles';
import { ErrorIcon } from 'applicaiton/assets';
import { useTranslation } from 'react-i18next';

export const PatientNotFoundModal = ({
  isOpen,
  onClose,
}: PatentNotfoundModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={onClose}
      width="700px"
      padding="0"
      hideCloseIcon
    >
      <Wrapper>
        <ErrorInfo>
          <ErrorIcon />
          <h2>404</h2>
          <h4>{t('patient_profile_not_found.title')}</h4>
          <p>{t('patient_profile_not_found.text')}</p>
          <PrimaryButton onClick={onClose}>
            {t('patient_profile_not_found.go_to_patients')}
          </PrimaryButton>
        </ErrorInfo>
      </Wrapper>
    </ModalComponent>
  );
};
