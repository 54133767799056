import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PrivateDoctorActions } from 'applicaiton/constants/actions';
import { PrivateDoctorAPI } from 'integration/api/privateDoctor';
import { PrivateDoctorUpdateParams } from 'common/types/privateDoctor';

export const getPrivateDoctor = createAsyncThunk(
  PrivateDoctorActions.getPrivateDoctor,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await PrivateDoctorAPI.getPrivateDoctor(
        id,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updatePrivateDoctor = createAsyncThunk(
  PrivateDoctorActions.updatePrivateDoctor,
  async (params: PrivateDoctorUpdateParams, { rejectWithValue }) => {
    try {
      const { data } = await PrivateDoctorAPI.updatePrivateDoctor(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
