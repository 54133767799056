import styled from 'styled-components';

export const Wrapper = styled.section`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    height: 360px;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.color.blue300};
    background: ${({ theme }) => theme.color.blue50};
  }
`;

export const PhotosGalaryStyled = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 11px;
  width: 480px;
  img {
    width: 240px;
    border-radius: 11px;
  }

  & :first-child {
    display: grid;
    gap: 8px;
    grid-template-rows: auto;
    grid-template-areas:
      'header header'
      'left right'
      'footer footer';

    img {
      &:nth-child(1) {
        grid-area: header;
        height: 165px;
        border-top-right-radius: 0px;
      }
      &:nth-child(2) {
        width: 113px;
        height: 113px;
        grid-area: left;
      }
      &:nth-child(3) {
        width: 119px;
        height: 113px;
        grid-area: right;
      }
      &:nth-child(4) {
        height: 64px;
        grid-area: footer;
        border-bottom-right-radius: 0px;
      }
    }
  }
  & :nth-child(2) {
    display: grid;
    gap: 8px;

    img {
      &:nth-child(1) {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
      &:nth-child(3) {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        height: 124px;
      }
    }
  }
`;

export const InfoSectionStyled = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  padding: 0 32px;

  h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 28px;
    line-height: 140%;
  }

  p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
  }

  button {
    margin-top: 28px;
    width: 200px;
    background-color: ${({ theme }) => theme.color.white};
  }
`;

export const CheckIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.color.success1};
  border-radius: 50%;
  opacity: 0.5;

  > svg {
    margin-bottom: 2px;
    path {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;
