import { setUserTimezoneInLocalStorage } from 'applicaiton/sessionStorage/auth';
import { setTimeZone } from 'applicaiton/store/reducers/TimeZone/TimeZoneSlice';

import { useAppDispatch, useAppSelector } from './redux';
import { setTimeZoneData } from 'applicaiton/store/reducers/TimeZone/ActionCreators';

export const useUserTimeZone = () => {
  const dispatch = useAppDispatch();
  const { tz } = useAppSelector((state) => state.timeZone);

  const setUserTimeZone = (value: string) => {
    dispatch(setTimeZoneData(value)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        dispatch(setTimeZone(value));
        setUserTimezoneInLocalStorage(value);
      }
    });
  };

  return { tz, setUserTimeZone };
};
