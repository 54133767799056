import { Participant } from 'features/feature-teleconsultation/types';
import { useEffect, useState } from 'react';
import { LocalAudioTrack, LocalTrack, LocalVideoTrack } from 'twilio-video';

/**
 * participant track subscription
 */
const useTrackSubscription = ({ participant }: Participant) => {
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | null>(null);
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack | null>(null);

  useEffect(() => {
    if (participant) {
      /**
       * participant track subs
       */
      const trackSubscribed = (track: LocalTrack) => {
        if (track.kind === 'video') {
          setVideoTrack(track);
        } else if (track.kind === 'audio') {
          setAudioTrack(track);
        }
      };

      participant.tracks.forEach((publication) => {
        if (publication.track) {
          trackSubscribed(publication.track as LocalTrack);
        }
      });

      participant.on('trackSubscribed', trackSubscribed);

      /**
       * participant track unsubs
       */
      const trackUnsubscribed = (track: LocalTrack) => {
        if (track.kind === 'video') {
          setVideoTrack(null);
        } else if (track.kind === 'audio') {
          setAudioTrack(null);
        }
      };
      participant.on('trackUnsubscribed', trackUnsubscribed);

      return () => {
        participant.off('trackSubscribed', trackSubscribed);
        participant.off('trackUnsubscribed', trackUnsubscribed);
      };
    }
  }, [participant]);

  return { audioTrack, videoTrack };
};

export default useTrackSubscription;
