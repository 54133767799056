import React, { FC, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ReactComponent as AttentionIcon } from 'applicaiton/assets/attention.svg';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { AccordionQuestion } from 'common/components';
import {
  Wrapper,
  QuestionItem,
  QuestionList,
  QuestionsWrapper,
  DividerStyled,
  Title,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { QuestionItemText } from 'common/types/clinics';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import {
  createClinicProfile,
  saveClinicProfile,
} from 'applicaiton/store/reducers/Clinics/ActionCreators';
import ButtonSection from '../ButtonSection';
import { PathNames } from 'applicaiton/routes';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { Faq } from '@docbay/schemas';
import { setShowSuccessModal } from 'applicaiton/store/reducers/Clinics/ClinicProfileSlice';
import { checkMandatoryFields } from 'features/feature-edit-profile-settings/helpers/checkMandatoryFields';

const FAQs: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isCreatePage = location.pathname.endsWith('create');
  const { clinicProfileData } = useAppSelector((state) => state.clinicProfile);
  const [questions, setQuestions] = useState<Faq[]>(
    clinicProfileData?.faqs || [],
  );

  const handleAddQuestion = () => {
    setQuestions((prev) => [
      ...prev,
      {
        id: uuid(),
        question: '',
        answer: '',
      },
    ]);
  };

  const handleEditQuestion = (id: string, value: QuestionItemText) => {
    const faqsEdited = questions?.map((question) => {
      if (question.id === id) {
        return { ...question, ...value };
      }
      return question;
    });
    setQuestions(faqsEdited);
  };

  const handleRemoveQuestion = (id: string) => {
    setQuestions((prev) => prev.filter((question) => question.id !== id));
  };

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const questionsReordered = reorder(
      questions || [],
      result.source.index,
      result.destination.index,
    );

    setQuestions(questionsReordered);
  };

  const disabledButton = questions?.some(
    (item) => !item.answer || !item.question,
  );

  const handleSave = () => {
    const filteredQuestions = questions.filter(
      (item) => item.answer && item.question,
    );
    if (isCreatePage && !clinicProfileData) {
      dispatch(
        createClinicProfile({
          name: '',
          ...(filteredQuestions.length ? { faqs: questions } : {}),
        }),
      );
    } else {
      const isRequiredFieldsFilled = checkMandatoryFields(clinicProfileData);
      if (isCreatePage && isRequiredFieldsFilled) {
        dispatch(setShowSuccessModal(true));
      }
      dispatch(
        saveClinicProfile({
          id: clinicProfileData?.id!,
          data: { ...(filteredQuestions.length ? { faqs: questions } : {}) },
        }),
      );
    }
  };

  const handleCancel = () => {
    navigate(PathNames.myClinics);
  };

  const isStateChanged = useMemo(() => {
    const hasChanges = compareObjectsChanges(
      { faqs: questions },
      { faqs: clinicProfileData?.faqs || [] },
      true,
    );

    return hasChanges;
  }, [questions, clinicProfileData?.faqs]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const isButtonDisabled = isCreatePage ? false : !isStateChanged;

  return (
    <>
      <Title>{t('editProfileSettings.faqs_title')}</Title>
      <Wrapper>
        <QuestionsWrapper>
          <div>
            <AttentionIcon />
            <h2>{t('clinicsConfiguration.profile_address.hint')}</h2>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <QuestionList
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {questions.map((item, index) => (
                    <Draggable
                      key={item.id || index}
                      draggableId={item.id || String(index)}
                      index={index}
                    >
                      {(provided) => (
                        <QuestionItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <AccordionQuestion
                            id={item.id!}
                            question={item.question!}
                            answer={item.answer!}
                            defaultName={`${t('accordion_question.question')} ${
                              index + 1
                            }`}
                            onChange={(value) =>
                              handleEditQuestion(item.id || '', value)
                            }
                          />
                          <button>
                            <CloseIcon
                              onClick={() =>
                                handleRemoveQuestion(item.id || '')
                              }
                            />
                          </button>
                        </QuestionItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </QuestionList>
              )}
            </Droppable>
          </DragDropContext>
          <button
            disabled={disabledButton}
            type="button"
            onClick={() => handleAddQuestion()}
          >
            <AddIcon />
            <h2>{t('add_another')}</h2>
          </button>
        </QuestionsWrapper>
        <div>
          <DividerStyled />
          <ButtonSection
            onSave={handleSave}
            onCancel={handleCancel}
            disabled={isButtonDisabled}
          />
        </div>
      </Wrapper>
    </>
  );
};

export default FAQs;
