import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NotificationConfigItem,
  NotificationConfigResponse,
  NotificationType,
} from '@docbay/schemas';

import {
  fetchNotificationsSettings,
  updateNotificationsSettings,
} from 'applicaiton/store/reducers/NotificationsSettings/ActionCreators';
import { getUserId } from 'applicaiton/sessionStorage/auth';

import { Loader, PrimaryButton, SecondaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';

import { ViewBy } from 'features/feature-notifications-settings/constants';
import { useSettingsConfiguration } from 'features/feature-notifications-settings/hooks/useSettingsConfiguration';

import TabsSection from '../TabsSection';
import SettingItem from '../SettingItem';

import { TableStyled, Wrapper, DividerStyled, ButtonSection } from './styles';

const PermissionsTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, notificationsSettings } = useAppSelector(
    (state) => state.notificationsSettings,
  );

  const [viewBy, setViewBy] = useState<ViewBy>(ViewBy.Patients);
  const [settingsList, setSettingsList] =
    useState<NotificationConfigResponse | null>(null);

  const userId = getUserId();
  const notificationsConfigurations = useSettingsConfiguration();

  useEffect(() => {
    if (userId) {
      dispatch(fetchNotificationsSettings(userId));
    }
  }, [userId]);

  useEffect(() => {
    setSettingsList(notificationsSettings);
  }, [notificationsSettings]);

  const currentSettingsList = useMemo(() => {
    if (!settingsList) return [];

    const translatedSettingsList = settingsList[viewBy].map((item) => {
      const currentType = notificationsConfigurations.find(
        (type) => (type.key as any) === item.notificationType,
      );
      if (currentType) {
        return {
          ...item,
          name: currentType.name as NotificationType,
        };
      }
      return { ...item, name: item.notificationType };
    });

    return translatedSettingsList;
  }, [settingsList, viewBy, notificationsConfigurations]);

  const handleChangeNotifications = (setting: NotificationConfigItem) => {
    if (settingsList) {
      const updatedSettings = settingsList[viewBy].map((item) => {
        if (item.notificationType === setting.notificationType) {
          return setting;
        }
        return item;
      });

      const updatedNotifications = {
        ...settingsList,
        [viewBy]: updatedSettings,
      };

      setSettingsList(updatedNotifications);
    }
  };

  const onCancel = () => {
    setSettingsList(notificationsSettings);
  };

  const onSave = () => {
    if (settingsList && userId) {
      dispatch(updateNotificationsSettings({ id: userId, data: settingsList }));
    }
  };

  const hasChanges = useMemo(() => {
    return compareObjectsChanges(settingsList, notificationsSettings);
  }, [notificationsSettings, settingsList]);
  const displaySmsColumn = viewBy === ViewBy.Patients;

  return (
    <Wrapper>
      {isLoading && <Loader />}
      {currentSettingsList && (
        <>
          <div>
            <TabsSection viewBy={viewBy} setViewBy={setViewBy} />
            <TableStyled>
              <thead>
                <tr>
                  <th></th>
                  {displaySmsColumn && (
                    <th>
                      <span>
                        {t('notifications_settings.sms_notification')}
                      </span>
                    </th>
                  )}
                  <th>
                    <span>{t('notifications_settings.push_notification')}</span>
                  </th>
                  <th>
                    <span>{t('email')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentSettingsList.map((item, index) => (
                  <SettingItem
                    key={item.notificationType}
                    index={index}
                    item={item}
                    displaySmsColumn={displaySmsColumn}
                    handleChangeNotifications={handleChangeNotifications}
                  />
                ))}
              </tbody>
            </TableStyled>
          </div>
          <DividerStyled />
          <ButtonSection>
            <SecondaryButton
              type="button"
              onClick={onCancel}
              disabled={!hasChanges}
            >
              {t('cancel')}
            </SecondaryButton>
            <PrimaryButton
              type="button"
              onClick={onSave}
              disabled={!hasChanges}
            >
              {t('save')}
            </PrimaryButton>
          </ButtonSection>
        </>
      )}
    </Wrapper>
  );
};

export default PermissionsTable;
