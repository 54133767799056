import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usersForgotPassword } from 'applicaiton/store/reducers/Users/ActionCreators';
import { PathNames } from 'applicaiton/routes';
import {
  setAuthError,
  setIsSuccessResetPassword,
} from 'applicaiton/store/reducers/Users/UsersSlice';

import { Input, PrimaryButton, ReCaptcha } from 'common/components';
import { authService } from 'common';
import { ErrorCodes } from 'common/types/error';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';

import Header from './components/Header';
import BackButton from './components/BackButton';
import { useForgotPasswordForm } from './hooks/useForgotPasswordForm';
import type { ForgotPasswordDataT } from './types';
import {
  FormGroupStyled,
  ForgotPasswordWrapper,
  StyledForm,
  Container,
} from './styles';

const ForgotPasswordPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error, isSuccessResetPassword } = useAppSelector(
    (state) => state.users,
  );

  const {
    register,
    errors,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForgotPasswordForm({
    emailError: t('errors.emailFormat'),
    requiredError: t('errors.required'),
  });

  useEffect(() => {
    if (error) {
      const isUserNotFound = error.includes(ErrorCodes.USER_NOT_FOUND);
      const message = isUserNotFound
        ? t('errors.user_not_found')
        : t('something_went_wrong');
      setError('email', { message });
    } else {
      clearErrors();
    }
  }, [error]);

  useEffect(() => {
    if (isSuccessResetPassword) {
      navigate(PathNames.confirmChange);
    }
    return () => {
      dispatch(setIsSuccessResetPassword(false));
      dispatch(setAuthError(false));
    };
  }, [isSuccessResetPassword]);

  useEffect(() => {
    if (error) {
      dispatch(setAuthError(false));
    }
  }, [watch('email')]);

  const onChangeReCAPTCHA = (token: string | null) => {
    if (token) {
      setValue('captchaToken', token, {
        shouldValidate: !!errors.captchaToken?.message,
      });
    }
  };

  const onExpiredReCAPTCHA = () => {
    setValue('captchaToken', '', {
      shouldValidate: !!watch('captchaToken'),
    });
  };

  const onSubmit = async (data: ForgotPasswordDataT) => {
    authService.saveEmail(data.email);
    dispatch(usersForgotPassword(data));
  };

  return (
    <>
      <BackButton />
      <ForgotPasswordWrapper>
        <Header
          title={t('auth.forgot_password.title')}
          subtitle={t('auth.forgot_password.sub_title') || ''}
        />

        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FormGroupStyled>
            <Container>
              <Input
                id="email"
                label={t('auth.email') || ''}
                type="email"
                placeholder={t('email_placeholder') || ''}
                register={register}
                errorMessage={errors.email?.message}
              />
              <ReCaptcha
                error={errors.captchaToken?.message}
                onChange={onChangeReCAPTCHA}
                onExpired={onExpiredReCAPTCHA}
              />
            </Container>
          </FormGroupStyled>

          <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            type="submit"
            disabled={!!Object.keys(errors).length}
          >
            {t('auth.continue')}
          </PrimaryButton>
        </StyledForm>
      </ForgotPasswordWrapper>
    </>
  );
};

export default ForgotPasswordPage;
