import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { RegistrationDataT } from '../types';

export const useCreatePasswordForm = ({
  emailError,
  requiredError,
}: {
  emailError: string;
  requiredError: string;
}) => {
  const createPasswordSchema = yup
    .object({
      email: yup.string().email(emailError!).required(requiredError!),
      password: yup
        .string()
        .required()
        .min(10)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.{10,})/),
    })
    .required();

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<RegistrationDataT>({
    resolver: yupResolver(createPasswordSchema),
  });

  return {
    register,
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
  };
};
