import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { ViewBy } from 'features/feature-patient-profile/constants';
import { fetchPatientAppointmentsBrief } from 'applicaiton/store/reducers/Appointments/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader } from 'common/components';
import FilterSection from '../FilterSection';
import EmptyStateByFuture from '../EmptyStateByFuture';
import EmptyStateByPast from '../EmptyStateByPast';
import AppointmentItem from '../AppointmentItem';
import { TableStyled, Wrapper } from './styles';

const AppointmentsTable: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { appointments, isLoading } = useAppSelector(
    (state) => state.patientAppointments,
  );
  const { deletedSuccess } = useAppSelector((state) => state.appointmentsSlice);

  const [viewBy, setViewBy] = useState<ViewBy>(ViewBy.Future);

  const isViewByFuture = viewBy === ViewBy.Future;

  useEffect(() => {
    dispatch(fetchPatientAppointmentsBrief({ patientId: String(id) }));
  }, [id, deletedSuccess]);

  const appointmentsList = useMemo(() => {
    const appointmentsFiltered = appointments.filter((appointment) => {
      if (isViewByFuture) {
        return dayjs().isBefore(dayjs(appointment.startDate));
      } else {
        return dayjs().isAfter(dayjs(appointment.startDate));
      }
    });
    return appointmentsFiltered;
  }, [appointments, isViewByFuture]);

  const isEmptyStateByFuture = !appointmentsList?.length && isViewByFuture;
  const isEmptyStateByPast = !appointmentsList?.length && !isViewByFuture;

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <FilterSection viewBy={viewBy} setViewBy={setViewBy} />
      <TableStyled>
        {!!appointmentsList?.length && (
          <thead>
            <tr>
              <th>
                <span>{t('doctor')}</span>
              </th>
              <th>
                <span>{t('appointment_type')}</span>
              </th>
              <th>
                <span>{t('place')}</span>
              </th>
              <th>
                <span>{t('date_and_time')}</span>
              </th>
              <th>
                <span>{t('edit')}</span>
              </th>
            </tr>
          </thead>
        )}
        <tbody>
          {Array.isArray(appointmentsList) &&
            appointmentsList?.map((appointment) => (
              <AppointmentItem
                key={appointment.id}
                appointment={appointment}
                isViewByFuture={isViewByFuture}
              />
            ))}
          {isEmptyStateByFuture && <EmptyStateByFuture />}
          {isEmptyStateByPast && <EmptyStateByPast />}
        </tbody>
      </TableStyled>
    </Wrapper>
  );
};

export default AppointmentsTable;
