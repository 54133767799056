import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserItem from '../UserItem';
import { TableStyled, Wrapper } from './styles';
import NoDataElement from '../NoDataElement';
import { Loader, Pagination } from 'common/components';
import { Option as ClinicOption } from 'common/components/DropdownClinics/models';
import FilterSection from '../FilterSection';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchClinicOwnerStaff } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { UserType } from 'features/feature-users-list/hooks/useUserTypeOptions';

const UsersTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clinicOwnerStaff, isLoading, totalPages } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [clinics, setClinics] = useState<ClinicOption[]>([]);
  const [userType, setUserType] = useState<UserType | ''>('');

  useEffect(() => {
    const clinicIds = clinics.map((clinic) => String(clinic.value));

    const userTypesList = userType
      ? [userType]
      : [UserType.Admin, UserType.Secretary];

    dispatch(
      fetchClinicOwnerStaff({
        page: currentPage,
        clinicIds,
        userTypes: userTypesList,
      }),
    );
  }, [dispatch, currentPage, clinics, userType]);

  const handleRemoveClinic = (id: string) => {
    setClinics((prev) => [...prev.filter((item) => item.value !== id)]);
  };

  const allUsersIsActive = useMemo(() => {
    if (clinicOwnerStaff?.length) return false;

    const hasNotActiveUsers = clinicOwnerStaff.some((user) => !user.active);
    return hasNotActiveUsers ? false : true;
  }, [clinicOwnerStaff]);

  return (
    <>
      <Wrapper>
        {isLoading && <Loader />}
        <FilterSection
          clinics={clinics}
          setClinics={setClinics}
          userType={userType}
          setUserType={setUserType}
          handleRemoveClinic={handleRemoveClinic}
        />
        <TableStyled>
          <thead>
            <tr>
              <th>
                <span>{t('adminsListConfiguration.users_name')}</span>
              </th>
              <th>
                <span>{t('adminsListConfiguration.users_type')}</span>
              </th>
              <th>
                <span>{t('adminsListConfiguration.actions')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(clinicOwnerStaff) &&
              clinicOwnerStaff?.map((user) => (
                <UserItem
                  key={`${user.userType}_${user.id}`}
                  user={user}
                  allUsersIsActive={allUsersIsActive}
                  currentPage={currentPage}
                  userType={userType}
                  currentUserRole={user.userType as UserType}
                />
              ))}
            {!clinicOwnerStaff?.length && <NoDataElement userType={userType} />}
          </tbody>
        </TableStyled>
        {clinicOwnerStaff.length && totalPages > 1 ? (
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} />
        ) : (
          ''
        )}
      </Wrapper>
    </>
  );
};

export default UsersTable;
