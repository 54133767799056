import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ViewBy } from 'features/feature-user-permissions/constants';
import { FilterSectionProps } from './modules';
import { ButtonGroup, ButtonStyled, FilterSectionStyled } from './styles';

const FilterSection: FC<FilterSectionProps> = ({ viewBy, setViewBy }) => {
  const { t } = useTranslation();

  return (
    <FilterSectionStyled>
      <ButtonGroup>
        <ButtonStyled
          isActive={viewBy === ViewBy.Agenda}
          onClick={() => {
            setViewBy(ViewBy.Agenda);
          }}
        >
          {t('agenda')}
        </ButtonStyled>
        <ButtonStyled
          isActive={viewBy === ViewBy.ClinicsConfiguration}
          onClick={() => {
            setViewBy(ViewBy.ClinicsConfiguration);
          }}
        >
          {t('clinics_configuration')}
        </ButtonStyled>
      </ButtonGroup>
    </FilterSectionStyled>
  );
};

export default FilterSection;
