import styled from 'styled-components';

interface PageButtonProps {
  currentPage: boolean;
}

export const Wrapper = styled.section`
  display: flex;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  padding: 12px 24px 16px 24px;
  justify-content: space-between;
  align-items: center;

  > button {
    width: fit-content;
  }
  > div {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.grey500};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
  }
`;

export const PageButton = styled.button<PageButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  color: ${({ theme, currentPage }) =>
    currentPage ? theme.color.grey800 : theme.color.grey500};
  font-size: 14px;
  font-weight: ${({ currentPage }) => (currentPage ? '600' : '500')};
  line-height: 19.6px;
  background: ${({ theme, currentPage }) =>
    currentPage ? theme.color.blue200 : theme.color.white};
`;

export const ButtonStyled = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 10px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.08px;
  color: ${({ theme }) => theme.color.grey700};
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.color.grey400};
  box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.color.grey100};
    color: ${({ theme }) => theme.color.grey900};
  }

  :disabled {
    background-color: ${({ theme }) => theme.color.white};
    border-color: ${({ theme }) => theme.color.grey300};
    color: ${({ theme }) => theme.color.grey300};
  }
`;
