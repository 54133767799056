import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Divider } from '@mui/material';
import SideBar from '../SideBar';
import ProfileEditHeader from '../ProfileEditHeader';
import ClinicInformation from '../ClinicInformation';
import { Wrapper, StyledSection } from './styles';
import { useAppSelector } from 'common/hooks/redux';
import { ClinicProfessionalUpdateDto } from '@docbay/schemas';
import { getSchedule } from 'common/helpers/getSchedule';
import { FormProvider } from 'react-hook-form';
import { useEditClinicForm } from 'features/feature-professional-edit/hooks/useEditClinicForm';
import { useTranslation } from 'react-i18next';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import Email from '../Email';
import Password from '../Password';
import Phone from '../ChangingPhone/Phone';
import ProfessionalSubscriptions from '../ProfessionalSubscriptions';

interface LayoutComponentProps {
  children: ReactNode;
}

const LayoutComponent: FC<LayoutComponentProps> = ({ children }) => {
  const userRole = getUserRole();
  const isUserRoleProfessional = userRole === UserRoles.professional;
  const professionalUserRoleIndex = isUserRoleProfessional ? 5 : 2;

  const [clinicNames, setClinicsNames] = useState<string[]>([]);
  const [clinics, setClinics] = useState<ClinicProfessionalUpdateDto[]>([]);
  const [activeForm, setActiveForm] = useState<number>(0);

  const { t } = useTranslation();
  const methods = useEditClinicForm({
    requiredError: t('errors.required'),
  });
  const { editedProfessional, isVerifiedOldEmail, isVerifiedOldPhone } =
    useAppSelector((state) => state.professionalProfile);
  const isClinicVisible =
    (isUserRoleProfessional && activeForm > 4) ||
    (!isUserRoleProfessional &&
      activeForm > 1 &&
      activeForm < clinics.length + professionalUserRoleIndex);

  useEffect(() => {
    const professionalEdit: HTMLDivElement | null =
      document.querySelector('#professional-edit');

    professionalEdit?.scroll(0, 0);
  }, [activeForm]);

  useEffect(() => {
    if (isVerifiedOldEmail || isVerifiedOldPhone) return;
    setActiveForm(0);
    setClinicsNames(
      editedProfessional && editedProfessional.clinicsRelations
        ? editedProfessional.clinicsRelations.map((item) => item.clinic?.name!)
        : [],
    );
    const clinicProfessionalDtos: ClinicProfessionalUpdateDto[] = [];
    const clinicsRelations = editedProfessional?.clinicsRelations;

    if (clinicsRelations) {
      for (const clinicProfessional of clinicsRelations) {
        const clinicProfessionalDto = {} as ClinicProfessionalUpdateDto;
        clinicProfessionalDto.clinicId = clinicProfessional.clinic?.id!;
        const specializationsToAdd = [];

        if (clinicProfessional.specializations) {
          for (const specialization of clinicProfessional.specializations) {
            if (specialization) {
              specializationsToAdd.push(specialization.specialization.id!);
            }
          }
        }

        clinicProfessionalDto.specializationsToAdd = specializationsToAdd;
        clinicProfessionalDto.schedule = clinicProfessional.schedule;
        clinicProfessionalDto.id = clinicProfessional.id;
        clinicProfessionalDtos.push(clinicProfessionalDto);
      }

      setClinics(clinicProfessionalDtos);
    }
  }, [editedProfessional]);

  const handleAddClinic = () => {
    setClinicsNames((prev) => [...prev, t('professional_profile.new_clinic')]);
    setActiveForm((prev) => prev + 1);
  };

  const handleRemoveClinic = () => {
    const allAddedClinics: string[] = [...clinicNames];
    allAddedClinics.splice(activeForm - professionalUserRoleIndex, 1);

    const renamedClinics = allAddedClinics.map(
      (_item: string, index: number) => {
        if (_item?.includes(t('professional_profile.new_clinic'))) {
          return `${t('professional_profile.new_clinic')} ${
            index > 1 ? index : ''
          }`;
        } else return _item;
      },
    );

    setActiveForm((prev) => {
      return prev > 0 ? prev - 1 : prev;
    });
    setClinicsNames(renamedClinics);
  };

  const addNewClinic = () => {
    if (!activeForm || !Object.keys(methods.formState.errors).length) {
      const defaultSchedule = getSchedule();
      methods.reset();
      const newClinicData: ClinicProfessionalUpdateDto = {
        clinicId: '',
        specializationsToAdd: [],
        appointmentTypesToAdd: [],
        schedule: {
          hours: defaultSchedule,
        },
      };
      setClinics((prevClinics) => [...prevClinics, newClinicData]);
      setClinicsNames((prev) => [
        ...prev,
        t('professional_profile.new_clinic'),
      ]);
      setActiveForm(clinics.length + professionalUserRoleIndex);
    }
  };

  return (
    <Wrapper>
      <ProfileEditHeader />
      <Divider />
      <StyledSection>
        <FormProvider {...methods}>
          <SideBar
            clinics={clinicNames}
            handleAddClinic={addNewClinic}
            selectedClinic={activeForm}
            setSelectedClinic={setActiveForm}
          />
          {activeForm ? (
            <>
              {activeForm === 1 && <ProfessionalSubscriptions />}
              {isUserRoleProfessional && activeForm === 2 && <Email />}
              {isUserRoleProfessional && activeForm === 3 && <Phone />}
              {isUserRoleProfessional && activeForm === 4 && <Password />}
              {isClinicVisible && (
                <ClinicInformation
                  arrayIndex={
                    activeForm > professionalUserRoleIndex
                      ? activeForm - professionalUserRoleIndex
                      : 0
                  }
                  handleDelete={handleRemoveClinic}
                  addClinic={handleAddClinic}
                  allClinics={clinics}
                  setAllClinics={setClinics}
                />
              )}
            </>
          ) : (
            <>{children}</>
          )}
        </FormProvider>
      </StyledSection>
    </Wrapper>
  );
};

export default LayoutComponent;
