import { useEffect, useState } from 'react';
import { VideoTrackProps } from 'features/feature-teleconsultation/types';
import { useAppSelector } from 'common/hooks/redux';

export default function useTrackVideo({ videoTrack }: VideoTrackProps) {
  const { isVideoOn } = useAppSelector((state) => state.teleconsultation);
  const [videoOn, setVideoOn] = useState(isVideoOn);

  useEffect(() => {
    if (videoTrack) {
      // initial state video
      setVideoOn(videoTrack.isEnabled);

      const handleVideoDisabled = () => {
        setVideoOn(false);
      };

      const handleVideoEnabled = () => {
        setVideoOn(true);
      };

      videoTrack.on('disabled', handleVideoDisabled);
      videoTrack.on('enabled', handleVideoEnabled);

      return () => {
        videoTrack.off('disabled', handleVideoDisabled);
        videoTrack.off('enabled', handleVideoEnabled);
      };
    }
  }, [videoTrack]);

  useEffect(() => {
    if (videoTrack?.id) {
      !isVideoOn ? videoTrack.disable() : videoTrack.enable();
    }
  }, [videoTrack, isVideoOn]);

  return { videoOn };
}
