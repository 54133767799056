import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { PathNames } from 'applicaiton/routes';
import { ReactComponent as HomeIcon } from 'applicaiton/assets/home.svg';
import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar.svg';
import {
  refreshAgenda,
  setIsAddNewEvent,
} from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { setErrorOpenAgenda } from 'applicaiton/store/reducers/Agenda/AgendaSlice';
import { ViewBy } from 'applicaiton/constants/scheduler';
import {
  addOpenAgenda,
  editOpenAgenda,
  fetchOpenAgenda,
  removeOpenAgenda,
} from 'applicaiton/store/reducers/Agenda/ActionCreators';

import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Dropdown, PrimaryButton, SecondaryButton } from 'common/components';
import { Option } from 'common/components/DropdownProfessionals/models';

import { OpenAgendaHours } from 'features/feature-agenda-reserve-slots-modal/components';

import { Wrapper, ButtonsWrapper, CurrentDate, FieldsSection } from './styles';

interface Props {
  onClose: () => void;
}

const OpenAgendaForm: FC<Props> = ({ onClose }) => {
  const { t, i18n } = useTranslation();
  const userRole = getUserRole();
  const location = useLocation();
  const isUserRoleProfessional = userRole === UserRoles.professional;
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const [chosenProfessionals, setChosenProfessionals] = useState<Option>();
  const [currentClinicId, setCurrentClinicId] = useState<string>('');
  const dispatch = useAppDispatch();
  const { isLoading, openAgenda, editedOpenAgenda, errorOpenAgenda } =
    useAppSelector((state) => state.agenda);
  const { viewBy, byAvailability, byProfessionals } = useAppSelector(
    (state) => state.scheduler,
  );
  const { currentAppointment, professionalId, startDate } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const { professionals } = useAppSelector((state) => state.professionals);
  const { editedProfessional } = useAppSelector(
    (state) => state.professionalProfile,
  );

  useEffect(() => {
    const currentProfessional = professionals.find(
      (professional) =>
        professional.id === currentAppointment?.professional?.id ||
        professional.id === professionalId,
    );
    if (currentProfessional) {
      setChosenProfessionals({
        label:
          currentProfessional.firstName + ' ' + currentProfessional.lastName,
        value: currentProfessional.id,
        image: currentProfessional.mainPhoto?.thumbnailUrl,
        specializations: currentProfessional.specializations || [],
      });
    }
  }, []);

  const currentViewData = useMemo(() => {
    return viewBy === ViewBy.byProfessionals ? byProfessionals : byAvailability;
  }, [viewBy, byAvailability, byProfessionals]);

  useEffect(() => {
    if (currentViewData.clinicId) {
      if (!isUserRoleProfessional && !isMyAgendaPage) {
        setCurrentClinicId(currentViewData.clinicId);
      }
    }
  }, [currentViewData]);

  useEffect(() => {
    currentClinicId &&
      dispatch(
        fetchOpenAgenda({
          id: String(chosenProfessionals?.value),
          params: {
            date: dayjs(startDate).format('YYYY-MM-DD'),
            clinicId: String(currentClinicId),
          },
        }),
      );
  }, [currentClinicId, chosenProfessionals, currentAppointment]);

  useEffect(() => {
    if (editedProfessional) {
      setChosenProfessionals({
        label: editedProfessional.firstName + ' ' + editedProfessional.lastName,
        value: editedProfessional.id,
        image: editedProfessional.mainPhoto?.thumbnailUrl,
        specializations: editedProfessional.specializations || [],
      });
    }
  }, [editedProfessional]);

  const clinicsOptions = useMemo(() => {
    if (!editedProfessional?.clinicsRelations?.length) return [];
    return editedProfessional?.clinicsRelations
      .filter((item) => item.clinic?.status === 'Active')
      .map((item) => ({
        label: String(item?.clinic?.name),
        value: String(item?.clinic?.id),
      }));
  }, [editedProfessional]);

  const professionalsOptions = useMemo(() => {
    return professionals.map((item) => ({
      value: String(item.id),
      label: item.firstName + ' ' + item.lastName,
      image: item.mainPhoto?.thumbnailUrl,
      specializations: item.specializations || [],
    }));
  }, [professionals]);

  const handleSelectClinic = (clinicId: string) => {
    setCurrentClinicId(clinicId);
  };

  const handleRefreshAgenda = () => {
    dispatch(setIsAddNewEvent(false));
    dispatch(setErrorOpenAgenda(false));
    dispatch(refreshAgenda());
  };

  const handleSave = () => {
    const params = {
      id: String(chosenProfessionals?.value),
      hours: {
        clinicId: String(currentClinicId),
        day: {
          date: dayjs(startDate).format('YYYY-MM-DD'),
          slots: editedOpenAgenda?.day?.slots!,
        },
      },
    };

    dispatch(addOpenAgenda(params)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        handleRefreshAgenda();
        onClose();
      }
    });
  };

  const handleEdit = () => {
    const openAgendaParams = {
      id: String(professionalId),
      customScheduleId: String(openAgenda.id),
      hoursData: {
        clinicId: String(currentClinicId),
        day: {
          date: dayjs(startDate).format('YYYY-MM-DD'),
          slots: editedOpenAgenda?.day?.slots!,
        },
      },
    };

    dispatch(editOpenAgenda(openAgendaParams)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        handleRefreshAgenda();
        onClose();
      }
    });
  };

  const handleDelete = () => {
    const params = {
      id: String(professionalId),
      customScheduleId: String(openAgenda.id),
      clinicId: String(currentClinicId),
    };

    dispatch(removeOpenAgenda(params)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        handleRefreshAgenda();
        onClose();
      }
    });
  };

  const handleSaveActions = () => {
    if (!openAgenda.day.slots.length) {
      handleSave();
    } else {
      if (editedOpenAgenda?.day?.slots?.length) {
        handleEdit();
      } else {
        handleDelete();
      }
    }
  };

  const isDisabledSaveButton = useMemo(() => {
    if (!editedOpenAgenda) return true;

    const hasChanges =
      JSON.stringify(openAgenda.day.slots) ===
      JSON.stringify(editedOpenAgenda?.day?.slots);

    return hasChanges;
  }, [openAgenda, editedOpenAgenda]);

  return (
    <Wrapper>
      <FieldsSection>
        <CurrentDate>
          <CalendarIcon />{' '}
          {dayjs(startDate).locale(i18n.language).format('dddd, D MMMM YYYY')}
        </CurrentDate>
        {!isUserRoleProfessional && !isMyAgendaPage && (
          <Dropdown
            id={'professionals'}
            label={t('clinicsConfiguration.clinics.professionals') || ''}
            placeholder={
              t('clinicsConfiguration.clinics.professionals_placeholder') || ''
            }
            value={(chosenProfessionals?.value as string) || ''}
            onChange={(value) => {
              setChosenProfessionals(value as Option);
            }}
            options={professionalsOptions}
          />
        )}
        {(isUserRoleProfessional || isMyAgendaPage) && (
          <Dropdown
            id={'hospital'}
            label={t('clinic') || ''}
            value={currentClinicId}
            onChange={(value) =>
              !Array.isArray(value) && handleSelectClinic(String(value.value))
            }
            placeholder={t('all_clinics')}
            options={clinicsOptions}
            LeftIcon={HomeIcon}
          />
        )}
        {currentClinicId && professionalId && (
          <>
            <p>{t('absenceModal.agenda_hours')}</p>
            <OpenAgendaHours />
          </>
        )}
      </FieldsSection>
      <ButtonsWrapper>
        <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
        <PrimaryButton
          onClick={handleSaveActions}
          disabled={isDisabledSaveButton || errorOpenAgenda || isLoading}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default OpenAgendaForm;
