import { createSlice } from '@reduxjs/toolkit';
import { fetchSpecializations } from './ActionCreators';
import { SpecializationsState } from './models';

const initialState: SpecializationsState = {
  specializations: [],
  isLoading: false,
  error: '',
};

const specializationsSlice = createSlice({
  name: 'specializations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSpecializations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSpecializations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchSpecializations.fulfilled, (state, action) => {
      state.specializations = action.payload;

      state.isLoading = false;
    });
  },
});

export default specializationsSlice.reducer;
