import React, { FC } from 'react';
import { MenuItems } from './components';
import { NavBarStyled } from './styles';
import { ReactComponent as Logo } from './assets/docbay-logo.svg';
const NavBar: FC = () => {
  return (
    <NavBarStyled>
      <Logo />
      <MenuItems />
    </NavBarStyled>
  );
};

export default NavBar;
