import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionPackageDto } from '@docbay/schemas';
import {
  currentClinicOwnerRegistration,
  setClinicOwnerRegistrationSessionStorage,
} from 'applicaiton/sessionStorage/clinicRegistration';
import { getSubscriptionsAction } from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';
import { setIsSubscriptionsFree } from 'applicaiton/store/reducers/ClinicRegistration/ClinicRegistrationSlice';
import { Loader, PrimaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import Package from '../Package';
import ButtonGroup from '../ButtonGroup';
import { SectionStyled, SubscriptionsWrapper } from './styles';
import { usePrivateDoctorRegistration } from 'features/feature-clinic-registration/hooks/usePrivateDoctorRegistration';

interface PackageListProps {
  setStep: (value: number) => void;
}

const PackageList: FC<PackageListProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, subscriptions } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );
  const { subscriptionPackages } = currentClinicOwnerRegistration();
  const [packages, setPackages] = useState<{
    [key: string]: SubscriptionPackageDto[];
  }>(subscriptionPackages || {});
  const [selectedPackage, setSelectedPackage] = useState('');

  const { isPrivateDoctor, setPrivateDoctor } = usePrivateDoctorRegistration();

  //TODO: ts-ignore will be removed later

  useEffect(() => {
    dispatch(getSubscriptionsAction());
  }, [dispatch]);

  const handleSetPackage = (
    key: string,
    value: number,
    selectedPackage: SubscriptionPackageDto,
  ) => {
    if (value < 0) return;
    const createArrayOfPackages = Array.from(Array(value).keys()).map(
      () => selectedPackage,
    );
    const shouldPay =
      createArrayOfPackages.some(
        (item) => Number(item.metadata.price.unitAmountDecimal) > 0,
      ) || !createArrayOfPackages.length;

    dispatch(setIsSubscriptionsFree(!shouldPay));
    setClinicOwnerRegistrationSessionStorage('subscriptionPackages', {
      ...(subscriptionPackages || {}),
      [key]: createArrayOfPackages,
    });
    setPackages((prev) => ({ ...prev, [key]: createArrayOfPackages }));
  };

  const packagesCount = Object.values(packages).flat(1).length;

  const disableButton = !packagesCount;

  useEffect(() => {
    if (!isPrivateDoctor) {
      return;
    }
    if (packagesCount > 1) {
      setClinicOwnerRegistrationSessionStorage('subscriptionPackages', {});
      setPackages({});
    }
    if (subscriptions.length === 1) {
      handleSetPackage('quantity1', 1, subscriptions[0]);
    }
  }, [isPrivateDoctor, subscriptions]);

  const handleChoosePackage = (id: string) => {
    const currentPack = subscriptions.find(
      (pack) => pack.metadata.productId === id,
    );
    setClinicOwnerRegistrationSessionStorage('subscriptionPackages', {});
    setSelectedPackage(id);
    setPackages({});

    // @ts-ignore
    setPrivateDoctor(currentPack.privateDoctor);
  };

  return (
    <SectionStyled>
      {isLoading && <Loader />}
      <SubscriptionsWrapper>
        {subscriptions.map((item, index) => (
          <Package
            key={item.metadata.productId}
            id={item.metadata.productId}
            quantity={packages[`quantity${index + 1}`]?.length || 0}
            handleSetQuantity={(value) =>
              handleSetPackage(`quantity${index + 1}`, value, item)
            }
            title={item.name}
            onClick={() => {
              handleChoosePackage(item.metadata.productId);
              // @ts-ignore
              item.privateDoctor &&
                handleSetPackage(`quantity${index + 1}`, 1, item);
            }}
            isActive={selectedPackage === item.metadata.productId}
            // @ts-ignore
            isPrivateDoctor={item.privateDoctor}
            // @ts-ignore
            packageType={item.type}
          />
        ))}
      </SubscriptionsWrapper>
      <ButtonGroup>
        <PrimaryButton onClick={() => setStep(1)} disabled={disableButton}>
          {t('clinicRegistration.continue')}
        </PrimaryButton>
      </ButtonGroup>
    </SectionStyled>
  );
};

export default PackageList;
