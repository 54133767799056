import styled from 'styled-components';

export const Wrapper = styled.section`
  display: grid;
  gap: 32px;
  grid-auto-rows: max-content;
  justify-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.grey200};
  border-radius: 12px;
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);

  > h2 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 28px;
    font-weight: 600;
    line-height: 39.2px;
  }

  > p {
    max-width: 440px;
    color: ${({ theme }) => theme.color.grey900};
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  button {
    width: fit-content;
  }

  @media screen and (max-width: 600px) {
    > h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    > p {
      font-size: 18px;
      font-weight: 500;
      line-height: 25.2px;
      padding: 0 16px;
    }
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  button:first-child {
    background-color: ${({ theme }) => theme.color.white};
  }
`;
