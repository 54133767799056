import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppointmentTypeNotificationResponseDto,
  CustomAppointmentTypeNotificationsUpdateDto,
} from '@docbay/schemas';
import {
  fetchAppointmentTypeById,
  updateAppointmentTypeNotifications,
} from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import { Loader } from 'common/components';
import LeavePageModal from 'features/feature-edit-profile-settings/component/LeavePageModal';
import NotificationItem from './NotificationItem';
import { Wrapper, TableStyled } from './styles';
import EditNotificationsModal from '../EditNotificationsModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

const Messages: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [editedNotification, setEditedNotification] =
    useState<AppointmentTypeNotificationResponseDto | null>(null);
  const { isLoading, currentAppointmentTypeChanges } = useAppSelector(
    (state) => state.appointmentTypes,
  );

  const notifications = useMemo(() => {
    if (currentAppointmentTypeChanges?.customNotifications?.length) {
      return currentAppointmentTypeChanges.customNotifications?.filter(
        (item) => item.channel === 'EMAIL',
      );
    }
  }, [currentAppointmentTypeChanges?.customNotifications, i18n.language]);

  const handleEditNotification = (type: string) => {
    const currentNotification = notifications?.find(
      (item) => item.type === type,
    );
    setEditedNotification(currentNotification || null);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditedNotification(null);
    if (showUnsavedChangesModal) {
      setShowUnsavedChangesModal(false);
      dispatch(setHasUnsavedChanges(false));
    }
  };

  const onSubmit = async (
    data: CustomAppointmentTypeNotificationsUpdateDto,
  ) => {
    if (editedNotification?.id) {
      const response = await dispatch(
        updateAppointmentTypeNotifications({
          id: editedNotification.id,
          data,
        }),
      );
      if (
        response.meta.requestStatus === 'fulfilled' &&
        currentAppointmentTypeChanges?.id
      ) {
        handleCloseModal();
        dispatch(fetchAppointmentTypeById(currentAppointmentTypeChanges.id));
        setShowUnsavedChangesModal(false);
        dispatch(setHasUnsavedChanges(false));
      }
    }
  };

  const notificationTypeKey = useMemo(() => {
    const language = getLanguageFromLocalStorage();
    return `typeToDisplay_${language.toUpperCase()}` as
      | 'typeToDisplay_EN'
      | 'typeToDisplay_PT';
  }, [i18n.language]);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <TableStyled>
        <thead>
          <tr>
            <th>
              <span>
                {t('appointment_types.notification_table.notification_type')}
              </span>
            </th>
            <th>
              <span>{t('appointment_types.notification_table.medium')}</span>
            </th>
            <th>
              <span>{t('appointment_types.notification_table.format')}</span>
            </th>
            <th>
              <span>{t('appointment_types.notification_table.action')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          {notifications?.map((notification) => (
            <NotificationItem
              key={notification.id}
              type={notification[notificationTypeKey]!}
              medium={t(notification.channel.toLowerCase())}
              handleEditNotification={handleEditNotification}
              isCustomNotification={
                !!notification.content_EN || !!notification.content_PT
              }
            />
          ))}
        </tbody>
      </TableStyled>
      {openModal && (
        <EditNotificationsModal
          opened={openModal}
          onClose={handleCloseModal}
          onSubmit={onSubmit}
          editedNotification={editedNotification}
          setShowUnsavedChangesModal={setShowUnsavedChangesModal}
        />
      )}
      <LeavePageModal
        showModal={showUnsavedChangesModal}
        onClose={() => setShowUnsavedChangesModal(false)}
        onSubmit={handleCloseModal}
      />
    </Wrapper>
  );
};

export default Messages;
