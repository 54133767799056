import React, { FC } from 'react';
import { PatientImportResultDto } from '@docbay/schemas';
import { PatientInfo, PatientList, Wrapper, WrapperItem } from './styles';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/warning-not-filled.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  data: PatientImportResultDto[];
}

const ExistingPatients: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <p>
        {data.length} {t('patient.contacts_already_exist')}
      </p>
      <PatientList>
        {data.map((item, index) => {
          return (
            <WrapperItem
              key={`existing_${item.patient.socialSecurityNumber}`}
              className={'existing'}
            >
              <div>
                <WarningIcon />
                <PatientInfo>
                  <p>
                    {index + 1}. {item.patient.firstName}{' '}
                    {item.patient.lastName}
                  </p>
                  <span>{item.patient.phone}</span>
                </PatientInfo>
              </div>
              <div>{t('patient.tab_already_exist')}</div>
            </WrapperItem>
          );
        })}
      </PatientList>
    </Wrapper>
  );
};

export default ExistingPatients;
