import styled from 'styled-components';

export const StyledSection = styled.div`
  background: ${({ theme }) => theme.color.white};
  vertical-align: middle;
  text-align: start;
  padding: 40px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  color: ${({ theme }) => theme.color.grey700};
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;

  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.color.grey200};
  }

  &:last-child {
    border-right: 1px solid ${({ theme }) => theme.color.grey200};
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      color: ${({ theme }) => theme.color.grey900};
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.05px;
      margin: 24px 0 12px 0;
    }

    p {
      color: ${({ theme }) => theme.color.grey700};
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      letter-spacing: 0.04px;
    }
  }
`;
