import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal, ButtonWrapper } from './styles';
import { Modal } from '@mui/material';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/unsuccess-icon.svg';
import { setIsHighlightedAppointment } from 'applicaiton/store/reducers/Appointments/PatientAppoinmentsSlice';
import { useAppDispatch } from 'common/hooks/redux';
import { setShowConfusingAgendaErrorModal } from 'applicaiton/store/reducers/ConfusingAgenda/ConfusingAgendaSlice';

interface SuccessModalProps {
  onClose: () => void;
  closeAppointmentModal: () => void;
  continueBooking: () => void;
}

const ErrorConfusingModal: FC<SuccessModalProps> = ({
  onClose,
  continueBooking,
  closeAppointmentModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleShowInCalendar = () => {
    closeAppointmentModal();
    dispatch(setShowConfusingAgendaErrorModal(false));
    dispatch(setIsHighlightedAppointment(true));
  };

  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <WarningIcon />
          <h6>{t('scheduler.confusing_modal.title')}</h6>
          <p>{t('scheduler.confusing_modal.text')}</p>
          <ButtonWrapper>
            <SecondaryButton onClick={continueBooking}>
              {t('scheduler.confusing_modal.continue')}
            </SecondaryButton>
            <PrimaryButton onClick={handleShowInCalendar}>
              {t('scheduler.confusing_modal.show_in_calendar')}
            </PrimaryButton>
          </ButtonWrapper>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default ErrorConfusingModal;
