import styled from 'styled-components';

export const Wrapper = styled.main`
  display: grid;
  gap: 32px;
  grid-template-rows: min-content;
  padding: 32px;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  background: ${({ theme }) => theme.color.background};
`;
