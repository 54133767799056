import { DateCalendar } from '@mui/x-date-pickers';
import styled from 'styled-components';

interface ButtonStyledProps {
  isActive: boolean;
}

export const Wrapper = styled.header`
  display: flex;
  width: 100%;
  padding: 16px 20px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  border-left: 0;
  background: ${({ theme }) => theme.color.grey50};

  > div:first-child {
    input[type='search'] {
      font-size: 14px;
      ::placeholder {
        font-size: 14px;
      }
    }
    > div {
      height: 42px;
      align-items: center;
      input {
        height: 42px;
      }
    }
    &:first-child {
      display: flex;
      align-items: center;
      width: 100%;
      > div {
        &:first-child {
          max-width: 324px;
          width: 100%;
          min-width: 150px;
        }
      }
    }

    > button {
      margin-left: 12px;
      height: 42px;
      width: fit-content;
      padding-left: 10px;
      padding-right: 10px;

      > svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  > div:last-child {
    display: flex;
    margin-left: 16px;

    > button {
      height: 42px;
      width: fit-content;
      padding-left: 8px;
      padding-right: 8px;
    }

    > div {
      max-width: 220px;
      min-width: 76px;
      margin-left: 16px;
      > section {
        min-width: 76px;
        height: 42px;
        font-size: 14px;
      }
      .optionList {
        width: 250px;
        right: 0;
        top: 100%;
        > div {
          display: inline;
          width: unset;
          font-size: 14px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
`;

export const DateCalendarStyled = styled(DateCalendar)`
  width: fit-content !important;

  .MuiPickersCalendarHeader-root.MuiPickersCalendarHeader-root {
    width: fit-content;
    padding: 0;
  }
  .MuiPickersFadeTransitionGroup-root {
    width: fit-content;
    .MuiDayCalendar-header.MuiDayCalendar-header {
      > span {
        width: 26px;
        height: 26px;
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  box-shadow: 1px 1px 2px 0 rgba(54, 54, 54, 0.1);
  margin-left: 12px;
`;

export const ButtonStyled = styled.button<ButtonStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px;
  border: none;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.blue100 : theme.color.white};
  cursor: pointer;

  color: ${({ theme }) => theme.color.grey900};
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? '700' : '500')};
  line-height: 22.4px;
  width: fit-content;

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }

  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.color.grey300};
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    border-left: 1px solid ${({ theme }) => theme.color.grey300};
    border-radius: 0 8px 8px 0;
  }
`;

export const SwitchStyled = styled.div`
  display: flex;
  margin-left: 12px;
  > p {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-right: 12px;
  }
`;

export const DaysPicker = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  margin-left: 12px;

  > button {
    display: flex;
    align-items: center;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.color.grey300};
    background: ${({ theme }) => theme.color.white};
    cursor: pointer;

    &:hover {
      background: ${({ theme }) => theme.color.grey100};
    }

    > svg {
      path {
        fill: ${({ theme }) => theme.color.grey500};
      }
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
      > svg {
        transform: rotate(180deg);
      }
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
  }

  > p {
    height: 100%;
    width: 160px;
    padding: 8px;
    border: 1px solid ${({ theme }) => theme.color.grey300};
    border-left: 0;
    border-right: 0;
    background: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.grey800};
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    text-transform: capitalize;
  }
`;
