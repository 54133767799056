import styled from 'styled-components';

interface Props {
  isError?: boolean;
}
export const Wrapper = styled.article`
  width: 100%;

  > div:first-child {
    display: grid;
    gap: 24px;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  }
`;

export const DropdownSection = styled.section`
  display: grid;
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;
`;

export const RadioButtonSection = styled.section<Props>`
  display: grid;
  width: 100%;
  gap: 12px;
  margin-bottom: 24px;

  > p {
    color: ${({ theme, isError }) =>
      isError ? theme.color.error2 : theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.error2};
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const TextAreaSection = styled.div`
  margin-bottom: 24px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;
