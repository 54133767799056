import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-pencil.svg';
import { commonColors } from 'applicaiton/theme';
import { StatusStyledProps } from './models';

interface ColorSquareProps {
  colorCode?: string;
}

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const BookAgain = styled.div`
  display: flex;
  align-items: center;

  button {
    width: max-content;
    min-width: 150px;

    &:last-child {
      margin-left: 20px;
    }
  }
`;
export const RightButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 150px;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;

    &:last-child {
      border: 1px solid ${({ theme }) => theme.color.blue800};
      color: ${({ theme }) => theme.color.blue800};
      margin-left: 20px;
    }
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;

  path {
    fill: ${({ theme }) => theme.color.grey600};
  }
`;

export const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  width: 24px;
  height: 24px;

  path {
    fill: ${({ theme }) => theme.color.blue800};
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  gap: 14px;
  padding: 16px;
  overflow-y: auto;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.color.grey800};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;

  &:last-child {
    margin-bottom: 0;
  }

  > svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 8px;

    path {
      fill: ${({ theme }) => theme.color.grey600};
    }
  }
`;

export const ColorSquare = styled.div<ColorSquareProps>`
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 4px;
  background: ${({ colorCode }) => colorCode};
  margin-right: 8px;
`;

export const AppointmentStatusStyled = styled.div`
  display: grid;
  gap: 13px;

  > p {
    color: ${commonColors.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: ${commonColors.grey200};
`;

export const StatusStyled = styled.div<StatusStyledProps>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  color: ${({ isActive }) =>
    isActive ? commonColors.blue700 : commonColors.grey700};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 8px;

    path {
      fill: ${({ isActive }) =>
        isActive ? commonColors.blue700 : commonColors.grey600};
    }
  }
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;
