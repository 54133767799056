import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { StyledTr } from './styles';

const NoDataElement: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledTr>
      <td colSpan={6}>
        <div>
          <PickIcon />
          <p>{t('waiting_list.empty_state')}</p>
        </div>
      </td>
    </StyledTr>
  );
};
export default NoDataElement;
