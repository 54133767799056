import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';
import { ReactComponent as SepaIcon } from 'applicaiton/assets/sepa.svg';
import { StyledSpan, PaymentStepStyled, SepaStyled } from './styles';
import { useAppSelector } from 'common/hooks/redux';

interface SubtitleProsp {
  step: number;
}

const Subtitle: FC<SubtitleProsp> = ({ step }) => {
  const { t } = useTranslation();
  const { isSubscriptionsFree } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );

  return (
    <>
      {step === 2 && (
        <StyledSpan>
          {t(`clinicRegistration.header.step${step}.subtitle`)}
        </StyledSpan>
      )}
      {step === 3 && (
        <StyledSpan>
          {t(`clinicRegistration.header.step${step}.subtitle`)}{' '}
          <Link to={'#'}>
            {currentClinicOwnerRegistration().personalInfo.email}
          </Link>
          .
        </StyledSpan>
      )}
      {(step === 4 || step === 5) && (
        <SepaStyled>
          <SepaIcon />
          {t(`clinicRegistration.header.step${step}.subtitle`)}
        </SepaStyled>
      )}
      {step === 6 && (
        <PaymentStepStyled>
          {isSubscriptionsFree ? (
            <>
              <h1>
                {t('clinicRegistration.photoshoot.success_registering_message')}
                !
              </h1>
              <h1>{t('clinicRegistration.photoshoot.you_can_now_log_in')}</h1>
            </>
          ) : (
            <>
              <h1>
                {t(`clinicRegistration.header.step${step}.subtitle1`)}{' '}
                <span>
                  {t(`clinicRegistration.header.step${step}.subtitle2`)}
                </span>
              </h1>
            </>
          )}
        </PaymentStepStyled>
      )}
    </>
  );
};

export default Subtitle;
