import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppointmentTypesActions } from '../../../constants/actions';
import { AxiosError } from 'axios';
import { AppointmentTypesApi } from 'integration/api/appointmentTypes';
import {
  AppointmentTypeCreationDto,
  AppointmentTypeNotificationConfigRequestDto,
  AppointmentTypeUpdateDto,
  CustomAppointmentTypeNotificationsUpdateDto,
} from '@docbay/schemas';

interface Props {
  page?: number;
  limit?: number;
  specializationIds?: string;
  clinicId?: string;
  professionalIds?: string;
  onlyProfessional?: boolean;
}

export const fetchAppointmentTypes = createAsyncThunk(
  AppointmentTypesActions.fetchAppointmentTypes,
  async (params: Props, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentTypesApi.fetchAppointmentTypes(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const postAppointmentTypes = createAsyncThunk(
  AppointmentTypesActions.postAppointmentType,
  async (params: AppointmentTypeCreationDto, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentTypesApi.postAppointmentType(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchAppointmentTypeById = createAsyncThunk(
  AppointmentTypesActions.fetchAppointmentTypeById,
  async (params: string, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentTypesApi.fetchAppointmentTypeById(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateAppointmentType = createAsyncThunk(
  AppointmentTypesActions.updateAppointmentType,
  async (
    params: { id: string; data: AppointmentTypeUpdateDto },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } =
        await AppointmentTypesApi.updateAppointmentType(params.id, params.data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteAppointmentTypes = createAsyncThunk(
  AppointmentTypesActions.deleteAppointmentType,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await AppointmentTypesApi.deleteAppointmentType(id);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateAppointmentTypeNotifications = createAsyncThunk(
  AppointmentTypesActions.updateAppointmentTypeNotifications,
  async (
    data: { id: string; data: CustomAppointmentTypeNotificationsUpdateDto },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } =
        await AppointmentTypesApi.updateAppointmentTypeNotifications(
          data.id,
          data.data,
        );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const changeAppointmentTypeNotificationsConfig = createAsyncThunk(
  AppointmentTypesActions.changeAppointmentTypeNotificationsConfig,
  async (
    data: { id: string; data: AppointmentTypeNotificationConfigRequestDto },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } =
        await AppointmentTypesApi.changeAppointmentTypeNotificationsConfig(
          data.id,
          data.data,
        );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
