import React, { useState } from 'react';
import { ActiveChatMenuProps } from './module';
import {
  ConfirmModalContentStyled,
  MenuItemStyled,
  MenuStyled,
  SuccessModalContentStyled,
} from './styles';
import { Link } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import {
  ArchiveIcon,
  CloseIcon,
  DeleteIcon,
  PersonIcon,
  UnArchiveIcon,
} from 'applicaiton/assets';
import { useConversation } from '../../hooks/useConversation';
import { ConfirmModal } from '../ConfirmModal';
import { SuccessModal } from '../SuccessModal';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'common/hooks/redux';
import { ChatsMode } from 'applicaiton/constants/chats';
import { useChats } from '../../hooks/useChats';

export const ActiveChatMenu = ({ anchorEl, onClose }: ActiveChatMenuProps) => {
  const { t } = useTranslation();

  const { selectedChat, archiveChat, deleteChat, unArchiveChat, closeTopic } =
    useConversation();

  const [deleteChatModalIsOpen, setDeleteChatModalIsOpen] =
    React.useState(false);
  const [deleteChatSuccessModalIsOpen, setDeleteChatSuccessModalIsOpen] =
    useState(false);
  const [archiveChatModalIsOpen, setArchiveChatModalIsOpen] =
    React.useState(false);
  const [unArchiveChatModalIsOpen, setUnArchiveChatModalIsOpen] =
    React.useState(false);
  const [closeTopicModalIsOpen, setCloseTopicModalIsOpen] =
    React.useState(false);
  const [archiveChatSuccessModalIsOpen, setArchiveChatSuccessModalIsOpen] =
    useState(false);
  const [unArchiveChatSuccessModalIsOpen, setUnArchiveChatSuccessModalIsOpen] =
    useState(false);
  const [closeTopicSuccessModalIsOpen, setCloseTopicSuccessModalIsOpen] =
    useState(false);

  const { setSelectedChat } = useChats();

  const menuIsOpen = !!anchorEl;

  const { mode } = useAppSelector((state) => state.chats);
  const isArchived = mode === ChatsMode.ArchivedChats;
  const isShowCloseTopicMenuItem = selectedChat?.clinicProfessionalTopic;

  const handleArchiveChat = () => {
    onClose();
    setArchiveChatModalIsOpen(false);
    if (selectedChat) {
      archiveChat(selectedChat.twilioConversationSID).then(() => {
        setArchiveChatSuccessModalIsOpen(true);
      });
    }
  };

  const handleUnArchiveChat = () => {
    onClose();
    setUnArchiveChatModalIsOpen(false);
    if (selectedChat) {
      unArchiveChat(selectedChat.twilioConversationSID).then(() => {
        setUnArchiveChatSuccessModalIsOpen(true);
      });
    }
  };

  const handleDeleteChat = () => {
    onClose();
    setDeleteChatModalIsOpen(false);
    if (selectedChat) {
      deleteChat(selectedChat.twilioConversationSID).then(() => {
        setDeleteChatSuccessModalIsOpen(true);
      });
    }
  };

  const handleCloseTopic = () => {
    onClose();
    setCloseTopicModalIsOpen(false);
    if (selectedChat) {
      closeTopic(selectedChat.twilioConversationSID);
    }
  };

  const handleCloseDeleteChatSuccessModal = () => {
    setDeleteChatSuccessModalIsOpen(false);
    setSelectedChat(null);
  };

  const handleCloseArchiveChatSuccessModal = () => {
    setArchiveChatSuccessModalIsOpen(false);
    setSelectedChat(null);
  };

  const handleCloseUnArchiveChatSuccessModal = () => {
    setUnArchiveChatSuccessModalIsOpen(false);
    setSelectedChat(null);
  };

  const handleCloseTopicSuccessModal = () => {
    setCloseTopicSuccessModalIsOpen(false);
    setSelectedChat(null);
  };

  const topicName =
    selectedChat?.clinicProfessionalTopic?.name || t('chats.no_topic');

  return (
    <>
      <MenuStyled
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuIsOpen}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItemStyled onClick={onClose}>
          <Link
            to={`${PathNames.patient}/${selectedChat?.patient?.id}`}
            target="_blank"
          >
            <PersonIcon />
            {t('chats.view_profile')}
          </Link>
        </MenuItemStyled>
        {isShowCloseTopicMenuItem && (
          <MenuItemStyled onClick={() => setCloseTopicModalIsOpen(true)}>
            <CloseIcon />
            {t('chats.close_topic')}
          </MenuItemStyled>
        )}
        {isArchived ? (
          <MenuItemStyled onClick={() => setUnArchiveChatModalIsOpen(true)}>
            <UnArchiveIcon />
            {t('chats.unArchive_chat')}
          </MenuItemStyled>
        ) : (
          <MenuItemStyled onClick={() => setArchiveChatModalIsOpen(true)}>
            <ArchiveIcon />
            {t('chats.archive_chat')}
          </MenuItemStyled>
        )}
        <MenuItemStyled onClick={() => setDeleteChatModalIsOpen(true)}>
          <DeleteIcon />
          {t('chats.delete_chat')}
        </MenuItemStyled>
      </MenuStyled>
      <ConfirmModal
        isOpen={deleteChatModalIsOpen}
        onCancel={() => setDeleteChatModalIsOpen(false)}
        onConfirm={handleDeleteChat}
      >
        <ConfirmModalContentStyled>
          {t('chats.active_chat.confirm_delete_message')}{' '}
          <span className={'topic-name'}>{topicName}</span> ?
        </ConfirmModalContentStyled>
      </ConfirmModal>

      <ConfirmModal
        isOpen={archiveChatModalIsOpen}
        onCancel={() => setArchiveChatModalIsOpen(false)}
        onConfirm={handleArchiveChat}
      >
        <ConfirmModalContentStyled>
          {t('chats.active_chat.confirm_archive_message')}{' '}
          <span className={'topic-name'}>{topicName}</span> ?
        </ConfirmModalContentStyled>
      </ConfirmModal>
      <ConfirmModal
        isOpen={unArchiveChatModalIsOpen}
        onCancel={() => setUnArchiveChatModalIsOpen(false)}
        onConfirm={handleUnArchiveChat}
      >
        <ConfirmModalContentStyled>
          {t('chats.active_chat.confirm_unarchive_message')}{' '}
          <span className={'topic-name'}>{topicName}</span> ?
        </ConfirmModalContentStyled>
      </ConfirmModal>
      <ConfirmModal
        isOpen={closeTopicModalIsOpen}
        onCancel={() => setCloseTopicModalIsOpen(false)}
        onConfirm={handleCloseTopic}
      >
        <ConfirmModalContentStyled>
          {t('chats.active_chat.confirm_close_topic')}{' '}
          <span className={'topic-name'}>{topicName}</span> ?
        </ConfirmModalContentStyled>
      </ConfirmModal>
      <SuccessModal
        isOpen={deleteChatSuccessModalIsOpen}
        onClose={handleCloseDeleteChatSuccessModal}
      >
        <SuccessModalContentStyled>
          {t('chats.active_chat.delete_success_1')}{' '}
          <span className={'topic-name'}>{topicName} </span>
          {t('chats.active_chat.delete_success_2')}
        </SuccessModalContentStyled>
      </SuccessModal>
      <SuccessModal
        isOpen={archiveChatSuccessModalIsOpen}
        onClose={handleCloseArchiveChatSuccessModal}
      >
        <SuccessModalContentStyled>
          {t('chats.active_chat.archive_success_1')}{' '}
          <span className={'topic-name'}>{topicName} </span>
          {t('chats.active_chat.archive_success_2')}
        </SuccessModalContentStyled>
      </SuccessModal>
      <SuccessModal
        isOpen={unArchiveChatSuccessModalIsOpen}
        onClose={handleCloseUnArchiveChatSuccessModal}
      >
        <SuccessModalContentStyled>
          {t('chats.active_chat.archive_success_1')}{' '}
          <span className={'topic-name'}>{topicName} </span>
          {t('chats.active_chat.unarchive_success_2')}
        </SuccessModalContentStyled>
      </SuccessModal>
      <SuccessModal
        isOpen={closeTopicSuccessModalIsOpen}
        onClose={handleCloseTopicSuccessModal}
      >
        <SuccessModalContentStyled>
          {t('chats.active_chat.topic_success_1')}{' '}
          <span className={'topic-name'}>{topicName} </span>
          {t('chats.active_chat.topic_success_2')}
        </SuccessModalContentStyled>
      </SuccessModal>
    </>
  );
};
