import styled from 'styled-components';

export const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  padding: 32px;
  background: ${({ theme }) => theme.color.background};
`;

export const DividerStyled = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.color.grey200};
`;

export const LayoutSectionStyled = styled.div`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-rows: min-content;
  padding: 32px;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  border-top: none;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);

  > hr {
    height: 1px;
    border-color: ${({ theme }) => theme.color.grey200};
  }
`;

export const StyledSection = styled.section`
  display: grid;
  grid-template-columns: max-content 1fr;
`;
