import React from 'react';
import { Wrapper, PatientItem } from './styles';
import { useAppSelector } from 'common/hooks/redux';

export const MassMessagePatientsList = () => {
  const { massMessagesPatientsIds } = useAppSelector((state) => state.chats);

  return (
    <Wrapper>
      {massMessagesPatientsIds?.map((item) => {
        return (
          <PatientItem key={item.id}>
            <p>
              {item.firstName} {item.lastName}
            </p>
            <span>{item.phone}</span>
          </PatientItem>
        );
      })}
    </Wrapper>
  );
};
