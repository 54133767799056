import React, { FC } from 'react';
import { useMenuItems } from 'features/feature-auth-navbar/hooks/useMenuItems';
import { styledTheme } from 'applicaiton/theme';
import { MenuItemStyled, MenuItemWrapper } from './styles';
import LanguageSwitcher from '../LanguageSwitcher';
import AuthMenuDropdown from '../AuthMenuDropdown';
import { useLocation } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';

const MenuItems: FC = () => {
  const steps = useMenuItems();

  const location = useLocation();
  const hideMenu =
    location.pathname === PathNames.finishRegistration ||
    PathNames.expiredVerificationCode;

  return (
    <MenuItemWrapper>
      {!hideMenu &&
        steps.map((step, index) => (
          <MenuItemStyled
            key={step.id}
            color={
              steps.length === index + 1
                ? styledTheme.color.blue300
                : styledTheme.color.white
            }
            href={step.path}
            target="_blank"
            rel="noreferrer"
          >
            {step.label}
          </MenuItemStyled>
        ))}
      {!hideMenu && <AuthMenuDropdown />}
      <LanguageSwitcher />
    </MenuItemWrapper>
  );
};

export default MenuItems;
