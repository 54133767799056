import styled from 'styled-components';
import { StyledProps } from './models';

export const StyledTr = styled.tr<StyledProps>`
  height: 72px;
  background: ${({ theme, isUnreadMessage }) =>
    isUnreadMessage ? theme.color.grey100 : theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    width: 220px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

    &:first-of-type {
      width: auto;
    }
    &:nth-of-type(2) {
      width: auto;
    }
    &:last-of-type {
      width: 150px;
    }
  }
`;

export const NameStyled = styled.div<StyledProps>`
  h2 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: ${({ isUnreadMessage }) => (isUnreadMessage ? 700 : 400)};
    line-height: 19.6px;
    margin-bottom: 2px;
  }
  p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.6px;
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  > a {
    display: flex;
    padding: 10px;
    align-items: center;
    position: absolute;
    right: 0;
    > svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }

  > button {
    position: absolute;
    left: 0;
    display: flex;
    padding: 0;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    color: ${({ theme }) => theme.color.blue800};
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }
`;
