import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormProps } from './types';
import { useNavigate } from 'react-router-dom';
import {
  Checkbox,
  Dropdown,
  DropdownClinics,
  Input,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import {
  Wrapper,
  ButtonsWrapper,
  StyledDivider,
  SelectSection,
  CheckboxSection,
  CheckboxStyled,
  ProfessionalsLabel,
  WaitingListSection,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  SearchProfessionalsByFiltersResponseDto,
  Specialization,
} from '@docbay/schemas';
import { fetchSpecializations } from 'applicaiton/store/reducers/Specializations/ActionCreators';
import { searchProfessionalsByFilters } from 'applicaiton/store/reducers/Scheduler/ActionCreators';
import { useAssigningForm } from '../../hooks/useAssigningForm';
import {
  resetAppointmentTypeChanges,
  resetProfessionals,
  setAppointmentTypeChanges,
} from 'applicaiton/store/reducers/AppointmentTypes/AppointmentTypesSlice';
import { postAppointmentTypes } from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import SuccessModal from '../SuccessModal';
import { PathNames } from 'applicaiton/routes';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { useClinicOwner } from 'common/hooks/useClinicOwner';
import { fetchClinics } from 'applicaiton/store/reducers/Clinics/ActionCreators';

const Assigning: FC<FormProps> = ({ onBackBtnClick }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isPrivateDoctor } = useClinicOwner();

  const { specializations } = useAppSelector((state) => state.specializations);
  const { isLoading, professionals, appointmentTypeChanges, isAdded } =
    useAppSelector((state) => state.appointmentTypes);
  const { clinics } = useAppSelector((state) => state.clinics);
  const [selectedProfessionals, setSelectedProfessionals] = useState<
    SearchProfessionalsByFiltersResponseDto[]
  >([]);

  const { setValue, errors, handleSubmit, getValues, watch, clearErrors } =
    useAssigningForm({
      requiredError: t('errors.required'),
    });

  useEffect(() => {
    if (appointmentTypeChanges) {
      setValue('clinicId', appointmentTypeChanges?.clinicId || '');
      setValue(
        'specializationId',
        appointmentTypeChanges?.specializationId || '',
      );
      setValue(
        'professionalIds',
        appointmentTypeChanges?.professionalIds || [],
      );
      setValue(
        'waitingListNotificationInterval',
        appointmentTypeChanges?.waitingListNotificationInterval || 30,
      );

      const addedProfessionals = professionals.filter((item) => {
        const findId = appointmentTypeChanges!.professionalIds?.find(
          (id) => id === item.id,
        );
        return !!findId;
      });

      setSelectedProfessionals(addedProfessionals);
    }
  }, [appointmentTypeChanges]);

  useEffect(() => {
    dispatch(fetchSpecializations());
  }, []);

  useEffect(() => {
    if (isPrivateDoctor) {
      dispatch(fetchClinics());
    }
  }, [isPrivateDoctor]);

  // set default clinic value for private doctor
  useEffect(() => {
    if (isPrivateDoctor) {
      handleChangeClinic(clinics[0]?.id);
    }
  }, [isPrivateDoctor, clinics]);

  const isShowWaitingList = useMemo(() => {
    if (watch('clinicId')) {
      const currentClinic = clinics.find(
        (item) => item.id === watch('clinicId'),
      );

      return currentClinic?.isWaitingListEnabled;
    }
  }, [watch('clinicId')]);

  useEffect(() => {
    if (getValues('clinicId') && getValues('specializationId')) {
      dispatch(
        searchProfessionalsByFilters({
          clinicId: getValues('clinicId'),
          onlyAvailable: false,
          specializations: [Number(getValues('specializationId'))],
        }),
      );
    } else {
      dispatch(resetProfessionals());
    }
  }, [watch('clinicId'), watch('specializationId')]);

  const specializationsOptions = useMemo(() => {
    const currentLanguage = getLanguageFromLocalStorage();
    const specializationLangKey = `name_${currentLanguage.toUpperCase()}` as
      | 'name_EN'
      | 'name_PT';

    const options = specializations.map((item) => {
      const specializationName =
        item[specializationLangKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });
    return options;
  }, [specializations, i18n.language]);

  const checkProfessionalSelected = (value: string) => {
    return selectedProfessionals.some((item) => {
      return item.id === value || getValues('professionalIds').includes(value);
    });
  };

  const handleSelectProfessional = (
    checked: boolean,
    professional: SearchProfessionalsByFiltersResponseDto,
  ) => {
    if (checked) {
      setSelectedProfessionals([...selectedProfessionals, professional]);
      setValue(
        'professionalIds',
        [...getValues('professionalIds'), String(professional.id)],
        {
          shouldValidate: !!Object.keys(errors.professionalIds || {}).length,
        },
      );
      clearErrors();
    } else {
      setSelectedProfessionals(
        selectedProfessionals.filter((item) => item.id !== professional.id),
      );
      setValue(
        'professionalIds',
        getValues('professionalIds').filter((item) => item !== professional.id),
        {
          shouldValidate: !!Object.keys(errors.professionalIds || {}).length,
        },
      );
    }
  };

  const handleBackBtn = () => {
    const {
      clinicId,
      specializationId,
      professionalIds,
      waitingListNotificationInterval,
    } = getValues();

    dispatch(
      setAppointmentTypeChanges({
        ...appointmentTypeChanges!,
        clinicId,
        specializationId,
        professionalIds,
        waitingListNotificationInterval: Number(
          waitingListNotificationInterval,
        ),
      }),
    );
    onBackBtnClick();
  };

  const handleChangeClinic = (value: string) => {
    setValue('clinicId', value);
    setValue('specializationId', '');
    setValue('professionalIds', []);
    setValue('waitingListNotificationInterval', 30);
    setSelectedProfessionals([]);
    clearErrors();
  };

  const onSubmit = () => {
    const {
      clinicId,
      specializationId,
      professionalIds,
      waitingListNotificationInterval,
    } = getValues();

    dispatch(
      postAppointmentTypes({
        ...appointmentTypeChanges!,
        clinicId,
        specializationId,
        professionalIds,
        ...(waitingListNotificationInterval > 0
          ? {
              waitingListNotificationInterval: Number(
                waitingListNotificationInterval,
              ),
            }
          : {}),
      }),
    );
  };

  const closeSuccessModal = () => {
    dispatch(resetAppointmentTypeChanges());
    navigate(PathNames.typesAppointments);
  };

  return (
    <Wrapper>
      <SelectSection>
        {!isPrivateDoctor ? (
          <DropdownClinics
            value={getValues('clinicId')}
            label={t('clinic')!}
            placeholder={t('professional_profile.select_clinic')!}
            onChange={(value) => {
              handleChangeClinic(String(value));
            }}
            isMulti={false}
            isError={!!errors.clinicId?.message}
            errorMessage={errors.clinicId?.message}
          />
        ) : (
          <></>
        )}
        {isShowWaitingList && (
          <>
            <StyledDivider />
            <WaitingListSection>
              <div>
                <Input
                  id={'waitingListNotificationInterval'}
                  type={'number'}
                  label={
                    t('appointment_types.waiting_list_notification_interval') ||
                    ''
                  }
                  value={String(watch('waitingListNotificationInterval'))}
                  onChange={(e) => {
                    setValue(
                      'waitingListNotificationInterval',
                      Number(e.target.value),
                    );
                  }}
                />
                <span>{t('mins')}</span>
              </div>
            </WaitingListSection>
            <StyledDivider />
          </>
        )}

        <Dropdown
          id={'specializationId'}
          label={t('appointment_types.assign_for_professionals')!}
          value={getValues('specializationId')}
          placeholder={t('appointment_types.specializations_placeholder')}
          onChange={(value) => {
            if (!Array.isArray(value)) {
              setValue('specializationId', String(value.value), {
                shouldValidate: !!errors.specializationId?.message,
              });
            }
          }}
          options={specializationsOptions}
          withSearch={true}
          isMulti={false}
          errorMessage={errors.specializationId?.message}
        />
      </SelectSection>
      {!!professionals.length && (
        <>
          <ProfessionalsLabel isError={!!errors.professionalIds}>
            {selectedProfessionals.length} professionals assigned
          </ProfessionalsLabel>
          <CheckboxSection>
            {professionals?.map((professional) => (
              <CheckboxStyled
                key={professional.id}
                isActive={checkProfessionalSelected(professional.id!)}
              >
                <Checkbox
                  id={professional.id!}
                  checked={checkProfessionalSelected(professional.id!)}
                  onChange={(checked) =>
                    handleSelectProfessional(checked, professional)
                  }
                  label={`${professional.firstName} ${professional.lastName}`}
                />
              </CheckboxStyled>
            ))}
          </CheckboxSection>
        </>
      )}

      <StyledDivider />
      <ButtonsWrapper>
        <SecondaryButton onClick={handleBackBtn}>{t('back')}</SecondaryButton>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={!!Object.keys(errors).length || isLoading}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonsWrapper>

      {isAdded && (
        <SuccessModal
          typeName={appointmentTypeChanges.title}
          onClose={closeSuccessModal}
        />
      )}
    </Wrapper>
  );
};

export default Assigning;
