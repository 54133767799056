import {
  ClinicChainSearchResponseDto,
  ClinicOwnerCreationDto,
  ClinicOwnerResponseDto,
  ClinicOwnerStaffListResponseDto,
  ClinicOwnerUpdateDto,
  CommonEntitiesListResponse,
  ConfirmSignUpDto,
  PaymentDto,
  ClinicOwnerPermissionDto,
  ClinicOwnerPermissionResponseDto,
  ApplyPromocodeDto,
  ApplyPromocodeResponseDto,
  InvoiceResponseDto,
  ClinicOwnerSubscriptionResponseDto,
} from '@docbay/schemas';
import axiosInstance from 'integration/api/axiosInstance';
import axiosAuthInstance from './axiosAuthInstance';
import {
  fetchClinicOwnerStaffProps,
  searchClinicsInChainProps,
} from 'common/types/clinicOwner';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

export const ClinicOwnerAPI = {
  clinicOwnerSignUp: (data: ClinicOwnerCreationDto) => {
    const language = getLanguageFromLocalStorage();
    return axiosAuthInstance('').post<ClinicOwnerResponseDto>(
      '/clinic-owner/sign-up',
      {
        ...data,
        language,
      },
    );
  },
  clinicOwnerConfirmSignUp: (data: ConfirmSignUpDto) =>
    axiosAuthInstance('').post('/clinic-owner/confirm-sign-up', data),
  getClinicOwner: () => axiosInstance.get('/clinic-owner'),
  clinicOwnerPayments: (data: PaymentDto, token: string) =>
    axiosAuthInstance(token).post<InvoiceResponseDto>(
      '/clinic-owner/payment',
      data,
    ),
  getClinicOwnerById: (id: string) =>
    axiosInstance.get(
      `/clinic-owner/${id}?include=clinics,photo,user,professional`,
    ),
  updateClinicOwner: (id: string, data: ClinicOwnerUpdateDto) =>
    axiosInstance.patch(`/clinic-owner/${id}`, data),
  deleteClinicOwner: (id: string) =>
    axiosInstance.delete(`/clinic-owner/${id}`),
  getClinicsByIdOwner: (clincId: string) =>
    axiosInstance.get(`/clinic-owner/${clincId}/clinics`),
  resendConfirmationCode: (email: string) => {
    const language = getLanguageFromLocalStorage();
    return axiosAuthInstance('').post(
      '/clinic-owner/resend-confirmation-code',
      {
        email,
        language,
      },
    );
  },
  searchClinicsInChain: ({
    entityType,
    entityId,
    searchItem,
    page,
    limit,
  }: searchClinicsInChainProps) => {
    const params = {
      includeDeleted: 'false',
      include: 'owner,address',
      searchEntityType: entityType,
      searchEntityId: entityId,
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(searchItem ? { search: searchItem } : {}),
    };
    return axiosInstance.get<
      CommonEntitiesListResponse<ClinicChainSearchResponseDto>
    >('/clinic-owner/search-clinics-in-chain', {
      params,
    });
  },
  getPermissionsByClinicOwnerId: (id: string) =>
    axiosInstance.get<ClinicOwnerPermissionResponseDto[]>(
      `/clinic-owner/${id}/permissions`,
    ),
  updatePermissionsByClinicOwnerId: (
    id: string,
    data: ClinicOwnerPermissionDto,
  ) => axiosInstance.put(`/clinic-owner/${id}/permissions`, data),
  fetchClinicOwnerStaff: ({
    page,
    limit,
    clinicIds,
    userTypes,
  }: fetchClinicOwnerStaffProps) => {
    const params = {
      includeDeleted: 'false',
      include: 'photo',
      ...(userTypes?.length ? { userTypes } : {}),
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(clinicIds?.length ? { clinicIds } : {}),
    };

    return axiosInstance.get<ClinicOwnerStaffListResponseDto>(
      '/clinic-owner/staff',
      {
        params,
      },
    );
  },
  applyClinicOwnerPromocode: (data: ApplyPromocodeDto, token: string) =>
    axiosAuthInstance(token).post<ApplyPromocodeResponseDto>(
      '/clinic-owner/apply-promocode',
      data,
    ),
  getSubscriptions: () => {
    return axiosInstance.get<ClinicOwnerSubscriptionResponseDto>(
      'clinic-owner/subscription',
    );
  },
  getCustomerPortalLink: () => {
    return axiosInstance.get('clinic-owner/billing');
  },
  getCustomerBillingLink: (id: string) => {
    return axiosInstance.get(`clinic-owner/${id}/payment/account-status`);
  },
  getCustomerBillingUpdateLink: () => {
    return axiosInstance.get('clinic-owner/payment/account-update-link');
  },
  getCustomerBillingActivationLink: () => {
    return axiosInstance.get('clinic-owner/payment/account-activation-link');
  },
  getPhotoPackage: (token: string) => {
    return axiosAuthInstance(token).get('clinic-owner/request-photo-package');
  },
  getAccountStatus: (id: string) => {
    return axiosInstance.get(`/clinic-owner/${id}/payment/account-status`);
  },
  deleteCOAccount: (id: string) => {
    return axiosInstance.delete(`/clinic-owner/${id}`);
  },
};
