import { ClinicResponseDto } from '@docbay/schemas';

export const checkIsPageActive = (
  index: number,
  profileData: ClinicResponseDto | null,
) => {
  switch (index) {
    case 0: {
      return !!profileData?.name && !!profileData.description;
    }
    case 1: {
      return (
        !!profileData?.address?.zipCode &&
        !!profileData?.address?.streetNumber &&
        !!profileData?.address?.country
      );
    }
    case 2: {
      const phoneExist = profileData?.contacts?.some(
        (item) => item.type === 'Phone',
      );
      const emailExist = profileData?.contacts?.some(
        (item) => item.type === 'Email',
      );
      return !!phoneExist && !!emailExist;
    }
    case 4: {
      return !!profileData?.openingHours?.length;
    }
    default:
      return true;
  }
};
