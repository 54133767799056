import React, { FC, useMemo } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { StepperStyled, DivStyled } from './styles';
import StepIcon from './StepIcon';
import { useSteps } from 'features/feature-stepper-aside/hooks/useSteps';
import { useAppSelector } from 'common/hooks/redux';

interface RegisterStepperProsp {
  activeStep: number;
}

const RegisterStepper: FC<RegisterStepperProsp> = ({ activeStep }) => {
  const steps = useSteps();
  const { isSubscriptionsFree } = useAppSelector(
    (state) => state.clinicRegistrationSlice,
  );

  const stepsList = useMemo(() => {
    if (isSubscriptionsFree) {
      return steps.filter(
        (item) =>
          item.id !== 'billing-information' && item.id !== 'payment-process',
      );
    }
    return steps;
  }, [steps, isSubscriptionsFree]);

  return (
    <DivStyled>
      <StepperStyled />
      <Stepper orientation="vertical" activeStep={activeStep}>
        {stepsList.map((step) => (
          <Step key={step.id}>
            <StepLabel StepIconComponent={StepIcon}>
              <span>{step.label}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </DivStyled>
  );
};

export default RegisterStepper;
