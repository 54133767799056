import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { getPatientById } from 'applicaiton/store/reducers/Patients/ActionCreators';
import { useMemo } from 'react';
import { clearError as clearErrorAction } from 'applicaiton/store/reducers/Patients/PatientsSlice';

export const usePatientProfile = () => {
  const dispatch = useAppDispatch();
  const { currentPatient, errorCode } = useAppSelector(
    (state) => state.patients,
  );

  const fetchPatientById = (patientId: string) => {
    dispatch(getPatientById(String(patientId)));
  };

  const patientIsNotFound = useMemo(() => {
    const codes = ['404', '403'];
    return codes.includes(String(errorCode)) && currentPatient === null;
  }, [currentPatient, errorCode]);

  const clearError = () => {
    dispatch(clearErrorAction());
  };

  return {
    currentPatient,
    fetchPatientById,
    patientIsNotFound,
    clearError,
  };
};
