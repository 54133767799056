import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const AsideStyled = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 400px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.color.menu};
  padding: 32px;
}
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > a {
    margin-bottom: 64px;
  }
`;

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  color:  ${({ theme }) => theme.color.white};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;
  text-decoration: none;

  & > svg {
    margin-right: 4px;
    path {
      fill: ${({ theme }) => theme.color.white};
    }
  }
}
`;
