import React, { FC, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { PathNames } from 'applicaiton/routes';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getUserRole } from 'applicaiton/sessionStorage/auth';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { ViewBy } from 'applicaiton/constants/scheduler';
import { ReactComponent as HomeIcon } from 'applicaiton/assets/home.svg';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/warning.svg';

import {
  DatePicker,
  Dropdown,
  Input,
  PrimaryButton,
  RadioButton,
  SecondaryButton,
  TimePicker,
} from 'common/components';
import DropdownAppointmentTypes from 'common/components/DropdownAppointmentTypes';
import { AppointmentTypeOption } from 'common/components/DropdownAppointmentTypes/models';
import { useAppSelector } from 'common/hooks/redux';
import {
  addMinutes,
  getDateInDefaultTimezone,
  updateDateAndTime,
} from 'common/helpers/dateTimeHelper';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { AppointmentStatus } from 'common/types/appointmentStatus';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';
import Switch from 'common/components/Switch';
import { convertTimeZone } from 'common/helpers/convertTimeZone';

import { checkValidDate } from 'features/feature-agenda-reserve-slots-modal/helpers/checkValidDate';
import { ProfessionalOption } from 'features/feature-agenda-reserve-slots-modal/containers/AppointmentForm/models';
import { useAppointmentStatus } from 'features/feature-agenda-reserve-slots-modal/hooks/useAppointmentStatus';
import AppointmentTypeDeletedBanner from '../AppointmentTypeDeletedBanner';

import { AppointmentClinicFormProps } from './models';
import {
  Wrapper,
  ButtonSection,
  FieldsSection,
  DateSection,
  TimePickerWrapper,
  ConfusingStyled,
  AppointmentStatusStyled,
  DividerStyled,
  RadioButtonWrapper,
  CheckboxWrapper,
  SwitchStyled,
} from './styles';

const AppointmentClinicForm: FC<AppointmentClinicFormProps> = ({
  watch,
  setValue,
  clearErrors,
  errors,
  appointmentStartDate,
  setAppointmentStartDate,
  appointmentEndDate,
  setAppointmentEndDate,
  appointmentType,
  setAppointmentType,
  chosenProfessionals,
  setChosenProfessionals,
  currentClinicId,
  setCurrentClinicId,
  hasConfusingAgendas,
  documentLoading,
  isCreateAppointmentValid,
  onSubmit,
  clinicOwnerIdBySelectedClinic,
  setClinicOwnerIdBySelectedClinic,
  hasUnsavedChanges,
  onClose,
  setShowLeaveModal,
  isDirtyPatient,
  setDefaultAppointmentTime,
  setAddToWaitingList,
  addToWaitingList,
}) => {
  const userRole = getUserRole();
  const location = useLocation();
  const { t } = useTranslation();
  const { viewBy, byAvailability, byProfessionals, professionals } =
    useAppSelector((state) => state.scheduler);
  const { currentAppointment, mode } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const { professionals: professionalsForCurrentAppointment } = useAppSelector(
    (state) => state.professionals,
  );
  const { editedProfessional } = useAppSelector(
    (state) => state.professionalProfile,
  );
  const { clinics } = useAppSelector((state) => state.clinics);
  const appointmentStatuses = useAppointmentStatus();
  const { tz } = useUserTimeZone();

  const [appointmentTypeDeleted, setAppointmentTypeDeleted] = useState(false);

  const isWaitingListPage = location.pathname.includes(PathNames.waitingList);
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;
  const isUserRoleProfessional = userRole === UserRoles.professional;
  const isEditMode = mode === ReserveSlotsMode.EditAppointment;
  const isCreateMode =
    mode === ReserveSlotsMode.CreateAppointment ||
    mode === ReserveSlotsMode.BookAgain;

  const currentViewData = useMemo(() => {
    return viewBy === ViewBy.byProfessionals ? byProfessionals : byAvailability;
  }, [viewBy, byAvailability, byProfessionals]);

  const canCreateAppointments = userHasPermission(
    UserPermissions.createAppointment,
    Number(clinicOwnerIdBySelectedClinic),
    isMyAgendaPage,
  );

  const professionalsOptions = useMemo(() => {
    return professionalsForCurrentAppointment.map((item) => ({
      value: String(item.id),
      label: item.firstName + ' ' + item.lastName,
      image: item.mainPhoto?.thumbnailUrl,
      specializations: item.specializations || [],
    }));
  }, [professionals, professionalsForCurrentAppointment]);

  const clinicsOptions = useMemo(() => {
    if (!editedProfessional?.clinicsRelations?.length) return [];
    return editedProfessional?.clinicsRelations
      .filter((item) => item.clinic?.status === 'Active')
      .map((item) => ({
        label: String(item?.clinic?.name),
        value: String(item?.clinic?.id),
      }));
  }, [editedProfessional]);

  const currentSpecializationIds = useMemo(() => {
    const specializationIds = chosenProfessionals?.specializations
      ?.map((item) => item.id)
      .join();
    return specializationIds || '';
  }, [chosenProfessionals]);

  const isStartDateValid = useMemo(() => {
    return checkValidDate(
      dayjs(new Date()).toDate(),
      dayjs(appointmentStartDate).toDate(),
    );
  }, [appointmentStartDate]);

  const isEndDateValid = useMemo(() => {
    return checkValidDate(
      dayjs(appointmentStartDate).toDate(),
      dayjs(appointmentEndDate).toDate(),
    );
  }, [appointmentStartDate, appointmentEndDate]);

  const isDateValid = useMemo(() => {
    return isStartDateValid && isEndDateValid;
  }, [isStartDateValid, isEndDateValid]);

  const minAppointmentEndTime = useMemo(() => {
    const startDateInTz = getDateInDefaultTimezone(appointmentStartDate);
    return startDateInTz.add(5, 'minute');
  }, [appointmentStartDate]);

  const deleteAppointmentType = () => {
    setAppointmentType(null);
    setValue('appointmentTypeId', '');
    setAppointmentTypeDeleted(!!currentAppointment?.appointmentType?.id);
  };

  const handleSelectClinic = (clinicId: string) => {
    const selectedClinic = editedProfessional?.clinicsRelations?.find(
      (relation) => relation.clinic?.id === clinicId,
    );
    setClinicOwnerIdBySelectedClinic(selectedClinic?.clinic?.owner?.id || null);
    setCurrentClinicId(clinicId);
    setAppointmentType(null);
    setValue('appointmentTypeId', '');
  };

  const handleChangeAppointmentType = (currentValue: AppointmentTypeOption) => {
    setAppointmentType(currentValue);
    setValue('appointmentTypeId', String(currentValue.value));
    clearErrors('appointmentTypeId');
    appointmentTypeDeleted && setAppointmentTypeDeleted(false);
    if (appointmentStartDate) {
      const duration: number = currentValue?.duration
        ? +currentValue?.duration
        : 0;
      const endDateAppointment = addMinutes(appointmentStartDate, duration);
      setAppointmentEndDate(dayjs.tz(endDateAppointment).format() as any);
    }
  };

  const getDayjsFromDateForTimePicker = (date: Date | null) => {
    const dayInTz = dayjs.tz(date, tz);
    return dayInTz.isUTC() ? dayInTz : dayjs(date).tz();
  };

  const checkValidDateString = (dateString: string) => {
    const dateToCheck = dayjs(dateString);
    if (!dateToCheck.isValid()) {
      return false;
    }
    return dateToCheck.year() >= 1900;
  };

  const formatDateStringInDefaultTimezone = (date: string) => {
    return dayjs(date).tz().format();
  };

  const handleChangeStartDate = (value: string) => {
    if (!checkValidDateString(value)) {
      return;
    }
    const newDate = formatDateStringInDefaultTimezone(value).split('T')[0];
    if (appointmentStartDate) {
      const updateDateTime = updateDateAndTime(appointmentStartDate, newDate);
      setAppointmentStartDate(updateDateTime as any);
    } else {
      setAppointmentStartDate(dayjs(value).tz().format() as any);
    }
    if (appointmentEndDate) {
      const updateDateTime = updateDateAndTime(appointmentEndDate, newDate);
      setAppointmentEndDate(updateDateTime as any);
    }
  };

  const handleChangeDate = (value: string) => {
    const isValidDate = dayjs(value).isValid();
    if (isValidDate) {
      setAppointmentStartDate(formatDateStringInDefaultTimezone(value) as any);
      if (appointmentType) {
        const duration: number = appointmentType?.duration
          ? +appointmentType?.duration
          : 0;
        const endDateAppointment = addMinutes(
          new Date(formatDateStringInDefaultTimezone(value)),
          duration,
        );
        setAppointmentEndDate(dayjs.tz(endDateAppointment).format() as any);
      }
    }
  };

  const handleChangeEndDate = (value: string) => {
    const isValidDate = dayjs(value).isValid();
    if (isValidDate) {
      setAppointmentEndDate(dayjs(value).tz().format() as any);
    }
  };

  const handleClose = () => {
    const { patientId } = watch();
    if (patientId && (isDirtyPatient || hasUnsavedChanges)) {
      setShowLeaveModal(true);
    } else {
      onClose();
    }
  };

  const isSaveButtonDisabled = useMemo(() => {
    if (isEditMode) {
      return !hasUnsavedChanges || !isDateValid || documentLoading;
    }
    return !!Object.keys(errors).length || !isDateValid || documentLoading;
  }, [
    hasUnsavedChanges,
    isDateValid,
    documentLoading,
    isCreateAppointmentValid,
    appointmentType,
    errors,
  ]);

  const startDateMinTime = useMemo(() => {
    const isSameDay =
      dayjs(dayjs(appointmentStartDate).tz()).format('DD') ===
      dayjs().tz().format('DD');

    if (isSameDay) {
      return dayjs.tz(dayjs().toDate(), tz);
    } else {
      return null;
    }
  }, [appointmentStartDate]);

  const minStartDate = new Date(
    new Date(new Date().setHours(dayjs().tz().get('hour'))).setDate(
      dayjs().tz().get('date'),
    ),
  ).valueOf();

  const isWaitingListEnabled = useMemo(() => {
    if (isWaitingListPage)
      return currentAppointment?.clinic?.isWaitingListEnabled;

    const currentClinic = clinics.find((item) => item.id === currentClinicId);

    return (
      currentClinic?.isWaitingListEnabled ||
      currentAppointment?.clinic?.isWaitingListEnabled
    );
  }, [currentClinicId, isWaitingListPage, clinics, currentAppointment]);

  return (
    <Wrapper>
      <FieldsSection>
        {!isUserRoleProfessional && !isMyAgendaPage && (
          <Dropdown
            id={'professionals'}
            label={'Professionals'}
            placeholder={'Select Professional'}
            value={(chosenProfessionals?.value as string) || ''}
            onChange={(value) => {
              setChosenProfessionals(value as ProfessionalOption);
            }}
            options={professionalsOptions}
            disabled={isEditMode}
          />
        )}
        {(isUserRoleProfessional || isMyAgendaPage) && (
          <Dropdown
            id={'hospital'}
            label={t('clinic') || ''}
            value={currentClinicId}
            onChange={(value) =>
              !Array.isArray(value) && handleSelectClinic(String(value.value))
            }
            placeholder={t('all_clinics')}
            options={clinicsOptions}
            LeftIcon={HomeIcon}
          />
        )}
        {appointmentTypeDeleted && <AppointmentTypeDeletedBanner />}
        <DropdownAppointmentTypes
          label={t('appointment_types.appointment_type')!}
          value={appointmentType as AppointmentTypeOption}
          specializationIds={currentSpecializationIds}
          onChange={(value) => {
            const currentValue = value as AppointmentTypeOption;
            clearErrors('appointmentTypeId');
            handleChangeAppointmentType(currentValue);
          }}
          clinicId={currentClinicId || currentViewData.clinicId}
          professionalId={
            chosenProfessionals?.value
              ? String(chosenProfessionals?.value)
              : null
          }
          withSearch
          isError={!!errors?.appointmentTypeId?.message}
          errorMessage={errors?.appointmentTypeId?.message}
          disabled={!currentClinicId}
          setIsAppointmentDeleted={deleteAppointmentType}
        />
        <DateSection>
          <DatePicker
            id={'dateStart'}
            label={t('date') || ''}
            format={'YYYY-MM-DDTHH:mm'}
            value={dayjs(
              convertTimeZone(new Date(appointmentStartDate || ''), tz),
            ).format('YYYY-MM-DDTHH:mm')}
            onChange={(value) => {
              handleChangeStartDate(value);
            }}
            maxDate={new Date(
              new Date().setFullYear(new Date().getFullYear() + 3),
            ).getTime()}
            minDate={minStartDate}
            isError={!isStartDateValid}
          />
          <TimePickerWrapper>
            <label>{t('time_start')}</label>
            <TimePicker
              key="startDate"
              defaultValue={dayjs(appointmentStartDate)}
              value={getDayjsFromDateForTimePicker(appointmentStartDate)}
              onChange={(value) => {
                handleChangeDate(value || '');
              }}
              minTime={startDateMinTime}
              disableIgnoringDatePartForTimeValidation={false}
              isError={false}
              onOpen={setDefaultAppointmentTime}
            />
          </TimePickerWrapper>
          <TimePickerWrapper>
            <label>{t('time_end')}</label>
            <TimePicker
              key="endDate"
              defaultValue={dayjs(appointmentEndDate)}
              value={getDayjsFromDateForTimePicker(appointmentEndDate)}
              onChange={(value) => {
                handleChangeEndDate(value || '');
              }}
              minTime={minAppointmentEndTime}
              disableIgnoringDatePartForTimeValidation={false}
              disabled={!appointmentType?.value}
              isError={false}
            />
          </TimePickerWrapper>
        </DateSection>
        {hasConfusingAgendas && (
          <ConfusingStyled>
            <WarningIcon />
            {t('patient.confusing')}
          </ConfusingStyled>
        )}
        <Input
          id="comments"
          label={t('comments') || ''}
          type="text"
          value={watch('comment')}
          placeholder={t('comments_placeholder') || ''}
          onChange={(value) => {
            setValue('comment', value.target.value);
          }}
        />
        {isWaitingListEnabled && (
          <CheckboxWrapper>
            <label>{t('appointment_types.waiting_list')}</label>
            <SwitchStyled>
              <Switch
                checked={addToWaitingList}
                onChange={(value) => {
                  setAddToWaitingList(value);
                }}
              />
              <p>{t('appointment_types.add_to_waiting_list')}</p>
            </SwitchStyled>
          </CheckboxWrapper>
        )}
        <DividerStyled />
        <AppointmentStatusStyled>
          <p>{t('appointment.appointment_status')}</p>
          {appointmentStatuses.map((status) => (
            <RadioButtonWrapper key={status.value}>
              <RadioButton
                id={status.value}
                checked={watch('status') === status.value}
                label={status.label}
                onChange={() => {
                  setValue('status', status.value);
                }}
              />
              {watch('status') === status.value &&
                status.value === AppointmentStatus.JUSTIFIED_ABSENCE && (
                  <Input
                    id="statusComments"
                    label={t('appointment.status_comment_label') || ''}
                    type="text"
                    value={watch('statusComment')}
                    placeholder={
                      t('appointment.status_comment_placeholder') || ''
                    }
                    onChange={(value) => {
                      setValue('statusComment', value.target.value);
                    }}
                  />
                )}
            </RadioButtonWrapper>
          ))}
        </AppointmentStatusStyled>
      </FieldsSection>
      <ButtonSection>
        <SecondaryButton
          onClick={handleClose}
          type="button"
          disabled={documentLoading}
        >
          {t('cancel')}
        </SecondaryButton>
        {((isCreateMode && canCreateAppointments) || isEditMode) && (
          <PrimaryButton onClick={onSubmit} disabled={isSaveButtonDisabled}>
            {t('save')}
          </PrimaryButton>
        )}
      </ButtonSection>
    </Wrapper>
  );
};

export default AppointmentClinicForm;
