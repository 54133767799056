import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccesslIcon } from 'applicaiton/assets/success-icon.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { useAppDispatch } from 'common/hooks/redux';
import {
  setIsCreatedAppointment,
  resetSlotsDetails,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { SuccessAppointmentCreatedProps } from './models';

const SuccessAppointmentCreated: FC<SuccessAppointmentCreatedProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setIsCreatedAppointment(false));
    dispatch(resetSlotsDetails());
    onClose();
  };

  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={handleClose} />
        <div>
          <SuccesslIcon />
          <p>{t('appointment.created')}</p>
          <SecondaryButton onClick={handleClose}>
            {t('appointment_types.modal_success.ok')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessAppointmentCreated;
