import React from 'react';

import { ReactComponent as LoginIcon } from 'applicaiton/assets/login.svg';

import { ImageContentWrapper, ImageAuth } from './styles';

const AuthImage = () => {
  return (
    <ImageContentWrapper>
      <ImageAuth borderRadius={'0'}>
        <LoginIcon />
      </ImageAuth>
    </ImageContentWrapper>
  );
};

export default AuthImage;
