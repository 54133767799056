import React, { FC } from 'react';
import { Wrapper, StyledSection } from './styles';
import PersonalInfo from '../PersonalInfo';

const LayoutComponent: FC = () => {
  return (
    <Wrapper>
      <StyledSection>
        <PersonalInfo />
      </StyledSection>
    </Wrapper>
  );
};

export default LayoutComponent;
