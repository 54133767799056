import styled from 'styled-components';

export const Wrapper = styled.article`
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-rows: min-content;
  padding: 32px;
  gap: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  border-top: none;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);

  > hr {
    height: 1px;
    border-color: ${({ theme }) => theme.color.grey200};
  }
`;

export const StyledSection = styled.section`
  display: grid;
  grid-template-columns: max-content 1fr;
`;
