import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CheckVerificationCodeT } from '../types';

export const useVerificationForm = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const createPasswordSchema = yup.object({
    code: yup.number().required(requiredError!),
    captchaToken: yup.string(),
  });

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<CheckVerificationCodeT>({
    resolver: yupResolver(createPasswordSchema),
    mode: 'onChange',
  });

  return {
    register,
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
  };
};
