import React from 'react';

import { Container } from './styles';
import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';

interface Props {
  title: string;
  onClick: () => void;
}

const AddAnother = ({ title, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <PlusIcon />
      <p>{title}</p>
    </Container>
  );
};

export default AddAnother;
