import React, { FC } from 'react';
import { Wrapper } from './styles';
import { AppointmentTypeEdit, SideMenu } from 'features';

const AppointmentTypeAddPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <AppointmentTypeEdit />
    </Wrapper>
  );
};

export default AppointmentTypeAddPage;
