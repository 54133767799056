import { RestartIcon } from 'applicaiton/assets';
import styled from 'styled-components';

interface Props {
  isError: boolean;
}

interface IconProps {
  isPending: boolean;
}

export const DropdownSection = styled.section`
  display: grid;
  gap: 24px;

  > div {
    width: 464px;
  }
`;

export const DropdownList = styled.section<Props>`
  display: grid;
  width: 492px;

  > label {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.08px;
    margin-bottom: 6px;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error2 : theme.color.grey600};
  }

  .optionList {
    top: 44px;
  }

  button {
    height: 100%;
    display: flex;
    border: none;
    background: none;
    padding: 0;
    color: ${({ theme }) => theme.color.grey600};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: 0.07px;
    cursor: pointer;

    > svg {
      width: 20px;
      height: 20px;
      cursor: pointer;

      g {
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
      > svg {
        g {
          path {
            fill: ${({ theme }) => theme.color.grey400};
          }
        }
      }
      > h2 {
        color: ${({ theme }) => theme.color.grey400};
      }
    }
  }
`;

export const AppointmentTypesWrapper = styled.div`
  position: relative;
`;

export const StyledRestartIcon = styled(RestartIcon)<IconProps>`
  @keyframes rotate {
    to {
      transform: rotate(-360deg);
    }
  }

  position: absolute;
  right: -30px;
  bottom: 20px;
  cursor: pointer;
  animation: ${({ isPending }) =>
    isPending ? 'rotate 1.5s linear infinite' : 'none'};

  path {
    fill: ${({ theme }) => theme.color.blue800};
  }
`;

export const Specializations = styled.div`
  display: grid;

  > div {
    display: flex;
    margin-bottom: 12px;
    button {
      > svg {
        margin-left: 8px;
        cursor: pointer;
      }
    }
    > div {
      width: 464px;
    }
  }
`;
