import { ClinicContactUpdateDto } from '@docbay/schemas';

export const formatProfileContact = (
  item: ClinicContactUpdateDto,
): ClinicContactUpdateDto => {
  const isPhoneType = item.type === 'Phone' || item.type === 'EmergencyPhone';
  if (isPhoneType) {
    return {
      ...item,
      contactDetail: formatPhoneNumber(item.contactDetail),
    };
  }
  return item;
};

export const formatPhoneNumber = (number?: string) =>
  number && number[0] === '+' ? number : '+' + number;
