import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { Dropdown, DropdownClinics } from 'common/components';
import { FilterSectionProps } from './modules';
import {
  ControllerSection,
  FilterSectionStyled,
  ItemList,
  ResetButton,
} from './styles';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import {
  UserType,
  useUserTypeOptions,
} from 'features/feature-users-list/hooks/useUserTypeOptions';
import { searchEntityType } from 'features/feature-scheduler/constants/searchEntityType';
import { UserRoles } from 'applicaiton/constants/userRoles';

const FilterSection: FC<FilterSectionProps> = ({
  clinics,
  setClinics,
  userType,
  setUserType,
  handleRemoveClinic,
}) => {
  const { t } = useTranslation();
  const userId = getUserId();
  const userTypeOptions = useUserTypeOptions();
  const isUserProfessional = getUserRole() === UserRoles.professional;

  const handleClearAll = () => {
    setClinics([]);
    setUserType('');
  };

  return (
    <FilterSectionStyled>
      <ControllerSection>
        <DropdownClinics
          value={clinics}
          onChange={(values) => {
            Array.isArray(values) && setClinics(values);
          }}
          isMulti={true}
          clinicOwnerId={userId || ''}
          entityType={
            isUserProfessional
              ? searchEntityType.professional
              : searchEntityType.clinicOwner
          }
        />
        <div>
          <Dropdown
            id={'userType'}
            value={userType}
            placeholder={t('adminsListConfiguration.users_type')}
            onChange={(element) => {
              if (!Array.isArray(element)) {
                setUserType(element.value as UserType);
              }
            }}
            options={userTypeOptions}
          />
        </div>
      </ControllerSection>
      {clinics.length || userType ? (
        <ItemList>
          {clinics.length ? <h2>{t('from_clinics')}:</h2> : null}

          {clinics.length
            ? clinics.map((clinic) => (
                <div key={clinic.value}>
                  <p>{clinic.label}</p>
                  <button
                    onClick={() => handleRemoveClinic(String(clinic.value))}
                  >
                    <CloseIcon />
                  </button>
                </div>
              ))
            : null}

          {userType && (
            <div key={userType}>
              {userType}

              <button onClick={() => setUserType('')}>
                <CloseIcon />
              </button>
            </div>
          )}
          <ResetButton onClick={handleClearAll}>{t('clear_all')}</ResetButton>
        </ItemList>
      ) : (
        ''
      )}
    </FilterSectionStyled>
  );
};

export default FilterSection;
