import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Checkbox,
  Dropdown,
  DropdownClinics,
  Input,
  PrimaryButton,
} from 'common/components';
import {
  Wrapper,
  ButtonsWrapper,
  StyledDivider,
  SelectSection,
  CheckboxSection,
  CheckboxStyled,
  ProfessionalsLabel,
  WaitingListSection,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  SearchProfessionalsByFiltersResponseDto,
  Specialization,
} from '@docbay/schemas';
import { fetchSpecializations } from 'applicaiton/store/reducers/Specializations/ActionCreators';
import { searchProfessionalsByFilters } from 'applicaiton/store/reducers/Scheduler/ActionCreators';
import { useAssigningForm } from '../../hooks/useAssigningForm';
import { updateAppointmentType } from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { resetProfessionals } from 'applicaiton/store/reducers/AppointmentTypes/AppointmentTypesSlice';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

const Assigning: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { specializations } = useAppSelector((state) => state.specializations);
  const { isLoading, professionals, currentAppointmentTypeChanges } =
    useAppSelector((state) => state.appointmentTypes);
  const { clinics } = useAppSelector((state) => state.clinics);
  const [selectedProfessionals, setSelectedProfessionals] = useState<
    SearchProfessionalsByFiltersResponseDto[]
  >([]);

  const { setValue, errors, handleSubmit, getValues, watch, clearErrors } =
    useAssigningForm({
      requiredError: t('errors.required'),
    });

  useEffect(() => {
    if (currentAppointmentTypeChanges) {
      setValue('clinicId', currentAppointmentTypeChanges?.clinic!.id! || '');
      setValue(
        'specializationId',
        currentAppointmentTypeChanges?.specialization!.id! || '',
      );
      const professionalIds =
        currentAppointmentTypeChanges?.professionals!.map((item) => item.id) ||
        [];

      setValue('professionalIds', (professionalIds as string[]) || []);
      setValue(
        'waitingListNotificationInterval',
        currentAppointmentTypeChanges?.notificationConfig
          ?.waitingListNotificationInterval || 30,
      );

      const addedProfessionals = professionals.filter((item) => {
        const findId = currentAppointmentTypeChanges!.professionals!.find(
          (professional) => professional.id === item.id,
        );
        return !!findId;
      });

      setSelectedProfessionals(addedProfessionals);
    }
  }, [currentAppointmentTypeChanges]);

  useEffect(() => {
    if (professionals.length) {
      const addedProfessionals = professionals.filter((item) => {
        const findId = currentAppointmentTypeChanges!.professionals!.find(
          (professional) => professional.id === item.id,
        );
        return !!findId;
      });

      setSelectedProfessionals(addedProfessionals);
    }
  }, [professionals]);

  const isShowWaitingList = useMemo(() => {
    if (watch('clinicId')) {
      const currentClinic = clinics.find(
        (item) => item.id === watch('clinicId'),
      );

      return currentClinic?.isWaitingListEnabled;
    }
  }, [watch('clinicId')]);

  useEffect(() => {
    dispatch(fetchSpecializations());
  }, []);

  useEffect(() => {
    if (getValues('clinicId') && getValues('specializationId')) {
      dispatch(
        searchProfessionalsByFilters({
          clinicId: getValues('clinicId'),
          onlyAvailable: false,
          specializations: [Number(getValues('specializationId'))],
        }),
      );
    } else {
      dispatch(resetProfessionals());
    }
  }, [watch('clinicId'), watch('specializationId')]);

  const specializationsOptions = useMemo(() => {
    const currentLanguage = getLanguageFromLocalStorage();
    const specializationLangKey = `name_${currentLanguage.toUpperCase()}` as
      | 'name_EN'
      | 'name_PT';

    const options = specializations.map((item) => {
      const specializationName =
        item[specializationLangKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });
    return options;
  }, [specializations, i18n.language, currentAppointmentTypeChanges]);

  const checkProfessionalSelected = (value: string) => {
    return selectedProfessionals.some((item) => {
      return item.id === value || getValues('professionalIds').includes(value);
    });
  };

  const handleSelectProfessional = (
    checked: boolean,
    professional: SearchProfessionalsByFiltersResponseDto,
  ) => {
    if (checked) {
      setSelectedProfessionals([...selectedProfessionals, professional]);
      setValue('professionalIds', [
        ...getValues('professionalIds'),
        String(professional.id),
      ]);
      clearErrors();
    } else {
      setSelectedProfessionals(
        selectedProfessionals.filter((item) => item.id !== professional.id),
      );
      setValue(
        'professionalIds',
        getValues('professionalIds').filter((item) => item !== professional.id),
      );
    }
  };

  const handleChangeClinic = (value: string) => {
    setValue('clinicId', value);
    setValue('specializationId', '');
    setValue('professionalIds', []);
    setSelectedProfessionals([]);
    clearErrors();
  };

  const isChanged = useMemo(() => {
    const {
      clinicId,
      specializationId,
      professionalIds,
      waitingListNotificationInterval,
    } = getValues();

    if (!currentAppointmentTypeChanges) return false;
    const allProfessionalIds =
      currentAppointmentTypeChanges?.professionals!.map((item) => item.id);

    if (
      currentAppointmentTypeChanges?.clinic?.id !== clinicId ||
      currentAppointmentTypeChanges?.specialization?.id !== specializationId ||
      allProfessionalIds.length !== professionalIds.length ||
      waitingListNotificationInterval !==
        currentAppointmentTypeChanges?.notificationConfig
          .waitingListNotificationInterval
    ) {
      dispatch(setHasUnsavedChanges(true));
      return true;
    } else {
      const hasChanges = compareObjectsChanges(
        {
          clinicId: currentAppointmentTypeChanges!.clinic!.id,
          specializationId: currentAppointmentTypeChanges.specialization!.id,
          professionalIds: allProfessionalIds,
          waitingListNotificationInterval:
            currentAppointmentTypeChanges?.notificationConfig
              .waitingListNotificationInterval,
        },
        {
          ...getValues(),
        },
        true,
      );

      dispatch(setHasUnsavedChanges(hasChanges));

      return hasChanges;
    }
  }, [
    watch('clinicId'),
    watch('specializationId'),
    watch('professionalIds'),
    watch('waitingListNotificationInterval'),
  ]);

  const onSubmit = () => {
    const {
      clinicId,
      specializationId,
      professionalIds,
      waitingListNotificationInterval,
    } = getValues();

    dispatch(
      updateAppointmentType({
        id: id!,
        data: {
          durationInMinutes: Number(
            currentAppointmentTypeChanges!.durationInMinutes,
          ),
          clinicId,
          specializationId,
          professionalIds,
          ...(waitingListNotificationInterval > 0
            ? {
                waitingListNotificationInterval: Number(
                  waitingListNotificationInterval,
                ),
              }
            : {}),
        },
      }),
    );
  };

  return (
    <Wrapper>
      <SelectSection>
        <DropdownClinics
          value={getValues('clinicId')}
          label={t('clinic')!}
          placeholder={t('professional_profile.select_clinic')!}
          onChange={(value) => {
            handleChangeClinic(String(value));
          }}
          isMulti={false}
          isError={!!errors.clinicId?.message}
          errorMessage={errors.clinicId?.message}
        />
        {isShowWaitingList && (
          <>
            <StyledDivider />
            <WaitingListSection>
              <div>
                <Input
                  id={'waitingListNotificationInterval'}
                  type={'number'}
                  label={
                    t('appointment_types.waiting_list_notification_interval') ||
                    ''
                  }
                  value={String(watch('waitingListNotificationInterval'))}
                  onChange={(e) => {
                    setValue(
                      'waitingListNotificationInterval',
                      Number(e.target.value),
                    );
                  }}
                />
                <span>{t('mins')}</span>
              </div>
            </WaitingListSection>
            <StyledDivider />
          </>
        )}
        <Dropdown
          id={'specializationId'}
          label={t('appointment_types.assign_for_professionals')!}
          value={getValues('specializationId')}
          placeholder={t('appointment_types.specializations_placeholder')}
          onChange={(value) => {
            if (!Array.isArray(value)) {
              setValue('specializationId', String(value.value), {
                shouldValidate: !!errors.specializationId?.message,
              });
            }
          }}
          options={specializationsOptions}
          withSearch={true}
          isMulti={false}
          errorMessage={errors.specializationId?.message}
        />
      </SelectSection>
      {!!professionals.length && (
        <>
          <ProfessionalsLabel isError={!!errors.professionalIds}>
            {selectedProfessionals.length} professionals assigned
          </ProfessionalsLabel>
          <CheckboxSection>
            {professionals?.map((professional) => (
              <CheckboxStyled
                key={professional.id}
                isActive={checkProfessionalSelected(professional.id!)}
              >
                <Checkbox
                  id={professional.id!}
                  checked={checkProfessionalSelected(professional.id!)}
                  onChange={(checked) =>
                    handleSelectProfessional(checked, professional)
                  }
                  label={`${professional.firstName} ${professional.lastName}`}
                />
              </CheckboxStyled>
            ))}
          </CheckboxSection>
        </>
      )}

      <StyledDivider />
      <ButtonsWrapper>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={!!Object.keys(errors).length || !isChanged || isLoading}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default Assigning;
