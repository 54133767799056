import React from 'react';
import { ModalStyled } from './styles';
import { ConfirmModalProps } from './module';
import Modal from '@mui/material/Modal';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';

export const ConfirmModal = ({
  isOpen,
  onConfirm,
  onCancel,
  children,
}: ConfirmModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen}>
      <ModalStyled>
        {children}
        <div className={'actions-wrapper'}>
          <SecondaryButton onClick={onCancel}>{t('cancel')}</SecondaryButton>
          <PrimaryButton onClick={onConfirm} styleType={'error'}>
            {t('confirm')}
          </PrimaryButton>
        </div>
      </ModalStyled>
    </Modal>
  );
};
