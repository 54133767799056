import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubscriberItem from '../SubscriberItem';
import { TableStyled, Wrapper } from './styles';
import NoDataElement from '../NoDataElement';
import { Loader, Pagination } from 'common/components';
import { Option as ClinicOption } from 'common/components/DropdownClinics/models';
import FilterSection from '../FilterSection';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchProfessionalsSubscribers } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';

const defaultLimit = 10;

const SubscribersTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userRole = getUserRole();
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);

  const [currentPage, setCurrentPage] = useState(1);
  const [clinics, setClinics] = useState<ClinicOption[]>([]);

  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const { patientsSubscribers, subscribersTotalPages, isLoading } =
    useAppSelector((state) => state.professionalProfile);

  const userId = useMemo(() => {
    return isClinicOwnerRole
      ? currentClinicOwner?.professional?.id
      : getUserId();
  }, [currentClinicOwner]);

  useEffect(() => {
    const clinicIds = clinics.map((clinic) => String(clinic.value)).join();

    const params = {
      limit: defaultLimit,
      page: currentPage,
      ...(clinics.length ? { clinicIds: String(clinicIds)! } : {}),
    };
    if (userId) {
      dispatch(fetchProfessionalsSubscribers({ id: userId, params }));
    }
  }, [dispatch, currentPage, clinics, userId]);

  return (
    <>
      <Wrapper>
        {isLoading && <Loader />}
        <FilterSection clinics={clinics} setClinics={setClinics} />
        <TableStyled>
          <thead>
            <tr>
              <th>
                <span>{t('subscriptions.patient')}</span>
              </th>
              <th>
                <span>{t('subscriptions.subscription_package')}</span>
              </th>
              <th>
                <span>{t('subscriptions.cost')}</span>
              </th>
              <th>
                <span>{t('subscriptions.for_whom')}</span>
              </th>
              <th>
                <span>{t('subscriptions.actions')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {patientsSubscribers?.map((patient) => (
              <SubscriberItem
                key={patient.patient.id}
                patient={patient}
                currentPage={currentPage}
              />
            ))}
            {!patientsSubscribers?.length && <NoDataElement />}
          </tbody>
        </TableStyled>
        {patientsSubscribers.length > 1 || subscribersTotalPages > 1 ? (
          <Pagination
            pages={subscribersTotalPages}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            itemCount={patientsSubscribers.length}
          />
        ) : (
          ''
        )}
      </Wrapper>
    </>
  );
};

export default SubscribersTable;
