import { createAsyncThunk } from '@reduxjs/toolkit';
import { MapBox } from 'applicaiton/constants/actions';
import { fetchAllLocations } from 'integration/api/mapBox';
import { AxiosError } from 'axios';
import { FetchLocationParams } from 'common/types/mapBox';

export const fetchLocations = createAsyncThunk(
  MapBox.fetchLocation,
  async (params: FetchLocationParams, { rejectWithValue }) => {
    try {
      const { data } = await fetchAllLocations(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
