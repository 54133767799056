import { useContext } from 'react';
import { ChatsContext } from '../context/ChatsContext';

export const useChatsContext = () => {
  const context = useContext(ChatsContext);
  if (!context)
    throw new Error('ChatsContext  must be use inside ChatsContextProvider');
  return {
    ...context,
  };
};
