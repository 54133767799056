import { PatientsApi } from 'integration/api/patients';

export const useDownloadCSVTemplate = () => {
  const downloadDocument = async () => {
    try {
      const response = await PatientsApi.downloadCSVTemplate();

      const contentDisposition = response?.headers['content-disposition'];
      let fileName = 'downloaded_file';
      if (contentDisposition) {
        const matches = /filename="([^"]+)"/.exec(contentDisposition);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      const downloadUrl = window.URL.createObjectURL(
        new Blob([response?.data]),
      );
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.log('Download document error: ', e);
    }
  };

  return downloadDocument;
};
