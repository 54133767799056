import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Loader } from 'common';
import { PathNames } from './constants';
import { getCurrentRoutes, publicRouts } from './routes';
import { LeavePageModal } from 'features';
import { ProtectedRoute } from './ProtectedRoute';
import CommonErrorModal from 'common/components/CommonErrorModal';
import { useAppSelector } from 'common/hooks/redux';
import { RoutesProps } from 'common/types/routes';
import { loginAllTabs, logoutAllTabs } from 'common/auth/authService';
import { useChatsClient } from 'features/feature-chats/hooks/useChatsClient';
import { usePushNotifications } from 'features/feature-chats/hooks/usePushNotifications';
import { getProfilesFromSessionStorage } from 'applicaiton/sessionStorage/auth';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

export const AppRouter: FC = () => {
  const { isErrorModalVisible } = useAppSelector((state) => state.error);
  const { tz } = useUserTimeZone();
  const { isAuth } = useAppSelector((state) => state.users);
  const { initChatClient, shutdownClient, currentClient } = useChatsClient();
  const { initPushNotifications } = usePushNotifications();
  const [routes, setRoutes] = useState<RoutesProps[]>(getCurrentRoutes());
  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const profiles = getProfilesFromSessionStorage();
  dayjs.tz.setDefault(tz);

  useEffect(() => {
    const routesList = getCurrentRoutes();
    setRoutes(routesList);
  }, [
    isAuth,
    currentClinicOwner?.professional,
    currentClinicOwner?.professional?.id,
  ]);

  useEffect(() => {
    logoutAllTabs();
    loginAllTabs();
  }, []);
  // Client initialization force to router to have the information about the availavility status and push notifications.
  useEffect(() => {
    if (!isAuth && !profiles) {
      return;
    }
    initChatClient();
    return () => {
      shutdownClient();
    };
  }, [isAuth, currentClinicOwner?.professional]);

  useEffect(() => {
    if (currentClient) {
      initPushNotifications(currentClient);
    }
  }, [currentClient]);

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, component }) => (
          <Route
            path={path}
            element={<ProtectedRoute>{component}</ProtectedRoute>}
            key={path}
          />
        ))}
        {publicRouts.map(({ path, component }) => (
          <Route path={path} element={component} key={path} />
        ))}
        <Route path="/*" element={<Navigate to={PathNames.login} />} />
      </Routes>
      <Loader />
      <LeavePageModal />
      {isErrorModalVisible && <CommonErrorModal isOpen={isErrorModalVisible} />}
    </BrowserRouter>
  );
};
