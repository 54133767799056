import { TimePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';

interface StyledProps {
  isError: boolean;
}

export const Wrapper = styled.div<StyledProps>`
  > div {
    & .MuiInputBase-root {
      border: 1px solid
        ${({ theme, isError }) =>
          isError ? theme.color.error2 : theme.color.grey300};
      background: ${({ theme, isError }) =>
        isError ? theme.color.error3 : theme.color.white};
    }
  }
`;

export const TimePickerStyled = styled(TimePicker)`
  width: 116px;
  height: 44px;

  & .MuiInputBase-root {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.color.grey300};
    background: ${({ theme }) => theme.color.white};
    box-shadow: 1px 1px 2px 0px rgba(54, 54, 54, 0.1);
    padding: 10px 14px;

    &.Mui-error {
      border: 1px solid ${({ theme }) => theme.color.error2};
      background: ${({ theme }) => theme.color.error3};
    }

    &.Mui-disabled {
      border: 1px solid ${({ theme }) => theme.color.grey300};
      background: ${({ theme }) => theme.color.grey50};
    }

    & .Mui-disabled {
      color: ${({ theme }) => theme.color.grey300};
    }

    .MuiInputBase-input {
      padding: 0;
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22.4px;
      letter-spacing: 0.08px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`;
