import styled from 'styled-components';

interface Props {
  error: boolean;
}

export const ProfessionalsList = styled.div`
  display: grid;
  gap: 12px;
  grid-template-rows: repeat();

  > h2 {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
  }

  > div {
    display: flex;
    width: 100%;

    > button:last-child {
      display: flex;
      cursor: pointer;
      border: none;
      background: none;
      padding: 0;
      margin-left: 8px;

      > svg {
        width: 20px;
        height: 20px;
        margin-right: 4px;
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }
  }
  > button {
    height: fit-content;
    width: fit-content;
    box-shadow: none;
    border: none;
    padding: 0;

    &:hover {
      background-color: unset;
    }

    > svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }
`;

export const ProfessionalItem = styled.div`
  display: flex;
  width: 464px;
  min-height: 62px;
  align-items: center;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${({ theme }) => theme.color.blueDark100};
    margin-right: 8px;
    color: ${({ theme }) => theme.color.white};
  }
  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
  }
`;

export const ProfessionalInfo = styled.div`
  > h2 {
    color: ${({ theme }) => theme.color.grey900};
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > p {
      color: ${({ theme }) => theme.color.grey600};
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      margin-right: 4px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

export const ProfessionalsDropdownSection = styled.div<Props>`
  display: grid;
  gap: 6px;
  width: 464px;

  > div {
    width: 464px;
  }

  > label {
    color: ${({ theme, error }) =>
      error ? theme.color.error2 : theme.color.grey600};
    font-size: 16px;
    line-height: 22.4px;
    font-family: 'Mulish';
    font-weight: 500;
    letter-spacing: 0.08px;
  }
`;
