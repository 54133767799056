import styled from 'styled-components';

interface StyledProps {
  isActive: boolean;
}

interface Props {
  isError: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 150px;

    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const SelectSection = styled.div`
  width: 464px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  > div {
    width: 464px;
  }
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const ProfessionalsLabel = styled.div<Props>`
  display: block;
  color: ${({ theme, isError }) =>
    isError ? theme.color.error2 : theme.color.grey600};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-top: 24px;
  margin-bottom: 12px;
`;

export const CheckboxSection = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 272px;
  overflow-y: auto;
`;

export const CheckboxStyled = styled.div<StyledProps>`
  > div {
    input + label:before {
      width: 16px;
      height: 16px;
    }
    input + label:after {
      width: 18px;
      height: 18px;
      top: -1px;
    }
    &:first-child {
      margin-top: 8px;
    }
    > label {
      color: ${({ theme }) => theme.color.grey800};
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
      letter-spacing: 0.07px;
    }
  }
`;

export const WaitingListSection = styled.section`
  display: grid;
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;
  grid-template-columns: calc(75% - 12px) calc(25% - 12px);

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    > span {
      font-family: Mulish;
      margin-bottom: 16px;
      margin-left: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: ${({ theme }) => theme.color.grey600};
    }
  }

  input {
    width: 100%;
  }
`;
