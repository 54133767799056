import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SearchConfusingAgendaDto } from '@docbay/schemas';
import { AgendaAPI } from 'integration/api/agenda';
import { ConfusingAgendasActions } from '../../../constants/actions';

export const fetchConfusingAgenda = createAsyncThunk(
  ConfusingAgendasActions.fetchConfusingAgenda,
  async (
    data: { data: SearchConfusingAgendaDto; isOnSave: boolean },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await AgendaAPI.fetchConfusingAgenda(
        data.data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
