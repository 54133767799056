export enum SupportedCountries {
  Portugal = 'PT',
  France = 'FR',
}

export interface SupportedCountriesDto {
  code: string;
  id: string;
  name: string;
}
