import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as HomeIcon } from 'applicaiton/assets/home.svg';
import {
  ClinicImage,
  DropdownStyled,
  OptionCheckBox,
  OptionsList,
  Wrapper,
} from './styles';
import Checkbox from '../Checkbox';
import { DropdownClinicsProps, Option } from './models';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  fetchClinics,
  searchClinicsInChain,
} from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { Loader } from 'common/loader';
import { searchEntityType } from 'features/feature-scheduler/constants/searchEntityType';

const DropdownClinics: FC<DropdownClinicsProps> = ({
  value,
  label,
  placeholder,
  onChange,
  disabled,
  isMulti,
  isError,
  errorMessage,
  isAllDataReturn = false,
  clinicOwnerId,
  notIncludingClinics = [],
  entityType = searchEntityType.clinicOwner,
  filterByFirstCOId = false,
  hideByClinicCount,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { clinics, clinicsByOwnerId, isLoading } = useAppSelector(
    (state) => state.clinics,
  );

  const [currentClinicOwnerId, setCurrentClinicOwnerId] = useState('');

  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  useEffect(() => {
    if (clinicOwnerId) {
      dispatch(
        searchClinicsInChain({
          entityType: entityType,
          entityId: clinicOwnerId,
        }),
      );
    } else {
      dispatch(fetchClinics());
    }
  }, []);

  const optionList = useMemo(() => {
    if (!clinics || !clinicsByOwnerId) return [];
    if (clinicOwnerId) {
      return clinicsByOwnerId.map((clinic) => ({
        label: clinic.name!,
        value: clinic.id!,
        image: clinic.mainPhoto?.thumbnailUrl,
      }));
    } else {
      const filteredClinics = clinics
        .filter(
          (clinic) =>
            !notIncludingClinics.some(
              (notIncludedClinic) => notIncludedClinic.id === clinic.id,
            ),
        )
        .map((clinic) => ({
          label: clinic.name!,
          value: clinic.id!,
          image: clinic.mainPhoto?.thumbnailUrl,
          address: clinic.address,
          clinicOwnerId: clinic.owner!.id,
          ...(filterByFirstCOId &&
            value.length && {
              isHidden: clinic.owner!.id !== currentClinicOwnerId,
            }),
        }));
      return filteredClinics;
    }
  }, [
    clinics,
    clinicsByOwnerId,
    clinicOwnerId,
    notIncludingClinics,
    currentClinicOwnerId,
  ]);

  const handleChange = (selectedItem: Option) => {
    if (!isMulti) {
      onChange(isAllDataReturn ? selectedItem : String(selectedItem.value));
      setIsActive(false);
    }

    if (Array.isArray(value) && isMulti) {
      const valueAlreadyAdded = value.some(
        (item) => item.value === selectedItem.value,
      );
      if (valueAlreadyAdded) {
        const filteredValue = value.filter((item) => {
          return item.value !== selectedItem.value;
        });

        if (value.length === 1) {
          setCurrentClinicOwnerId('');
        }

        onChange(filteredValue);
      } else {
        if (!value.length) {
          setCurrentClinicOwnerId(selectedItem.clinicOwnerId!);
        }

        onChange([...value, selectedItem]);
      }
    }
    return;
  };

  const IsValueChecked = (item: Option) => {
    if (!isMulti) return String(value) === String(item.value);
    //checking if checkbox checked
    const isValueArray = Array.isArray(value);
    return isValueArray && value.some((i) => i.value === item.value);
  };

  const getValue = () => {
    if (isMulti) return `${value.length} ${t('selected')}`;
    const currentOption = optionList.find(
      (item) => String(item.value) === String(value),
    );
    return currentOption?.label;
  };

  if (hideByClinicCount && clinics.length <= hideByClinicCount) {
    return <></>;
  }

  return (
    <Wrapper isError={isError}>
      {label && <label>{label}</label>}
      <DropdownStyled
        isError={isError}
        isActive={isActive}
        disabled={disabled}
        ref={ref}
      >
        <section onMouseDown={() => !disabled && setIsActive((prev) => !prev)}>
          {value?.length ? (
            <h2>{getValue()}</h2>
          ) : (
            <span>{placeholder || t('all_clinics')}</span>
          )}
          <ExpandMoreIcon />
        </section>
        {isActive && (
          <OptionsList className="clinics-list">
            {!isLoading && <Loader />}
            {isActive &&
              optionList.map((item: Option) => (
                <OptionCheckBox
                  htmlFor={String(item.value)}
                  key={item.value}
                  selected={IsValueChecked(item!)}
                  isMulti={isMulti}
                  isHidden={item.isHidden}
                >
                  <div>
                    <ClinicImage>
                      {item.image ? (
                        <img src={item.image} alt={item.label} />
                      ) : (
                        <HomeIcon />
                      )}
                    </ClinicImage>
                    <p>{item.label}</p>
                  </div>
                  <Checkbox
                    id={String(item.value)}
                    checked={IsValueChecked(item)}
                    onChange={() => handleChange(item)}
                  />
                </OptionCheckBox>
              ))}
          </OptionsList>
        )}
      </DropdownStyled>
      {errorMessage && <span>{errorMessage}</span>}
    </Wrapper>
  );
};

export default DropdownClinics;
