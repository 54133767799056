import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';
import { useLanguage } from 'common/hooks/useLanguage';
import { SupportedCountries } from 'common/types/countries';

export const usePersonalInfo = () => {
  const { language } = useLanguage();

  const code = currentClinicOwnerRegistration().personalInfo.supportedCountry
    .code
    ? currentClinicOwnerRegistration().personalInfo.supportedCountry.code
    : language === 'en'
    ? SupportedCountries.Portugal
    : language;

  const personalInfo = {
    firstName: currentClinicOwnerRegistration().personalInfo.firstName,
    lastName: currentClinicOwnerRegistration().personalInfo.lastName,
    jobPosition: currentClinicOwnerRegistration().personalInfo.jobPosition,
    email: currentClinicOwnerRegistration().personalInfo.email,
    phone: currentClinicOwnerRegistration().personalInfo.phone,
    supportedCountry: {
      ...currentClinicOwnerRegistration().personalInfo.supportedCountry,
      code: code.toUpperCase(),
    },
  };

  return personalInfo;
};
