import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 664px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  overflow-y: auto;
  max-height: 85vh;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  color: ${({ theme }) => theme.color.grey900};
  font-family: AcidGrotesk-Medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.2px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.color.grey700};
  }
`;

export const SubscriptionInfo = styled.div`
  display: grid;
  padding: 32px;
  gap: 24px;
`;

export const Row = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding: 32px;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
  justify-content: flex-end;

  button {
    width: 150px;

    &:last-child {
      margin-left: 20px;
    }
  }
`;

export const RadioButtonSection = styled.section`
  display: grid;
  width: 100%;
  gap: 12px;

  > p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.error2};
  }

  > div {
    display: flex;
    align-items: center;

    div:last-child {
      margin-left: 16px;
    }
  }
`;
