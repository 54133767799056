import { useAppDispatch, useAppSelector } from './redux';
import {
  getIsUserPrivateDoctor,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { useState } from 'react';
import { getCustomerBillingLink } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';

export const useClinicOwner = () => {
  const dispatch = useAppDispatch();
  const { currentClinicOwner, isLoading } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const userRole = getUserRole();
  const isUserRoleClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const [isPaymentAccountActive, setIsPaymentAccountActive] = useState(false);

  const updatePaymentAccountStatus = async () => {
    if (currentClinicOwner?.id) {
      await dispatch(getCustomerBillingLink(currentClinicOwner.id!)).then(
        (data) => {
          if (data.meta.requestStatus === 'fulfilled') {
            setIsPaymentAccountActive(data.payload);
          }
        },
      );
    }
  };

  const isPrivateDoctor = getIsUserPrivateDoctor();
  return {
    isLoading,
    isUserRoleClinicOwner,
    isPrivateDoctor,
    isPaymentAccountActive,
    updatePaymentAccountStatus,
  };
};
