import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > p {
    margin-top: 32px;
    color: ${({ theme }) => theme.color.grey900};
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }
`;
