import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPatientAppointmentsBrief } from './ActionCreators';
import { InitialState } from './models';
import { AppointmentBriefResponseDto } from '@docbay/schemas';

const initialState: InitialState = {
  isLoading: false,
  appointments: [],
  isHighlighted: false,
  currentAppointment: null,
  error: '',
};

const patientAppointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setCurrentAppointment: (
      state,
      actions: PayloadAction<AppointmentBriefResponseDto | null>,
    ) => {
      state.currentAppointment = actions.payload;
    },
    setIsHighlightedAppointment: (state, actions: PayloadAction<boolean>) => {
      state.isHighlighted = actions.payload;
    },
    resetPatientAppointmentsData: (state) => {
      state.appointments = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPatientAppointmentsBrief.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPatientAppointmentsBrief.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(
      fetchPatientAppointmentsBrief.fulfilled,
      (state, action) => {
        state.appointments = action.payload;
        state.isLoading = false;
      },
    );
  },
});

export const {
  resetPatientAppointmentsData,
  setCurrentAppointment,
  setIsHighlightedAppointment,
} = patientAppointmentsSlice.actions;

export default patientAppointmentsSlice.reducer;
