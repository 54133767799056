import React, { FC } from 'react';
import { Wrapper } from './styles';
import { UsersList, SideMenu, SubscriptionsLimitModal } from 'features';

const UsersPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />
      <UsersList />
      <SubscriptionsLimitModal />
    </Wrapper>
  );
};

export default UsersPage;
