import { createAsyncThunk } from '@reduxjs/toolkit';
import { uploadPhoto } from 'integration/api/photo';
import { PhotoActions } from 'applicaiton/constants/actions';
import { AxiosError } from 'axios';

export const uploadNewPhoto = createAsyncThunk(
  PhotoActions.uploadPhoto,
  async (data: FormData, { rejectWithValue }) => {
    try {
      const response = await uploadPhoto(data);
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
