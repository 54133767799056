import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ReactComponent as ClockIcon } from 'applicaiton/assets/clock.svg';
import { ReactComponent as HelpIcon } from 'applicaiton/assets/help.svg';
import { SecondaryButton } from 'common/components';
import { useAppSelector } from 'common/hooks/redux';
import {
  HeaderStyled,
  DividerStyled,
  InfoSection,
  SupportButton,
  Title,
} from './styles';
import { DocBaySupportLink } from 'applicaiton';

const Header: FC = () => {
  const { t } = useTranslation();
  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const patientFullName = `${currentAppointment?.patient?.firstName} ${currentAppointment?.patient?.lastName}`;
  const startEventTime = dayjs(currentAppointment?.startDate).format('HH:mm');
  const endEventTime = dayjs(currentAppointment?.endDate).format('HH:mm');
  const eventTime = `${startEventTime} - ${endEventTime}`;

  return (
    <HeaderStyled>
      <InfoSection>
        <Title>
          {currentAppointment ? (
            <>
              <h2>{t('teleconsultation.teleconsultation_with')}</h2>
              <h2>{patientFullName}</h2>
            </>
          ) : (
            <h2>{t('appointment_types.teleconsultation')}</h2>
          )}
        </Title>
        {!!currentAppointment && (
          <div>
            <ClockIcon />
            <p>{eventTime}</p>
          </div>
        )}
      </InfoSection>
      <DividerStyled />
      <SupportButton to={DocBaySupportLink} target="_blank">
        <SecondaryButton styleType={'color'}>
          <HelpIcon />
          {t('support')}
        </SecondaryButton>
      </SupportButton>
    </HeaderStyled>
  );
};

export default Header;
