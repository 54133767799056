import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input,
  InputPhone,
  PhotoUploader,
  PrimaryButton,
} from 'common/components';
import {
  Wrapper,
  ButtonsWrapper,
  FieldsSection,
  StyledDivider,
} from './styles';
import { FormProps } from './types';
import { useSecretaryInfoForm } from '../../hooks/useSecretaryInfoForm';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setSecretaryData } from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { checkUsersEmailExisting } from 'applicaiton/store/reducers/Users/ActionCreators';
import { UserResponseFindByEmailDto } from '@docbay/schemas/dist/user/dto/user-response-find-by-email.dto';

const ProfileInfo: FC<FormProps> = ({ onNextBtnClick }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { register, errors, handleSubmit, watch, setValue, setError } =
    useSecretaryInfoForm({
      requiredError: t('errors.required'),
      phoneError: t('invalid_phone_number'),
    });

  const { uploadedPhotos } = useAppSelector((state) => state.photos);
  const { profileChanges } = useAppSelector((state) => state.secretaries);

  const isStateChanged = useMemo(() => {
    const hasChanges =
      !!watch('firstName') ||
      !!watch('lastName') ||
      !!watch('email') ||
      !!watch('phone');

    return hasChanges;
  }, [watch('firstName'), watch('lastName'), watch('email'), watch('phone')]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  useEffect(() => {
    if (profileChanges) {
      setValue('firstName', profileChanges.firstName);
      setValue('lastName', profileChanges.lastName);
      setValue('email', profileChanges.email);
      setValue('phone', profileChanges.phone || '');
    }
  }, [profileChanges]);

  const onSubmit = async () => {
    const response = await dispatch(checkUsersEmailExisting(watch('email')));
    const payload = response.payload as UserResponseFindByEmailDto;

    const isUserAlreadyExist =
      response.meta.requestStatus === 'fulfilled' && payload?.isExist;
    if (isUserAlreadyExist) {
      return setError('email', {
        message: t('errors.doctor_is_already_exists') || '',
      });
    }

    const secretaryData = {
      firstName: watch('firstName'),
      lastName: watch('lastName'),
      email: watch('email'),
      phone: watch('phone'),
      photo: uploadedPhotos[0],
      clinics: profileChanges?.clinics || [],
    };

    await dispatch(setSecretaryData(secretaryData));

    await onNextBtnClick();
  };

  return (
    <Wrapper>
      <FieldsSection>
        <Input
          id="email"
          label={t('email') || ''}
          type="email"
          placeholder={t('email_placeholder') || ''}
          register={register}
          errorMessage={errors.email?.message}
        />
        <Input
          label={t('personal_information.first_name') || ''}
          id={'firstName'}
          type="text"
          register={register}
          errorMessage={errors?.firstName?.message}
          placeholder={t('personal_information.first_name_placeholder') || ''}
        />
        <Input
          label={t('personal_information.last_name') || ''}
          id={'lastName'}
          type="text"
          register={register}
          errorMessage={errors?.lastName?.message}
          placeholder={t('personal_information.last_name_placeholder') || ''}
        />
        <InputPhone
          id="phone"
          label={t('phone') || ''}
          register={register}
          errorMessage={errors.phone?.message}
          value={watch('phone')}
          isValid={true}
        />
        <PhotoUploader label={t('upload_photo_optional') || ''} />
      </FieldsSection>
      <StyledDivider />
      <ButtonsWrapper>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={!!Object.keys(errors).length}
        >
          {t('next')}
        </PrimaryButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default ProfileInfo;
