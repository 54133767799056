import React, { FC } from 'react';
import { PatientImportResultDto } from '@docbay/schemas';
import {
  PatientInfo,
  PatientList,
  Wrapper,
  WrapperItem,
  ErrorsList,
} from './styles';
import { ReactComponent as AttentionIcon } from 'applicaiton/assets/attention.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  data: PatientImportResultDto[];
}

const ErrorPatients: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <p>
        {data.length} {t('patient.contacts_imported_with_error')}
      </p>
      <PatientList>
        {data.map((item, index) => {
          return (
            <WrapperItem
              key={`error_${item.patient.socialSecurityNumber}`}
              className={'error'}
            >
              <div>
                <AttentionIcon />
                <PatientInfo>
                  <p>
                    {index + 1}. {item.patient.firstName}{' '}
                    {item.patient.lastName}
                  </p>
                  <span>{item?.patient?.phone || ''}</span>
                  <ErrorsList>
                    {item?.errors?.map((error, ind) => {
                      return (
                        <span key={ind}>
                          {error.constraints[Object.keys(error.constraints)[0]]}
                        </span>
                      );
                    })}
                  </ErrorsList>
                </PatientInfo>
              </div>
              <div>{t('patient.tab_error')}</div>
            </WrapperItem>
          );
        })}
      </PatientList>
    </Wrapper>
  );
};

export default ErrorPatients;
