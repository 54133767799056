import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MicroIcon } from 'applicaiton/assets/micro-not-access.svg';
import { ReactComponent as CameraIcon } from 'applicaiton/assets/camera-not-access.svg';
import { Wrapper, InfoSection, InstructionList, ItemStyled } from './styles';

const PrivacyPolicy: FC = () => {
  const { t } = useTranslation();
  const instruction = [
    t('teleconsultation.privacy.step1'),
    t('teleconsultation.privacy.step2'),
    t('teleconsultation.privacy.step3'),
  ];

  return (
    <Wrapper>
      <InfoSection>
        <div>
          <MicroIcon />
          <CameraIcon />
        </div>
        <h1>{t('teleconsultation.privacy.title')}</h1>
        <InstructionList>
          {instruction.map((item, index) => (
            <ItemStyled key={item}>
              <p>{`${index + 1}.`}</p>
              <p>{item}</p>
            </ItemStyled>
          ))}
        </InstructionList>
        <h3>{t('teleconsultation.privacy.tip')}</h3>
      </InfoSection>
    </Wrapper>
  );
};

export default PrivacyPolicy;
