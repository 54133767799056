import styled from 'styled-components';

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
    width: 140px;

    &:first-child {
      width: inherit;
    }

    p {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 19.6px;
    }
  }

  &:last-child {
    td {
    }
  }
`;
