import React, { FC, useEffect, useMemo, useState } from 'react';
import { ClinicProfileProps } from './types';
import { useNavigate } from 'react-router-dom';
import {
  DropdownClinics,
  Loader,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import {
  Wrapper,
  ButtonsWrapper,
  StyledDivider,
  SelectSection,
  Header,
  ButtonWrapper,
  AddClinic,
  MessageText,
  ContentWrapper,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import SuccessModal from '../SuccessModal';
import { PathNames } from 'applicaiton/routes';
import { uploadedPhoto } from 'applicaiton/store/reducers/Photo/PhotoSlice';
import {
  resetSecretaryData,
  setSecretaryData,
} from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';
import AssigningProfessionals from '../AssigningProfessionals';
import { createSecretary } from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import { fetchProfessionals } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { Option } from 'common/components/DropdownProfessionals/models';
import { useClinicForm } from 'features/feature-user-secretary-add/hooks/useClinicForm';
import { SecretaryClinicDto } from '@docbay/schemas';
import { FormProvider } from 'react-hook-form';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';

const ClinicProfile: FC<ClinicProfileProps> = ({
  onBackBtnClick,
  onNextBtnClick,
  arrayIndex,
  activeForm,
  addClinic,
  onRemoveClinic,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [professionalsToAdd, setProfessionalsToAdd] = useState<Option[]>([]);
  const { isLoading: professionalIsLoading, professionals } = useAppSelector(
    (state) => state.professionals,
  );
  const [isCreated, setIsCreated] = useState<boolean>(false);

  const methods = useClinicForm({
    requiredError: t('errors.required'),
  });

  const { profileChanges } = useAppSelector((state) => state.secretaries);
  const { clinics } = useAppSelector((state) => state.clinics);
  const allClinic = [...(profileChanges?.clinics || [])];
  const currentClinic = allClinic[arrayIndex];

  const isStateChanged = useMemo(() => {
    return !!profileChanges;
  }, [profileChanges]);
  const allClinicsAdded = useMemo(() => {
    const addedClinics = clinics.filter((clinic) => {
      const clinicAlreadyAdded = profileChanges?.clinics.some(
        (item) => item.id === clinic.id,
      );
      if (clinicAlreadyAdded) return clinic;
    });
    return (
      addedClinics.length === clinics.length ||
      profileChanges?.clinics.length === clinics.length
    );
  }, [clinics, profileChanges?.clinics]);

  const canUserAddMoreClinic = clinics?.length > 1 && !allClinicsAdded;

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const professionalsOptions = useMemo(() => {
    return professionals.map((item) => ({
      value: item.id,
      label: item.firstName + ' ' + item.lastName,
      image: item.mainPhoto?.thumbnailUrl || '',
      specializations: item.specializations,
    }));
  }, [professionals]);

  useEffect(() => {
    if (currentClinic) {
      const currentClinicId =
        currentClinic.id ||
        (profileChanges?.clinics || []).find((_, index) => index === arrayIndex)
          ?.id ||
        '';
      methods.setValue('id', currentClinicId);
      methods.setValue('professionalIds', currentClinic.professionalIds!);

      const professionalIds = currentClinic.professionalIds;
      const filteredProfessionalsOptions = professionalsOptions.filter(
        (option) => professionalIds?.includes(option.value),
      );

      setProfessionalsToAdd(filteredProfessionalsOptions);
    } else {
      methods.reset();
      dispatch(
        setSecretaryData({
          ...profileChanges!,
          clinics: [
            ...(profileChanges?.clinics || []),
            {
              id: '',
              professionalIds: [],
            },
          ],
        }),
      );
    }
  }, [arrayIndex, professionals]);

  const getCurrentClinicData = () => {
    const { id, professionalIds } = methods.getValues();

    const newClinicData: SecretaryClinicDto = {
      ...currentClinic,
      id: id,
      professionalIds: professionalIds,
    };

    return newClinicData;
  };

  useEffect(() => {
    methods.watch('id') &&
      dispatch(fetchProfessionals({ clinicIds: methods.watch('id') }));
  }, [methods.watch('id')]);

  const sendInvite = async () => {
    const newClinicData = getCurrentClinicData();
    allClinic.splice(+arrayIndex, 1, newClinicData);

    const fullSecretaryData = {
      ...profileChanges,
      firstName: profileChanges?.firstName || '',
      lastName: profileChanges?.lastName || '',
      email: profileChanges?.email || '',
      phone: `+${profileChanges?.phone}` || '',
      clinics: allClinic,
    };

    const response = await dispatch(createSecretary(fullSecretaryData));

    if (response.meta.requestStatus === 'fulfilled') {
      setIsCreated(true);
    }
  };

  const handleBack = () => {
    const newClinicData = getCurrentClinicData();

    allClinic.splice(arrayIndex, 1, newClinicData);

    dispatch(
      setSecretaryData({
        ...profileChanges!,
        clinics: [...allClinic],
      }),
    );

    onBackBtnClick();
  };

  const handleDelete = () => {
    const filteredClinics =
      profileChanges?.clinics.filter((_, index) => index !== arrayIndex) || [];
    dispatch(
      setSecretaryData({
        ...profileChanges!,
        clinics: filteredClinics,
      }),
    );
    onRemoveClinic(arrayIndex);
  };

  const addNewClinic = () => {
    if (!Object.keys(methods.formState.errors).length) {
      const setClinicData = getCurrentClinicData();
      methods.reset();
      allClinic.splice(arrayIndex, 1, setClinicData);

      const newClinicData: SecretaryClinicDto = {
        id: '',
        professionalIds: [],
      };

      dispatch(
        setSecretaryData({
          ...profileChanges!,
          clinics: [...allClinic, newClinicData],
        }),
      );

      addClinic();
    }
  };

  const onSubmit = async () => {
    if (activeForm !== allClinic.length) {
      onNextBtnClick();
    } else {
      await sendInvite();
    }
  };

  const closeSuccessModal = () => {
    dispatch(resetSecretaryData());
    navigate(PathNames.myUsers);
    dispatch(uploadedPhoto([]));
    setIsCreated(false);
    navigate(PathNames.myUsers);
  };

  const showProfessionalDropdown = methods.watch('id') && professionals.length;

  const handleClinicChange = (id: string) => {
    methods.setValue('id', id);
    const clinicsList =
      profileChanges?.clinics.map((item, index) => {
        if (index === arrayIndex) {
          return {
            ...item,
            id: id,
          };
        }
        return item;
      }) || [];
    dispatch(
      setSecretaryData({
        ...profileChanges!,
        clinics: clinicsList,
      }),
    );
    methods.setValue('professionalIds', []);

    setProfessionalsToAdd([]);

    methods.clearErrors('id');
  };

  const selectedClinics = useMemo(() => {
    return clinics.filter((clinic) => {
      const clinicAlreadyAdded = profileChanges?.clinics.some(
        (item) => item.id === clinic.id && clinic.id !== currentClinic?.id,
      );
      if (clinicAlreadyAdded) return clinic;
    });
  }, [clinics, profileChanges?.clinics, currentClinic]);

  const clinicHasNotProfessionals =
    !!currentClinic?.id && !professionals.length;

  return (
    <Wrapper>
      {professionalIsLoading && <Loader />}

      <FormProvider {...methods}>
        <ContentWrapper>
          <Header>
            <h1>
              {arrayIndex === 0
                ? t('users.clinic')
                : `${t('users.clinic')} ${arrayIndex + 1}`}
            </h1>
            <div>
              <button type="button" onClick={handleDelete}>
                <DeleteIcon />
                <h2>{t('delete')}</h2>
              </button>
            </div>
          </Header>

          <StyledDivider />

          <SelectSection>
            <DropdownClinics
              value={methods.watch('id')}
              label={t('users.select_a_clinic_for_secretary')!}
              isMulti={false}
              placeholder={
                t('professional_profile.select_clinic_from_list') || ''
              }
              onChange={(value) => {
                handleClinicChange(String(value));
              }}
              isError={!!methods.formState.errors.id}
              errorMessage={
                methods.formState.errors.id && t('errors.required')!
              }
              notIncludingClinics={selectedClinics}
            />
          </SelectSection>

          {clinicHasNotProfessionals && (
            <MessageText>
              {t('errors.not_allowed_to_assign_clinic_without_doctors')}
            </MessageText>
          )}

          {showProfessionalDropdown ? (
            <AssigningProfessionals
              professionalsToAdd={professionalsToAdd}
              setProfessionalsToAdd={setProfessionalsToAdd}
              professionalsOptions={professionalsOptions}
            />
          ) : null}

          {canUserAddMoreClinic && (
            <>
              <StyledDivider />

              <ButtonWrapper>
                <SecondaryButton onClick={methods.handleSubmit(addNewClinic)}>
                  <AddClinic />
                  {t('professional_profile.add_clinic')}
                </SecondaryButton>
              </ButtonWrapper>
            </>
          )}
        </ContentWrapper>
        <ContentWrapper>
          <StyledDivider />

          <ButtonsWrapper>
            <SecondaryButton onClick={handleBack}>{t('back')}</SecondaryButton>
            <PrimaryButton
              type={'button'}
              onClick={methods.handleSubmit(onSubmit)}
              disabled={
                !!Object.keys(methods.formState.errors).length ||
                clinicHasNotProfessionals
              }
            >
              {activeForm !== allClinic.length ? t('next') : t('save')}
            </PrimaryButton>
          </ButtonsWrapper>
        </ContentWrapper>

        {isCreated && (
          <SuccessModal
            typeName={`${profileChanges?.firstName} ${profileChanges?.lastName}`}
            onClose={closeSuccessModal}
          />
        )}
      </FormProvider>
    </Wrapper>
  );
};

export default ClinicProfile;
