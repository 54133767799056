import styled from 'styled-components';

export const ModalStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 500px;
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.color.grey500};
    }
  }
`;

export const ButtonSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 32px;
`;

export const InfoSection = styled.div`
  display: grid;
  gap: 12px;
  justify-items: center;

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
  > p {
    color: ${({ theme }) => theme.color.grey700};
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 25.2px;
  }
`;
