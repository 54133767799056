import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

export interface PhoneT {
  phone: string;
}

export const usePhoneForm = ({ requiredError }: { requiredError: string }) => {
  const formEmailValues = {
    phone: '',
  };

  const PhoneSchema = yup.object({
    phone: yup.string().test({
      name: 'is-valid',
      test(value, ctx) {
        const isValidNumber = isValidPhoneNumber(`+${String(value)}`);
        if (!isValidNumber) return ctx.createError({ message: requiredError });
        return isValidNumber;
      },
    }),
  });

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<PhoneT>({
    resolver: yupResolver(PhoneSchema),
    defaultValues: formEmailValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    trigger,
  };
};
