import { useTranslation } from 'react-i18next';

import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';

export const useHeader = (mode: ReserveSlotsMode) => {
  const { t } = useTranslation();

  switch (mode) {
    case ReserveSlotsMode.PreviewAppointment:
      return t('absenceModal.appointment_details');
    case ReserveSlotsMode.BookAgain:
      return t('appointment.book_again');
    case ReserveSlotsMode.EditAppointment:
      return t('appointment.edit_appointment');
    case ReserveSlotsMode.EditAbsence:
      return t('appointment.edit_absence_hours');
    default:
      return t('absenceModal.reserve_slot');
  }
};
