import React from 'react';

import dayjs from 'dayjs';
import { DateSelectorProps } from './models';

import { Box } from '@mui/system';

import { useTranslation } from 'react-i18next';

import {
  DaySlotList,
  DaySlotWrapper,
  HeaderWrapper,
  PickerStyled,
  TitleWrapper,
} from './styles';
import { DateRangeDtoRes } from 'common/hooks/useSlotsByDay';

const DateSelector = ({
  slots,
  setSelectedDay,
  selectedDay,
}: DateSelectorProps) => {
  const { t, i18n } = useTranslation();

  const allStartDatesAreEmpty = (availableSlots: DateRangeDtoRes[]) => {
    return availableSlots.every((slot) => slot.startDate === '-');
  };

  const formatDate = (date: string) => {
    const formattedDate = dayjs(date).locale(i18n.language).format('MMM DD');
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  };

  return (
    <Box>
      <HeaderWrapper>
        <TitleWrapper>{t('book_appointment.select_date')}</TitleWrapper>

        <PickerStyled>
          {formatDate(slots[0]?.day)}

          {slots?.length > 2 &&
            ` - ${formatDate(slots[slots?.length - 1].day)}`}
        </PickerStyled>
      </HeaderWrapper>

      <DaySlotList>
        {slots.map((slot, index) => {
          return (
            <DaySlotWrapper
              onClick={() => {
                if (!allStartDatesAreEmpty(slot.availableSlots)) {
                  setSelectedDay(slot.day);
                }
              }}
              active={selectedDay === slot.day ? 1 : 0}
              disabled={allStartDatesAreEmpty(slot.availableSlots) ? 1 : 0}
              key={index}
            >
              {formatDate(slot.day)}
            </DaySlotWrapper>
          );
        })}
      </DaySlotList>
    </Box>
  );
};

export default DateSelector;
