import styled from 'styled-components';

interface Props {
  disabled: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const SlotsWrapper = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 32px;
`;

export const DividerStyled = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.color.grey200};
`;

export const SlotsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ReplicateButton = styled.div<Props>`
  height: fit-content;
  margin-left: 32px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  color: ${({ theme, disabled }) =>
    disabled ? theme.color.grey400 : theme.color.blue800};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')}; ;
`;
