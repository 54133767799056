import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { AsideStyled, StyledItem } from './styles';
import { Divider } from '@mui/material';
import { SideBarProps } from './modules';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import LeavePageModal from '../LeavePageModal';

const SideBar: FC<SideBarProps> = ({
  clinics,
  handleAddClinic,
  selectedClinic,
  setSelectedClinic,
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext();
  const dispatch = useAppDispatch();
  const { hasUnsavedChanges } = useAppSelector((state) => state.savedChanges);
  const [showModal, setShowModal] = useState(false);
  const [nextFormIndex, setNextFormIndex] = useState<number | null>(null);

  const handleChangeForm = (index: number) => {
    if (hasUnsavedChanges) {
      setShowModal(true);
      setNextFormIndex(index);
    } else {
      setSelectedClinic(index);
      setNextFormIndex(null);
    }
  };

  const handleCloseModal = () => {
    setSelectedClinic(Number(nextFormIndex));
    setShowModal(false);
    dispatch(setHasUnsavedChanges(false));
  };

  return (
    <AsideStyled>
      <LeavePageModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleCloseModal}
      />

      <StyledItem
        isSelected={!selectedClinic}
        onClick={() => handleChangeForm(0)}
      >
        {t('professionalConfiguration.general_profile_info')}
      </StyledItem>

      <div>
        <Divider />

        {clinics.map((clinic, index) => (
          <StyledItem
            key={index}
            isSelected={selectedClinic === index + 1}
            onClick={() => handleChangeForm(index + 1)}
          >
            {clinic}
          </StyledItem>
        ))}
      </div>

      <button
        type="button"
        onClick={
          !selectedClinic ? handleAddClinic : handleSubmit(handleAddClinic)
        }
        disabled={hasUnsavedChanges}
      >
        <AddIcon />

        <h2>{t('add_another')}</h2>
      </button>
    </AsideStyled>
  );
};

export default SideBar;
