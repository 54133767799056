import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 664px;
  max-height: 80%;
  overflow-y: auto;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

  > div {
    display: flex;
    align-items: center;

    svg {
      width: 54px;
      height: 54px;
    }
    p {
      color: ${({ theme }) => theme.color.grey700};
      font-family: Mulish;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-left: 12px;

      span {
        font-weight: 700;
      }
    }
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.color.grey700};
  }
`;

export const WrapperAppointments = styled.div`
  padding: 32px;
`;

export const Title = styled.p`
  color: ${({ theme }) => theme.color.warning};
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.09px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding: 32px;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
  justify-content: flex-end;

  button {
    width: 150px;
  }
`;
