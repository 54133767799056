import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClinicOwnerActions } from 'applicaiton/constants/actions';
import { AxiosError } from 'axios';
import { ClinicOwnerAPI } from 'integration/api/clinicOwner';
import {
  ClinicOwnerUpdateDto,
  SupportedLanguageIso,
  UserChangeEmailDto,
  UserChangePhoneDto,
  UserRequestChangePhoneDto,
  UserVerifyCodeDto,
} from '@docbay/schemas';
import { getToken } from 'applicaiton/sessionStorage/auth';
import { UsersAPI } from 'integration/api/users';
import { updatePermissionsByClinicOwnerIdProps } from './models';
import { fetchClinicOwnerStaffProps } from 'common/types/clinicOwner';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { getRegistrationToken } from 'applicaiton/sessionStorage/clinicRegistration';

export const getClinicOwnerById = createAsyncThunk(
  ClinicOwnerActions.getClinicOwnerById,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.getClinicOwnerById(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateClinicOwner = createAsyncThunk(
  ClinicOwnerActions.updateClinicOwner,
  async (
    params: { id: string; data: ClinicOwnerUpdateDto },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ClinicOwnerAPI.updateClinicOwner(
        params.id,
        params.data,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const clinicOwnerVerifyOldEmail = createAsyncThunk(
  ClinicOwnerActions.clinicOwnerVerifyOldEmail,
  async (params: { email: string }, { rejectWithValue }) => {
    try {
      const token = getToken();
      const language = getLanguageFromLocalStorage();
      const { data } = await UsersAPI.userVerifyOldEmail(
        { ...params, language },
        token,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const clinicOwnerVerifyCode = createAsyncThunk(
  ClinicOwnerActions.clinicOwnerVerifyCode,
  async (params: UserVerifyCodeDto, { rejectWithValue }) => {
    try {
      // const token = getToken();
      const { data } = await UsersAPI.usersVerifyCode(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const clinicOwnerSetNewEmail = createAsyncThunk(
  ClinicOwnerActions.clinicOwnerSetNewEmail,
  async (params: UserChangeEmailDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const { data } = await UsersAPI.userSetNewEmail(params, token);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getPermissionsByClinicOwnerId = createAsyncThunk(
  ClinicOwnerActions.getPermissionsByClinicOwnerId,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.getPermissionsByClinicOwnerId(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updatePermissionsByClinicOwnerId = createAsyncThunk(
  ClinicOwnerActions.updatePermissionsByClinicOwnerId,
  async (
    { id, permission }: updatePermissionsByClinicOwnerIdProps,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ClinicOwnerAPI.updatePermissionsByClinicOwnerId(
        id,
        permission,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const fetchClinicOwnerStaff = createAsyncThunk(
  ClinicOwnerActions.fetchClinicOwnerStaff,
  async (params: fetchClinicOwnerStaffProps, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.fetchClinicOwnerStaff(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const clinicOwnerVerifyPhone = createAsyncThunk(
  ClinicOwnerActions.clinicOwnerVerifyPhone,
  async (params: UserRequestChangePhoneDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const language = getLanguageFromLocalStorage();

      const { data } = await UsersAPI.userPhoneVerificationCode(
        { ...params, language: language as SupportedLanguageIso },
        token,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const clinicOwnerSetNewPhone = createAsyncThunk(
  ClinicOwnerActions.clinicOwnerSetNewPhone,
  async (params: UserChangePhoneDto, { rejectWithValue }) => {
    try {
      const token = getToken();
      const { data } = await UsersAPI.userSetNewPhone(params, token);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
export const getSubscriptions = createAsyncThunk(
  ClinicOwnerActions.getSubscriptions,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.getSubscriptions();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
export const getCustomerPortalLink = createAsyncThunk(
  ClinicOwnerActions.getCustomerPortalLink,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.getCustomerPortalLink();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getCustomerBillingLink = createAsyncThunk(
  ClinicOwnerActions.getCustomerBillingLink,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.getCustomerBillingLink(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getCustomerBillingUpdateLink = createAsyncThunk(
  ClinicOwnerActions.getCustomerBillingUpdateLink,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.getCustomerBillingUpdateLink();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getCustomerBillingActivationLink = createAsyncThunk(
  ClinicOwnerActions.getCustomerBillingActivationLink,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.getCustomerBillingActivationLink();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getPhotoPackage = createAsyncThunk(
  ClinicOwnerActions.getPhotoPackage,
  async (_, { rejectWithValue }) => {
    try {
      const token = getRegistrationToken();
      const { data } = await ClinicOwnerAPI.getPhotoPackage(token);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
export const getAccountStatus = createAsyncThunk(
  ClinicOwnerActions.getAccountStatus,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.getAccountStatus(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteCOAccount = createAsyncThunk(
  ClinicOwnerActions.deleteCOAccount,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await ClinicOwnerAPI.deleteCOAccount(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
