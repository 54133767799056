import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider as StyledProvider } from 'styled-components';
import { store } from './store';
import { AppRouter } from './routes';
import { styledTheme, materialTheme } from './theme';
import './App.css';
import 'dayjs/locale/pt';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import { useTranslation } from 'react-i18next';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { ChatsContextProvider } from 'features/feature-chats/context/ChatsContext';
dayjs.extend(updateLocale);

dayjs.updateLocale('pt', {
  weekdaysMin: 'dom_seg_ter_qua_qui_sex_sab'.split('_'),
});
dayjs.updateLocale('fr', {
  weekdaysMin: 'di_lu_ma_me_je_ve_sa'.split('_'),
});

const App = () => {
  const { i18n } = useTranslation();

  const currentLanguage = useMemo(() => {
    return getLanguageFromLocalStorage();
  }, [i18n.language]);

  return (
    <StyledProvider theme={styledTheme}>
      <ThemeProvider theme={materialTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={currentLanguage}
        >
          <Provider store={store}>
            <ChatsContextProvider>
              <AppRouter />
            </ChatsContextProvider>
          </Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledProvider>
  );
};

export default App;
