import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, InputWrapper, PromoCodeSectionStyled } from './styles';
import { Input, SecondaryButton } from 'common/components';
import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';
import { SubscriptionPackageDto } from '@docbay/schemas';
import { useAppDispatch } from 'common/hooks/redux';
import { applyClinicOwnerPromocode } from 'applicaiton/store/reducers/ClinicRegistration/ActionCreators';

const PromoCodes: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [promoCode, setPromoCode] = useState('');
  const { subscriptionPackages, stripeDebit } =
    currentClinicOwnerRegistration();

  const packages = ([] as SubscriptionPackageDto[]).concat(
    ...Object.values(subscriptionPackages || {}),
  );

  const handleApplyPromoCode = () => {
    dispatch(
      applyClinicOwnerPromocode({
        promocode: promoCode,
        packages: packages,
        token: stripeDebit.token,
      }),
    );
  };

  return (
    <PromoCodeSectionStyled>
      <InputWrapper>
        <Input
          id={'code'}
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          placeholder={t('promo_code') || ''}
          type={'text'}
        />
      </InputWrapper>

      <ButtonWrapper>
        <SecondaryButton onClick={handleApplyPromoCode} styleType="color">
          {t('apply')}
        </SecondaryButton>
      </ButtonWrapper>
    </PromoCodeSectionStyled>
  );
};

export default PromoCodes;
