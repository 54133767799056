import { useTranslation } from 'react-i18next';

export const useDaysOfWeek = () => {
  const { t } = useTranslation();

  return [
    { id: 0, name: t('monday') },
    { id: 1, name: t('tuesday') },
    { id: 2, name: t('wednesday') },
    { id: 3, name: t('thursday') },
    { id: 4, name: t('friday') },
    { id: 5, name: t('saturday') },
    { id: 6, name: t('sunday') },
  ];
};
