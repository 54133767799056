import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownClinics } from 'common/components';
import { FilterSectionProps } from './modules';
import {
  ControllerSection,
  FilterSectionStyled,
  ItemList,
  ResetButton,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Specialization } from '@docbay/schemas';
import { fetchSpecializations } from 'applicaiton/store/reducers/Specializations/ActionCreators';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { Option } from 'common/components/Dropdown/models';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

const FilterSection: FC<FilterSectionProps> = ({
  clinics,
  setClinic,
  setSpecializationId,
  specializationId,
  handleRemoveFilterItem,
  handleResetFilter,
  notIncludingClinics,
  hideClinicDropdown,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { specializations } = useAppSelector((state) => state.specializations);

  useEffect(() => {
    dispatch(fetchSpecializations());
  }, []);

  const specializationsOptions = useMemo(() => {
    const language = getLanguageFromLocalStorage();
    const specializationLangKey = `name_${language.toUpperCase()}`;

    const options = specializations.map((item) => {
      const specializationName =
        item[specializationLangKey as keyof Specialization];

      return {
        value: item.id,
        label: specializationName as string,
      };
    });
    return options;
  }, [specializations, i18n.language]);

  return (
    <FilterSectionStyled>
      <ControllerSection>
        {!hideClinicDropdown ? (
          <DropdownClinics
            isAllDataReturn
            value={(clinics?.value as string) || ''}
            onChange={(value) => {
              setClinic(value as Option);
            }}
            isMulti={false}
            notIncludingClinics={notIncludingClinics}
          />
        ) : (
          <></>
        )}
        <Dropdown
          id={'specializations'}
          value={specializationId}
          placeholder={t('appointment_types.specializations_placeholder')}
          onChange={(value) => {
            setSpecializationId(value as string[]);
          }}
          options={specializationsOptions}
          withSearch={true}
          isMulti={true}
        />
      </ControllerSection>
      {clinics || !!specializationId.length ? (
        <ItemList>
          <h2>{t('appointment_types.selected_filters')}:</h2>
          {!!clinics && (
            <div>
              <p>{clinics.label}</p>
              <button
                onClick={() =>
                  handleRemoveFilterItem(String(clinics), 'clinic')
                }
              >
                <CloseIcon />
              </button>
            </div>
          )}
          {!!specializationId.length &&
            specializationId.map((id) => {
              const currentSpecialization = specializationsOptions.filter(
                (item) => item.value === id,
              );

              return (
                <div key={`specialization_${id}`}>
                  <p>{currentSpecialization[0].label}</p>
                  <button
                    onClick={() =>
                      handleRemoveFilterItem(String(id), 'specialization')
                    }
                  >
                    <CloseIcon />
                  </button>
                </div>
              );
            })}
          <ResetButton onClick={handleResetFilter}>
            {t('clear_all')}
          </ResetButton>
        </ItemList>
      ) : (
        ''
      )}
    </FilterSectionStyled>
  );
};

export default FilterSection;
