import styled from 'styled-components';
import { ButtonGroupProps } from './modules';

export const FilterSectionStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  box-shadow: 1px 1px 2px 0px rgba(54, 54, 54, 0.1);
`;

export const ButtonStyled = styled.button<ButtonGroupProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 10px 16px;
  border: none;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.blue100 : theme.color.white};
  cursor: pointer;

  color: ${({ theme }) => theme.color.grey900};
  font-size: 16px;
  font-weight: ${({ isActive }) => (isActive ? '600' : '500')};
  line-height: 22.4px;
  letter-spacing: 0.08px;

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }

  &:first-child {
    min-width: fit-content;
    border-right: 1px solid ${({ theme }) => theme.color.grey300};
    border-radius: 8px 0 0 8px;
  }
  &:last-child {
    min-width: fit-content;
    border-radius: 0 8px 8px 0;
  }
`;
