import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
  grid-template-rows: min-content 1fr;

  > div {
    width: 464px;
  }

  > div:last-child {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.color.grey200};
`;
