import styled from 'styled-components';

export const StyledForm = styled.form`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:first-child {
    display: grid;
    grid-template-columns: 100%;
    gap: 28px;
    max-width: 470px;
  }
`;

export const ButtonSectionStyled = styled.div`
  > h2 {
    color: ${({ theme }) => theme.color.error1};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 6px;

    > p {
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      color: ${({ theme }) => theme.color.grey700};
    }

    > hr {
      border-color: ${({ theme }) => theme.color.grey400};
      height: 17px;
      margin: 0 12px;
    }

    > button {
      border: none;
      background: none;
      padding: 2px;
      color: ${({ theme }) => theme.color.blue800};
      font-size: 16px;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.08px;
      cursor: pointer;
    }
  }
`;
