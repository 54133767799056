import { createAsyncThunk } from '@reduxjs/toolkit';
import { ChatsActions } from 'applicaiton/constants/actions';
import { AxiosError } from 'axios';
import { ChatsAPI, ChatsSearchParams } from 'integration/api/chats';
import { MassMessageRequestDto } from '@docbay/schemas';
import { StartTeleconsultationRequest } from './models';

export const getChats = createAsyncThunk(
  ChatsActions.getChats,
  async (params: ChatsSearchParams, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.getChats(params);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const getAccessToken = createAsyncThunk(
  ChatsActions.getAccessToken,
  async (_: void, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.getAccessToken();
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const createChat = createAsyncThunk(
  ChatsActions.createChat,
  async (patientId: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.createChat(patientId);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const postMassMessagesChat = createAsyncThunk(
  ChatsActions.postMassMessagesChat,
  async (params: MassMessageRequestDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.postMassMessagesChat({
        params,
      });
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateChat = createAsyncThunk(
  ChatsActions.updateChat,
  async (data: { conversationSID: string; data: any }, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.updateChat(
        data.conversationSID,
        data.data,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const archiveChat = createAsyncThunk(
  ChatsActions.archiveChat,
  async (conversationSID: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.archiveChat(
        conversationSID,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const unArchiveChat = createAsyncThunk(
  ChatsActions.unArchiveChat,
  async (conversationSID: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.unArchiveChat(
        conversationSID,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteChat = createAsyncThunk(
  ChatsActions.deleteChat,
  async (conversationSID: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.deleteChat(conversationSID);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteMassMessage = createAsyncThunk(
  ChatsActions.deleteMassMessage,
  async (massMessageId: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.deleteMassMessage(
        massMessageId,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const startChatTeleconsultation = createAsyncThunk(
  ChatsActions.startChatTeleconsultation,
  async (request: StartTeleconsultationRequest, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.startChatTeleconsultation(
        request,
      );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
export const addDocumentMessage = createAsyncThunk(
  ChatsActions.addDocumentMessage,
  async (
    {
      conversationSID,
      data,
    }: {
      conversationSID: string;
      data: FormData;
    },
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } = await ChatsAPI.addDocumentMessage({
        conversationSID,
        data,
      });
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const closeTopic = createAsyncThunk(
  ChatsActions.updateChat,
  async (conversationSid: string, { rejectWithValue }) => {
    try {
      const { data: responseData } = await ChatsAPI.closeTopic(conversationSid);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
