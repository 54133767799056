import React, { FC, useEffect, useMemo } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import {
  LanguageDropdown,
  PrimaryButton,
  SecondaryButton,
  TextArea,
} from 'common/components';
import { useAppDispatch } from 'common/hooks/redux';
import { useMessagesScheme } from 'features/feature-appointment-type-edit/hooks/useMessagesScheme';
import { EditNotificationsModalProps, FormData } from './models';
import { ModalStyled, Header, InfoSection, ButtonSection } from './styles';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';

const EditNotificationsModal: FC<EditNotificationsModalProps> = ({
  opened,
  onClose,
  onSubmit,
  editedNotification,
  setShowUnsavedChangesModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const messagesScheme = useMessagesScheme();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(messagesScheme),
    defaultValues: {
      language: 'pt',
      content: editedNotification?.content_PT || '',
    },
  });

  const handleChangeLanguage = (value: string) => {
    const isEnglish = value === 'en';
    setValue('language', value);
    setValue(
      'content',
      isEnglish
        ? editedNotification?.content_EN || ''
        : editedNotification?.content_PT || '',
    );
  };

  const handleSubmitForm = (values: FormData) => {
    const { content, language } = values;
    if (editedNotification) {
      const isEnglish = language === 'en';
      const data = {
        type: editedNotification.type,
        channel: editedNotification.channel,
        content_EN: isEnglish ? content : editedNotification.content_EN,
        content_PT: isEnglish ? editedNotification.content_PT : content,
      };
      onSubmit(data);
    }
  };

  const isStateChanged = useMemo(() => {
    const { content, language } = watch();
    const isEnglish = language === 'en';
    if (isEnglish) {
      return (editedNotification?.content_EN || '') !== content;
    }
    return (editedNotification?.content_PT || '') !== content;
  }, [editedNotification, watch()]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const handleCloseModal = () => {
    if (isStateChanged) {
      setShowUnsavedChangesModal(true);
    } else {
      onClose();
    }
  };

  return (
    <Modal open={opened} onClose={handleCloseModal}>
      <ModalStyled onSubmit={handleSubmit(handleSubmitForm)}>
        <Header>
          <h1>
            {t('appointment_types.edit_booking_confirmation_notifications')}
          </h1>
          <button type="button" onClick={handleCloseModal}>
            <CloseIcon />
          </button>
        </Header>
        <InfoSection>
          <LanguageDropdown
            id={'language'}
            placeholder={t('select_language')}
            value={watch('language')}
            onChange={handleChangeLanguage}
            errorMessage={errors.language?.message}
          />
          <TextArea
            id="content"
            label={t('appointment_types.for_email') || ''}
            placeholder={
              t('appointment_types.notification_content_placeholder') || ''
            }
            textLength={watch('content')}
            register={register}
            maxTextLength={275}
            errorMessage={errors.content?.message}
          />
        </InfoSection>
        <ButtonSection>
          <SecondaryButton type="button" onClick={handleCloseModal}>
            {t('cancel')}
          </SecondaryButton>
          <PrimaryButton type="submit" disabled={!isStateChanged}>
            {t('save')}
          </PrimaryButton>
        </ButtonSection>
      </ModalStyled>
    </Modal>
  );
};

export default EditNotificationsModal;
