import React, { FC } from 'react';
import { DropdownClinics } from 'common/components';
import { FilterSectionProps } from './modules';
import { ControllerSection, FilterSectionStyled } from './styles';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { searchEntityType } from 'features/feature-scheduler/constants/searchEntityType';
import { UserRoles } from 'applicaiton/constants/userRoles';

const FilterSection: FC<FilterSectionProps> = ({ clinics, setClinics }) => {
  const userId = getUserId();
  const isUserProfessional = getUserRole() === UserRoles.professional;

  return (
    <FilterSectionStyled>
      <ControllerSection>
        <DropdownClinics
          value={clinics}
          onChange={(values) => {
            Array.isArray(values) && setClinics(values);
          }}
          isMulti={true}
          clinicOwnerId={userId || ''}
          entityType={
            isUserProfessional
              ? searchEntityType.professional
              : searchEntityType.clinicOwner
          }
        />
        <div></div>
      </ControllerSection>
    </FilterSectionStyled>
  );
};

export default FilterSection;
