import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ClinicInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ClinicImage = styled.div`
  position: relative;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.blue800};
  margin-right: 16px;
  color: ${({ theme }) => theme.color.white};

  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ClinicName = styled.h2`
  font-family: 'AcidGrotesk-Medium';
  font-size: 24px;
  color: ${({ theme }) => theme.color.grey900};
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: capitalize;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: fit-content;

  button {
    padding: 12px 24px;
    min-width: fit-content;
    white-space: nowrap;
  }

  a {
    margin-left: 16px;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

export const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  height: 48px;
  min-width: fit-content;
  width: fit-content;
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.blue800};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 2px 0px rgba(54, 54, 54, 0.1);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.08px;
  color: ${({ theme }) => theme.color.blue800};
  text-decoration: none;

  &.isDraft {
    pointer-events: none;
    background-color: ${({ theme }) => theme.color.blue50};
    border-color: ${({ theme }) => theme.color.blue200};
    color: ${({ theme }) => theme.color.blue200};

    svg {
      path {
        fill: ${({ theme }) => theme.color.blue200};
      }
    }
  }

  svg {
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    margin-right: 10px;

    path {
      fill: ${({ theme }) => theme.color.blue800};
    }
  }

  :hover {
    background-color: ${({ theme }) => theme.color.blue50};
    color: ${({ theme }) => theme.color.blue800};
  }
`;
