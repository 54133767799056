import React, { FC, useEffect } from 'react';
import { Modal } from '@mui/material';
import { DeleteModal, SuccessModal } from './components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader } from 'common/components';
import { selectProfessional } from 'applicaiton/store/reducers/Professionals/ProfessionalsSlice';
import { deleteProfessionals } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { useLocation, useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import {
  getProfilesFromSessionStorage,
  getUserRole,
  Profile,
} from '../../applicaiton/sessionStorage/auth';
import { usersRefreshToken } from 'applicaiton/store/reducers/Users/ActionCreators';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { setActiveForm } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';
import { ProfileForm } from 'applicaiton/constants/clincOwnerProfile';

const DeleteProfessionalModal: FC = () => {
  const { isLoading, currentProfessional, deletedSuccess } = useAppSelector(
    (state) => state.professionals,
  );
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userRole = getUserRole();
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);

  const handleClose = () => {
    dispatch(selectProfessional(null));
    if (isClinicOwnerRole) {
      dispatch(setActiveForm(ProfileForm.previewProfile));
    }
  };

  const handleSubmit = () => {
    dispatch(deleteProfessionals(Number(currentProfessional?.id))).then(
      (data) => {
        const isRedirect =
          data.meta.requestStatus === 'fulfilled' &&
          pathname.includes('users/professionals/edit');

        const isCOProfessional =
          data.meta.requestStatus === 'fulfilled' &&
          isClinicOwnerRole &&
          pathname.includes('profile');

        if (isRedirect) {
          navigate(PathNames.professionals);
        }

        if (isCOProfessional) {
          const profile: Profile[] | null = getProfilesFromSessionStorage();

          if (profile) {
            dispatch(
              usersRefreshToken({
                refreshToken: profile[0].refreshToken,
                email: profile[0].email,
              }),
            );
          }
        }
      },
    );
  };

  useEffect(() => {
    if (!deletedSuccess) return;
    const timer = setTimeout(() => {
      handleClose();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [deletedSuccess]);

  const professionalFullName = `${currentProfessional?.firstName || ''} ${
    currentProfessional?.lastName || ''
  }`;

  return (
    <Modal open={!!currentProfessional}>
      <>
        {isLoading && <Loader />}
        <DeleteModal
          profileName={professionalFullName}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />

        {deletedSuccess && !isLoading && (
          <SuccessModal
            profileName={professionalFullName}
            onClose={handleClose}
          />
        )}
      </>
    </Modal>
  );
};

export default DeleteProfessionalModal;
