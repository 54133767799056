import React, { useMemo } from 'react';
import { Wrapper } from './styles';
import Input from 'common/components/Input';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'common/components';
import { Option } from 'common/components/Dropdown/models';
import { useChatsContext } from '../../hooks/useChatsContext';
import { getUniqueObjects } from 'common/helpers/collectionsHelper';

export const ChatsSearch = () => {
  const { t } = useTranslation();
  const {
    chatSearch,
    setChatSearch,
    dynamicChats,
    selectedTopicsIds,
    setSelectedTopicsIds,
  } = useChatsContext();

  const topicsOfChats = useMemo(() => {
    const allTopics = dynamicChats.map((chat) => chat.clinicProfessionalTopic);
    return getUniqueObjects(allTopics, 'id').map((topic) => ({
      value: topic?.id || '',
      label: topic?.name || t('chats.no_topic'),
    }));
  }, [dynamicChats]);

  const handleSelectTopics = (value: Option | string[]) => {
    setSelectedTopicsIds(value as string[]);
  };
  return (
    <Wrapper>
      <Input
        id={'search'}
        type="search"
        value={chatSearch}
        onChange={(e) => setChatSearch(e.target?.value || '')}
        placeholder={t('chats.search_placeholder')!}
        onClear={() => setChatSearch('')}
      />
      <Dropdown
        id={'topicId'}
        value={selectedTopicsIds}
        placeholder={t('chats.all_topics')}
        onChange={handleSelectTopics}
        options={topicsOfChats}
        isMulti={true}
      />
    </Wrapper>
  );
};
