import { createSlice } from '@reduxjs/toolkit';
import {
  uploadChatTeleconsultationDocument,
  downloadChatTeleconsultationDocument,
  deleteChatDocumentMessage,
} from './ActionCreators';
import { InitialState } from './models';

const initialState: InitialState = {
  isLoading: false,
  error: '',
  isDocumentLoading: false,
  documentError: '',
};

const chatTeleconsultationSlice = createSlice({
  name: 'chatTeleconsultation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // upload chat teleconsultation document
    builder.addCase(uploadChatTeleconsultationDocument.pending, (state) => {
      state.isDocumentLoading = true;
    });
    builder.addCase(
      uploadChatTeleconsultationDocument.rejected,
      (state, action) => {
        state.isDocumentLoading = false;
        state.documentError = action.payload as string;
      },
    );
    builder.addCase(uploadChatTeleconsultationDocument.fulfilled, (state) => {
      state.isDocumentLoading = false;
    });
    // download chat teleconsultation document
    builder.addCase(downloadChatTeleconsultationDocument.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      downloadChatTeleconsultationDocument.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      },
    );
    builder.addCase(downloadChatTeleconsultationDocument.fulfilled, (state) => {
      state.isLoading = false;
    });
    // delete chat document message
    builder.addCase(deleteChatDocumentMessage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteChatDocumentMessage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteChatDocumentMessage.fulfilled, (state) => {
      state.isLoading = false;
    });
  },
});

export default chatTeleconsultationSlice.reducer;
