import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  button {
    width: 85px;
    height: 46px;
  }
`;

export const PromoCodeSectionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 16px;
`;

export const InputWrapper = styled.div`
  width: 254px;
`;
