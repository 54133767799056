import styled from 'styled-components';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    position: relative;
    outline: none;
    width: fit-content;
    padding: 32px;
    border-radius: 8px;
    background: ${({ theme }) => theme.color.white};
    box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

    > svg {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 12px;
      top: 12px;
      cursor: pointer;

      path {
        fill: ${({ theme }) => theme.color.grey500};
      }
    }

    h1 {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.2px;
      margin-bottom: 8px;
      text-align: center;
      > span {
        color: ${({ theme }) => theme.color.blue700};
      }
    }

    p {
      color: ${({ theme }) => theme.color.grey700};
      font-size: 18px;
      font-weight: 500;
      line-height: 25.2px;
      text-align: center;
      > span {
        font-weight: 600;
        color: ${({ theme }) => theme.color.blue700};
      }
    }
  }
`;

export const ConfirmSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 392px;

  > section {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-top: 32px;
  }
`;
