import styled from 'styled-components';

export const Wrapper = styled.section``;

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 500px;
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.color.grey500};
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
      color: ${({ theme }) => theme.color.grey900};
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.2px;
      margin-top: 12px;
    }

    > p {
      color: ${({ theme }) => theme.color.grey700};
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      margin-top: 12px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  button {
    width: 120px;
    padding: 12px 24px;
    min-width: fit-content;
    white-space: nowrap;
  }

  button:last-child {
    margin-left: 16px;
  }
`;
