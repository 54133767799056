import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
`;
export const TableStyled = styled.table`
  width: 100%;
  margin-top: 22px;
  border-radius: 12px;
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
  border-collapse: separate;

  > thead {
    th {
      vertical-align: middle;
      text-align: start;
      padding: 12px 24px;
      color: ${({ theme }) => theme.color.grey600};
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.07px;
      background: ${({ theme }) => theme.color.grey50};
      border-top: 1px solid ${({ theme }) => theme.color.grey200};
      border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
      width: 120px;

      &:first-child {
        border-top-left-radius: 12px;
        border-left: 1px solid ${({ theme }) => theme.color.grey200};
        width: 275px;
      }
      &:nth-child(3) {
        width: 140px;
      }
      &:last-child {
        border-top-right-radius: 12px;
        border-right: 1px solid ${({ theme }) => theme.color.grey200};
        width: unset;
        text-align: end;
      }
    }
  }
  > tbody {
    > tr {
      &:last-child {
        > td {
          &:first-child {
            border-bottom-left-radius: 12px;
          }
          &:last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
`;
