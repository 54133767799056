import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { StyledButton } from './styles';

interface PrimaryButtonProps {
  styleType?: 'default' | 'error';
  disabled?: boolean;
  children: ReactNode;
  onClick?: () => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
  styleType = 'default',
  disabled = false,
  children,
  onClick,
  type,
}) => {
  return (
    <StyledButton
      styleType={styleType}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default PrimaryButton;
