import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { setEditedPatientId } from 'applicaiton/store/reducers/Patients/PatientsSlice';
import {
  getPatientById,
  updatePatient,
} from 'applicaiton/store/reducers/Patients/ActionCreators';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { LeavePageModal, PatientForm, SuccessModal } from './component';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { usePatientEditScheme } from './hooks/usePatientEditScheme';
import { FormData } from './modules';
import { ModalStyled, HeaderStyled } from './styles';
import { Gender } from '@docbay/schemas';
import dayjs from 'dayjs';

const PatientEdit: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const patientEditScheme = usePatientEditScheme();
  const { currentPatient, editedPatientId } = useAppSelector(
    (state) => state.patients,
  );
  const { hasUnsavedChanges } = useAppSelector((state) => state.savedChanges);
  const [pronunciation, setPronunciation] = useState('');
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [updatedSuccess, setUpdatedSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(patientEditScheme),
  });

  useEffect(() => {
    if (editedPatientId) {
      clearErrors();
      dispatch(getPatientById(editedPatientId));
    }
  }, [editedPatientId]);

  useEffect(() => {
    if (currentPatient) {
      setValue('firstName', currentPatient.firstName);
      setValue('lastName', currentPatient.lastName);
      setValue(
        'phone',
        `${currentPatient.phone?.includes('+') ? '' : '+'}${
          currentPatient?.phone
        }` || '',
      );
      setValue('dateOfBirth', currentPatient.dateOfBirth || '');
      setValue('email', currentPatient.email || '');
      setValue('nif', currentPatient.nif || '');
      setValue(
        'socialSecurityNumber',
        currentPatient.socialSecurityNumber || '',
      );
      setPronunciation(currentPatient.pronunciation || '');
    }
  }, [currentPatient]);

  const handleCloseModal = () => {
    if (hasUnsavedChanges) {
      return setShowWarningModal(true);
    }
    setUpdatedSuccess(false);
    dispatch(setEditedPatientId(null));
  };

  const handleSubmitWarningModal = () => {
    setShowWarningModal(false);
    dispatch(setEditedPatientId(null));
    dispatch(setHasUnsavedChanges(false));
  };

  const onSubmit = async (data: FormData) => {
    const { email, dateOfBirth, phone, ...restData } = data;
    const phoneNumber = phone?.startsWith('+') ? phone : `+${String(phone)}`;
    const payload = {
      ...restData,
      ...(dateOfBirth
        ? { dateOfBirth: dayjs(dateOfBirth).utc(true).format() }
        : {}),
      ...(email ? { email } : {}),
      phone: phoneNumber,
      pronunciation,
      gender: pronunciation as Gender,
    };
    const response = await dispatch(
      updatePatient({
        id: String(editedPatientId),
        data: payload,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      setUpdatedSuccess(true);
      dispatch(setHasUnsavedChanges(false));
    }
  };

  const isStateChanged = useMemo(() => {
    const currentData = { ...watch(), pronunciation };
    const defaultData = {
      firstName: currentPatient?.firstName,
      lastName: currentPatient?.lastName,
      phone: currentPatient?.phone || '',
      dateOfBirth: currentPatient?.dateOfBirth || '',
      email: currentPatient?.email || '',
      pronunciation: currentPatient?.pronunciation || '',
      nif: currentPatient?.nif || '',
      socialSecurityNumber: currentPatient?.socialSecurityNumber || '',
    };

    const hasChanges = compareObjectsChanges(currentData, defaultData);
    return hasChanges;
  }, [watch(), pronunciation, pronunciation]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  useEffect(() => {
    if (!updatedSuccess) return;
    const timer = setTimeout(() => {
      handleCloseModal();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [updatedSuccess]);

  return (
    <Modal open={!!editedPatientId}>
      <>
        <LeavePageModal
          showModal={showWarningModal}
          onClose={() => setShowWarningModal(false)}
          onSubmit={handleSubmitWarningModal}
        />
        {updatedSuccess ? (
          <SuccessModal onClose={handleCloseModal} />
        ) : (
          <ModalStyled>
            <HeaderStyled>
              <h1>{t('patient_edit_form.edit_patient_profile')}</h1>
              <button type="button" onClick={handleCloseModal}>
                <CloseIcon />
              </button>
            </HeaderStyled>
            <PatientForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              pronunciation={pronunciation}
              setPronunciation={setPronunciation}
              register={register}
              watch={watch}
              setValue={setValue}
              handleCloseModal={handleCloseModal}
              isStateChanged={isStateChanged}
              errors={errors}
            />
          </ModalStyled>
        )}
      </>
    </Modal>
  );
};

export default PatientEdit;
