import { Box, styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled(Box)`
  display: flex;
  flex: 0;
  min-width: 420px;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(2.5)};
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  background: ${commonColors.white};
  /* Vidget */
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
`;
