import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export interface GeneralTypeInfoT {
  title: string;
  durationInMinutes: number;
  meetingPlace: string;
  availableForOnlineBooking: boolean;
  colorCode: string;
  reservationOpenInMinutes: number;
  reservationCloseInMinutes: number;
  price: string;
  waitingListNotificationInterval: number;
  billingTitle?: string;
}

export const useGeneralTypeInfoForm = ({
  requiredError,
  maxLengthError,
}: {
  requiredError: string;
  maxLengthError: string;
}) => {
  const formGeneralValues = {
    title: '',
    durationInMinutes: 10,
    meetingPlace: 'Clinic',
    availableForOnlineBooking: true,
    colorCode: 'red',
    reservationOpenInMinutes: 262975,
    reservationCloseInMinutes: 262975,
    price: '0.00',
    waitingListNotificationInterval: 0,
    billingTitle: '',
  };

  const appointmentGeneralSchema = yup
    .object({
      title: yup
        .string()
        .min(3, requiredError!)
        .max(255, maxLengthError!)
        .required(),
      durationInMinutes: yup.number(),
      meetingPlace: yup.string(),
      colorCode: yup.string(),
      availableForOnlineBooking: yup.boolean(),
      reservationOpenInMinutes: yup.number(),
      reservationCloseInMinutes: yup.number(),
      price: yup.string(),
      waitingListNotificationInterval: yup.number(),
      billingTitle: yup.string(),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<GeneralTypeInfoT>({
    resolver: yupResolver(appointmentGeneralSchema),
    defaultValues: formGeneralValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    setError,
  };
};
