import React, { FC, useEffect } from 'react';
import { Wrapper } from './styles';
import { Header, LayoutComponent } from './components';
import { useParams } from 'react-router-dom';
import { getSecretaryById } from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { resetSecretaryData } from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';
import { useAppDispatch } from 'common/hooks/redux';
import ProfileInfo from './components/ProfileInfo';

const SecretaryEdit: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSecretaryById(id!));

    return () => {
      dispatch(setHasUnsavedChanges(false));
      dispatch(resetSecretaryData());
    };
  }, []);

  return (
    <Wrapper>
      <Header />

      <LayoutComponent>
        <ProfileInfo />
      </LayoutComponent>
    </Wrapper>
  );
};

export default SecretaryEdit;
