import styled from 'styled-components';

export const EmergencyMessage = styled.div`
  display: flex;
  margin-top: 6px;
  margin-left: 12px;

  p {
    margin-left: 8px;
    max-width: 250px;
    /* font */
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-family: 'Mulish';
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.grey600};
  }

  svg {
    color: ${({ theme }) => theme.color.grey600};
  }
`;
