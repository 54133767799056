import styled from 'styled-components';

interface Props {
  isActive: boolean;
}
export const Wrapper = styled.article`
  width: 100%;
  height: fit-content;
  padding: 32px;
  gap: 32px;
  margin-top: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  border-top: none;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);

  > hr {
    height: 1px;
    border-color: ${({ theme }) => theme.color.grey200};
    margin-bottom: 32px;
    margin-top: 32px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div:first-child {
    display: flex;
    align-items: center;
  }

  button {
    max-width: max-content;
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.blueDark100};
  margin-right: 16px;
  color: ${({ theme }) => theme.color.white};

  > svg {
    path {
      fill: ${({ theme }) => theme.color.grey900};
    }
  }

  > img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
  }
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.color.grey900};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: AcidGrotesk-Bold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: capitalize;
`;

export const StyledDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: ${({ theme }) => theme.color.grey200};
`;

export const StyledSection = styled.section`
  display: flex;
`;

export const StepSectionWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  margin-bottom: 80px;
`;

export const StepSection = styled.div`
  padding-right: 64px;
`;

export const StepItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
  width: 220px;

  &:last-child {
    margin: 0;
  }
`;

export const StepText = styled.div<Props>`
  width: 100%;
  padding: 8px 12px;
  border-radius: 6px;
  color: ${({ theme }) => theme.color.grey900};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 140%;
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.green100 : 'unset'};
  cursor: pointer;
`;
