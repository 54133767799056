import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { styledTheme } from 'applicaiton/theme';
import { Link } from 'react-router-dom';

export const BackLink = styled(Link)`
  color: ${styledTheme.color.grey800};
  text-decoration: none;
  font: 400 16px/20px;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
`;

export const BackButtonWrapper = styled(Box)`
  margin: 32px 0 0 32px;
`;

export const BackButtonText = styled(Typography)`
  color: ${styledTheme.color.grey600};
  font: 700 16px/22.4px 'Mulish', sans-serif;
  letter-spacing: 0.08px;
`;
