import styled from 'styled-components';

export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
  width: fit-content;

  p {
    margin-left: 4px;
    /* font style */
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    font-style: normal;
    font-family: 'Mulish';
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.grey600};
  }
`;
