import { styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

export const StyledModal = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  min-width: 500px;
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: ${commonColors.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      color: ${commonColors.grey900};
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      margin-top: 12px;
    }

    > p {
      color: ${commonColors.grey900};
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      margin: 12px 0 32px 0;
      > span {
        font-weight: 600;
      }
    }
  }
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  a {
    text-decoration: none;
    width: inherit;
    width: fit-content;
  }

  button {
    width: fit-content;
    padding: 12px 24px;
    white-space: nowrap;
  }
`;
