import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { EmailChangedPopUpProps } from './modules';
import { StyledModal, ConfirmSection } from './styles';

const EmailChangingPopUp: FC<EmailChangedPopUpProps> = ({
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <StyledModal>
      <div>
        <CloseIcon onClick={onClose} />
        <ConfirmSection>
          <h1>{t('clinic_owner.email_pop_up.confirm_section.titlePart1')}</h1>
          <p>{t('clinic_owner.email_pop_up.confirm_section.description')}</p>
          <section>
            <SecondaryButton onClick={onClose}>{t('no')}</SecondaryButton>
            <PrimaryButton onClick={onSubmit}>{t('yes')}</PrimaryButton>
          </section>
        </ConfirmSection>
      </div>
    </StyledModal>
  );
};

export default EmailChangingPopUp;
