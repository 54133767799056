import React, { useState } from 'react';
import { Conversation } from '@twilio/conversations';
import { ChatWithExtraInfo } from 'applicaiton/store/reducers/Chats/models';

export interface ChatsContextStateI {
  conversation: Conversation | null;
  setConversation: (conversation: Conversation | null) => void;
  dynamicChats: ChatWithExtraInfo[];
  setDynamicChats: React.Dispatch<React.SetStateAction<ChatWithExtraInfo[]>>;
  chatSearch: string;
  setChatSearch: (search: string) => void;
  selectedTopicsIds: string[];
  setSelectedTopicsIds: (selectedTopicsIds: string[]) => void;
}

export const ChatsContext = React.createContext<ChatsContextStateI>(
  {} as ChatsContextStateI,
);

export const ChatsContextProvider = (props: any) => {
  const [conversation, setConversation] = React.useState<Conversation | null>(
    null,
  );
  const [dynamicChats, setDynamicChats] = React.useState<ChatWithExtraInfo[]>(
    [],
  );
  const [chatSearch, setChatSearch] = useState('');
  const [selectedTopicsIds, setSelectedTopicsIds] = useState<string[]>([]);

  return (
    <ChatsContext.Provider
      value={{
        conversation,
        setConversation,
        dynamicChats,
        setDynamicChats,
        chatSearch,
        setChatSearch,
        selectedTopicsIds,
        setSelectedTopicsIds,
      }}
    >
      {props.children}
    </ChatsContext.Provider>
  );
};
