import React, { FC } from 'react';
import { StyledDiv, Title, FeatureItem } from './styles';
import { CloseIcon, CheckIcon } from 'applicaiton/assets';
import { FeaturesProps } from './models';
import { useFeatures } from 'features/feature-clinic-registration/hooks/useFeatures';

const Features: FC<FeaturesProps> = ({ packageType }) => {
  const list = useFeatures(packageType);

  return (
    <StyledDiv>
      <Title>Features:</Title>
      <div>
        {list?.map((item) => {
          return (
            <FeatureItem key={item.title} isActive={item.isActive}>
              {item.isActive ? <CheckIcon /> : <CloseIcon />}
              <p>{item.title}</p>
            </FeatureItem>
          );
        })}
      </div>
    </StyledDiv>
  );
};

export default Features;
