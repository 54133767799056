import styled from 'styled-components';

export const SectionStyled = styled.section`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
`;

export const EmailSection = styled.div`
  display: grid;
  gap: 12px;
  > button {
    height: 38px;
    width: fit-content;
  }
`;
