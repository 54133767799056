import React, { FC } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as CancelIcon } from 'applicaiton/assets/cancel.svg';

import { PrimaryButton, SecondaryButton } from 'common/components';

import { DeleteModalProps } from './modules';
import { StyledModal } from './styles';

const DeleteModal: FC<DeleteModalProps> = ({
  name,
  startDate,
  onClose,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language);

  const formattedDate = dayjs(startDate).tz().format('DD MMM HH:mm');

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <StyledModal>
      <CloseIcon onClick={onClose} />
      <h1>
        {t('appointment.delete_type.titlePart1')} <span>{name}</span> {t('on')}{' '}
        <span>{formattedDate}</span>
      </h1>
      <div>
        <p> {t('appointment.delete_type.description')}</p>
        <div>
          <CancelIcon />
          {t('appointment.delete_type.descriptionText1')}
        </div>
        <div>
          <CancelIcon />
          {t('appointment.delete_type.descriptionText2')}
        </div>
        <div>
          <CancelIcon />
          {t('appointment.delete_type.descriptionText3')}
        </div>
      </div>
      <section>
        <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
        <PrimaryButton onClick={handleSubmit} styleType={'error'}>
          {t('appointment_types.delete_type.confirm')}
        </PrimaryButton>
      </section>
    </StyledModal>
  );
};

export default DeleteModal;
