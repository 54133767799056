import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  margin-top: 32px;
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  border: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const TableStyled = styled.table`
  width: 100%;
  border-radius: 12px;
  border-collapse: separate;

  > thead {
    tr {
      th {
        vertical-align: middle;
        text-align: start;
        padding: 12px 24px;
        color: ${({ theme }) => theme.color.grey600};
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.07px;
        background: ${({ theme }) => theme.color.grey50};
        border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

        span {
          white-space: nowrap;
        }

        &:last-child {
          span {
            margin-left: 164px;
          }
        }
      }
    }
  }
  > tbody {
    > tr {
      &:last-child {
        > td {
          &:first-child {
            border-bottom-left-radius: 12px;
          }
          &:last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }

    .noResults {
      text-align: center;
      padding: 16px;
    }
  }
`;

export const ClinicWrapper = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.color.white};
  border-radius: 8px 0 0 8px;

  > div {
    width: 220px;
  }
`;
