import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.warning2};
  background: ${({ theme }) => theme.color.warning3};

  svg {
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    path {
      fill: ${({ theme }) => theme.color.warning};
    }
  }

  p {
    color: ${({ theme }) => theme.color.warning};
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }
`;
