import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'common/components';
import { ButtonsWrapper } from './styles';
import { ButtonSectionProps } from './modules';

const ButtonSection: FC<ButtonSectionProps> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <ButtonsWrapper>
      <PrimaryButton type={'submit'} disabled={disabled}>
        {t('save')}
      </PrimaryButton>
    </ButtonsWrapper>
  );
};

export default ButtonSection;
