import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setPolicyType } from 'applicaiton/store/reducers/AgreementsAndPolicies/AgreementsAndPoliciesSlice';
import { AgreementAndPolicyType } from 'applicaiton/store/reducers/AgreementsAndPolicies/models';

export const useAgreementsAndPoliciesModal = () => {
  const dispatch = useAppDispatch();
  const { policyType } = useAppSelector((state) => state.agreementsAndPolicies);

  const openCookiesModal = () => {
    dispatch(setPolicyType(AgreementAndPolicyType.COOKIES));
  };
  const openPrivacyPolicyModal = () => {
    dispatch(setPolicyType(AgreementAndPolicyType.PRIVACY_POLICY));
  };
  const openTermsAndConditionsModal = () => {
    dispatch(setPolicyType(AgreementAndPolicyType.TERMS_AND_CONDITIONS));
  };
  const closeModal = () => {
    dispatch(setPolicyType(null));
  };
  const isModalOpen = policyType !== null;
  return {
    isModalOpen,
    policyType,
    closeModal,
    openCookiesModal,
    openPrivacyPolicyModal,
    openTermsAndConditionsModal,
  };
};
