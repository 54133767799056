import React from 'react';
import { IconButtonStyled, Wrapper, Title } from './styles';
import { AddIcon } from 'applicaiton/assets/chats';
import { useTranslation } from 'react-i18next';
import { SidebarHeaderProps } from './module';
import { ChatsMode } from 'applicaiton/constants/chats';
import { useAppSelector } from 'common/hooks/redux';
import { ChooseChatType } from '../ChooseChatType';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from 'applicaiton/assets/expand-less.svg';
import { AddConversationMenu } from '../AddConversationMenu';

export const ChatsSidebarHeader = ({
  onNewChatClick,
  onNewMassMessagesClick,
}: SidebarHeaderProps) => {
  const { t } = useTranslation();
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [addMenuAnchor, setAddMenuAnchor] = React.useState<null | HTMLElement>(
    null,
  );
  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleOpenAddMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddMenuAnchor(event.currentTarget);
  };
  const { mode } = useAppSelector((state) => state.chats);

  const handleClose = () => {
    setMenuAnchor(null);
  };
  const handleCloseAddMenu = () => {
    setAddMenuAnchor(null);
  };

  const handleSingleChatClick = () => {
    setAddMenuAnchor(null);
    onNewChatClick();
  };

  const handleMassMessagesClick = () => {
    setAddMenuAnchor(null);
    onNewMassMessagesClick();
  };

  return (
    <Wrapper>
      <Title onClick={handleOpenMenu}>
        <h1 className={'title'}>
          {mode === ChatsMode.Chats
            ? t('chats.sidebar_title')
            : t('chats.archives_sidebar_title')}
        </h1>
        {menuAnchor ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Title>

      {mode === ChatsMode.Chats && (
        <IconButtonStyled onClick={handleOpenAddMenu}>
          <AddIcon />
        </IconButtonStyled>
      )}

      <ChooseChatType anchorEl={menuAnchor} onClose={handleClose} />
      <AddConversationMenu
        anchorEl={addMenuAnchor}
        onClose={handleCloseAddMenu}
        onSingleChatClick={handleSingleChatClick}
        onMassMessagesClick={handleMassMessagesClick}
      />
    </Wrapper>
  );
};
