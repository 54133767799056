import React, { FC, ReactNode, useEffect, useState } from 'react';
import {
  Wrapper,
  StyledSection,
  SecretaryInfo,
  Image,
  StyledDivider,
  Name,
} from './styles';

import { useTranslation } from 'react-i18next';
import ClinicProfile from '../ClinicProfile';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import { SecondaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { deleteSecretary } from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import { useNavigate, useParams } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import DeleteModal from '../DeleteModal';
import { SecretaryClinicDto } from '@docbay/schemas';
import { useClinicForm } from 'features/feature-user-secretary-edit/hooks/useClinicForm';
import { FormProvider } from 'react-hook-form';
import SideBar from '../SideBar';

interface LayoutComponentProps {
  children: ReactNode;
}

const LayoutComponent: FC<LayoutComponentProps> = ({ children }) => {
  const [activeForm, setActiveForm] = useState<number>(0);
  const [clinicNames, setClinicsNames] = useState<string[]>([]);
  const [clinics, setClinics] = useState<SecretaryClinicDto[]>([]);
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.secretaries);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const methods = useClinicForm({
    requiredError: t('errors.required'),
  });

  const deleteProfile = () => {
    dispatch(deleteSecretary(id!)).then(() => {
      navigate(PathNames.myUsers);
    });
  };

  const handleAddClinic = () => {
    setClinicsNames((prev) => [...prev, t('users.clinic')]);
    setActiveForm((prev) => prev + 1);
  };

  useEffect(() => {
    setActiveForm(0);
    setClinicsNames(
      currentUser && currentUser.clinics
        ? currentUser.clinics.map((clinic) => clinic?.name!)
        : [],
    );
    const clinicsRelations = currentUser?.clinics;

    const formattedData = clinicsRelations?.map((item) => {
      const formattedClinic = {
        id: item.id!,
        professionalIds: item?.professionals
          ? item?.professionals.map((professional) => professional.id!)
          : [''],
      };

      return formattedClinic;
    });
    if (clinicsRelations) {
      setClinics(formattedData!);
    }
  }, [currentUser]);

  const addNewClinic = () => {
    if (!activeForm || !Object.keys(methods.formState.errors).length) {
      methods.reset();
      const newClinicData: SecretaryClinicDto = {
        id: '',
        professionalIds: [],
      };
      setClinics((prevClinics) => [...prevClinics, newClinicData]);
      setClinicsNames((prev) => [...prev, t('users.clinic')]);
      setActiveForm(clinics.length + 1);
    }
  };

  return (
    <Wrapper>
      <SecretaryInfo>
        <div>
          <Image>
            {currentUser?.photo ? (
              <img
                src={currentUser?.photo?.thumbnailUrl}
                alt={currentUser?.photo?.thumbnailUrl}
              />
            ) : (
              <PersonIcon />
            )}
          </Image>
          <Name>
            {currentUser?.firstName} {currentUser?.lastName}
          </Name>
        </div>
        <SecondaryButton onClick={() => setShowDeleteModal(true)}>
          {t('users.delete_profile')}
        </SecondaryButton>
      </SecretaryInfo>

      <StyledDivider />

      <StyledSection>
        <FormProvider {...methods}>
          <SideBar
            clinics={clinicNames}
            handleAddClinic={addNewClinic}
            selectedClinic={activeForm}
            setSelectedClinic={setActiveForm}
          />
          {activeForm ? (
            <ClinicProfile
              arrayIndex={activeForm > 1 ? activeForm - 1 : 0}
              addClinic={handleAddClinic}
              allClinics={clinics}
              setAllClinics={setClinics}
            />
          ) : (
            <>{children}</>
          )}
        </FormProvider>
      </StyledSection>

      <DeleteModal
        isOpen={showDeleteModal}
        typeName={`${currentUser?.firstName} ${currentUser?.lastName}`}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={deleteProfile}
      />
    </Wrapper>
  );
};

export default LayoutComponent;
