import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Option } from 'common/components/DropdownClinics/models';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

export interface ClinicsT {
  clinic: Option[];
}

export const useClinicsForm = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const clinicFormValues = {
    clinic: [],
  };

  const clinicSchema = yup
    .object({
      clinic: yup.array().min(1, requiredError!).required(requiredError!),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<ClinicsT>({
    resolver: yupResolver(clinicSchema),
    defaultValues: clinicFormValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
  };
};
