import { commonColors } from 'applicaiton/theme';
import styled from 'styled-components';

interface StyledProps {
  isChatTeleconsultation: boolean;
}

export const MessageFormStyled = styled.form<StyledProps>`
  position: relative;
  display: flex;
  gap: 6px;
  padding: 4px 4px 4px 62px;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.grey100};
`;

export const InputStyled = styled.textarea`
  width: 100%;
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.color.grey500};
  font-size: 16px;
  height: 44px;
  resize: none;
  padding: 12px;

  &:focus-visible {
    border: none;
    outline: none;
  }
`;

export const SendButton = styled.button`
  all: unset;
  width: 44px;
  min-width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.blue300};
  border-radius: 4px;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.color.grey600};
      }
    }
  }
`;

export const DocumentButtonStyled = styled('button')`
  all: unset;
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  min-width: 52px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${commonColors.grey200};
  border-radius: 4px 0 0 4px;
  border-right: 1px solid ${commonColors.grey200};

  &:disabled {
    pointer-events: none;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    svg {
      path {
        fill: ${commonColors.grey600};
      }
    }
  }
`;
