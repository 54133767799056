import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ConfirmPasswordChange,
  CreateNewPassword,
  ForgotPassword,
  Login,
  Registration,
  RegistrationExpiredCode,
} from 'common';
import { PathNames } from '../routes/constants';

const AuthPage: FC = () => {
  const { pathname } = useLocation();

  const showPage = () => {
    switch (pathname) {
      case PathNames.login:
        return <Login />;
      case PathNames.finishRegistration:
        return <Registration />;
      case PathNames.expiredVerificationCode:
        return <RegistrationExpiredCode />;
      case PathNames.confirmChange:
        return <ConfirmPasswordChange />;
      case PathNames.createNewPassword:
        return <CreateNewPassword />;
      case PathNames.forgotPassword:
        return <ForgotPassword />;

      default:
        <Login />;
    }
  };

  return <section className="App-content">{showPage()}</section>;
};

export default AuthPage;
