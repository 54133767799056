import React, { FC } from 'react';
import { Wrapper } from './styles';
import { Header, LayoutComponent } from './components';

const AdminEdit: FC = () => {
  return (
    <Wrapper>
      <Header />

      <LayoutComponent />
    </Wrapper>
  );
};

export default AdminEdit;
