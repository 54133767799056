import React, { FC, useState } from 'react';
import { Props } from './models';
import { StyledTr, ActionsStyled } from './styles';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import AddFamilyHistoricModal from '../AddFamilyHistoricModal';
import { ConfirmModal } from '../ConfirmModal';
import { useTranslation } from 'react-i18next';
import SuccessModal from '../SuccessModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { deletePatientFamilyHistoricData } from 'applicaiton/store/reducers/Consultation/ActionCreators';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';

const FamilyHistoricItem: FC<Props> = ({ item, updateMedicalBackground }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();
  const [familyHistoricModalOpen, setFamilyHistoricModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const handleSavedFamilyHistoricModal = () => {
    setFamilyHistoricModalOpen(false);
    updateMedicalBackground();
  };

  const deleteHistoricalData = () => {
    dispatch(
      deletePatientFamilyHistoricData({
        patientId: currentAppointment?.patient?.id!,
        familyHistoryId: item.id,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        setConfirmModalOpen(false);
        setSuccessModalOpen(true);
      }
    });
  };

  const handleCloseSuccessModal = () => {
    updateMedicalBackground();
    setSuccessModalOpen(false);
  };

  return (
    <StyledTr>
      <td>{item?.familyMember}</td>
      <td>{item?.disease?.[localizeNameKey]}</td>
      <td>{item?.comments}</td>
      <td>
        <ActionsStyled>
          <button onClick={() => setFamilyHistoricModalOpen(true)}>
            <EditIcon />
          </button>
          <button onClick={() => setConfirmModalOpen(true)}>
            <DeleteIcon />
          </button>
        </ActionsStyled>
      </td>
      {familyHistoricModalOpen && (
        <AddFamilyHistoricModal
          history={item}
          onClose={() => setFamilyHistoricModalOpen(false)}
          onSave={handleSavedFamilyHistoricModal}
        />
      )}
      <ConfirmModal
        onConfirm={deleteHistoricalData}
        isOpen={confirmModalOpen}
        onCancel={() => setConfirmModalOpen(false)}
      >
        <p>
          {t('consultation_data.confirm_delete')}{' '}
          {t('consultation_data.family_historic_header')}?
        </p>
      </ConfirmModal>
      {successModalOpen && (
        <SuccessModal
          typeName={t('consultation_data.family_historic_header')}
          onClose={handleCloseSuccessModal}
        />
      )}
    </StyledTr>
  );
};
export default FamilyHistoricItem;
