import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccessIcon } from 'applicaiton/assets/success-icon.svg';
import { SuccessModalProps } from './modules';
import { StyledModal } from './styles';

const SuccessModal: FC<SuccessModalProps> = ({ name, onClose }) => {
  const { t } = useTranslation();
  return (
    <StyledModal>
      <CloseIcon onClick={onClose} />
      <div>
        <SuccessIcon />
        <p>
          {t('appointment.delete_type.success.titlePart1')} <span>{name}</span>{' '}
          {t('appointment.delete_type.success.titlePart2')}
        </p>
        <SecondaryButton onClick={onClose}>
          {t('appointment.delete_type.success.ok')}
        </SecondaryButton>
      </div>
    </StyledModal>
  );
};

export default SuccessModal;
