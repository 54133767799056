import { currentClinicOwnerRegistration } from 'applicaiton/sessionStorage/clinicRegistration';

export const useBillingInfo = () => {
  const billingInfo = {
    companyName: currentClinicOwnerRegistration().billingInfo.companyName,
    address: currentClinicOwnerRegistration().billingInfo.address,
    iban: currentClinicOwnerRegistration().billingInfo.iban,
  };

  return billingInfo;
};
