import dayjs from 'dayjs';

const TIME_FORMAT_24H = 'HH:mm';

export const getTimeIn24H = (date?: Date | string | null) => {
  return date ? dayjs(date).format(TIME_FORMAT_24H) : '';
};

export const secondsToTime = (seconds: number) => {
  const secondsAbs = Math.abs(seconds);
  const minutes = Math.floor(secondsAbs / 60)
    .toString()
    .padStart(2, '0');
  const secString = (secondsAbs % 60).toString().padStart(2, '0');
  const result = `${minutes}:${secString}`;
  return seconds < 0 ? `-${result}` : result;
};
