import React, { useRef } from 'react';
import { SubscribersListProps } from './model';
import { ResultsItemStyled, SearchResultsWrapper } from './styles';
import { SearchIcon } from 'applicaiton/assets';

export const SubscribersList = ({
  subscribers,
  onSubscriberSelected,
  clearSearch,
}: SubscribersListProps) => {
  const refSearchResults = useRef(null);
  return (
    <SearchResultsWrapper ref={refSearchResults}>
      {subscribers.map((subscriber) => (
        <ResultsItemStyled
          key={subscriber.id}
          onClick={() => onSubscriberSelected(subscriber)}
        >
          <SearchIcon />
          <div>
            <p className={'name'}>
              {subscriber.firstName} {subscriber.lastName}
            </p>
            <p className={'phone'}>{subscriber.phone}</p>
          </div>
        </ResultsItemStyled>
      ))}
    </SearchResultsWrapper>
  );
};
