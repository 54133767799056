import styled from 'styled-components';
import { VideoWrapperStyled } from './modules';

export const Wrapper = styled.article`
  width: 100%;
  height: calc(100vh - 184px);
`;

export const VideoWrapper = styled.article<VideoWrapperStyled>`
  display: flex;
  width: 100%;
  height: inherit;
  padding: 0 20px;

  > div:last-child {
    margin-left: 20px;
  }

  @media screen and (max-width: 900px) {
    & > :first-child {
      display: ${({ isPreviewOpened }) => (isPreviewOpened ? 'none' : '')};
    }
  }
`;
