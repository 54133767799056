import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { NotificationsSettingsActions } from 'applicaiton/constants/actions';
import { NotificationsSettingsAPI } from 'integration/api/notifications-settings';
import { UpdateNotificationsSettingsProps } from 'common/types/notificationsSettings';

export const fetchNotificationsSettings = createAsyncThunk(
  NotificationsSettingsActions.fetchNotificationsSettings,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } =
        await NotificationsSettingsAPI.fetchNotificationsSettings(id);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const updateNotificationsSettings = createAsyncThunk(
  NotificationsSettingsActions.updateNotificationsSettings,
  async (params: UpdateNotificationsSettingsProps, { rejectWithValue }) => {
    try {
      const { data } =
        await NotificationsSettingsAPI.updateNotificationsSettings(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
