import { Box, styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { commonColors } from 'applicaiton/theme';

export const ModalWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
`;

export const ContentContainerStyled = styled(Box)`
  display: flex;
  flex-direction: column;

  & .title {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    color: ${commonColors.grey900};
    font-size: 20px;
    font-weight: 700;
    line-height: 140%; /* 28px */
    letter-spacing: 0.2px;
  }

  & .description {
    color: ${commonColors.grey700};
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const LinkStyled = styled(Link)`
  color: ${commonColors.blue700};
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-decoration-line: underline;
`;

export const ButtonContainerStyled = styled(Box)`
    display: flex;
    justify-content: flex-end;
    button {
        width: auto;
        padding: ${({ theme }) => theme.spacing(2, 3)}
    }
;
}
`;

export const WarningItemsListStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const WarningItemStyled = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: flex-start;

  svg {
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  }

  & .warning-text {
    color: ${commonColors.grey700};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  & .clinic-name {
    font-weight: 700;
  }
`;
