import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from 'applicaiton/assets/expand-less.svg';
import { AccordionStyled } from './styles';
import Input from '../Input';
import TextArea from '../TextArea';
import { QuestionItemText } from 'common/types/clinics';

interface AccordionQuestionProps {
  question: string;
  answer: string;
  onChange: (value: QuestionItemText) => void;
  defaultName?: string;
  id: string;
}

const AccordionQuestion: FC<AccordionQuestionProps> = ({
  question,
  answer,
  onChange,
  defaultName,
  id,
}) => {
  const { t } = useTranslation();
  const [showList, setShowList] = useState(false);

  const handleShowList = (e: any) => {
    if (e.target.id) return;
    setShowList((prev) => !prev);
  };

  return (
    <AccordionStyled onClick={handleShowList}>
      <div>
        <p className="accordion-title">{question || defaultName}</p>
        <div className="accordion-expand">
          {!showList ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </div>
      </div>
      {showList && (
        <div className="accordion-list">
          <Input
            id={id}
            placeholder={t('accordion_question.input_placeholder') || ''}
            type="text"
            value={question}
            onChange={(e) => onChange({ question: e.target.value })}
          />
          <TextArea
            id={id}
            placeholder={t('accordion_question.textArea_placeholder') || ''}
            value={answer}
            onChange={(e) => onChange({ answer: e.target.value })}
            textLength={answer}
            maxTextLength={400}
          />
        </div>
      )}
    </AccordionStyled>
  );
};

export default memo(AccordionQuestion);
