import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';

export const useProfessionalEditWarnings = () => {
  const warnings = useAppSelector(
    (state) => state.professionalProfile.editProfessionalWarnings ?? [],
  );
  const dispatch = useAppDispatch();
  const clearWarnings = () => {
    dispatch(professionalProfileActions.resetUpdateProfessionalWarnings());
  };

  return {
    warnings,
    clearWarnings,
  };
};
