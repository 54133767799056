import dayjs from 'dayjs';

export const getReadableDuration = (mins: number) => {
  const currentTime = dayjs.duration(mins, 'minutes');

  const formattedTime =
    mins % 60 === 0
      ? `${currentTime.hours()} h`
      : mins < 60
      ? `${currentTime.minutes()} min`
      : `${currentTime.hours()} h ${currentTime.minutes()} min`;

  return formattedTime;
};
