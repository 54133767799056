import React, { FC, useEffect, useMemo, useState } from 'react';
import { ClinicProfileProps } from './types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DropdownClinics,
  Loader,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import {
  Wrapper,
  ButtonsWrapper,
  StyledDivider,
  SelectSection,
  Header,
} from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import SuccessModal from '../SuccessModal';
import { PathNames } from 'applicaiton/routes';
import { uploadedPhoto } from 'applicaiton/store/reducers/Photo/PhotoSlice';
import {
  resetSecretaryData,
  setSecretaryData,
} from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';
import AssigningProfessionals from '../AssigningProfessionals';
import {
  editSecretary,
  getSecretaryById,
} from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { fetchProfessionals } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { Option } from 'common/components/DropdownProfessionals/models';
import { FormProvider, useFormContext } from 'react-hook-form';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { ClinicResponseDto } from '@docbay/schemas';

const ClinicProfile: FC<ClinicProfileProps> = ({
  arrayIndex,
  allClinics,
  setAllClinics,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [professionalsToAdd, setProfessionalsToAdd] = useState<Option[]>([]);
  const { isLoading: professionalIsLoading, professionals } = useAppSelector(
    (state) => state.professionals,
  );
  const [chosenClinic, setChosenClinic] = useState<
    ClinicResponseDto | undefined
  >(undefined);

  const { clinics } = useAppSelector((state) => state.clinics);
  const { id } = useParams();
  const { currentUser } = useAppSelector((state) => state.secretaries);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const methods = useFormContext();
  const currentClinic = allClinics[arrayIndex];

  const getChosenClinic = (id: string) => {
    const chosenClinic = clinics.find((clinic) => String(clinic.id) === id);
    return chosenClinic;
  };

  useEffect(() => {
    setChosenClinic(getChosenClinic(currentClinic.id));
  }, [currentClinic]);

  const isStateChanged = useMemo(() => {
    const { id } = methods.getValues();

    const currentClinicProfessionals =
      currentUser?.clinics?.find(
        (clinic) => String(clinic.id) === currentClinic.id,
      )?.professionals || [];

    const professionalIdList = currentClinicProfessionals?.map(
      (professional) => professional.id,
    );

    const defaultData = {
      id: currentClinic.id,
      professionalIds: professionalIdList.sort(),
    };

    const currentChanges = {
      id: id,
      professionalIds: professionalsToAdd
        .map((option) => String(option.value)!)
        .sort(),
    };

    const hasChanges = compareObjectsChanges(currentChanges, defaultData, true);

    return hasChanges;
  }, [
    currentUser?.clinics,
    currentClinic,
    methods.watch('id'),
    methods.watch('professionalIds'),
  ]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const professionalsOptions = useMemo(() => {
    return professionals.map((item) => ({
      value: item.id,
      label: item.firstName + ' ' + item.lastName,
      image: item.mainPhoto?.thumbnailUrl || '',
      specializations: item.specializations,
    }));
  }, [professionals]);

  useEffect(() => {
    if (currentClinic) {
      const currentClinicProfessionals =
        currentUser?.clinics?.find(
          (clinic) => String(clinic.id) === currentClinic.id,
        )?.professionals || [];

      const professionalIds = currentClinicProfessionals?.map(
        (professional) => professional.id,
      );

      const professionalOptionsSelected = professionalsOptions.filter(
        (option) => professionalIds.includes(option.value),
      );

      methods.setValue('id', currentClinic.id || '');

      methods.setValue('professionalIds', professionalIds!);

      setProfessionalsToAdd(professionalOptionsSelected!);
    } else {
      methods.reset();
    }
  }, [arrayIndex, professionals]);

  useEffect(() => {
    methods.watch('id') &&
      dispatch(fetchProfessionals({ clinicIds: methods.watch('id') }));
  }, [methods.watch('id')]);

  const closeSuccessModal = () => {
    dispatch(resetSecretaryData());
    navigate(PathNames.myUsers);
    dispatch(uploadedPhoto([]));
    setIsCreated(false);
    navigate(PathNames.myUsers);
  };

  const showProfessionalDropdown = methods.watch('id') && professionals.length;

  const handleClinicChange = (id: string) => {
    methods.setValue('id', id);
    methods.setValue('professionalIds', []);

    const updatedArray = allClinics.map((clinic, index) => {
      if (index === arrayIndex) {
        return { id: id, professionalIds: [] };
      }
      return clinic;
    });
    setAllClinics(updatedArray);

    setProfessionalsToAdd([]);

    methods.clearErrors('id');
  };

  const handleUpdateClinicList = () => {
    const formattedClinicList = allClinics.map((clinic) => {
      const formattedData = {
        id: clinic.id!,
        professionalIds: clinic.professionalIds,
      };
      return formattedData;
    });

    return formattedClinicList;
  };

  const handleSave = async () => {
    await dispatch(
      editSecretary({
        id: currentUser?.id! || '',
        data: { clinics: handleUpdateClinicList() },
      }),
    );
    await dispatch(getSecretaryById(id!));
  };

  const handleCancel = () => {
    dispatch(setSecretaryData(null));
    navigate(PathNames.myUsers);
  };

  const handleSetProfessionals = (professionalsToAdd: Option[] | []) => {
    const { id } = methods.getValues();

    const updatedArray = allClinics.map((clinic, index) => {
      if (index === arrayIndex) {
        return {
          id: id,
          professionalIds: professionalsToAdd.map(
            (option) => String(option.value)!,
          ),
        };
      }
      return clinic;
    });

    setAllClinics(updatedArray);
  };
  const notIncludingClinics = () => {
    const currentClinicSelected = currentUser?.clinics?.find(
      (clinic) => String(clinic.id) === currentClinic.id,
    );

    if (currentClinicSelected?.id) {
      const clinicsWithoutAddedItem = currentUser?.clinics!.filter(
        (clinic) => clinic.id !== currentClinicSelected.id,
      );

      return clinicsWithoutAddedItem;
    }
    if (!currentClinicSelected?.id) return currentUser?.clinics;
  };

  return (
    <Wrapper>
      {professionalIsLoading && <Loader />}

      <FormProvider {...methods}>
        <section>
          <Header>
            <h1>
              {chosenClinic?.name || arrayIndex === 1
                ? t('users.clinic')
                : `${t('users.clinic')} ${arrayIndex + 1}`}
            </h1>
          </Header>

          <StyledDivider />

          <SelectSection>
            <DropdownClinics
              value={methods.watch('id')}
              label={t('users.select_a_clinic_for_secretary')!}
              isMulti={false}
              placeholder={
                t('professional_profile.select_clinic_from_list') || ''
              }
              onChange={(value) => {
                handleClinicChange(String(value));
              }}
              isError={!!methods.formState.errors.id}
              errorMessage={
                methods.formState.errors.id && t('errors.required')!
              }
              notIncludingClinics={notIncludingClinics()}
            />
          </SelectSection>

          {showProfessionalDropdown ? (
            <AssigningProfessionals
              professionalsToAdd={professionalsToAdd}
              setProfessionalsToAdd={setProfessionalsToAdd}
              professionalsOptions={professionalsOptions}
              handleSetProfessionals={handleSetProfessionals}
            />
          ) : null}
        </section>

        <section>
          <StyledDivider />

          <ButtonsWrapper>
            <SecondaryButton onClick={handleCancel}>
              {t('cancel')}
            </SecondaryButton>
            <PrimaryButton
              type={'button'}
              onClick={methods.handleSubmit(handleSave)}
              disabled={
                !!Object.keys(methods.formState.errors).length ||
                !isStateChanged
              }
            >
              {t('save')}
            </PrimaryButton>
          </ButtonsWrapper>
        </section>

        {isCreated && (
          <SuccessModal
            typeName={`${currentUser?.firstName} ${currentUser?.lastName}`}
            onClose={closeSuccessModal}
          />
        )}
      </FormProvider>
    </Wrapper>
  );
};

export default ClinicProfile;
