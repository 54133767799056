import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import {
  setAgendaProfessionals,
  setSelectedProfessionals,
} from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { CalendarView, ViewBy } from 'applicaiton/constants/scheduler';
import { commonColors } from 'applicaiton/theme';

import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Checkbox } from 'common/components';

import TableRow from './TableRow';
import { AgendaListProps } from './models';
import {
  Wrapper,
  ProfessionalItem,
  ProfessionalInfo,
  TableStyled,
  TableWrapper,
} from './styles';
import { useDaysOfWeek } from 'features/feature-scheduler/helpers/useDaysOfWeek';
import { meetingPlaces } from 'features/feature-scheduler/constants/meetingPlace';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';

const AgendaList: FC<AgendaListProps> = ({
  appointments,
  absences,
  confusingAgendas,
  isViewByAvailability,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();
  const { viewBy, byProfessionals, byAvailability } = useAppSelector(
    (state) => state.scheduler,
  );

  const daysOfWeek = useDaysOfWeek();

  const isViewByProfessionals = viewBy === ViewBy.byProfessionals;
  const currentView = isViewByProfessionals ? byProfessionals : byAvailability;
  const agendaProfessionals = currentView.agendaProfessionals;
  const selectedDate = currentView.selectedDate;
  const isWeeklyView = currentView.calendarView === CalendarView.weekly;
  const absenceBackgroundColor = commonColors.grey300;

  const getEventsList = (professionalId: string) => {
    const list = [...appointments, ...absences].filter(
      (item) => item.priorityId === professionalId,
    );

    const flatConfusingAgenda = confusingAgendas
      .map((item) => [...item.absences, ...item.appointments])
      .flat()
      .map((item) => [
        ...(item.confusingAgenda?.absences || []),
        ...(item.confusingAgenda?.appointments || []),
      ])
      .flat();

    return list.map((item) => {
      const hasConfusing = flatConfusingAgenda.some(
        (confusingItem) => confusingItem.id === item.id,
      );
      const isTeleconsultation =
        item.meetingPlace === meetingPlaces.teleconsultation;

      return {
        id: String(item.id),
        patientName: item.patientName || '',
        patientPhoneNumber: item.patientPhoneNumber || '',
        priorityId: String(item.priorityId),
        startDate: item.startDate,
        endDate: item.endDate,
        appointmentType: item.appointmentType || t('appointment.absence') || '',
        status: '',
        isAppointment: !!item.patientName,
        colorCode: item.colorCode || absenceBackgroundColor,
        clinicName: item.clinicName || '',
        isTeleconsultation,
        hasConfusing,
        clinicOwnerId: item.clinicOwnerId,
      };
    });
  };

  const getSelectedDaysOfWeek = () => {
    const currDate = dayjs(selectedDate).locale(i18n.language); // get current date
    const dayOfWeek = currDate.day() === 0 ? 6 : currDate.day() - 1;
    const firstDay = currDate.date() - dayOfWeek; // First day is the day of the month - the day of the week
    const secondDay = firstDay + 1;
    const thirdDay = firstDay + 2;
    const forthDay = firstDay + 3;
    const fivesDay = firstDay + 4;
    const sixesDay = firstDay + 5;
    const sevensDay = firstDay + 6;
    const currentMoth = dayjs(selectedDate).get('month');
    const lastDatePrevMonth = dayjs(
      new Date(2008, currentMoth, 0).toUTCString(),
    ).get('date');

    return [
      firstDay,
      secondDay,
      thirdDay,
      forthDay,
      fivesDay,
      sixesDay,
      sevensDay,
    ].map((item, index) => {
      return {
        name: daysOfWeek[index].name,
        date: dayjs(
          new Date(
            currDate
              .month(item < 0 ? currentMoth - 1 : currentMoth)
              .set('date', item < 0 ? lastDatePrevMonth + item : item)
              .toDate(),
          ).toUTCString(),
        ).locale(i18n.language),
      };
    });
  };

  const getScheduler = (professionalId: string) => {
    const events = getEventsList(professionalId).sort((a, b) =>
      a.startDate > b.startDate ? 1 : -1,
    );
    if (isWeeklyView || isViewByAvailability) {
      const daysOfCurrentWeek = getSelectedDaysOfWeek();
      const filteredEvents = daysOfCurrentWeek
        .filter((day) => {
          const findDay = events.find((item) =>
            day.date.isSame(item.startDate, 'date'),
          );
          if (findDay) {
            return day;
          }
        })
        .map((day) => {
          const findEvents = events.filter((item) =>
            day.date.isSame(item.startDate, 'date'),
          );
          return {
            date: dayjs(day.date).format('DD/MM'),
            name: day.name,
            events: findEvents,
          };
        });
      return filteredEvents;
    } else {
      const dayOfWeek = dayjs(selectedDate).day() || 0;
      const currentDay = daysOfWeek.find((item) => item.id === dayOfWeek - 1);
      return [
        {
          date: dayjs(selectedDate).format('DD/MM'),
          name: currentDay?.name,
          events,
        },
      ];
    }
  };

  const checkProfessionalSelected = (value: string) => {
    return agendaProfessionals.some((item) => item.id === value);
  };

  const handleUncheckProfessional = (professionalId: string) => {
    const filteredAgendaProfessionals = agendaProfessionals.filter(
      (item) => item.id !== professionalId,
    );

    dispatch(
      setAgendaProfessionals({
        professionals: filteredAgendaProfessionals,
        key: viewBy,
      }),
    );
    dispatch(
      setSelectedProfessionals({
        professionals: filteredAgendaProfessionals,
        key: viewBy,
      }),
    );
  };

  return (
    <Wrapper>
      {agendaProfessionals.map((professional) => (
        <div key={professional.id}>
          {agendaProfessionals.length > 1 && (
            <ProfessionalItem>
              <div>
                {professional?.photo ? (
                  <img
                    src={professional.photo.thumbnailUrl}
                    alt={professional.id}
                  />
                ) : (
                  <div>
                    <p>
                      {professional?.firstName ? professional.firstName[0] : ''}
                    </p>
                    <p>
                      {professional?.lastName ? professional.lastName[0] : ''}
                    </p>
                  </div>
                )}
                <ProfessionalInfo>
                  <h2>
                    {professional?.firstName} {professional?.lastName}
                  </h2>
                  <div>
                    {professional?.specializations?.map((item, index) => {
                      return (
                        <p key={item.id}>
                          {item[localizeNameKey]}
                          {index + 1 !== professional?.specializations.length &&
                            ','}
                        </p>
                      );
                    })}
                  </div>
                </ProfessionalInfo>
              </div>
              <Checkbox
                id={`agenda-professional-${String(professional?.id)}`}
                checked={checkProfessionalSelected(String(professional?.id))}
                onChange={() =>
                  handleUncheckProfessional(String(professional.id))
                }
              />
            </ProfessionalItem>
          )}
          {getScheduler(String(professional.id)).map((day) => (
            <TableWrapper key={day.name}>
              <p>
                {day.name}, {day.date}
              </p>
              <TableStyled>
                <thead>
                  <tr>
                    <th>{t('patients_name')}</th>
                    <th>
                      <span>{t('time')}</span>
                    </th>
                    <th>
                      <span>{t('type')}</span>
                    </th>
                    <th>
                      <span>{t('status')}</span>
                    </th>
                    <th>
                      <span>{t('actions')}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {day.events.map((item) => (
                    <TableRow key={item.id} item={item} />
                  ))}
                </tbody>
              </TableStyled>
            </TableWrapper>
          ))}
        </div>
      ))}
    </Wrapper>
  );
};

export default memo(AgendaList);
