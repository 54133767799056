import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import {
  AppointmentColor,
  DropdownStyled,
  OptionsList,
  TitleText,
  Wrapper,
  AppointmentOption,
  EmptyResult,
} from './styles';
import { DropdownAppointmentTypesProps, AppointmentTypeOption } from './models';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchAppointmentTypes } from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import Input from '../Input';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import Checkbox from '../Checkbox';
import { Link } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';

const DropdownAppointmentTypes: FC<DropdownAppointmentTypesProps> = ({
  value,
  label,
  placeholder,
  onChange,
  disabled,
  isError,
  errorMessage,
  clinicId,
  professionalId,
  specializationIds,
  withSearch,
  isMulti = false,
  isOpen,
  setIsAppointmentDeleted,
}) => {
  const userRole = getUserRole();
  const userId = getUserId();
  const isUserRoleProfessional = userRole === UserRoles.professional;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { appointmentTypes } = useAppSelector(
    (state) => state.appointmentTypes,
  );

  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchItem, setSearchItem] = useState('');

  useEffect(() => {
    if (isOpen) {
      setIsActive(true);
    }
  }, [isOpen]);
  useOnClickOutside(ref, () => setIsActive(false));

  useEffect(() => {
    const specializations = isUserRoleProfessional
      ? {}
      : { specializationIds: specializationIds };

    if (clinicId && specializationIds) {
      dispatch(
        fetchAppointmentTypes({
          clinicId: clinicId,
          ...specializations,
          ...(isUserRoleProfessional ? { professionalIds: userId! } : {}),
          ...(professionalId ? { professionalIds: professionalId! } : {}),
        }),
      );
    }
  }, [clinicId, professionalId, specializationIds]);

  const optionList = useMemo(() => {
    if (!appointmentTypes) return [];

    const filteredOptions = appointmentTypes.filter((item) => {
      return item.professionals?.find((prof) => prof.id === professionalId);
    });
    if (value && !Array.isArray(value)) {
      const appointmentExist = filteredOptions.some(
        (item) => item.id === value.value,
      );

      if (
        filteredOptions.length &&
        !appointmentExist &&
        setIsAppointmentDeleted
      ) {
        setIsAppointmentDeleted();
      }
    }
    const appointmentList = professionalId ? filteredOptions : appointmentTypes;

    const options = appointmentList.map((item) => ({
      label: item?.title || '',
      value: item?.id,
      colorCode: item?.colorCode,
      duration: item?.durationInMinutes,
      specializationIds: item?.specialization?.id,
    }));

    if (searchItem) {
      return options.filter((item) =>
        item?.label
          ?.toLocaleLowerCase()
          .includes(searchItem.toLocaleLowerCase()),
      );
    }

    return options;
  }, [appointmentTypes, searchItem, specializationIds]);

  const handleChange = (selectedItem: AppointmentTypeOption) => {
    if (!isMulti) {
      onChange(selectedItem);
      setIsActive(false);
    }

    if (Array.isArray(value) && isMulti) {
      const valueAlreadyAdded = value.some(
        (item) => item.value === selectedItem.value,
      );
      if (valueAlreadyAdded) {
        const filteredValue = value.filter(
          (item) => item.value !== selectedItem.value,
        );
        onChange(filteredValue as AppointmentTypeOption[]);
      } else {
        onChange([
          ...(value as AppointmentTypeOption[]),
          selectedItem as AppointmentTypeOption,
        ]);
      }
    }
    return;
  };

  const IsValueChecked = (item: AppointmentTypeOption) => {
    if (!isMulti) {
      const currentValue: AppointmentTypeOption =
        value as AppointmentTypeOption;

      return currentValue?.value === item.value;
    }

    //checking if checkbox checked
    const isValueArray = Array.isArray(value);
    return isValueArray && value.some((i) => i.value === item.value);
  };

  const getValue = () => {
    if (isMulti) {
      const currentValue = value as AppointmentTypeOption[];

      return currentValue.length > 0 ? (
        `${currentValue.length} ${t('selected')}`
      ) : (
        <span>
          {placeholder ||
            t('appointment_types.select_appointment_types_placeholder')}
        </span>
      );
    } else {
      const currentValue = value as AppointmentTypeOption;

      if (currentValue) {
        return (
          <h2>
            <AppointmentColor colorCode={currentValue!.colorCode as string} />
            {currentValue!.label}
          </h2>
        );
      } else {
        return (
          <span>
            {placeholder ||
              t('appointment_types.select_appointment_types_placeholder')}
          </span>
        );
      }
    }
  };

  return (
    <Wrapper isError={isError}>
      {label && <label>{label}</label>}
      <DropdownStyled
        isError={isError}
        isActive={isActive}
        disabled={disabled}
        ref={ref}
        onMouseDown={() => !disabled && setIsActive(true)}
      >
        <section>
          <>
            {getValue()}
            <ExpandMoreIcon />
          </>
        </section>
        {isActive && (
          <OptionsList className="appointment-types-options">
            {withSearch && (
              <Input
                id={'search'}
                type="search"
                value={searchItem}
                onChange={(e) => setSearchItem(e.target?.value || '')}
                placeholder={t('appointment_types.search_types')!}
                onClear={() => setSearchItem('')}
              />
            )}
            {!optionList.length && (
              <EmptyResult>
                <PickIcon />
                <p>
                  {t('appointment_types.empty_result.textPart1')}{' '}
                  <Link to={PathNames.typesAppointments} target="_blank">
                    {t(
                      'appointment_types.type_of_appointments',
                    ).toLocaleLowerCase()}
                  </Link>{' '}
                  {t('appointment_types.empty_result.textPart2')}
                </p>
              </EmptyResult>
            )}
            {optionList.map((item) => (
              <AppointmentOption
                key={item?.value}
                htmlFor={String(item.value)}
                selected={IsValueChecked(item!)}
                isMulti={isMulti}
              >
                <div>
                  <AppointmentColor colorCode={item?.colorCode as string} />
                  <TitleText>
                    <span>{item?.label}</span>
                    <span>
                      {t('appointment_types.table.duration')}: {item?.duration}
                    </span>
                  </TitleText>
                </div>
                <Checkbox
                  id={String(item.value)}
                  checked={IsValueChecked(item)}
                  onChange={() => handleChange(item)}
                />
              </AppointmentOption>
            ))}
          </OptionsList>
        )}
      </DropdownStyled>
      {errorMessage && <span>{errorMessage}</span>}
    </Wrapper>
  );
};

export default DropdownAppointmentTypes;
