import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HelpIcon } from 'applicaiton/assets/help.svg';
import { LinkStyled, HeaderStyled, DeviderStyled } from './styles';
import { SecondaryButton } from 'common/components';
import { DocBaySupportLink } from 'applicaiton';

interface HeaderProps {
  title: string;
  subtitle?: ReactNode;
  description?: string;
}

const Header: FC<HeaderProps> = ({ title, subtitle, description }) => {
  const { t } = useTranslation();

  return (
    <div>
      <HeaderStyled>
        <div>
          <h1>{title}</h1>
          <div>{subtitle}</div>
          <p>{description}</p>
        </div>
        <LinkStyled to={DocBaySupportLink} target="_blank">
          <SecondaryButton styleType={'color'} type="button">
            <HelpIcon />
            {t('clinicRegistration.header.help')}
          </SecondaryButton>
        </LinkStyled>
      </HeaderStyled>
      <DeviderStyled />
    </div>
  );
};

export default Header;
