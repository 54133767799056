import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { ProfessionalEditClinicT } from '../components/ClinicInformation/types';

export const useEditClinicForm = ({
  requiredError,
}: {
  requiredError: string;
}) => {
  const formClinicValues = {
    clinic: '',
    specializationsToAdd: [],
    hours: [],
    appointmentTypes: [],
    appointmentTypesToDelete: [],
  };

  const profileGeneralSchema = yup
    .object({
      clinic: yup.string().required(requiredError!),
      specializationsToAdd: yup
        .array()
        .min(1, requiredError!)
        .required(requiredError!),
      hours: yup.array().min(0, requiredError!).required(requiredError!),
      appointmentTypes: yup.array(),
      appointmentTypesToDelete: yup.array(),
    })
    .required();

  const methods = useForm<ProfessionalEditClinicT>({
    resolver: yupResolver(profileGeneralSchema),
    defaultValues: formClinicValues,
  });

  return methods;
};
