import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { TimerWrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import { authService } from 'common';
import { SubPathNames } from 'features/feature-forgot-password/constants';

const Timer: FC = () => {
  const { t } = useTranslation();
  const targetEndTime = authService.getTimer();
  const fiveMinutesInMilliseconds = 300000;
  const initialTimer = targetEndTime
    ? Math.max(0, Math.floor((parseInt(targetEndTime, 10) - Date.now()) / 1000))
    : 300;

  const [timer, setTimer] = useState(initialTimer);
  const navigate = useNavigate();

  useEffect(() => {
    if (!targetEndTime) {
      const newTargetEndTime = Date.now() + fiveMinutesInMilliseconds;
      authService.saveTimer(newTargetEndTime.toString());
    }

    const intervalId = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        clearInterval(intervalId);
        navigate(`${PathNames.confirmChange}${SubPathNames.expired}`);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  useEffect(() => {
    return () => {
      authService.removeTimer();
    };
  }, []);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;

  return (
    <TimerWrapper>
      <span>{t('auth.forgot_password.still_active_code')}</span>&nbsp;
      <span>
        {formattedTime} {t('auth.forgot_password.min')}
      </span>
    </TimerWrapper>
  );
};

export default Timer;
