import { TeleconsultationState } from './models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createChat,
  getConnectionData,
  getConnectionDataByRoomId,
} from './ActionCreators';
import { ConnectionDataResponseDto } from '@docbay/schemas';

const initialState: TeleconsultationState = {
  room: null,
  accessToken: null,
  isLoading: false,
  chatIsCreated: false,
  error: '',
  messages: [],
  client: null,
  conversation: null,
  isAudioOn: true,
  isVideoOn: true,
  roomCompleted: false,
};

const teleconsultationSlice = createSlice({
  name: 'teleconsultations',
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    addMessage: (state, action) => {
      state.messages = state.messages.concat(action.payload);
    },
    removeMessage: (state, action) => {
      state.messages = state.messages.filter(
        (item) => item.sid !== action.payload.sid,
      );
    },
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setConversation: (state, action) => {
      state.conversation = action.payload;
    },
    setTeleconsultationAccessData: (
      state,
      action: PayloadAction<ConnectionDataResponseDto>,
    ) => {
      state.accessToken = action.payload.accessToken || null;
      state.room = action.payload.room;
    },
    setIsAudioOn: (state, action: PayloadAction<boolean>) => {
      state.isAudioOn = action.payload;
    },
    setIsVideoOn: (state, action: PayloadAction<boolean>) => {
      state.isVideoOn = action.payload;
    },
    setRoomCompleted: (state, action: PayloadAction<boolean>) => {
      state.roomCompleted = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConnectionData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getConnectionData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getConnectionData.fulfilled, (state, action) => {
      state.room = String(action.payload.room);
      state.accessToken = action.payload.accessToken;
      state.isLoading = false;
    });
    builder.addCase(createChat.pending, (state) => {
      state.chatIsCreated = false;
      state.isLoading = true;
    });
    builder.addCase(createChat.rejected, (state, action) => {
      state.isLoading = false;
      state.chatIsCreated = false;
      state.error = action.payload as string;
    });
    builder.addCase(createChat.fulfilled, (state, action) => {
      state.chatIsCreated = true;
      state.isLoading = false;
    });
    // create connection by room id;
    builder.addCase(getConnectionDataByRoomId.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getConnectionDataByRoomId.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.room = null;
      state.accessToken = null;
      state.roomCompleted = true;
    });
    builder.addCase(getConnectionDataByRoomId.fulfilled, (state, action) => {
      state.room = action.payload.room;
      state.accessToken = action.payload.accessToken;
      state.isLoading = false;
    });
  },
});
export const {
  setMessages,
  setClient,
  setConversation,
  addMessage,
  removeMessage,
  setTeleconsultationAccessData,
  setIsAudioOn,
  setIsVideoOn,
  setRoomCompleted,
} = teleconsultationSlice.actions;

export default teleconsultationSlice.reducer;
