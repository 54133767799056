import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-template-rows: min-content;
  padding: 0 8px;
  min-width: 260px;
  width: 260px;
  max-height: 100vh;
  background: ${({ theme }) => theme.color.background};
`;
