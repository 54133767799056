import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  checkSecretariesVerificationCode,
  createSecretariesPassword,
  createSecretary,
  deleteSecretary,
  editSecretary,
  getSecretaryById,
  secretarySetNewEmail,
  secretarySetNewPhone,
  secretaryVerifyCode,
  secretaryVerifyOldEmail,
  secretaryVerifyPhone,
} from './ActionCreators';
import { SecretariesState } from './models';
import { SecretaryCreationDto, SecretaryResponseDto } from '@docbay/schemas';
import {
  AuthLocalStorage,
  setUsersRefreshTokenSessionStorage,
} from 'applicaiton/sessionStorage/auth';

const initialState: SecretariesState = {
  total: 0,
  page: 0,
  totalPages: 0,
  profileChanges: null,
  currentUser: null,
  isLoading: false,
  error: '',
  isAdded: false,
  isUpdated: false,
  isVerificationCodeExpired: false,
  isCreatePassword: false,
  isVerifiedOldEmail: false,
  isVerifiedOldEmailCode: false,
  isVerifiedOldPhone: false,
  isVerifiedOldPhoneCode: false,
  isResentActive: true,
  isWrongVerificationCode: false,
};

const secretariesSlice = createSlice({
  name: 'secretariesSlice',
  initialState,
  reducers: {
    setSecretaryData: (
      state,
      actions: PayloadAction<SecretaryCreationDto | null>,
    ) => {
      state.profileChanges = actions.payload;
    },
    resetSecretaryData: (state) => {
      state.profileChanges = null;
      state.isAdded = false;
      state.isUpdated = false;
      state.error = '';
      state.currentUser = null;
    },
    resetIsUpdatedSecretaryData: (state) => {
      state.isUpdated = false;
      state.error = '';
    },
    resetEmailData: (state) => {
      state.isVerifiedOldEmail = false;
      state.isVerifiedOldEmailCode = false;
      state.isWrongVerificationCode = false;
    },
    resetVerifiedOldEmailCode: (state) => {
      state.isVerifiedOldEmailCode = false;
    },
    setCurrentData: (state, action) => {
      state.currentUser = action.payload;
    },
    resetVerifiedOldPhoneCode: (state) => {
      state.isVerifiedOldPhoneCode = false;
    },
    resetPhoneData: (state) => {
      state.isVerifiedOldPhone = false;
      state.isVerifiedOldPhoneCode = false;
      state.isWrongVerificationCode = false;
    },
    setIsResentActive: (state, actions) => {
      state.isResentActive = actions.payload;
    },
    setIsWrongVerificationCode: (state, actions) => {
      state.isWrongVerificationCode = actions.payload;
    },
    resetSecretaryError: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    //create secretary
    builder.addCase(createSecretary.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createSecretary.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createSecretary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isAdded = true;
    });
    //get secretary by id
    builder.addCase(getSecretaryById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSecretaryById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getSecretaryById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentUser = action.payload;
    });
    //delete secretary
    builder.addCase(deleteSecretary.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSecretary.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteSecretary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentUser = null;
      state.profileChanges = null;
    });
    //edit secretary
    builder.addCase(editSecretary.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editSecretary.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(editSecretary.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isUpdated = true;
      state.currentUser = action.payload;
    });
    //secretary check code expiration dto
    builder.addCase(checkSecretariesVerificationCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      checkSecretariesVerificationCode.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      },
    );
    builder.addCase(checkSecretariesVerificationCode.fulfilled, (state) => {
      state.isLoading = false;
    });
    //secretary create password
    builder.addCase(createSecretariesPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createSecretariesPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isCreatePassword = false;
    });
    builder.addCase(createSecretariesPassword.fulfilled, (state) => {
      state.isLoading = false;
      state.isCreatePassword = true;
    });

    //verify old email
    builder.addCase(secretaryVerifyOldEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(secretaryVerifyOldEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(secretaryVerifyOldEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldEmail = true;
    });

    //verify code
    builder.addCase(secretaryVerifyCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(secretaryVerifyCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(secretaryVerifyCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldEmailCode = !!action.payload;
    });

    //set new email
    builder.addCase(secretarySetNewEmail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(secretarySetNewEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(secretarySetNewEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.token) {
        setUsersRefreshTokenSessionStorage(action.payload);

        const newData = {
          ...state.currentUser,
          email: String(sessionStorage.getItem('newEmail')),
        };

        state.currentUser = newData as SecretaryResponseDto;

        const profiles = JSON.parse(
          localStorage.getItem(AuthLocalStorage.profiles) as string,
        );

        const newProfile = {
          ...profiles[0],
          email: String(sessionStorage.getItem('newEmail')),
          token: action.payload.token,
        };

        localStorage.setItem(
          AuthLocalStorage.profiles,
          JSON.stringify([newProfile]),
        );
        sessionStorage.removeItem('oldEmail');
        sessionStorage.removeItem('newEmail');
      }
    });

    //verify old phone
    builder.addCase(secretaryVerifyPhone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(secretaryVerifyPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(secretaryVerifyPhone.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldPhone = true;
    });
    //set new phone
    builder.addCase(secretarySetNewPhone.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(secretarySetNewPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(secretarySetNewPhone.fulfilled, (state, action) => {
      state.isLoading = false;

      if (action.payload.token) {
        setUsersRefreshTokenSessionStorage(action.payload);

        const profiles = JSON.parse(
          localStorage.getItem(AuthLocalStorage.profiles) as string,
        );

        const newData = {
          ...state.currentUser,
          phone: String(sessionStorage.getItem('newPhone')),
        };

        state.currentUser = newData as SecretaryResponseDto;

        const newProfile = {
          ...profiles[0],
          phone: String(sessionStorage.getItem('newPhone')),
          token: action.payload.token,
        };

        localStorage.setItem(
          AuthLocalStorage.profiles,
          JSON.stringify([newProfile]),
        );
        sessionStorage.removeItem('oldPhone');
        sessionStorage.removeItem('newPhone');
      }
    });
  },
});

export const {
  setSecretaryData,
  resetSecretaryData,
  resetIsUpdatedSecretaryData,
  resetVerifiedOldPhoneCode,
  resetPhoneData,
  resetEmailData,
  resetVerifiedOldEmailCode,
  setIsResentActive,
  setIsWrongVerificationCode,
  resetSecretaryError,
} = secretariesSlice.actions;

export default secretariesSlice.reducer;
