import React from 'react';
import { ActiveChatMenuProps } from './module';
import { MenuItemStyled, MenuStyled } from './styles';
import { ReactComponent as PersonsIcon } from 'applicaiton/assets/persons.svg';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import { useTranslation } from 'react-i18next';

export const AddConversationMenu = ({
  anchorEl,
  onClose,
  onSingleChatClick,
  onMassMessagesClick,
}: ActiveChatMenuProps) => {
  const { t } = useTranslation();

  const menuIsOpen = !!anchorEl;

  return (
    <>
      <MenuStyled
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuIsOpen}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItemStyled onClick={onSingleChatClick}>
          <PersonIcon />
          {t('chats.single_chat')}
        </MenuItemStyled>
        <MenuItemStyled onClick={onMassMessagesClick}>
          <PersonsIcon />
          {t('chats.mass_messages')}
        </MenuItemStyled>
      </MenuStyled>
    </>
  );
};
