import { startChatTeleconsultation } from 'applicaiton/store/reducers/Chats/ActionCreators';
import { PathNames } from 'applicaiton/routes';
import { useAppDispatch } from 'common/hooks/redux';

export const useTeleconsultationRequest = () => {
  const dispatch = useAppDispatch();
  const startTeleconsultation = async (conversationSid: string) => {
    const response: any = await dispatch(
      startChatTeleconsultation({ conversationSid }),
    );
    if (response.meta.requestStatus === 'fulfilled') {
      const roomId = response?.payload?.room;
      joinTeleconsultation(roomId);
    }
  };

  const joinTeleconsultation = (roomId?: string) => {
    if (roomId) {
      window.open(
        `${PathNames.teleconsultation}?room=${roomId}`,
        '_blank',
        'noopener,noreferrer',
      );
    } else {
      console.error('Room is not provided to start teleconsultaion');
    }
  };

  const acceptTeleconsultationRequest = async (
    conversationSid: string,
    messageSid?: string,
  ) => {
    const patientMessageToUpdate = messageSid
      ? {
          conversationSid,
          messageSid,
        }
      : undefined;
    const request = {
      conversationSid,
      patientMessageToUpdate,
    };

    const response: any = await dispatch(startChatTeleconsultation(request));
    if (response.meta.requestStatus === 'fulfilled') {
      const roomId = response?.payload?.room;
      joinTeleconsultation(roomId);
    }
  };

  return {
    startTeleconsultation,
    joinTeleconsultation,
    acceptTeleconsultationRequest,
  };
};
