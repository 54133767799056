import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { formatPhoneNumber } from './number';
import { useTranslation } from 'react-i18next';

declare module 'yup' {
  interface StringSchema {
    phoneNumber(error: string): StringSchema;
    zipCode(error: string, clinicActive: boolean): StringSchema;
  }
}

yup.addMethod(yup.string, 'phoneNumber', function (errorMessage) {
  return this.test('test-phone-number', errorMessage, function (value) {
    const { path, createError } = this;

    return (
      isValidPhoneNumber(formatPhoneNumber(value)) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.string, 'zipCode', function (errorMessage, clinicActive) {
  return this.test('test-zip-code', errorMessage, function (value) {
    if (!value && !clinicActive) return true;
    const zipCodePTValidation =
      /^[1-9][0-9]{3}$|^(?:^[1-9][0-9]{3}|\(^[1-9][0-9]{3}\))([-/.])\d{3}$/;
    const zipCodeFRValidation =
      /^(?:0[1-9]|[13-8][0-9]|2[ab1-9]|9[0-5])(?:[0-9]{3})?|9[78][1-9](?:[0-9]{2})?/;

    return (
      !!value?.match(zipCodePTValidation) || !!value?.match(zipCodeFRValidation)
    );
  });
});

export const useProfileSchema = (isEditPage: boolean) => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const lengthError = t('errors.at_least_2_characters');
  const maxLengthError = t('errors.max_characters');

  return yup.object({
    name: isEditPage
      ? yup
          .string()
          .min(2, lengthError)
          .max(255, maxLengthError)
          .required(requiredError)
      : yup.string(),
    description: yup.string().max(2000),
    photos: yup.array().of(
      yup.object().shape({
        key: yup.string(),
        photoUrl: yup.string(),
        thumbnailUrl: yup.string(),
      }),
    ),
    isWaitingList: yup.string(),
  });
};

export const useProfileSchemaActive = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const lengthError = t('errors.at_least_2_characters');
  const maxLengthError = t('errors.max_characters');
  return yup.object({
    name: yup
      .string()
      .min(2, lengthError)
      .max(255, maxLengthError)
      .required(requiredError),
    description: yup
      .string()
      .min(2, lengthError)
      .max(2000)
      .required(requiredError),
    photos: yup.array().of(
      yup.object().shape({
        key: yup.string(),
        photoUrl: yup.string(),
        thumbnailUrl: yup.string(),
      }),
    ),
    isWaitingList: yup.string(),
  });
};

export const useAddressSchema = () => {
  const { t } = useTranslation();
  const zipCodeError = t('errors.zip_code_not_valid') || '';
  const requiredError = t('errors.required');
  return yup.object({
    supportedCountry: yup.object().shape({
      id: yup.string(),
      name: yup.string(),
      code: yup.string(),
    }),
    address: yup.string(),
    room: yup.string(),
    streetNumber: yup.string().required(requiredError),
    floor: yup.string(),
    zipCode: yup.string().zipCode(zipCodeError, false),
  });
};

export const useAddressSchemaActive = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const zipCodeError = t('errors.zip_code_not_valid') || '';

  return yup.object({
    supportedCountry: yup
      .object()
      .shape({
        id: yup.string().required(),
        name: yup.string().required(),
        code: yup.string().required(),
      })
      .required(requiredError),
    address: yup.string().required(requiredError),
    streetNumber: yup.string().required(requiredError),
    room: yup.string(),
    floor: yup.string(),
    zipCode: yup.string().required(requiredError).zipCode(zipCodeError, true),
  });
};
