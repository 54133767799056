import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { UserType } from 'features/feature-users-list/hooks/useUserTypeOptions';
import { ReactComponent as CancelIcon } from 'applicaiton/assets/cancel.svg';

interface DeleteModalProps {
  isOpen: boolean;
  typeName: string;
  onClose: () => void;
  onSubmit: () => void;
  userType: UserType | '';
}

const DeleteModal: FC<DeleteModalProps> = ({
  isOpen,
  typeName,
  onClose,
  onSubmit,
  userType,
}) => {
  const { t } = useTranslation();
  const isAdmin = userType === UserType.Admin;

  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <h1>
          {isAdmin
            ? t('users.delete_type.titlePart1')
            : t('users.delete_type.titlePart1_secretary')}{' '}
          <br />
          <span>{typeName}</span> {t('users.delete_type.profile')}?
        </h1>

        <div>
          <p> {t('clinicsConfiguration.delete_profile.description')}</p>
          <div>
            <CancelIcon />
            {t('clinicsConfiguration.delete_profile.descriptionTextUsers')}
          </div>
        </div>

        <section>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton onClick={onSubmit} styleType={'error'}>
            {t('users.delete_type.confirm')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default DeleteModal;
