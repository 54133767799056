import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/unsuccess-icon.svg';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { EmailChangedPopUpProps } from './modules';
import { StyledModal, ConfirmSection, WarningSection } from './styles';

const EmailChangedPopUp: FC<EmailChangedPopUpProps> = ({
  professionalName,
  email,
  onClose,
  onSubmit,
  isEmailExist,
}) => {
  const { t } = useTranslation();
  return (
    <StyledModal>
      <div>
        <CloseIcon onClick={onClose} />
        {!isEmailExist ? (
          <ConfirmSection>
            <h1>
              {t('email_pop_up.confirm_section.titlePart1')}{' '}
              <span>{professionalName}</span>{' '}
              {t('email_pop_up.confirm_section.titlePart2')}
            </h1>
            <p>
              {t('email_pop_up.confirm_section.description')}{' '}
              <span>{email}</span>
            </p>
            <section>
              <SecondaryButton onClick={onClose}>
                {t('back_to_editing')}
              </SecondaryButton>
              <PrimaryButton onClick={onSubmit}>
                {t('send_activation_link')}
              </PrimaryButton>
            </section>{' '}
          </ConfirmSection>
        ) : (
          <WarningSection>
            <WarningIcon />
            <h1>
              {t('email_pop_up.warning_section.titlePart1')}{' '}
              {t('email_pop_up.warning_section.titlePart2')}{' '}
              <span>{email}</span>{' '}
              {t('email_pop_up.warning_section.titlePart3')}
            </h1>
            <p>{t('email_pop_up.warning_section.description')}</p>
            <SecondaryButton onClick={onClose}>
              {t('back_to_editing')}
            </SecondaryButton>
          </WarningSection>
        )}
      </div>
    </StyledModal>
  );
};

export default EmailChangedPopUp;
