import { useState } from 'react';

export const useDynamicTime = (updateIntervalMs: number) => {
  const [dynamicTime, setDynamicTime] = useState(new Date());
  const [currentInterval, setCurrentInterval] = useState<NodeJS.Timer | null>(
    null,
  );

  const updateAndStopDynamicTime = () => {
    if (currentInterval) {
      setDynamicTime(new Date());
      clearInterval(currentInterval);
      setCurrentInterval(null);
    }
  };

  const startDynamicTime = () => {
    const interval = setInterval(() => {
      setDynamicTime(new Date());
    }, updateIntervalMs);
    setCurrentInterval(interval);
  };
  return {
    dynamicTime,
    updateAndStopDynamicTime,
    startDynamicTime,
  };
};
