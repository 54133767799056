import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { Wrapper } from './styles';

interface Props {
  id: string;
  name: string;
  onDelete: (id: string) => void;
}

const DataChip: FC<Props> = ({ id, name, onDelete }) => {
  return (
    <Wrapper>
      <span>{name}</span>
      <CloseIcon onClick={() => onDelete(id)} />
    </Wrapper>
  );
};

export default DataChip;
