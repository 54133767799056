import { useTranslation } from 'react-i18next';
import { Permissions } from '../constants';

export const useClinicsConfiguration = () => {
  const { t } = useTranslation();

  const clinicsConfiguration = [
    {
      key: Permissions.ClinicOpeningHours,
      name: t(
        'user_permissions_configuration.clinic_configuration.opening_hours',
      ),
    },
    {
      key: Permissions.ClinicFAQ,
      name: t('user_permissions_configuration.clinic_configuration.faqs'),
    },
    {
      key: Permissions.ClinicTeleconsultationPayment,
      name: t(
        'user_permissions_configuration.clinic_configuration.teleconsultation_payment',
      ),
    },
    {
      key: Permissions.ClinicDescription,
      name: t(
        'user_permissions_configuration.clinic_configuration.clinic_description',
      ),
    },
    {
      key: Permissions.ClinicProfessionalsManagement,
      name: t(
        'user_permissions_configuration.clinic_configuration.professionals_management',
      ),
    },
    {
      key: Permissions.ClinicAppointmentTypesConfiguration,
      name: t(
        'user_permissions_configuration.clinic_configuration.appointment_types_configuration',
      ),
    },
    {
      key: Permissions.ClinicDeletion,
      name: t(
        'user_permissions_configuration.clinic_configuration.clinic_deletion',
      ),
    },
    {
      key: Permissions.ClinicCommunicationWithPatients,
      name: t(
        'user_permissions_configuration.clinic_configuration.communication_with_patients',
      ),
    },
    {
      key: Permissions.ClinicInsurancesConfiguration,
      name: t(
        'user_permissions_configuration.clinic_configuration.insurances_configuration',
      ),
    },
  ];

  return clinicsConfiguration;
};
