import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { getRGBAColor } from 'common/helpers/getRGBAColor';
import styled from 'styled-components';

export const StyledAppointment = styled(Appointments.Appointment)`
  background-color: ${({ theme }) => theme.color.white} !important;
  border-radius: 4px;
  border-left: ${({ colorCode }) => `2px solid ${colorCode}`} !important;

  &.highLighted {
    border: ${({ theme }) => `2px solid ${theme.color.blue700}`} !important;
    box-shadow: 4px 4px 10px 0 rgba(129, 36, 16, 0.3) !important;
  }
  &:hover {
    background: ${({ theme }) => theme.color.white} !important;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: ${({ isSmall }) => (isSmall ? '0 4px' : '4px 8px')};
    border: none;

    background: ${({ colorCode }) => getRGBAColor(colorCode)};
    > div {
      > p {
        color: ${({ theme }) => theme.color.grey500};
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  &:hover {
    background: rgba(0, 186, 219, 0.3);
  }

  h2 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  h3 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: 4px;
    > svg {
      width: 12px;
      height: 12px;

      rect {
        fill: ${({ colorCode }) => colorCode};
      }
    }
  }
  > p {
    color: ${({ theme }) => theme.color.grey500};
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }

  div.clinicName {
    display: flex;
    align-items: center;
    overflow: hidden;
    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > svg {
      min-width: 14px;
      min-height: 14px;
    }
  }

  div.minText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    h2 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  div.patientName {
    display: flex;
    align-items: center;
    h3 {
      margin-left: 8px;
      min-width: fit-content;
    }
  }

  div.row {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    div.patientName {
      display: flex;
      align-items: center;
      h3 {
        margin-left: 8px;
      }
    }

    > p {
      margin-left: 8px;
      min-width: fit-content;
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      overflow: hidden;
    }
  }
`;
