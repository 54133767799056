import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

export interface SecretaryInfoT {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  photo?: string;
}

export const useSecretaryInfoForm = ({
  requiredError,
  phoneError,
}: {
  requiredError: string;
  phoneError: string;
}) => {
  const formUserValues = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    photo: '',
  };

  const SecretarySchema = yup
    .object({
      firstName: yup.string().required(requiredError),
      lastName: yup.string().required(requiredError),
      email: yup.string().email(requiredError!).required(requiredError),
      phone: yup.string().test({
        name: 'is-valid',
        test(value, ctx) {
          const isValidNumber = isValidPhoneNumber(`+${String(value)}`);
          if (!isValidNumber) return ctx.createError({ message: phoneError });
          return isValidNumber;
        },
      }),
      photo: yup.string(),
    })
    .required();

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<SecretaryInfoT>({
    resolver: yupResolver(SecretarySchema),
    defaultValues: formUserValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    trigger,
  };
};
