import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ButtonsWrapper } from './styles';

interface ButtonSectionProps {
  onSave: () => void;
  onCancel: () => void;
  disabled: boolean;
  showNextButton?: boolean;
}

const ButtonSection: FC<ButtonSectionProps> = ({
  onCancel,
  onSave,
  disabled,
  showNextButton,
}) => {
  const { t } = useTranslation();

  return (
    <ButtonsWrapper>
      <SecondaryButton onClick={onCancel}>{t('cancel')}</SecondaryButton>
      <PrimaryButton disabled={disabled} onClick={onSave}>
        {showNextButton ? t('next') : t('save')}
      </PrimaryButton>
    </ButtonsWrapper>
  );
};

export default ButtonSection;
