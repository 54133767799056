import React from 'react';
import { ModalStyled } from './styles';
import { ConfirmModalProps } from './module';
import Modal from '@mui/material/Modal';
import { SecondaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import { SuccessIcon } from 'applicaiton/assets';

export const SuccessModal = ({
  isOpen,
  onClose,
  children,
}: ConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen}>
      <ModalStyled>
        <div className={'content-wrapper'}>
          <SuccessIcon />
          {children}
        </div>
        <div className={'actions-wrapper'}>
          <SecondaryButton onClick={onClose}>{t('ok')}</SecondaryButton>
        </div>
      </ModalStyled>
    </Modal>
  );
};
