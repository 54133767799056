import styled from 'styled-components';

interface Props {
  isEmergency?: boolean;
}

export const StyledTr = styled.tr<Props>`
  height: 72px;
  background: ${({ theme }) => theme.color.white};
  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    width: 220px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

    span {
      padding: 6px 12px;
      border-radius: 100px;
      background: ${({ theme, isEmergency }) =>
        isEmergency ? theme.color.errorBackground : theme.color.blue100};
      color: ${({ theme, isEmergency }) =>
        isEmergency ? theme.color.error2 : theme.color.blue800};
    }

    &:first-of-type {
      width: auto;
    }
    &:nth-of-type(2) {
      width: auto;
    }
    &:last-of-type {
      width: 150px;
    }
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  > button {
    display: flex;
    padding: 0;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    margin-right: 20px;
    svg {
      path {
        fill: ${({ theme }) => theme.color.blue700};
      }
    }

    &:last-child {
      margin-right: 0;
      svg {
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }
  }
`;
