import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  DatePicker,
  Dropdown,
  RadioButton,
  TextArea,
  PrimaryButton,
  SecondaryButton,
  PhotoUploaderMultiCrop,
} from 'common/components';
// import { getYearsOptions } from 'common/helpers/getYearsOptions'; --- Will be use in future
import {
  Wrapper,
  DropdownSection,
  RadioButtonSection,
  StyledDivider,
  TextAreaSection,
  ButtonsWrapper,
} from './styles';
import { useGenderOptions } from 'common/hooks/useGenderOptions';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setProfileChanges } from 'applicaiton/store/reducers/Professionals/CreateProfessionalProfileSlice';
import { Gender, ProfessionalPhotoUpdateDto } from '@docbay/schemas';
import { FormProps, TypeInfo } from './types';
import { useProfessionalGeneralForm } from '../../hooks/useProfessionalGeneralForm';
import { Photo } from 'common/types/clinics';
import Social from '../Social';
import { fetchProfessionalsSpokenLanguages } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import WarningMessage from '../WarningMessage';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

// const YEAR_AFTER_BIRTH = 18; --- Will be use in future

const GeneralProfileInfo: FC<FormProps> = ({
  onNextBtnClick,
  onBackBtnClick,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { profileChanges, existingProfessional } = useAppSelector(
    (state) => state.createProfessionalProfile,
  );
  const { languages } = useAppSelector(
    (state) => state.professionalsSpokenLanguages,
  );
  const { isLoading: isUploadingPhoto } = useAppSelector(
    (state) => state.photos,
  );

  const languagesOptions = useMemo(() => {
    const language = getLanguageFromLocalStorage();
    const localizeName = `name_${language.toUpperCase()}` as
      | 'name_EN'
      | 'name_PT';

    return languages
      ?.map((item) => {
        return { label: item[localizeName], value: item.id };
      })
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  }, [languages, i18n.language]);

  useEffect(() => {
    dispatch(fetchProfessionalsSpokenLanguages());
  }, [dispatch]);

  const {
    control,
    setValue,
    errors,
    register,
    handleSubmit,
    getValues,
    watch,
    clearErrors,
  } = useProfessionalGeneralForm({
    requiredError: t('errors.required'),
  });

  useEffect(() => {
    if (profileChanges) {
      setValue('spokenLanguageIds', profileChanges?.spokenLanguageIds || []);

      setValue('dateOfBirth', profileChanges?.dateOfBirth);
      setValue('gender', profileChanges?.gender || 'Male');
      setValue('description', profileChanges?.description || '');
      setValue(
        'socialMedia',
        profileChanges?.socialMedia?.length
          ? profileChanges?.socialMedia
          : [{ name: 'Facebook', url: '' }],
      );
      //--- Will be use in future ---
      // setValue(
      //   'professionalActivityStartDate',
      //   profileChanges?.professionalActivityStartDate || '',
      // );
      // ----------------------------
    }
  }, [
    profileChanges?.spokenLanguageIds,
    // profileChanges?.professionalActivityStartDate, --- Will be use in future
    profileChanges?.dateOfBirth,
    profileChanges?.gender,
    profileChanges?.description,
    profileChanges?.socialMedia,
  ]);

  const genders = useGenderOptions();

  const handleSetChanges = (value: string[] | string, id: TypeInfo) => {
    let newValue: string[] | string = value;
    //--- Will be use in future ---
    // if (id === 'professionalActivityStartDate') {
    //   newValue = `${value}-12-12`;
    // }
    // ----------------------------

    setValue(id, newValue);
    clearErrors(id);
  };

  const handleChangePhotos = (photos: Photo[]) => {
    dispatch(
      setProfileChanges({
        ...profileChanges!,
        photos: photos as ProfessionalPhotoUpdateDto[],
      }),
    );
  };

  const updateProfileChanges = () => {
    const {
      spokenLanguageIds,
      // professionalActivityStartDate, ---Will be use in future
      dateOfBirth,
      gender,
      description,
      socialMedia,
    } = getValues();
    const socialMediaWithURL = socialMedia.filter((item) => !!item.url);

    dispatch(
      setProfileChanges({
        ...profileChanges!,
        spokenLanguageIds,
        // professionalActivityStartDate,  ---Will be use in future
        dateOfBirth,
        gender: gender as Gender,
        ...(description?.length ? { description } : {}),
        ...(socialMediaWithURL?.length
          ? { socialMedia: socialMediaWithURL }
          : {}),
      }),
    );
  };

  const onSubmit = () => {
    updateProfileChanges();
    onNextBtnClick();
  };

  const handleBackBtn = () => {
    updateProfileChanges();
    onBackBtnClick();
  };
  // --- Will be use in future ---
  // const professionalActivityStartDate = watch('professionalActivityStartDate')
  //   ? dayjs(watch('professionalActivityStartDate')).format('YYYY')
  //   : '';

  // const optionsProfessionalActivityStartDate = useMemo(() => {
  //   return getYearsOptions(
  //     !watch('dateOfBirth')
  //       ? dayjs().year()
  //       : Number(dayjs(watch('dateOfBirth')).year()) + YEAR_AFTER_BIRTH,
  //   );
  // }, [watch('dateOfBirth')]);
  // ----------------------------

  return (
    <Wrapper>
      <div>
        <DropdownSection>
          <Dropdown
            id={'spokenLanguageIds'}
            value={getValues('spokenLanguageIds')}
            label={t('personal_information.spoken_languages') || ''}
            placeholder={
              t('personal_information.spoken_languages_placeholder') || ''
            }
            searchPlaceholder={
              t('personal_information.spoken_languages_search') || ''
            }
            onChange={(value) => {
              Array.isArray(value) &&
                handleSetChanges(value, 'spokenLanguageIds');
            }}
            options={languagesOptions}
            isMulti={true}
            errorMessage={(errors?.spokenLanguageIds as any)?.message}
            disabled={!!existingProfessional}
            withSearch={true}
          />
          {/* Will be use in future */}
          {/* <Dropdown
            id={'professionalActivityStartDate'}
            value={professionalActivityStartDate}
            label={t('personal_information.start_work_year') || ''}
            placeholder={
              t('personal_information.start_work_year_placeholder') || ''
            }
            onChange={(value) => {
              if (!Array.isArray(value)) {
                handleSetChanges(
                  String(value.value),
                  'professionalActivityStartDate',
                );
              }
            }}
            options={optionsProfessionalActivityStartDate}
            errorMessage={errors?.professionalActivityStartDate?.message}
            disabled={!!existingProfessional || !watch('dateOfBirth')}
          /> */}
          <DatePicker
            id={'dateOfBirth'}
            label={t('personal_information.date_birth') || ''}
            value={watch('dateOfBirth') || ''}
            format={'YYYY-MM-DD'}
            onChange={(value) => {
              handleSetChanges(value, 'dateOfBirth');
            }}
            maxDate={dayjs(dayjs().subtract(18, 'year')).valueOf()}
            errorMessage={errors?.dateOfBirth?.message}
            disabled={!!existingProfessional}
            views={['year', 'month', 'day']}
            openTo="year"
          />
        </DropdownSection>
        {existingProfessional && (
          <WarningMessage isExistingProfessional={!!existingProfessional} />
        )}
      </div>
      <RadioButtonSection isError={!!errors?.gender?.message}>
        <p>{t('personal_information.gender.title') || ''}</p>
        {genders.map((item) => (
          <RadioButton
            key={item.value}
            id={item.value}
            label={item.label}
            checked={watch('gender') === item.value}
            onChange={(e) => handleSetChanges(e.target.id, 'gender')}
            disabled={!!existingProfessional}
          />
        ))}
        {errors?.gender?.message && <span>{errors?.gender?.message}</span>}
      </RadioButtonSection>
      <TextAreaSection>
        <TextArea
          id={'description'}
          register={register}
          label={t('personal_information.description') || ''}
          textLength={watch('description') || ''}
          maxTextLength={2000}
          errorMessage={errors.description?.message}
          disabled={!!existingProfessional}
        />
      </TextAreaSection>
      <Social
        errors={errors}
        control={control}
        register={register}
        getValues={getValues}
        disabled={!!existingProfessional}
      />
      <PhotoUploaderMultiCrop
        currentPhotos={(profileChanges?.photos || []) as Photo[]}
        setCurrentPhotos={handleChangePhotos}
        label={t('upload_photo_optional')!}
        isDeprecateMainPhotoDeletion={false}
        disabled={!!existingProfessional}
      />

      <StyledDivider />
      <ButtonsWrapper>
        <SecondaryButton onClick={handleBackBtn}>{t('back')}</SecondaryButton>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={!!Object.keys(errors).length || isUploadingPhoto}
        >
          {t('next')}
        </PrimaryButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default GeneralProfileInfo;
