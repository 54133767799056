import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useClinicOwner } from 'common/hooks/useClinicOwner';

import { getClinicOwnerById } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';

import { SubscriptionsLimitModal } from 'features';

import LeavePageModal from '../LeavePageModal';
import Email from '../Email';
import Password from '../Password';
import ProfileHeader from '../ProfileHeader';
import ProfileInfo from '../ProfileInfo';
import Phone from '../ChangingPhone/Phone';
import PrivateDoctorForm from '../PrivateDoctorForm';

import {
  Wrapper,
  StyledSection,
  StepSection,
  StepItem,
  StepText,
  StepSectionWrapper,
  StyledDivider,
} from './styles';

const LayoutComponent: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const { hasUnsavedChanges } = useAppSelector((state) => state.savedChanges);
  const { isPrivateDoctor } = useClinicOwner();
  const [activeForm, setActiveForm] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [nextPageIndex, setNextPageIndex] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getClinicOwnerById(String(id)!));
  }, []);

  const handleChangeActiveForm = (index: number) => {
    if (activeForm === index) return;
    if (hasUnsavedChanges) {
      setShowModal(true);
      setNextPageIndex(index);
    } else {
      setActiveForm(index);
      setNextPageIndex(null);
    }
  };

  const handleSubmit = () => {
    setActiveForm(Number(nextPageIndex));
    setShowModal(false);
    dispatch(setHasUnsavedChanges(false));
  };

  const getCurrentForm = () => {
    switch (activeForm) {
      case 0:
        return isPrivateDoctor ? <PrivateDoctorForm /> : <ProfileInfo />;
      case 1:
        return <Phone />;
      case 2:
        return <Email />;
      case 3:
        return <Password />;
    }
  };

  return (
    <Wrapper>
      <LeavePageModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleSubmit}
      />
      <ProfileHeader currentClinicOwner={currentClinicOwner} />
      <StyledDivider />
      <StyledSection>
        <StepSectionWrapper>
          <StepSection>
            <StepItem>
              <StepText
                onClick={() => handleChangeActiveForm(0)}
                isActive={activeForm === 0}
              >
                {t('general_info')}
              </StepText>
            </StepItem>
            <StepItem>
              <StepText
                onClick={() => handleChangeActiveForm(1)}
                isActive={activeForm === 1}
              >
                {t('phone')}
              </StepText>
            </StepItem>
            <StepItem>
              <StepText
                onClick={() => handleChangeActiveForm(2)}
                isActive={activeForm === 2}
              >
                {t('email')}
              </StepText>
            </StepItem>
            <StepItem>
              <StepText
                onClick={() => handleChangeActiveForm(3)}
                isActive={activeForm === 3}
              >
                {t('password')}
              </StepText>
            </StepItem>
          </StepSection>
        </StepSectionWrapper>
        {getCurrentForm()}
      </StyledSection>
      <SubscriptionsLimitModal />
    </Wrapper>
  );
};

export default LayoutComponent;
