import React, { FC } from 'react';
import {
  ReserveSlotModal,
  DeleteAbsenceModal,
  Scheduler,
  SideMenu,
} from 'features';
import DeleteAppointmentModal from 'features/feature-delete-appointment';
import { Wrapper } from './styles';

const SchedulerPage: FC = () => (
  <Wrapper>
    <SideMenu />
    <Scheduler />
    <ReserveSlotModal />
    <DeleteAppointmentModal />
    <DeleteAbsenceModal />
  </Wrapper>
);

export default SchedulerPage;
