import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { ReactComponent as BackArrow } from 'applicaiton/assets/back-arrow.svg';
import { PathNames } from 'applicaiton/routes';
import { BackButtonText, BackButtonWrapper, BackLink } from './styles';

const BackButton: FC = () => {
  const { t } = useTranslation();

  return (
    <BackButtonWrapper>
      <BackLink to={PathNames.login}>
        <BackArrow />
        <BackButtonText>{t('auth.back_to_login')}</BackButtonText>
      </BackLink>
    </BackButtonWrapper>
  );
};

export default BackButton;
