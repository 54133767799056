import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';

import { ContactsType } from 'common/types/clinics';
import SocialInput from 'common/components/SocialInput';

import { SocialMediaProps } from './models';
import {
  Close,
  InputWrapper,
  InputContainer,
  WrapperMargin,
  Container,
} from './styles';

const defaultSocialMedia = {
  name: 'Facebook' as ContactsType,
  url: '',
};

const SocialMedia: FC<SocialMediaProps> = ({
  errors,
  register,
  watch,
  setValue,

  disableEditing = false,
}) => {
  const { t } = useTranslation();

  const socialMediaList = watch('professionalData.socialMedia')?.length
    ? watch('professionalData.socialMedia')
    : [defaultSocialMedia];

  const prependKey = 'professionalData.socialMedia';

  const handleRemove = (index: number) => {
    const filteredList = socialMediaList
      .map((item) => item)
      .filter((_, idx) => index !== idx);
    setValue('professionalData.socialMedia', filteredList);
  };

  const handleChangeType = (name: ContactsType, idx: number) => {
    const updatedList = socialMediaList.map((item, index) => {
      if (index === idx) {
        return {
          ...item,
          name,
        };
      }
      return item;
    });
    setValue('professionalData.socialMedia', updatedList);
  };

  const handleAddAnother = () => {
    setValue('professionalData.socialMedia', [
      ...socialMediaList,
      defaultSocialMedia,
    ]);
  };

  return (
    <WrapperMargin>
      {socialMediaList.map((field, index) => {
        const id = `${prependKey}.${index}.url`;
        const values = watch(`${prependKey}.${index}`);
        const error =
          errors?.professionalData?.socialMedia?.[index]?.url?.message;
        const label = index === 0 ? t('edit_contacts.social_media') : undefined;

        return (
          <InputWrapper
            key={`${field.name}-${index}`}
            last={index === socialMediaList.length - 1}
          >
            <InputContainer>
              <SocialInput
                id={id}
                type="text"
                label={label}
                register={register}
                errorMessage={error}
                socialType={values?.name! as ContactsType}
                placeholder={t('edit_contacts.social_media') as string}
                onTypeSelect={(name) => handleChangeType(name, index)}
                disabled={disableEditing}
              />
            </InputContainer>
            {index !== 0 && <Close onClick={() => handleRemove(index)} />}
          </InputWrapper>
        );
      })}
      <Container onClick={handleAddAnother}>
        <PlusIcon />
        <p>{t('add_another')}</p>
      </Container>
    </WrapperMargin>
  );
};

export default SocialMedia;
