import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProfessionalItem from '../ProfessionalItem';
import { TableStyled, Wrapper } from './styles';
import NoDataElement from '../NoDataElement';
import { Loader, Pagination } from 'common/components';
import { Option as ClinicOption } from 'common/components/DropdownClinics/models';
import FilterSection from '../FilterSection';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchProfessionals } from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { useDebounce } from 'common/hooks/useDebounce';

const defaultLimit = 10;

const ProfessionalsTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { professionals, isLoading, totalPages, deletedSuccess } =
    useAppSelector((state) => state.professionals);
  const [searchItem, setSearchItem] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [clinics, setClinics] = useState<ClinicOption[]>([]);
  const searchResult = useDebounce(searchItem, 500);

  useEffect(() => {
    const clinicIds = clinics.map((clinic) => String(clinic.value)).join();
    const page = deletedSuccess
      ? professionals.length === 1
        ? currentPage === 1
          ? 1
          : currentPage - 1
        : 1
      : currentPage;

    dispatch(
      fetchProfessionals({
        searchItem: searchResult,
        page: page,
        limit: defaultLimit,
        clinicIds,
      }),
    );

    setCurrentPage(page);
  }, [dispatch, currentPage, searchResult, clinics, deletedSuccess]);

  const handleRemoveClinic = (id: string) => {
    setClinics((prev) => [...prev.filter((item) => item.value !== id)]);
  };

  return (
    <>
      <Wrapper>
        {isLoading && <Loader />}
        <FilterSection
          searchItem={searchItem}
          setSearchItem={setSearchItem}
          clinics={clinics}
          setClinics={setClinics}
          handleRemoveClinic={handleRemoveClinic}
        />
        <TableStyled>
          <thead>
            <tr>
              <th>
                <span>
                  {t('professionalsListConfiguration.professional_name')}
                </span>
              </th>
              <th>
                <span>
                  {t('professionalsListConfiguration.specialization')}
                </span>
              </th>
              <th>
                <span>{t('professionalsListConfiguration.access_rights')}</span>
              </th>
              <th>
                <span>{t('professionalsListConfiguration.actions')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(professionals) &&
              professionals?.map((professional) => (
                <ProfessionalItem
                  key={professional.id}
                  professional={professional}
                />
              ))}
            {!professionals?.length && <NoDataElement />}
          </tbody>
        </TableStyled>
        {professionals.length ? (
          <Pagination
            pages={totalPages}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            itemCount={professionals.length}
          />
        ) : (
          ''
        )}
      </Wrapper>
    </>
  );
};

export default ProfessionalsTable;
