import styled from 'styled-components';

export const PatientInfo = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 99;
  min-width: 400px;
  transform: translateY(100%);
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  padding-bottom: 16px;
`;

export const PatientData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 16px 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey300};
`;

export const PatientNameSection = styled.div`
  margin-left: 8px;

  > p {
    color: ${({ theme }) => theme.color.grey900};
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.09px;
    margin-bottom: 2px;

    &:last-child {
      color: ${({ theme }) => theme.color.grey600};
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.035px;
      margin-bottom: 0;
    }
`;

export const SeeProfile = styled.div`
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    span {
      color: ${({ theme }) => theme.color.blue800};
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
      letter-spacing: 0.07px;
      margin-right: 4px;
    }

    > svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }
`;

export const Appointments = styled.div`
  margin-top: 12px;
  padding: 0 12px;
  color: ${({ theme }) => theme.color.grey600};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
`;

export const AppointmentsList = styled.div`
  max-height: 272px;
  overflow-y: auto;
  margin-top: 8px;
  padding: 0 12px 10px;
`;
