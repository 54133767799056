import React, { FC, useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import {
  StyledModal,
  TitleWrapper,
  DataSection,
  ButtonWrapper,
} from './styles';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import {
  DropdownClinics,
  Input,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import { Props } from './modules';
import { useTranslation } from 'react-i18next';
import { useAddTopicForm } from 'features/feature-topics-of-chats/hooks/useAddTopicForm';
import { Option } from 'common/components/Dropdown/models';
import { useAppSelector } from 'common/hooks/redux';

const AddTopicModal: FC<Props> = ({ onClose, onAdd, onEdit }) => {
  const { t } = useTranslation();
  const [clinic, setClinic] = useState<Option | null>(null);

  const { topicsChanges } = useAppSelector((state) => state.topics);
  const { clinics } = useAppSelector((state) => state.clinics);
  const { errors, register, handleSubmit, getValues, setValue } =
    useAddTopicForm({
      requiredError: t('errors.required'),
    });

  const handleAddTopic = () => {
    const { title, clinicIds } = getValues();
    !topicsChanges
      ? onAdd(title, clinicIds)
      : onEdit(title, clinicIds, topicsChanges?.id);
  };

  useEffect(() => {
    if (topicsChanges) {
      const currentClinic = clinics.find(
        (item) => item.name === topicsChanges.clinicName,
      );

      setClinic({ label: currentClinic?.name!, value: currentClinic?.id! });
      setValue('clinicIds', String(currentClinic?.id));

      setValue('title', topicsChanges.name);
    }
  }, [topicsChanges]);

  return (
    <Modal open={true}>
      <StyledModal>
        <TitleWrapper>
          <h2>{t('topics_of_chats.add_modal.modal_title') || ''}</h2>
          <CloseIcon onClick={onClose} />
        </TitleWrapper>
        <DataSection>
          <Input
            label={t('topics_of_chats.add_modal.title') || ''}
            id={'title'}
            type="text"
            register={register}
            errorMessage={errors?.title?.message}
            placeholder={t('topics_of_chats.add_modal.placeholder') || ''}
          />
          <DropdownClinics
            isAllDataReturn
            value={(clinic?.value as string) || ''}
            onChange={(value) => {
              setClinic(value as Option);

              const clinic: Option = value as Option;
              setValue('clinicIds', String(clinic.value));
            }}
            isMulti={false}
            label={t('topics_of_chats.add_modal.clinic_name') || ''}
            errorMessage={errors?.clinicIds?.message}
          />
        </DataSection>
        <ButtonWrapper>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton onClick={handleSubmit(handleAddTopic)}>
            {t('save')}
          </PrimaryButton>
        </ButtonWrapper>
      </StyledModal>
    </Modal>
  );
};

export default AddTopicModal;
