import React, { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClinicResponseDto, PhotoResponseDto } from '@docbay/schemas';
import { Input, Loader, PhotoUploaderMulti } from 'common/components';
import TextArea from 'common/components/TextArea';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Photo } from 'common/types/clinics';
import {
  DividerStyled,
  InputWrapper,
  Wrapper,
  CheckboxWrapper,
  SwitchStyled,
} from './styles';
import {
  useProfileSchema,
  useProfileSchemaActive,
} from '../../helpers/validation';
import ButtonSection from '../ButtonSection';
import {
  createClinicProfile,
  saveClinicProfile,
} from 'applicaiton/store/reducers/Clinics/ActionCreators';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { PathNames } from 'applicaiton/routes';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import {
  setProfileChanges,
  setShowSuccessModal,
} from 'applicaiton/store/reducers/Clinics/ClinicProfileSlice';
import { ClinicDataT, ProfileInfoProps } from './models';
import { setPhotosToDelete } from 'applicaiton/store/reducers/Photo/PhotoSlice';
import Switch from 'common/components/Switch';

const EditProfile: FC<ProfileInfoProps> = ({ handleSetNextPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isCreatePage = location.pathname.endsWith('create');
  const dispatch = useAppDispatch();
  const { isLoading, clinicProfileData, profileChanges } = useAppSelector(
    (state) => state.clinicProfile,
  );
  const { photosToDelete } = useAppSelector((state) => state.photos);
  const isClinicActive = clinicProfileData?.status === 'Active';
  const profileSchemaActive = useProfileSchemaActive();
  const profileSchema = useProfileSchema(!isCreatePage);

  const currentPhotos = useMemo(() => {
    const existingImages = clinicProfileData?.photos?.filter(
      (item) => item.id !== clinicProfileData?.mainPhoto?.id,
    );

    return (
      profileChanges?.photos || [
        ...(clinicProfileData?.mainPhoto ? [clinicProfileData?.mainPhoto] : []),
        ...(existingImages || []),
      ] ||
      []
    );
  }, [
    profileChanges?.photos,
    clinicProfileData?.photos,
    clinicProfileData?.mainPhoto,
  ]);

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ClinicDataT>({
    resolver: yupResolver(isClinicActive ? profileSchemaActive : profileSchema),
  });

  const watchDescription = watch('description');

  useEffect(() => {
    setValue('name', clinicProfileData?.name || '');
    setValue('description', clinicProfileData?.description || '');
    setValue('photos', clinicProfileData?.photos || []);
    setValue(
      'isWaitingList',
      String(clinicProfileData?.isWaitingListEnabled) || 'false',
    );
  }, [clinicProfileData]);

  const handleSave = async () => {
    const { name, description, isWaitingList } = getValues();
    const canMoveNext = isCreatePage && name?.length && description.length;

    if (isCreatePage && !clinicProfileData) {
      const response = await dispatch(
        createClinicProfile({
          ...(name ? { name } : { name: '' }),
          ...(description ? { description } : {}),
          ...{ photos: currentPhotos },
          isWaitingListEnabled:
            isWaitingList === 'undefined' ? 'false' : isWaitingList,
        }),
      );
      if (response.meta.requestStatus === 'fulfilled' && canMoveNext) {
        handleSetNextPage();
      }
    } else {
      const response = await dispatch(
        saveClinicProfile({
          id: clinicProfileData?.id!,
          data: {
            ...(name ? { name } : { name: '' }),
            description,
            photos: currentPhotos,
            photosToDelete,
            isWaitingListEnabled: isWaitingList,
          },
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(setPhotosToDelete([]));
        const currentClinic = response.payload as ClinicResponseDto;
        const showSuccessActivated =
          currentClinic.status === 'Active' &&
          !isCreatePage &&
          clinicProfileData?.status !== 'Active';
        if (showSuccessActivated) {
          dispatch(setShowSuccessModal(true));
        }
        if (canMoveNext) {
          handleSetNextPage();
        }
      }
    }
  };

  const handleCancel = () => {
    navigate(PathNames.myClinics);
  };

  const handleChangePhotos = (photos: Photo[]) => {
    dispatch(
      setProfileChanges({
        ...clinicProfileData!,
        photos: photos as PhotoResponseDto[],
      }),
    );
    setValue('photos', photos as PhotoResponseDto[]);
  };

  const isStateChanged = useMemo(() => {
    const { name, description } = getValues();
    const existingImages = clinicProfileData?.photos?.filter(
      (item) => item.id !== clinicProfileData?.mainPhoto?.id,
    );

    if (isCreatePage && !clinicProfileData) {
      return name || description || profileChanges?.photos?.length;
    }

    const hasChanges = compareObjectsChanges(
      {
        ...getValues(),
        photos: currentPhotos.map((item) => ({
          key: item.key,
          photoUrl: item.photoUrl,
          thumbnailUrl: item.thumbnailUrl,
          ...(item.id ? { id: item.id } : {}),
        })),
        ...(photosToDelete.length ? { photosToDelete } : {}),
      },
      {
        name: clinicProfileData?.name,
        description: clinicProfileData?.description || '',
        photos: [
          ...(clinicProfileData?.mainPhoto
            ? [clinicProfileData?.mainPhoto]
            : []),
          ...(existingImages || []),
        ],
        isWaitingList: String(clinicProfileData?.isWaitingListEnabled),
      },
      true,
    );

    return hasChanges;
  }, [
    watch('description'),
    watch('name'),
    watch('isWaitingList'),
    photosToDelete,
    currentPhotos,
    isCreatePage,
  ]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  useEffect(() => {
    return () => {
      dispatch(setProfileChanges(null));
    };
  }, []);

  const isButtonDisabled = isCreatePage ? false : !isStateChanged;

  const showNextButton = isCreatePage && !!watch('description')?.length;

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <div>
        <form>
          <Input
            label={t('editProfileSettings.clinicName') || ''}
            id={'name'}
            type="text"
            register={register}
            errorMessage={errors.name?.message}
            showRequiredIcon={!clinicProfileData?.name}
          />
          <InputWrapper>
            <TextArea
              label={t('description') || ''}
              id={'description'}
              register={register}
              isCount
              maxTextLength={2000}
              textLength={watchDescription || ''}
              errorMessage={errors.description?.message}
              showRequiredIcon={!clinicProfileData?.description}
            />
          </InputWrapper>
        </form>
      </div>
      <CheckboxWrapper>
        <label>{t('editProfileSettings.available_waiting_list')}</label>
        <SwitchStyled>
          <Switch
            checked={watch('isWaitingList') === 'true'}
            onChange={(value) => {
              setValue('isWaitingList', String(value));
            }}
          />
          <p>
            {t('editProfileSettings.add_waiting_list_permissions')}
            {/* will uncommented later */}
            {/*<span>More information</span>*/}
          </p>
        </SwitchStyled>
      </CheckboxWrapper>
      <InputWrapper>
        <PhotoUploaderMulti
          currentPhotos={currentPhotos}
          setCurrentPhotos={handleChangePhotos}
          isDeprecateMainPhotoDeletion={isClinicActive}
        />
      </InputWrapper>
      <div>
        <DividerStyled />
        <ButtonSection
          onSave={handleSubmit(handleSave)}
          onCancel={handleCancel}
          disabled={isButtonDisabled}
          showNextButton={showNextButton}
        />
      </div>
    </Wrapper>
  );
};

export default EditProfile;
