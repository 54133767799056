import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  border-radius: 12px;
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  border: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const TableStyled = styled.table`
  width: 100%;

  > thead {
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
    th {
      vertical-align: middle;
      text-align: start;
      padding: 12px 24px;
      color: ${({ theme }) => theme.color.grey600};
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.07px;
      background: ${({ theme }) => theme.color.grey50};

      &:nth-child(2) {
        width: 200px;
      }
    }
  }
`;
