import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ChatTeleconsultationActions } from 'applicaiton/constants/actions';
import { ChatTeleconsultationAPI } from 'integration/api/chatTeleconsultation';
import { UploadChatTeleconsultationDocumentParams } from 'common/types/chat';

export const uploadChatTeleconsultationDocument = createAsyncThunk(
  ChatTeleconsultationActions.uploadChatTeleconsultationDocument,
  async (
    data: UploadChatTeleconsultationDocumentParams,
    { rejectWithValue },
  ) => {
    try {
      const { data: responseData } =
        await ChatTeleconsultationAPI.uploadChatTeleconsultationDocument(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const downloadChatTeleconsultationDocument = createAsyncThunk(
  ChatTeleconsultationActions.downloadChatTeleconsultationDocument,
  async (messageSid: string, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await ChatTeleconsultationAPI.downloadChatTeleconsultationDocument(
          messageSid,
        );
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const deleteChatDocumentMessage = createAsyncThunk(
  ChatTeleconsultationActions.deleteChatDocumentMessage,
  async (messageSid: string, { rejectWithValue }) => {
    try {
      const { data: responseData } =
        await ChatTeleconsultationAPI.deleteChatDocumentMessage(messageSid);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
