import React, { FC, useEffect, useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import {
  StyledModal,
  TitleWrapper,
  DataSection,
  ButtonWrapper,
} from './styles';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import {
  DatePicker,
  Dropdown,
  InfinityScrollDropdown,
  Input,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import { Props } from './modules';
import { useTranslation } from 'react-i18next';
import { Option } from 'common/components/Dropdown/models';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  getSurgeries,
  setPatientSurgeryData,
  updatePatientSurgeryData,
} from 'applicaiton/store/reducers/Consultation/ActionCreators';
import { useForm } from 'react-hook-form';
import { GeneralInfoForm } from './modules';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { convertTimeZone } from 'common/helpers/convertTimeZone';
import { TimeZone } from 'applicaiton/constants/timeZone';
import { SurgeryStatus } from '@docbay/schemas/dist/medical-background/surgery/surgery-status.enum';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import {
  CommonEntitiesListResponse,
  SurgeryResponseDto,
} from '@docbay/schemas';
import { setSurgeries } from 'applicaiton/store/reducers/Consultation/ConsultationSlice';
import { useLanguage } from 'common/hooks/useLanguage';

const tz = TimeZone.Lisbon;

const AddSurgeryModal: FC<Props> = ({ onClose, onSave, surgery }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchItem, setSearchItem] = useState('');
  const [currentSearchText, setCurrentSearchText] = useState('');
  const { t } = useTranslation();
  const { language } = useLanguage();
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();
  const isEdit = !!surgery;

  const { currentAppointment } = useAppSelector(
    (state) => state.appointmentsSlice,
  );

  const { surgeries, surgeriesTotalPages } = useAppSelector(
    (state) => state.consultationData,
  );

  const addSchema = yup.object({
    name: yup.string().required(t('errors.required')!),
    date: yup.string().required(t('errors.required')!),
    status: yup.string().required(t('errors.required')!),
    comments: yup.string(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm<GeneralInfoForm>({
    resolver: yupResolver(addSchema),
  });

  useEffect(() => {
    if (isEdit) {
      setValue('name', surgery?.surgery.id!);
      setValue('date', surgery?.dateOfSurgery);
      setValue('status', surgery?.status);
      surgery?.comments && setValue('comments', surgery?.comments);

      setCurrentSearchText(surgery?.surgery[localizeNameKey]);
    }
  }, [surgery]);

  const optionsArr = useMemo(() => {
    const options = surgeries.map((item) => {
      const optionName = item[localizeNameKey];

      return {
        value: item.id,
        label: optionName as string,
      };
    });
    return options;
  }, [surgeries]);

  const handleSave = () => {
    const { name, comments, status, date } = getValues();

    if (isEdit) {
      dispatch(
        updatePatientSurgeryData({
          patientId: currentAppointment?.patient?.id!,
          surgeryDataId: surgery?.id,
          data: {
            surgeryId: name,
            dateOfSurgery: dayjs(date).toISOString(),
            comments,
            status: status as SurgeryStatus,
          },
        }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          onSave();
        }
      });
    } else {
      dispatch(
        setPatientSurgeryData({
          patientId: currentAppointment?.patient?.id!,
          data: {
            surgeryId: name,
            dateOfSurgery: dayjs(date).toISOString(),
            comments,
            status: status as SurgeryStatus,
          },
        }),
      ).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          onSave();
        }
      });
    }
  };

  const getSurgeriesData = () => {
    dispatch(
      getSurgeries({
        page: currentPage,
        limit: 10,
        languageIso: language,
        search: searchItem,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        const dataArr =
          data.payload as CommonEntitiesListResponse<SurgeryResponseDto>;

        dispatch(
          setSurgeries(
            currentPage === 1
              ? dataArr?.entities!
              : [...surgeries, ...dataArr.entities!],
          ),
        );

        if (!dataArr?.entities.length) {
          setHasNextPage(!dataArr?.entities.length);
        }
      }
    });
  };
  useEffect(() => {
    if (currentPage <= surgeriesTotalPages || surgeriesTotalPages === 0) {
      getSurgeriesData();
    }
  }, [currentPage, searchItem]);

  const handleGetNewData = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSearchData = (search: string) => {
    setSearchItem(search);
    setHasNextPage(true);
    setCurrentPage(1);
  };

  const headerText = isEdit
    ? t('consultation_data.edit_surgery_header')
    : t('consultation_data.add_surgery_header');

  return (
    <Modal open={true}>
      <StyledModal>
        <TitleWrapper>
          <h2>{headerText}</h2>
          <CloseIcon onClick={onClose} />
        </TitleWrapper>
        <DataSection>
          <InfinityScrollDropdown
            id={'name'}
            placeholder={
              t('consultation_data.add_surgery_name_placeholder') || ''
            }
            value={watch('name') || ''}
            onChange={(value) => {
              const val = value as Option;

              setValue('name', val.value as string, {
                shouldValidate: true,
              });
              setCurrentSearchText(val.label);
            }}
            options={optionsArr}
            label={t('consultation_data.surgeries_table.name') || ''}
            withSearch
            errorMessage={errors?.name?.message}
            getNewData={handleGetNewData}
            hasNextPage={hasNextPage}
            onSearchData={handleSearchData}
            currentValue={currentSearchText}
          />
          <DatePicker
            id={'date'}
            label={t('consultation_data.surgeries_table.date') || ''}
            format={'YYYY-MM-DD'}
            value={dayjs(convertTimeZone(new Date(watch('date')), tz)).format(
              'YYYY-MM-DD',
            )}
            onChange={(value) => {
              setValue('date', value, { shouldValidate: true });
            }}
            maxDate={new Date(new Date()).getTime()}
            errorMessage={errors.date?.message}
          />
          <Input
            id={'comments'}
            type={'text'}
            label={
              t('consultation_data.add_family_historic_comments_label') || ''
            }
            register={register}
            placeholder={
              t('consultation_data.add_family_historic_comments_placeholder') ||
              ''
            }
          />
          <Dropdown
            id={'status'}
            placeholder={
              t('consultation_data.add_surgery_name_placeholder') || ''
            }
            value={watch('status') || ''}
            onChange={(value) => {
              const val = value as Option;

              setValue('status', val.value as string, {
                shouldValidate: true,
              });
            }}
            options={[
              { label: 'Emergency', value: 'EMERGENCY' },
              { label: 'Elective', value: 'ELECTIVE' },
            ]}
            label={t('consultation_data.surgeries_table.status') || ''}
            errorMessage={errors?.name?.message}
          />
        </DataSection>
        <ButtonWrapper>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton
            disabled={!!Object.keys(errors).length}
            onClick={handleSubmit(handleSave)}
          >
            {t('save')}
          </PrimaryButton>
        </ButtonWrapper>
      </StyledModal>
    </Modal>
  );
};

export default AddSurgeryModal;
