import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackToAllClinics from '../BackToAllClinics';
import {
  ContentDivider,
  ContentWrapper,
  EditContent,
  EditList,
  EditListItem,
  EditWrapper,
  NotificationStyled,
  Wrapper,
} from './styles';
import { ReactComponent as InfoIcon } from 'applicaiton/assets/info.svg';
import EditSettingsHeader from '../EditSettingsHeader';
import ProfileAddress from '../ProfileAddress';
import { getClinicFormsList } from 'features/feature-edit-profile-settings/constants/editList';
import EditProfile from '../EditProfile';
import { fetchClinicProfile } from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { DeleteProfileModal } from '../../../index';
import FAQs from '../FAQs';
import PaymentMethods from 'features/feature-edit-profile-settings/component/PaymentMethods';
import Schedule from '../Schedule';
import EditContacts from '../EditContacts';
import LeavePageModal from '../LeavePageModal';
import Insurances from '../Insurances';
import { checkIsPageActive } from 'features/feature-edit-profile-settings/helpers/checkIsPageActive';
import {
  clinicProfile,
  setProfileChanges,
} from 'applicaiton/store/reducers/Clinics/ClinicProfileSlice';
import { ClinicsForms } from 'features/feature-edit-profile-settings/constants/clinicsForms';
import { ClinicResponseDto } from '@docbay/schemas';
import { checkMandatoryFields } from 'features/feature-edit-profile-settings/helpers/checkMandatoryFields';

const MenuList: FC<{
  activePage: ClinicsForms | null;
  setActivePage: (value: ClinicsForms) => void;
  clinicProfileData: ClinicResponseDto | null;
}> = ({ activePage, setActivePage, clinicProfileData }) => {
  const { t } = useTranslation();
  const { hasUnsavedChanges } = useAppSelector((state) => state.savedChanges);
  const [showModal, setShowModal] = useState(false);
  const [nextPage, setNextPage] = useState<ClinicsForms | null>(null);
  const clinicsFormsList = getClinicFormsList(
    Number(clinicProfileData?.owner?.id),
  );
  const isClinicActive = clinicProfileData?.status === 'Active';
  const isRequiredFieldsFilled = checkMandatoryFields(clinicProfileData);
  const handleChangePage = (item: ClinicsForms) => {
    if (activePage === item) return;
    if (hasUnsavedChanges) {
      setShowModal(true);
      setNextPage(item);
    } else {
      setActivePage(item);
      setNextPage(null);
    }
  };

  const handleSubmit = () => {
    setActivePage(nextPage!);
    setShowModal(false);
  };

  return (
    <EditList>
      <LeavePageModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleSubmit}
      />
      {clinicsFormsList.map((item, idx) => {
        const isPageActive = checkIsPageActive(idx, clinicProfileData || null);
        return (
          <EditListItem
            key={item}
            isActive={activePage === item}
            onClick={() => handleChangePage(item)}
          >
            {t(`editProfileSettings.editProfileList.${item}`)}
            {!isPageActive && <InfoIcon />}
          </EditListItem>
        );
      })}
      {!isClinicActive && (
        <NotificationStyled>
          <InfoIcon />
          <p>
            {isRequiredFieldsFilled
              ? t('editProfileSettings.activate_instruction')
              : t('editProfileSettings.notification')}
          </p>
        </NotificationStyled>
      )}
    </EditList>
  );
};

const MainContent = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { clinicProfileData } = useAppSelector((state) => state.clinicProfile);
  const clinicsFormsList = getClinicFormsList(
    Number(clinicProfileData?.owner?.id),
  );
  const [activePage, setActivePage] = useState<ClinicsForms | null>(null);

  useEffect(() => {
    if (!activePage) {
      setActivePage(clinicsFormsList[0]);
    }
  }, [clinicProfileData?.owner?.id]);

  useEffect(() => {
    if (id) {
      dispatch(fetchClinicProfile(String(id)));
    }
  }, [id]);

  const handleSetNextPage = () => {
    if (activePage) {
      const findIndex = clinicsFormsList.indexOf(activePage);
      setActivePage(clinicsFormsList[findIndex + 1]);
    }
  };

  const onUnmountClearChanges = () => {
    dispatch(clinicProfile(null));
    dispatch(setProfileChanges(null));
  };

  useEffect(() => {
    return () => onUnmountClearChanges();
  }, []);

  const getCurrentScreen = () => {
    switch (activePage) {
      case ClinicsForms.Profile:
        return <EditProfile handleSetNextPage={handleSetNextPage} />;
      case ClinicsForms.Address:
        return <ProfileAddress handleSetNextPage={handleSetNextPage} />;
      case ClinicsForms.Contacts:
        return <EditContacts handleSetNextPage={handleSetNextPage} />;
      case ClinicsForms.Payment:
        return <PaymentMethods handleSetNextPage={handleSetNextPage} />;
      case ClinicsForms.Schedule:
        return <Schedule handleSetNextPage={handleSetNextPage} />;
      case ClinicsForms.Insurance:
        return <Insurances handleSetNextPage={handleSetNextPage} />;
      case ClinicsForms.FAQs:
        return <FAQs />;
    }
  };

  return (
    <Wrapper>
      <BackToAllClinics />
      <ContentWrapper>
        <EditSettingsHeader />
        <ContentDivider />
        <EditWrapper>
          <div>
            <MenuList
              activePage={activePage}
              setActivePage={setActivePage}
              clinicProfileData={clinicProfileData}
            />
          </div>
          <EditContent>{getCurrentScreen()}</EditContent>
        </EditWrapper>
      </ContentWrapper>
      <DeleteProfileModal />
    </Wrapper>
  );
};

export default MainContent;
