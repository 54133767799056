import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonWrapper,
  Header,
  ClinicInfo,
  ClinicImage,
  ClinicName,
  TitleSection,
  LinkStyled,
} from './styles';
import { Loader, SecondaryButton } from 'common/components';
import { ReactComponent as EyeOpenIcon } from 'applicaiton/assets/eye-open.svg';
import { ReactComponent as HomeIcon } from 'applicaiton/assets/home.svg';
import { selectProfile } from 'applicaiton/store/reducers/Clinics/ClinicsSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import BadgeWithCheck from 'common/components/BadgeWithCheck';
import { useLocation } from 'react-router-dom';
import { getUserPermissions } from 'applicaiton/sessionStorage/auth';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { DocBayPatientLandingLink } from 'applicaiton';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';
import { useClinicOwner } from 'common/hooks/useClinicOwner';

const EditSettingsHeader = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isCreatePage = location.pathname.endsWith('create');
  const permissions = getUserPermissions();
  const { clinicProfileData, isLoading, profileChanges } = useAppSelector(
    (state) => state.clinicProfile,
  );
  const { isPrivateDoctor } = useClinicOwner();

  const handleDeleteProfile = () => {
    dispatch(selectProfile(clinicProfileData));
  };

  const currentPhoto = useMemo(() => {
    if (profileChanges?.photos?.length) {
      return profileChanges?.photos[0].thumbnailUrl;
    }
    if (clinicProfileData?.mainPhoto) {
      return clinicProfileData?.mainPhoto.thumbnailUrl;
    }
  }, [clinicProfileData?.mainPhoto, profileChanges?.photos]);

  const linkToPatientAppProfile = useMemo(() => {
    const language = getLanguageFromLocalStorage();

    return `${DocBayPatientLandingLink}${language}/clinic/${clinicProfileData?.id}`;
  }, [clinicProfileData?.id, i18n.language]);

  const deleteButtonIsShown = useMemo(() => {
    return (
      !isPrivateDoctor &&
      !isCreatePage &&
      permissions?.includes(UserPermissions.clinicDeletion)
    );
  }, [permissions, isCreatePage]);

  return (
    <Header>
      {isLoading && <Loader />}
      <ClinicInfo>
        <ClinicImage>
          {currentPhoto ? (
            <img src={currentPhoto} alt="Main Photo" />
          ) : (
            <HomeIcon />
          )}
        </ClinicImage>
        <TitleSection>
          {clinicProfileData?.name && (
            <ClinicName>{clinicProfileData?.name}</ClinicName>
          )}
          {clinicProfileData?.status !== 'Active' ? (
            <BadgeWithCheck status={''} />
          ) : null}
        </TitleSection>
      </ClinicInfo>
      <ButtonWrapper>
        {deleteButtonIsShown ? (
          <SecondaryButton onClick={handleDeleteProfile}>
            {t('delete')}
          </SecondaryButton>
        ) : (
          <></>
        )}
        <LinkStyled
          className={clinicProfileData?.status === 'Active' ? '' : 'isDraft'}
          to={linkToPatientAppProfile}
          target="_blank"
        >
          <EyeOpenIcon />
          {t('previewProfile')}
        </LinkStyled>
      </ButtonWrapper>
    </Header>
  );
};

export default EditSettingsHeader;
