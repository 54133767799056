import styled from 'styled-components';

interface Props {
  isActive: boolean;
}

export const Wrapper = styled.main`
  display: grid;
  grid-template-rows: repeat(3, max-content);
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 32px;
  background: ${({ theme }) => theme.color.blue50};
  overflow-y: auto;

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 28px;
    font-weight: 700;
    line-height: 39.2px;
    letter-spacing: 0.28px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 16px;
`;

export const Tab = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 8px 4px;
  margin-right: 20px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.blue700 : theme.color.grey600};
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 140%;
  cursor: pointer;
  border-bottom: ${({ theme, isActive }) =>
    isActive ? `2px solid ${theme.color.blue700}` : 'none'};

  > svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${({ theme, isActive }) =>
        isActive ? theme.color.blue700 : theme.color.grey600};
    }
  }
`;
