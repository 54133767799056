import React from 'react';
import { Navigate } from 'react-router-dom';
import { authService } from 'common';
import { PathNames } from './constants';
import { getToken } from 'applicaiton/sessionStorage/auth';

export const ProtectedRoute = ({ children }: any) => {
  const isAuth = authService.checkAuth();
  const token = getToken();

  if (!isAuth && !token) {
    return <Navigate to={PathNames.login} />;
  }
  return children;
};
