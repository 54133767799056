import styled from 'styled-components';

export const InfoWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  > p {
    color: ${({ theme }) => theme.color.grey800};
    text-align: center;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 25.2px */
    margin: 32px 0;
    width: 420px;
  }

  @media screen and (max-width: 768px) {
    height: calc(100vh - 130px);
  }
  @media screen and (max-width: 425px) {
    padding: 0 16px;

    > img {
      height: 30%;
      width: auto;
    }

    > p {
      width: 100%;
    }
  }
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: ${({ theme }) => theme.color.menu};
  padding: 0 32px;
  font-family: 'Mulish', sans-serif;
`;

export const FooterWrapper = styled('div')`
  background-color: ${({ theme }) => theme.color.menu};
  padding: 6px 0;
  width: 100%;

  > p {
    text-align: center;
    color: ${({ theme }) => theme.color.white};
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;

export const Apps = styled('div')`
  display: flex;
  align-items: center;

  a {
    width: 180px;

    > img {
      width: 100%;
    }

    &:last-child {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 425px) {
    a {
      width: 165px;
    }
  }
`;
