import React, { FC } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';

import { PrimaryButton, SecondaryButton } from 'common/components';
// import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import { DeleteModalProps } from './modules';
import { StyledModal } from './styles';

const DeleteModal: FC<DeleteModalProps> = ({
  endDate,
  startDate,
  onClose,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language);

  // const { tz } = useUserTimeZone();

  const formattedStartDate = dayjs(startDate).tz().format('MMMM D [at] HH:mm');

  const formattedEndDate = dayjs(endDate).tz().format('MMMM D [at] HH:mm');

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <StyledModal>
      <CloseIcon onClick={onClose} />
      <h1>{t('delete_absence.title')}</h1>
      <h1>
        {t('from')}
        <span> {formattedStartDate}</span>
      </h1>
      <h1>
        {t('to')}
        <span> {formattedEndDate}</span>
      </h1>
      <section>
        <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
        <PrimaryButton onClick={handleSubmit} styleType={'error'}>
          {t('appointment_types.delete_type.confirm')}
        </PrimaryButton>
      </section>
    </StyledModal>
  );
};

export default DeleteModal;
