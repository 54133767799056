import React, { FC } from 'react';
import { DataSection, Header, EmptyState, TableStyled } from './styles';
import { SecondaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { useAppSelector } from 'common/hooks/redux';
import Loader from 'common/components/Loader';
import SurgeryItem from '../SurgeryItem';
import AddSurgeryModal from '../AddSurgeryModal';

interface Props {
  updateMedicalBackground: () => void;
}

const AddSurgeries: FC<Props> = ({ updateMedicalBackground }) => {
  const { t } = useTranslation();
  const [surgeriesModalOpen, setSurgeriesModalOpen] = React.useState(false);

  const { patientMedicalBackground, isLoading } = useAppSelector(
    (state) => state.consultationData,
  );

  const handleSavedFamilyHistoricModal = () => {
    updateMedicalBackground();
    setSurgeriesModalOpen(false);
  };

  return (
    <DataSection>
      {isLoading && <Loader />}
      <Header>
        <h6>{t('consultation_data.surgeries_header')}</h6>
        <SecondaryButton
          onClick={() => setSurgeriesModalOpen(true)}
          styleType={'color'}
        >
          <AddIcon />
          {t('consultation_data.add_surgeries_header')}
        </SecondaryButton>
      </Header>
      <>
        {patientMedicalBackground?.surgeries?.length ? (
          <TableStyled>
            <thead>
              <tr>
                <th>
                  <span>{t('consultation_data.surgeries_table.name')}</span>
                </th>
                <th>
                  <span>{t('consultation_data.surgeries_table.date')}</span>
                </th>
                <th>
                  <span>{t('consultation_data.surgeries_table.comments')}</span>
                </th>
                <th>
                  <span>{t('consultation_data.surgeries_table.status')}</span>
                </th>
                <th>
                  <span>{t('consultation_data.surgeries_table.actions')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {patientMedicalBackground?.surgeries?.map((item) => (
                <SurgeryItem
                  key={item.id}
                  item={item}
                  updateMedicalBackground={updateMedicalBackground}
                />
              ))}
            </tbody>
          </TableStyled>
        ) : (
          <EmptyState>
            <PickIcon />
            <div>
              <p>{t('consultation_data.add_surgeries_empty_1')}</p>
              <p>
                {t('consultation_data.add_surgeries_empty_2')}
                <span onClick={() => setSurgeriesModalOpen(true)}>
                  {t('consultation_data.add_surgeries_empty_3')}
                </span>
              </p>
            </div>
          </EmptyState>
        )}
      </>
      {surgeriesModalOpen && (
        <AddSurgeryModal
          onClose={() => setSurgeriesModalOpen(false)}
          onSave={handleSavedFamilyHistoricModal}
        />
      )}
    </DataSection>
  );
};

export default AddSurgeries;
