import styled from 'styled-components';

interface Color {
  color?: string;
}

interface Props {
  isBookOnline?: boolean;
}

export const StyledTr = styled.tr<Props>`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    &:first-child {
      width: 82px;
    }

    &:nth-child(2) {
      color: ${({ theme }) => theme.color.grey900};
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      div:first-child {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        svg {
          margin-left: 12px;
        }
      }

      div:last-child {
        color: ${({ theme }) => theme.color.grey700};
        font-weight: 500;
      }
    }

    &:nth-child(4) {
      text-align: center;
      width: 148px;

      > span {
        padding: 6px 12px;
        border-radius: 100px;
        background: ${({ theme, isBookOnline }) =>
          isBookOnline ? theme.color.positive : theme.color.errorBackground};
        color: ${({ theme, isBookOnline }) =>
          isBookOnline ? theme.color.success1 : theme.color.error2};
      }
    }
    &:nth-child(3) {
      text-align: center;
      width: 126px;
    }
    &:last-child {
      width: 150px;
    }
  }
`;

export const ColorItem = styled.div<Color>`
  width: 20px;
  height: 20px;
  margin: 0 auto;
  background: ${({ color }) => color};
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > a {
    display: flex;
    padding: 10px;
    align-items: center;
    > svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }

  > button {
    display: flex;
    padding: 0;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    color: ${({ theme }) => theme.color.blue800};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;

    &:first-child {
      > svg {
        path {
          fill: ${({ theme }) => theme.color.blue800};
        }
      }
    }
    &:last-child {
      padding: 10px;
      > svg {
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }
  }
`;
