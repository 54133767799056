import React, { FC } from 'react';
import { Wrapper } from './styles';
import { SideMenu, AdminEdit } from 'features';

const AdminEditPage: FC = () => {
  return (
    <Wrapper>
      <SideMenu />

      <AdminEdit />
    </Wrapper>
  );
};

export default AdminEditPage;
