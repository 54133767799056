import React, { FC } from 'react';
import { SwitchProps } from './modules';
import { SwitchStyled } from './styles';

const Switch: FC<SwitchProps> = ({ checked, onChange, disabled }) => {
  return (
    <SwitchStyled
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
      disabled={!!disabled}
    />
  );
};

export default Switch;
