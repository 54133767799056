import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FieldType } from '../components/types';

export type Metric = {
  type: FieldType;
  metricName: string;
  metricId: string;
  value: string;
  unitName: string;
};

export interface ConsultationData {
  reason?: string;
  symptoms?: string[];
  metric: Metric[];
}

export const useConsultationDataForm = () => {
  const UserSchema = yup.object({
    reason: yup.string().nullable(),
    symptoms: yup.array().of(yup.string()).nullable(),
    metric: yup.array().of(
      yup.object().shape({
        value: yup.string(),
      }),
    ),
  });

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    setError,
    control,
    reset,
    formState: { errors },
  } = useForm<ConsultationData>({
    resolver: yupResolver(UserSchema),
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    setError,
    control,
    reset,
  };
};
