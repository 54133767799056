import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'common/hooks/redux';
import { PathNames } from 'applicaiton/routes';
import {
  setProfilesInSessionStorage,
  setUsersAuthSessionStorage,
} from 'applicaiton/sessionStorage/auth';
import { setIsAuth } from 'applicaiton/store/reducers/Users/UsersSlice';

const LoginAsPage: FC = () => {
  const { email, token, refreshToken, expiresAt, data } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const urlData = window.atob(data!);
    const userData = JSON.parse(decodeURI(urlData)!);

    setUsersAuthSessionStorage({
      token: token!,
      refreshToken: refreshToken!,
      expiresAt: expiresAt!,
      user: userData,
    });
    setProfilesInSessionStorage([
      {
        id: userData?.id!,
        email: email!,
        firstName: userData?.firstName!,
        lastName: userData?.lastName!,
        photo: userData?.photo!,
        token: token!,
        refreshToken: refreshToken!,
      },
    ]);

    dispatch(setIsAuth());
    navigate(PathNames.schedule);
  }, [email, token, refreshToken, expiresAt, data, dispatch, navigate]);

  return <></>;
};

export default LoginAsPage;
