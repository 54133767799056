import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppointmentTypesListStyled } from './styles';
import { PrimaryButton, Header } from 'common/components';
import { AppointmentTypesTable } from './components';
import { ReactComponent as AddIcon } from 'applicaiton/assets/add.svg';
import { PathNames } from 'applicaiton/routes';

const AppointmentTypesList: FC = () => {
  const { t } = useTranslation();
  return (
    <AppointmentTypesListStyled>
      <Header />
      <div>
        <h1>{t('appointment_types.appointment_types')}</h1>
        <Link to={PathNames.addAppointmentType}>
          <PrimaryButton>
            <AddIcon />
            {t('appointment_types.add_new_type')}
          </PrimaryButton>
        </Link>
      </div>
      <AppointmentTypesTable />
    </AppointmentTypesListStyled>
  );
};

export default AppointmentTypesList;
