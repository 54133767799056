import { UploadChatTeleconsultationDocumentParams } from 'common/types/chat';
import axiosInstance from './axiosInstance';
import axiosBlobInstance from './axiosBlobInstance';

export const ChatTeleconsultationAPI = {
  uploadChatTeleconsultationDocument: ({
    conversationSid,
    file,
  }: UploadChatTeleconsultationDocumentParams) => {
    return axiosInstance.post(
      `/pro-side/chat-teleconsultation/documents/${conversationSid}/upload`,
      file,
    );
  },
  downloadChatTeleconsultationDocument: (messageSid: string) => {
    return axiosBlobInstance.get(
      `/pro-side/chat-teleconsultation/documents/${messageSid}/download`,
    );
  },
  deleteChatDocumentMessage: (messageSid: string) => {
    return axiosInstance.delete(
      `/pro-side/chat-teleconsultation/documents/${messageSid}`,
    );
  },
  getChatAccessToken: (roomId: string) => {
    return axiosInstance.post<string>(
      `/pro-side/chat-teleconsultation/${roomId}/generate-access-token`,
      { roomId },
    );
  },
};
