import { Box, styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(2)};
  box-shadow: inset 0 -1px 0 ${commonColors.grey200};
`;

export const TabItemStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(1, 0.5)};
  cursor: pointer;
  span {
    color: ${commonColors.grey600};
    font-size: 12px;
    font-weight: 500;
    line-height: 140%;
  }
  &.active {
    border-bottom: 2px solid ${commonColors.blue700};
    span {
      color: ${commonColors.blue700};
    }
    svg {
      path {
        fill: ${commonColors.blue700};
      }
    }
    .color-stroke {
      path {
        stroke: ${commonColors.blue700};
        fill: none;
      }
    }
  }
`;
