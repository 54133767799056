import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'common/components';
import { ChangePasswordProps } from './modules';
import { Wrapper } from './styles';
import CreatePassword from '../CreatePassword';

const ChangePassword: FC<ChangePasswordProps> = ({
  watch,
  getValues,
  register,
  oldPasswordErrorMessage,
  passwordErrorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Input
        id="oldPassword"
        type={'password'}
        label={t('old_password') || ''}
        placeholder={t('old_password') || ''}
        register={register}
        errorMessage={oldPasswordErrorMessage}
      />
      <CreatePassword
        label={t('new_password') || ''}
        placeholder={t('new_password') || ''}
        watch={watch}
        getValues={getValues}
        register={register}
        errors={passwordErrorMessage}
      />
    </Wrapper>
  );
};

export default ChangePassword;
