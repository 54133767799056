import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CalendarIcon } from 'applicaiton/assets/calendar-lines-pen.svg';
import { Wrapper } from './styles';

const NoDataElement: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <CalendarIcon />
      <p>{t('scheduler.no_data_element')}</p>
    </Wrapper>
  );
};
export default NoDataElement;
