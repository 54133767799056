import { Format } from 'applicaiton/constants/scheduler';
import dayjs from 'dayjs';

export const getSelectedDays = (
  isWeeklyView: boolean,
  selectedDate: string,
) => {
  if (isWeeklyView) {
    const currDate = dayjs(selectedDate); // get current date
    const dayOfWeek = currDate.day() === 0 ? 6 : currDate.day() - 1;
    const first = currDate.date() - dayOfWeek;
    const last = first + 6; // last day is the first day + 6
    const currentMoth = dayjs(selectedDate).get('month');
    const lastDatePrevMonth = dayjs(
      new Date(2008, currentMoth, 0).toUTCString(),
    ).get('date');

    const startDate = dayjs(
      new Date(
        currDate
          .month(first < 0 ? currentMoth - 1 : currentMoth)
          .set('date', first < 0 ? lastDatePrevMonth + first : first)
          .toDate(),
      ).toUTCString(),
    )
      .set('hours', 0)
      .set('minute', 0)
      .set('millisecond', 0)
      .format(Format.dateFormat);
    const endDate = dayjs(
      new Date(new Date(selectedDate).setDate(last)).toUTCString(),
    )
      .set('hours', 23)
      .set('minute', 59)
      .set('millisecond', 0)
      .format(Format.dateFormat);

    return { startDate, endDate };
  }

  return {
    startDate: dayjs(selectedDate)
      .set('hours', 0)
      .set('minute', 0)
      .set('millisecond', 0)
      .format(Format.dateFormat),
    endDate: dayjs(selectedDate)
      .set('hours', 23)
      .set('minute', 59)
      .set('millisecond', 0)
      .format(Format.dateFormat),
  };
};
