import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as CancelIcon } from 'applicaiton/assets/cancel.svg';
import { StyledModal } from './styles';

interface DeleteModalProps {
  profileName: string;
  onClose: () => void;
  onSubmit: () => void;
}

const DeleteModal: FC<DeleteModalProps> = ({
  profileName,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <StyledModal>
      <CloseIcon onClick={onClose} />
      <h1>
        {t('clinicsConfiguration.delete_profile.titlePart1')}{' '}
        <span>{profileName}</span>{' '}
        {t('clinicsConfiguration.delete_profile.titlePart2')}
      </h1>
      <div>
        <p> {t('clinicsConfiguration.delete_profile.description')}</p>
        <div>
          <CancelIcon />
          {t('clinicsConfiguration.delete_profile.descriptionText1')}
        </div>
        <div>
          <CancelIcon />
          {t('clinicsConfiguration.delete_profile.descriptionText2')}
        </div>
      </div>
      <section>
        <SecondaryButton onClick={onClose}>
          {t('clinicsConfiguration.delete_profile.cancel')}
        </SecondaryButton>
        <PrimaryButton onClick={onSubmit} styleType={'error'}>
          {t('clinicsConfiguration.delete_profile.confirm')}
        </PrimaryButton>
      </section>
    </StyledModal>
  );
};

export default DeleteModal;
