import {
  ProfessionalNotificationUpdateDto,
  ProfessionalNotificationsListResponse,
} from '@docbay/schemas';
import {
  UpdateNotificationParamsDto,
  GetNotificationsParamsDto,
} from 'common/types/notifications';
import axiosInstance from 'integration/api/axiosInstance';

export const NotificationsAPI = {
  getNotifications: ({ page, limit, search }: GetNotificationsParamsDto) => {
    const params = {
      includeDeleted: 'false',
      ...(page ? { page } : {}),
      ...(limit ? { limit } : {}),
      ...(search ? { search } : {}),
    };
    return axiosInstance.get<ProfessionalNotificationsListResponse>(
      '/professional-notifications',
      { params },
    );
  },
  updateNotification: ({ id, isRead }: UpdateNotificationParamsDto) =>
    axiosInstance.patch<ProfessionalNotificationUpdateDto>(
      `/professional-notifications/${id}`,
      { isRead },
    ),
  readAllNotification: () =>
    axiosInstance.patch('/professional-notifications/mark-all-as-read'),
};
