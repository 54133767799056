import { getToken } from 'applicaiton/sessionStorage/auth';
import axios from 'axios';
import { ApplicationType, x_app_type } from '../../applicaiton';

const axiosTokenInstance = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  axiosInstance.interceptors.request.use(
    async function (config) {
      return {
        ...config,
        ...(getToken()
          ? {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-type': 'Application/json',
                Authorization: `Bearer ${getToken()}`,
                [x_app_type]: ApplicationType.ProfessionalWeb,
              },
            }
          : {}),
      };
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

export default axiosTokenInstance;
