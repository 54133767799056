import styled from 'styled-components';

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    width: 220px;

    &:first-child {
      width: inherit;
    }

    &.noWrap {
      white-space: nowrap;
    }
  }
`;

export const ProfessionalNameStyled = styled.div`
  display: flex;
  align-items: center;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  > div:last-child {
    display: grid;
    gap: 2px;
    margin: 0 12px;

    > p {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      white-space: nowrap;
    }
    > span {
      color: ${({ theme }) => theme.color.grey700};
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
    }
  }
`;

export const AvatarStyled = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.blueDark100};
  border-radius: 50%;
  > svg {
    path {
      fill: ${({ theme }) => theme.color.grey900};
    }
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 245px;

  > a {
    display: flex;
    padding: 10px;
    align-items: center;
    > svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }

  > button {
    display: flex;
    padding: 0;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    color: ${({ theme }) => theme.color.blue800};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;

    &:first-child {
      > svg {
        path {
          fill: ${({ theme }) => theme.color.blue800};
        }
      }
    }
    &:last-child {
      padding: 10px;
      > svg {
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }
  }
`;

export const ResendButton = styled.button`
  width: 100%;
  border: none;
  background: none;
  padding: 0;
  color: ${({ theme }) => theme.color.blue800};
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  cursor: pointer;
`;
