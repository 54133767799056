import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PermissionsTable } from './components';
import { Wrapper } from './styles';
import { Header } from 'common/components';

const UserPermissions: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header />
      <h1>{t('user_permissions')}</h1>
      <PermissionsTable />
    </Wrapper>
  );
};

export default UserPermissions;
