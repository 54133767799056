import React, { FC } from 'react';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { BadgeWithCheckProps } from './modules';
import { BadgeWrapper } from './style';

const BadgeWithCheck: FC<BadgeWithCheckProps> = ({ status }) => {
  const { t } = useTranslation();
  return (
    <BadgeWrapper status={status || ''}>
      <CloseIcon sx={{ width: '16px', height: '16px', color: '' }} />
      <Typography variant="caption" ml={0.5} fontSize="14px" fontWeight="700">
        {t('not_active')}
      </Typography>
    </BadgeWrapper>
  );
};

export default BadgeWithCheck;
