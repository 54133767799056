import styled from 'styled-components';

interface Props {
  isChecked: boolean;
}
export const CheckboxGroup = styled.div`
  max-width: 464px;
`;

export const ImageWrapper = styled.div<Props>`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: ${({ theme, isChecked }) =>
    isChecked ? theme.color.blue700 : theme.color.blue100};
  position: relative;
  margin-right: 12px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ theme, isChecked }) =>
      isChecked ? theme.color.white : theme.color.blue700};
  }
`;

export const PaymentItem = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid
    ${({ theme, isChecked }) =>
      isChecked ? theme.color.blue300 : theme.color.grey300};
  background: ${({ theme, isChecked }) =>
    isChecked ? theme.color.background : theme.color.white};
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;
