import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  overflow: auto;

  > div {
    height: fit-content;
    min-width: calc(100% - 200px);
    display: grid;
    grid-template-rows: max-content;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > p {
    display: flex;
    align-items: center;
    height: 86px;
    padding: 0px 32px;
    border-right: 1px solid ${({ theme }) => theme.color.grey300};
    border-bottom: 1px solid ${({ theme }) => theme.color.grey300};

    color: ${({ theme }) => theme.color.grey800};
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
  height: fit-content;
  border-right: 1px solid ${({ theme }) => theme.color.grey200};

  > thead {
    tr {
      th {
        vertical-align: middle;
        text-align: start;
        padding: 12px 24px;
        background: ${({ theme }) => theme.color.grey50};
        width: calc(100% - 200px / 4);

        color: ${({ theme }) => theme.color.grey600};
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 19.6px;
        letter-spacing: 0.07px;

        &:nth-child(2) {
          width: 200px;
        }
        &:last-of-type {
          width: 100px;
        }
      }
    }
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.grey200};
  margin: 32px 0;
`;

export const ProfessionalItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  height: 86px;

  border-right: 1px solid ${({ theme }) => theme.color.grey300};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey300};

  > div {
    display: flex;
    align-items: center;
    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.blueDark100};
      margin-right: 8px;
      color: ${({ theme }) => theme.color.white};
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }

    p {
      color: ${({ theme }) => theme.color.grey900};
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 25.2px;
      text-transform: uppercase;
    }
  }
`;

export const ProfessionalInfo = styled.div`
  > h2 {
    color: ${({ theme }) => theme.color.grey900};
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
  }
  > div {
    display: flex;
    align-items: center;

    > p {
      color: ${({ theme }) => theme.color.grey600};
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      margin-left: 4px;
      text-transform: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }
`;
