import styled from 'styled-components';

interface ItemStyledProps {
  isStrokeSvg?: boolean;
}

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  gap: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};

  > button {
    height: 36px;
    width: fit-content;
    svg {
      path {
        fill: ${({ theme }) => theme.color.blue800};
      }
    }
  }
`;

export const ItemsList = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ItemStyled = styled.div<ItemStyledProps>`
  display: flex;
  align-items: center;
  min-width: 400px;

  span {
    color: ${({ theme }) => theme.color.grey500};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin: 0 6px 0 8px;
  }

  p {
    color: ${({ theme }) => theme.color.grey700};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }

  svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme, isStrokeSvg }) =>
        !isStrokeSvg ? theme.color.grey600 : ''};
      stroke: ${({ theme, isStrokeSvg }) =>
        isStrokeSvg ? theme.color.grey600 : ''};
    }
  }
`;
