import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ProfileInfo } from './components';
import { Wrapper, Tabs, Tab } from './styles';
import ConsultationData from './components/ConsultationData';
import ConsultationHistory from './components/ConsultationHistory';
import { useAppDispatch } from '../../common/hooks/redux';
import { fetchAppointmentById } from '../../applicaiton/store/reducers/Appointments/ActionCreators';
import MedicalBackground from './components/MedicalBackground';

const PatientDataProfile: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { appointmentId, tab = 'data' } = useParams();
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    appointmentId && dispatch(fetchAppointmentById(String(appointmentId)));
  }, [appointmentId]);

  return (
    <Wrapper>
      <ProfileInfo />
      <Tabs>
        <Tab
          isActive={activeTab === 'data'}
          onClick={() => setActiveTab('data')}
        >
          {t('consultation_data.tabs.consultation_data')}
        </Tab>
        <Tab
          isActive={activeTab === 'history'}
          onClick={() => setActiveTab('history')}
        >
          {t('consultation_data.tabs.consultation_history')}
        </Tab>
        <Tab
          isActive={activeTab === 'medicalBackground'}
          onClick={() => setActiveTab('medicalBackground')}
        >
          {t('consultation_data.tabs.medicalBackground')}
        </Tab>
      </Tabs>
      {activeTab === 'data' && <ConsultationData />}
      {activeTab === 'history' && <ConsultationHistory />}
      {activeTab === 'medicalBackground' && <MedicalBackground />}
    </Wrapper>
  );
};

export default PatientDataProfile;
