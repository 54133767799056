import styled from 'styled-components';
import { StyledTrProps } from './modules';

export const StyledTr = styled.tr<StyledTrProps>`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

    &.actions {
      width: ${({ isViewByFuture }) => (isViewByFuture ? 'unset' : '150px')};
    }

    p {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 19.6px;
    }

    div {
      p {
        white-space: nowrap;
      }
    }
  }

  &:last-child {
    td {
      border-bottom: 0;
    }
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > button {
    display: flex;
    padding: 0;
    align-items: center;
    cursor: pointer;
    border: none;
    background: none;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }
  }
`;

export const ViewButton = styled.button`
  width: fit-content;
  border: none;
  background: none;
  padding: 0;
  color: ${({ theme }) => theme.color.blue800};
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  cursor: pointer;
`;
