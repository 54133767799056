import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes/constants';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import {
  ProfessionalNameStyled,
  StyledTr,
  ActionsStyled,
  AvatarStyled,
  ResendButton,
} from './styles';
import BadgeWithCheck from 'common/components/BadgeWithCheck';
import { Box } from '@mui/system';
import { UserItemProps } from './modules';
import DeleteModal from '../DeleteModal';
import { useAppDispatch } from 'common/hooks/redux';
import { deleteAdmin } from 'applicaiton/store/reducers/Admins/ActionCreators';
import { resendAdminAuthorizationData } from 'applicaiton/store/reducers/Admins/ActionCreators';
import { fetchClinicOwnerStaff } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { UserType } from 'features/feature-users-list/hooks/useUserTypeOptions';
import {
  deleteSecretary,
  resendSecretariesAuthorizationData,
} from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import SuccessResendLink from 'common/components/SuccessResendLink';

const UserItem: FC<UserItemProps> = ({
  user,
  allUsersIsActive,
  currentPage,
  userType,
  currentUserRole,
}) => {
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isResentLink, setIsResentLink] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAdmin = user.userType === UserType.Admin;
  const isSecretary = user.userType === UserType.Secretary;

  const editLink = isAdmin ? PathNames.editAdmin : PathNames.editSecretary;
  const userTypesList = userType
    ? [userType]
    : [UserType.Admin, UserType.Secretary];

  const handleGetUsersList = () => {
    setShowDeleteModal(false);
    dispatch(
      fetchClinicOwnerStaff({
        page: currentPage,
        clinicIds: [],
        userTypes: userTypesList,
      }),
    );
    navigate(PathNames.myUsers);
  };

  const deleteAdminProfile = (id: string) => {
    dispatch(deleteAdmin(id!)).then(() => {
      handleGetUsersList();
    });
  };

  const deleteSecretaryProfile = (id: string) => {
    dispatch(deleteSecretary(id!)).then(() => {
      handleGetUsersList();
    });
  };

  const deleteProfile = (id: string) => {
    isAdmin ? deleteAdminProfile(id) : deleteSecretaryProfile(id);
  };

  const handleResendAuthAdmin = () => {
    if (isAdmin) {
      dispatch(resendAdminAuthorizationData(user.id)).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          setIsResentLink(true);
        }
      });
    }
    if (isSecretary) {
      dispatch(resendSecretariesAuthorizationData(user.id)).then((data) => {
        if (data.meta.requestStatus === 'fulfilled') {
          setIsResentLink(true);
        }
      });
    }
  };

  const closeResendModal = () => {
    setIsResentLink(false);
  };

  return (
    <StyledTr>
      <td>
        <Box display="flex" gap={1} alignItems="center" flexWrap="wrap">
          <ProfessionalNameStyled>
            {user.photo ? (
              <img src={user.photo.thumbnailUrl} alt={user.photo.id} />
            ) : (
              <AvatarStyled>
                <PersonIcon />
              </AvatarStyled>
            )}
            <div>
              <p>{user.firstName + ' ' + user.lastName}</p>
              <span>{user.email}</span>
            </div>
          </ProfessionalNameStyled>
          {!user.active ? <BadgeWithCheck status={''} /> : null}
        </Box>
      </td>
      <td>{user.userType === 'Admin' ? t('admin') : t('secretary')}</td>
      <td>
        <ActionsStyled allUsersIsActive={allUsersIsActive}>
          {!user.active && (
            <>
              <ResendButton onClick={handleResendAuthAdmin}>
                {t('adminsListConfiguration.resend_access_link')}
              </ResendButton>
              {isResentLink && (
                <SuccessResendLink
                  onClose={closeResendModal}
                  email={user.email!}
                />
              )}
            </>
          )}
          <Link to={`${editLink}/${user.id}`}>
            <EditIcon />
          </Link>
          <button onClick={() => setShowDeleteModal(true)}>
            <DeleteIcon />
          </button>
        </ActionsStyled>
      </td>
      <DeleteModal
        isOpen={showDeleteModal}
        typeName={`${user?.firstName} ${user?.lastName}`}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => deleteProfile(user.id)}
        userType={currentUserRole}
      />
    </StyledTr>
  );
};
export default UserItem;
