import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';

import { ContactsType } from 'common/types/clinics';
import { ContactsFormValues, ContactsChildProps } from '../models';

import AddAnother from '../AddAnother';
import SocialInput from 'common/components/SocialInput';

import { Close, InputWrapper, InputContainer, WrapperContact } from '../styles';

const Social = ({
  errors,
  control,
  register,
  getValues,
  handleRemoveContact,
}: ContactsChildProps) => {
  const { t } = useTranslation();

  const prependKey = 'social';
  const { fields, append, remove, update } = useFieldArray<ContactsFormValues>({
    control,
    name: prependKey,
  });

  const handleAddAnother = () => {
    append({
      type: 'Facebook' as ContactsType,
      contactDetail: '',
    });
  };

  const removeContact = (index: number) => {
    const contacts = getValues(prependKey);
    if (contacts.length) {
      const contactWithId = contacts.find(
        (item, idx) => !!item.id && idx === index,
      );
      if (contactWithId) {
        handleRemoveContact(contactWithId.id);
      }
    }
    remove(index);
  };

  return (
    <WrapperContact>
      <label>{t('edit_contacts.social_media')}</label>
      {fields.map((field, index) => {
        const id = `${prependKey}.${index}.contactDetail`;
        const values = getValues(`${prependKey}.${index}`);
        const error = errors?.[prependKey]?.[index]?.contactDetail?.message;

        return (
          <InputWrapper key={field.id} last={index === fields.length - 1}>
            <InputContainer>
              <SocialInput
                id={id}
                type="text"
                register={register}
                errorMessage={error}
                socialType={values.type}
                placeholder={t('edit_contacts.social_media') as string}
                onTypeSelect={(type) => update(index, { ...values, type })}
              />
            </InputContainer>
            {index !== 0 && <Close onClick={() => removeContact(index)} />}
          </InputWrapper>
        );
      })}

      <AddAnother title={t('add_another')} onClick={handleAddAnother} />
    </WrapperContact>
  );
};

export default Social;
