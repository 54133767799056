import { isValidPhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { nifValidate } from 'common/helpers/nifValidate';

export const usePatientEditScheme = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const numberError = t('errors.number_error') || '';
  const lengthFinError = t('errors.lengthFinError') || '';
  const lengthSsnError = t('errors.lengthSsnError') || '';

  return yup.object({
    firstName: yup.string().required(requiredError),
    lastName: yup.string().required(requiredError),
    phone: yup.string().test({
      name: 'is-valid',
      test(value, ctx) {
        const phoneNumber = value?.startsWith('+')
          ? value
          : `+${String(value)}`;
        const isValidNumber = isValidPhoneNumber(phoneNumber);
        if (!isValidNumber)
          return ctx.createError({ message: 'Phone number is not valid' });
        return isValidNumber;
      },
    }),
    email: yup.string(),
    dateOfBirth: yup.date(),
    nif: yup.string().test({
      name: 'nif',
      test(value, ctx) {
        if (!value || value?.length === 0) return true;

        const isNum = nifValidate(value);

        if (value?.length !== 9) {
          return ctx.createError({ message: lengthFinError });
        } else if (!isNum) {
          return ctx.createError({ message: numberError });
        }
        return isNum;
      },
    }),
    socialSecurityNumber: yup.string().test({
      name: 'socialSecurityNumber',
      test(value, ctx) {
        if (!value || value?.length === 0) return true;

        const isNum = /^\d+$/.test(value!);

        if (!isNum) {
          return ctx.createError({ message: numberError });
        } else if (value?.length !== 9) {
          return ctx.createError({ message: lengthSsnError });
        }

        return isNum;
      },
    }),
  });
};
