import styled from 'styled-components';

interface ErrorProps {
  isError?: boolean;
}

export const PhotoUploaderStyled = styled.div`
  display: grid;
  gap: 20px;

  > h2 {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }

  > img {
    height: 150px;
    width: 174px;
    border-radius: 6px;
    border: 2px solid ${({ theme }) => theme.color.blue700};
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  align-items: center;

  > button {
    width: 183px;
    position: relative;
    > label {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 8px;
      cursor: pointer;
    }
    > input {
      display: none;
    }
  }
  > span {
    margin-left: 16px;
    color: ${({ theme }) => theme.color.grey500};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
`;

export const UploadingProgress = styled.div`
  width: calc(50% - 12px);
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
`;

export const UploadingProgressError = styled(UploadingProgress)`
  width: 464px;
`;

export const UploadingText = styled.div<ErrorProps>`
  margin-left: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${({ theme, isError }) =>
    isError ? theme.color.error1 : theme.color.grey900};

  & p:last-child {
    font-weight: 400;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error1 : theme.color.grey700};
  }
`;

export const Loader = styled.div<ErrorProps>`
  display: flex;
  width: 44px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ theme, isError }) =>
    isError ? theme.color.error3 : theme.color.blue100};

  svg {
    animation: ${({ isError }) =>
      isError ? 'none' : 'rotation 3s infinite linear'};
    color: ${({ theme, isError }) =>
      isError ? theme.color.error1 : theme.color.grey700};

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
`;
