import styled from 'styled-components';

export const ProfessionalItem = styled.div`
  display: flex;
  height: 62px;
  align-items: center;
  border-radius: 8px;
  padding: 0 24px;

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${({ theme }) => theme.color.blueDark100};
    margin-right: 8px;
    color: ${({ theme }) => theme.color.white};
  }
  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
  }

  p {
    color: ${({ theme }) => theme.color.grey900};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.2px;
    text-transform: uppercase;
  }
  h2 {
    color: ${({ theme }) => theme.color.grey800};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    > span {
      margin-left: 12px;
      font-weight: 400;
    }
  }
`;
