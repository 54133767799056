import { UseToggleProps } from 'features/feature-teleconsultation/types';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { setIsAudioOn } from 'applicaiton/store/reducers/Teleconsultation/TeleconsultationSlice';

/**
 * Toggle on/off local participant microphone
 *
 * References:
 * https://www.twilio.com/docs/video/javascript-getting-started#mute-your-local-media
 */
const useToggleMicrophone = ({ room, initialState }: UseToggleProps) => {
  const dispatch = useAppDispatch();
  const { isAudioOn } = useAppSelector((state) => state.teleconsultation);

  const toggleMicrophone = useCallback(() => {
    if (room) {
      if (isAudioOn) {
        // turn off
        room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
        });

        dispatch(setIsAudioOn(false));
      } else {
        // turn on
        room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable();
        });

        dispatch(setIsAudioOn(true));
      }
    }
  }, [isAudioOn, room]);

  return { isMicrophoneOn: isAudioOn, toggleMicrophone };
};

export default useToggleMicrophone;
