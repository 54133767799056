import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'common/hooks/redux';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-pencil.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import {
  selectAppointmentToDeleted,
  setSlotsDetails,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { AppointmentItemProps } from './modules';
import { StyledTr, ActionsStyled, ViewButton } from './styles';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { formatDateTimeInDefaultTimezone } from 'common/helpers/dateTimeHelper';

require('dayjs/locale/pt');
require('dayjs/locale/fr');

const AppointmentItem: FC<AppointmentItemProps> = ({
  appointment,
  isViewByFuture,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const isCanReschedule = userHasPermission(
    UserPermissions.rescheduleAppointment,
  );

  const canUserCancel = userHasPermission(UserPermissions.cancelAgenda);

  const handleViewAppointment = () => {
    dispatch(
      setSlotsDetails({
        appointmentId: appointment.id,
        mode: ReserveSlotsMode.PreviewAppointment,
      }),
    );
  };

  const handleEditAppointment = () => {
    dispatch(
      setSlotsDetails({
        appointmentId: appointment.id,
        mode: ReserveSlotsMode.EditAppointment,
      }),
    );
  };

  const handleDeleteAppointment = () => {
    dispatch(selectAppointmentToDeleted(appointment));
  };

  const doctorName = `${appointment.professional?.firstName} ${appointment.professional?.lastName}`;
  const date = formatDateTimeInDefaultTimezone(
    appointment.startDate,
    'DD MMMM, YYYY',
    i18n.language,
  );
  const time = formatDateTimeInDefaultTimezone(appointment.startDate, 'HH:mm');

  return (
    <StyledTr isViewByFuture={isViewByFuture}>
      <td>
        <p>
          {t('dr')} {doctorName}
        </p>
      </td>
      <td>{appointment.appointmentType.title}</td>
      <td>{appointment.appointmentType?.meetingPlace}</td>
      <td>
        <div>
          <p>{date}</p>
          <span>
            {t('at')} {time}
          </span>
        </div>
      </td>
      <td className="actions">
        <ActionsStyled>
          {isViewByFuture && (
            <>
              {isCanReschedule && (
                <button onClick={handleEditAppointment}>
                  <EditIcon />
                </button>
              )}
              {canUserCancel && (
                <button onClick={handleDeleteAppointment}>
                  <DeleteIcon />
                </button>
              )}
            </>
          )}
          <ViewButton onClick={handleViewAppointment}>{t('view')}</ViewButton>
        </ActionsStyled>
      </td>
    </StyledTr>
  );
};
export default AppointmentItem;
