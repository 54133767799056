import styled from 'styled-components';
import { StyledProps } from './modules';

export const StyledTr = styled.tr<StyledProps>`
  height: 44px;
  background: ${({ theme }) => theme.color.white};

  > td {
    vertical-align: middle;
    text-align: start;
    padding-bottom: 12px;
    padding: 16px 24px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
    cursor: pointer;

    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0.07px;

    &:first-of-type {
      border-left: 4px solid ${({ colorCode }) => colorCode};
    }
    &:last-of-type {
      cursor: unset;
    }
  }
`;

export const NameStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    overflow: hidden;
    h2 {
      color: ${({ theme }) => theme.color.grey900};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-right: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    p {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.color.grey800};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-right: 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        margin-right: 4px;
      }
    }
  }

  > svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;

    path {
      fill: ${({ theme }) => theme.color.warning2};
    }
  }
`;

export const TypeStyled = styled.div<StyledProps>`
  display: flex;
  align-items: center;

  > div {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 2px;
    background-color: ${({ colorCode }) => colorCode};
    margin-right: 12px;
  }

  > p {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-left: 12px;

    rect {
      fill: ${({ colorCode }) => colorCode};
    }
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    right: 0;

    > svg {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;

      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }

    &.editButton {
      left: 0;
      > svg {
        path {
          fill: ${({ theme }) => theme.color.blue800};
        }
      }
    }
  }
`;
