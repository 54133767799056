import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { StyledTr } from './styles';

interface Props {
  openAddModal: () => void;
}
const NoDataElement: FC<Props> = ({ openAddModal }) => {
  const { t } = useTranslation();
  return (
    <StyledTr>
      <td colSpan={3}>
        <div>
          <PickIcon />
          <p>
            {t('topics_of_chats.no_topics.title1')}
            <br />
            {t('topics_of_chats.no_topics.title2')}{' '}
            <span onClick={openAddModal}>
              {t('topics_of_chats.no_topics.title3')}
            </span>
          </p>
        </div>
      </td>
    </StyledTr>
  );
};
export default NoDataElement;
