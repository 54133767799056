import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { PackFeatures } from '../constants';

export const useFeatures = (type: PackFeatures) => {
  const { t, i18n } = useTranslation();

  const features = useMemo(() => {
    return {
      privateDoctor: [
        {
          title: t('packs_features.doctor_public_profile'),
          isActive: true,
        },
        {
          title: t('packs_features.teleconsultation_platform'),
          isActive: true,
        },
        {
          title: t('packs_features.patient_to_doctor_subscription'),
          isActive: true,
        },
        {
          title: t('packs_features.digital_waiting_list'),
          isActive: true,
        },
        {
          title: t('packs_features.patient_clinical_file'),
          isActive: true,
        },
        {
          title: t('packs_features.invoicing'),
          isActive: true,
        },
        {
          title: t('packs_features.reminders'),
          isActive: true,
        },
      ],
      basicPlan: [
        {
          title: t('packs_features.doctor_public_profile'),
          isActive: true,
        },
        {
          title: t('packs_features.request_button'),
          isActive: true,
        },
        {
          title: t('packs_features.digital_waiting_list'),
          isActive: true,
        },
        {
          title: t('packs_features.online_scheduling'),
          isActive: false,
        },
        {
          title: t('packs_features.reminders'),
          isActive: false,
        },
        {
          title: t('packs_features.patient_clinical_file'),
          isActive: false,
        },
        {
          title: t('packs_features.invoicing'),
          isActive: false,
        },
      ],
      fullPlan: [
        {
          title: t('packs_features.doctor_public_profile'),
          isActive: true,
        },
        {
          title: t('packs_features.teleconsultation_platform'),
          isActive: true,
        },
        {
          title: t('packs_features.online_scheduling'),
          isActive: true,
        },
        {
          title: t('packs_features.digital_waiting_list'),
          isActive: true,
        },
        {
          title: t('packs_features.reminders_full'),
          isActive: true,
        },
        {
          title: t('packs_features.patient_clinical_file'),
          isActive: true,
        },
        {
          title: t('packs_features.invoicing'),
          isActive: true,
        },
      ],
    };
  }, [i18n.language]);

  switch (type) {
    case PackFeatures.basicPack: {
      return features.basicPlan;
    }
    case PackFeatures.clinicFullPack: {
      return features.fullPlan;
    }
    case PackFeatures.privateDoctorPack: {
      return features.privateDoctor;
    }
  }
};
