import React, { FC, memo } from 'react';
import { GroupingPanel } from '@devexpress/dx-react-scheduler-material-ui';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { ProfessionalInfo, ProfessionalItem } from './styles';
import { Checkbox } from 'common/components';
import {
  setAgendaProfessionals,
  setSelectedProfessionals,
} from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';
import { ViewBy } from 'applicaiton/constants/scheduler';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';

const GroupingPanelCell: FC = ({ group, ...restProps }: any) => {
  const dispatch = useAppDispatch();
  const { localizeNameKey } = useLocalizeKey();
  const { viewBy, byAvailability, byProfessionals } = useAppSelector(
    (state) => state.scheduler,
  );
  const isViewByProfessionals = viewBy === ViewBy.byProfessionals;
  const agendaProfessionals = isViewByProfessionals
    ? byProfessionals.agendaProfessionals
    : byAvailability.agendaProfessionals;
  const professional = agendaProfessionals.find(
    (item) => item.id === group.id,
  )!;

  const checkProfessionalSelected = (value: string) => {
    return agendaProfessionals.some((item) => item.id === value);
  };

  const handleUncheckProfessional = () => {
    const filteredAgendaProfessionals = agendaProfessionals.filter(
      (item) => item.id !== group.id,
    );

    dispatch(
      setAgendaProfessionals({
        professionals: filteredAgendaProfessionals,
        key: viewBy,
      }),
    );
    dispatch(
      setSelectedProfessionals({
        professionals: filteredAgendaProfessionals,
        key: viewBy,
      }),
    );
  };

  return (
    <GroupingPanel.Cell group={group} {...restProps}>
      {agendaProfessionals.length && (
        <ProfessionalItem>
          <div>
            {professional?.photo ? (
              <img
                src={professional.photo.thumbnailUrl}
                alt={professional.id}
              />
            ) : (
              <div>
                <p>
                  {professional?.firstName ? professional.firstName[0] : ''}
                </p>
                <p>{professional?.lastName ? professional.lastName[0] : ''}</p>
              </div>
            )}
            <ProfessionalInfo>
              <h2>
                {professional?.firstName} {professional?.lastName}
              </h2>
              <div>
                {professional?.specializations?.map((item, index) => {
                  return (
                    <p key={item.id}>
                      {item[localizeNameKey]}
                      {index + 1 !== professional?.specializations.length &&
                        ','}
                    </p>
                  );
                })}
              </div>
            </ProfessionalInfo>
          </div>
          <Checkbox
            id={`agenda-professional-${String(professional?.id)}`}
            checked={checkProfessionalSelected(String(professional?.id))}
            onChange={handleUncheckProfessional}
          />
        </ProfessionalItem>
      )}
    </GroupingPanel.Cell>
  );
};

export default memo(GroupingPanelCell);
