import { styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled('div')`
  padding: 20px 20px 0 20px;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
`;

export const PatientItem = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 12px;
  border-bottom: 1px solid ${commonColors.grey200};

  p {
    color: ${commonColors.grey800};
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }

  span {
    color: ${commonColors.grey700};
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: 0.08px;
  }
`;
