import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const InputWrapper = styled.div`
  margin-top: 18px;
`;

export const DividerStyled = styled.div`
  margin-top: 32px;
  height: 1px;
  background: ${({ theme }) => theme.color.grey200};
`;

export const CheckboxWrapper = styled.div`
  display: block !important;
  margin-top: 18px;

  > label {
    display: block;
    color: ${({ theme }) => theme.color.grey600};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 8px;
  }
`;

export const SwitchStyled = styled.div`
  display: flex;

  > p {
    color: ${({ theme }) => theme.color.grey500};
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin-left: 12px;

    > span {
      margin-left: 8px;
      font-weight: 700;
      color: ${({ theme }) => theme.color.blue800};
      cursor: pointer;
    }
  }
`;
