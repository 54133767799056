import React, { useEffect } from 'react';
import { InputPhone, PrimaryButton, SecondaryButton } from 'common/components';
import { useTranslation } from 'react-i18next';
import { Wrapper, InputWrapper, ButtonWrapper, StyledDivider } from './styles';
import { useAppDispatch } from 'common/hooks/redux';
import { secretaryVerifyPhone } from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import { NewEmailProps } from './modules';
import { UserType } from '@docbay/schemas/dist/user/user.types';
import { usePhoneForm } from 'features/feature-secretary-profile/hooks/usePhoneForm';
import { resetVerifiedOldPhoneCode } from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';

const NewPhone = ({ onNext, onCancel }: NewEmailProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const newPhone = sessionStorage.getItem('newPhone');

  const { register, errors, handleSubmit, setValue, watch, clearErrors } =
    usePhoneForm({
      requiredError: t('errors.required'),
    });

  useEffect(() => {
    setValue('phone', String(newPhone?.replace('+', '')));
    clearErrors();
    dispatch(resetVerifiedOldPhoneCode());
  }, []);

  useEffect(() => {
    sessionStorage.setItem('newPhone', `+${watch('phone')}`);
  }, [newPhone, watch('phone')]);

  const handleNext = () => {
    dispatch(
      secretaryVerifyPhone({
        phone: `+${String(watch('phone'))}`,
        userType: UserType.Secretary,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        onNext();
      }
    });
  };

  return (
    <Wrapper>
      <InputWrapper>
        <InputPhone
          id="phone"
          label={t('phone') || ''}
          register={register}
          errorMessage={errors.phone?.message}
          value={watch('phone')}
          isValid={true}
        />
      </InputWrapper>
      <StyledDivider />
      <ButtonWrapper>
        <SecondaryButton onClick={onCancel}>{t('cancel')}</SecondaryButton>
        <PrimaryButton onClick={handleSubmit(handleNext)}>
          {t('next')}
        </PrimaryButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default NewPhone;
