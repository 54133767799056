import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { professionalProfileActions } from 'applicaiton/store/reducers/Professionals/ProfessionalProfileSlice';
import { PathNames } from 'applicaiton/routes';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { RegistrationData } from 'features/feature-professional-edit/types/password';
import { useCreatePasswordForm } from 'features/feature-professional-edit/hooks/useCreatePasswordForm';
import ButtonSection from '../ButtonSection';
import { Wrapper, DividerStyled } from './styles';
import { ChangePassword } from 'common/components';
import { userChangePassword } from 'applicaiton/store/reducers/Users/ActionCreators';
import { setIsPasswordChanged } from 'applicaiton/store/reducers/Users/UsersSlice';
import SuccessChangePasswordModal from 'common/components/SuccessChangePasswordModal';
import { useTranslation } from 'react-i18next';

const Password: FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isPasswordChanged, error } = useAppSelector((state) => state.users);
  const {
    register,
    errors,
    getValues,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useCreatePasswordForm();

  const handleCancel = () => {
    dispatch(professionalProfileActions.setProfileChanges(null));
    navigate(PathNames.professionals);
  };

  const onSubmit = async (data: RegistrationData) => {
    dispatch(userChangePassword(data));
  };

  const closeSuccessModal = () => {
    setValue('password', '');
    setValue('oldPassword', '');
    dispatch(setIsPasswordChanged(false));
  };

  useEffect(() => {
    if (error) {
      setError('oldPassword', { message: t('incorrect_password')! });
    } else {
      clearErrors();
    }
  }, [error, i18n.language]);

  const isButtonDisabled = !watch('password') || !watch('oldPassword');

  return (
    <Wrapper>
      <ChangePassword
        watch={watch}
        getValues={getValues}
        register={register}
        oldPasswordErrorMessage={errors.oldPassword?.message}
        passwordErrorMessage={errors.password?.message}
      />
      <div>
        <DividerStyled />
        <ButtonSection
          onSave={handleSubmit(onSubmit)}
          onCancel={handleCancel}
          disabled={isButtonDisabled}
        />
      </div>
      {isPasswordChanged && (
        <SuccessChangePasswordModal onClose={closeSuccessModal} />
      )}
    </Wrapper>
  );
};

export default Password;
