import React, { FC } from 'react';
import { AccordionStyled } from './styles';
import MenuItem from '../MenuItem';
import { useSubscriptionsMenu } from 'features/feature-subscriptions-submenu/hooks/useSubscriptionsMenu';

const AccordionSubscriptions: FC = () => {
  const subscriptionOptions = useSubscriptionsMenu();

  return (
    <AccordionStyled>
      <div className="accordion-list">
        {subscriptionOptions.map((item, index) => (
          <MenuItem
            key={index}
            name={item.name}
            path={item.path}
            Image={item.image}
          />
        ))}
      </div>
    </AccordionStyled>
  );
};
export default AccordionSubscriptions;
