import styled from 'styled-components';

export const Wrapper = styled.article`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    > section {
      width: 100%;
      display: flex;
      & section:first-child {
        margin-right: 32px;
      }
    }
  }
`;
