import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ClinicItem from '../ClinicItem';
import { TableStyled } from './styles';
import NoDataElement from '../NoDataElement';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { fetchClinics } from 'applicaiton/store/reducers/Clinics/ActionCreators';
import { Loader } from 'common/components';

const ClinicsTable: FC = () => {
  const { t } = useTranslation();
  const { clinics, isLoading } = useAppSelector((state) => state.clinics);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchClinics());
  }, [dispatch]);

  return (
    <>
      {isLoading && <Loader />}
      <TableStyled>
        <thead>
          <tr>
            <th>
              <span>{t('clinicsConfiguration.clinics.clinics_name')}</span>
            </th>
            <th>
              <span>{t('clinicsConfiguration.clinics.professionals')}</span>
            </th>
            <th>
              <span>{t('clinicsConfiguration.clinics.actions')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(clinics) &&
            clinics?.map((clinic) => (
              <ClinicItem key={clinic.id} clinic={clinic} />
            ))}
          {!isLoading && !clinics?.length && <NoDataElement />}
        </tbody>
      </TableStyled>
    </>
  );
};

export default ClinicsTable;
