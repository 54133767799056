import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyStateIcon } from 'applicaiton/assets/empty-state-appointments-past.svg';
import { StyledSection } from './styles';

const EmptySubscriptionsState: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledSection>
      <div>
        <EmptyStateIcon />
        <h2>
          {t(
            'professional_profile.subscriptions.patient_doesnt_have_subscriptions',
          )}
        </h2>
      </div>
    </StyledSection>
  );
};
export default EmptySubscriptionsState;
