import { NotificationConfigResponse } from '@docbay/schemas';
import { UpdateNotificationsSettingsProps } from 'common/types/notificationsSettings';
import axiosInstance from 'integration/api/axiosInstance';

export const NotificationsSettingsAPI = {
  fetchNotificationsSettings: (id: string) =>
    axiosInstance.get<NotificationConfigResponse>(
      `/notifications-config/clinic-owner/${id}`,
    ),
  updateNotificationsSettings: (params: UpdateNotificationsSettingsProps) =>
    axiosInstance.patch<NotificationConfigResponse>(
      `/notifications-config/clinic-owner/${params.id}`,
      params.data,
    ),
};
