import React, { FC, useMemo } from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import { ReactComponent as WarningIcon } from 'applicaiton/assets/warning.svg';
import { ReactComponent as OnlineIcon } from 'applicaiton/assets/online.svg';
import { ReactComponent as PhoneIcon } from 'applicaiton/assets/phone.svg';
import { ReactComponent as EditIcon } from 'applicaiton/assets/edit-square.svg';
import { ReactComponent as DeleteIcon } from 'applicaiton/assets/delete.svg';
import {
  selectAppointmentToDeleted,
  setSlotsDetails,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import {
  setAbsenceToDelete,
  setShowDeleteModal,
} from 'applicaiton/store/reducers/Absence/AbsenceSlice';
import { PathNames } from 'applicaiton/routes';
import { getUserId } from 'applicaiton/sessionStorage/auth';

import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { convertDateToTimeZone } from 'common/helpers/convertDateToTimeZone';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import { TableRowProps } from './modules';
import { StyledTr, NameStyled, TypeStyled, ActionsStyled } from './styles';

const TableRow: FC<TableRowProps> = ({ item }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userId = getUserId();
  const { tz } = useUserTimeZone();
  const { agenda } = useAppSelector((state) => state.agenda);
  const isMyAgendaPage = location.pathname === PathNames.clinicOwnerSchedule;

  const clinicOwnerId = useMemo(() => {
    const ownerId = item.clinicOwnerId;
    if (ownerId && ownerId !== userId) {
      return Number(item?.clinicOwnerId);
    }
    return undefined;
  }, [item]);

  const isPastAppointment = useMemo(() => {
    return !dayjs().isBefore(item?.startDate);
  }, [item]);

  const hasPermissionRescheduleAppointment = item.isAppointment
    ? !isPastAppointment &&
      userHasPermission(
        UserPermissions.rescheduleAppointment,
        clinicOwnerId,
        isMyAgendaPage,
      )
    : true;

  const hasPermissionCancelAgenda = useMemo(() => {
    if (item.isAppointment && isPastAppointment) {
      return false;
    } else {
      return userHasPermission(
        UserPermissions.cancelAgenda,
        Number(item.isAppointment ? clinicOwnerId : item.clinicOwnerId),
        isMyAgendaPage,
      );
    }
  }, [item, isPastAppointment, isMyAgendaPage, clinicOwnerId]);

  const getTime = (startDate: Date, endDate: Date) => {
    return `${dayjs(startDate).format('H:mm')} - ${dayjs(endDate).format(
      'H:mm',
    )}`;
  };

  const handleRescheduleClick = (editMode?: boolean) => {
    if (item.isAppointment) {
      dispatch(
        setSlotsDetails({
          appointmentId: String(item.id),
          mode: editMode
            ? ReserveSlotsMode.EditAppointment
            : ReserveSlotsMode.PreviewAppointment,
        }),
      );
    } else {
      dispatch(
        setSlotsDetails({
          professionalId: String(item.priorityId),
          absenceId: item.id,
          mode: ReserveSlotsMode.EditAbsence,
        }),
      );
    }
  };

  const cancelEvent = () => {
    if (item.isAppointment) {
      const currentAppointment = agenda.map((data) =>
        data.agenda.appointments.find(
          (appointment) => appointment.id === item.id,
        ),
      );

      if (currentAppointment?.length) {
        dispatch(
          selectAppointmentToDeleted({
            ...currentAppointment[0]!,
          }),
        );
      }
    } else {
      const startDate = dayjs(
        convertDateToTimeZone(new Date(item.startDate || ''), tz),
      ).toString();
      const endDate = dayjs(
        convertDateToTimeZone(new Date(item.endDate || ''), tz),
      ).toString();

      dispatch(
        setAbsenceToDelete({
          id: item.id,
          startDate: startDate as any,
          endDate: endDate as any,
          reason: '',
          comment: '',
          isOriginalRepeatEveryWeek: false,
        }),
      );
      dispatch(setShowDeleteModal(true));
    }
  };

  return (
    <StyledTr colorCode={item.colorCode || ''}>
      <td onClick={() => handleRescheduleClick()}>
        <NameStyled>
          <div>
            <h2>{item.patientName || ''}</h2>
            {item.patientPhoneNumber && (
              <p>
                <PhoneIcon />
                {item.patientPhoneNumber}
              </p>
            )}
          </div>
          {item.hasConfusing && <WarningIcon />}
        </NameStyled>
      </td>
      <td onClick={() => handleRescheduleClick()}>
        {getTime(item.startDate, item.endDate)}
      </td>
      <td onClick={() => handleRescheduleClick()}>
        <TypeStyled colorCode={item.colorCode || ''}>
          <div />
          <p>{item.appointmentType}</p>
          {item.isTeleconsultation && <OnlineIcon />}
        </TypeStyled>
      </td>
      <td onClick={() => handleRescheduleClick()}>{item.status}</td>
      <td>
        <ActionsStyled>
          {hasPermissionRescheduleAppointment && (
            <button
              className="editButton"
              onClick={() => handleRescheduleClick(true)}
            >
              <EditIcon />
            </button>
          )}
          {hasPermissionCancelAgenda && (
            <button onClick={cancelEvent}>
              <DeleteIcon />
            </button>
          )}
        </ActionsStyled>
      </td>
    </StyledTr>
  );
};
export default TableRow;
