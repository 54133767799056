import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PhotoActions } from 'applicaiton/constants/actions';
import { ClinicPhotoUpdateDto } from '@docbay/schemas';

interface InitialState {
  isLoading: boolean;
  isError: boolean;
  uploadedPhotos: ClinicPhotoUpdateDto[];
  photosToDelete: string[];
}

const initialState: InitialState = {
  uploadedPhotos: [],
  photosToDelete: [],
  isLoading: false,
  isError: false,
};

const isCurrentSliceChanged = (type: string, status: string) => {
  return type.endsWith(`${PhotoActions.uploadPhoto}/${status}`);
};

const photoSlice = createSlice({
  name: 'photo',
  initialState,
  reducers: {
    uploadedPhoto: (state, actions: PayloadAction<ClinicPhotoUpdateDto[]>) => {
      state.uploadedPhotos = actions.payload;
      state.isLoading = false;
      state.isError = false;
    },
    deletePhoto: (state, actions: PayloadAction<string>) => {
      state.photosToDelete.push(actions.payload);
    },
    setErrorPhoto: (state) => {
      state.isError = false;
    },
    setPhotosToDelete: (state, action: PayloadAction<string[]>) => {
      state.photosToDelete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'pending'),
      (state) => {
        state.isLoading = true;
        state.isError = false;
      },
    );
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'rejected'),
      (state) => {
        state.isLoading = false;
        state.isError = true;
      },
    );
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'fulfilled'),
      (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.uploadedPhotos = action.payload;
      },
    );
  },
});

export const { uploadedPhoto, deletePhoto, setErrorPhoto, setPhotosToDelete } =
  photoSlice.actions;

export default photoSlice.reducer;
