import { useTranslation } from 'react-i18next';
import { Permissions } from '../constants';

export const useAgendaConfiguration = () => {
  const { t } = useTranslation();

  const agendaConfiguration = [
    {
      key: Permissions.CreateAppointment,
      name: t(
        'user_permissions_configuration.agenda_configuration.appointments_creation',
      ),
    },
    {
      key: Permissions.RescheduleAppointment,
      name: t(
        'user_permissions_configuration.agenda_configuration.appointments_reschedule',
      ),
    },
    {
      key: Permissions.CancelAgenda,
      name: t(
        'user_permissions_configuration.agenda_configuration.agenda_cancellation',
      ),
    },
    {
      key: Permissions.CreateAbsence,
      name: t(
        'user_permissions_configuration.agenda_configuration.absence_creation',
      ),
    },
    {
      key: Permissions.ProfessionalOpeningHours,
      name: t(
        'user_permissions_configuration.agenda_configuration.doctor_working_hours',
      ),
    },
    {
      key: Permissions.AccessToOtherAgendas,
      name: t(
        'user_permissions_configuration.agenda_configuration.access_to_agendas_of_others',
      ),
    },
  ];

  return agendaConfiguration;
};
