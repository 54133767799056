import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './models';

const initialState: InitialState = {
  policyType: null,
};

const agreementsAndPoliciesSlice = createSlice({
  name: 'agreementsAndPoliciesSlice',
  initialState,
  reducers: {
    setPolicyType: (state, action) => {
      state.policyType = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setPolicyType } = agreementsAndPoliciesSlice.actions;

export default agreementsAndPoliciesSlice.reducer;
