import styled from 'styled-components';

export const LoaderStyled = styled.main`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 145px;
    height: 145px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 24px;

    background: ${({ theme }) => theme.color.white};

    > svg {
      animation: anim__rotate 5s linear infinite;
    }
  }

  > h1 {
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 1px;
  }

  @keyframes anim__rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
