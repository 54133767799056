import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchAppointmentTypeById,
  fetchAppointmentTypes,
  postAppointmentTypes,
  updateAppointmentType,
  deleteAppointmentTypes,
  updateAppointmentTypeNotifications,
  changeAppointmentTypeNotificationsConfig,
} from './ActionCreators';
import { InitialState } from './models';
import { AppointmentTypeCreationDto } from '@docbay/schemas';
import { searchProfessionalsByFilters } from '../Scheduler/ActionCreators';
import { Option } from 'common/components/Dropdown/models';

export enum MeetingPlace {
  Clinic = 'Clinic',
  Teleconsultation = 'Teleconsultation',
}

interface FilteredTypes {
  clinic?: Option;
  specializations?: string[];
}

const initialState: InitialState = {
  isLoading: false,
  appointmentTypes: [],
  professionalAppointmentTypes: [],
  currentAppointmentTypeChanges: null,
  appointmentTypeChanges: {
    title: '',
    durationInMinutes: 10,
    meetingPlace: MeetingPlace.Clinic,
    colorCode: '#756FD7',
    availableForOnlineBooking: true,
    reservationOpenInMinutes: 259200,
    reservationCloseInMinutes: 0,
    clinicId: '',
    specializationId: '',
  },
  professionals: [],
  filteredTypes: null,
  error: '',
  total: 0,
  page: 0,
  totalPages: 0,
  isAdded: false,
  isUpdated: false,
  isDeleted: false,
};

const appointmentTypesSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    resetProfessionalAppointmentTypes: (state) => {
      state.professionalAppointmentTypes = [];
    },
    setAppointmentTypeChanges: (
      state,
      actions: PayloadAction<AppointmentTypeCreationDto>,
    ) => {
      state.appointmentTypeChanges = actions.payload;
    },
    resetAppointmentTypeChanges: (state) => {
      state.appointmentTypeChanges = initialState.appointmentTypeChanges;
      state.currentAppointmentTypeChanges = null;
      state.isAdded = false;
      state.isUpdated = false;
      state.error = '';
    },
    resetUpdatedState: (state) => {
      state.isUpdated = false;
      state.error = '';
    },
    setFilteredAppointmentTypes: (
      state,
      actions: PayloadAction<FilteredTypes | null>,
    ) => {
      state.filteredTypes = actions.payload;
    },
    resetIsDeleted: (state) => {
      state.isDeleted = false;
    },
    resetProfessionals: (state) => {
      state.professionals = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAppointmentTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAppointmentTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAppointmentTypes.fulfilled, (state, action) => {
      if (action.meta.arg.onlyProfessional) {
        state.professionalAppointmentTypes = action.payload.entities;
      } else {
        state.appointmentTypes = action.payload.entities;
        state.total = action.payload.total!;
        state.page = action.payload.page!;
        state.totalPages = action.payload.totalPages!;
      }
      state.isLoading = false;
    });
    builder.addCase(searchProfessionalsByFilters.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchProfessionalsByFilters.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(searchProfessionalsByFilters.fulfilled, (state, action) => {
      state.professionals = action.payload;
      state.isLoading = false;
    });
    builder.addCase(postAppointmentTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postAppointmentTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(postAppointmentTypes.fulfilled, (state) => {
      state.appointmentTypeChanges = initialState.appointmentTypeChanges;
      state.professionals = [];
      state.isLoading = false;
      state.isAdded = true;
    });
    // get current appointment type
    builder.addCase(fetchAppointmentTypeById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAppointmentTypeById.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAppointmentTypeById.fulfilled, (state, action) => {
      state.currentAppointmentTypeChanges = action.payload;
      state.isLoading = false;
    });
    // update appointment type
    builder.addCase(updateAppointmentType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAppointmentType.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateAppointmentType.fulfilled, (state, action) => {
      state.currentAppointmentTypeChanges = {
        ...state.currentAppointmentTypeChanges,
        ...action.payload,
      };
      state.isLoading = false;
      state.isUpdated = true;
    });
    builder.addCase(deleteAppointmentTypes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAppointmentTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteAppointmentTypes.fulfilled, (state) => {
      state.isLoading = false;
      state.isDeleted = true;
    });
    // update appointment type notifications
    builder.addCase(updateAppointmentTypeNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateAppointmentTypeNotifications.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      },
    );
    builder.addCase(updateAppointmentTypeNotifications.fulfilled, (state) => {
      state.isLoading = false;
    });
    // update appointment type notifications config
    builder.addCase(
      changeAppointmentTypeNotificationsConfig.pending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      changeAppointmentTypeNotificationsConfig.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      },
    );
    builder.addCase(
      changeAppointmentTypeNotificationsConfig.fulfilled,
      (state) => {
        state.isLoading = false;
      },
    );
  },
});

export const {
  setAppointmentTypeChanges,
  resetAppointmentTypeChanges,
  resetUpdatedState,
  setFilteredAppointmentTypes,
  resetIsDeleted,
  resetProfessionals,
  resetProfessionalAppointmentTypes,
} = appointmentTypesSlice.actions;

export default appointmentTypesSlice.reducer;
