import styled from 'styled-components';

export const FilterSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  background: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;

  > h2 {
    margin-left: 0;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.grey900};
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
  }
  > div {
    display: flex;
    padding: 6px 12px;
    align-items: center;
    border-radius: 100px;
    background: ${({ theme }) => theme.color.grey200};
    color: ${({ theme }) => theme.color.grey800};
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    max-width: 220px;

    > p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    > button {
      display: flex;
      border: none;
      background: none;
      margin: 0;
      padding: 0;
      > svg {
        width: 16px;
        height: 16px;
        margin-left: 4px;
        cursor: pointer;
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }
  }
`;

export const ControllerSection = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
  > div:first-child {
    min-width: 220px;
    max-width: 220px;
  }
  > div:last-child {
    width: 220px;
  }
`;

export const ResetButton = styled.button`
  width: fit-content;
  border: none;
  background: none;
  padding: 0;
  color: ${({ theme }) => theme.color.blue800};
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: 0.08px;
  cursor: pointer;
`;
