import styled from 'styled-components';

interface LiProps {
  isActive: boolean;
  onClick: () => void;
}

export const Wrapper = styled.div`
  flex-grow: 1;
  padding: 32px;
  min-height: 100vh;
  background: ${({ theme }) => theme.color.background};
  overflow-y: auto;
`;

export const ContentWrapper = styled.div`
  margin-top: 32px;
  flex-grow: 1;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  background: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  border-right: 1px solid ${({ theme }) => theme.color.grey200};
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  border-left: 1px solid ${({ theme }) => theme.color.grey200};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
`;

export const ContentDivider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.color.grey200};
`;

export const EditContent = styled.div`
  flex-grow: 1;
`;

export const EditWrapper = styled.div`
  display: flex;
  width: 100%;

  > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 80px;
    border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
    padding: 0 64px 32px 0;
  }
`;

export const EditList = styled.ul`
  list-style: none;
  min-width: 220px;
`;

export const EditListItem = styled.li<LiProps>`
  display: flex;
  align-items: center;
  margin-top: 4px;
  padding: 8px 12px;
  cursor: pointer;
  line-height: 140%;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.black : theme.color.grey700};
  border-radius: ${({ isActive }) => (isActive ? '6px' : 'none')};
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  background: ${({ theme, isActive }) =>
    isActive ? theme.color.green100 : 'none'};

  > svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;

    path {
      fill: ${({ theme }) => theme.color.error2};
    }
  }
`;

export const NotificationStyled = styled.div`
  display: grid;
  width: 220px;
  padding: 8px;
  grid-template-columns: 16px 180px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.grey50};
  margin-top: 20px;

  > svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${({ theme }) => theme.color.error2};
    }
  }
  > p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: 0.07px;
  }
`;
