import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoSectionStyled, Divider } from './styles';
import { InfoSectionProps } from './models';
import { useDownloadCSVTemplate } from 'common/hooks/useDownloadCSVTemplate';
import DocumentsUploadModal from 'common/components/DocumentsUploadModal';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  fetchPatients,
  uploadCSV,
} from 'applicaiton/store/reducers/Patients/ActionCreators';
import ImportedCSVInfoModal from '../ImportedCSVInfoModal';
import {
  resetImportedPatients,
  resetImportError,
} from 'applicaiton/store/reducers/Patients/PatientsSlice';
import ImportErrorModal from '../ImportErrorModal';

const InfoSection: FC<InfoSectionProps> = ({
  patientAvailable,
  setCurrentPage,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const downloadDocument = useDownloadCSVTemplate();
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isImportedDataOpen, setIsImportedDataOpen] = useState(false);

  const { importError, isLoading } = useAppSelector((state) => state.patients);

  const handleCloseModal = () => {
    setIsImportModalOpen(false);
  };

  const handleCloseImportedInfoModal = () => {
    setIsImportModalOpen(false);
    setIsImportedDataOpen(false);
    dispatch(resetImportedPatients());
  };

  const handleCloseErrorModal = () => {
    dispatch(resetImportError());
  };

  const handleSaveModal = (data: FormData) => {
    dispatch(uploadCSV(data)).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        dispatch(
          fetchPatients({
            page: 1,
            limit: 10,
          }),
        );
        setCurrentPage(1);
        setIsImportedDataOpen(true);
        handleCloseModal();
      }
    });
  };

  return (
    <InfoSectionStyled>
      <h1>{t('my_patients.title')}</h1>
      <div>
        <p>{`${patientAvailable}  ${t(
          'my_patients.patients_are_available',
        )}`}</p>
        <button type="button" onClick={downloadDocument}>
          {t('my_patients.download_template')}
        </button>
        <Divider />
        <button type="button" onClick={() => setIsImportModalOpen(true)}>
          {t('my_patients.import_file')}
        </button>
      </div>
      <DocumentsUploadModal
        isOpen={isImportModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveModal}
        isLoading={isLoading}
        extantions={'.xlsx'}
      />
      <ImportedCSVInfoModal
        onClose={handleCloseImportedInfoModal}
        isOpen={isImportedDataOpen}
      />
      {importError && <ImportErrorModal onClose={handleCloseErrorModal} />}
    </InfoSectionStyled>
  );
};

export default InfoSection;
