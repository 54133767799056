import {
  CommonEntitiesListResponse,
  SupportedCountryResponse,
} from '@docbay/schemas';
import axiosAuthInstance from './axiosAuthInstance';

export const CountriesAPI = {
  getSupportedCountries: async () => {
    return await axiosAuthInstance('').get<
      CommonEntitiesListResponse<SupportedCountryResponse>
    >('/countries/supported');
  },
};
