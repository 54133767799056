import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { OnlineHoursState } from './models';
import { fetchOnlineHours, updateOnlineHours } from './ActionCreators';

const initialState: OnlineHoursState = {
  isLoading: false,
  error: '',
  onlineHours: [],
  professionalId: '',
  onlineStatusVisible: false,
  defaultState: {
    onlineHours: [],
    onlineStatusVisible: false,
  },
};

const onlineHoursSlice = createSlice({
  name: 'onlineHours',
  initialState,
  reducers: {
    setOnlineStatusVisible: (state, action: PayloadAction<boolean>) => {
      state.onlineStatusVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    //fetch online hours
    builder.addCase(fetchOnlineHours.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOnlineHours.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchOnlineHours.fulfilled, (state, action) => {
      state.onlineHours = action.payload.onlineHours;
      state.onlineStatusVisible = action.payload.onlineStatusVisible;
      state.professionalId = action.payload.professionalId;
      state.defaultState.onlineHours = action.payload.onlineHours;
      state.defaultState.onlineStatusVisible =
        action.payload.onlineStatusVisible;
      state.isLoading = false;
      state.error = '';
    });
    //update online hours
    builder.addCase(updateOnlineHours.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateOnlineHours.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateOnlineHours.fulfilled, (state, action) => {
      state.onlineHours = action.payload.onlineHours;
      state.onlineStatusVisible = action.payload.onlineStatusVisible;
      state.defaultState.onlineHours = action.payload.onlineHours;
      state.defaultState.onlineStatusVisible =
        action.payload.onlineStatusVisible;
      state.error = '';
      state.isLoading = false;
    });
  },
});

export const { setOnlineStatusVisible } = onlineHoursSlice.actions;

export default onlineHoursSlice.reducer;
