import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as PlusIcon } from 'applicaiton/assets/plus.svg';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';
import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { setMode } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';

import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { userHasPermission } from 'common/helpers/userHasPermission';

import { useHeader } from 'features/feature-agenda-reserve-slots-modal/hooks/useHeaders';

import { HeaderProps } from './models';
import { HeaderStyled, CloseButton, Tabs, Tab } from './styles';

const Header: FC<HeaderProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { mode } = useAppSelector((state) => state.appointmentsSlice);
  const title = useHeader(mode!);

  const isCanCreateAbsence = userHasPermission(UserPermissions.createAbsence);
  const isCanCreateAppointment = userHasPermission(
    UserPermissions.createAppointment,
  );
  const isCanManageWorkingHours = userHasPermission(
    UserPermissions.professionalOpeningHours,
  );

  const isCreateAppointment = mode === ReserveSlotsMode.CreateAppointment;
  const isCreateAbsence = mode === ReserveSlotsMode.CreateAbsence;
  const isOpenAgenda = mode === ReserveSlotsMode.OpenAgenda;
  const isCreateMode = isCreateAppointment || isCreateAbsence || isOpenAgenda;

  const handleChangeTab = (mode: ReserveSlotsMode) => {
    dispatch(setMode(mode));
  };

  return (
    <HeaderStyled isCreateMode={isCreateMode}>
      <div>
        <h1>{title}</h1>
        {isCreateMode && (
          <Tabs>
            {isCanCreateAppointment && (
              <Tab
                isActive={isCreateAppointment}
                onClick={() =>
                  handleChangeTab(ReserveSlotsMode.CreateAppointment)
                }
              >
                <PlusIcon />
                {t('absenceModal.book_appointment')}
              </Tab>
            )}
            {isCanCreateAbsence && (
              <Tab
                isActive={isCreateAbsence}
                onClick={() => handleChangeTab(ReserveSlotsMode.CreateAbsence)}
              >
                <PlusIcon />
                {t('absenceModal.create_absence')}
              </Tab>
            )}
            {isCanManageWorkingHours && (
              <Tab
                isActive={isOpenAgenda}
                onClick={() => handleChangeTab(ReserveSlotsMode.OpenAgenda)}
              >
                {t('absenceModal.open_agenda')}
              </Tab>
            )}
          </Tabs>
        )}
      </div>
      <CloseButton type="button" onClick={onClose}>
        <CloseIcon />
      </CloseButton>
    </HeaderStyled>
  );
};

export default Header;
