import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ClinicProfileState } from './models';
import { ClinicsActions } from 'applicaiton/constants/actions';
import {
  createClinicProfile,
  fetchClinicProfile,
  saveClinicProfile,
} from './ActionCreators';
import { ClinicResponseDto } from '@docbay/schemas';

const initialState: ClinicProfileState = {
  clinicProfileData: null,
  profileChanges: null,
  isLoading: false,
  error: '',
  showSuccessModal: false,
};

const isCurrentSliceChanged = (type: string, status: string) => {
  return (
    type.endsWith(`${ClinicsActions.getClinicProfile}/${status}`) ||
    type.endsWith(`${ClinicsActions.saveClinicProfile}/${status}`) ||
    type.endsWith(`${ClinicsActions.createClinicProfile}/${status}`)
  );
};

const clinicProfileSlice = createSlice({
  name: 'clinicProfile',
  initialState,
  reducers: {
    clinicProfile: (
      state,
      actions: PayloadAction<ClinicResponseDto | null>,
    ) => {
      state.clinicProfileData = actions.payload!;
    },
    setProfileChanges: (
      state,
      actions: PayloadAction<ClinicResponseDto | null>,
    ) => {
      state.profileChanges = actions.payload;
    },
    setShowSuccessModal: (state, actions: PayloadAction<boolean>) => {
      state.showSuccessModal = actions.payload;
    },
  },
  extraReducers: (builder) => {
    // Requests
    builder.addCase(fetchClinicProfile.fulfilled, (state, action) => {
      state.clinicProfileData = action.payload;
    });
    builder.addCase(createClinicProfile.fulfilled, (state, action) => {
      state.clinicProfileData = action.payload;
    });
    builder.addCase(saveClinicProfile.fulfilled, (state, action) => {
      state.clinicProfileData = action.payload;
    });

    // Loading
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'pending'),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'rejected'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'fulfilled'),
      (state) => {
        state.isLoading = false;
        state.profileChanges = null;
      },
    );
  },
});

export const { clinicProfile, setProfileChanges, setShowSuccessModal } =
  clinicProfileSlice.actions;

export default clinicProfileSlice.reducer;
