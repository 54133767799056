import React, { useRef } from 'react';
import {
  Appointments,
  AppointmentsList,
  PatientData,
  PatientInfo,
  PatientNameSection,
  SeeProfile,
} from './styles';
import { ReactComponent as ChevronIcon } from 'applicaiton/assets/chevron-right.svg';
import { AppointmentBriefResponseDto } from '@docbay/schemas';
import PatientsAppointment from '../PatientsAppointment';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'common/hooks/useClickOutside';
import { resetPatientAppointmentsData } from 'applicaiton/store/reducers/Appointments/PatientAppoinmentsSlice';
import { setCurrentSearchItem } from 'applicaiton/store/reducers/Patients/PatientsSlice';
import { PathNames } from 'applicaiton/routes';
import { Link } from 'react-router-dom';

const PatientSearchInfo = () => {
  const { t } = useTranslation();
  const { appointments } = useAppSelector((state) => state.patientAppointments);
  const { currentFamilyMemberSearch } = useAppSelector(
    (state) => state.patients,
  );
  const dispatch = useAppDispatch();
  const refPatientInfo = useRef(null);

  useOnClickOutside(refPatientInfo, () => {
    dispatch(resetPatientAppointmentsData());
    dispatch(setCurrentSearchItem(null));
  });

  return (
    <PatientInfo ref={refPatientInfo}>
      <PatientData>
        <PatientNameSection>
          <p>
            {currentFamilyMemberSearch?.firstName}{' '}
            {currentFamilyMemberSearch?.lastName}
          </p>
          <p>{currentFamilyMemberSearch?.phone}</p>
        </PatientNameSection>
        <SeeProfile>
          <Link
            to={`${PathNames.patient}/${
              currentFamilyMemberSearch?.isPatient
                ? currentFamilyMemberSearch?.id!
                : currentFamilyMemberSearch?.patient?.id!
            }`}
            target="_blank"
          >
            <span>{t('see_profile')}</span>
            <ChevronIcon />
          </Link>
        </SeeProfile>
      </PatientData>
      {!!appointments.length && (
        <>
          {' '}
          <Appointments>{t('patient.future_appointments')}</Appointments>
          <AppointmentsList>
            {appointments.map((item: AppointmentBriefResponseDto) => (
              <PatientsAppointment key={item.id} appointment={item} />
            ))}
          </AppointmentsList>
        </>
      )}
    </PatientInfo>
  );
};

export default PatientSearchInfo;
