import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderStyled, ClinicInfo, ClinicImage } from './styles';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import { useAppSelector } from 'common/hooks/redux';

const Header: FC = () => {
  const { t } = useTranslation();

  const { currentUser } = useAppSelector((state) => state.admins);

  return (
    <HeaderStyled>
      <ClinicInfo>
        <ClinicImage>
          {currentUser?.photo ? (
            <img
              src={currentUser.photo.thumbnailUrl}
              alt={currentUser.photo.key}
            />
          ) : (
            <PersonIcon />
          )}
        </ClinicImage>
        <h1>{t('My DocBay Profile')}</h1>
      </ClinicInfo>
    </HeaderStyled>
  );
};

export default Header;
