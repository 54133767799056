import React, { useEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  StyledSection,
  StepSection,
  StepItem,
  Step,
  StepText,
  StepSectionWrapper,
  BackToProfile,
  SetNewPhoneTitle,
  FormWrapper,
} from './styles';

import { ReactComponent as CheckIcon } from 'applicaiton/assets/checked.svg';
import { ReactComponent as ArrowLeftIcon } from 'applicaiton/assets/arrow-left.svg';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import VerifyPhone from '../VerifyPhone';
import NewPhone from '../NewPhone';
import { ChangePhoneModalProps } from './modules';

const ChangePhoneModal = ({ isOpen, onClose }: ChangePhoneModalProps) => {
  const [activeForm, setActiveForm] = useState<number>(0);
  const { t } = useTranslation();
  const isHasOldPhone = !!sessionStorage.getItem('oldPhone')?.length;

  const setNextStep = () => {
    setActiveForm((prev) => prev + 1);
  };

  useEffect(() => {
    setActiveForm(isHasOldPhone ? 0 : 2);
  }, []);

  const cancelPhoneChange = () => {
    setActiveForm(0);
    onClose();
  };

  const getCurrentForm = () => {
    switch (activeForm) {
      case 0:
        return (
          <VerifyPhone onNext={setNextStep} onCancel={cancelPhoneChange} />
        );
      case 1:
        return <NewPhone onNext={setNextStep} onCancel={cancelPhoneChange} />;
      default:
        return (
          <VerifyPhone
            isNewPhone
            onNext={setNextStep}
            onCancel={cancelPhoneChange}
          />
        );
    }
  };

  const verifyExistingPhoneStep = useMemo(() => {
    return activeForm === 0;
  }, [activeForm]);

  const newPhoneStep = useMemo(() => {
    return activeForm === 1;
  }, [activeForm]);

  const verifyNewPhoneStep = useMemo(() => {
    return activeForm === 2;
  }, [activeForm]);

  return (
    <Modal open={isOpen}>
      <Wrapper>
        <StyledSection>
          <BackToProfile onClick={onClose}>
            <ArrowLeftIcon />
            {t('clinic_owner.email.back_to_profile')}
          </BackToProfile>
          <SetNewPhoneTitle>
            {t('changePhone.phone.set_new_phone')}
          </SetNewPhoneTitle>
          <FormWrapper>
            <StepSectionWrapper>
              <StepSection>
                {isHasOldPhone && (
                  <>
                    {' '}
                    <StepItem>
                      {activeForm < 1 ? (
                        <Step isActive={verifyExistingPhoneStep}>1</Step>
                      ) : (
                        <Step isActive={true}>
                          <CheckIcon />
                        </Step>
                      )}
                      <StepText isActive={verifyExistingPhoneStep}>
                        {t('changePhone.phone.verify_existing_phone')}
                      </StepText>
                    </StepItem>
                    <StepItem>
                      {activeForm < 2 ? (
                        <Step isActive={newPhoneStep}>2</Step>
                      ) : (
                        <Step isActive={true}>
                          <CheckIcon />
                        </Step>
                      )}
                      <StepText isActive={newPhoneStep}>
                        {t('changePhone.phone.new_phone')}
                      </StepText>
                    </StepItem>
                  </>
                )}

                <StepItem>
                  {activeForm < 2 ? (
                    <Step isActive={verifyNewPhoneStep}>2</Step>
                  ) : (
                    <Step isActive={true}>
                      <CheckIcon />
                    </Step>
                  )}
                  <StepText isActive={verifyNewPhoneStep}>
                    {t('changePhone.phone.verify_new_phone')}
                  </StepText>
                </StepItem>
              </StepSection>
            </StepSectionWrapper>
            {getCurrentForm()}
          </FormWrapper>
        </StyledSection>
      </Wrapper>
    </Modal>
  );
};

export default ChangePhoneModal;
