import { useTranslation } from 'react-i18next';

export const useTimezonesWithOffsets = () => {
  const { t } = useTranslation();

  const timezoneList = Intl.supportedValuesOf('timeZone').map((timeZone) => {
    const timeZoneName = Intl.DateTimeFormat('en', {
      timeZone: timeZone,
      timeZoneName: 'longOffset',
    })
      .formatToParts()
      .find((item) => item.type === 'timeZoneName').value;

    const gmt =
      timeZoneName.length === 3
        ? `(${timeZoneName}+0:00)`
        : `(${timeZoneName})`;

    return {
      label: `${gmt} ${t(
        `timezone_list.${timeZone.replaceAll('/', '_').toLowerCase()}`,
      )}`,
      value: timeZone,
    };
  });

  const timezoneListSortByAlphabet = timezoneList.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  const timezoneListSortByGMT = timezoneListSortByAlphabet.sort((a, b) => {
    const firstItemGmt = Number(
      a.label.replaceAll(':', '.').slice('4', a.label.indexOf(')')),
    );
    const secondItemGmt = Number(
      b.label.replaceAll(':', '.').slice('4', b.label.indexOf(')')),
    );

    if (firstItemGmt < secondItemGmt) {
      return -1;
    }
    if (firstItemGmt > secondItemGmt) {
      return 1;
    }
    return 0;
  });

  return { timezoneListSortByGMT };
};
