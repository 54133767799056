import { Box, styled } from '@mui/system';
import { commonColors } from 'applicaiton/theme';

interface ActiveProps {
  active: boolean;
}

interface Props {
  emergency: boolean;
}

export const Wrapper = styled(Box)`
  max-height: calc(100vh - 374px);
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  overflow-y: auto;
`;

export const ListItemWrapper = styled('div')<ActiveProps>`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: ${({ active }) =>
    active ? commonColors.blue100 : commonColors.grey50};
  border-left: ${({ active }) =>
    active ? `2px solid ${commonColors.blue700}` : 'none'};
  cursor: pointer;
  box-sizing: border-box;
`;

export const ListItemHeader = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  .text {
    flex: 1;
    overflow: hidden;
    color: ${commonColors.grey900};
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.08px;
  }
  .time {
    flex: 0;
    color: ${commonColors.grey500};
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
  }
`;

export const ListItemContent = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  .content-wrapper {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .topic {
    color: ${commonColors.grey700};
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
  }
  .message {
    color: ${commonColors.grey700};
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .counter {
    color: ${commonColors.white};
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    width: 20px;
    height: 20px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 40px;
    background: ${commonColors.blue700};
  }
`;

export const ProfessionalPhoto = styled('div')<Props>`
  position: relative;

  > div {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    border: ${({ emergency }) =>
      emergency ? `2px solid ${commonColors.warning2}` : 'none'};
    box-sizing: border-box;
  }
`;

export const ProfessionalData = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  gap: 4px;
  max-width: calc(100% - 60px);
`;

export const ActiveUser = styled('span')`
  width: 12px;
  height: 12px;
  background: ${commonColors.onlineGreen};
  border-radius: 50%;
  border: 2px solid ${commonColors.white};
  position: absolute;
  right: 0;
  top: 0;
`;

export const AvatarStyled = styled('div')`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  background: ${commonColors.blueDark100};
  border-radius: 50%;

  > svg {
    path {
      fill: ${commonColors.grey900};
    }
  }
`;
