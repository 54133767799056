import { Box, styled } from '@mui/system';
import { styledTheme } from 'applicaiton/theme';

export const FormTitle = styled(Box)`
  color: ${styledTheme.color.grey900};
  text-align: center;
  font-family: 'AcidGrotesk-Medium';
  font-size: 28px;
  line-height: 39.2px;
  letter-spacing: 0.5px;
`;

export const FormSubTitle = styled(Box)`
  color: ${styledTheme.color.grey700};
  text-align: center;
  font: 500 18px/25.2px 'Mulish', sans-serif;
  letter-spacing: 0.09px;
`;
export const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
`;
