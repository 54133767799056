import styled from 'styled-components';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 32px;
    border-bottom: 1px solid ${commonColors.grey200};
  }
`;

export const FieldsSection = styled.div`
  width: 470px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  button {
    width: 150px;
    &:last-child {
      margin-left: 20px;
    }
  }
`;
