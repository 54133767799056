import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(110px, max-content) max-content max-content;
  gap: 32px;

  > div {
    display: grid;
    grid-template-columns: min-content;
    gap: 12px;

    > button:last-child {
      display: flex;
      cursor: pointer;
      border: none;
      background: none;
      padding: 0;
      width: fit-content;

      > svg {
        width: 20px;
        height: 20px;
        margin-right: 4px;
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }

      > h2 {
        color: ${({ theme }) => theme.color.grey600};
        font-size: 14px;
        font-weight: 700;
        line-height: 140%;
        letter-spacing: 0.07px;
        white-space: nowrap;
      }

      &:disabled {
        cursor: not-allowed;
        > svg {
          g {
            path {
              fill: ${({ theme }) => theme.color.grey400};
            }
          }
        }
        > h2 {
          color: ${({ theme }) => theme.color.grey400};
        }
      }
    }
  }
`;

export const TimeWrapper = styled.div`
  display: flex;
  align-items: center;

  > button {
    height: 100%;
    display: flex;
    border: none;
    background: none;
    padding: 0;

    > svg {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      cursor: pointer;

      g {
        path {
          fill: ${({ theme }) => theme.color.grey500};
        }
      }
    }

    &:disabled {
      > svg {
        cursor: not-allowed;
      }
    }
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 12px;
  margin: 0 12px;
  background: ${({ theme }) => theme.color.grey400};
`;
