import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { UserRoles } from 'applicaiton/constants/userRoles';
import {
  getUserProPermissions,
  getUserPermissions,
  getUserRole,
} from 'applicaiton/sessionStorage/auth';
import { userRoleExactMatch } from './userRoleExactMatch';

export const userHasPermission = (
  type: UserPermissions,
  clinicOwnerId?: number,
  checkCOProPermission?: boolean,
): boolean => {
  const userRole = getUserRole();
  const permissions = getUserPermissions();
  const proPermissions = getUserProPermissions();
  const isClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const isProfessional = userRole?.includes(UserRoles.professional);
  const isAdmin = userRoleExactMatch(UserRoles.admin);
  const isSecretary = userRole?.includes(UserRoles.secretary);

  if (isClinicOwner && !checkCOProPermission) {
    return true;
  }

  if (proPermissions?.length && isProfessional) {
    if (clinicOwnerId) {
      const permissionsByClinicOwnerId = proPermissions.find(
        (item: any) => !!item[clinicOwnerId],
      );

      if (!permissionsByClinicOwnerId) return false;

      return permissionsByClinicOwnerId[clinicOwnerId]?.includes(type);
    }
    const hasPermission = proPermissions.some((item: any) => {
      for (const property in item) {
        return item[property].includes(type);
      }
    });
    return hasPermission;
  }
  if (isAdmin || isSecretary) {
    return !!permissions?.includes(type);
  }

  return false;
};
