import React, { FC, JSXElementConstructor } from 'react';
import { MenuItemStyled } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'common/hooks/redux';
import { setNextPath } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { useDetectPathChanged } from 'common/hooks/useDetectPathChanged';
import { resetAgendaFiltersSessionStorage } from 'applicaiton/sessionStorage/scheduler';
import { resetScheduler } from 'applicaiton/store/reducers/Scheduler/SchedulerSlice';

interface MenuItemProps {
  path: string;
  name: string;
  Image?: JSXElementConstructor<any>;
  isNotificationItem?: boolean;
  isDocBoxItem?: boolean;
  availablePaths?: string[];
}

const MenuItem: FC<MenuItemProps> = ({ path, name, Image }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isCurrentPage = location.pathname === path;
  const hasUnsavedChanges = useDetectPathChanged(path);

  const handleChangePath = () => {
    if (!isCurrentPage) {
      resetAgendaFiltersSessionStorage();
      dispatch(resetScheduler());
      if (!hasUnsavedChanges) {
        navigate(path);
      } else {
        dispatch(setNextPath(path));
      }
    }
  };

  return (
    <MenuItemStyled>
      <button
        onClick={handleChangePath}
        className={`${isCurrentPage ? 'active' : ''}`}
      >
        <>
          {Image && <Image />}
          {name}
        </>
      </button>
    </MenuItemStyled>
  );
};

export default MenuItem;
