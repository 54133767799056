import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NotificationsInboxState } from './models';
import {
  getNotifications,
  readAllNotification,
  updateNotification,
} from './ActionCreators';

const initialState: NotificationsInboxState = {
  isLoading: false,
  error: '',
  notifications: [],
  total: 0,
  page: 0,
  totalPages: 0,
};

const notificationsInboxSlice = createSlice({
  name: 'notificationsInbox',
  initialState,
  reducers: {
    setIsRead: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.map((item) => {
        if (item.id === action.payload) {
          return { ...item, isRead: true };
        }
        return item;
      });
    },
  },
  extraReducers: (builder) => {
    //get notifications
    builder.addCase(getNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.notifications = action.payload.entities;
      state.total = action.payload.total!;
      state.page = action.payload.page!;
      state.totalPages = action.payload.totalPages!;
    });
    //update notifications
    builder.addCase(updateNotification.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateNotification.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateNotification.fulfilled, (state) => {
      state.error = '';
      state.isLoading = false;
    });
    //read all notification
    builder.addCase(readAllNotification.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(readAllNotification.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(readAllNotification.fulfilled, (state) => {
      state.error = '';
      state.isLoading = false;
    });
  },
});

export const { setIsRead } = notificationsInboxSlice.actions;

export default notificationsInboxSlice.reducer;
