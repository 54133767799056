import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccesslIcon } from 'applicaiton/assets/success-icon.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { SuccessModalProps } from './modules';

const SuccessEmailModal: FC<SuccessModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccesslIcon />
          <p>{t('clinic_owner.modal_email_success.title_part1')}</p>
          <SecondaryButton onClick={onClose}>
            {t('clinic_owner.modal_success.ok')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessEmailModal;
