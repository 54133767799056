import styled from 'styled-components';
import { StyledProps } from './models';

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  width: 664px;
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

  h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: ${({ theme }) => theme.color.grey700};
      }
    }
  }
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;

  > div:first-of-type {
    display: grid;
    grid-template-rows: repeat(7, max-content);
    gap: 18px;
    height: 476px;
    overflow-y: auto;
    padding: 32px;
  }
`;

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
`;

export const RadioButtonSection = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    color: ${({ theme }) => theme.color.grey600};
    font-family: 'Mulish';
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    margin-bottom: 8px;
  }

  > div {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 16px;
  }
`;

export const ClinicSection = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;

  > p {
    color: ${({ theme, isError }) =>
      isError ? theme.color.error2 : theme.color.grey600};
    font-family: 'Mulish';
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    margin-bottom: 8px;
  }

  .clinics-list {
    top: unset;
    bottom: 100%;
  }
`;

export const ButtonSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.color.grey200};
  padding: 32px;

  button {
    min-width: 150px;
    width: fit-content;
    &:first-of-type {
      margin-right: 20px;
    }
  }
`;
