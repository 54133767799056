import React, { useState } from 'react';
import { Wrapper } from './styles';
import { MessagesList } from '../MessagesList';
import { MessageInput } from '../MessageInput';
import { useConversation } from '../../hooks/useConversation';
import { ActiveMassMessageChatHeader } from '../ActiveMassMessageChatHeader';
import { MassMessagePatientsList } from '../MassMessagePatientsList';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  deleteMassMessage,
  postMassMessagesChat,
} from 'applicaiton/store/reducers/Chats/ActionCreators';
import { MessageBriefInfo } from '../MessagesList/module';
import { ConfirmModalContentStyled } from '../ActiveChatMenu/styles';
import { ConfirmModal } from '../ConfirmModal';
import { SuccessModalContentStyled } from '../ActiveMassMessageMenu/styles';
import { SuccessModal } from '../SuccessModal';
import { useTranslation } from 'react-i18next';
import { useDocumentDownload } from 'common/hooks/useDownloadDocument';

export const ActiveMassMessageChat = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [messages, setMessages] = useState<MessageBriefInfo[]>([]);
  const [deletedMessageId, setDeletedMessageId] = useState('');
  const [isShowPatientsList, setIsShowPatientsList] = useState(false);
  const [deleteMessageModalIsOpen, setDeleteMessageModalIsOpen] =
    useState(false);
  const [deleteChatSuccessModalIsOpen, setDeleteChatSuccessModalIsOpen] =
    useState(false);

  const { massMessagesPatientsIds } = useAppSelector((state) => state.chats);
  const { downloadDocument } = useDocumentDownload();
  const { selectedChat } = useConversation();

  const handleSendMessage = (message: string) => {
    const patientsIds = massMessagesPatientsIds?.map((patient) => patient.id);

    const params = {
      patientsIds: patientsIds!,
      messageBody: message,
    };
    dispatch(postMassMessagesChat(params)).then((data) => {
      const newMessage = {
        id: data?.payload?.success?.id,
        text: message,
        date: new Date(),
        isOwnMessage: true,
      };

      setMessages((prev) => [...prev, newMessage]);
    });
  };

  const topic =
    selectedChat?.clinicProfessionalTopic?.name || t('chats.no_topic') || '';

  const handleConfirmDeleteMessage = (messageId: string) => {
    setDeleteMessageModalIsOpen(true);
    setDeletedMessageId(messageId);
  };

  const handleDeleteMessage = () => {
    dispatch(deleteMassMessage(deletedMessageId)).then(() => {
      setDeleteMessageModalIsOpen(false);
      setDeleteChatSuccessModalIsOpen(true);

      const indexOfObject = messages.findIndex(
        (object) => object.id === deletedMessageId,
      );

      messages!.splice(indexOfObject, 1);

      setMessages(messages);
    });
  };

  const handleCancelDelete = () => {
    setDeleteMessageModalIsOpen(false);
    setDeletedMessageId('');
  };

  const handleDownloadDocument = (messageId: string) => {
    downloadDocument(messageId);
  };

  return (
    <Wrapper>
      <ActiveMassMessageChatHeader
        showPatientsList={() => setIsShowPatientsList(true)}
        isShowPatients={isShowPatientsList}
        onBack={() => setIsShowPatientsList(false)}
      />
      {isShowPatientsList ? (
        <MassMessagePatientsList />
      ) : (
        <>
          <MessagesList
            topic={topic}
            messages={messages}
            onDeleteMessage={handleConfirmDeleteMessage}
            hideTopic
            onDownloadDocument={handleDownloadDocument}
          />
          <MessageInput onSendMessage={handleSendMessage} isDisabled={false} />
        </>
      )}
      <ConfirmModal
        isOpen={deleteMessageModalIsOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDeleteMessage}
      >
        <ConfirmModalContentStyled>
          {t('chats.confirm_delete_mass_message')}{' '}
        </ConfirmModalContentStyled>
      </ConfirmModal>
      <SuccessModal
        isOpen={deleteChatSuccessModalIsOpen}
        onClose={() => setDeleteChatSuccessModalIsOpen(false)}
      >
        <SuccessModalContentStyled>
          {t('chats.success_deleted_mass_message')}
        </SuccessModalContentStyled>
      </SuccessModal>
    </Wrapper>
  );
};
