import React, { FC, useEffect, useState } from 'react';
import {
  Wrapper,
  StyledSection,
  StepSection,
  StepItem,
  StepText,
  StepSectionWrapper,
  UserInfo,
  StyledDivider,
  Name,
  Image,
} from './styles';

import ProfileInfo from '../ProfileInfo';
import { useTranslation } from 'react-i18next';
import Assigning from '../Clinics';
import { SecondaryButton } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  deleteAdmin,
  getAdminById,
} from 'applicaiton/store/reducers/Admins/ActionCreators';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as PersonIcon } from 'applicaiton/assets/person.svg';
import DeleteModal from '../DeleteModal';
import { PathNames } from 'applicaiton/routes';

const LayoutComponent: FC = () => {
  const [activeForm, setActiveForm] = useState<number>(0);
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getAdminById(id!));
  }, []);

  const { currentUser } = useAppSelector((state) => state.admins);

  const getCurrentForm = () => {
    switch (activeForm) {
      case 0:
        return <ProfileInfo />;

      case 1:
        return <Assigning />;
    }
  };

  const deleteProfile = () => {
    dispatch(deleteAdmin(id!)).then(() => {
      navigate(PathNames.myUsers);
    });
  };

  return (
    <Wrapper>
      <UserInfo>
        <div>
          <Image>
            {currentUser?.photo ? (
              <img
                src={currentUser?.photo?.thumbnailUrl}
                alt={currentUser?.photo?.thumbnailUrl}
              />
            ) : (
              <PersonIcon />
            )}
          </Image>
          <Name>
            {currentUser?.firstName} {currentUser?.lastName}
          </Name>
        </div>
        <SecondaryButton onClick={() => setShowDeleteModal(true)}>
          {t('users.delete_profile')}
        </SecondaryButton>
      </UserInfo>
      <StyledDivider />
      <StyledSection>
        <StepSectionWrapper>
          <StepSection>
            <StepItem>
              <StepText
                onClick={() => setActiveForm(0)}
                isActive={activeForm === 0}
              >
                {t('users.profile_info')}
              </StepText>
            </StepItem>
            <StepItem>
              <StepText
                onClick={() => setActiveForm(1)}
                isActive={activeForm === 1}
              >
                {t('users.clinics')}
              </StepText>
            </StepItem>
          </StepSection>
        </StepSectionWrapper>
        {getCurrentForm()}
      </StyledSection>
      <DeleteModal
        isOpen={showDeleteModal}
        typeName={`${currentUser?.firstName} ${currentUser?.lastName}`}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={deleteProfile}
      />
    </Wrapper>
  );
};

export default LayoutComponent;
