import { Box, styled } from '@mui/system';
import Markdown from 'react-markdown';

export const ModalContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  overflow-y: auto;
  max-height: 80vh;
  height: 80vh;
`;

export const MarkdownStyled = styled(Markdown)`
  font-size: medium;
  color: ${({ theme }) => theme.palette.common.grey700};
  line-height: 140%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 140%;
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    margin-top: ${({ theme }) => theme.spacing(4.25)};
    @media only screen and (max-width: 600px) {
      margin-top: ${({ theme }) => theme.spacing(1.75)};
    }
  }

  h1 {
    font-size: xx-large;
  }

  h2 {
    font-size: x-large;
  }

  h3 {
    font-size: large;
  }

  h4 {
    font-size: medium;
  }

  h5 {
    font-size: small;
  }

  h6 {
    font-size: x-small;
  }

  p {
    text-indent: ${({ theme }) => theme.spacing(2)};

    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing(1)};
    }
  }

  strong {
    font-weight: bold;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    padding: ${({ theme }) => theme.spacing(0, 0, 0, 4)};

    &:not(:last-child) {
      margin: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;

    th,
    td {
      padding: ${({ theme }) => theme.spacing(1, 2)};
      border: 1px solid ${({ theme }) => theme.palette.common.grey300};
    }

    th {
      background-color: ${({ theme }) => theme.palette.common.grey300};
      font-weight: bold;
    }

    tr:nth-child(even) {
      background-color: ${({ theme }) => theme.palette.common.grey50};
    }

    thead,
    tfoot {
      font-weight: bold;
    }
  }
`;
