import styled from 'styled-components';
export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  border-radius: 12px;
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  border: 1px solid ${({ theme }) => theme.color.grey200};
`;

export const TableStyled = styled.table`
  width: 100%;

  border-radius: 12px;
  border-collapse: separate;

  > thead {
    tr {
      th {
        vertical-align: middle;
        text-align: start;
        padding: 12px 24px;
        color: ${({ theme }) => theme.color.grey600};
        font-size: 14px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: 0.07px;
        background: ${({ theme }) => theme.color.grey50};
        border-bottom: 1px solid ${({ theme }) => theme.color.grey200};

        &:first-child {
          width: 220px;
        }
        &:nth-child(2) {
          width: 540px;
        }
        &:nth-child(3) {
          width: auto;
        }
      }
    }
  }
  > tbody {
    > tr {
      &:last-child {
        > td {
          &:first-child {
            border-bottom-left-radius: 12px;
          }
          &:last-child {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
`;

export const FilerItems = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.white};
  gap: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grey200};
  padding: 20px;
`;

export const StyledTr = styled.tr`
  height: 72px;
  background: ${({ theme }) => theme.color.white};

  &:hover {
    background: ${({ theme }) => theme.color.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 16px 24px;
    color: ${({ theme }) => theme.color.grey700};
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;

    &:first-child {
      width: 220px;
    }

    &:nth-child(2) {
      width: 540px;
    }
    &:last-child {
      width: auto;
    }
  }
`;

export const ItemList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;

  > h2 {
    margin-left: 0;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.grey900};
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
  }
  > div {
    display: flex;
    padding: 6px 12px;
    align-items: center;
    border-radius: 100px;
    background: ${({ theme }) => theme.color.grey200};
    color: ${({ theme }) => theme.color.grey800};
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    max-width: 220px;

    > p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    > button {
      display: flex;
      border: none;
      background: none;
      margin: 0;
      padding: 0;
      > svg {
        width: 16px;
        height: 16px;
        margin-left: 4px;
        cursor: pointer;
        path {
          fill: ${({ theme }) => theme.color.grey600};
        }
      }
    }
  }
`;
