import React, { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import type { CreateNewPasswordDataT } from './types';
import { PrimaryButton } from 'common/components';

import { FormGroupStyled, ForgotPasswordWrapper, StyledForm } from './styles';
import Header from './components/Header';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { usersCreateNewPassword } from 'applicaiton/store/reducers/Users/ActionCreators';
import { useNavigate } from 'react-router-dom';
import { PathNames } from 'applicaiton/routes';
import {
  setAuthError,
  setIsCreateNewPassword,
} from 'applicaiton/store/reducers/Users/UsersSlice';
import BackButton from './components/BackButton';
import { authService } from 'common';
import CreatePassword from 'common/components/CreatePassword';
import { useCreateNewPasswordForm } from './hooks/useCreateNewPasswordForm';

const CreateNewPasswordPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const email = authService.getEmail();
  const verificationCode = authService.getEmailVerifiationCode();
  const { error, isCreateNewPassword } = useAppSelector((state) => state.users);
  const {
    register,
    errors,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    getValues,
  } = useCreateNewPasswordForm();

  useEffect(() => {
    if (error) {
      setError('password', { message: t('something_went_wrong') || '' });
    } else {
      clearErrors();
    }
  }, [error]);

  useEffect(() => {
    if (isCreateNewPassword) {
      navigate(PathNames.login);
    }
    return () => {
      dispatch(setIsCreateNewPassword(false));
      dispatch(setAuthError(false));
    };
  }, [isCreateNewPassword]);

  useEffect(() => {
    if (error) {
      dispatch(setAuthError(false));
    }
  }, [watch('password')]);

  const onSubmit = (data: CreateNewPasswordDataT) => {
    dispatch(
      usersCreateNewPassword({
        password: data.password,
        email: email!,
        code: verificationCode!,
      }),
    );
  };

  return (
    <>
      <BackButton />
      <ForgotPasswordWrapper>
        <Header
          title={t('auth.create_new_password.title')}
          subtitle={t('auth.create_new_password.sub_title') || ''}
        />

        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <FormGroupStyled>
            <CreatePassword
              watch={watch}
              getValues={getValues}
              register={register}
              errors={errors.password?.message}
            />
          </FormGroupStyled>

          <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            type="submit"
            disabled={!!Object.keys(errors).length}
          >
            {t('auth.create_new_password.set_password')}
          </PrimaryButton>
        </StyledForm>
      </ForgotPasswordWrapper>
    </>
  );
};

export default CreateNewPasswordPage;
