import React, { useRef } from 'react';
import {
  EmptyResults,
  MainPatientInfo,
  ResultsItem,
  SearchResults,
  PatientInfo,
} from './styles';
import { ReactComponent as SearchIcon } from 'applicaiton/assets/search.svg';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import useOnClickOutside from 'common/hooks/useClickOutside';
import {
  setCurrentSearchItem,
  setPatientsFamilyMembersSearch,
} from 'applicaiton/store/reducers/Patients/PatientsSlice';
import { fetchPatientAppointmentsBrief } from 'applicaiton/store/reducers/Appointments/ActionCreators';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { PatientSearchResponseDto } from '@docbay/schemas';

const PatientSearchList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const refSearchResults = useRef(null);
  const { patientsFamilyMembersSearch } = useAppSelector(
    (state) => state.patients,
  );

  useOnClickOutside(refSearchResults, () => {
    dispatch(setPatientsFamilyMembersSearch(null));
  });

  const handlePatientClick = async (item: PatientSearchResponseDto) => {
    dispatch(setCurrentSearchItem(item));
    dispatch(setPatientsFamilyMembersSearch(null));
    await dispatch(
      fetchPatientAppointmentsBrief({
        patientId: item.isPatient ? item.id! : item.patient?.id!,
        startDateTime: dayjs().toISOString(),
      }),
    );
  };

  return (
    <>
      {patientsFamilyMembersSearch && patientsFamilyMembersSearch.length && (
        <SearchResults ref={refSearchResults}>
          {patientsFamilyMembersSearch!.map((item) => {
            return (
              <ResultsItem
                key={item.id}
                onClick={() => handlePatientClick(item)}
              >
                <SearchIcon />
                <div>
                  <MainPatientInfo>
                    {item.firstName} {item.lastName}
                  </MainPatientInfo>
                  <PatientInfo>{item.phone}</PatientInfo>
                  {!!item?.familyMembers?.length &&
                    item?.familyMembers?.map((member) => (
                      <PatientInfo
                        key={member.id}
                      >{`${member.relationship} ${member.firstName} ${member.lastName}`}</PatientInfo>
                    ))}
                </div>
              </ResultsItem>
            );
          })}
        </SearchResults>
      )}
      {patientsFamilyMembersSearch && !patientsFamilyMembersSearch?.length && (
        <EmptyResults ref={refSearchResults}>
          <PickIcon />
          <p>{t('patient.patient_not_found')}</p>
        </EmptyResults>
      )}
    </>
  );
};

export default PatientSearchList;
