import React, { FC, memo, useEffect, useRef } from 'react';
import { DayView } from '@devexpress/dx-react-scheduler-material-ui';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { useTranslation } from 'react-i18next';

import { UserPermissions } from 'applicaiton/constants/userPermissions';
import { setSlotsDetails } from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { ReserveSlotsMode } from 'applicaiton/constants/reserveSlots';

import { useAppDispatch } from 'common/hooks/redux';
import { dateWithoutTimezone } from 'common/helpers/dateWithoutTimezone';
import { userHasPermission } from 'common/helpers/userHasPermission';
import { useUserTimeZone } from 'common/hooks/useUserTimeZone';

import { TimeTableCellProps, DailyViewProps } from './modules';
import {
  StyledDayViewDayScaleCell,
  TimeScaleLabelStyled,
  TimeTableCellStyled,
} from './styles';

dayjs.extend(isBetweenPlugin);

const TimeScaleLabelComponent = (props: any) => {
  const minute = dayjs(props.time).minute();
  const time = dayjs(props.time).format('HH:mm');

  return (
    <TimeScaleLabelStyled isHour={minute === 0}>
      {minute === 0 ? time : ''}
    </TimeScaleLabelStyled>
  );
};

const DayViewDayScaleCell = (props: any) => {
  return <StyledDayViewDayScaleCell {...props} />;
};

const DayViewTimeTableCell = memo(
  ({
    startDate,
    endDate,
    groupingInfo,
    workingHours,
    absences,
    scrollEvent,
  }: TimeTableCellProps) => {
    const { t, i18n } = useTranslation();
    const { tz } = useUserTimeZone();
    const currentProfessional = groupingInfo?.length ? groupingInfo[0] : null;
    const ref = useRef<HTMLTableCellElement | null>(null);
    const isCanCreateAbsence = userHasPermission(UserPermissions.createAbsence);
    const isCanCreateAppointment = userHasPermission(
      UserPermissions.createAppointment,
    );
    const isCanCreateEvents = isCanCreateAbsence || isCanCreateAppointment;

    const dispatch = useAppDispatch();
    const currentStartDate = dayjs(startDate);
    const currentEndDate = dayjs(endDate);
    const minute = dayjs(endDate).minute();
    const isWorkingTime = workingHours
      .filter((item) => currentProfessional?.id === item.priorityId)
      .some((item) => {
        const isStartDate = currentStartDate.isSame(dayjs(item.startDate));
        const isEndDate = currentEndDate.isSame(dayjs(item.endDate));

        return (
          isStartDate ||
          isEndDate ||
          currentEndDate.isBetween(dayjs(item.startDate), dayjs(item.endDate))
        );
      });

    const absenceByPriorityId = absences.filter(
      (item) => groupingInfo && item.priorityId === groupingInfo[0].id,
    );
    const absence = absenceByPriorityId.find(
      (item) =>
        dayjs(item.startDate).isSame(startDate) ||
        dayjs(item.endDate).isSame(endDate) ||
        currentEndDate.isBetween(dayjs(item.startDate), dayjs(item.endDate)),
    );

    const isEarliestWorkingHour =
      scrollEvent &&
      dayjs(scrollEvent.startDate).toString() === dayjs(startDate).toString();

    useEffect(() => {
      if (ref.current) {
        const wrapper = document.querySelector('.MainLayout-container')!;
        wrapper.scrollTo({
          top: ref.current.offsetTop,
          behavior: 'smooth',
        });
      }
    }, [ref.current]);

    const reserveSlot = () => {
      const isValidDate = dayjs
        .tz(dateWithoutTimezone(startDate as Date), tz)
        .isAfter(dayjs.tz(new Date(), tz));

      if (absence) {
        return dispatch(
          setSlotsDetails({
            professionalId: String(currentProfessional?.id),
            absenceId: absence.id,
            mode: ReserveSlotsMode.EditAbsence,
          }),
        );
      }

      const newTimeZoneHours = new Date().setHours(dayjs().tz().get('hour'));
      const currentTimezoneDate = new Date(newTimeZoneHours).setDate(
        dayjs().tz().get('date'),
      );

      return dispatch(
        setSlotsDetails({
          professionalId: String(currentProfessional?.id),
          mode: isCanCreateAppointment
            ? ReserveSlotsMode.CreateAppointment
            : ReserveSlotsMode.CreateAbsence,
          startDate: isValidDate ? startDate : new Date(currentTimezoneDate),
        }),
      );
    };

    useEffect(() => {
      if (absence) {
        const elements = document.querySelectorAll(
          `[datatype="${absence.id}"]`,
        );

        if (elements.length) {
          elements[0].innerHTML = `<span>${t('appointment.absence')}`;
          if (elements.length && elements[1] && absence.reason) {
            elements[1].innerHTML = `<p>${absence.reason}</p>`;
          }
        }
      }
    }, [i18n.language]);

    return (
      <TimeTableCellStyled
        datatype={absence?.id ? absence.id : ''}
        ref={isEarliestWorkingHour ? ref : null}
        isHour={minute === 0 || minute === 30}
        isWorkingTime={isWorkingTime}
        isAbsence={!!absence}
        id={dayjs(startDate).toString()}
        onClick={() => {
          isCanCreateEvents && reserveSlot();
        }}
      ></TimeTableCellStyled>
    );
  },
);

DayViewTimeTableCell.displayName = 'DayViewTimeTableCell';

const DailyView: FC<DailyViewProps> = ({
  workingHours,
  intervalCount = 1,
  dayHour,
  absences,
  scrollEvent,
}) => {
  return (
    <DayView
      startDayHour={dayHour.start}
      endDayHour={dayHour.end}
      cellDuration={10}
      timeTableCellComponent={(props) => (
        <DayViewTimeTableCell
          workingHours={workingHours}
          absences={absences}
          scrollEvent={scrollEvent}
          {...props}
        />
      )}
      dayScaleCellComponent={DayViewDayScaleCell}
      timeScaleLabelComponent={TimeScaleLabelComponent}
      intervalCount={intervalCount}
    />
  );
};

export default memo(DailyView);
