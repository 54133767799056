import React, { FC, useEffect, useState } from 'react';
import { TimeSlot } from '@docbay/schemas';
import dayjs from 'dayjs';

import {
  setEditedOpenAgenda,
  setErrorOpenAgenda,
} from 'applicaiton/store/reducers/Agenda/AgendaSlice';

import { TimeSlots } from 'common/types/clinics';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import HoursRange from 'common/components/HoursRange';

import { HoursSection } from './styles';

const OpenAgendaHours: FC = () => {
  const dispatch = useAppDispatch();

  const [openingHours, setOpeningHours] = useState<TimeSlot[]>([]);

  const { openAgenda, editedOpenAgenda } = useAppSelector(
    (state) => state.agenda,
  );
  const { startDate } = useAppSelector((state) => state.appointmentsSlice);

  const editeOpenAgenda = (newOpeningHours: TimeSlots[]) => {
    const newOpenAgenda = {
      id: openAgenda?.id!,
      day: {
        date: dayjs(startDate).format('YYYY-MM-DD'),
        slots: newOpeningHours,
      },
    };

    dispatch(setEditedOpenAgenda(newOpenAgenda));
  };

  useEffect(() => {
    setOpeningHours(
      openAgenda ? openAgenda.day.slots : [{ startTime: '', endTime: '' }],
    );
  }, [openAgenda]);

  useEffect(() => {
    const errorCollection = editedOpenAgenda?.day?.slots?.map((slot, i) => {
      if (i === 0) {
        if (slot.startTime === '' || slot.endTime === '') {
          return true;
        } else {
          return (slot.startTime || '') >= slot.endTime;
        }
      } else {
        if (slot.startTime === '' || slot.endTime === '') {
          return true;
        }
        return (
          (slot.startTime || '') > slot.endTime ||
          (slot.startTime || '') < editedOpenAgenda?.day?.slots[i - 1].endTime
        );
      }
    });

    const disabled = errorCollection?.includes(true);

    dispatch(setErrorOpenAgenda(disabled));
  }, [editedOpenAgenda?.day?.slots]);

  const handleAddSlot = () => {
    const newSlot = {
      startTime: '',
      endTime: '',
    };

    setOpeningHours((prev) => {
      const newOpeningHours = [...prev, newSlot];
      editeOpenAgenda(newOpeningHours);
      return newOpeningHours;
    });
  };

  const handleEditSlot = (index: number, value: TimeSlots) => {
    const newOpeningHours = [...openingHours];

    newOpeningHours.splice(index, 1, { ...newOpeningHours[index], ...value });

    setOpeningHours(newOpeningHours);
    editeOpenAgenda(newOpeningHours);
  };

  const handleRemoveSlot = (index: number) => {
    const newOpeningHours = [...(openingHours || [])];
    newOpeningHours.splice(index, 1);

    setOpeningHours(newOpeningHours);
    editeOpenAgenda(newOpeningHours);
  };

  return (
    <HoursSection>
      <HoursRange
        defaultDate={dayjs(startDate).format('YYYY-MM-DD')}
        slots={openingHours}
        handleAddSlot={handleAddSlot}
        handleEditSlot={handleEditSlot}
        handleRemoveSlot={handleRemoveSlot}
      />
    </HoursSection>
  );
};

export default OpenAgendaHours;
