import React, { FC, useState } from 'react';
import {
  Wrapper,
  StyledSection,
  StepSection,
  StepItem,
  Step,
  StepText,
  StepSectionWrapper,
} from './styles';

import ProfileInfo from '../ProfileInfo';
import { ReactComponent as CheckIcon } from 'applicaiton/assets/checked.svg';
import { useTranslation } from 'react-i18next';
import Assigning from '../Clinics';

const LayoutComponent: FC = () => {
  const [activeForm, setActiveForm] = useState<number>(0);
  const { t } = useTranslation();

  const handleNextGeneralBtn = () => {
    setActiveForm((prev) => prev + 1);
  };

  const handleBackGeneralBtn = () => {
    setActiveForm((prev) => prev - 1);
  };

  const getCurrentForm = () => {
    switch (activeForm) {
      case 0:
        return <ProfileInfo onNextBtnClick={handleNextGeneralBtn} />;

      case 1:
        return <Assigning onBackBtnClick={handleBackGeneralBtn} />;
    }
  };

  return (
    <Wrapper>
      <StyledSection>
        <StepSectionWrapper>
          <StepSection>
            <StepItem>
              {activeForm < 1 ? (
                <Step isActive={activeForm === 0}>1</Step>
              ) : (
                <Step isActive={true}>
                  <CheckIcon />
                </Step>
              )}
              <StepText isActive={activeForm === 0}>
                {t('users.profile_info')}
              </StepText>
            </StepItem>
            <StepItem>
              {activeForm < 2 ? (
                <Step isActive={activeForm === 1}>2</Step>
              ) : (
                <Step isActive={true}>
                  <CheckIcon />
                </Step>
              )}
              <StepText isActive={activeForm === 1}>
                {t('users.clinics')}
              </StepText>
            </StepItem>
          </StepSection>
        </StepSectionWrapper>
        {getCurrentForm()}
      </StyledSection>
    </Wrapper>
  );
};

export default LayoutComponent;
