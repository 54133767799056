import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.warning2};
  background: ${({ theme }) => theme.color.warning3};

  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;

    path {
      fill: ${({ theme }) => theme.color.warning2};
    }
  }

  p {
    color: ${({ theme }) => theme.color.warning};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.4px;
  }
`;
