import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UserRoles } from 'applicaiton/constants/userRoles';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Header } from 'common/components';

import { OnlineConfigure, OnlineHours } from './components';
import { Wrapper, SettingsStyled, Divider } from './styles';
import { fetchOnlineHours } from 'applicaiton/store/reducers/OnlineHours/ActionCreators';

const SubscriptionSettings: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userRole = getUserRole();

  const { currentClinicOwner } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );
  const isClinicOwnerRole = userRole?.includes(UserRoles.clinicOwner);
  const userId = useMemo(() => {
    return isClinicOwnerRole
      ? currentClinicOwner?.professional?.id
      : getUserId();
  }, [currentClinicOwner]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchOnlineHours(userId));
    }
  }, [userId]);

  return (
    <Wrapper>
      <Header />
      <h1>{t('subscription_settings.title')}</h1>
      <SettingsStyled>
        <OnlineConfigure />
        <Divider />
        <OnlineHours />
      </SettingsStyled>
    </Wrapper>
  );
};

export default SubscriptionSettings;
