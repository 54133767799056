import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styled from 'styled-components';

interface Props {
  selected: boolean;
}
export const LanguageWrapper = styled(Box)`
  display: flex;
  color: rgba(255, 255, 255, 0.7);
  gap: 4px;
  align-items: center;
  height: 60px;
`;

export const LanguageSwitcherStyled = styled(Typography)<Props>`
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  line-height: 22.4px;

  color: ${({ theme, selected }) =>
    selected ? theme.color.white : theme.color.grey400};
  padding: 4px;

  :hover {
    border-radius: 4px;
    background: ${({ theme }) => theme.color.navigatorHover};
  }
`;

export const DividerStyled = styled(Divider)`
  width: 1px;
  height: 16px;
  background: ${({ theme }) => theme.color.navigator};
`;
