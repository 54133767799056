import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader } from 'common/components';
import { Wrapper, Title } from './styles';
import SubscriptionItem from '../SubscriptionItem';
import EmptySubscriptionsState from '../EmptySubscriptionsState';
import { getPatientsSubscriptions } from 'applicaiton/store/reducers/Patients/ActionCreators';

const SubscriptionsTable: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { patientSubscriptions, isLoading } = useAppSelector(
    (state) => state.patients,
  );

  useEffect(() => {
    dispatch(getPatientsSubscriptions(String(id)));
  }, [id]);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Title>
        <h1>{t('professional_profile.subscriptions.subscription_title')}</h1>
      </Title>

      {patientSubscriptions?.map((subscription, ind) => (
        <SubscriptionItem
          key={subscription.id}
          data={subscription}
          index={ind + 1}
        />
      ))}
      {!patientSubscriptions?.length && <EmptySubscriptionsState />}
    </Wrapper>
  );
};

export default SubscriptionsTable;
