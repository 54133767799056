import { styled } from '@mui/material/styles';
import { ColorsPallet } from 'applicaiton/theme';
import styledComponent, { createGlobalStyle } from 'styled-components';

interface StepIconRootProps {
  ownerState: { completed?: boolean; active?: boolean };
  theme?: { palette: { common: ColorsPallet } };
}

interface StepperStyledProps {
  theme: { color: ColorsPallet };
}

export const StepIconRoot = styled('div')<StepIconRootProps>(
  ({ ownerState, theme }) => {
    return {
      backgroundColor: 'transparent',
      zIndex: 1,
      width: 32,
      height: 32,
      color: theme.palette.common.grey300,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '16px',
      border: `1px solid ${theme.palette.common.grey300}`,
      ...(ownerState.active && {
        backgroundColor: `${theme.palette.common.blue300}`,
        color: `${theme.palette.common.grey900}`,
        fontWeight: 700,
        border: 'none',
      }),
    };
  },
);

export const StepperStyled = createGlobalStyle<StepperStyledProps>`
  .MuiStepLabel-root{
    min-width: 240px;
  }
  .MuiStepLabel-iconContainer {
    padding-right: 10px;  
    svg {
      width: 32px;
      height: 32px;
    }
  }
  .MuiStepLabel-labelContainer {
    & > span {
      font-weight: 500;
      font-size: 18px;
      color: ${({ theme }) => theme.color.grey300};
    }
    .Mui-completed {
      color: ${({ theme }) => theme.color.white} !important;
    }
    .Mui-active {
      font-weight: 700 !important;
      color: ${({ theme }) => theme.color.white} !important;
    }
  }
  .MuiStepConnector-root > span {
    min-height: 12px;
    margin-left: 3px;
    border-color: ${({ theme }) => theme.color.grey300};
  }
`;

export const DivStyled = styledComponent.div`
  width: fit-content;
`;
