import { createSlice } from '@reduxjs/toolkit';
import { store } from 'applicaiton/store';

export const initialState = false;

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setIsLoading: {
      reducer: (state, action) => action.payload,
      // @ts-ignore
      prepare: (isLoading: boolean) => ({ payload: isLoading }),
    },
  },
});

export const { setIsLoading } = loadingSlice.actions;
export const isLoadingSelector = () => store.getState().loading;
