import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageList, MessageInput } from './components';
import { Wrapper } from './styles';
import { useTeleconsultationChat } from 'features/feature-teleconsultation/hooks/useTeleconsultationChat';
import Modal from '../Modal';
import { ChatProps } from './types';

const Chat: FC<ChatProps> = ({
  isChatOpened,
  handleAsideModal,
  isChatTeleconsultation,
  conversationSid,
}) => {
  const { t } = useTranslation();
  const { messages, sendMessage } = useTeleconsultationChat();

  const messagesList = useMemo(() => {
    return messages.map((item) => {
      const attributes = item.attributes as any;
      return {
        ...item,
        body: item.body,
        sid: item.sid,
        author: item.author,
        ...(attributes.document && { document: attributes.documentObject }),
      };
    });
  }, [messages]);

  return (
    <>
      {isChatOpened && (
        <Modal
          title={t('teleconsultation.chat') || ''}
          onClose={handleAsideModal}
        >
          <Wrapper>
            <MessageList messages={messagesList} />
            <MessageInput
              onSendMessage={sendMessage}
              isChatTeleconsultation={isChatTeleconsultation}
              conversationSid={conversationSid}
            />
          </Wrapper>
        </Modal>
      )}
    </>
  );
};

export default Chat;
