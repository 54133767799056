import React, { FC, useMemo } from 'react';
import { PackageInfoStyled, Wrapper } from './styles';
import Counter from '../Counter';
import Features from '../Features';
import {
  BasicPackIcon,
  PrivateDoctorPackIcon,
  FullPackIcon,
} from 'applicaiton/assets';
import { PackFeatures } from 'features/feature-clinic-registration/constants';
import { useTranslation } from 'react-i18next';
import { PackageProps } from './models';

const Package: FC<PackageProps> = ({
  quantity,
  handleSetQuantity,
  id,
  title,
  isActive,
  onClick,
  isPrivateDoctor,
  packageType,
}) => {
  const { t, i18n } = useTranslation();
  const description = useMemo(() => {
    return isPrivateDoctor
      ? t('packs_description.private_doctor')
      : t('packs_description.other_packs');
  }, [i18n.language]);

  const getCurrentIcon = () => {
    switch (packageType) {
      case PackFeatures.privateDoctorPack:
        return <PrivateDoctorPackIcon />;
      case PackFeatures.basicPack:
        return <BasicPackIcon />;
      default:
        return <FullPackIcon />;
    }
  };

  return (
    <Wrapper onClick={() => onClick(id)} isActive={isActive}>
      <div>
        <PackageInfoStyled>
          {getCurrentIcon()}
          <div>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        </PackageInfoStyled>
      </div>
      <div>
        {!isPrivateDoctor && (
          <Counter
            value={quantity}
            handleSetQuantity={handleSetQuantity}
            id={id}
            isActivePack={isActive}
          />
        )}
        <Features packageType={packageType} />
      </div>
    </Wrapper>
  );
};

export default Package;
