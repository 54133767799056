import styled from 'styled-components';

export const Wrapper = styled.article`
  display: grid;
  width: 100%;
  gap: 24px;
  grid-template-rows: min-content 1fr;

  > div:last-child {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  > h1 {
    color: ${({ theme }) => theme.color.grey800};
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.1px;
  }

  > div {
    display: flex;

    > button {
      height: 100%;
      display: flex;
      border: none;
      background: none;
      padding: 0;
      color: ${({ theme }) => theme.color.grey600};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22.4px;
      letter-spacing: 0.08px;
      cursor: pointer;

      &:first-child {
        margin-right: 16px;
      }

      > svg {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        cursor: pointer;

        g {
          path {
            fill: ${({ theme }) => theme.color.grey600};
          }
        }
      }
    }
  }
`;

export const FormStyled = styled.article`
  display: grid;
  width: 100%;
  gap: 24px;
  grid-template-rows: min-content;
`;

export const DividerStyled = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.color.grey200};
`;
