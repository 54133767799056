import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as SuccesslIcon } from 'applicaiton/assets/success-icon.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { useAppSelector } from 'common/hooks/redux';

interface Props {
  onClose: () => void;
}

const SuccessAppointmentEditing: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  const { currentPatient } = useAppSelector((state) => state.appointmentsSlice);

  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccesslIcon />
          <p>{`${t('appointment.successEdited.descriptionText1')}
           ${currentPatient?.firstName} ${currentPatient?.lastName}
           ${t('appointment.successEdited.descriptionText2')}`}</p>
          <SecondaryButton onClick={onClose}>
            {t('appointment_types.modal_success.ok')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessAppointmentEditing;
