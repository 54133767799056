import React, { FC } from 'react';
import { StepIconProps } from '@mui/material/StepIcon';
import { Box } from '@mui/system';
import Chekced from 'features/feature-stepper-aside/assets/checked.svg';
import { StepIconRoot } from './styles';

const StepIcon: FC<StepIconProps> = (props) => {
  const { active, completed, className, icon } = props;

  return (
    <Box sx={{ height: 32, width: 32 }}>
      {completed ? (
        <img src={Chekced} alt="completed" />
      ) : (
        <StepIconRoot ownerState={{ completed, active }} className={className}>
          {icon}
        </StepIconRoot>
      )}
    </Box>
  );
};

export default StepIcon;
