import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  CountryDropdown,
  Input,
  Loader,
  MapBoxAutocompleteInput,
} from 'common/components';
import {
  Wrapper,
  AccessibilityStyled,
  DividerStyled,
  FormStyled,
  AddressSection,
} from './styles';
import { useAppSelector, useAppDispatch } from 'common/hooks/redux';
import { Context } from 'common/types/mapBox';
import ButtonSection from '../ButtonSection';
import {
  createClinicProfile,
  saveClinicProfile,
} from 'applicaiton/store/reducers/Clinics/ActionCreators';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { Address } from 'common/types/clinics';
import { PathNames } from 'applicaiton/routes';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import {
  ClinicAddressDto,
  ClinicAddressUpdateDto,
  ClinicResponseDto,
} from '@docbay/schemas';
import {
  useAddressSchema,
  useAddressSchemaActive,
} from 'features/feature-edit-profile-settings/helpers/validation';
import { ProfileAddressProps } from './models';
import { setShowSuccessModal } from 'applicaiton/store/reducers/Clinics/ClinicProfileSlice';
import { SupportedCountriesDto } from 'common/types/countries';
import { getUserCountryIso } from 'applicaiton/sessionStorage/auth';

interface FormData {
  address: string;
  streetNumber: string;
  room: string;
  floor: string;
  zipCode: string;
  supportedCountry: SupportedCountriesDto;
}

const ProfileAddress: FC<ProfileAddressProps> = ({ handleSetNextPage }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const path = useLocation();
  const isCreatePage = path.pathname.endsWith('create');
  const { t } = useTranslation();
  const { isLoading, clinicProfileData } = useAppSelector(
    (state) => state.clinicProfile,
  );
  const [location, setLocation] = useState<Address | null>({
    country: clinicProfileData?.address?.country || '',
    city: clinicProfileData?.address?.city || '',
    streetName: clinicProfileData?.address?.streetName || '',
  });
  const [accessibilityOptions, setAccessibilityOptions] = useState({
    elevator:
      clinicProfileData?.address?.accessibilityOptions?.elevator || false,
    handicapAccess:
      clinicProfileData?.address?.accessibilityOptions?.handicapAccess || false,
    carPark: clinicProfileData?.address?.accessibilityOptions?.carPark || false,
  });
  const [hideAddress, setHideAddress] = useState(
    clinicProfileData?.address?.hideAddress || false,
  );
  const isClinicActive = clinicProfileData?.status === 'Active';

  const formDefaultValues = {
    streetNumber: clinicProfileData?.address?.streetNumber || '',
    room: clinicProfileData?.address?.room || '',
    floor: clinicProfileData?.address?.floor || '',
    zipCode: clinicProfileData?.address?.zipCode || '',
    supportedCountry: {
      id: clinicProfileData?.address?.supportedCountry?.id || '',
      name: clinicProfileData?.address?.supportedCountry?.name || '',
      code:
        clinicProfileData?.address?.supportedCountry?.code ||
        getUserCountryIso() ||
        '',
    },
  };

  const addressSchemaActive = useAddressSchemaActive();
  const addressSchema = useAddressSchema();

  const {
    register,
    watch,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(isClinicActive ? addressSchemaActive : addressSchema),
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    setValue(
      'address',
      clinicProfileData?.address?.country ||
        clinicProfileData?.address?.streetAddress ||
        clinicProfileData?.address?.city ||
        '',
    );
  }, [clinicProfileData]);

  const handleChangeAccessibility = (id: string, checked: boolean) => {
    setAccessibilityOptions((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleChangeHideAddress = (val: boolean) => {
    setHideAddress(val);
  };

  const handleSetLocation = (location: Context) => {
    const zipCode = location.postcode.name;
    const streetNumber = location.address?.address_number;
    const streetName = (
      location?.address?.street_name || location?.street?.name
    )
      ?.split(' ')
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(' ');

    if (zipCode) {
      setValue('zipCode', zipCode, {
        shouldValidate: !!errors.zipCode?.message,
      });
    }
    if (streetNumber) {
      setValue('streetNumber', streetNumber, {
        shouldValidate: !!errors.streetNumber?.message,
      });
    }
    setValue('address', streetName, {
      shouldValidate: !!errors.address?.message,
    });
    setLocation({
      country: location.country?.name || '',
      city: location.place?.name || '',
      streetName: streetName || '',
    });
  };

  const handleSave = async () => {
    const { streetNumber, room, floor, zipCode, address, supportedCountry } =
      getValues();
    const formData = {
      ...(streetNumber ? { streetNumber } : {}),
      ...(room ? { room } : {}),
      ...(floor ? { floor } : {}),
      ...(zipCode ? { zipCode } : {}),
    };
    const isLocationChanged = compareObjectsChanges(location, {
      ...(!clinicProfileData?.address
        ? { country: '', city: '', streetAddress: '' }
        : clinicProfileData?.address),
    });
    const isAccessibilityOptionsChanged = compareObjectsChanges(
      accessibilityOptions,
      {
        ...(!clinicProfileData?.address?.accessibilityOptions
          ? {
              elevator: false,
              handicapAccess: false,
              carPark: false,
            }
          : clinicProfileData?.address?.accessibilityOptions),
      },
    );
    const isFormDataChanged = compareObjectsChanges(
      { streetNumber, room, floor, zipCode },
      {
        ...(!clinicProfileData?.address
          ? { streetNumber: '', room: '', floor: '', zipCode: '' }
          : clinicProfileData?.address),
      },
    );
    const canMoveNext =
      isCreatePage && !!address && !!zipCode && !!streetNumber;

    if (isCreatePage && !clinicProfileData) {
      const hasChanges =
        isFormDataChanged || isLocationChanged || isAccessibilityOptionsChanged;

      const response = await dispatch(
        createClinicProfile({
          name: '',
          ...(hasChanges
            ? ({
                address: {
                  ...(isFormDataChanged ? formData : {}),
                  ...(isLocationChanged ? location : {}),
                  ...(isAccessibilityOptionsChanged
                    ? { accessibilityOptions }
                    : {}),
                  ...(supportedCountry.id
                    ? { supportedCountryId: supportedCountry.id }
                    : {}),
                  hideAddress,
                },
              } as ClinicAddressUpdateDto)
            : {}),
        }),
      );
      if (response.meta.requestStatus === 'fulfilled' && canMoveNext) {
        handleSetNextPage();
      }
    } else {
      const response = await dispatch(
        saveClinicProfile({
          id: clinicProfileData?.id!,
          data: {
            address: {
              ...(isFormDataChanged
                ? { streetNumber, room, floor, zipCode }
                : {}),
              ...(isLocationChanged ? location : {}),
              ...(isAccessibilityOptionsChanged
                ? { accessibilityOptions }
                : {}),
              ...(supportedCountry.id
                ? { supportedCountryId: supportedCountry.id }
                : {}),
              hideAddress,
            } as ClinicAddressDto,
          },
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        const currentClinic = response.payload as ClinicResponseDto;
        const showSuccessActivated =
          currentClinic.status === 'Active' &&
          !isCreatePage &&
          clinicProfileData?.status !== 'Active';
        if (showSuccessActivated) {
          dispatch(setShowSuccessModal(true));
        }
        if (canMoveNext) {
          handleSetNextPage();
        }
      }
    }
  };

  const handleCancel = () => {
    navigate(PathNames.myClinics);
  };

  const isStateChanged = useMemo(() => {
    const { streetNumber, room, floor, zipCode } = getValues();
    if (isCreatePage && !clinicProfileData) {
      const locationChanged =
        location && Object.values(location).some((item) => !!item);

      const accessibilityOptionsChanged = Object.values(
        accessibilityOptions,
      ).some((item) => !!item);

      return (
        streetNumber ||
        room ||
        floor ||
        zipCode ||
        accessibilityOptionsChanged ||
        locationChanged
      );
    }
    const defaultData = {
      address: {
        streetNumber: clinicProfileData?.address?.streetNumber || '',
        room: clinicProfileData?.address?.room || '',
        floor: clinicProfileData?.address?.floor || '',
        zipCode: clinicProfileData?.address?.zipCode || '',
        country: clinicProfileData?.address?.country || '',
        city: clinicProfileData?.address?.city || '',
        streetName: clinicProfileData?.address?.streetName || '',
        accessibilityOptions: {
          elevator:
            clinicProfileData?.address?.accessibilityOptions?.elevator || false,
          handicapAccess:
            clinicProfileData?.address?.accessibilityOptions?.handicapAccess ||
            false,
          carPark:
            clinicProfileData?.address?.accessibilityOptions?.carPark || false,
        },
        hideAddress: clinicProfileData?.address?.hideAddress || false,
      },
    };

    const hasChanges = compareObjectsChanges(
      {
        address: {
          streetNumber,
          room,
          floor,
          zipCode,
          ...location,
          accessibilityOptions,
          hideAddress,
        },
      },
      defaultData,
    );

    return hasChanges;
  }, [
    watch('streetNumber'),
    watch('floor'),
    watch('zipCode'),
    watch('room'),
    location,
    accessibilityOptions,
    clinicProfileData?.address,
    isCreatePage,
    hideAddress,
  ]);

  useEffect(() => {
    dispatch(setHasUnsavedChanges(isStateChanged));
  }, [isStateChanged]);

  const defaultLocation = useMemo(() => {
    const zipCode = getValues('zipCode');
    const address = [
      location?.streetName || '',
      zipCode || '',
      location?.city || '',
      location?.country || '',
    ]
      .filter((item) => !!item)
      .join(' ');

    return address;
  }, [location, watch('zipCode'), watch('streetNumber')]);

  const handleChangeCountry = (country: SupportedCountriesDto) => {
    setValue('supportedCountry', country, {
      shouldValidate: !!errors.supportedCountry,
    });
    setValue('address', '');
    setValue('zipCode', '');
    setValue('streetNumber', '');
    setLocation({
      country: '',
      city: '',
      streetName: '',
    });
  };

  const isButtonDisabled = isCreatePage ? false : !isStateChanged;
  const showNextButton =
    isCreatePage &&
    !!watch('address') &&
    !!watch('zipCode') &&
    !!watch('streetNumber');

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <FormStyled>
        <AddressSection>
          <CountryDropdown
            label={t('country_of_practice') || ''}
            placeholder={t('select_country_of_practice')}
            country={watch('supportedCountry')}
            onChange={handleChangeCountry}
          />
          <MapBoxAutocompleteInput
            id={'streetAddress'}
            setLocation={handleSetLocation}
            defaultValue={defaultLocation}
            label={
              t('clinicsConfiguration.profile_address.facility_address') || ''
            }
            showRequiredIcon={!clinicProfileData?.address?.zipCode}
            countryIso={watch('supportedCountry').code}
            errorMessage={errors.address?.message}
          />
          <Checkbox
            id={'hiddenAddress'}
            checked={hideAddress}
            onChange={(checked) => handleChangeHideAddress(checked)}
            label={t('clinicsConfiguration.profile_address.hide_address') || ''}
          />
        </AddressSection>

        <section>
          <Input
            id={'streetNumber'}
            type="text"
            register={register}
            placeholder=""
            label={
              t('clinicsConfiguration.profile_address.street_number') || ''
            }
            errorMessage={errors.streetNumber?.message}
            showRequiredIcon={!clinicProfileData?.address?.streetNumber}
          />
          <Input
            id={'room'}
            type="text"
            register={register}
            placeholder=""
            label={t('clinicsConfiguration.profile_address.room') || ''}
            errorMessage={errors.room?.message}
          />
          <Input
            id={'floor'}
            type="text"
            register={register}
            placeholder=""
            label={t('clinicsConfiguration.profile_address.floor') || ''}
            errorMessage={errors.floor?.message}
          />
          <Input
            id={'zipCode'}
            type="text"
            register={register}
            placeholder=""
            label={t('clinicsConfiguration.profile_address.zip_code') || ''}
            errorMessage={errors.zipCode?.message}
            showRequiredIcon={!clinicProfileData?.address?.zipCode}
          />
        </section>
        <AccessibilityStyled>
          <p>
            {t(
              'clinicsConfiguration.profile_address.do_you_have_accessibility',
            ) || ''}
          </p>
          <Checkbox
            id={'elevator'}
            checked={accessibilityOptions.elevator}
            onChange={(checked) =>
              handleChangeAccessibility('elevator', checked)
            }
            label={t('clinicsConfiguration.profile_address.elevator') || ''}
          />
          <Checkbox
            id={'handicapAccess'}
            checked={accessibilityOptions.handicapAccess}
            onChange={(checked) =>
              handleChangeAccessibility('handicapAccess', checked)
            }
            label={
              t('clinicsConfiguration.profile_address.handicap_access') || ''
            }
          />
          <Checkbox
            id={'carPark'}
            checked={accessibilityOptions.carPark}
            onChange={(checked) =>
              handleChangeAccessibility('carPark', checked)
            }
            label={t('clinicsConfiguration.profile_address.car_park') || ''}
          />
        </AccessibilityStyled>
      </FormStyled>
      <div>
        <DividerStyled />
        <ButtonSection
          onSave={handleSubmit(handleSave)}
          onCancel={handleCancel}
          disabled={isButtonDisabled}
          showNextButton={showNextButton}
        />
      </div>
    </Wrapper>
  );
};

export default ProfileAddress;
