import axiosInstance from './axiosInstance';
import {
  ConnectionDataResponseDto,
  MassMessageRequestDto,
  ProfessionalChatUpdateDto,
} from '@docbay/schemas';
import { StartTeleconsultationRequest } from 'applicaiton/store/reducers/Chats/models';
import axiosBlobInstance from './axiosBlobInstance';

export interface ChatsSearchParams {
  topicName?: string;
  participantName?: string;
  topicIds?: string[];
}

export const ChatsAPI = {
  getChats: (requestParams: ChatsSearchParams) => {
    const params = {
      ...requestParams,
      topicIds: requestParams.topicIds?.join(','),
    };
    return axiosInstance.get('/pro-side/professional-chats', { params });
  },
  getAccessToken: () => {
    return axiosInstance.post(
      '/pro-side/professional-chats/generate-access-token',
    );
  },
  createChat: (patientId: string) => {
    return axiosInstance.post('/pro-side/professional-chats', { patientId });
  },
  getChatBySID: (conversationSID: string) => {
    return axiosInstance.get(`/pro-side/professional-chats/${conversationSID}`);
  },
  updateChat: (conversationSID: string, data: ProfessionalChatUpdateDto) => {
    return axiosInstance.patch(
      `/pro-side/professional-chats/${conversationSID}`,
      data,
    );
  },
  archiveChat: (conversationSID: string) => {
    return axiosInstance.patch(
      `/pro-side/professional-chats/${conversationSID}/archive`,
    );
  },
  unArchiveChat: (conversationSID: string) => {
    return axiosInstance.patch(
      `/pro-side/professional-chats/${conversationSID}/unarchive`,
    );
  },
  deleteChat: (conversationSID: string) => {
    return axiosInstance.delete(
      `/pro-side/professional-chats/${conversationSID}`,
    );
  },
  postMassMessagesChat: (data: { params: MassMessageRequestDto }) => {
    return axiosInstance.post(
      '/pro-side/professional-chats/mass-message',
      data.params,
    );
  },
  deleteMassMessage: (massMessageId: string) => {
    return axiosInstance.delete(
      `/pro-side/professional-chats/mass-message/${massMessageId}`,
    );
  },
  startChatTeleconsultation: ({
    conversationSid,
    patientMessageToUpdate,
  }: StartTeleconsultationRequest) => {
    const data = patientMessageToUpdate
      ? {
          patientMessageToUpdate,
        }
      : {};
    return axiosInstance.post<ConnectionDataResponseDto>(
      `/pro-side/professional-chats/${conversationSid}/start-teleconsultation`,
      data,
    );
  },
  addDocumentMessage: ({
    conversationSID,
    data,
  }: {
    conversationSID: string;
    data: FormData;
  }) => {
    return axiosInstance.post(
      `/pro-side/professional-chats/document-message/${conversationSID}/upload`,
      data,
    );
  },
  deleteDocumentMessage: (messageSid: string) => {
    return axiosInstance.delete(
      `/pro-side/professional-chats/document-message/${messageSid}`,
    );
  },
  downloadDocument: (messageSid: string) => {
    return axiosBlobInstance.get(
      `/pro-side/professional-chats/document-message/${messageSid}/download`,
    );
  },
  closeTopic: (conversationSid: string) => {
    return axiosInstance.patch(
      `/pro-side/professional-chats/${conversationSid}/close-topic`,
    );
  },
  createTeleconsultationTokenByRoomId: (roomId: string) =>
    axiosInstance.post(
      `/pro-side/chat-teleconsultation/${roomId}/generate-access-token`,
    ),
};
