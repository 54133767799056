import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

export interface UserInfoT {
  email: string;
  firstName: string;
  lastName: string;
  photo?: string;
  phone: string;
}

export const useUserInfoForm = ({
  requiredError,
  phoneError,
}: {
  requiredError: string;
  phoneError: string;
}) => {
  const formUserValues = {
    email: '',
    firstName: '',
    lastName: '',
    photo: '',
    phone: '',
  };

  const UserSchema = yup.object({
    firstName: yup.string().required(requiredError),
    lastName: yup.string().required(requiredError),
    email: yup.string().email(requiredError!).required(requiredError),
    photo: yup.string(),
    phone: yup.string().test({
      name: 'is-valid',
      test(value, ctx) {
        const phoneNumber = value?.startsWith('+')
          ? value
          : `+${String(value)}`;
        const isValidNumber = isValidPhoneNumber(phoneNumber);

        if (!isValidNumber) return ctx.createError({ message: phoneError });
        return isValidNumber;
      },
    }),
  });

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    setError,
    formState: { errors },
  } = useForm<UserInfoT>({
    resolver: yupResolver(UserSchema),
    defaultValues: formUserValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    trigger,
    setError,
  };
};
