import styled from 'styled-components';

export const Wrapper = styled.article`
  display: grid;
  width: 100%;
  gap: 24px;
  grid-template-rows: min-content 1fr;

  > div:last-child {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
`;

export const FormStyled = styled.article`
  display: grid;
  width: 100%;
  gap: 24px;
  grid-template-rows: min-content;
`;

export const RadioButtonSection = styled.section`
  display: grid;
  width: 100%;
  gap: 12px;

  > p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.color.grey200};
`;
