import styled from 'styled-components';

import { commonColors } from 'applicaiton/theme';

import { StyledProps } from './models';

export const HeaderStyled = styled.div<StyledProps>`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding: ${({ isCreateMode }) =>
    isCreateMode ? '20px 20px 0 20px' : '20px'};
  border-bottom: 1px solid ${commonColors.grey200};

  > div {
    h1 {
      color: ${commonColors.grey900};
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.2px;
    }
  }
`;

export const CloseButton = styled.button`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;

  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    path {
      fill: ${commonColors.grey700};
    }
  }
`;

export const Tabs = styled.div`
  position: relative;
  top: 1px;
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-auto-rows: 38px;
  gap: 20px;
  margin-top: 4px;
`;

export const Tab = styled.button<StyledProps>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px 4px;

  display: flex;
  align-items: center;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? commonColors.blue700 : 'transparent')};

  color: ${({ isActive }) =>
    isActive ? commonColors.blue700 : commonColors.grey600};
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  line-height: 22.4px;

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 4px;

    path {
      fill: ${({ isActive }) =>
        isActive ? commonColors.blue700 : commonColors.grey600};
    }
  }
`;
