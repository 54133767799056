import { Button, Divider, Menu, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { styledTheme } from 'applicaiton/theme';

interface Props {
  active: number;
}

interface MenuItemProps {
  open: number;
}

export const DivStyled = styled(Box)`
  width: fit-content;
`;

export const MenuItemStyled = styled(Typography)<MenuItemProps>`
  cursor: pointer;
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  line-height: 22.4px;
  color: ${({ open }) =>
    open ? styledTheme.color.blue300 : styledTheme.color.white};
  text-transform: none;

  &:hover {
    color: ${styledTheme.color.blue200};
  }
`;

export const DropdownMenuWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${styledTheme.color.menu};
  color: ${styledTheme.color.white};
  height: auto;
  min-width: 220px;
  gap: 12px;
  padding: 16px;
  align-items: flex-start;
`;

export const MenuDropdown = styled(Menu)`
  ul {
    padding: 0;
  }
  margin-top: 18px;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);

  .MuiPaper-elevation {
    border-radius: 0px 0px 8px 8px !important;
  }
`;

export const ButtonStyled = styled(Button)`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: ${styledTheme.color.blue200};
    background-color: transparent;
  }

  .MuiButton-endIcon {
    margin-right: 0px;
    margin-left: 4px;
  }
`;

export const DropdownGroupStyled = styled(Box)`
  display: flex;

  gap: 20px;
  color: ${styledTheme.color.white};
  justify-content: space-between;
  font-family: 'Mulish';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  span {
    width: 94px;
  }
`;

export const DividerStyled = styled(Divider)`
  background: ${styledTheme.color.navigator};
  width: 100%;
  height: 1px;
`;

export const DropdownMenuItemStyled = styled(Box)`
  border-bottom: 1px dashed ${styledTheme.color.white};

  a {
    color: ${styledTheme.color.white};
    text-align: right;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    &:hover {
      color: ${styledTheme.color.blue200};
    }
  }

  &:hover {
    border-bottom: 1px dashed ${styledTheme.color.blue200};
  }
  cursor: pointer;
`;

export const ArrowStyled = styled(Box)<Props>`
  display: flex;
  align-items: center;

  svg {
    transform: ${({ active }) => (!active ? 'rotate(180deg)' : 'unset')};
    path {
      fill: ${({ active }) =>
        active ? styledTheme.color.blue300 : styledTheme.color.white};
    }
  }
`;
