import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckboxGroup,
  ImageWrapper,
  LabelWrapper,
  PaymentItem,
} from './styles';
import { ReactComponent as DollarIcon } from 'applicaiton/assets/monetization.svg';
import { ReactComponent as CardIcon } from 'applicaiton/assets/credit-card.svg';
import { Checkbox } from '../../../../../common/components/index';
import { ClinicPaymentMethod } from 'features/feature-edit-profile-settings/constants/paymentMethod';

interface PaymentSectionProps {
  paymentMethods: ClinicPaymentMethod[];
  handleChange: (value: ClinicPaymentMethod) => void;
}

const PaymentSection: FC<PaymentSectionProps> = ({
  paymentMethods,
  handleChange,
}) => {
  const { t } = useTranslation();

  const isCardsPayment = paymentMethods.some((item) => item === 'CardsPayment');
  const isCashPayment = paymentMethods.some((item) => item === 'CashMoney');

  return (
    <CheckboxGroup>
      <PaymentItem isChecked={isCardsPayment!}>
        <LabelWrapper>
          <ImageWrapper isChecked={isCardsPayment!}>
            <CardIcon />
          </ImageWrapper>
          <span>{t('cardsPayments')}</span>
        </LabelWrapper>
        <Checkbox
          checked={isCardsPayment!}
          id={'cardChecked'}
          onChange={() => handleChange(ClinicPaymentMethod.CardsPayment)}
        />
      </PaymentItem>
      <PaymentItem isChecked={isCashPayment!}>
        <LabelWrapper>
          <ImageWrapper isChecked={isCashPayment!}>
            <DollarIcon />
          </ImageWrapper>
          <span>{t('cashMoney')}</span>
        </LabelWrapper>
        <Checkbox
          checked={isCashPayment!}
          id={'cashChecked'}
          onChange={() => handleChange(ClinicPaymentMethod.CashMoney)}
        />
      </PaymentItem>
    </CheckboxGroup>
  );
};

export default PaymentSection;
