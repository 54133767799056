import React, { FC } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import { MarkdownTextProps } from './models';
import { Wrapper } from './styles';

const breaksMarkdownText = (text: string) => {
  const lines = text.split('\n');

  return lines
    .map((line, index) => {
      // Check if the line is part of a list
      const isListItem = /^\s*[*\-+]\s+|^\s*\d+\.\s+/.test(line);

      const isNextLineListItem =
        index < lines.length - 1 &&
        /^\s*[*\-+]\s+|^\s*\d+\.\s+/.test(lines[index + 1]);

      if (isListItem || isNextLineListItem) return line;

      if (line.trim() === '\\') return line.replace('\\', '&nbsp;\n');

      return line + '&nbsp;\n';
    })
    .join('\n');
};

const MarkdownText: FC<MarkdownTextProps> = ({ text }) => {
  const markdownText = breaksMarkdownText(text);
  return (
    <Wrapper>
      <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
        {markdownText}
      </Markdown>
    </Wrapper>
  );
};

export default MarkdownText;
