import styled from 'styled-components';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 32px 0;

  p {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    color: ${commonColors.grey600};
  }
`;

export const PatientInfo = styled.div`
  p {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    color: ${commonColors.grey800};
  }

  span {
    font-family: Mulish;
    font-size: 12px;
    font-weight: 400;
    line-height: 22.4px;
    color: ${commonColors.grey700};
  }
`;

export const PatientList = styled.div`
  height: calc(80vh - 310px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const WrapperItem = styled.div`
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    &:first-of-type {
      display: flex;
      align-items: flex-start;
    }
  }
  &.saved {
    background: ${commonColors.grey50};
    border: 1px solid ${commonColors.grey300};

    > div:first-child {
      svg {
        margin-right: 8px;
        path {
          fill: ${commonColors.success1};
        }
      }
    }

    > div:last-child {
      font-family: Mulish;
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      color: ${commonColors.success1};
    }
  }

  &.error {
    background: ${commonColors.error3};
    border: 1px solid ${commonColors.error1};

    > div:first-child {
      svg {
        margin-right: 8px;
        path {
          fill: ${commonColors.error2};
        }
      }
    }

    > div:last-child {
      font-family: Mulish;
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      color: ${commonColors.error2};
    }
  }
  &.existing {
    background: ${commonColors.warning3};
    border: 1px solid ${commonColors.warning2};

    > div:first-child {
      svg {
        margin-right: 8px;
        path {
          fill: ${commonColors.warning2};
        }
      }
    }

    > div:last-child {
      font-family: Mulish;
      font-size: 14px;
      font-weight: 700;
      line-height: 19.6px;
      color: ${commonColors.warning2};
    }
  }
`;

export const ErrorsList = styled.div`
  span {
    display: block;
    font-family: Mulish;
    font-size: 12px;
    font-weight: 400;
    line-height: 22.4px;
    color: ${commonColors.error2};
  }
`;
