import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStripe, useElements, IbanElement } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonGroup from '../ButtonGroup';
import {
  Input,
  MapBoxAutocompleteInput,
  PrimaryButton,
  SecondaryButton,
} from 'common/components';
import { useBillingInfo } from 'features/feature-clinic-registration/hooks/useBillingInfo';
import { useBillingInfoSchema } from 'features/feature-clinic-registration/hooks/useBillingInfoSchema';
import {
  BilligInfoStyled,
  IBAN_STYLE,
  IbanSection,
  IbanStyled,
} from './styles';
import {
  currentClinicOwnerRegistration,
  getIsBackModalShowSessionStorage,
  setClinicOwnerRegistrationSessionStorage,
} from 'applicaiton/sessionStorage/clinicRegistration';
import { Context } from 'common/types/mapBox';
import { SetupIntent } from 'common/types/stripe';
import BackModal from '../BackModal';
import { useAppDispatch } from 'common/hooks/redux';
import { clearClinicOwnerSuccessSignUp } from 'applicaiton/store/reducers/ClinicRegistration/ClinicRegistrationSlice';
import { Location } from './modules';

interface BillingInfoProps {
  setStep: (value: number) => void;
}

interface FormData {
  companyName: string;
  iban: string;
}

const BillingInfo: FC<BillingInfoProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const { companyName, iban, address } = useBillingInfo();
  const [location, setLocation] = useState<Location>(address);
  const { personalInfo, stripeDebit } = currentClinicOwnerRegistration();
  const [openModal, setOpenModal] = useState(false);
  const billingInfoSchema = useBillingInfoSchema();

  const {
    register,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(billingInfoSchema),
    defaultValues: { companyName, iban },
  });

  const handleSetAddress = (context: Context) => {
    setLocation({
      city: context.place.name,
      country: context.country.name,
      postal_code: context.postcode.name,
      address: context.address?.name || context.street?.name || '',
    });
  };

  useEffect(() => {
    const values = getValues();
    setClinicOwnerRegistrationSessionStorage('billingInfo', {
      ...values,
      address: location,
    });
  }, [watch('companyName'), location]);

  const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    // Elements can use a placeholder as an example IBAN that reflects
    // the IBAN format of your customer's country. If you know your
    // customer's country, we recommend that you pass it to the Element as the
    // placeholderCountry.
    placeholderCountry: 'PT',
    style: IBAN_STYLE,
  };

  const handleSubmit = async () => {
    const ibanElement = elements?.getElement(IbanElement);
    if (!stripe || !elements || !ibanElement) {
      return;
    }

    const { setupIntent } = await stripe.confirmSepaDebitSetup(
      stripeDebit.sepaClientSecret,
      {
        payment_method: {
          sepa_debit: ibanElement,
          billing_details: {
            name: getValues('companyName'),
            email: personalInfo.email!,
            address: location,
            phone: personalInfo.phone,
          },
        },
      },
    );

    if (setupIntent) {
      setClinicOwnerRegistrationSessionStorage(
        'setupIntent',
        (setupIntent || {}) as SetupIntent,
      );
      setStep(5);
    }
  };

  const getAddressStreet = () => {
    if (location) {
      const address = [
        location.address || '',
        location.postal_code || '',
        location.city || '',
        location.country || '',
      ]
        .filter((item) => !!item)
        .join(' ');
      return address;
    }
    return '';
  };
  const disabledButton = !location || !getValues('companyName');

  const handleBackButton = () => {
    if (getIsBackModalShowSessionStorage() === 'N') {
      setOpenModal(true);
    } else {
      dispatch(clearClinicOwnerSuccessSignUp());
      setStep(2);
    }
  };

  return (
    <>
      <BilligInfoStyled>
        <section>
          <h2>
            {t('clinicRegistration.billing_information.personal_info_SEPA')}
          </h2>
          <div>
            <Input
              id="companyName"
              label={
                t('clinicRegistration.billing_information.company_name') || ''
              }
              type="text"
              placeholder={
                t(
                  'clinicRegistration.billing_information.company_name_placeholder',
                ) || ''
              }
              errorMessage={errors.companyName?.message}
              register={register}
            />
            <MapBoxAutocompleteInput
              id={'address'}
              label={t('clinicRegistration.billing_information.address') || ''}
              defaultValue={getAddressStreet()}
              setLocation={handleSetAddress}
            />
            <IbanSection>
              <label>IBAN</label>
              <IbanStyled>
                <IbanElement options={IBAN_ELEMENT_OPTIONS} />
              </IbanStyled>
            </IbanSection>
          </div>
        </section>
        <ButtonGroup>
          <PrimaryButton
            disabled={disabledButton}
            type="button"
            onClick={handleSubmit}
          >
            {t('clinicRegistration.continue')}
          </PrimaryButton>
          <SecondaryButton
            onClick={handleBackButton}
            disabled={false}
            type="button"
          >
            {t('clinicRegistration.back')}
          </SecondaryButton>
        </ButtonGroup>
      </BilligInfoStyled>
      <BackModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        backToStep={() => setStep(2)}
      />
    </>
  );
};

export default BillingInfo;
