import React, { FC, useEffect } from 'react';
import { Wrapper } from './styles';
import LayoutComponent from './components/LayoutComponent';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { ProfileForm } from 'applicaiton/constants/clincOwnerProfile';
import ProfessionalAdd from 'features/feature-professional-add';
import ProfessionalEdit from 'features/feature-professional-edit';
import { resetClinicOwnerProfile } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';

const ClinicOwnerProfile: FC = () => {
  const dispatch = useAppDispatch();
  const { activeForm } = useAppSelector((state) => state.clinicOwnerSlice);

  const previewProfile = activeForm === ProfileForm.previewProfile;
  const createProfessionalProfile =
    activeForm === ProfileForm.createProfessionalProfile;
  const editProfessionalProfile =
    activeForm === ProfileForm.editProfessionalProfile;

  useEffect(() => {
    return () => {
      dispatch(resetClinicOwnerProfile());
    };
  }, []);

  return (
    <>
      {previewProfile && (
        <Wrapper>
          <LayoutComponent />
        </Wrapper>
      )}
      {createProfessionalProfile && <ProfessionalAdd />}
      {editProfessionalProfile && <ProfessionalEdit />}
    </>
  );
};

export default ClinicOwnerProfile;
