export const getDayHours = (slotsTimeView: string) => {
  if (slotsTimeView.startsWith('00:00') && slotsTimeView.endsWith('23:59')) {
    return {
      start: 0,
      end: 24,
    };
  }
  if (slotsTimeView.endsWith('12:00')) {
    return {
      start: 0,
      end: 12,
    };
  }
  if (slotsTimeView.startsWith('12:00')) {
    return {
      start: 12,
      end: 18,
    };
  }
  if (slotsTimeView.endsWith('23:59')) {
    return {
      start: 18,
      end: 24,
    };
  }
  return { start: 0, end: 24 };
};
