import React, { FC, useState } from 'react';
import { DropdownProfessionals, SecondaryButton } from 'common/components';
import {
  ProfessionalsDropdownSection,
  ProfessionalsList,
  ProfessionalItem,
  ProfessionalInfo,
} from './styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as ExpandMoreIcon } from 'applicaiton/assets/expand-more.svg';
import { ReactComponent as ExpandLessIcon } from 'applicaiton/assets/expand-less.svg';
import { AssigningProfessionalProps } from './types';
import { Option } from 'common/components/DropdownProfessionals/models';
import { useFormContext } from 'react-hook-form';
import { getLanguageFromLocalStorage } from 'applicaiton/sessionStorage/language';

const AssigningProfessionals: FC<AssigningProfessionalProps> = ({
  setProfessionalsToAdd,
  professionalsToAdd,
  professionalsOptions,
  handleSetProfessionals,
}) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState<boolean>(false);
  const {
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const language = getLanguageFromLocalStorage();
  const localizeKey = `name_${language.toUpperCase()}` as 'name_EN' | 'name_PT';

  const handleDeleteProfessionals = (value: string) => {
    setProfessionalsToAdd((prev: Option[]) => [
      ...prev.filter((item) => item.value !== value),
    ]);
    handleSetProfessionals(
      professionalsToAdd.filter((item) => item.value !== value),
    );
  };

  const handleProfessionalIdsChange = (professionals: Option[]) => {
    setProfessionalsToAdd(professionals as Option[]);
    handleSetProfessionals(professionals as Option[]);
    const professionalIds = professionals.map((item) => item.value);

    setValue('professionalIds', professionalIds);
    clearErrors('professionalIds');
  };

  return (
    <>
      <ProfessionalsDropdownSection error={!!errors.professionalIds}>
        <label>{t('users.add_professional')}</label>

        <DropdownProfessionals
          options={professionalsOptions}
          id={'professionalIds'}
          searchPlaceholder={t('select_from_your_professional_list') || ''}
          value={professionalsToAdd}
          onChange={handleProfessionalIdsChange}
          disabled={!professionalsToAdd.length}
        />
      </ProfessionalsDropdownSection>

      <ProfessionalsList>
        {professionalsToAdd?.length ? (
          <h2>
            {professionalsToAdd?.length} {t('selected')}
          </h2>
        ) : null}

        {professionalsToAdd?.map((professional, index) => {
          if (!showMore && index > 2) return;
          return (
            <div key={professional.value}>
              <ProfessionalItem>
                {professional.image ? (
                  <img src={professional.image} alt={professional.image} />
                ) : (
                  <div>
                    <p>{professional.label[0]}</p>
                    <p>{professional.label[1]}</p>
                  </div>
                )}
                <ProfessionalInfo>
                  <h2>{professional.label}</h2>
                  <div>
                    {professional?.specializations?.map((item, index) => (
                      <p key={item.id}>
                        {item[localizeKey]}
                        {index + 1 !== professional?.specializations?.length &&
                          ','}
                      </p>
                    ))}
                  </div>
                </ProfessionalInfo>
              </ProfessionalItem>
              {professionalsToAdd.length > 1 && (
                <button
                  onClick={() =>
                    handleDeleteProfessionals(String(professional.value))
                  }
                >
                  <CloseIcon />
                </button>
              )}
            </div>
          );
        })}
        {professionalsToAdd.length > 3 && (
          <SecondaryButton
            styleType={'color'}
            onClick={() => setShowMore((prev) => !prev)}
          >
            {!showMore ? (
              <>
                {`${t('show_more')} (${professionalsToAdd.length - 3}) `}
                <ExpandMoreIcon />
              </>
            ) : (
              <>
                {t('show_less')}
                <ExpandLessIcon />
              </>
            )}
          </SecondaryButton>
        )}
      </ProfessionalsList>
    </>
  );
};

export default AssigningProfessionals;
