import { useLocation } from 'react-router-dom';
import { useAppSelector } from './redux';

export const useDetectPathChanged = (path: string) => {
  const location = useLocation();
  const { hasUnsavedChanges } = useAppSelector((state) => state.savedChanges);
  const currentPath = location.pathname === path;

  if (!currentPath && !hasUnsavedChanges) return false;
  return true;
};
