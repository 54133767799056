import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import { Wrapper } from './styles';

const NoDataElement: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div>
        <PickIcon />
        <p>{t('my_notifications.empty_state')}</p>
      </div>
    </Wrapper>
  );
};
export default NoDataElement;
