import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TimeLeft } from './styles';
import { Props } from './models';

const TimeCounter = ({
  isStart,
  onChange,
  timeMins,
  showResetButton,
}: Props) => {
  const [time, setTime] = useState('');
  const [date, setDate] = useState<Dayjs>();

  useEffect(() => {
    if (isStart) {
      onChange(false);
      setTime('');

      setDate(dayjs().add(timeMins, 'minutes'));
    }
  }, [isStart, timeMins]);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (dayjs().isAfter(date)) {
        showResetButton && showResetButton();
        return;
      }

      const allSeconds = dayjs(date).diff(dayjs(), 'seconds');
      const minutes = Math.floor(allSeconds / 60);
      const seconds = allSeconds - 60 * minutes;

      setTime(`0${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [date]);

  return dayjs().isAfter(date) ? (
    <TimeLeft isEnded>00:00 min</TimeLeft>
  ) : (
    <TimeLeft>{time} min</TimeLeft>
  );
};

export default TimeCounter;
