import styled from 'styled-components';

interface StyledProps {
  isEnded?: boolean;
}

export const TimeLeft = styled.span<StyledProps>`
  color: ${({ theme, isEnded }) =>
    isEnded ? theme.color.warning : theme.color.green600};
  padding: 0 4px;
  font-weight: 700;
`;
