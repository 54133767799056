import React, { FC } from 'react';
import { Wrapper, RemoteParticipantStyled, ParticipantStyled } from './styles';
import { RoomProps } from 'features/feature-teleconsultation/types';
import { PrivacyPolicy, Participant } from './component';

const Room: FC<RoomProps> = ({
  localParticipant,
  remoteParticipants,
  patientInitials,
  hasError,
}) => {
  const isRemoteParticipantsJoined = remoteParticipants.some(
    (item) => item.sid,
  );

  return (
    <Wrapper>
      {hasError ? (
        <PrivacyPolicy />
      ) : (
        <>
          <RemoteParticipantStyled>
            {remoteParticipants.map((p) => (
              <Participant
                key={p.sid}
                participant={p}
                isRemoteParticipant={isRemoteParticipantsJoined}
                patientInitials={patientInitials}
              />
            ))}
            {!isRemoteParticipantsJoined && (
              <Participant participant={localParticipant} />
            )}
          </RemoteParticipantStyled>
          {isRemoteParticipantsJoined && (
            <ParticipantStyled>
              <Participant participant={localParticipant} />
            </ParticipantStyled>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Room;
