import React from 'react';
import { ChooseChatTypeProps } from './module';
import { MenuItemStyled, MenuStyled } from './styles';
import { ArchiveIcon } from 'applicaiton/assets';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'common/hooks/redux';
import { setChatMode } from 'applicaiton/store/reducers/Chats/ChatsSlice';
import { ChatsMode } from 'applicaiton/constants/chats';
import { AllChatsIcon } from 'applicaiton/assets/chats';
import { useChats } from '../../hooks/useChats';
import { useConversation } from 'features/feature-chats/hooks/useConversation';

export const ChooseChatType = ({ anchorEl, onClose }: ChooseChatTypeProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setSelectedChat } = useChats();
  const { setConversation } = useConversation();
  const menuIsOpen = !!anchorEl;

  const handleSetChatMode = async (mode: ChatsMode) => {
    dispatch(setChatMode(mode));
    setSelectedChat(null);
    setConversation(null);
    onClose();
  };

  return (
    <>
      <MenuStyled
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuIsOpen}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItemStyled onClick={() => handleSetChatMode(ChatsMode.Chats)}>
          <AllChatsIcon />
          {t('chats.sidebar_title')}
        </MenuItemStyled>
        <MenuItemStyled
          onClick={() => handleSetChatMode(ChatsMode.ArchivedChats)}
        >
          <ArchiveIcon />
          {t('chats.archives_sidebar_title')}
        </MenuItemStyled>
      </MenuStyled>
    </>
  );
};
