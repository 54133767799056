import { createAsyncThunk } from '@reduxjs/toolkit';
import { TimeZoneActions } from 'applicaiton/constants/actions';
import { AxiosError } from 'axios';
import { TimeZoneAPI } from 'integration/api/timeZone';

export const setTimeZoneData = createAsyncThunk(
  TimeZoneActions.setTimeZone,
  async (timeZone: string, { rejectWithValue }) => {
    try {
      const { data } = await TimeZoneAPI.setTimeZone(timeZone);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
