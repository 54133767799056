import styled from 'styled-components';
import { MessageStyledProps, WrapperStyledProps } from './modules';
import { commonColors } from 'applicaiton/theme';

export const Wrapper = styled.div<WrapperStyledProps>`
  position: relative;
  cursor: ${({ isDocument }) => (isDocument ? 'pointer' : 'auto')};
`;

export const MessageStyled = styled.div<MessageStyledProps>`
  border-radius: ${({ isOwnMessage }) =>
    isOwnMessage ? '15px 15px 0 15px' : '15px 15px 15px 0'};
  background-color: ${({ theme, isOwnMessage }) =>
    isOwnMessage ? theme.color.blue800 : theme.color.grey100};
  margin-left: ${({ isOwnMessage }) => (isOwnMessage ? '48px' : '0')};
  margin-right: ${({ isOwnMessage }) => (isOwnMessage ? '0' : '48px')};
  padding: 15px 48px 15px 12px;
  position: relative;
  & .message-text {
    font-size: 16px;
    line-height: 26px;
    color: ${({ theme, isOwnMessage }) =>
      isOwnMessage ? theme.color.white : theme.color.grey800};
    white-space: pre-wrap;
    word-break: break-word;

    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
    resize: none;
    field-sizing: content;
  }
  & .message-time {
    position: absolute;
    bottom: 6px;
    right: 6px;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme, isOwnMessage }) =>
      isOwnMessage ? theme.color.white : theme.color.grey400};
  }

  & .message-document {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    word-break: break-all;
    color: ${({ theme, isOwnMessage }) =>
      isOwnMessage ? theme.color.white : theme.color.grey800};

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      margin-right: 8px;
    }
  }
`;

export const AnchorMenu = styled.div`
  position: absolute;
  width: fit-content;
  top: 100%;
  z-index: 10;
  left: 50px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);

  p {
    display: flex;
    padding: 4px 6px;
    align-items: center;
    color: ${commonColors.grey800};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;

    &:hover {
      background-color: ${commonColors.grey100};
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      path {
        fill: ${commonColors.grey600};
      }
    }
  }
`;
