import { Switch } from '@mui/material';
import styled from 'styled-components';

export const SwitchStyled = styled(Switch)`
  width: 36px !important;
  height: 20px !important;
  padding: 0 !important;
  margin: 0;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: 2px;
    transition-duration: 300ms;
    &.Mui-checked {
      transform: translateX(16px);
      color: ${({ theme }) => theme.color.white};
      & + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.color.blue400};
        opacity: 1;
        border: 0;
      }
      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }
    &.Mui-focusVisible .MuiSwitch-thumb {
      color: #33cf4d;
      border: 6px solid ${({ theme }) => theme.color.white};
    }
    &.Mui-disabled .MuiSwitch-thumb {
      color: ${({ theme }) => theme.color.white};
    }
    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.7;
      background-color: ${({ theme }) => theme.color.grey200};
    }
  }
  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 16px;
    height: 16px;
  }
  & .MuiSwitch-track {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.color.grey400};
    opacity: 1;
  }
`;
