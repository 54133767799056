import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { StyledModal } from './styles';
import { LeavePageModalProps } from './modules';

const LeavePageModal: FC<LeavePageModalProps> = ({
  showModal,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={showModal} onClose={onClose}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <h1>{t('Want to leave this page?')}</h1>
        <p>{t('Updates will not be saved')}</p>
        <section>
          <SecondaryButton onClick={onClose}>{t('cancel')}</SecondaryButton>
          <PrimaryButton onClick={onSubmit} styleType={'error'}>
            {t('Exit')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default LeavePageModal;
