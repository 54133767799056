import React, { FC } from 'react';
import { Modal } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningIcon } from 'applicaiton/assets/unsuccess-icon.svg';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { PathNames } from 'applicaiton/routes';
import { setShowLimitSubscriptionsModal } from 'applicaiton/store/reducers/ClinicOwner/ClinicOwnerSlice';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Loader, PrimaryButton, SecondaryButton } from 'common/components';
import { ModalStyled, ButtonSection, InfoSection } from './styles';

const SubscriptionsLimitModal: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, showLimitSubscriptionsModal } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );

  const handleClose = () => {
    const isProfessionalAddPage =
      location.pathname === PathNames.addProfessional;
    const isUserAddPage =
      location.pathname === PathNames.addAdmin ||
      location.pathname === PathNames.addSecretary;

    dispatch(setShowLimitSubscriptionsModal(false));
    if (isProfessionalAddPage) {
      navigate(PathNames.professionals);
    }
    if (isUserAddPage) {
      navigate(PathNames.myUsers);
    }
  };

  const addedItem = location.pathname.includes(PathNames.professionals)
    ? t('professional')
    : t('user');

  const handleSubmit = () => {
    handleClose();
    navigate(PathNames.clinicBilling);
  };

  return (
    <Modal open={showLimitSubscriptionsModal}>
      <>
        {isLoading && <Loader />}
        <ModalStyled>
          <CloseIcon onClick={handleClose} />
          <InfoSection>
            <WarningIcon />
            <h1>
              {`${t('subscriptions_limit_modal.title_part1')} ${t(
                addedItem,
              )}, ${t('subscriptions_limit_modal.title_part2')}`}
            </h1>
            <p>
              {`${t('subscriptions_limit_modal.text_part1')} ${t(
                addedItem,
              )}, ${t('subscriptions_limit_modal.text_part2')}`}
            </p>
          </InfoSection>
          <ButtonSection>
            <SecondaryButton onClick={handleClose}>
              {t('subscriptions_limit_modal.cancel_text')}
            </SecondaryButton>
            <PrimaryButton onClick={handleSubmit} type="button">
              {t('subscriptions_limit_modal.submit_text')}
            </PrimaryButton>
          </ButtonSection>
        </ModalStyled>
      </>
    </Modal>
  );
};

export default SubscriptionsLimitModal;
