import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal, ButtonWrapper } from './styles';
import { Modal } from '@mui/material';
import { RestoreModalProps } from './modules';

const RestoreModal: FC<RestoreModalProps> = ({ onRestore, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <p>{t('clinic_owner.restore_professional_profile')}</p>
          <ButtonWrapper>
            <SecondaryButton onClick={onClose}>{t('no')}</SecondaryButton>
            <PrimaryButton onClick={onRestore}>{t('yes')}</PrimaryButton>
          </ButtonWrapper>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default RestoreModal;
