import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableStyled, Wrapper, StyledTr, FilerItems, ItemList } from './styles';
import { NoDataElement, FilterSection } from '../index';
import { Loader } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { Option } from 'common/components/Dropdown/models';
import {
  fetchSubscriptions,
  fetchSubscriptionStatistic,
} from 'applicaiton/store/reducers/Professionals/ActionCreators';
import { ReactComponent as TotalSubscribersIcon } from 'applicaiton/assets/total-subscribers.svg';
import { ReactComponent as MonthlyRevenueIcon } from 'applicaiton/assets/monthly-revenue.svg';
import { ReactComponent as WeeklyRevenueIcon } from 'applicaiton/assets/weekly-revenue.svg';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';

const StatisticTable: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [chosenClinics, setChosenClinics] = useState<Option[]>([]);
  const [chosenSubscriptions, setChosenSubscriptions] = useState<string[]>([]);
  const { clinics } = useAppSelector((state) => state.clinics);
  const { subscriptionsStatistic, subscriptionsPackages, isLoading } =
    useAppSelector((state) => state.professionalProfile);

  const getSubscriptionStatistic = (ids: string[]) => {
    dispatch(fetchSubscriptionStatistic(ids ? { packageIds: ids } : {}));
  };

  const getSubscriptions = (clinics?: Option[]) => {
    const selectedClinics = clinics?.length ? clinics : [];
    const clinicIds = selectedClinics?.map((clinic) => String(clinic.value));

    dispatch(fetchSubscriptions(clinicIds ? { clinicIds } : {}));
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  useEffect(() => {
    if (clinics.length === 1) {
      setChosenClinics([{ value: clinics[0].id, label: clinics[0].name! }]);
    }
  }, [clinics]);

  useEffect(() => {
    if (subscriptionsPackages?.length) {
      const packageIds = subscriptionsPackages?.map((packageData) =>
        String(packageData.id),
      );
      getSubscriptionStatistic(packageIds);
    }
  }, [subscriptionsPackages]);

  const subscriptionsOptions = useMemo(() => {
    const options = subscriptionsPackages.map((item) => {
      return {
        value: item.id,
        label: item.title,
        durationInWeeks: item.durationInWeeks,
        price: item.price,
      };
    });
    return options;
  }, [subscriptionsPackages]);

  useEffect(() => {
    const packageIds = subscriptionsPackages?.map((packageData) =>
      String(packageData.id),
    );

    setChosenSubscriptions(packageIds);
  }, [subscriptionsPackages]);

  const handleRemoveFilterItem = (id: string, type: string) => {
    if (type === 'clinic') {
      setChosenClinics(chosenClinics.filter((item) => item.value !== id));
    } else {
      setChosenSubscriptions(chosenSubscriptions.filter((item) => item !== id));
    }
  };

  const handleResetFilter = () => {
    setChosenClinics([]);
    setChosenSubscriptions([]);
  };

  const handleClinicChange = (value: Option[]) => {
    setChosenSubscriptions([]);
    setChosenClinics(value);
    getSubscriptions(value);
  };

  const handleSubscriptionChange = (value: string[]) => {
    setChosenSubscriptions(value);
    getSubscriptionStatistic(value);
  };

  return (
    <>
      <Wrapper>
        {isLoading && <Loader />}
        <>
          {subscriptionsStatistic ? (
            <>
              <FilerItems>
                <FilterSection
                  subscriptionsId={chosenSubscriptions}
                  setSubscriptionsId={handleSubscriptionChange}
                  clinics={chosenClinics}
                  setClinic={handleClinicChange}
                  handleRemoveFilterItem={handleRemoveFilterItem}
                  handleResetFilter={handleResetFilter}
                  subscriptionsOptions={subscriptionsOptions}
                />
                <>
                  {chosenClinics?.length || !!chosenSubscriptions.length ? (
                    <ItemList>
                      <h2>{t('appointment_types.selected_filters')}:</h2>
                      {chosenClinics?.map((item) => {
                        return (
                          <div key={item.value}>
                            <p>{item.label}</p>
                            <button
                              onClick={() =>
                                handleRemoveFilterItem(
                                  String(item.value),
                                  'clinic',
                                )
                              }
                            >
                              <CloseIcon />
                            </button>
                          </div>
                        );
                      })}
                      {!!chosenSubscriptions.length &&
                        chosenSubscriptions.map((id) => {
                          const currentSubscription =
                            subscriptionsPackages.find(
                              (item) => item.id === id,
                            );

                          return (
                            <div key={`subscription_${id}`}>
                              <p>{currentSubscription?.title}</p>
                              <button
                                onClick={() =>
                                  handleRemoveFilterItem(
                                    String(id),
                                    'subscription',
                                  )
                                }
                              >
                                <CloseIcon />
                              </button>
                            </div>
                          );
                        })}
                    </ItemList>
                  ) : (
                    ''
                  )}
                </>
              </FilerItems>

              <TableStyled>
                <thead>
                  <tr>
                    <th>
                      <span>{t('subscriptions_statistics.table.col1')}</span>
                    </th>
                    <th>
                      <span>{t('subscriptions_statistics.table.col2')}</span>
                    </th>
                    <th>
                      <span>{t('subscriptions_statistics.table.col3')}</span>
                    </th>
                  </tr>
                </thead>

                {subscriptionsPackages.length && subscriptionsStatistic ? (
                  <tbody>
                    <StyledTr>
                      <td>
                        <TotalSubscribersIcon />
                      </td>
                      <td>{t('subscriptions_statistics.total_subscribers')}</td>
                      <td>{subscriptionsStatistic.totalSubscribers}</td>
                    </StyledTr>
                    <StyledTr>
                      <td>
                        <MonthlyRevenueIcon />
                      </td>
                      <td>{t('subscriptions_statistics.monthly_revenue')}</td>
                      <td>{subscriptionsStatistic.mrr}</td>
                    </StyledTr>
                    <StyledTr>
                      <td>
                        <WeeklyRevenueIcon />
                      </td>
                      <td>{t('subscriptions_statistics.weekly_revenue')}</td>
                      <td>{subscriptionsStatistic.wrr}</td>
                    </StyledTr>
                  </tbody>
                ) : (
                  <NoDataElement emptyCurrentClinic={true} />
                )}
              </TableStyled>
            </>
          ) : (
            <NoDataElement emptyCurrentClinic={false} />
          )}
        </>
      </Wrapper>
    </>
  );
};

export default StatisticTable;
