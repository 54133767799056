import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 144px;
  padding: 40px 0px;
  background: ${({ theme }) => theme.color.white};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.color.grey200};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);

  > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: 24px;
    }
  }
`;
