import { Box, styled } from '@mui/system';

export const ForgotPasswordStyled = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
`;

export const ForgotPasswordWrapper = styled(Box)`
  width: 100%;
  margin-top: 32px;
`;
