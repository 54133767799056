import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'common/components';
import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { ReactComponent as CancelIcon } from 'applicaiton/assets/cancel.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';

interface DeleteModalProps {
  isOpen: boolean;
  documentName: string;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmDeleteDocument: FC<DeleteModalProps> = ({
  isOpen,
  documentName,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <h1>
          {t('appointment.confirmDelete.titlePart1')}{' '}
          <span>{documentName}</span>?
        </h1>
        <div>
          <p> {t('appointment.confirmDelete.description')}</p>
          <div>
            <CancelIcon />
            {t('appointment.confirmDelete.descriptionText1')}
          </div>
        </div>
        <section>
          <SecondaryButton type={'button'} onClick={onClose}>
            {t('cancel')}
          </SecondaryButton>
          <PrimaryButton type={'button'} onClick={onSubmit} styleType={'error'}>
            {t('appointment_types.delete_type.confirm')}
          </PrimaryButton>
        </section>
      </StyledModal>
    </Modal>
  );
};

export default ConfirmDeleteDocument;
