import {
  PatientBriefResponseDto,
  ProfessionalChatResponseDto,
  ProfessionalChatsResponseDto,
  TeleconsultationMessageObjectDto,
} from '@docbay/schemas';
import { Client } from '@twilio/conversations';
import { ChatsMode } from 'applicaiton/constants/chats';

export enum ChatsType {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
  EMERGENCY = 'EMERGENCY',
  PRIORITY = 'PRIORITY',
  TELECONSULTATION = 'TELECONSULTATION',
}

export interface ChatsReadMessageInfo {
  sid: string;
  lastReadMessageIndex: number | null;
}

export interface ChatExtraInfo {
  unreadMessagesCount?: number | null;
  lastMessageText?: string | null;
  lastMessageTime?: Date | null;
}

export interface ChatAttributes {
  emergency?: boolean;
  teleconsultation?: boolean;
  archived?: boolean;
}

export interface ChatWithExtraInfo
  extends ProfessionalChatResponseDto,
    ChatExtraInfo {}

export interface InitialState {
  currentClient: Client | null;
  selectedChatsType: ChatsType;
  selectedChat: ProfessionalChatResponseDto | null;
  chats: ProfessionalChatsResponseDto;
  accessToken: string | null;
  isLoading: boolean;
  error: string;
  mode: ChatsMode;
  massMessagesPatientsIds: PatientBriefResponseDto[] | null;
  massMessages: any;
  room: string;
  isDocumentLoading: boolean;
  documentError: string;
  chatsReadMessagesInfo: ChatsReadMessageInfo[];
}

export interface MessageAttributes {
  emergency?: boolean;
  teleconsultation?: boolean;
  teleconsultationRequest?: boolean;
  teleconsultationObject?: TeleconsultationMessageObjectDto;
}

export interface StartTeleconsultationRequest {
  conversationSid: string;
  patientMessageToUpdate?: {
    messageSid: string;
    conversationSid: string;
  };
}
