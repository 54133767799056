import styled from 'styled-components';

export const Wrapper = styled.main`
  display: grid;
  grid-template-rows: repeat(3, max-content);
  padding: 0 32px 20px 32px;
  gap: 20px;
  background: ${({ theme }) => theme.color.background};
  overflow-y: auto;
  height: 100%;
  width: 100%;

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 28px;
    font-weight: 700;
    line-height: 39.2px;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }
`;
