import React, { useMemo } from 'react';
import {
  Wrapper,
  Notes,
  ItemStyled,
  ItemTeleconsultationStyled,
} from './styles';
import { ReactComponent as AssignmentIcon } from 'applicaiton/assets/assignment.svg';
import { ReactComponent as ClinicIcon } from 'applicaiton/assets/clinic.svg';
import { ReactComponent as OnlineIcon } from 'applicaiton/assets/online.svg';
import dayjs from 'dayjs';
import { AppointmentHistoryResponseDto } from '@docbay/schemas';
import { MeetingPlace } from 'applicaiton/store/reducers/AppointmentTypes/AppointmentTypesSlice';
import { useTranslation } from 'react-i18next';

const HistoryListItem = ({
  item,
  isActive,
  onClick,
}: {
  item: AppointmentHistoryResponseDto;
  isActive: boolean;
  onClick: (appointmentData: AppointmentHistoryResponseDto) => void;
}) => {
  const { t } = useTranslation();
  const isTeleconsultation = useMemo(() => {
    return item?.appointmentType.meetingPlace === MeetingPlace.Teleconsultation;
  }, [item]);

  const appointmentTime = `${dayjs(item?.startDate)
    .local()
    .format('DD MMMM YYYY')} | ${dayjs(item?.startDate)
    .local()
    .format('HH:mm')} - ${dayjs(item?.endDate).local().format('HH:mm')}`;

  return (
    <Wrapper isActive={isActive} onClick={() => onClick(item)}>
      <Notes>
        <AssignmentIcon />
        <span>{item.appointmentType?.title || ''}</span>
      </Notes>
      <Notes>
        {isTeleconsultation ? (
          <ItemTeleconsultationStyled isActive={isActive}>
            <OnlineIcon />
            <span>{t('teleconsultationTitle')}</span>
          </ItemTeleconsultationStyled>
        ) : (
          <ItemStyled isActive={isActive}>
            <ClinicIcon />
            <span>{item?.clinic?.name || ''}</span>
          </ItemStyled>
        )}
      </Notes>
      <div>{appointmentTime}</div>
    </Wrapper>
  );
};

export default HistoryListItem;
