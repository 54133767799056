import styled from 'styled-components';

interface DropdownStyledProps {
  isActive?: boolean;
  disabled?: boolean;
  isError?: boolean;
}

interface OptionItemProps {
  selected: boolean;
  isMulti?: boolean;
  isHidden?: boolean;
}

interface ErrorProps {
  isError?: boolean;
}

export const Wrapper = styled.div<ErrorProps>`
  display: grid;
  gap: 6px;
  > label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: ${({ theme, isError }) =>
      isError ? theme.color.error2 : theme.color.grey600};
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.error2};
  }
`;

export const DropdownStyled = styled.div<DropdownStyledProps>`
  display: grid;
  gap: 6px;
  width: 100%;
  position: relative;

  > label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: ${({ theme, disabled }) =>
      disabled ? theme.color.grey400 : theme.color.grey600};
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ theme, disabled }) =>
      disabled ? theme.color.grey400 : theme.color.grey600};
  }
  > section {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    width: 100%;
    height: 44px;
    background: ${({ theme, isActive, disabled, isError }) =>
      isError
        ? theme.color.error3
        : isActive
        ? theme.color.blue50
        : theme.color[disabled ? 'grey50' : 'white']};
    border: 1px solid
      ${({ theme, isActive, isError }) =>
        isError
          ? theme.color.error2
          : isActive
          ? theme.color.blue800
          : theme.color.grey300};
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.color.grey700};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    white-space: nowrap;
    overflow: hidden;

    > span {
      color: ${({ theme, disabled }) =>
        disabled ? theme.color.grey300 : theme.color.grey500};
    }
    > svg {
      margin-left: 12px;
      transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : '')};
      path {
        fill: ${({ theme, disabled }) =>
          disabled ? theme.color.grey300 : theme.color.grey700};
      }
    }

    > h2 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;

export const OptionsList = styled.div`
  display: grid;
  padding: 12px;
  gap: 4px;
  grid-template-rows: max-content;
  position: absolute;
  z-index: 10;
  top: 44px;
  max-height: 390px;
  min-width: 440px;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  cursor: pointer;
`;

export const OptionCheckBox = styled.label<OptionItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px;
  cursor: ${({ isHidden }) => (isHidden ? 'default' : 'pointer')};
  background: ${({ theme, selected }) =>
    selected ? theme.color.blue100 : 'transparent'};

  opacity: ${({ isHidden }) => (isHidden ? 0.6 : 1)};
  pointer-events: ${({ isHidden }) => (isHidden ? 'none' : 'all')};

  &:hover {
    background: ${({ theme }) => theme.color.grey100};
  }

  > div:first-child {
    display: flex;
    align-items: center;
  }

  > p {
    color: ${({ theme }) => theme.color.grey800};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
  }

  > div:first-child {
    margin-right: 12px;
    label {
      padding: 0px;
    }
  }
  > div:last-child {
    display: ${({ isMulti }) => (!isMulti ? 'none' : 'flex')};
  }
`;

export const ClinicImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.blueDark100};
  margin-right: 16px;
  color: ${({ theme }) => theme.color.white};

  > svg {
    path {
      fill: ${({ theme }) => theme.color.grey900};
    }
  }

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
