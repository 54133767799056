import React from 'react';
import { SideMenu } from 'features';
import { Wrapper } from './styles';
import WaitingList from 'features/feature-waiting-list';

const WaitingListPage = () => {
  return (
    <Wrapper>
      <SideMenu />
      <WaitingList />
    </Wrapper>
  );
};

export default WaitingListPage;
