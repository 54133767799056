import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientSearchResponseDto } from '@docbay/schemas';

import { ReactComponent as SearchIcon } from 'applicaiton/assets/search.svg';
import { ReactComponent as PickIcon } from 'applicaiton/assets/pick.svg';
import {
  setPatients,
  setCurrentFamilyMemberSearch,
  setCurrentPatient,
} from 'applicaiton/store/reducers/Appointments/AppoinmentsSlice';
import { fetchAppointmentPatients } from 'applicaiton/store/reducers/Appointments/ActionCreators';

import { Input } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useDebounce } from 'common/hooks/useDebounce';
import useOnClickOutside from 'common/hooks/useClickOutside';

import { PatientSearchProps } from './models';
import { Wrapper, PatientsList, PatientItem, EmptyResults } from './styles';

const PatientSearch: FC<PatientSearchProps> = ({
  setValue,
  errorMessage,
  handleResetPatient,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { isLoading, appointmentPatients, currentPatient } = useAppSelector(
    (state) => state.appointmentsSlice,
  );
  const [showList, setShowList] = useState(false);
  const [search, setSearch] = useState('');
  const searchValue = useDebounce(search, 300);

  useOnClickOutside(ref, () => {
    setShowList(false);
  });

  useEffect(() => {
    !!currentPatient &&
      setSearch(`${currentPatient?.firstName} ${currentPatient?.lastName}`);
  }, [currentPatient]);

  useEffect(() => {
    if (searchValue.length < 3) {
      dispatch(setPatients(null));
    } else {
      dispatch(fetchAppointmentPatients({ search: searchValue }));
    }
  }, [searchValue]);

  const onClear = () => {
    setShowList(false);
    setSearch('');
    dispatch(setPatients(null));
    dispatch(setCurrentFamilyMemberSearch(null));
    dispatch(setCurrentPatient(null));
    setValue('patientId', '', {
      shouldValidate: !!errorMessage,
    });
    handleResetPatient();
  };

  const handleSelectPatient = (item: PatientSearchResponseDto) => {
    handleResetPatient();
    dispatch(setCurrentFamilyMemberSearch(item));
    setValue('patientId', item?.isPatient ? item?.id! : item?.patient?.id!, {
      shouldValidate: !!errorMessage,
    });
    setShowList(false);
  };

  const handleShowList = (localName: string) => {
    const showList = localName === 'input' || localName === 'svg';
    setShowList((prev) => showList || !prev);
  };

  const showEmptyState =
    !isLoading &&
    !appointmentPatients?.length &&
    search &&
    searchValue.length > 2 &&
    showList;

  return (
    <Wrapper ref={ref}>
      <div
        onClick={(e: any) => {
          handleShowList(e.target.localName);
        }}
      >
        <Input
          id={'patientId'}
          type="search"
          value={search}
          onClear={onClear}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('scheduler.search_patient_placeholder') || ''}
          errorMessage={errorMessage}
          label={t('patient.patient')!}
        />
      </div>
      {showList && (
        <>
          {!!appointmentPatients?.length && (
            <PatientsList>
              {appointmentPatients.map((item) => {
                return (
                  <PatientItem
                    key={item.id}
                    onClick={() => handleSelectPatient(item)}
                  >
                    <SearchIcon />
                    <div>
                      <h2>
                        {item.firstName} {item.lastName}{' '}
                        {item.relationship && `(${item.relationship})`}
                      </h2>
                      <p>{item.phone}</p>
                      {!!item?.familyMembers?.length &&
                        item?.familyMembers?.map((member) => (
                          <p
                            key={member.id}
                          >{`${member.relationship} ${member.firstName} ${member.lastName}`}</p>
                        ))}
                    </div>
                  </PatientItem>
                );
              })}
            </PatientsList>
          )}
          {showEmptyState && (
            <EmptyResults>
              <PickIcon />
              <p>{t('patient.patient_not_found')}</p>
            </EmptyResults>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default PatientSearch;
