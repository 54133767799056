import axiosInstance from 'integration/api/axiosInstance';
import {
  CreateOnlineHoursData,
  OnlineHoursDto,
  UpdateOnlineHoursData,
} from 'common/types/onlineHours';

export const OnlineHoursAPI = {
  fetchOnlineHours: (id: string) => {
    return axiosInstance.get<OnlineHoursDto>(
      `/professionals/${id}/online-hours`,
    );
  },
  createOnlineHours: ({ id, data }: CreateOnlineHoursData) => {
    return axiosInstance.post(`/professionals/${id}/online-hours`, data);
  },
  updateOnlineHours: ({ id, data }: UpdateOnlineHoursData) => {
    return axiosInstance.patch(`/professionals/${id}/online-hours`, data);
  },
};
