import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeftIcon } from 'applicaiton/assets/arrow-left.svg';
import { ReactComponent as HelpIcon } from 'applicaiton/assets/help.svg';
import { HeaderStyled } from './styles';
import { useAppDispatch } from 'common/hooks/redux';
import { PathNames } from 'applicaiton/routes';
import { resetSecretaryData } from 'applicaiton/store/reducers/Secretaries/SecretariesSlice';
import { DocBaySupportLink } from 'applicaiton';

const Header: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleReset = () => {
    dispatch(resetSecretaryData());
  };

  return (
    <HeaderStyled>
      <Link onClick={handleReset} to={PathNames.myUsers}>
        <ArrowLeftIcon />
        {t('users.back_to_users')}
      </Link>
      <Link to={DocBaySupportLink} target="_blank">
        <HelpIcon />
        {t('professionalConfiguration.help_center')}
      </Link>
    </HeaderStyled>
  );
};

export default Header;
