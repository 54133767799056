import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface LinkProps {
  color: string;
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HelpCenterLink = styled(Link)<LinkProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme, color }) => theme.color[color]};
  text-decoration: none;
  font-weight: 700;
`;

export const HelpCenterText = styled.span`
  margin-left: 4px;
`;
