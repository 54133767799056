import React from 'react';

import Modal from '@mui/material/Modal';

import { IModal } from './types';

import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';
import { StyledModal } from './styles';

export const ModalComponent = ({
  isOpen,
  handleClose,
  children,
  padding,
  width = 'fit-content',
  maxWidth = '100%',
  hideCloseIcon = false,
}: IModal) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <StyledModal width={width} maxWidth={maxWidth} padding={padding}>
        {!hideCloseIcon && <CloseIcon onClick={handleClose} />}
        {children}
      </StyledModal>
    </Modal>
  );
};
