import styled from 'styled-components';

export const PaymentStepStyled = styled.article`
  display: flex;
  flex-direction: column;
  h1 {
    margin: 0px;
    span {
      color: ${({ theme }) => theme.color.blue700};
    }
  }
  color: ${({ theme }) => theme.color.grey700};
`;

export const SepaStyled = styled.span`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  color: ${({ theme }) => theme.color.grey700};

  > svg {
    margin-right: 4px;
  }
`;

export const StyledSpan = styled.span`
  > a {
    color: ${({ theme }) => theme.color.blue800};
    font-weight: 700;
    text-underline-offset: 3px;
  }
`;
