import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AgendaView,
  CalendarView,
  Format,
  ViewBy,
} from 'applicaiton/constants/scheduler';
import { SchedulerState } from './models';
import dayjs from 'dayjs';
import { searchProfessionalsByFilters } from './ActionCreators';
import { SearchProfessionalsByFiltersResponseDto } from '@docbay/schemas';
import {
  agendaFiltersSessionStorage,
  setAgendaFiltersSessionStorage,
} from 'applicaiton/sessionStorage/scheduler';

const sessionStorageData = agendaFiltersSessionStorage();

const initialState: SchedulerState = {
  isLoading: false,
  error: '',
  viewBy: sessionStorageData.viewBy || ViewBy.byProfessionals,
  professionals: [],
  byProfessionals: {
    calendarView:
      sessionStorageData?.byProfessionals?.calendarView || CalendarView.weekly,
    selectedDate:
      dayjs(sessionStorageData?.byProfessionals?.selectedDate).format(
        Format.dateFormat,
      ) || dayjs().format(Format.dateFormat),
    clinicId: sessionStorageData?.byProfessionals?.clinicId || '',
    selectedProfessionals:
      sessionStorageData?.byProfessionals?.selectedProfessionals || [],
    agendaProfessionals:
      sessionStorageData?.byProfessionals?.agendaProfessionals || [],
    daysCount: sessionStorageData?.byProfessionals?.daysCount || 1,
    slotsTimeView:
      sessionStorageData?.byProfessionals?.slotsTimeView || '00:00-23:59',
    agendaView:
      sessionStorageData?.byProfessionals?.agendaView || AgendaView.agenda,
  },
  byAvailability: {
    calendarView:
      sessionStorageData?.byAvailability?.calendarView || CalendarView.daily,
    selectedDate:
      dayjs(sessionStorageData?.byAvailability?.selectedDate).format(
        Format.dateFormat,
      ) || dayjs().format(Format.dateFormat),
    clinicId: sessionStorageData?.byAvailability?.clinicId || '',
    selectedProfessionals:
      sessionStorageData?.byAvailability?.selectedProfessionals || [],
    agendaProfessionals:
      sessionStorageData?.byAvailability?.agendaProfessionals || [],
    daysCount: sessionStorageData?.byAvailability?.daysCount || 1,
    slotsTimeView:
      sessionStorageData?.byAvailability?.slotsTimeView || '00:00-23:59',
    agendaView:
      sessionStorageData?.byAvailability?.agendaView || AgendaView.agenda,
  },
  refreshAgendaCounter: 0,
  isAddNewEvent: false,
};

const schedulerSlice = createSlice({
  name: 'schedulerSlice',
  initialState,
  reducers: {
    setViewBy: (state, action: PayloadAction<ViewBy>) => {
      state.viewBy = action.payload;
      setAgendaFiltersSessionStorage({
        viewBy: action.payload,
      });
    },
    setCalendarView: (state, action: PayloadAction<CalendarView>) => {
      state.byProfessionals.calendarView = action.payload;
      setAgendaFiltersSessionStorage({
        byProfessionals: {
          ...state.byProfessionals,
          calendarView: action.payload,
        },
      });
    },
    setSelectedDate: (
      state,
      action: PayloadAction<{ date: string; key: ViewBy }>,
    ) => {
      state[action.payload.key].selectedDate = action.payload.date;
      setAgendaFiltersSessionStorage({
        [action.payload.key]: {
          ...state[action.payload.key],
          selectedDate: action.payload.date,
        },
      });
    },
    setClinicId: (
      state,
      action: PayloadAction<{ clinicId: string; key: ViewBy }>,
    ) => {
      state[action.payload.key].clinicId = action.payload.clinicId;
      setAgendaFiltersSessionStorage({
        [action.payload.key]: {
          ...state[action.payload.key],
          clinicId: action.payload.clinicId,
        },
      });
    },
    setSelectedProfessionals: (
      state,
      action: PayloadAction<{
        professionals: SearchProfessionalsByFiltersResponseDto[];
        key: ViewBy;
      }>,
    ) => {
      state[action.payload.key].selectedProfessionals =
        action.payload.professionals;
      setAgendaFiltersSessionStorage({
        [action.payload.key]: {
          ...state[action.payload.key],
          selectedProfessionals: action.payload.professionals,
        },
      });
    },
    setAgendaProfessionals: (
      state,
      action: PayloadAction<{
        professionals: SearchProfessionalsByFiltersResponseDto[];
        key: ViewBy;
      }>,
    ) => {
      state[action.payload.key].agendaProfessionals =
        action.payload.professionals;
      setAgendaFiltersSessionStorage({
        [action.payload.key]: {
          ...state[action.payload.key],
          agendaProfessionals: action.payload.professionals,
        },
      });
    },
    setDaysCount: (
      state,
      action: PayloadAction<{ daysCount: number; key: ViewBy }>,
    ) => {
      state[action.payload.key].daysCount = action.payload.daysCount;
      setAgendaFiltersSessionStorage({
        [action.payload.key]: {
          ...state[action.payload.key],
          daysCount: action.payload.daysCount,
        },
      });
    },
    setSlotsTimeView: (
      state,
      action: PayloadAction<{ slotsTimeView: string; key: ViewBy }>,
    ) => {
      state[action.payload.key].slotsTimeView = action.payload.slotsTimeView;
      setAgendaFiltersSessionStorage({
        [action.payload.key]: {
          ...state[action.payload.key],
          slotsTimeView: action.payload.slotsTimeView,
        },
      });
    },
    refreshAgenda: (state) => {
      state.refreshAgendaCounter += 1;
    },
    resetScheduler: (state) => {
      state.viewBy = ViewBy.byProfessionals;
      state.professionals = [];
      state.byProfessionals = {
        calendarView: CalendarView.weekly,
        selectedDate: dayjs().format(Format.dateFormat),
        clinicId: '',
        selectedProfessionals: [],
        agendaProfessionals: [],
        daysCount: 1,
        slotsTimeView: '00:00-23:59',
        agendaView: AgendaView.agenda,
      };
      state.byAvailability = {
        calendarView: CalendarView.daily,
        selectedDate: dayjs().format(Format.dateFormat),
        clinicId: '',
        selectedProfessionals: [],
        agendaProfessionals: [],
        daysCount: 1,
        slotsTimeView: '00:00-23:59',
        agendaView: AgendaView.agenda,
      };
      state.refreshAgendaCounter = 0;
      state.isAddNewEvent = false;
    },
    setIsAddNewEvent: (state, action: PayloadAction<boolean>) => {
      state.isAddNewEvent = action.payload;
    },
    setAgendaView: (state, action: PayloadAction<AgendaView>) => {
      state[state.viewBy].agendaView = action.payload;
      setAgendaFiltersSessionStorage({
        [state.viewBy]: {
          ...state[state.viewBy],
          agendaView: action.payload,
        },
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchProfessionalsByFilters.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchProfessionalsByFilters.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(searchProfessionalsByFilters.fulfilled, (state, action) => {
      const filteredProfessionals = action.payload.filter((professional) => {
        const findProfessional = state[state.viewBy].selectedProfessionals.find(
          (item) => item.id === professional.id,
        );
        return !!findProfessional;
      });
      state.professionals = action.payload;
      state[state.viewBy].selectedProfessionals = filteredProfessionals;
      state[state.viewBy].agendaProfessionals = filteredProfessionals;
      state.isLoading = false;
    });
  },
});

export const {
  setViewBy,
  setCalendarView,
  setSelectedDate,
  setClinicId,
  setSelectedProfessionals,
  setDaysCount,
  setAgendaProfessionals,
  setSlotsTimeView,
  refreshAgenda,
  resetScheduler,
  setIsAddNewEvent,
  setAgendaView,
} = schedulerSlice.actions;

export default schedulerSlice.reducer;
