import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'applicaiton/assets/close.svg';

interface StylesProps {
  last?: boolean;
}

export const WrapperMargin = styled.div`
  margin-bottom: 24px;
`;

export const InputWrapper = styled.div<StylesProps>`
  display: flex;
  margin-bottom: ${({ last }) => (last ? 0 : 12)}px;
`;

export const InputContainer = styled.div`
  width: 464px;
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
  padding-left: 8px;
  path {
    fill: ${({ theme }) => theme.color.grey500};
  }
`;
export const Container = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;

  p {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: 0.07px;
    color: ${({ theme }) => theme.color.grey600};
  }
`;
