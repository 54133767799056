import React, { FC, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'mobile-device-detect';

import type { RegistrationDataT } from './types';
import { useCreatePasswordForm } from './hooks/useCreatePasswordForm';

import CreatePassword from 'common/components/CreatePassword';
import { Input, PrimaryButton } from 'common/components';

import { PathNames } from 'applicaiton/routes';

import {
  CreatePasswordWrapper,
  FormSubTitle,
  FormTitle,
  FormWrapper,
  StyledForm,
  TitleWrapper,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  checkProfessionalVerificationCode,
  createProfessionalPassword,
} from 'applicaiton/store/reducers/Professionals/ActionCreators';
import {
  setIsCreatePassword,
  setIsVerificationCodeExpired,
} from 'applicaiton/store/reducers/Professionals/ProfessionalsSlice';
import {
  checkAdminVerificationCode,
  createAdminPassword,
} from 'applicaiton/store/reducers/Admins/ActionCreators';
import { UserRoles } from 'applicaiton/constants/userRoles';
import {
  checkSecretariesVerificationCode,
  createSecretariesPassword,
} from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import { ReactComponent as LoginIcon } from 'applicaiton/assets/login.svg';
import { login } from 'common/auth/authService';

const FinishRegistration: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isVerificationCodeExpired, isCreatePassword } = useAppSelector(
    (state) => state.professionals,
  );
  const email = location.search.split('?email=').pop()?.split('&')[0] ?? '';
  const code = new URLSearchParams(location.search).get('code') ?? '';
  const userType = new URLSearchParams(location.search).get('user-type') ?? '';

  const isAdmin = userType === UserRoles.admin.toLocaleLowerCase();
  const isProfessional =
    userType === UserRoles.professional.toLocaleLowerCase();
  const isSecretary = userType === UserRoles.secretary.toLocaleLowerCase();

  const { register, errors, getValues, handleSubmit, watch, setError } =
    useCreatePasswordForm({
      emailError: t('errors.emailFormat'),
      requiredError: t('errors.required'),
    });

  useEffect(() => {
    if (email && code) {
      if (isAdmin) {
        dispatch(checkAdminVerificationCode({ email, code }));
      }
      if (isProfessional) {
        dispatch(checkProfessionalVerificationCode({ email, code }));
      }
      if (isSecretary) {
        dispatch(checkSecretariesVerificationCode({ email, code }));
      }
    }
  }, [email, code]);

  useEffect(() => {
    isVerificationCodeExpired && navigate(PathNames.expiredVerificationCode);
    return () => {
      dispatch(setIsVerificationCodeExpired(false));
    };
  }, [isVerificationCodeExpired]);

  useEffect(() => {
    isCreatePassword &&
      navigate(isMobile ? PathNames.mobileInfo : PathNames.schedule);
    return () => {
      dispatch(setIsCreatePassword(false));
    };
  }, [isCreatePassword]);

  const onSubmit = async (data: RegistrationDataT) => {
    let response = null;
    if (isProfessional) {
      response = await dispatch(createProfessionalPassword({ ...data, code }));

      if (response.meta.requestStatus === 'rejected') {
        setError('email', { message: t('auth.log_in.incorrect_credentials')! });
      }
    }

    if (isAdmin) {
      response = await dispatch(createAdminPassword({ ...data, code }));

      if (response.meta.requestStatus === 'rejected') {
        setError('email', { message: t('auth.log_in.incorrect_credentials')! });
      }
    }

    if (isSecretary) {
      response = await dispatch(createSecretariesPassword({ ...data, code }));

      if (response.meta.requestStatus === 'rejected') {
        setError('email', {
          message: t('auth.log_in.incorrect_credentials')!,
        });
      }
    }
    if (response?.meta.requestStatus === 'fulfilled') {
      !isMobile && login();
    }
  };

  return (
    <CreatePasswordWrapper>
      <LoginIcon />
      <TitleWrapper>
        <FormTitle>{t('auth.create_password.title')}</FormTitle>
        <FormSubTitle>{t('auth.create_password.sub_title')}</FormSubTitle>
      </TitleWrapper>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper>
          <Input
            id="email"
            label={t('email') || ''}
            type="email"
            placeholder={t('email_placeholder') || ''}
            register={register}
            errorMessage={errors.email?.message}
          />
          <CreatePassword
            watch={watch}
            getValues={getValues}
            register={register}
            errors={errors.password?.message}
          />
        </FormWrapper>
        <PrimaryButton
          onClick={handleSubmit(onSubmit)}
          type="submit"
          disabled={!!Object.keys(errors).length}
        >
          {t('auth.continue')}
        </PrimaryButton>
      </StyledForm>
    </CreatePasswordWrapper>
  );
};

export default FinishRegistration;
