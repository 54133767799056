import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { AppointmentStatus } from 'common/types/appointmentStatus';

export interface AppointmentForm {
  appointmentTypeId: string | null;
  patientId: string | null;
  comment: string;
  status: AppointmentStatus;
  statusComment: string;
}

export const useCreateAppointment = () => {
  const { t } = useTranslation();

  const requiredError = t('errors.required');

  const formValues = {
    appointmentTypeId: null,
    patientId: null,
    comment: '',
    status: AppointmentStatus.UPCOMING,
  };

  const appointmentSchema = yup.object({
    appointmentTypeId: yup.string().required(requiredError!).nullable(),
    patientId: yup.string().required(requiredError!).nullable(),
    comment: yup.string(),
    status: yup.string().required(),
    statusComment: yup.string(),
  });

  const {
    watch,
    getValues,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    setError,
    trigger,
    formState: { errors, isValid },
  } = useForm<AppointmentForm>({
    resolver: yupResolver(appointmentSchema),
    defaultValues: formValues,
  });

  return {
    watch,
    getValues,
    setValue,
    errors,
    register,
    handleSubmit,
    clearErrors,
    setError,
    trigger,
    isValid,
  };
};
