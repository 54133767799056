import styled from 'styled-components';

export const ClinicsListStyled = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 32px 20px 32px;
  background: ${({ theme }) => theme.color.background};
  overflow-y: auto;
  height: 100%;
`;

export const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h1 {
    color: ${({ theme }) => theme.color.grey900};
    font-size: 28px;
    line-height: 22px;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }
  > a {
    text-decoration: none;
    > button {
      width: fit-content;
      padding: 10px 24px;
      > svg {
        path {
          fill: ${({ theme }) => theme.color.grey900};
        }
      }
    }
  }
`;

export const NotificationStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.color.grey300};
  background: ${({ theme }) => theme.color.grey50};
  margin-bottom: 32px;

  > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;

    path {
      fill: ${({ theme }) => theme.color.error2};
    }
  }
  > p {
    color: ${({ theme }) => theme.color.grey600};
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0.07px;
  }
`;
