import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import dayjs from 'dayjs';
import { Dropdown, RadioButton, PrimaryButton, Input } from 'common/components';
import { getDurationOptions } from 'common/helpers/getDurationOptions';
import {
  Wrapper,
  DropdownSection,
  RadioButtonSection,
  StyledDivider,
  ButtonsWrapper,
  ColorPicker,
  ColorValue,
  ColorPickerWrapper,
  Picker,
  PreviewAppointmentWrapper,
  ColorSection,
  PreviewAppointments,
  Name,
  Type,
  Time,
  CheckboxWrapper,
  SwitchStyled,
  DropdownBottomSection,
  InfoSection,
  PriceInputStyled,
  BillingTitleSection,
  RadioButtonWrapper,
  WarningText,
} from './styles';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import { useGeneralTypeInfoForm } from '../../hooks/useGeneralTypeInfoForm';
import { useAppointmentPlacesOptions } from 'common/hooks/useAppointmentPlacesOptions';
import duration from 'dayjs/plugin/duration';
import { Option } from 'common/components/Dropdown/models';
import useOnClickOutside from 'common/hooks/useClickOutside';
import Switch from 'common/components/Switch';
import { useOpeningReservationAppointment } from 'common/hooks/useOpeningReservationAppointment';
import { ReactComponent as AttentionIcon } from 'applicaiton/assets/attention.svg';
import { ReactComponent as OnlineIcon } from 'applicaiton/assets/online.svg';
import { getRGBAColor } from 'common/helpers/getRGBAColor';
import { updateAppointmentType } from 'applicaiton/store/reducers/AppointmentTypes/ActionCreators';
import { Link, useParams } from 'react-router-dom';
import compareObjectsChanges from 'common/helpers/compareObjectsChanges';
import { setHasUnsavedChanges } from 'applicaiton/store/reducers/DetectChangesSaved/DetectChangesSavedSlice';
import { getUserId, getUserRole } from 'applicaiton/sessionStorage/auth';
import { UserRoles } from 'applicaiton/constants/userRoles';
import { getAccountStatus } from 'applicaiton/store/reducers/ClinicOwner/ActionCreators';
import { userRoleExactMatch } from 'common/helpers/userRoleExactMatch';
import { getAdminById } from 'applicaiton/store/reducers/Admins/ActionCreators';
import { getSecretaryById } from 'applicaiton/store/reducers/Secretaries/ActionCreators';
import { PathNames } from 'applicaiton/routes';

dayjs.extend(duration);

export enum MeetingPlace {
  Clinic = 'Clinic',
  Teleconsultation = 'Teleconsultation',
}

const GeneralTypeInfo: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const colorRef = useRef(null);
  const userId = getUserId();
  const userRole = getUserRole();
  const isClinicOwner = userRole?.includes(UserRoles.clinicOwner);
  const isAdmin = userRoleExactMatch(UserRoles.admin);
  const isSecretary = userRole?.includes(UserRoles.secretary);
  const dispatch = useAppDispatch();
  const { currentAppointmentTypeChanges } = useAppSelector(
    (state) => state.appointmentTypes,
  );
  const { isAccountVerified } = useAppSelector(
    (state) => state.clinicOwnerSlice,
  );

  const admin = useAppSelector((state) => state.admins);
  const secretary = useAppSelector((state) => state.secretaries);

  const {
    setValue,
    errors,
    register,
    handleSubmit,
    getValues,
    watch,
    setError,
  } = useGeneralTypeInfoForm({
    requiredError: t('errors.required'),
    maxLengthError: t('appointment_types.max_characters'),
  });

  const places = useAppointmentPlacesOptions();
  const isTeleconsultation = useMemo(() => {
    return watch('meetingPlace') === MeetingPlace.Teleconsultation;
  }, [watch('meetingPlace')]);

  const durationValue = useMemo(() => {
    return getDurationOptions(10, 120, 5);
  }, [isAccountVerified]);

  const openingReservationAppointmentValue: Option[] =
    useOpeningReservationAppointment();
  const closeReservationAppointmentValue: Option[] = getDurationOptions(
    0,
    60,
    15,
  );

  const [colorValue, setColorValue] = useState('#756FD7');
  const [colorRGBA, setColorRGBA] = useState('');

  const [openingReservationAppointment, setOpeningReservationAppointment] =
    useState<Option>(openingReservationAppointmentValue[0]);
  const [closeReservationAppointment, setCloseReservationAppointment] =
    useState<Option>(closeReservationAppointmentValue[0]);

  const [currentDuration, setCurrentDuration] = useState<Option>(
    durationValue[0],
  );
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isBookOnline, setIsBookOnline] = useState(true);

  useOnClickOutside(colorRef, () => setShowColorPicker(false));

  const rgbaBackground = (color: string) => {
    const backgroundRGBA = getRGBAColor(color);

    setColorRGBA(backgroundRGBA);
  };

  useEffect(() => {
    if (isClinicOwner && userId) {
      dispatch(getAccountStatus(userId));
    }
    if (isAdmin && userId) {
      dispatch(getAdminById(userId));
    }
    if (isSecretary && userId) {
      dispatch(getSecretaryById(userId));
    }
  }, [isClinicOwner, isAdmin, isSecretary]);

  const isClinicOwnerAccountVerified = useMemo(() => {
    return (
      isAccountVerified ||
      admin?.currentUser?.clinicOwner?.isStripeAccountActive ||
      secretary?.currentUser?.clinicOwner?.isStripeAccountActive
    );
  }, [isAccountVerified, admin, secretary]);

  useEffect(() => {
    if (currentAppointmentTypeChanges) {
      const {
        title,
        durationInMinutes,
        meetingPlace,
        colorCode,
        availableForOnlineBooking,
        reservationOpenInMinutes,
        reservationCloseInMinutes,
        price,
        billingTitle,
      } = currentAppointmentTypeChanges;

      setValue('title', title!);
      setValue('durationInMinutes', durationInMinutes as number);
      setValue('meetingPlace', meetingPlace!);
      setValue('colorCode', colorCode!);
      setValue('availableForOnlineBooking', availableForOnlineBooking!);
      setValue('reservationOpenInMinutes', reservationOpenInMinutes as number);
      setValue(
        'reservationCloseInMinutes',
        reservationCloseInMinutes as number,
      );
      setValue('price', String(price));
      setValue('billingTitle', billingTitle);

      const duration = durationValue.filter(
        (item) => String(item.value) === String(durationInMinutes)!,
      );
      const closeReservation = closeReservationAppointmentValue.filter(
        (item) => String(item.value) === String(reservationCloseInMinutes)!,
      );
      const openReservation = openingReservationAppointmentValue.filter(
        (item) => String(item.value) === String(reservationOpenInMinutes)!,
      );

      setOpeningReservationAppointment(
        openReservation[0] || openingReservationAppointmentValue[0],
      );
      setCloseReservationAppointment(
        closeReservation[0] || closeReservationAppointmentValue[0],
      );
      setColorValue(colorCode!);
      setCurrentDuration(duration[0]);
      setIsBookOnline(availableForOnlineBooking!);
      rgbaBackground(colorCode!);
    }
  }, [currentAppointmentTypeChanges]);

  const onSubmit = () => {
    const {
      title,
      durationInMinutes,
      meetingPlace,
      colorCode,
      availableForOnlineBooking,
      reservationOpenInMinutes,
      reservationCloseInMinutes,
      price,
      billingTitle,
    } = getValues();

    if (isTeleconsultation) {
      const isValidPrice = parseFloat(price!) >= 1;

      if (!isValidPrice) {
        return setError('price', {
          message: t('appointment_types.min_price') || '',
        });
      }
    }

    dispatch(
      updateAppointmentType({
        id: id!,
        data: {
          title,
          durationInMinutes: Number(durationInMinutes),
          meetingPlace: meetingPlace as MeetingPlace,
          colorCode,
          availableForOnlineBooking,
          reservationOpenInMinutes: Number(reservationOpenInMinutes),
          reservationCloseInMinutes: Number(reservationCloseInMinutes),
          ...(isTeleconsultation && price ? { price: Number(price) } : {}),
          billingTitle,
        },
      }),
    );
  };

  const isChanged = useMemo(() => {
    if (!currentAppointmentTypeChanges) return false;

    const hasChanges = compareObjectsChanges(
      {
        title: currentAppointmentTypeChanges.title!,
        durationInMinutes: Number(
          currentAppointmentTypeChanges?.durationInMinutes!,
        ),
        meetingPlace: currentAppointmentTypeChanges?.meetingPlace,
        colorCode: currentAppointmentTypeChanges?.colorCode,
        availableForOnlineBooking:
          currentAppointmentTypeChanges?.availableForOnlineBooking!,
        reservationOpenInMinutes: Number(
          currentAppointmentTypeChanges?.reservationOpenInMinutes!,
        ),
        reservationCloseInMinutes: Number(
          currentAppointmentTypeChanges?.reservationCloseInMinutes!,
        ),
        price: String(currentAppointmentTypeChanges.price),
        billingTitle: currentAppointmentTypeChanges.billingTitle || '',
      },
      {
        ...getValues(),
        durationInMinutes: Number(getValues('durationInMinutes')!),
        reservationOpenInMinutes: Number(
          getValues('reservationOpenInMinutes')!,
        ),
        reservationCloseInMinutes: Number(
          getValues('reservationCloseInMinutes')!,
        ),
        billingTitle: getValues('billingTitle') || '',
      },
    );
    dispatch(setHasUnsavedChanges(hasChanges));

    return hasChanges;
  }, [
    watch('title'),
    watch('durationInMinutes'),
    watch('meetingPlace'),
    watch('colorCode'),
    watch('availableForOnlineBooking'),
    watch('reservationOpenInMinutes'),
    watch('reservationCloseInMinutes'),
    watch('price'),
    watch('billingTitle'),
  ]);

  const handleChangePrice = (price: string) => {
    const isIntNumber = price.indexOf('.') < 0;
    const isValidDecimalNumber = price.slice(price.indexOf('.')).length < 4;
    if (isIntNumber || isValidDecimalNumber) {
      setValue('price', price, {
        shouldValidate: !!Object.keys(errors).length,
      });
    }

    if (isTeleconsultation) {
      const isValidPrice = parseFloat(price!) >= 1;

      !isValidPrice &&
        setError('price', { message: t('appointment_types.min_price') || '' });
    }
  };

  return (
    <Wrapper>
      <RadioButtonSection>
        <p>{t('appointment_types.meeting_place') || ''}</p>
        <div>
          {places.map((item) => (
            <RadioButtonWrapper key={item.value}>
              <RadioButton
                key={item.value}
                id={item.value}
                label={item.label}
                checked={watch('meetingPlace') === item.value}
                onChange={(e) => {
                  const value =
                    e.target.id === 'Clinic'
                      ? MeetingPlace.Clinic
                      : MeetingPlace.Teleconsultation;

                  setValue('meetingPlace', value);
                }}
                disabled={
                  !isClinicOwnerAccountVerified &&
                  item.value === MeetingPlace.Teleconsultation
                }
              />
              {!isClinicOwnerAccountVerified &&
                item.value === MeetingPlace.Teleconsultation &&
                (isClinicOwner ? (
                  <WarningText>
                    {t('appointment_types.setup_stripe')}{' '}
                    <Link to={PathNames.clinicBilling} target="_blank">
                      {t('appointment_types.setup_stripe_link')}
                    </Link>
                  </WarningText>
                ) : (
                  <WarningText>
                    {t('appointment_types.ask_setup_stripe_link')}
                  </WarningText>
                ))}
            </RadioButtonWrapper>
          ))}
        </div>
      </RadioButtonSection>
      {isTeleconsultation && (
        <PriceInputStyled>
          <Input
            id={'price'}
            type={'number'}
            label={t('price') || ''}
            min={'0.00'}
            step={'0.01'}
            value={watch('price')}
            onChange={(e) => handleChangePrice(e.target.value)}
            errorMessage={errors?.price?.message}
          />
        </PriceInputStyled>
      )}
      <DropdownSection>
        <Input
          id={'title'}
          type={'text'}
          register={register}
          label={t('appointment_types.title')!}
          placeholder={t('appointment_types.title_placeholder')!}
          errorMessage={errors?.title?.message}
        />
        <Dropdown
          id={'durationInMinutes'}
          value={String(currentDuration.value)}
          label={t('appointment_types.duration')!}
          placeholder={t('appointment_types.duration')!}
          onChange={(value) => {
            if (!Array.isArray(value)) {
              setCurrentDuration(value);
              setValue('durationInMinutes', value.value as number);
            }
          }}
          options={durationValue}
          errorMessage={errors?.durationInMinutes?.message}
        />
      </DropdownSection>
      <BillingTitleSection>
        <Input
          id={'billingTitle'}
          type={'text'}
          register={register}
          label={t('appointment_types.billing_title')!}
          placeholder={t('appointment_types.billing_title_placeholder')!}
          errorMessage={errors?.title?.message}
        />
      </BillingTitleSection>
      <ColorSection>
        <ColorPickerWrapper>
          <label>{t('appointment_types.color_code_for_schedule')}</label>
          <ColorPicker onClick={() => setShowColorPicker(true)}>
            <ColorValue color={colorValue}></ColorValue>
            <span>{colorValue}</span>
          </ColorPicker>
          {showColorPicker && (
            <Picker ref={colorRef}>
              <SketchPicker
                color={colorValue}
                onChange={(value) => {
                  rgbaBackground(value.hex);
                  setColorValue(value.hex);
                  setValue('colorCode', value.hex);
                }}
              />
            </Picker>
          )}
        </ColorPickerWrapper>
        <PreviewAppointmentWrapper>
          <label>{t('appointment_types.review_appointment')}</label>
          <PreviewAppointments color={colorValue} backgroundColor={colorRGBA}>
            <div>
              <Name>{t('appointment_types.patient_name')}</Name>
              <Type color={colorValue}>
                {t('appointment_types.teleconsultation')}{' '}
                {getValues('meetingPlace') !== 'Clinic' && <OnlineIcon />}
              </Type>
              <Time>14:00 - 15:00</Time>
            </div>
            <div>
              <Time>
                8:00 -<br /> 9:00
              </Time>
            </div>
          </PreviewAppointments>
        </PreviewAppointmentWrapper>
      </ColorSection>
      <CheckboxWrapper>
        <label>{t('appointment_types.availableForOnlineBooking_label')}</label>
        <SwitchStyled>
          <Switch
            checked={isBookOnline}
            onChange={(value) => {
              setIsBookOnline(value);
              setValue('availableForOnlineBooking', value);
            }}
          />
          <p>{t('appointment_types.availableForOnlineBooking')}</p>
        </SwitchStyled>
      </CheckboxWrapper>
      <StyledDivider />
      <DropdownBottomSection>
        <Dropdown
          id={'reservationOpenInMinutes'}
          value={String(openingReservationAppointment?.value)}
          label={t('appointment_types.reservationOpenInMinutes')!}
          placeholder={t('appointment_types.duration')!}
          onChange={(value) => {
            if (!Array.isArray(value)) {
              setOpeningReservationAppointment(value);
              setValue('reservationOpenInMinutes', value.value as number);
            }
          }}
          options={openingReservationAppointmentValue}
          errorMessage={errors?.durationInMinutes?.message}
        />
        <Dropdown
          id={'reservationCloseInMinutes'}
          value={String(closeReservationAppointment?.value)}
          label={t('appointment_types.reservationCloseInMinutes')!}
          placeholder={t('appointment_types.duration')!}
          onChange={(value) => {
            if (!Array.isArray(value)) {
              setCloseReservationAppointment(value);
              setValue('reservationCloseInMinutes', value.value as number);
            }
          }}
          options={closeReservationAppointmentValue}
          errorMessage={errors?.durationInMinutes?.message}
        />
      </DropdownBottomSection>
      <InfoSection>
        <AttentionIcon />
        <div>
          {t('appointment_types.book_info.info_text1')}{' '}
          <span>{openingReservationAppointment?.label}</span>{' '}
          {t('appointment_types.book_info.info_text2')}{' '}
          <span>{closeReservationAppointment?.label}</span>{' '}
          {t('appointment_types.book_info.info_text3')}
        </div>
      </InfoSection>
      <StyledDivider />
      <ButtonsWrapper>
        <PrimaryButton
          type={'button'}
          onClick={handleSubmit(onSubmit)}
          disabled={!!errors.title?.message || !isChanged}
        >
          {t('save')}
        </PrimaryButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default GeneralTypeInfo;
